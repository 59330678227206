export const apiKey = process.env.REACT_APP_GOOGLE_KEY
export const mapApiJs = 'https://maps.googleapis.com/maps/api/js'

//load google api
export function loadAsyncScript(src: any) {
  return new Promise(resolve => {
    const script = document.createElement('script')
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    })
    script.addEventListener('load', () => resolve(script))
    document.head.appendChild(script)
  })
}
