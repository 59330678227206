import { OreButton, OreDivider, OreStack } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { TTOrderExcelSummaryState } from '../hooks/useTTOrderExcelSummaryController'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { useTTOrder } from '../../../context/TTOrderProvider'

interface Props {
  ttProps: TTOrderExcelSummaryState
}

export const TTOrderExcelSummaryButtons = ({ ttProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { ttOrderCheckout } = useTTOrder()
  const { form, onClickCancel, onCheckoutOrder } = ttProps

  return (
    <div style={{ width: '100%' }}>
      <OreDivider space="medium" />

      <OreStack direction="row" placeContent="space-between" placeItems="normal">
        <div> </div>
        <OreStack direction="row" space="medium">
          <OreButton onClick={() => onClickCancel()}>{t(forms.buttons.cancel)}</OreButton>
          <OreButton
            category="primary"
            onClick={form.handleSubmit(onCheckoutOrder)}
            disabled={ttOrderCheckout.data?.abstractCardOrder.resumeData.length === 0}>
            {t(TTOrderTranslation.summary.checkoutButton)}
          </OreButton>
        </OreStack>
      </OreStack>
    </div>
  )
}
