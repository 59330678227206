import { Seq } from 'immutable'
import { useContext, useState } from 'react'
import type { OrderContext } from '../../../domain/enum'
import { orderType, orderUndefined } from '../../../domain/enum'
import type { RowModel } from '../../../domain/customComponents/table/TableModel'
import { browserStorageKeys } from '../../../domain/enum/browserStorageKeys'
import type { BasicParameters, OrderConfigParameters } from '../../../domain/forms'
import type {
  CardOrderResumeModel,
  EmployeesWithLastCard,
} from '../../../domain/models/cardOrder/'
import type { CardOrderModel } from '../../../domain/models/cardOrder/CardOrderModel'
import type { SaveCardOrder } from '../../../domain/protocols/CardOrderProtocol'
import { CardOrderContext } from './CardOrderContext'
import { validateExcelCheckoutDefault } from '../../../infrastructure/Mocks/OrderExcel'
import type { MessagesModel } from '../../../domain/models'
import { navigationRoutes } from '../../../config/constants/navigationRoutes'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'

export const CardOrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [orders, setOrders] = useState([] as CardOrderModel[])
  const defaultExcelOrder = validateExcelCheckoutDefault.summary
  const [orderExcel, setOrderExcel] = useState<CardOrderResumeModel>(defaultExcelOrder)
  const [base64File, setBase64File] = useState<string>('')
  const [base64FileAlerts, setBase64FileAlerts] = useState<string>('')
  const [orderExcelAlerts, setOrderExcelAlerts] = useState<MessagesModel[]>([])
  const [cardOrderData, setCardOrderData] = useState<SaveCardOrder>()
  const [cardOrderCheckout, setCardOrderCheckout] = useState<CardOrderResumeModel>()
  const [formParameters, setFormParameters] = useState<
    BasicParameters<CardOrderModel> | undefined
  >()
  const [configurationParameters, setConfigurationParameters] = useState<
    OrderConfigParameters<RowModel[]> | undefined
  >()
  const [orderContext, setOrderContext] = useState<OrderContext>(orderUndefined)
  const [leaveOrderEnabled, setLeaveOrderEnabled] = useState(false)

  const clearAll = () => {
    setOrders([] as CardOrderModel[])
    setOrderExcel(defaultExcelOrder)
    setBase64File('')
    setBase64FileAlerts('')
    setOrderExcelAlerts([])
    setCardOrderData({} as SaveCardOrder)
    setCardOrderCheckout({} as CardOrderResumeModel)
    setFormParameters(undefined)
    setConfigurationParameters(undefined)
    sessionStorage.removeItem(browserStorageKeys.orderURIFallback)
  }

  // This function removes any orders different from the current order Type.
  const clearByOrderType = (orderType: number) => {
    const ordersFixed = orders.filter(o => o.orderTypeId === orderType)
    setOrders(ordersFixed)
    setCardOrderData({} as SaveCardOrder)
    sessionStorage.removeItem(browserStorageKeys.orderURIFallback)
  }

  const addOrder = (order: CardOrderModel) => {
    setOrders([...orders, { ...order }])
  }

  const addOrders = (orders: CardOrderModel[]) => {
    Seq(orders).forEach(order => {
      setOrders(prevOrders => [...prevOrders, order])
    })
  }

  const removeOrder = (orderId: number) => {
    setOrders(orders => orders.filter(cardOrder => cardOrder.orderId !== orderId))
  }

  const removeRechargeOrder = (orderId: number) => {
    setOrders(orders =>
      orders.filter(
        cardOrder =>
          cardOrder.orderId !== orderId && cardOrder.orderTypeId === orderType.Recharge
      )
    )
  }

  const removeUnloadOrder = (orderId: number) => {
    setOrders(orders =>
      orders.filter(
        cardOrder =>
          cardOrder.orderId !== orderId && cardOrder.orderTypeId === orderType.Unload
      )
    )
  }

  const getAllRechargeOrders = (): CardOrderModel[] => {
    return orders.filter(cardOrder => cardOrder.orderTypeId === orderType.Recharge)
  }

  const getAllUnloadOrders = (): CardOrderModel[] => {
    return orders.filter(cardOrder => cardOrder.orderTypeId === orderType.Unload)
  }

  const editOrder = (order: CardOrderModel) => {
    const index: number = Seq(orders).findIndex(
      (o: CardOrderModel) => o.orderId === order.orderId
    )
    const updateOrders: CardOrderModel[] = [...orders]
    updateOrders[index] = order
    setOrders(updateOrders)
  }

  const getOrderById = (id: number): any => {
    return orders.find(order => order.orderId === id)
  }

  const updateOrder = (orderToEdit: CardOrderModel): void => {
    const index = orders.findIndex(order => order.orderId === orderToEdit.orderId)
    const updateOrders: CardOrderModel[] = [...orders]
    updateOrders[index] = orderToEdit
    setOrders(updateOrders)
  }

  const addOrUpdateOrders = (newOrders: CardOrderModel[]) => {
    if (orders && orders.length <= 0) {
      setOrders(newOrders)
      return
    }
    const updatedOrders: CardOrderModel[] = [...orders]
    newOrders.forEach(newOrder => {
      const index = updatedOrders.findIndex(order => order.orderId === newOrder.orderId)
      if (index !== -1) {
        updatedOrders[index] = { ...updatedOrders[index], ...newOrder }
      } else {
        updatedOrders.push(newOrder)
      }
    })
    setOrders(updatedOrders)
  }

  const clearOrders = () => {
    setOrders([] as CardOrderModel[])
  }

  const clearOrderExcel = () => {
    setOrderExcel(defaultExcelOrder)
  }

  const clearOrderCheckout = () => {
    setCardOrderCheckout(undefined)
  }

  const setFormMode = (formParameters: BasicParameters<CardOrderModel>) => {
    setFormParameters(formParameters)
  }

  const setCardOrderDuplicates = (duplicates: EmployeesWithLastCardNew[]) => {
    sessionStorage.setItem(
      browserStorageKeys.cardOrderDuplicate,
      JSON.stringify(duplicates)
    )
  }

  const getCardOrderDuplicates = () => {
    const duplicates = sessionStorage.getItem(browserStorageKeys.cardOrderDuplicate)
    return duplicates ? JSON.parse(duplicates) : []
  }

  const clearCardOrderDuplicates = () => {
    sessionStorage.clear()
  }

  const setCardOrderRecharges = (recharges: EmployeesWithLastCardNew[]) => {
    sessionStorage.setItem(
      browserStorageKeys.cardOrderRecharge,
      JSON.stringify(recharges)
    )
  }

  const clearCardOrderUnload = () => {
    sessionStorage.clear()
  }

  const setCardOrderUnload = (recharges: EmployeesWithLastCardNew[]) => {
    sessionStorage.setItem(browserStorageKeys.cardOrderUnload, JSON.stringify(recharges))
  }
  const getCardOrderUnload = (): EmployeesWithLastCardNew[] => {
    const unloads = sessionStorage.getItem(browserStorageKeys.cardOrderUnload)
    return unloads ? JSON.parse(unloads) : []
  }

  const getCardOrderRecharges = (): EmployeesWithLastCardNew[] => {
    const recharges = sessionStorage.getItem(browserStorageKeys.cardOrderRecharge)
    return recharges ? JSON.parse(recharges) : []
  }

  const clearCardOrderRecharges = () => {
    sessionStorage.clear()
  }

  const setTRSelectedEmployees = (employees: EmployeesWithLastCard[]): void => {
    sessionStorage.setItem(
      browserStorageKeys.cardOrderSelectedEmployees,
      JSON.stringify(employees)
    )
  }

  const getTRSelectedEmployees = (): EmployeesWithLastCard[] => {
    const employees = sessionStorage.getItem(
      browserStorageKeys.cardOrderSelectedEmployees
    )
    return employees ? JSON.parse(employees) : []
  }

  const setTREmployeesToConfig = (employees: CardOrderModel[]): void => {
    sessionStorage.setItem(
      browserStorageKeys.cardOrderEmployees,
      JSON.stringify(employees)
    )
  }

  const getTREmployeesToConfig = (): CardOrderModel[] => {
    const employees = sessionStorage.getItem(browserStorageKeys.cardOrderEmployees)
    return employees ? JSON.parse(employees) : []
  }

  const setConfigurationMode = (configuration: OrderConfigParameters<RowModel[]>) => {
    setConfigurationParameters(configuration)
  }

  const isOrderExcel = () => {
    return orderContext.isExcelOrder
  }

  function HandleExcelRedirects(orderContext: OrderContext) {
    switch (orderContext.orderType) {
      case orderType.Card:
        sessionStorage.setItem(
          browserStorageKeys.orderURIFallback,
          navigationRoutes.cardOrderNewExcel
        )
        break
      case orderType.Recharge:
        sessionStorage.setItem(
          browserStorageKeys.orderURIFallback,
          navigationRoutes.cardOrderRechargeExcel
        )
        break
      case orderType.Unload:
        sessionStorage.setItem(
          browserStorageKeys.orderURIFallback,
          navigationRoutes.cardOrderUnloadExcel
        )
        break
    }
  }

  const resetOrderContextAs = (orderContext: OrderContext): void => {
    setOrderContext(orderContext)
    if (orderContext.isExcelOrder) {
      HandleExcelRedirects(orderContext)
    }
  }

  // add a new variable for CardOrderContextProps
  const context = {
    orders,
    addOrder,
    removeOrder,
    editOrder,
    updateOrder,
    addOrUpdateOrders,
    getOrderById,
    clearOrders,
    setCardOrderData,
    cardOrderData,
    cardOrderCheckout,
    setCardOrderCheckout,
    clearAll,
    setFormMode,
    formParameters,
    setCardOrderDuplicates,
    getCardOrderDuplicates,
    setCardOrderRecharges,
    getCardOrderRecharges,
    setTRSelectedEmployees,
    getTRSelectedEmployees,
    setTREmployeesToConfig,
    getTREmployeesToConfig,
    setCardOrderUnload,
    getCardOrderUnload,
    clearCardOrderDuplicates,
    clearCardOrderRecharges,
    clearCardOrderUnload,
    clearOrderExcel,
    clearOrderCheckout,
    addOrders,
    removeRechargeOrder,
    removeUnloadOrder,
    getAllRechargeOrders,
    getAllUnloadOrders,
    setConfigurationMode,
    configurationParameters,
    setOrderExcel,
    orderExcel,
    isOrderExcel,
    orderExcelAlerts,
    setOrderExcelAlerts,
    base64File,
    setBase64File,
    base64FileAlerts,
    setBase64FileAlerts,
    orderContext,
    setOrderContext,
    resetOrderContextAs,
    clearByOrderType,
    leaveOrderEnabled,
    setLeaveOrderEnabled,
  }

  return <CardOrderContext.Provider value={context}>{children}</CardOrderContext.Provider>
}

export const useCardOrder = () => useContext(CardOrderContext)
