import { TFunction } from 'react-i18next'
import { StringSchema } from 'yup'
import { nonEmptyStringSchema } from 'src/Flex/Shared/ui/Form'
import { GetDateFormatted } from 'src/core/helpers'
import { forms } from 'src/domain/translations'

export const dateMinTest = (
  schema: StringSchema<string | undefined>,
  errorMessage: string,
  min: Date
): StringSchema<string | undefined> =>
  schema.test({
    name: 'can-not-be-before-min',
    exclusive: true,
    message: errorMessage,
    test: (value: string | undefined) => value === undefined || new Date(value) >= min,
  })

export const dateMaxTest = (
  schema: StringSchema<string | undefined>,
  errorMessage: string,
  max: Date
): StringSchema<string | undefined> =>
  schema.test({
    name: 'can-not-be-after-max',
    exclusive: true,
    message: errorMessage,
    test: (value: string | undefined) => value === undefined || new Date(value) <= max,
  })

export const dateSchema = (
  t: TFunction<'translation', undefined>,
  min = new Date('1900-01-01'),
  max = new Date('2100-01-01')
): StringSchema<string | undefined> =>
  dateMinTest(
    dateMaxTest(
      nonEmptyStringSchema(t),
      t(forms.errors.maxDate, {
        date: GetDateFormatted(max),
      }),
      max
    ),
    t(forms.errors.minDate, {
      date: GetDateFormatted(min),
    }),
    min
  )
