import { IncompleteOrderModel } from 'src/domain/models'
import {
  TGDChildModel,
  TGDEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import {
  CardOrderModel,
  EmployeeDataModel,
} from 'src/domain/models/cardOrder/CardOrderModel'

export const MapToTgdEmployeeAndChildren = (
  incompleteOrder: IncompleteOrderModel
): TGDEmployeeAndChildsModel[] => {
  // Get employeeSon from CardOrderModel array by employeeData.document
  const getEmployeeSons = (employeeData: EmployeeDataModel): TGDChildModel[] => {
    const cardOrder = incompleteOrder.cardOrders.filter(
      (employee: CardOrderModel) =>
        employee.employeeData.document === employeeData.document
    )
    const children: TGDChildModel[] = []

    cardOrder.forEach((employee: CardOrderModel) => {
      if (employee.employeeSon === undefined) return
      const child: TGDChildModel = {
        birthDate: employee.employeeSon?.birthDate ? employee.employeeSon?.birthDate : '',
        firstName: employee.employeeSon?.firstName ? employee.employeeSon?.firstName : '',
        firstName2: employee.employeeSon?.firstName2
          ? employee.employeeSon?.firstName2
          : '',
        kindergartenId: employee.employeeSon?.kindergartenId
          ? employee.employeeSon?.kindergartenId
          : 0,
        lastName: employee.employeeSon?.lastName ? employee.employeeSon?.lastName : '',
        lastName2: employee.employeeSon?.lastName2 ? employee.employeeSon?.lastName2 : '',
        initialAmount: employee.cardData?.initialAmount
          ? employee.cardData?.initialAmount
          : 0,
        kindergartenData: {
          id: employee.employeeSon?.kindergartenId
            ? employee.employeeSon?.kindergartenId
            : 0,
          city: employee.employeeSon?.kindergartenCity
            ? employee.employeeSon?.kindergartenCity
            : '',
          email: employee.employeeSon?.kindergartenEmail
            ? employee.employeeSon?.kindergartenEmail
            : '',
          latitude: employee.employeeSon?.kindergartenLatitude
            ? employee.employeeSon?.kindergartenLatitude
            : '',
          longitude: employee.employeeSon?.kindergartenLongitude
            ? employee.employeeSon?.kindergartenLongitude
            : '',
          name: employee.employeeSon?.kindergartenName
            ? employee.employeeSon?.kindergartenName
            : '',
          province: employee.employeeSon?.kindergartenProvince
            ? employee.employeeSon?.kindergartenProvince
            : '',
          streetDescription1: employee.employeeSon?.kindergartenStreetDescription1
            ? employee.employeeSon?.kindergartenStreetDescription1
            : '',
          streetDescription2: employee.employeeSon?.kindergartenStreetDescription2
            ? employee.employeeSon?.kindergartenStreetDescription2
            : '',
          streetName: employee.employeeSon?.kindergartenStreetName
            ? employee.employeeSon?.kindergartenStreetName
            : '',
          streetNumber: employee.employeeSon?.kindergartenStreetNumber
            ? employee.employeeSon?.kindergartenStreetNumber
            : '',
          streetType: employee.employeeSon?.kindergartenStreetType
            ? employee.employeeSon?.kindergartenStreetType
            : '',
          telephone: employee.employeeSon?.kindergartenTelephone
            ? employee.employeeSon?.kindergartenTelephone
            : '',
          zipCode: employee.employeeSon?.kindergartenZipCode
            ? employee.employeeSon?.kindergartenZipCode
            : '',
          finantialName: '',
          paymentDay1: 0,
          paymentDay2: 0,
          paymentDay3: 0,
          usesPaymentDay: false,
        },
      }
      children.push(child)
    })
    return children
  }
  const tgdEmployeesAndChilds: TGDEmployeeAndChildsModel[] = []

  incompleteOrder.cardOrders.forEach(cardOrder => {
    const exists = tgdEmployeesAndChilds.find(
      employee => employee.document === cardOrder.employeeData.document
    )
    if (!exists) {
      const employeeWithChilds: TGDEmployeeAndChildsModel = {
        id: Math.random().toString(),
        userId: Math.random(),
        childs: getEmployeeSons(cardOrder.employeeData),
        tgdOrderType: cardOrder.cardData.cardRequestTypeId
          ? cardOrder.cardData.cardRequestTypeId
          : 0,
        documentTypeId: cardOrder.employeeData.documentTypeId
          ? cardOrder.employeeData.documentTypeId.toString()
          : '',
        companyId: cardOrder.employeeData.companyId
          ? cardOrder.employeeData.companyId
          : 0,
        name: cardOrder.employeeData.name ? cardOrder.employeeData.name : '',
        firstSurname: cardOrder.employeeData.firstSurname
          ? cardOrder.employeeData.firstSurname
          : '',
        secondSurname: cardOrder.employeeData.secondSurname
          ? cardOrder.employeeData.secondSurname
          : '',
        birthDate: cardOrder.employeeData.birthDate
          ? cardOrder.employeeData.birthDate
          : '',
        telephone: cardOrder.employeeData.telephone
          ? cardOrder.employeeData.telephone
          : '',
        email: cardOrder.employeeData.email ? cardOrder.employeeData.email : '',
        employeeNumber: cardOrder.employeeData.employeeNumber
          ? cardOrder.employeeData.employeeNumber
          : '',
        document: cardOrder.employeeData.document ? cardOrder.employeeData.document : '',
        costCenter: cardOrder.employeeData.costCenter
          ? cardOrder.employeeData.costCenter
          : '',
      }
      tgdEmployeesAndChilds.push(employeeWithChilds)
    }
  })
  return tgdEmployeesAndChilds
}
