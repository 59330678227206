import { generalTermsType } from '../../../../domain/enum/generalTerms'
import type { GeneralTermsModel } from '../../../../domain/models/generalTerms/GeneralTermsModel'
import { Table } from '../../Edenred'
import { useGeneralTermsStrategiesController } from '../hooks/useGeneralTermsStrategiesController'

interface Props {
  generalTerms: GeneralTermsModel
}

export const PaymentAndInvoiceDataTable = ({ generalTerms }: Props): JSX.Element => {
  const { caption, rows, setRows } = useGeneralTermsStrategiesController(
    generalTermsType.paymentData,
    generalTerms
  )

  return <Table rows={rows} setRows={setRows} caption={caption} />
}
