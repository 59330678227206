import {
  OreButton,
  OreCardContainer,
  OreCheckbox,
  OreHeading,
  OreText,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { accountNotificationTranslation } from 'src/domain/translations/myAccount/accountNotification'
import { useAccountNotificationController } from '../hooks/useAccountNotificationController'
import { FormProvider } from 'react-hook-form'
import { MessageBar } from 'src/presentation/components/Edenred'

export const AccountNotification = (): JSX.Element => {
  const { t } = useTranslation()
  const { form, saveChanges, errorMessage } = useAccountNotificationController()

  return (
    <OreCardContainer>
      <div className="px-105">
        <div className="pt-105">
          <OreHeading as="h1" size="headline-sm">
            {t(accountNotificationTranslation.card.title)}
          </OreHeading>
        </div>

        <section className="form-molecule">
          <FormProvider {...form}>
            <form>
              <div className="form-atom float-left mt-1 inline-flex">
                <OreCheckbox
                  {...form.register('receiveInformation')}
                  label={
                    <OreText size="text-md">
                      {t(accountNotificationTranslation.card.checkDescription)}
                    </OreText>
                  }
                  name="receiveInformation"
                />
              </div>
            </form>
          </FormProvider>
        </section>
        {errorMessage && (
          <div className="pt-105">
            <MessageBar color="error" message={errorMessage} />
          </div>
        )}
        <div className="py-2">
          <OreButton as="button" category="primary" onClick={saveChanges} size="large">
            {t(accountNotificationTranslation.card.button)}
          </OreButton>
        </div>
      </div>
    </OreCardContainer>
  )
}
