import { Fragment } from 'react'
import type { FieldValues } from 'react-hook-form'
import {
  OreFormGroup,
  OreRadioButtonBox,
} from '@runroom/oreneta'
import { Seq } from 'immutable'
import { get } from 'lodash'
import type { RadioButtonGroupModel, RadioButtonModel } from '../../../domain/customComponents'

export const RadioButtonBox = <TFormValues extends FieldValues>(
  props: RadioButtonGroupModel<TFormValues>
): JSX.Element => {
  const errorMessages = get(props.errors, props.name)
  const hasError = !!(props.errors && errorMessages)
  return (
    <>
      <OreFormGroup
        direction={props.direction}
        label={props.label}
        required={props.required}
        hasError={hasError}
        errorMessage={errorMessages?.message}>
        <div className="flex flex--column gap-1">
          {Seq(props.radioButtons).map((radio: RadioButtonModel, index: number) => {
            return (
              <Fragment key={'fragment_' + index}>
                <OreRadioButtonBox
                  {...(props.register && props.register(props.name))}
                  id={'id_' + radio.value}
                  name={props.name}
                  subtitle={radio.subtitle}
                  label={radio.label}
                  value={radio.value}
                  onChange={props.onValueChange}
                  checked={props.selectedValue?.toString() === radio.value}
                  disabled={radio.disabled}
                />
              </Fragment>
            )
          })}
        </div>
      </OreFormGroup>
    </>
  )
}
