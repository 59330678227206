import {
  OreMessageBar,
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
} from '@runroom/oreneta'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import {
  TemplatesRow,
  useTemplates,
} from 'src/Flex/CommunicationsTemplates/ui/templates-list'
import { useTranslation } from 'react-i18next'
import { tableSelector } from 'src/domain/translations'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { DeleteModalActions, DeleteTemplateModal } from '../atoms'
import { useRef } from 'react'
import { TemplateModel } from 'src/Flex/CommunicationsTemplates/domain'
import { useUserData } from 'src/Flex/User/ui/context'

export const TemplatesList = (): JSX.Element => {
  const { t } = useTranslation()
  const { numberOfRecordsPerPage, templates, page, count, onPageChange } = useTemplates()
  const { flexData } = useUserData()
  const ref = useRef<DeleteModalActions>(null)

  const templatesHeaders: HeadersModel[] = [
    {
      key: 'name',
      label: t(flexCommunicationsTemplatesTranslations.table.header.name),
    },
    {
      key: 'type',
      label: t(flexCommunicationsTemplatesTranslations.table.header.type),
    },
    {
      key: 'description',
      label: t(flexCommunicationsTemplatesTranslations.table.header.description),
    },
    {
      key: 'date',
      label: t(flexCommunicationsTemplatesTranslations.table.header.date),
    },
    {
      key: 'actions',
      label: t(flexCommunicationsTemplatesTranslations.table.header.actions),
    },
  ]

  const handleDeleteTemplate = (template: TemplateModel): void => {
    ref?.current?.open(template)
  }

  return (
    <>
      <OreTableContainer>
        <OreTable>
          <OreTableCaption
            title={t(flexCommunicationsTemplatesTranslations.table.header.title)}
          />
          <OreTableHead valign="middle">
            <OreTableRow hover={false}>
              {templatesHeaders.map(
                (header: HeadersModel): JSX.Element => (
                  <OreTableCell as="th" key={header.key} size={header.size}>
                    <span style={{ whiteSpace: 'pre-line' }}>{header.label}</span>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody valign="top">
            {templates.map((template, index) => (
              <TemplatesRow
                key={index}
                template={template}
                deleteTemplate={handleDeleteTemplate}
              />
            ))}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <OrePagination
            displayPageButtons
            count={count}
            labelNextPage={t(tableSelector.nextPageLabel)}
            labelPreviousPage={t(tableSelector.previousPageLabel)}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={numberOfRecordsPerPage}
            text={t(tableSelector.pageOf)}
            legend={t(tableSelector.rowsShowing)}
          />
        </OreTableActions>
      </OreTableContainer>
      {flexData?.onboardingStep === 3 && (
        <OreMessageBar color="warning" icon>
          {t(flexCommunicationsTemplatesTranslations.table.warning)}
        </OreMessageBar>
      )}

      <DeleteTemplateModal ref={ref} />
    </>
  )
}
