import { useEffect, useState } from 'react'
import { myOrdersTabs } from '../../../../../domain/enum'

interface ContainerState {
  handleTabClick: (index: number) => void
  value: myOrdersTabs
}

export const useContainerController = (navigateValue?: myOrdersTabs): ContainerState => {
  const [value, setValue] = useState(myOrdersTabs.lastOrders)
  const handleTabClick = (index: number): void => setValue(index)
  useEffect(() => {
    if (navigateValue) setValue(navigateValue)
  }, [navigateValue])
  return { handleTabClick, value }
}
