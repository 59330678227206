import { OreNavBarUser, OreStack, OreText, SvgCompany } from '@runroom/oreneta'
import { useState } from 'react'
import { useAuth } from '../../../../../context/auth/AuthProvider'
import { Grid } from '../../../layout'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { MultiCIFModel } from 'src/domain/models/user/userMultiCIF'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'

export interface NavbarUserListItemModel {
  title: string
  icon?: JSX.Element
  link?: string
  handleClick?: () => void
  size?: 'text-sm' | 'text-xs'
  divider?: boolean
}

const MultiCIF = (): JSX.Element => {
  const [openUser, setOpenUser] = useState(false)
  const { user } = useAuth()
  const { clientsMultiCIF, updateMultiCIF } = useUser()
  const { startLoading, stopLoading } = useLoader()

  const onUserChange = (code: number) => {
    startLoading()
    updateMultiCIF(code)
      .then(result => {
        if (result) window.location.href = navigationRoutes.clients
      })
      .finally(() => stopLoading())
  }

  return (
    <div
      onMouseEnter={() => setOpenUser(true)}
      onMouseLeave={() => setOpenUser(false)}
      className="wide-ore-navbar-user"
      style={{ padding: '15px 0' }}>
      <OreStack direction="row" space="xsmall">
        <div
          className={clientsMultiCIF && clientsMultiCIF.length > 1 ? 'hover-cursor' : ''}>
          <Grid direction="column">
            <OreText tone="neutral-500" bold>
              {user?.name}
            </OreText>
            <OreText size="text-sm">{user?.cif + ' - ' + user?.code}</OreText>
          </Grid>
        </div>
        {clientsMultiCIF && clientsMultiCIF.length > 1 && (
          <OreNavBarUser open={openUser}>
            {clientsMultiCIF
              .filter(user => !user.active)
              .map((user: MultiCIFModel, index: number): JSX.Element => {
                return (
                  <div
                    className="multicif-navbar"
                    onClick={() => onUserChange(user.code)}
                    key={index}>
                    <OreStack direction="row" space="xsmall" placeItems="center">
                      <SvgCompany />
                      <OreStack direction="column" space="2xsmall">
                        <OreText bold>{user.name}</OreText>
                        <OreText size="text-xs">{user.cif + ' - ' + user.code}</OreText>
                      </OreStack>
                    </OreStack>
                  </div>
                )
              })}
          </OreNavBarUser>
        )}
      </OreStack>
    </div>
  )
}

export default MultiCIF
