import { MouseEvent, forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { HeadersModel } from 'src/domain/models/tables/Headers'
import ISalud from 'src/presentation/assets/logo/isalud.png'
import {
  OreButton,
  OreMessageBar,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  OreStack,
  OreCardContainer,
  OreCardTitle,
  SvgInternalHealth,
  OreIcon,
} from '@runroom/oreneta'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'

import {
  useConfigureHealthInsuranceController,
  FlexHealthProductRow,
  ConfigureModalActions,
  HealthInsuranceForm,
} from 'src/Flex/Products/ui/product-configuration'
import type { HealthProductModel } from 'src/Flex/Products/domain'
import { textToPdf } from 'src/core/helpers'
import { FormProvider } from 'react-hook-form'
import { forms } from 'src/domain/translations'
import { Divider } from 'src/presentation/layout'

export const ConfigureHealthInsuranceModalForm = forwardRef<ConfigureModalActions>(
  (_, ref): JSX.Element => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)

    const { model, load, downloadConditions, getPolicyIcon, form, save, isGlobal } =
      useConfigureHealthInsuranceController()

    form.watch('policies')

    const handleDownloadConditions = (id: string, name: string): void => {
      downloadConditions(id).then(response => {
        if (response)
          textToPdf(
            response,
            t(flexProductsTranslations.configure.health.table.header.conditions) +
              ' ' +
              name
          )
      })
    }

    const close = (): void => {
      setOpen(false)
    }

    const saveAndClose = (event: MouseEvent): void => {
      event.preventDefault()

      form.handleSubmit(async (data: HealthInsuranceForm) => {
        const result = await save({ policies: data.policies })
        if (result) {
          setOpen(false)
        }
      })()
    }

    useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
      open: (): void => {
        load().then(() => {
          setOpen(true)
        })
      },
      close,
    }))

    const productHeaders: HeadersModel[] = [
      {
        key: 'company',
        label: t(flexProductsTranslations.configure.health.table.header.company),
      },
      {
        key: 'modality',
        label: t(flexProductsTranslations.configure.health.table.header.modality),
      },
      {
        key: 'monthlyPrice',
        label: t(flexProductsTranslations.configure.health.table.header.monthlyImport),
      },
      {
        key: 'conditions',
        label: t(flexProductsTranslations.configure.health.table.header.conditions),
      },
    ]

    if (model?.length && 'active' in model[0]) {
      productHeaders.unshift({
        key: 'state',
      })
    }

    return (
      <OreModal open={isOpen}>
        <OreModalBox size="large" handleOnClose={(): void => setOpen(false)}>
          <OreModalBoxBody noGap>
            <OreCardContainer hasShadow={false}>
              <FormProvider {...form}>
                <form>
                  <OreCardTitle
                    icon={
                      <OreIcon
                        size="large"
                        icon={<SvgInternalHealth />}
                        tone="flex-products"
                      />
                    }
                    title={t(flexProductsTranslations.configure.health.title)}
                  />
                  <Divider marginTop="1rem" marginBottom="2rem" />
                  <OreStack placeContent="stretch" space="large">
                    <OreStack>
                      <OreText tone="neutral-600">
                        {t(flexProductsTranslations.configure.health.table.legend)}
                      </OreText>
                      <OreTableContainer>
                        <OreTable>
                          <OreTableCaption
                            title={t(flexProductsTranslations.configure.health.subtitle)}
                          />
                          <OreTableHead valign="middle">
                            <OreTableRow>
                              {productHeaders.map((header: HeadersModel) => (
                                <OreTableCell as="th" key={header.key}>
                                  {header.label}
                                </OreTableCell>
                              ))}
                            </OreTableRow>
                          </OreTableHead>

                          <OreTableBody valign="top">
                            {model?.length &&
                              model.map((policy: HealthProductModel) => (
                                <FlexHealthProductRow
                                  key={policy.id}
                                  policy={policy}
                                  downloadConditions={() =>
                                    handleDownloadConditions(policy.id, policy.modality)
                                  }
                                  getPolicyIcon={getPolicyIcon}
                                />
                              ))}
                          </OreTableBody>
                        </OreTable>
                      </OreTableContainer>
                    </OreStack>
                    <OreStack space="2xsmall" direction="row">
                      <OreText>
                        {t(flexProductsTranslations.configure.health.isalud.pre)}
                      </OreText>
                      <img style={{ marginBottom: '5px' }} src={ISalud} alt="isalud" />
                      <OreText>
                        {t(flexProductsTranslations.configure.health.isalud.post)}
                        &nbsp;
                        <a
                          href={`tel: ${t(
                            flexProductsTranslations.configure.health.isalud.tlf
                          )}`}>
                          {t(flexProductsTranslations.configure.health.isalud.tlf)}
                        </a>
                      </OreText>
                    </OreStack>
                    <div>
                      {isGlobal ? (
                        <OreMessageBar color="warning" icon={false}>
                          {t(flexProductsTranslations.table.bottom.legend)}
                        </OreMessageBar>
                      ) : (
                        <OreText size="text-sm">
                          {t(flexProductsTranslations.table.bottom.legend)}
                        </OreText>
                      )}
                    </div>
                    {form.formState.isDirty ? (
                      <OreMessageBar color="warning">
                        {form.getValues('policies').length
                          ? t(
                              flexProductsTranslations.configure.common.collectivesChanges
                            )
                          : model?.length && 'active' in model[0]
                          ? t(
                              flexProductsTranslations.configure.health.form.error
                                .noPolicies
                            )
                          : null}
                      </OreMessageBar>
                    ) : null}
                  </OreStack>
                </form>
              </FormProvider>
              <div className="pt-05"></div>
            </OreCardContainer>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            {model?.length && 'active' in model[0] ? (
              <>
                <OreButton onClick={close} size="small" category="secondary">
                  {t(forms.buttons.cancel)}
                </OreButton>
                <OreButton
                  type="submit"
                  onClick={saveAndClose}
                  size="small"
                  category="primary">
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              </>
            ) : (
              <OreButton onClick={close} size="small" category="primary">
                {t(flexProductsTranslations.configure.common.close)}
              </OreButton>
            )}
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
