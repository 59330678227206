import React from 'react'
import { useLocation } from 'react-router-dom'
import { MessagesModel } from 'src/domain/models'
import { TGDOrderCompleteError } from '../molecules/TGDOrderCompleteError'
import { TGDOrderCompleteSuccess } from '../molecules/TGDOrderCompleteSuccess'
import { TGDOrderCompleteModel } from 'src/domain/models/TGDOrder/TGDOrderModel'

export interface TGDOrderCompleteState {
  success: boolean
  errorMessage: MessagesModel[]
  data: TGDOrderCompleteModel
  isExcel: boolean
  excel: string
}

export const TGDOrderCompleteContainer = (): JSX.Element => {
  const location = useLocation()
  const orderCompleteData = location.state as TGDOrderCompleteState

  return (
    <>
      {orderCompleteData.success && (
        <TGDOrderCompleteSuccess orderCompleteData={orderCompleteData.data} />
      )}
      {!orderCompleteData.success && (
        <TGDOrderCompleteError
          errorMessages={orderCompleteData.errorMessage}
          excel={orderCompleteData.excel}
          isExcel={orderCompleteData.isExcel}
        />
      )}
    </>
  )
}
