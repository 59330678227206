import { useState, useCallback, useContext } from 'react'
import { Notification, NotificationContext, Severity } from './NotificationContext'
import { NotificationSeverity } from 'src/domain/enum'

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [notification, setNotification] = useState<Notification>({
    message: '',
    severity: NotificationSeverity.success,
  })

  const addNotification = (message: string, severity: Severity) =>
    setNotification({ message, severity })

  const removeNotification = () =>
    setNotification({
      ...notification,
      message: '',
      severity: NotificationSeverity.success,
    })

  const value = {
    notification,
    addNotification: useCallback(
      (message: string, severity: Severity) => addNotification(message, severity),
      []
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  }

  return (
    <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)
