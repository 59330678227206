import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { getFirstDayOfMonthOneYearBefore, getToday } from 'src/core/helpers'
import { HistorySpendingService } from 'src/core/services/dietSpendingService'
import { HeaderModel } from 'src/domain/customComponents/table'
import { NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import {
  AssignmentsModel,
  AssignmentsRequest,
} from 'src/domain/models/historySpendings/AssignmentsModel'
import { assignmentsTranslations } from 'src/domain/translations/history/assignments'

export interface AssignmentsState {
  header: HeaderModel
  totalRows: number
  assignments: AssignmentsModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  search(filter: AssignmentsRequest): void
  downloadExcel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onEnter: (event: KeyboardEvent<HTMLInputElement>) => void
  form: UseFormReturn<AssignmentsRequest, any>
  onSearch: () => void
  clearFilters: () => void
  showMoreFilters: boolean
  setShowMoreFilters: Dispatch<SetStateAction<boolean>>
}

export const useAssingments = (): AssignmentsState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [assignments, setAssignments] = useState<AssignmentsModel[]>([])
  const { addNotification } = useNotification()
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false)

  const defaultValues: AssignmentsRequest = {
    productCode: edenredProducts.ticketGuarderia,
    beginDate: getFirstDayOfMonthOneYearBefore(),
    endDate: getToday(),
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  }

  const [query, setQuery] = useState<AssignmentsRequest>({ ...defaultValues })

  const header: HeaderModel = {
    headerLabel: [
      { label: t(assignmentsTranslations.table.header.employee) },
      { label: t(assignmentsTranslations.table.header.child) },
      { label: t(assignmentsTranslations.table.header.kindergartenAndDate) },
      { label: t(assignmentsTranslations.table.header.assignmentDate) },
      { label: t(assignmentsTranslations.table.header.amount) },
    ],
  }

  const getAssignments = (): void => {
    startLoading()
    HistorySpendingService()
      .AssignmentsList(query)
      .then(response => {
        if (response && response.data && response.data.rows) {
          setAssignments(response.data.rows)
          setTotalRows(response.data.totalRows)
        }
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const search = (filters: AssignmentsRequest): void => {
    setQuery({
      ...filters,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const downloadExcel = (): void => {
    startLoading()
    HistorySpendingService()
      .GetAssignmentsExcel(query)
      .then(response => {
        if (response?.meta?.status === 'SUCCESS') {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = 'Guardería_Historial.xlsx'
          link.click()
          link.remove()
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const formSchema = Yup.object({
    productCode: Yup.number(),
    beginDate: Yup.string().required(t(forms.errors.fieldRequired)),
    endDate: Yup.string().required(t(forms.errors.fieldRequired)),
    beginHour: Yup.string().required(t(forms.errors.fieldRequired)),
    endHour: Yup.string().required(t(forms.errors.fieldRequired)),
  })

  const form = useForm<AssignmentsRequest>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      productCode: edenredProducts.ticketGuarderia,
      beginDate: getFirstDayOfMonthOneYearBefore(),
      endDate: getToday(),
    },
  })

  const onSearch = (): void => {
    const filters: AssignmentsRequest = {
      productCode: edenredProducts.ticketGuarderia,
      multiFilter: form.getValues('multiFilter'),
      beginDate: form.getValues('beginDate'),
      endDate: form.getValues('endDate'),
    }
    search(filters)
  }

  const clearFilters = (): void => {
    form.setValue('multiFilter', defaultValues.multiFilter)
    form.setValue('beginDate', defaultValues.beginDate)
    form.setValue('endDate', defaultValues.endDate)
    //TODO: A revisar con negocio si hace falta ocultar los filtros al limpiar
    // setShowMoreFilters(false)
  }

  useEffect(() => {
    getAssignments()
  }, [query])

  return {
    header,
    totalRows,
    assignments,
    page,
    setPage,
    pageSize,
    onPageChange,
    search,
    downloadExcel,
    onEnter,
    form,
    onSearch,
    clearFilters,
    showMoreFilters,
    setShowMoreFilters,
  }
}
