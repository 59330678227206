import { Modal } from 'src/presentation/components/Edenred'
import { useTTOrderImportExcel } from '../hooks/useTTOrderImportExcel'
import { TTOrderImportForm } from '../molecules/TTOrderImportForm'
import { TTOrderStoppersAndWarnings } from '../molecules/TTOrderStoppersAndWarnings'
import { OreHeading, OreText } from '@runroom/oreneta'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'

export const TTOrderImportExcelContainer = (): JSX.Element => {
  const props = useTTOrderImportExcel()
  const { t } = useTranslation()

  return (
    <>
      {props.hasStoppersOrWarnings ? (
        <TTOrderStoppersAndWarnings props={props} />
      ) : (
        <TTOrderImportForm props={props} />
      )}
      <Modal
        handleOnClose={props.setShowCancelModal}
        open={props.showCancelModal}
        buttons={props.cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {t(TGDOrderTranslation.form.child.cancelModalTitle)}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {t(TGDOrderTranslation.form.child.cancelModalDescription)}
          </OreText>
        </>
      </Modal>
    </>
  )
}
