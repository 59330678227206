import { Location } from 'react-router-dom'
import {
  Routes,
  flexNavigationRoutes,
  navigationRoutes,
} from 'src/config/constants/navigationRoutes'

const isRoute = (location: Location, routes: Routes): boolean =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(routes).some(([_, value]) => {
    const regex = new RegExp(`^${value.replaceAll(/:\w+/g, '[^/]+')}$`)

    return regex.test(location.pathname)
  })

export const isFlexRoute = (location: Location): boolean =>
  isRoute(location, flexNavigationRoutes)

export const isGeneralRoute = (location: Location): boolean =>
  isRoute(location, navigationRoutes)
