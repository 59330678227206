import { useTranslation } from 'react-i18next'
import { OreButton, OreDivider, OreStack, SvgArrowWidget } from '@runroom/oreneta'
import {
  useOrderValidation,
  OrderValidationSummaryDetail,
  OrderValidationSummaryGeneral,
  OrderValidationSummaryDelivery,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'
import { Dispatch, SetStateAction } from 'react'

export const OrderValidationStepTwo = (props: {
  setStepError: Dispatch<SetStateAction<number>>
}): JSX.Element => {
  const { orderSummary, back, confirm, form } = useOrderValidation()
  const { t } = useTranslation()

  if (!orderSummary) {
    return <></>
  }

  return (
    <div>
      <OreStack space="xlarge" placeContent="stretch">
        <OreStack
          direction="row"
          space="xlarge"
          placeItems="start"
          placeContent="stretch"
          sameSize>
          <OrderValidationSummaryGeneral
            name={t(orderValidationsTranslations.step2.general.title)}
            detail={orderSummary.detail}
            form={form}
          />
          <OrderValidationSummaryDelivery
            card={orderSummary.card}
            charge={orderSummary.charge}
          />
        </OreStack>
        {orderSummary.card.services.length > 0 && (
          <OrderValidationSummaryDetail
            counter={`${orderSummary.card.quantity} ${t(
              orderValidationsTranslations.step2.detail.counterCard
            )}`}
            name={t(orderValidationsTranslations.step2.detail.titleCard)}
            detail={orderSummary.card}
          />
        )}
        {orderSummary.charge.services.length > 0 && (
          <OrderValidationSummaryDetail
            counter={`${orderSummary.charge.quantity} ${t(
              orderValidationsTranslations.step2.detail.counterRecharge
            )}`}
            name={t(orderValidationsTranslations.step2.detail.titleRecharge)}
            detail={orderSummary.charge}
          />
        )}
      </OreStack>
      <OreDivider space="larger-top" />
      <OreStack direction="row" placeContent="space-between">
        <OreButton category="tertiary" icon={<SvgArrowWidget />} onClick={back}>
          {t(orderValidationsTranslations.step2.buttonBack)}
        </OreButton>
        <OreButton category="primary" onClick={confirm}>
          {t(orderValidationsTranslations.step2.buttonConfirm)}
        </OreButton>
      </OreStack>
    </div>
  )
}
