export enum DeleteAccountReason {
  noReason = 0,
  changeManager = 1,
  finishWorkInCompany = 2,
  finishColaborationWithEdenred = 3,
  other = 4,
}

export enum LegalDocumentType {
  useWebConditions = 1,
  legalAdvice = 2,
  privacyPolicies = 3,
  cookiesPolicies = 4,
  flexPrivacyPolicy = 6,
  flexTemplate = 7,
}

export enum EmployeeDocumentType {
  signedNovationContract = 1,
  signedNovationContractCertificate = 2,
}

export enum UserAndLicensesProfiles {
  viewer = 1,
  admin = 2,
  superAdmin = 3,
}

export enum UserAndLicensesProfilesNames {
  viewer = 'Visor',
  admin = 'Admin',
  superAdmin = 'SuperAdmin',
}
