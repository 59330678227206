export const dropZoneTranslations = {
  validation: {
    fileExists: 'flex.dropZone.validation.file_exists',
    fileFormat: 'flex.dropZone.validation.file_format',
    fileSize: 'flex.dropZone.validation.file_size',
  },
  modal: {
    title: 'flex.dropZone.modal.title',
    subtitle: 'flex.dropZone.modal.subtitle',
  },
  delete: 'flex.dropZone.delete',
  error: 'flex.dropZone.error',
  format: 'flex.dropZone.format',
  formatOr: 'flex.dropZone.format_or',
  valid: 'flex.dropZone.valid',
  select: 'flex.dropZone.select',
  change: 'flex.dropZone.change',
  drag: 'flex.dropZone.drag',
  dragNew: 'flex.dropZone.drag_new',
}
