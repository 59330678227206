import {
  OreCardContainer,
  OreCardWrapper,
  OreHeading,
  OreStack,
  OreText,
} from '@runroom/oreneta'
import { KPI as KPIModel, KPIUnit } from 'src/Flex/Reporting/domain'
import { agregarSeparadorMiles, currency, number } from 'src/core/helpers'

type KPIProps = {
  kpi: KPIModel
}

const convertKPIValue = (kpi: KPIModel): string => {
  switch (kpi.units) {
    case KPIUnit.Currency:
      return agregarSeparadorMiles(currency(kpi.value))
    case KPIUnit.Percentage:
      return `${kpi.value}%`
    case KPIUnit.Number:
      return number(kpi.value)
  }
}

export const KPI = ({ kpi }: KPIProps): JSX.Element => {
  return (
    <OreCardContainer>
      <OreCardWrapper space="large">
        <OreStack space="2xsmall">
          <OreText tone="neutral-900">{kpi.name}</OreText>
          <OreHeading size="title-sm">{convertKPIValue(kpi)}</OreHeading>
        </OreStack>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
