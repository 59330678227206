import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { deliverySiteService } from '../../../core/services'
import { MetaStatusCodes, NotificationSeverity } from '../../../domain/enum'
import type { DeliverySiteModel } from '../../../domain/models/deliverySites'
import { useLoader } from '../../context/loader/LoaderProvider'
import { useDeliversySiteSchema } from '../../validations'
import { navigationRoutes } from '../../../config/constants/navigationRoutes'
import { useNotificationHandler } from '../../hooks'
import { MessagesModel } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

export const useDeliverySiteForm = (productCode: number) => {
  const { deliverySiteSchema } = useDeliversySiteSchema()
  const form = useForm<DeliverySiteModel>({
    resolver: yupResolver(deliverySiteSchema),
    mode: 'onChange',
    shouldFocusError: true,
  })

  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [showNoPhoneWarning, setStateShowNoPhoneModal] = useState(false)
  const [errorMessage, setStateErrorMessage] = useState('')

  const onNoPhoneConfirmation = (isConfirmed: boolean) => {
    if (isConfirmed) {
      addDeliverySite(form.getValues())
    }
    setStateShowNoPhoneModal(false)
  }

  const onNoPhoneUpdateConfirmation = (isConfirmed: boolean) => {
    if (isConfirmed) {
      updateDeliverySite(form.getValues())
    }
    setStateShowNoPhoneModal(false)
  }

  const onModalNoPhoneConfirmation = (
    isConfirmed: boolean,
    type: 'new' | 'edit' | undefined,
    setShowModal?: Dispatch<SetStateAction<boolean>>,
    setNewDeliverySite?: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  ) => {
    if (isConfirmed && setShowModal && setNewDeliverySite) {
      addModalDeliverySite(form.getValues(), type, setShowModal, setNewDeliverySite)
    }
    setStateShowNoPhoneModal(false)
  }

  const [showAbandonWarning, setShowShowAbandonModal] = useState(false)
  const onAbandonConfirmation = (isAbandonConfirmed: boolean) => {
    if (isAbandonConfirmed) navigate(-1)

    setShowShowAbandonModal(false)
  }

  const onModalAbandonConfirmation = (
    isAbandonConfirmed: boolean,
    setShowModal?: Dispatch<SetStateAction<boolean>>
  ) => {
    if (isAbandonConfirmed && setShowModal) setShowModal(false)
    setShowShowAbandonModal(false)
  }

  const onSubmitWarnings = (newDeliverySite: DeliverySiteModel) => {
    addDeliverySiteWithWarnings(newDeliverySite)
  }

  const onModalSubmitWarnings = (
    type: 'new' | 'edit' | undefined,
    setShowModal?: Dispatch<SetStateAction<boolean>>,
    setNewDeliverySite?: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  ) => {
    if (setShowModal && setNewDeliverySite)
      addModalDeliverySiteWithWarnings(
        form.getValues(),
        type,
        setShowModal,
        setNewDeliverySite
      )
  }

  const onUpdateSubmitWarnings = (DeliverySite: DeliverySiteModel) => {
    updateDeliverySiteWithWarnings(DeliverySite)
  }

  const { startLoading, stopLoading } = useLoader()
  const { notificationHandler } = useNotificationHandler()

  async function addDeliverySite(newDeliverySite: DeliverySiteModel) {
    // Fix payload
    const deliverySitePayload = {
      ...newDeliverySite,
      contactType: Number(newDeliverySite.contactType),
      deliveryType: Number(newDeliverySite.deliveryType),
      productTypeId: productCode,
    }

    // API request
    startLoading()
    const { meta } = await deliverySiteService().add(deliverySitePayload)
    stopLoading()

    // Handle API response
    if (meta.status === MetaStatusCodes.SUCCESS) {
      notificationHandler(meta, true, null)
      navigate(-1)
    } else {
      // has any message?
      if (meta.messages.length > 0) {
        meta?.messages.forEach(error => {
          if (error.value) {
            form.setError(error.value as any, {
              type: 'value',
              message: error.description,
            })
          } else {
            setStateErrorMessage(meta?.messages[0].description)
          }
        })
      }
    }
  }

  async function addModalDeliverySite(
    newDeliverySite: DeliverySiteModel,
    type: 'new' | 'edit' | undefined,
    setShowModal: Dispatch<SetStateAction<boolean>>,
    setNewDeliverySite: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  ) {
    // Fix payload
    const deliverySitePayload = {
      ...newDeliverySite,
      contactType: Number(newDeliverySite.contactType),
      deliveryType: Number(newDeliverySite.deliveryType),
      productTypeId: productCode,
    }

    // API request
    startLoading()
    const { meta } =
      type === 'new'
        ? await deliverySiteService().add(deliverySitePayload)
        : await deliverySiteService().update(deliverySitePayload)
    stopLoading()

    // Handle API response
    if (meta.status === MetaStatusCodes.SUCCESS) {
      setNewDeliverySite(deliverySitePayload)
      setShowModal(false)
    } else {
      if (meta.messages.length > 0) {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
        meta?.messages.forEach((error: MessagesModel) => {
          if (error.value) {
            form.setError(error.value as any, {
              type: 'value',
              message: error.description,
            })
          } else {
            // has any message?
            const description = meta.messages[0].description
            const errorField = meta.messages[0].value
            let errorNotification = description

            if (errorField !== null) errorNotification += ': ' + errorField

            setStateErrorMessage(errorNotification)
          }
        })
      }
    }
  }

  const addDeliverySiteWithWarnings = async (newDeliverySite: DeliverySiteModel) => {
    if (!newDeliverySite.telephone) setStateShowNoPhoneModal(true)
    else await addDeliverySite(newDeliverySite)
  }

  const addModalDeliverySiteWithWarnings = async (
    newDeliverySite: DeliverySiteModel,
    type: 'new' | 'edit' | undefined,
    setShowModal: Dispatch<SetStateAction<boolean>>,
    setNewDeliverySite: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  ) => {
    if (!newDeliverySite.telephone) setStateShowNoPhoneModal(true)
    else
      await addModalDeliverySite(newDeliverySite, type, setShowModal, setNewDeliverySite)
  }

  async function updateDeliverySite(newDeliverySite: DeliverySiteModel) {
    // Fix payload
    const deliverySitePayload = {
      ...newDeliverySite,
      contactType: Number(newDeliverySite.contactType),
      deliveryType: Number(newDeliverySite.deliveryType),
      productTypeId: productCode,
    }

    // API request
    startLoading()
    const { meta } = await deliverySiteService().update(deliverySitePayload)
    stopLoading()

    // Handle API response
    if (meta.status === MetaStatusCodes.SUCCESS) {
      notificationHandler(meta, true, null)
      navigate(-1)
    } else {
      // has any message?
      if (meta.messages.length > 0) {
        const description = meta.messages[0].description
        const errorField = meta.messages[0].value
        let errorNotification = description

        if (errorField !== null) errorNotification += ': ' + errorField

        setStateErrorMessage(errorNotification)
      }
    }
  }

  const updateDeliverySiteWithWarnings = async (newDeliverySite: DeliverySiteModel) => {
    if (!newDeliverySite.telephone) setStateShowNoPhoneModal(true)
    else await updateDeliverySite(newDeliverySite)
  }

  return {
    form,
    t,
    errorMessage,
    showAbandonWarning,
    setShowShowAbandonModal,
    onAbandonConfirmation,
    showNoPhoneWarning,
    setStateShowNoPhoneModal,
    onNoPhoneConfirmation,
    addDeliverySite,
    addDeliverySiteWithWarnings,
    onSubmitWarnings,
    onUpdateSubmitWarnings,
    onNoPhoneUpdateConfirmation,
    onModalSubmitWarnings,
    onModalNoPhoneConfirmation,
    onModalAbandonConfirmation,
  }
}
