import React from 'react'
import { useLocation } from 'react-router-dom'
import { MessagesModel } from 'src/domain/models'
import { TGDBasicOrderCompleteError } from '../molecules/TGDBasicOrderCompleteError'
import { TGDBasicOrderCompleteSuccess } from '../molecules/TGDBasicOrderCompleteSuccess'
import { TGDOrderCompleteModel } from 'src/domain/models/TGDOrder/TGDOrderModel'

export interface TGDBasicOrderCompleteState {
  success: boolean
  errorMessage: MessagesModel[]
  data: TGDOrderCompleteModel
  isExcel: boolean
  excel: string
}

export const TGDBasicOrderCompleteContainer = (): JSX.Element => {
  const location = useLocation()
  const orderCompleteData = location.state as TGDBasicOrderCompleteState

  return (
    <>
      {orderCompleteData.success && (
        <TGDBasicOrderCompleteSuccess orderCompleteData={orderCompleteData.data} />
      )}
      {!orderCompleteData.success && (
        <TGDBasicOrderCompleteError
          errorMessages={orderCompleteData.errorMessage}
          excel={orderCompleteData.excel}
          isExcel={orderCompleteData.isExcel}
        />
      )}
    </>
  )
}
