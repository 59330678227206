import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './presentation/styles/styles.scss'
import TagManager from 'react-gtm-module'
import { IsTgmEnabled } from './core/services/ToggleFeatureService'

if(IsTgmEnabled()){
  const tagManagerArgs = {
    gtmId: 'GTM-NW8QZCN'
  }
  TagManager.initialize(tagManagerArgs)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
