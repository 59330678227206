import { t } from 'i18next'
import type { RowModel } from '../../../../domain/customComponents/table'
import type { CardOrderModel } from '../../../../domain/models'
import {
  forms,
  rechargesConfigurationTranslations,
} from '../../../../domain/translations'
import { formatString } from '../../utils'
import { currency, emptyValues } from 'src/domain/enum'

export const ErrorValidRechargeValue = (recharge: string | undefined): boolean => {
  if (recharge === '' || recharge === undefined) {
    return true
  }

  if (isNaN(Number(recharge))) return true

  if (Number(recharge) < 0.01 || Number(recharge) > 5000) {
    return true
  }

  if (Number(recharge) % 1 !== 0) {
    const rechargeDecimals = recharge.split('.' || ',')[1]
    if (rechargeDecimals.length > 2) return true
  }

  return false
}

export const MessageValidRechargeValue = (
  recharge: string | undefined
): string | undefined => {
  if (recharge === '' || recharge === undefined) {
    return t(forms.errors.fieldRequired)
  }

  if (isNaN(Number(recharge)))
    return t(rechargesConfigurationTranslations.table.rows.recharge.validation.numeric)

  if (Number(recharge) < 0.01 || Number(recharge) > 5000) {
    return t(rechargesConfigurationTranslations.table.rows.recharge.validation.amount)
  }

  if (Number(recharge) % 1 !== 0) {
    const rechargeDecimals = recharge.split('.' || ',')[1]
    if (rechargeDecimals.length > 2)
      return t(
        rechargesConfigurationTranslations.table.rows.recharge.validation
          .amountTwoDecimalsMax
      )
  }

  return undefined
}

export const FillRechargeConfigurationRows = (
  cardOrder: CardOrderModel[]
): RowModel[] => {
  const rechargeRows: RowModel[] = []
  cardOrder.forEach((cardOrder: CardOrderModel) => {
    const rechargeRow: RowModel = {
      rowId: cardOrder.orderId,
      cells: [
        {
          type: 'text',
          cellText: [
            {
              text:
                formatString(cardOrder.employeeData.name) +
                ' ' +
                (cardOrder.employeeData.firstSurname
                  ? formatString(cardOrder.employeeData.firstSurname)
                  : '') +
                (cardOrder.employeeData.secondSurname
                  ? formatString(cardOrder.employeeData.secondSurname)
                  : ''),
              name: 'name',
              tone: 'neutral-900',
              size: 'text-sm',
              isBold: true,
            },
            {
              text: formatString(cardOrder.employeeData.document),
              name: 'idCard',
              size: 'text-sm',
            },
          ],
        },
        {
          type: 'text',
          cellText: [
            {
              text:
                cardOrder.cardData.balance &&
                cardOrder.cardData.balance !== undefined &&
                cardOrder.cardData.balance !== null &&
                cardOrder.cardData.balance !== emptyValues.dash
                  ? cardOrder.cardData.balance.toString().replace(currency.euro, '') + // Fix backend issue 0€
                    currency.euro
                  : emptyValues.dash,
              name: 'balance',
              size: 'text-sm',
            },
          ],
        },
        {
          type: 'textBox',
          cellControll: {
            name: 'recharge',
            type: 'number',
            placeHolder: t(
              rechargesConfigurationTranslations.table.rows.recharge.placeHolder
            ),
            value: formatString(cardOrder.cardData.initialAmount?.toString()),
            startAdornment: '€',
            errorValidate: ErrorValidRechargeValue,
            massageErrorValidate: MessageValidRechargeValue,
          },
        },
        {
          type: 'textBox',
          cellControll: {
            name: 'costs',
            type: 'text',
            placeHolder: t(rechargesConfigurationTranslations.table.rows.costs),
            value: formatString(cardOrder.employeeData.costCenter),
          },
        },
      ],
    }
    rechargeRows.push(rechargeRow)
  })
  return rechargeRows
}
