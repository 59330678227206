import type {
  ExcelTemplateRequest,
  LoadExcelOrder,
  validateOrderExcelRequest,
} from '../../domain/bodyRequest/ExcelOrders'
import type {
  ExcelTemplateModel,
  HttpModel,
  ValidateExcelCheckoutResponse,
} from '../../domain/models'
import type { HttpRequest } from '../../domain/protocols';
import { HttpMethod } from '../../domain/protocols'
import {
  createOrderExcel,
  indexOrderTemplate,
  validateOrderExcel,
} from '../../config/constants/endpoints'
import { axiosFetch } from '../axios'

export class ExcelRepository {
  async getTemplate(
    excelTemplateRequest: ExcelTemplateRequest
  ): Promise<HttpModel<ExcelTemplateModel>> {
    const { url, method, body }: HttpRequest = {
      url: indexOrderTemplate,
      method: HttpMethod.POST,
      body: excelTemplateRequest,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async validateOrder(
    validateOrder: validateOrderExcelRequest
  ): Promise<HttpModel<ValidateExcelCheckoutResponse>> {
    const { url, method, body }: HttpRequest = {
      url: validateOrderExcel,
      method: HttpMethod.POST,
      body: validateOrder,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async confirmOrder(loadExcelOrder: LoadExcelOrder): Promise<HttpModel<any>> {
    const { url, method, body }: HttpRequest = {
      url: createOrderExcel,
      method: HttpMethod.POST,
      body: loadExcelOrder,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
