import { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { flexPayrollsTranslations } from '../../translations'
import {
  PayrollColumnEnum,
  PayrollColumnNameEnum,
  PayrollCustomModel,
} from 'src/Flex/Payrolls/domain/PayrollCustomModel'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { payrollsService } from 'src/Flex/Payrolls/application'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

export interface IUseFlexPayrollCustomController {
  columns: PayrollColumnInterface[]
  addNewUser: (columnName: number) => void
  onDragEnd: (args: any) => void
  setItemsList: (value: SetStateAction<PayrollCustomModel[]>) => void
}

export interface PayrollColumnInterface {
  id: string
  title: string
  columnName: number
  itemsId: string[]
}

export const usePayrollCustomController = () => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()

  //Default list to restore the original state
  const [defaultItemsList, setDefaultItemsList] = useState<PayrollCustomModel[]>([])

  //List of all columns (custom and default)
  const [itemsList, setItemsList] = useState<PayrollCustomModel[]>([])

  //2 columns: My Payroll and Hide Items
  const [columns, setColumns] = useState<PayrollColumnInterface[]>([
    {
      id: '1',
      title: t(flexPayrollsTranslations.customPayroll.myPayroll),
      columnName: PayrollColumnNameEnum.MY_PAYROLL,
      itemsId: [],
    },
    {
      id: '2',
      title: t(flexPayrollsTranslations.customPayroll.hideItems),
      columnName: PayrollColumnNameEnum.HIDE_ITEMS,
      itemsId: [],
    },
  ])

  const getPayrollExcelConfiguration = async (): Promise<void> => {
    startLoading()
    payrollsService()
      .GetPayrollExcelConfiguration()
      .then(response => {
        if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
          setDefaultItemsList(response.data.defaultColumnList)
          const myPayrollItemsId = response.data.customColumnList.map(({ id }) =>
            id.toString()
          )
          const hiddenPayrollItemsId = response.data.remainingColumnList.map(({ id }) =>
            id.toString()
          )
          setItemsList([
            ...response.data.customColumnList,
            ...response.data.remainingColumnList,
          ])
          setColumns(prevColumns =>
            prevColumns.map(column => {
              if (column.columnName === PayrollColumnNameEnum.MY_PAYROLL) {
                return {
                  ...column,
                  itemsId: myPayrollItemsId,
                }
              } else if (column.columnName === PayrollColumnNameEnum.HIDE_ITEMS) {
                return {
                  ...column,
                  itemsId: hiddenPayrollItemsId,
                }
              }
              return column
            })
          )
        } else {
          setDefaultItemsList([])
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  useEffect(() => {
    getPayrollExcelConfiguration()
  }, [])

  const addNewItem = (columnName: number) => {
    const newItem: PayrollCustomModel = {
      id: Math.random().toString(16),
      isCustom: true,
      standardId: PayrollColumnEnum.STANDARD_ID,
      name: 'Nuevo ítem',
      customName: '',
      order: 0,
    }
    setItemsList([...itemsList, newItem])
    setColumns(prevColumns =>
      prevColumns.map(column => {
        if (column.columnName === columnName) {
          return {
            ...column,
            itemsId: [...column.itemsId, newItem.id],
          }
        }
        return column
      })
    )
  }

  const onDragEnd = ({
    source,
    destination,
    draggableId,
  }: {
    source: any
    destination: any
    draggableId: string
  }) => {
    // WIP: El codigo de abajo bloquea un drop en una columna diferente dada una condición
    // const itemId = columns.find(x => x.id === source.droppableId)?.itemsId[source.index]
    // const isCustom =
    //   itemsList.find(x => x.id == itemId)?.standardId === PayrollColumnEnum.STANDARD_ID
    // console.log(isCustom)
    // if (isCustom && source.droppableId !== destination.droppableId) {
    //   alert('No se puede mover los custom')
    //   return
    // }

    // dropped inside of the list
    if (source && destination) {
      setColumns(prevState => {
        // source container index and id
        const { index: sourceIndex, droppableId: sourceId } = source

        // destination container index and id
        const { index: destinationIndex, droppableId: destinationId } = destination

        // source container object
        const sourceContainer = prevState.find(column => column.id === sourceId)

        // Add null check for sourceContainer
        if (!sourceContainer) {
          return prevState // or handle the error
        }

        // desination container object
        const destinationContainer = prevState.find(column => column.id === destinationId)

        // Add null check for destinationContainer
        if (!destinationContainer) {
          return prevState // or handle the error
        }

        // source container "userIds" array
        const sourceIds = Array.from(sourceContainer.itemsId)

        // destination container "userIds" array
        const destinationIds = Array.from(destinationContainer.itemsId)

        // check if source and destination container are the same
        const isSameContainer = sourceContainer.id === destinationContainer.id

        //  remove a userId from the source "userIds" array via the sourceIndex
        sourceIds.splice(sourceIndex, 1)

        // add a userId (draggableId) to the source or destination "userIds" array
        if (isSameContainer) {
          sourceIds.splice(destinationIndex, 0, draggableId)
        } else {
          destinationIds.splice(destinationIndex, 0, draggableId)
        }

        // update the source container with changed sourceIds
        const newSourceContainer = {
          ...sourceContainer,
          itemsId: sourceIds,
        }

        // update the destination container with changed destinationIds
        const newDestinationContainer = {
          ...destinationContainer,
          itemsId: destinationIds,
        }

        // loop through current columns and update the source
        // and destination containers
        const columns = prevState.map(column => {
          if (column.id === newSourceContainer.id) {
            return newSourceContainer
          } else if (column.id === newDestinationContainer.id && !isSameContainer) {
            return newDestinationContainer
          } else {
            return column
          }
        })
        return [...columns]
      })
    }
  }

  return { itemsList, columns, addNewItem, onDragEnd, setItemsList }
}
