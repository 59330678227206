import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'
import {
  OreTableContainer,
  OreTable,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableBody,
  OreCheckbox,
  OreDivider,
  OreStack,
  OreButton,
  OreText,
  OreTableActions,
  OrePagination,
  OreHeading,
  OreFormGroup,
  OreRadioButton,
} from '@runroom/oreneta'
import type { HeadersModel } from 'src/domain/models/tables/Headers'
import {
  DeleteImputationModal,
  DeleteModalActions,
  FlexOrderImputationRow,
  FlexOrderImputationRowSalud,
  ForceUpdateInputation,
  getOrderImputationTableHeaders,
  orderImputationsTranslations,
  useOrders,
} from 'src/Flex/Orders/ui/order-imputation'
import {
  orderValidationsTranslations,
  useBatchCheckboxes,
} from 'src/Flex/Orders/ui/order-validation'
import { edenredProducts } from 'src/domain/enum'
import { forms, tableSelector } from 'src/domain/translations'
import { TableSearcher } from 'src/Flex/Shared/ui/TableSearcher'
import { ChargePayrollMonthOptions } from 'src/Flex/Orders/domain'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { FlexOrderImputationEmptyRows } from '../molecules/FlexOrderImputationEmptyRows'
import { getLastDayOfMonth } from 'src/core/helpers'
//import { FlexOrderImputationEmptyRows as EmptyRows } from '././molecules/FlexOrderImputationEmptyRows'

export const FlexOrderImputationList = (): JSX.Element => {
  const deleteRef = useRef<DeleteModalActions>(null)

  const { t } = useTranslation()

  const {
    orders,
    form,
    dates: { imputationMonth, nextMonth, cutoffDate, updatedDate },
    productType,
    search,
    searchParam,
    onPageChange,
    page,
    numberOfRecordsPerPage,
    rows,
    calculate,
    emptyRows,
    conditions,
    forceUpdate,
  } = useOrders()

  const { handleSelectAllClick, indeterminate, massiveSelect } = useBatchCheckboxes({
    name: 'orders',
    form,
  })

  const { errors } = handleErrors(form.formState)

  const handleDeleteImputation = (id: string): void => {
    deleteRef?.current?.open(id)
  }

  const payrollMonthErrors = errors('chargePayrollMonth')
  const productHeaders = getOrderImputationTableHeaders(productType, t)

  const Row =
    productType === edenredProducts.formacion
      ? FlexOrderImputationRow
      : FlexOrderImputationRowSalud

  const productTransTitle =
    productType === edenredProducts.formacion
      ? t(orderImputationsTranslations.table.title_formation)
      : t(orderImputationsTranslations.table.title_external_health)
  const productTransNoOrder =
    productType === edenredProducts.formacion
      ? t(orderImputationsTranslations.table.title_formation).toLocaleLowerCase()
      : t(orderImputationsTranslations.table.title_external_health)

  return (
    <>
      {conditions?.canOrderBeUpdate ? (
        <ForceUpdateInputation refresh={forceUpdate} date={updatedDate} />
      ) : (
        <></>
      )}
      {emptyRows ? (
        <form>
          <OreStack placeContent="stretch" space="2xlarge">
            <OreTableContainer>
              <FormProvider {...form}>
                <TableSearcher
                  handleSearch={search}
                  title={t(orderImputationsTranslations.table.title, {
                    product: productTransTitle,
                  })}
                />
                <OreTable>
                  <OreTableHead valign="middle">
                    <OreTableRow hover={false}>
                      <OreTableCell as="th">
                        <OreCheckbox
                          name="check"
                          defaultChecked={
                            !orders || orders.length === 0 ? true : massiveSelect
                          }
                          indeterminate={indeterminate}
                          onChange={handleSelectAllClick}
                          disabled={!orders || orders.length === 0}
                        />
                      </OreTableCell>
                      {productHeaders.map(
                        (header: HeadersModel): JSX.Element => (
                          <OreTableCell as="th" key={header.key} size={header.size}>
                            <span style={{ whiteSpace: 'pre-line', display: 'flex' }}>
                              {header.label}
                            </span>
                          </OreTableCell>
                        )
                      )}
                    </OreTableRow>
                  </OreTableHead>
                  <OreTableBody valign="top">
                    {rows
                      .slice(
                        numberOfRecordsPerPage * (page - 1),
                        numberOfRecordsPerPage + numberOfRecordsPerPage * (page - 1)
                      )
                      .map(order => (
                        <Row
                          key={order.id}
                          order={order}
                          deleteOrder={handleDeleteImputation}
                        />
                      ))}
                  </OreTableBody>
                </OreTable>
                {!rows || rows.length === 0 ? (
                  <OreTableActions>
                    <OreText size="text-sm" tone="neutral-600">
                      {searchParam !== ''
                        ? t(orderImputationsTranslations.table.noOrdersWithFilters, {
                            product: productTransNoOrder,
                          })
                        : t(orderImputationsTranslations.table.noOrders, {
                            product: productTransNoOrder,
                          })}
                    </OreText>
                  </OreTableActions>
                ) : (
                  <OreTableActions>
                    <OrePagination
                      displayPageButtons
                      count={rows.length}
                      labelNextPage={t(tableSelector.nextPageLabel)}
                      labelPreviousPage={t(tableSelector.previousPageLabel)}
                      onPageChange={onPageChange}
                      page={page}
                      rowsPerPage={numberOfRecordsPerPage}
                      text={t(tableSelector.pageOf)}
                      legend={t(tableSelector.rowsShowing)}
                    />
                  </OreTableActions>
                )}
              </FormProvider>
            </OreTableContainer>
            <ul>
              <li>{t(orderImputationsTranslations.list.text1)}</li>
              <li>
                {t(orderImputationsTranslations.list.text2) +
                  t(flexProductsTranslations.ids[productType]) +
                  t(orderImputationsTranslations.list.text3)}
              </li>
            </ul>
            <div>
              <OreHeading size="headline-md">
                {t(orderValidationsTranslations.step1.complete.title)}
              </OreHeading>
              <br />
              <OreFormGroup
                label={t(orderValidationsTranslations.step1.complete.imputate.month)}
                direction="row"
                {...payrollMonthErrors}
                required
                labelTooltip={t(
                  orderValidationsTranslations.step1.complete.imputate.tooltip
                )}>
                <OreRadioButton
                  {...form.register('chargePayrollMonth')}
                  hasError={payrollMonthErrors.hasError}
                  label={t(orderValidationsTranslations.step1.complete.imputate.current, {
                    month: imputationMonth,
                  })}
                  value={ChargePayrollMonthOptions.Current}
                />
                <OreRadioButton
                  {...form.register('chargePayrollMonth')}
                  hasError={payrollMonthErrors.hasError}
                  label={t(orderValidationsTranslations.step1.complete.imputate.next, {
                    month: nextMonth,
                  })}
                  value={ChargePayrollMonthOptions.Next}
                />
              </OreFormGroup>
            </div>
            <OreText> {t(forms.errors.fieldsRequired)}</OreText>
          </OreStack>
          <OreDivider space="larger-top" />
          <OreStack placeContent="end">
            <OreButton
              category="primary"
              type="submit"
              onClick={form.handleSubmit(calculate)}
              disabled={
                !orders ||
                orders.length === 0 ||
                (form.getValues('orders') &&
                  form.getValues('orders').filter(o => o.active).length === 0)
              }>
              {t(orderImputationsTranslations.calc_button)}
            </OreButton>
          </OreStack>
          <DeleteImputationModal ref={deleteRef} />
        </form>
      ) : (
        <FlexOrderImputationEmptyRows />
      )}
    </>
  )
}
