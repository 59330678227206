import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreIconButton,
  SvgEdit,
  OrePill,
  SvgSubscribeUser,
  SvgUnsubscribeUserFlex,
  SvgCommunications,
  OreTooltip,
} from '@runroom/oreneta'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { type EmployeeModel } from 'src/Flex/Employees/domain'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { GetDateFormatted } from 'src/core/helpers'
import { RestrictedComponent } from 'src/presentation/components/Edenred/permissions'
import { actionTag } from 'src/domain/enum/permissionTags'
import { OrePillTones, OrePillTonesTypes } from 'src/domain/runrom/IOrePillTones'
import { EmployeePRFEnum } from 'src/Flex/Employees/domain/EmployeePRFEnum'

interface IProps {
  employee: EmployeeModel
  handleSubscribe: (employee: EmployeeModel) => void
  sendEmail: (id: string) => void
}

export const FlexEmployeesPendingAdherenceRow = ({
  employee,
  handleSubscribe,
  sendEmail,
}: IProps): JSX.Element => {
  const {
    userFirstName,
    userLastName,
    userLastName2,
    document,
    collective,
    communicationDate,
    id,
    active,
  } = employee
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEmployeeEdit = (): void => {
    navigate(flexNavigationRoutes.flexEmployeesEdit.replace(':id', id))
  }

  const GetPRFPill = (flexRetributionPlanRegisterStep: EmployeePRFEnum): JSX.Element => {
    const statusConfig: Record<
      EmployeePRFEnum,
      { tone: OrePillTonesTypes; text: string }
    > = {
      [EmployeePRFEnum.notStarted]: {
        tone: OrePillTones.gray,
        text: flexEmployeesTranslations.table.body.notStartedPrf,
      },
      [EmployeePRFEnum.pending]: {
        tone: OrePillTones.warning,
        text: flexEmployeesTranslations.table.body.pendingPrf,
      },
      [EmployeePRFEnum.done]: {
        tone: OrePillTones.success,
        text: flexEmployeesTranslations.table.body.donePrf,
      },
      [EmployeePRFEnum.cancelled]: {
        tone: OrePillTones.error,
        text: flexEmployeesTranslations.table.body.cancelledPrf,
      },
    }
    const { tone, text } = statusConfig[flexRetributionPlanRegisterStep]
    return (
      <OrePill tone={tone}>
        <OreText align="center" size="text-sm">
          {t(text)}
        </OreText>
      </OrePill>
    )
  }

  return (
    <OreTableRow valign="middle">
      <OreTableCell>
        <div className="flex">
          <OreStack space="2xsmall">
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {userFirstName} {userLastName} {userLastName2}
            </OreText>
            <OreText size="text-sm" tone="neutral-600">
              {document}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="small">
          <OreText size="text-sm">{collective}</OreText>
        </OreStack>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="small">
          {communicationDate ? (
            <OreText size="text-sm">{GetDateFormatted(communicationDate)}</OreText>
          ) : null}
        </OreStack>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="small">
          {active ? (
            <OrePill tone="success-600">
              <OreStack space="2xsmall" direction="row">
                <SvgSubscribeUser />
                <OreText size="text-sm" tone="success-600">
                  {t(flexEmployeesTranslations.table.body.active)}
                </OreText>
              </OreStack>
            </OrePill>
          ) : (
            <OrePill tone="error-600">
              <OreStack space="2xsmall" direction="row">
                <SvgUnsubscribeUserFlex />
                <OreText size="text-sm" tone="error-600">
                  {t(flexEmployeesTranslations.table.body.inactive)}
                </OreText>
              </OreStack>
            </OrePill>
          )}
        </OreStack>
      </OreTableCell>
      <OreTableCell>{GetPRFPill(employee.flexRetributionPlanRegisterStep)}</OreTableCell>
      <OreTableCell>
        <OreStack space="2xsmall" direction="row">
          <OreTooltip
            text={t(flexCommunicationsTemplatesTranslations.sendModal.icon)}
            placement="top">
            <OreIconButton
              icon={<SvgCommunications />}
              onClick={(event): void => {
                event.preventDefault()
                sendEmail(id)
              }}
            />
          </OreTooltip>
          <RestrictedComponent by={actionTag.ACTION_FL_EMPLOYEES_LIST_DELETE}>
            <OreTooltip
              text={
                employee.active
                  ? t(flexEmployeesTranslations.unsubscribe.cta)
                  : t(flexEmployeesTranslations.subscribe.again)
              }
              placement="top">
              <OreIconButton
                icon={active ? <SvgUnsubscribeUserFlex /> : <SvgSubscribeUser />}
                onClick={(event): void => {
                  event.preventDefault()
                  handleSubscribe(employee)
                }}
              />
            </OreTooltip>
          </RestrictedComponent>
          <RestrictedComponent by={actionTag.ACTION_FL_EMPLOYEES_LIST_EDIT}>
            <OreTooltip
              text={t(flexEmployeesTranslations.configure.edit.title)}
              placement="top">
              <OreIconButton icon={<SvgEdit />} onClick={handleEmployeeEdit} />
            </OreTooltip>
          </RestrictedComponent>
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
