export const flexHomeVideTutorialTranslations = {
  videoTutorial: 'flex.home.video_tutorial',
}

export const flexTaskManagerTranslations = {
  title: 'flex.task_manager.title',
  subtitle: 'flex.task_manager.subtitle',
}

export const flexPendingTasksTranslations = {
  title: 'flex.pending_tasks.title',
  subtitle: 'flex.pending_tasks.subtitle',
  noTasks: 'flex.pending_tasks.no_tasks',
  tasks: {
    subtitles: {
      pendingValidate: 'flex.pending_tasks.tasks.subtitles.pending_validate',
      pendingImputation: 'flex.pending_tasks.tasks.subtitles.pending_imputation',
      pendingOnboardingSteps:
        'flex.pending_tasks.tasks.subtitles.pending_onboarding_steps',
      pendingGeneral: 'flex.pending_tasks.tasks.subtitles.pending_general',
      employees: 'flex.pending_tasks.tasks.subtitles.employees',
    },
    onboarding: {
      subtitle1: 'flex.pending_tasks.tasks.onboarding.subtitle1',
      subtitle2: 'flex.pending_tasks.tasks.onboarding.subtitle2',
    },
    homeTables: {
      ordersDescription: 'flex.pending_tasks.home_tables_orders.description',
      orderSubDescription: 'flex.pending_tasks.home_tables_orders.sub_description',
      pendigValidate: 'flex.pending_tasks.home_tables_orders.pending_validate',
      pendingInputing: 'flex.pending_tasks.home_tables_orders.pending_inputing',
      pendingDescription: 'flex.pending_tasks.home_tables_orders.pending_description',
      pendingSend: 'flex.pending_tasks.home_tables_orders.pending_send',
      notPendigValidate: 'flex.pending_tasks.home_tables_orders.not_pending_validate',
      notPendigValidateDesc:
        'flex.pending_tasks.home_tables_orders.not_pending_validate_description',
      notPendingInputing: 'flex.pending_tasks.home_tables_orders.not_pending_inputing',
      notPendingInputingDesc:
        'flex.pending_tasks.home_tables_orders.not_pending_inputing_description',
      search_employee: 'flex.pending_tasks.home_tables_orders.pending_search_employee',
      add_product: 'flex.pending_tasks.home_tables_orders.pending_add_product',
      notPendingGeneral: 'flex.pending_tasks.home_tables_orders.not_pending_general',
      notPendingGeneralDesc:
        'flex.pending_tasks.home_tables_orders.not_pending_general_description',
      notPendingEmployees: 'flex.pending_tasks.home_tables_orders.not_pending_employee',
      notPendingEmployeesDesc:
        'flex.pending_tasks.home_tables_orders.not_pending_employee_description',
    },
  },
  sendModal: {
    title: 'flex.pending_tasks.send_modal.title',
    subtitle: 'flex.pending_tasks.send_modal.subtitle',
  },
}
