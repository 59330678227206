import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreInput,
  OreText,
  SvgEdit,
} from '@runroom/oreneta'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { forms } from '../../../../../domain/translations'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { useUserDataController } from '../hooks/useUserDataController'
import { MessageBar } from 'src/presentation/components/Edenred'

export const UserData = (): JSX.Element => {
  const { t } = useTranslation()
  const { user, isEditing, setIsEditing, form, editEmail, errorMessage } =
    useUserDataController()
  if (!user) return <></>
  return (
    <OreCardContainer>
      <OreCardWrapper space="large">
        <OreHeading as="h1" size="headline-sm">
          {t(accountSettingsTranslation.myData.title)}
        </OreHeading>

        <OreDivider space="medium" />

        <div>
          <div>
            <OreText bold={true}>{t(accountSettingsTranslation.myData.fullName)}</OreText>
            <OreText>{user.fullName}</OreText>
          </div>
          <div className="pt-2">
            <OreText bold={true}>{t(accountSettingsTranslation.myData.userName)}</OreText>
            <OreText>{user?.userName}</OreText>
          </div>
          <div className="pt-2">
            <FormProvider {...form}>
              <form>
                <OreInput
                  {...(form.register && form.register('email'))}
                  name="email"
                  id="email"
                  label={t(accountSettingsTranslation.myData.email)}
                  type="email"
                  hasError={!!form.formState.errors.email}
                  errorMessage={
                    form.formState.errors.email
                      ? form.formState.errors.email.message
                      : undefined
                  }
                  maxLength={75}
                  required={true}
                  disabled={!isEditing}
                />
              </form>
            </FormProvider>
          </div>
        </div>
        {errorMessage && (
          <div className="pt-105">
            <MessageBar color="error" message={errorMessage} />
          </div>
        )}
        <div className="py-2">
          {!isEditing ? (
            <OreButton
              as="button"
              category="secondary"
              icon={<SvgEdit />}
              onClick={() => setIsEditing(true)}
              size="large">
              {t(accountSettingsTranslation.myData.editButton)}
            </OreButton>
          ) : (
            <OreButton
              as="button"
              category="primary"
              icon={<SvgEdit />}
              onClick={form.handleSubmit(editEmail)}
              size="large">
              {t(forms.buttons.save)}
            </OreButton>
          )}
        </div>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
