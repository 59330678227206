import {
  OreButton,
  OrePagination,
  OrePill,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  SvgAlert,
  SvgDownload,
} from '@runroom/oreneta'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MessagesModel } from 'src/domain/models'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { orderValidationsTranslations } from 'src/Flex/Orders/ui/order-validation'

interface Props {
  errorMessages: MessagesModel[]
  backToHome: () => void
  back: () => void
  additionalDataExcel: string
}

export const ErrorMessagesTable = ({
  errorMessages,
  backToHome,
  back,
  additionalDataExcel,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const pageSize = 8

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const downloadFile = async () => {
    if (additionalDataExcel === '') return

    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${additionalDataExcel}`
    link.download = 'listado-errores.xlsx'
    link.click()
    link.remove()
  }

  return (
    <>
      {/* Table */}
      {errorMessages.length > 0 && (
        <OreStack>
          <OreTableContainer>
            <OreTable>
              <OreTableCaption
                title={t(TTOrderTranslation.stoppersAndWarnings.grid.title2)}
                counter={
                  errorMessages.slice((page - 1) * pageSize, page * pageSize).length +
                  ' ' +
                  t(orderValidationsTranslations.step3.error.table.numberAlerts)
                }
              />
              <OreTableHead>
                <OreTableRow hover={false}>
                  <OreTableCell as="th">
                    {t(TTOrderTranslation.stoppersAndWarnings.grid.row)}
                  </OreTableCell>
                  <OreTableCell as="th">
                    {t(TTOrderTranslation.stoppersAndWarnings.grid.errorType)}
                  </OreTableCell>
                  <OreTableCell as="th">
                    {t(TTOrderTranslation.stoppersAndWarnings.grid.document)}
                  </OreTableCell>
                  <OreTableCell as="th">
                    {t(TTOrderTranslation.stoppersAndWarnings.grid.errorDescription)}
                  </OreTableCell>
                </OreTableRow>
              </OreTableHead>
              <OreTableBody as="tbody" valign="top">
                {errorMessages
                  .slice((page - 1) * pageSize, page * pageSize)
                  .map((message: MessagesModel, index: number) => {
                    return (
                      <OreTableRow valign="middle" key={index} hover={false}>
                        <OreTableCell align="left" as="td" size="auto">
                          <OreText>{JSON.parse(message.value).errorLineNum}</OreText>
                        </OreTableCell>
                        <OreTableCell align="left" as="td" size="auto">
                          <div className="whitespace-nowrap">
                            <OrePill icon={<SvgAlert />} tone="error-600">
                              {t(cardOrderTranslation.excelAlerts.table.errorLabel)}
                            </OrePill>
                          </div>
                        </OreTableCell>
                        <OreTableCell align="left" as="td" size="auto">
                          <OreText>{JSON.parse(message.value).document}</OreText>
                        </OreTableCell>
                        <OreTableCell align="left" as="td" size="auto">
                          <OreText>{message.description}</OreText>
                        </OreTableCell>
                      </OreTableRow>
                    )
                  })}
              </OreTableBody>
            </OreTable>
            <OreTableActions>
              <OrePagination
                count={errorMessages.length}
                labelNextPage={t(forms.pagination.next)}
                labelPreviousPage={t(forms.pagination.back)}
                onPageChange={onPageChange}
                page={page}
                rowsPerPage={pageSize}
                text={t(forms.pagination.of)}
              />
            </OreTableActions>
          </OreTableContainer>
        </OreStack>
      )}
      {/* Buttons */}
      <OreStack
        direction="column"
        space="small"
        placeContent="center"
        placeItems="center">
        <div className="mt-1">
          {errorMessages.length > 0 && additionalDataExcel !== '' ? (
            <OreButton
              as="button"
              category="primary"
              size="large"
              icon={<SvgDownload />}
              onClick={() => downloadFile()}>
              {t(TTOrderTranslation.stoppersAndWarnings.downloadButton)}
            </OreButton>
          ) : (
            <OreButton as="button" category="primary" onClick={back} size="large">
              {t(cardOrderTranslation.cardOrderComplete.newOrder)}
            </OreButton>
          )}
        </div>
        <OreButton
          as="button"
          category="highlight"
          onClick={backToHome}
          size="large"
          style={{ color: '#0F172A' }}>
          {t(forms.buttons.goHome)}
        </OreButton>
      </OreStack>
    </>
  )
}
