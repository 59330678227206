import { useDietSpendings } from '../hooks/useDietSpendings'
import { DietSpendingsFooterButtons, DietSpendingsTable } from '../molecules'

export const DietSpendingsContainer = (): JSX.Element => {
  const props = useDietSpendings()
  return (
    <div className="py-2">
      <DietSpendingsTable useDietSpendingsProps={props} />
      <DietSpendingsFooterButtons useDietSpendings={props} />
    </div>
  )
}
