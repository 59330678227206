import { RepositoryFactory } from '../factories/RepositoryFactory'
import { Repositories } from '../../domain/enum/repositories'
import { duplicatesConfigurationTranslations } from '../../domain/translations/cardOrder/duplicatesConfiguration'
import type { ComboBoxOptionModel } from '../../domain/customComponents'
import { OrderContext, lastCardStatus } from '../../domain/enum'
import {
  cardOrderReason,
  cardRequestType,
  cardRequestTypeId,
  cardType,
  cardOrderTypeEnum,
  formNewCardsOrDuplicate,
  formRecharge,
  formUnload,
  orderType,
  orderUndefined,
  emptyValues,
} from '../../domain/enum'
import { cardOrderTranslation } from '../../domain/translations'
import type { CardOrderModel, CardOrderResumeModel } from '../../domain/models'
import {
  createCardOrderNewDuplicated,
  createCardOrderRecharge,
  createCardOrderUnload,
  singleGetNewDuplicatedCheckout,
  singleGetRechargeCheckout,
  singleGetUnloadCheckout,
} from '../../config/constants/endpoints'
import { myOrders } from '../../domain/translations/cardOrder/myOrders'
import type CardOrderRepository from '../../infrastructure/repository/cardOrder/cardOrderRepository'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { isCardStatus } from '../helpers'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'

export const cardOrderService = (): CardOrderRepository => {
  return RepositoryFactory(Repositories.cardOrder)
}

export const getDuplicateCardReason = (): ComboBoxOptionModel[] => {
  return [
    {
      options: [
        [
          duplicatesConfigurationTranslations.table.comboBoxFields.reson.optionOne,
          cardOrderReason.stolenLost,
        ],
        [
          duplicatesConfigurationTranslations.table.comboBoxFields.reson.optionTwo,
          cardOrderReason.damage,
        ],
      ],
    },
  ]
}

export const getDuplicateCardType = (
  allowPlasticless?: boolean
): ComboBoxOptionModel[] => {
  const options: ComboBoxOptionModel[] = [
    {
      options: [
        [
          duplicatesConfigurationTranslations.table.comboBoxFields.type.optionOne,
          cardType.Plastic,
        ],
      ],
    },
  ]
  if (allowPlasticless) {
    options[0].options.push([
      duplicatesConfigurationTranslations.table.comboBoxFields.type.optionTwo,
      cardType.Virtual,
    ])
  }
  return options
}

export const getCardReasonByCardRequestTypeId = (id: number): string => {
  switch (id) {
    case cardRequestTypeId.newPlastic:
      return cardOrderTranslation.cardOrderHome.newPlastic
    case cardRequestTypeId.newPlasticless:
      return cardOrderTranslation.cardOrderHome.newVirtual
    case cardRequestTypeId.lostStolenPlastic:
      return cardOrderTranslation.cardOrderHome.plasticLostStolen
    case cardRequestTypeId.damagePlastic:
      return cardOrderTranslation.cardOrderHome.plasticDamage
    case cardRequestTypeId.lostStolenPlasticless:
      return cardOrderTranslation.cardOrderHome.virtualLostStolen
    case cardRequestTypeId.damagePlasticless:
      return cardOrderTranslation.cardOrderHome.virtualDamage
    default:
      return emptyValues.dash
  }
}

export const getNewCardReasonByCardRequestTypeId = (id: number): string => {
  switch (id) {
    case cardRequestTypeId.newPlastic:
      return cardOrderTranslation.cardOrderHome.newPlastic
    case cardRequestTypeId.newPlasticless:
      return cardOrderTranslation.cardOrderHome.newVirtual
    case cardRequestTypeId.lostStolenPlastic:
      return cardOrderTranslation.cardOrderHome.duplicated
    case cardRequestTypeId.damagePlastic:
      return cardOrderTranslation.cardOrderHome.duplicated
    case cardRequestTypeId.lostStolenPlasticless:
      return cardOrderTranslation.cardOrderHome.duplicated
    case cardRequestTypeId.damagePlasticless:
      return cardOrderTranslation.cardOrderHome.duplicated
    default:
      return emptyValues.dash
  }
}

export const getCardOrderTypeLength = (orders: CardOrderModel[]) => {
  return orders.filter(o => o.orderTypeId === orderType.Card).length
}

export const cardOrderTypeExists = (orders: CardOrderModel[]) => {
  return orders.some(o => o.orderTypeId === orderType.Card)
}

export const getUrlCheckoutByCardOrderType = (orders: CardOrderModel[]): string => {
  switch (orders[0].orderTypeId) {
    case cardRequestType.newCard:
    case cardRequestType.duplicated:
      return singleGetNewDuplicatedCheckout
    case cardRequestType.charge:
      return singleGetRechargeCheckout
    case orderType.Unload:
      return singleGetUnloadCheckout
    default:
      return ''
  }
}
export const getOrderContextType = (orders: CardOrderModel[]): OrderContext => {
  switch (orders[0].orderTypeId) {
    case cardRequestType.newCard:
    case cardRequestType.duplicated:
      return formNewCardsOrDuplicate
    case cardRequestType.charge:
      return formRecharge
    case orderType.Unload:
      return formUnload
    default:
      return orderUndefined
  }
}

export const getUrlCompleteByCardOrderType = (orderTypeId: number): string => {
  switch (orderTypeId) {
    case cardRequestType.newCard:
    case cardRequestType.duplicated:
      return createCardOrderNewDuplicated
    case cardRequestType.charge:
      return createCardOrderRecharge
    case orderType.Unload:
      return createCardOrderUnload
    default:
      return ''
  }
}

export const getLastCardOrderTypeDescriptionById = (orderTypeId: number): string => {
  switch (orderTypeId) {
    case orderType.Card:
      return myOrders.lastOrders.orderTypes.cards
    case orderType.Recharge:
      return myOrders.lastOrders.orderTypes.charge
    case orderType.Unload:
      return myOrders.lastOrders.orderTypes.unload
    case orderType.NurseryAssignmentOrder:
      return myOrders.lastOrders.orderTypes.assignment
    default:
      return ''
  }
}

export const hasUnloadOrders = (orders: CardOrderModel[] | TTOrderModel[]): boolean => {
  if (orders.length === 0) return false
  return orderType.Unload === orders[0].orderTypeId
}

export const existRecharge = (
  orders: CardOrderModel[],
  orderExcel: CardOrderResumeModel
): boolean => {
  const existOrderRecharge = !!orders.find(order => !!order.cardData.initialAmount)
  const existExcelRecharge = orderExcel.charge.quantity > 0
  return !hasUnloadOrders(orders) && (existOrderRecharge || existExcelRecharge)
}

export const hasRechargeOrDischarge = (
  orders: CardOrderModel[],
  orderExcel: CardOrderResumeModel
): boolean => {
  const existOrderRecharge = !!orders.find(order => !!order.cardData.initialAmount)
  const existExcelRecharge = orderExcel.charge.quantity > 0
  return existOrderRecharge || existExcelRecharge
}

export const isDischarge = (orderType: number): boolean => {
  if (orderType === cardOrderTypeEnum.discharge) return true
  return false
}

export const getIncompleteCardOrderTypeDescriptionById = (
  orderTypeId: number
): string => {
  switch (orderTypeId) {
    case cardOrderTypeEnum.newAndDuplicate:
      return myOrders.incompleteOrders.orderTypes.newAndDuplicated
    case cardOrderTypeEnum.recharge:
      return myOrders.incompleteOrders.orderTypes.charge
    case cardOrderTypeEnum.discharge:
      return myOrders.incompleteOrders.orderTypes.discharge
    case orderType.NurseryAssignmentOrder:
      return myOrders.lastOrders.orderTypes.assignment
    default:
      return ''
  }
}

export const validateDate = (date: string | undefined) => {
  if (!date) return ''
  if (date === emptyValues.dash) return ''
  return date
}

export const getCardOrderStatusLabelById = (cardStatusId: number): string => {
  switch (cardStatusId) {
    case lastCardStatus.logicDestrucction:
      return employeesTranslation.profile.card.status.logicDestrucction
    case lastCardStatus.requested:
      return employeesTranslation.profile.card.status.requested
    case isCardStatus(lastCardStatus.sent, cardStatusId):
      return employeesTranslation.profile.card.status.sent
    case lastCardStatus.rejected:
      return employeesTranslation.profile.card.status.rejected
    case lastCardStatus.confirmed:
      return employeesTranslation.profile.card.status.confirmed
    case lastCardStatus.activationResquested:
      return employeesTranslation.profile.card.status.activationResquested
    case lastCardStatus.active:
      return employeesTranslation.profile.card.status.active
    case lastCardStatus.lockRequested:
      return employeesTranslation.profile.card.status.lockRequested
    case lastCardStatus.locked:
      return employeesTranslation.profile.card.status.locked
    case lastCardStatus.unlockRequested:
      return employeesTranslation.profile.card.status.unlockRequested
    case lastCardStatus.cancelRequested:
      return employeesTranslation.profile.card.status.cancelRequested
    case isCardStatus(lastCardStatus.cancelled, cardStatusId):
      return employeesTranslation.profile.card.status.cancelled
    case lastCardStatus.activationRejected:
      return employeesTranslation.profile.card.status.activationRejected
    case lastCardStatus.lockRejected:
      return employeesTranslation.profile.card.status.lockRejected
    case lastCardStatus.unlockRejected:
      return employeesTranslation.profile.card.status.unlockRejected
    case lastCardStatus.cancellationRejected:
      return employeesTranslation.profile.card.status.cancellationRejected
    default:
      return ''
  }
}
