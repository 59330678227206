import { useRechargeEmployees } from '../hooks/useRechargeEmployees'
import { RechargeEmployeesFooterButtons, RechargeEmployeesTable } from '../molecules'

export const RechargeEmployees = (): JSX.Element => {
  const props = useRechargeEmployees()
  return (
    <>
      <RechargeEmployeesTable rechargeEmpoyeesProps={props} />
      <RechargeEmployeesFooterButtons rechargeEmpoyeesProps={props} />
    </>
  )
}
