import { LeaveOrderPrompt } from 'src/presentation/components/Edenred/navigation'
import { orderType } from '../../../../../../domain/enum'
import { useCardOrder } from '../../../../../context/cardOrder/CardOrderProvider'
import { Table } from '../../../../Edenred'
import { CardOrderHomeForm } from '../../../cardOrderHome/organism'
import { useExcelSummaryController } from '../hooks/useExcelSummaryController'

const OrderSummaryExcelContainer = () => {
  const {
    cardRows,
    header,
    setCardRows,
    cardCaption,
    chargeCaption,
    chargeRows,
    setChargeRows,
  } = useExcelSummaryController()
  const { orderExcel, orderContext } = useCardOrder()

  return (
    <>
      {orderExcel.card.services.length > 0 && (
        <Table
          header={header}
          rows={cardRows}
          setRows={setCardRows}
          selector={{ buttonTitle: '', description: '', selectors: [], title: '' }}
          caption={cardCaption}
        />
      )}
      {orderExcel.card.services.length > 0 && orderExcel.charge.services.length > 0 && (
        <div className="mt-3"></div>
      )}
      {orderExcel.charge.services.length > 0 &&
        orderContext.orderType === orderType.Recharge && (
          <Table
            header={header}
            rows={chargeRows}
            setRows={setChargeRows}
            selector={{ buttonTitle: '', description: '', selectors: [], title: '' }}
            caption={chargeCaption}
          />
        )}
      {orderExcel.charge.services.length > 0 &&
        orderContext.orderType === orderType.Unload && (
          <Table
            header={header}
            rows={chargeRows}
            setRows={setChargeRows}
            selector={{ buttonTitle: '', description: '', selectors: [], title: '' }}
            caption={chargeCaption}
          />
        )}
      <CardOrderHomeForm />

      <LeaveOrderPrompt />
    </>
  )
}

export default OrderSummaryExcelContainer
