import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { UseFormReturn, useForm } from 'react-hook-form'
import { object } from 'yup'
import { productsService } from 'src/Flex/Products/application'
import { ProductModel, CutOffDateForm, CutOffDate } from 'src/Flex/Products/domain'
import { CollectiveConfigurationModel } from 'src/Flex/Collectives/domain'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import {
  nonEmptyPositiveNumberSchema,
  useMetaResponseHandler,
} from 'src/Flex/Shared/ui/Form'
import { useUserData } from 'src/Flex/User/ui/context'

interface IUseProductController {
  products: ProductModel[]
  loadProducts: () => Promise<void>
  form: UseFormReturn<CutOffDateForm>
  saveCutOffDate: (data: CutOffDateForm) => Promise<boolean>
  cutOffDate: CutOffDate | undefined
  loadCutOffDate: () => Promise<void>
}

export const useProductController = (
  collective?: CollectiveConfigurationModel
): IUseProductController => {
  const [products, setProducts] = useState<ProductModel[]>([])
  const [cutOffDate, setCutOffDate] = useState<CutOffDate>()

  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const { t } = useTranslation()
  const { setAllProducts } = useUserData()

  const form = useForm<CutOffDateForm>({
    resolver: yupResolver(
      object({
        cutOffDate: nonEmptyPositiveNumberSchema(t).test(
          'cutOffDate',
          t(flexProductsTranslations.cutOffDate.form.monthsError),
          value => value !== undefined && value <= 28
        ),
      })
    ),
    defaultValues: {
      cutOffDate: undefined,
    },
    mode: 'onChange',
  })

  const loadProducts = async (): Promise<void> => {
    startLoading()

    const response = await productsService().GetProducts(collective?.id)

    if (response.data) {
      loadCutOffDate()
    }

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setProducts(response.data ?? [])
      if (collective === undefined) {
        setAllProducts(response.data ?? [])
      }
    }
  }

  const loadCutOffDate = async (): Promise<void> => {
    startLoading()

    const response = await productsService().GetCutOffDate()

    stopLoading()

    setCutOffDate(response)
  }

  const saveCutOffDate = async (data: CutOffDateForm): Promise<boolean> => {
    startLoading()

    const response = await productsService().SaveCutOffDate(data)

    stopLoading()

    const valid = handleMetaResponse(response?.meta, undefined, {
      successMessage: t(flexProductsTranslations.cutOffDate.notification),
    })

    if (valid) {
      form.reset()

      loadCutOffDate()
    }

    return valid
  }

  useEffect(() => {
    loadProducts()
  }, [])

  return {
    products,
    loadProducts,
    form,
    saveCutOffDate,
    cutOffDate,
    loadCutOffDate,
  }
}
