export const accountSettingsTranslation = {
  title: 'accountSettings.title',
  companyData: {
    title: 'accountSettings.companyData.title',
    company: 'accountSettings.companyData.company',
    code: 'accountSettings.companyData.code',
    CIF: 'accountSettings.companyData.CIF',
    address: 'accountSettings.companyData.address',
    phone: 'accountSettings.companyData.phone',
    description: 'accountSettings.companyData.description',
    orderEmail: 'accountSettings.companyData.orderEmail',
  },
  myData: {
    title: 'accountSettings.myData.title',
    fullName: 'accountSettings.myData.fullName',
    userName: 'accountSettings.myData.userName',
    email: 'accountSettings.myData.email',
    editButton: 'accountSettings.myData.editButton',
    editSuccess: 'accountSettings.myData.editSuccess',
  },
  changePassword: {
    title: 'accountSettings.changePassword.title',
    description: 'accountSettings.changePassword.description',
    currentPassword: 'accountSettings.changePassword.currentPassword',
    newPassword: 'accountSettings.changePassword.newPassword',
    newPasswordLegend: 'accountSettings.changePassword.newPasswordLegend',
    newPasswordError: 'accountSettings.changePassword.newPasswordError',
    repeatPassword: 'accountSettings.changePassword.repeatPassword',
    repeatPasswordError: 'accountSettings.changePassword.repeatPasswordError',
    changeSuccess: 'accountSettings.changePassword.changeSuccess',
  },
  deleteAccount: {
    title: 'accountSettings.deleteAccount.title',
    description: 'accountSettings.deleteAccount.description',
    deleteButton: 'accountSettings.deleteAccount.deleteButton',
    deletedSuccess: 'accountSettings.deleteAccount.deletedSuccess',
    modal: {
      title: 'accountSettings.deleteAccount.modal.title',
      description: 'accountSettings.deleteAccount.modal.description',
      reason: 'accountSettings.deleteAccount.modal.reason',
      reasonPlaceholder: 'accountSettings.deleteAccount.modal.reasonPlaceholder',
      selectReason: 'accountSettings.deleteAccount.modal.selectReason',
      reasonChangeManager: 'accountSettings.deleteAccount.modal.reasonChangeManager',
      reasonFinishWork: 'accountSettings.deleteAccount.modal.reasonFinishWork',
      reasonFinishColaboration:
        'accountSettings.deleteAccount.modal.reasonFinishColaboration',
      reasonOther: 'accountSettings.deleteAccount.modal.reasonOther',
      addMore: 'accountSettings.deleteAccount.modal.addMore',
      addMorePlaceholder: 'accountSettings.deleteAccount.modal.addMorePlaceholder',
      acceptConditions1: 'accountSettings.deleteAccount.modal.acceptConditions1',
      acceptConditions2: 'accountSettings.deleteAccount.modal.acceptConditions2',
      acceptConditions3: 'accountSettings.deleteAccount.modal.acceptConditions3',
      successTitle: 'accountSettings.deleteAccount.modal.successTitle',
      successDescription: 'accountSettings.deleteAccount.modal.successDescription',
      successButton: 'accountSettings.deleteAccount.modal.successButton',
    },
  },
  changeLanguage: {
    title: 'accountSettings.changeLanguage.title',
    select: {
      label: 'accountSettings.changeLanguage.select.label',
    },
    cta: {
      label: 'accountSettings.changeLanguage.cta.label',
    },
  },
}
