import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreCheckbox,
  SvgBin,
  OreInput,
  OreTooltip,
} from '@runroom/oreneta'
import { useFormContext } from 'react-hook-form'
import {
  ImputationForm,
  orderImputationsTranslations,
} from 'src/Flex/Orders/ui/order-imputation'

import { Kinship, OrderImputationModel } from 'src/Flex/Orders/domain'
import { useTranslation } from 'react-i18next'
import { flexOrdersTranslations } from '../../translations'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

interface IProps {
  order: OrderImputationModel
  deleteOrder: (id: string) => void
}

export const FlexOrderImputationRowSalud = ({
  order,
  deleteOrder,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    id,
    position,
    owner,
    ownerId,
    name,
    kinship,
    company,
    beneficiary,
    isEditable,
    description,
  } = order
  const { register, formState, getFieldState, getValues } =
    useFormContext<ImputationForm>()
  const { errors } = handleErrors(formState, getFieldState)
  return (
    <OreTableRow valign="middle">
      <OreTableCell>
        <OreTooltip
          text={
            getValues(`orders.${position}.active`)
              ? t(orderImputationsTranslations.configure.delete.checkImputationTooltip)
              : t(orderImputationsTranslations.configure.delete.uncheckImputationTooltip)
          }
          placement="top">
          <div>
            <OreCheckbox {...register(`orders.${position}.active`)} />
          </div>
        </OreTooltip>
      </OreTableCell>
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack space="2xsmall">
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {owner}
            </OreText>
            <OreText size="text-sm" tone="neutral-600">
              {ownerId}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell size="half">
        <>
          {kinship === Kinship.couple ||
          kinship === Kinship.parent ||
          kinship === Kinship.sibling ||
          kinship === Kinship.son ||
          kinship === Kinship.others ? (
            <>
              <OreText size="text-sm">
                {t(flexOrdersTranslations.kinship[kinship])} - {beneficiary}
              </OreText>
              <OreText size="text-sm">{company}</OreText>
              <OreText size="text-sm">{description}</OreText>
            </>
          ) : (
            <>
              <OreText size="text-sm">{owner}</OreText>
              <OreText size="text-sm">{company}</OreText>
              <OreText size="text-sm">{description}</OreText>
            </>
          )}
        </>
      </OreTableCell>
      <OreTableCell size="quarter">
        <OreInput
          disabled={!isEditable}
          id="import"
          type="number"
          startAdornment={'€'}
          {...register(`orders.${position}.value`)}
          {...errors(`orders.${position}.value`)}
        />
      </OreTableCell>
      <OreTableCell>
        <OreTooltip
          text={t(orderImputationsTranslations.configure.delete.removeImputationTooltip)}
          placement="top">
          <OreIconButton
            icon={<SvgBin />}
            title={t(orderImputationsTranslations.configure.delete.title)}
            onClick={() => deleteOrder(id)}
          />
        </OreTooltip>
      </OreTableCell>
    </OreTableRow>
  )
}
