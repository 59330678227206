import { OreTableCell, OreTableRow, OreText } from '@runroom/oreneta'
import React from 'react'
import { NavLink } from 'react-router-dom'
import type { TableEmptyModel } from '../../../../../domain/customComponents/table'

const TableEmpty = (props: TableEmptyModel): JSX.Element => {
  return (
    <OreTableRow>
      <OreTableCell colSpan={props.colSpan}>
        <div className="p-05">
          <OreText size="text-sm">
            {props.firstText}{' '}
            {props.linkText && props.linkUrl && (
              <NavLink to={props.linkUrl}>{props.linkText}</NavLink>
            )}{' '}
            {props.endText}
          </OreText>
        </div>
      </OreTableCell>
    </OreTableRow>
  )
}

export default TableEmpty
