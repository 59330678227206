import { OreHeading, OreStack } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { TTOrderProvider } from '../context/TTOrderProvider'
import { Stepper, TTOrderStepsData } from '../../Edenred/stepper'
import { stepper } from 'src/domain/enum'
import { Outlet } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

export const TTRechargeExcelPageTemplate = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <TTOrderProvider>
      <OreStack placeContent="normal" space="xlarge">
        <header className="width-max">
          <OreStack placeContent="normal" space="large">
            <OreHeading as="h1" size="title-md">
              {t(TTOrderTranslation.recharge.title)}
            </OreHeading>
            <Stepper
              paddingTop={0}
              paddingBottom={0}
              currentStep={stepper.firstStep}
              stepsFor={TTOrderStepsData}
              error={
                window.location.pathname === navigationRoutes.ttOrderExcelCompleteError
              }
            />
          </OreStack>
        </header>
        <Outlet />
      </OreStack>
    </TTOrderProvider>
  )
}
