import { AlignPosition } from '../../../../../domain/enum'
import type { DistributorState } from '../../../../../domain/models/cardOrder/distributors/DistributorModel'
import { Grid } from '../../layout'
import { Distributor } from '../molecules/Distributor'

interface Props {
  position: number
  distributors: DistributorState[]
}

export const DistributorsContainer = ({ position, distributors }: Props): JSX.Element => {
  return (
    <Grid gap="1rem" direction={position === AlignPosition.horizontal ? 'row' : 'column'}>
      {distributors.map((distributor: DistributorState, index: number) => {
        return <Distributor key={index} distributor={distributor} position={position} />
      })}
    </Grid>
  )
}
