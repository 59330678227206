import { Seq } from 'immutable'
import type { ChangeEvent, Dispatch, SetStateAction} from 'react';
import { useCallback } from 'react'
interface SelectorTextBoxState {
  onChangeSelector: (event: ChangeEvent<HTMLInputElement>, fieldName: string) => void
  GetTextBoxValue: (fieldName: string) => string
}
export const useSelectorTextBox = (
  selectorState: [string, string][],
  setSelectorState: Dispatch<SetStateAction<[string, string][]>>
): SelectorTextBoxState => {
  const onChangeSelector = useCallback(
    (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
      const newSelectorState: [string, string][] = []
      Seq(selectorState).forEach(state => {
        if (state[0] === fieldName) newSelectorState.push([state[0], event.target.value])
        else newSelectorState.push(state)
      })
      setSelectorState(newSelectorState)
    },
    [selectorState]
  )

  const GetTextBoxValue = (fieldName: string): string => {
    const textBoxValue = Seq(selectorState)
      .filter(state => state[0] === fieldName)
      .get(0)?.[1]
    if (!textBoxValue) return ''
    return textBoxValue
  }

  return { onChangeSelector, GetTextBoxValue }
}
