import { SvgEdit, SvgShow, SvgUnsubscribeUser } from '@runroom/oreneta'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { addCurrencyPPS, getUndefinedDashString } from '../../../../../core/services'
import { EmployeesService } from '../../../../../core/services/employeesService'
import type { EmployeesRequestBody } from '../../../../../domain/bodyRequest/EmployeesRequestBody'
import type {
  CaptionModel,
  FilterModel,
  FooterModel,
  HeaderModel,
  RowModel,
  SelectorModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import {
  MetaStatusCodes,
  NotificationSeverity,
  browserStorageKeys,
  edenredProducts,
  queryParam,
} from '../../../../../domain/enum'
import type {
  EmployeesWithLastCard,
  EmployeesWithLastCardResponse,
} from '../../../../../domain/models'
import { forms } from '../../../../../domain/translations'
import { employeesTranslation } from '../../../../../domain/translations/cardOrder/employees'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useModalController } from '../../../Edenred'
import { useEmployeesSharedController } from '../../hooks/useEmployeesSharedController'

interface ControllerState {
  header: HeaderModel
  selector: SelectorModel
  caption: CaptionModel
  rows: RowModel[]
  footer: FooterModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  downloadExcel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  tableEmpty: TableEmptyModel
}

export const useEmployeesListTableTRController = (): ControllerState => {
  const { t } = useTranslation()
  const [rows, setRows] = useState<RowModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [employees, setEmployees] = useState<EmployeesWithLastCard[]>([])
  const [caption, setCaption] = useState<CaptionModel>({
    title: t(employeesTranslation.table.caption.employeesList),
    description: t(employeesTranslation.table.caption.totalEmployees),
  })
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()
  const productCode = edenredProducts.ticketRestaurant
  const [query, setQuery] = useState<EmployeesRequestBody>({
    productCode: productCode,
    userNameOrDNIFilter: '',
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  })
  const [filter, setFilter] = useState<FilterModel>({})
  const [employeeId, setEmployeeId] = useState<number>(0)
  const { addNotification } = useNotification()
  const { getCardStatusPill, addSuccessNotification } = useEmployeesSharedController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      { label: t(employeesTranslation.table.header.employeeNumber) },
      { label: t(employeesTranslation.table.header.balance) },
      { label: t(employeesTranslation.table.header.usageProfile) },
      { label: t(employeesTranslation.table.header.cardStatus) },
      { label: t(employeesTranslation.table.header.actions) },
    ],
  }

  const selector: SelectorModel = {
    buttonTitle: '',
    description: '',
    selectors: [],
    title: '',
  }

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const tableEmpty: TableEmptyModel = {
    colSpan: 5,
    firstText: t(employeesTranslation.table.empty),
  }

  const getEmployees = async (): Promise<EmployeesWithLastCardResponse> => {
    startLoading()
    return new Promise<EmployeesWithLastCardResponse>(resolve => {
      EmployeesService()
        .GetEmployees(query)
        .then(response => {
          setEmployees(response.data.rows)
          setTotalRows(response.data.totalRows)
          resolve(response.data)
        })
        .finally(() => stopLoading())
    })
  }

  const GetRows = async () => {
    const employeesRows: RowModel[] = []
    Seq(employees).forEach((employee: EmployeesWithLastCard) => {
      const employeeRow: RowModel = {
        rowId: +employee.userId,
        cells: [
          {
            type: 'text',
            cellText: [
              { text: employee.userName, name: 'name', size: 'text-sm', isBold: true },
              {
                text: getUndefinedDashString(employee.document),
                name: 'document',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: getUndefinedDashString(employee.employeeNumber),
                name: 'employeeNumber',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: addCurrencyPPS(employee.balance, employee.isPPSBalanceRetrieved),
                name: 'balance',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: getUndefinedDashString(employee.usageProfile),
                name: 'restrictionProfile',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'element',
            cellElement: getCardStatusPill(employee.cardStatusId),
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'download',
                icon: <SvgUnsubscribeUser />,
                type: 'iconButton',
                tooltip: t(employeesTranslation.table.header.deleteTooltip),
                onClick: () => onClickDelete(employee.document),
              },
              {
                name: 'download',
                icon: <SvgShow />,
                type: 'iconButton',
                tooltip: t(employeesTranslation.table.header.viewTooltip),
                onClick: onClickEdit,
              },
            ],
          },
        ],
      }
      employeesRows.push(employeeRow)
    })
    setRows(employeesRows)
  }

  const SetPopupButtons = (document: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.unsubscribe),
        category: 'danger',
        onClick: () => deleteEmployee(document),
        size: 'large',
      },
    ]
  }

  const deleteEmployee = (document: string): void => {
    setShowPopup(false)
    startLoading()
    EmployeesService()
      .deleteEmployeeByDocument(document)
      .then(response => {
        if (response?.meta?.status === 'SUCCESS') {
          setEmployeeId(0)
          setQuery({
            ...query,
            paginationModel: {
              numberOfRecordsPerPage: pageSize,
              pageNumber: 0,
            },
          })
          setPage(1)
          addSuccessNotification(response.data, false)
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .finally(() => stopLoading())
  }

  const onClickEdit = (rowId: number): void => {
    const employeeToEdit = employees.find(
      (empl: EmployeesWithLastCard) => empl.userId === rowId.toString()
    )
    sessionStorage.setItem(
      browserStorageKeys.employeeProfile,
      JSON.stringify(employeeToEdit)
    )
    const employeeDocument = employeeToEdit?.document || ''
    navigate(
      `${navigationRoutes.employeeProfile.replace(':document', employeeDocument)}?${
        queryParam.product
      }=${edenredProducts.ticketRestaurant}`
    )
  }

  const onClickDelete = (document: string): void => {
    setShowPopup(true)
    setButtonsPopup(SetPopupButtons(document))
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: true,
        totalRows: totalRows,
      },
    })
  }

  const getFilters = (): void => {
    setFilter({
      search: {
        textBox: {
          fields: ['creditNoteNumber', 'orderNumber'],
          placeHolder: t(employeesTranslation.filter.textBox),
          value: '',
        },
        searchButton: t(forms.buttons.search),
      },
    })
  }

  const onClickFilter = (): void => {
    // event.preventDefault()
    setQuery({
      ...query,
      userNameOrDNIFilter: filter.search?.textBox.value
        ? filter.search?.textBox.value
        : '',
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const downloadExcel = (): void => {
    startLoading()
    EmployeesService()
      .downloadExcel(productCode)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = 'ListaEmpleados.xlsx'
          link.click()
          link.remove()
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  useEffect(() => {
    if (rows.length > 0) {
      setCaption(prevCaption => {
        return {
          ...prevCaption,
          rowCount: totalRows,
        }
      })
    }
  }, [rows])

  useEffect(() => {
    GetRows()
    getFooter()
  }, [employees])

  useEffect(() => {
    getEmployees()
  }, [query])

  useEffect(() => {
    setTitlePopup(t(employeesTranslation.table.deleteModal.title))
    setDescriptionPopup(t(employeesTranslation.table.deleteModal.description))
    getFilters()
  }, [])

  return {
    header,
    selector,
    caption,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    downloadExcel,
    tableEmpty,
  }
}
