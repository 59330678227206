import { OreHeading, OreMessageBar, OreText } from '@runroom/oreneta'
import { Modal } from 'src/presentation/components/Edenred'
import { useTGDOrderSummaryContainer } from '../hooks/useTGDOrderSummaryContainer'
import { TGDOrderSummaryTable } from '../molecules/TGDOrderSummaryTable'
import { TGDOrderSummaryForm } from '../molecules'
import { TGDOrderSummaryButtons } from '../molecules/TGDOrderSummaryButtons'
import { TGDOrderSaveAndClose } from '../../TGDSaveAndCloseModal/organism/TGDOrderSaveAndClose'
import { useLocation } from 'react-router-dom'
import { TGDOrderSummaryExcelTable } from '../molecules/TGDOrderSummaryExcelTable'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'

export const TGDOrderSummaryContainer = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const data: any = location.state
  const props = useTGDOrderSummaryContainer(data ? data.isExcel : false)
  const {
    showDeleteModal,
    setShowDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
  } = props

  return (
    <>
      {props.existChildsOlderThan3 && (
        <OreMessageBar color="warning">
          {t(TGDOrderTranslation.summaryAssignment.messageBar.childsOlderThan3Warning)}
        </OreMessageBar>
      )}
      {data && data.isExcel ? (
        <TGDOrderSummaryExcelTable />
      ) : (
        <TGDOrderSummaryTable tgdProps={props} />
      )}
      <TGDOrderSummaryForm tgdProps={props} isExcel={data && data.isExcel} />
      <TGDOrderSummaryButtons tgdProps={props} isExcel={data && data.isExcel} />
      <Modal
        handleOnClose={setShowDeleteModal}
        open={showDeleteModal}
        buttons={deletePopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{deleteModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {deleteModalDescription}
          </OreText>
        </>
      </Modal>
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>
      <TGDOrderSaveAndClose
        setShowSaveCloseModal={props.setShowSaveCloseModal}
        showSaveCloseModal={props.showSaveCloseModal}
      />
    </>
  )
}
