import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import type { EventInfo } from '@ckeditor/ckeditor5-utils'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import type { EditorConfig } from '@ckeditor/ckeditor5-core'
import { OreLabel, OreList, OreListItem, OreStack, OreText } from '@runroom/oreneta'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useTemplatesEdit } from 'src/Flex/CommunicationsTemplates/ui/templates-detail'
import { ResetButton } from 'src/Flex/Shared/ui/ResetButton'
import { forms } from 'src/domain/translations'

interface ITemplateEditCkEditorProps {
  setDirty: (dirty: boolean) => void
}

export const TemplatesEditCkEditor = ({
  setDirty,
}: ITemplateEditCkEditorProps): JSX.Element => {
  const {
    form: { register, setValue, formState },
    model,
    initialState,
  } = useTemplatesEdit()

  const [editorData, setEditorData] = useState<string>('')

  const editorDataEmpty =
    editorData.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '') === ''

  useEffect(() => {
    const bodyEmail = model?.bodyEmail || model?.defaultText || ''

    setEditorData(bodyEmail)
  }, [model?.bodyEmail])

  const { t } = useTranslation()

  const reset = (): void => {
    if (model?.defaultText) {
      setEditorData(model.defaultText)
    }
  }

  const onChangeHandler = (_: EventInfo<string, unknown>, editor: ClassicEditor) => {
    setEditorData(editor.getData())
    setValue('bodyEmail', editor.getData())

    if (initialState && editor.getData() !== initialState) {
      setDirty(true)
    } else {
      setDirty(false)
    }
  }

  const onBlurHandler = (_: EventInfo<string, unknown>, editor: ClassicEditor) => {
    if (initialState && editor.getData() !== initialState) {
      setDirty(true)
    } else {
      setDirty(false)
    }
  }

  const toolBarConfiguration: EditorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
    ],
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph',
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1',
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2',
        },
      ],
    },
  }

  return (
    <>
      <OreStack space="medium" placeContent="stretch">
        <OreText bold>
          {t(flexCommunicationsTemplatesTranslations.configure.form.description.text)}
        </OreText>
        <OreText>
          {t(flexCommunicationsTemplatesTranslations.configure.form.description.list)}
        </OreText>
        <OreList bullet>
          {model?.availableVars && model.availableVars.length > 0
            ? model.availableVars.map(v => (
                <OreListItem key={v.name}>
                  <OreText>
                    {v.name} (
                    {v.required
                      ? t(
                          flexCommunicationsTemplatesTranslations.configure.form
                            .description.required
                        )
                      : t(
                          flexCommunicationsTemplatesTranslations.configure.form
                            .description.optional
                        )}
                    ): {v.description}
                  </OreText>
                </OreListItem>
              ))
            : null}
        </OreList>
        <OreStack space="xsmall" placeContent="stretch">
          <OreLabel>
            {t(flexCommunicationsTemplatesTranslations.configure.form.text.label)}*
          </OreLabel>
          <CKEditor
            {...register('bodyEmail')}
            onChange={onChangeHandler}
            editor={ClassicEditor}
            config={toolBarConfiguration}
            data={editorData}
            onBlur={onBlurHandler}
          />
          {formState.errors.bodyEmail && editorDataEmpty ? (
            <OreText size="text-xs" tone="error">
              {t(forms.errors.fieldRequired)}
            </OreText>
          ) : null}
        </OreStack>
        {model?.defaultText ? (
          <ResetButton
            reset={reset}
            text={t(flexCommunicationsTemplatesTranslations.configure.form.resetTitle)}
            button={t(flexCommunicationsTemplatesTranslations.configure.form.resetButton)}
          />
        ) : null}
      </OreStack>
    </>
  )
}
