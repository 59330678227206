import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { MetaModel } from 'src/domain/models'
import { useNotificationHandler } from 'src/presentation/hooks'
import { attachFormErrors } from 'src/Flex/Shared/ui/Form'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

interface IOptions {
  notifySuccess?: boolean
  successMessage?: string
  resetForm?: boolean
}

const validateMetaResponse = (meta: MetaModel): boolean =>
  meta.status === MetaStatusCodes.SUCCESS

export const useMetaResponseHandler = () => {
  const { notificationHandler } = useNotificationHandler()
  const { addNotification } = useNotification()

  const handleMetaResponse = <T extends FieldValues>(
    meta: MetaModel | undefined,
    form?: UseFormReturn<T>,
    options: IOptions = {
      notifySuccess: undefined,
      successMessage: undefined,
      resetForm: undefined,
    }
  ): boolean => {
    if (!meta) return false

    const { notifySuccess = true, successMessage, resetForm = true } = options

    if (validateMetaResponse(meta)) {
      if (form && resetForm) form.reset(form.getValues())
      if (notifySuccess) {
        successMessage
          ? addNotification(successMessage, NotificationSeverity.success)
          : notificationHandler(meta, true)
      }

      return true
    }

    const attachableFormErrors = form ? attachFormErrors(form, meta.messages) : false

    if (!attachableFormErrors) {
      notificationHandler(meta)
    }

    return false
  }

  return { handleMetaResponse }
}
