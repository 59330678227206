import {
  OreButton,
  OreHeading,
  OreIcon,
  OreStack,
  OreText,
  SvgDownload,
  SvgWarning,
} from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { forms } from 'src/domain/translations'
import { Modal, Table } from 'src/presentation/components/Edenred'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useEmployeesListTableTRController } from '../hooks'

const EmployeesListTableTR = (): JSX.Element => {
  const {
    header,
    selector,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    downloadExcel,
    tableEmpty,
  } = useEmployeesListTableTRController()
  const { t } = useTranslation()
  return (
    <div role="tabpanel" style={{ marginTop: '2rem' }}>
      <Table
        header={header}
        selector={selector}
        rows={rows}
        setRows={setRows}
        tableBodyValign="middle"
        footer={footer}
        filter={filter}
        setFilter={setFilter}
        onClickFilter={onClickFilter}
        tableEmpty={tableEmpty}
      />
      <div className="mt-2">
        <Grid justify="flex-end">
          <OreButton
            as="button"
            category="primary"
            size="large"
            icon={<SvgDownload />}
            onClick={downloadExcel}>
            {t(forms.buttons.downloadExcel)}
          </OreButton>
        </Grid>
      </div>
      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        buttons={popupButtons}
        closeIcon={false}>
        <>
          <OreStack placeItems="start" direction="row">
            <OreIcon
              size="small"
              tone="neutral"
              icon={<SvgWarning color="var(--color-warning-500)" />}
            />
            <OreHeading size="headline-lg">{popupTitle}</OreHeading>
          </OreStack>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {popupDescription}
          </OreText>
          <OreText align="left" as="p" bold size="text-md" tone="neutral">
            {t(employeesTranslation.table.deleteModal.confirmDeleteDescription)}
          </OreText>
        </>
      </Modal>
    </div>
  )
}

export default EmployeesListTableTR
