import { Navigate } from 'react-router-dom'
import { useAuth } from '../context/auth/AuthProvider'

interface ProtectedViewProps {
  by: string | string[]
  children: JSX.Element
}

export const ProtectedView = ({ by, children }: ProtectedViewProps): JSX.Element => {
  let allowed = false
  const { permissionTags } = useAuth()

  if (typeof by === 'object' && by.some(tag => permissionTags.includes(tag))) {
    allowed = true
  }

  if (typeof by === 'string' && permissionTags.includes(by)) {
    allowed = true
  }

  if (!allowed) {
    return <Navigate to="/" replace />
  }

  return children
}
