import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { viewport } from '../../../../../domain/enum'
import { useUI } from '../../../../context/ui/UIProvider'
import { useWindowSize } from './useWindowSize'
import {
  navigationRoutes,
  flexNavigationRoutes,
} from 'src/config/constants/navigationRoutes'
import {
  isFlexRoute,
  isGeneralRoute,
} from 'src/presentation/components/Edenred/navigation/helpers'
import { getSUBHomePage } from 'src/core/services'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface SidebarState {
  hover: boolean
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  openBackdrop: boolean
  sidebarTab: number
  changeTab: (tab: number) => void
}

export const useSidebar = (): SidebarState => {
  const navigate = useNavigate()
  const location = useLocation()

  const [hover, setHover] = useState<boolean>(false)
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false)
  const [sidebarTab, setSidebarTab] = useState<number>(0)
  const { permissionTags } = useAuth()

  const { sidebarCollapsed, closeSidebar, openSidebar } = useUI()
  const { width } = useWindowSize()

  const handleMouseEnter = (): void | null =>
    width < viewport.desktop ? null : setHover(true)

  const handleMouseLeave = (): void | null =>
    width < viewport.desktop ? null : setHover(false)

  const showBackdrop = (): void => {
    if (!sidebarCollapsed && width < viewport.desktop) {
      setOpenBackdrop(true)
    } else {
      setOpenBackdrop(false)
    }
  }

  const setInitialSidebarState = (): void => {
    if (width < viewport.desktop) {
      closeSidebar()
    } else {
      openSidebar()
    }
  }

  const changeTab = (tab: number, url = false): void => {
    setSidebarTab(tab)

    if (url) return

    navigate(tab === 0 ? getSUBHomePage(permissionTags) : flexNavigationRoutes.flex)
  }

  useEffect(() => {
    showBackdrop()
  }, [sidebarCollapsed, width])

  useEffect(() => {
    setInitialSidebarState()
  }, [width])

  useEffect(() => {
    if (isGeneralRoute(location)) {
      changeTab(0, true)

      return
    }

    if (isFlexRoute(location)) {
      changeTab(1, true)
    }
  }, [])

  return {
    hover,
    handleMouseEnter,
    handleMouseLeave,
    openBackdrop,
    sidebarTab,
    changeTab,
  }
}
