import { getLastCardOrderTypeDescriptionById } from '../../../core/services'
import type { LastOrderModel } from '../../models/cardOrder/LastOrderModel'

export const getLastOrdersMap = (response: LastOrderModel[]): LastOrderModel[] => {
  if (!response) return []

  const list: LastOrderModel[] = []
  response.forEach((item: LastOrderModel) => {
    const tmpItem: LastOrderModel = {
      orderId: item.orderId,
      invoiceNumber: item.invoiceNumber,
      invoiceTotalAmount: item.invoiceTotalAmount ? item.invoiceTotalAmount + '€' : '0€',
      orderCreationDate: new Date(item.orderCreationDate).toLocaleDateString(),
      orderType: item.orderType,
      orderTypeDescription: getLastCardOrderTypeDescriptionById(item.orderType),
      productCode: item.productCode,
      productName: item.productName,
      status: item.status,
      statusDescription: item.statusDescription,
      isOrderCreatedByExcel: item.isOrderCreatedByExcel,
      isOrderFavorite: item.isOrderFavorite,
    }
    list.push(tmpItem)
  })
  return list
}
