import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OreCheckbox, OreText } from '@runroom/oreneta'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { EmployeeForm, useDownloader } from 'src/Flex/Employees/ui/employee-detail'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { LegalDocumentType } from 'src/domain/enum'
import { forms } from 'src/domain/translations'

export const ConfigureEmployeesConditionsForm = (): JSX.Element => {
  const { t } = useTranslation()
  const { register, formState } = useFormContext<EmployeeForm>()
  const { errors } = handleErrors(formState)
  const { downloadLegalDocument } = useDownloader()

  return (
    <>
      <OreCheckbox
        {...register('acceptConditions')}
        {...errors('acceptConditions')}
        label={
          <>
            {t(flexEmployeesTranslations.configure.common.form.conditions.label)}
            <button
              type="button"
              onClick={() =>
                downloadLegalDocument(
                  LegalDocumentType.flexPrivacyPolicy,
                  t(flexEmployeesTranslations.configure.common.form.conditions.filename)
                )
              }
              style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {t(flexEmployeesTranslations.configure.common.form.conditions.link)}
            </button>
          </>
        }
        required
      />
      <OreText>{t(forms.errors.fieldsRequired)}</OreText>
    </>
  )
}
