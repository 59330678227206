import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import DropZone from '../molecules/DropZone'
import { usePayrollCustomController } from '../hooks/usePayrollCustomController'
import { OreButton, OreDivider, OreStack, SvgArrowLeft } from '@runroom/oreneta'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PayrollColumnNameEnum } from 'src/Flex/Payrolls/domain/PayrollCustomModel'

export const DropContainer = () => {
  const { itemsList, columns, addNewItem, onDragEnd, setItemsList } =
    usePayrollCustomController()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const savePayrollConfiguration = () => {
    const itemsToSave = columns
      .filter(column => column.columnName === PayrollColumnNameEnum.MY_PAYROLL)
      .flatMap(column => column.itemsId)
    const filteredItems = itemsList.filter(item =>
      itemsToSave.includes(item.id.toString())
    )
    console.log(filteredItems)
  }

  return (
    <>
      <div
        style={{
          margin: 0,
          minHeight: 280,
        }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map(({ id, title, columnName, itemsId }) => (
            <DropZone
              key={id}
              id={id}
              title={title}
              allItems={itemsList}
              setItemsList={setItemsList}
              itemsId={itemsId}
              columnName={columnName}
              addNewItem={addNewItem}
            />
          ))}
        </DragDropContext>
      </div>
      <OreDivider space="medium" />
      <OreStack direction="row" placeContent="space-between">
        <OreButton
          as="a"
          category="tertiary"
          icon={<SvgArrowLeft />}
          onClick={() => navigate(-1)}>
          {t(forms.buttons.goBack)}
        </OreButton>
        <OreButton as="a" category="primary" onClick={savePayrollConfiguration}>
          {t(forms.buttons.save)}
        </OreButton>
      </OreStack>
    </>
  )
}
