import { getKindergartenSearch } from 'src/config/constants/endpoints'
import { createQueryString } from 'src/core/helpers'
import { HttpModel } from 'src/domain/models'
import {
  KindergartenSearchResponse,
  SingleKindergartenSearchRequestBody,
} from 'src/domain/models/TGDOrder'
import { HttpMethod, HttpRequest } from 'src/domain/protocols'
import { axiosFetch } from '../../axios'
import { SearchKindergartenRequestBody } from 'src/domain/models/TGDOrder/SearchKindergarten'

export class KindergartenRepository {
  async GetSingleKindergarten(
    filters: SingleKindergartenSearchRequestBody
  ): Promise<HttpModel<KindergartenSearchResponse>> {
    const { url, method, body }: HttpRequest = {
      url: getKindergartenSearch,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async GetKindergartenList(
    filters: SearchKindergartenRequestBody
  ): Promise<HttpModel<KindergartenSearchResponse>> {
    const { url, method, body }: HttpRequest = {
      url: getKindergartenSearch,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }
}
