import { communicationsTemplatesService } from 'src/Flex/CommunicationsTemplates/application/communicationsTemplatesService'
import {
  TemplateModel,
  TemplateTargetEnum,
} from 'src/Flex/CommunicationsTemplates/domain'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useTranslation } from 'react-i18next'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

interface IUseSendEmail {
  getAvailableTemplatesById: (
    id: string,
    target: TemplateTargetEnum
  ) => Promise<Partial<TemplateModel>[]>
  sendEmailToTarget: (
    id: string[],
    target: TemplateTargetEnum,
    templateId: string
  ) => Promise<boolean>
  sendPendingEmails: () => Promise<boolean>
}

export const useSendEmail = (): IUseSendEmail => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()

  const getAvailableTemplatesById = async (
    id: string,
    target: TemplateTargetEnum
  ): Promise<Partial<TemplateModel>[]> => {
    startLoading()

    const response = await communicationsTemplatesService().GetAvailableTemplatesById(
      id,
      target
    )

    stopLoading()

    if (response.data) {
      return response.data
    }

    return []
  }

  const sendEmailToTarget = async (
    id: string[],
    target: TemplateTargetEnum,
    templateId: string
  ): Promise<boolean> => {
    startLoading()

    const response = await communicationsTemplatesService().SendTemplateById(
      id,
      target,
      templateId
    )

    stopLoading()

    return handleMetaResponse(response?.meta, undefined, {
      successMessage:
        target === TemplateTargetEnum.employee
          ? t(flexCommunicationsTemplatesTranslations.sendModal.success)
          : t(flexCommunicationsTemplatesTranslations.sendModal.multipleSuccess, {
              count: response.count,
            }),
    })
  }

  const sendPendingEmails = async (): Promise<boolean> => {
    startLoading()

    const response = await communicationsTemplatesService().SendPendingEmails()

    stopLoading()

    return handleMetaResponse(response?.meta, undefined, {
      successMessage: t(
        flexCommunicationsTemplatesTranslations.sendModal.multipleSuccess,
        {
          count: response.count,
        }
      ),
    })
  }

  return { getAvailableTemplatesById, sendEmailToTarget, sendPendingEmails }
}
