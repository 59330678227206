import { RepositoryFactory } from '../factories/RepositoryFactory'
import { Repositories } from '../../domain/enum/repositories'
import type { DeliverySiteModel } from '../../domain/models'
import type { ComboBoxOptionModel } from '../../domain/customComponents'
import { unavailableMessengerAreas } from 'src/domain/constants/deliverySiteConstants'

export const deliverySiteService = () => {
  return RepositoryFactory(Repositories.deliverySites)
}

export const getDeliverySitesByGroup = (
  deliverySites: DeliverySiteModel[]
): ComboBoxOptionModel[] => {
  const options: ComboBoxOptionModel[] = []
  options.push(
    getDeliverySiteGrouped(
      deliverySites?.filter((value: DeliverySiteModel) => value.isFavorite),
      'Favoritos'
    )
  )
  options.push(
    getDeliverySiteGrouped(
      deliverySites?.filter(
        (value: DeliverySiteModel) =>
          value.contactType === 1 && value.isFavorite === false
      ),
      'Empresariales'
    )
  )
  options.push(
    getDeliverySiteGrouped(
      deliverySites?.filter(
        (value: DeliverySiteModel) =>
          value.contactType === 3 && value.isFavorite === false
      ),
      'Otros'
    )
  )
  options.push(
    getDeliverySiteGrouped(
      deliverySites?.filter(
        (value: DeliverySiteModel) =>
          value.contactType === 2 && value.isFavorite === false
      ),
      'Particulares'
    )
  )
  return options
}

const getDeliverySiteGrouped = (
  deliverySites: DeliverySiteModel[],
  groupName: string
): ComboBoxOptionModel => {
  const options: [string, number][] = []
  deliverySites.map(deliverySite => {
    options.push([
      deliverySite.alias +
        ' - ' +
        deliverySite.contactName +
        ' - ' +
        deliverySite.streetName +
        ', ' +
        deliverySite.streetNumber +
        ', ' +
        deliverySite.zipCode +
        ', ' +
        deliverySite.province,
      deliverySite.deliveryPointId,
    ])
  })
  return {
    groupName: groupName,
    options: options,
  }
}

export const isMessengerServiceUnavailableInArea = (province: string) => {
  return unavailableMessengerAreas.includes(province)
}
