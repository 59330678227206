export interface PayrollCustomModel {
  id: string
  standardId: number
  name: string
  customName: string
  order: number
  isCustom: boolean
}

export interface PayrollExcelConfigurationResponse {
  defaultColumnList: PayrollCustomModel[]
  customColumnList: PayrollCustomModel[]
  remainingColumnList: PayrollCustomModel[]
}

export enum PayrollColumnNameEnum {
  MY_PAYROLL = 1,
  HIDE_ITEMS = 2,
}

export enum PayrollColumnEnum {
  STANDARD_ID = 23,
}
