import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreText,
} from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { FormProvider } from 'react-hook-form'
import { useChangePasswordController } from '../hooks/useChangePasswordController'
import { MessageBar, Textbox } from '../../../Edenred'
import { forms } from '../../../../../domain/translations'

export const ChangePassword = (): JSX.Element => {
  const { t } = useTranslation()
  const { form, changePassword, getPasswordForm, errorMessage } =
    useChangePasswordController()
  return (
    <OreCardContainer>
      <OreCardWrapper space="large">
        <OreHeading as="h1" size="headline-sm">
          {t(accountSettingsTranslation.changePassword.title)}
        </OreHeading>

        <OreDivider space="medium" />

        <div className="pb-105">
          <OreText>{t(accountSettingsTranslation.changePassword.description)}</OreText>
        </div>
        <div>
          <FormProvider {...form}>
            <form>
              <Textbox
                textBoxList={getPasswordForm()}
                register={form.register}
                errors={form.formState.errors}
              />
            </form>
          </FormProvider>
        </div>
        <MessageBar color="error" message={errorMessage} />
        <div className="py-2">
          <OreButton
            as="button"
            category="primary"
            onClick={form.handleSubmit(changePassword)}
            size="large">
            {t(forms.buttons.save)}
          </OreButton>
        </div>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
