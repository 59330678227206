import type { Dispatch } from 'react'
import { useState } from 'react'
import type { PopupButtonModel } from '../../../../domain/customComponents/Popup'

export interface ModalState {
  show: boolean
  title: string
  description: string
  buttons: PopupButtonModel[]
  htmlElementModal: JSX.Element
  setShow: Dispatch<React.SetStateAction<boolean>>
  setTitle: Dispatch<React.SetStateAction<string>>
  setDescription: Dispatch<React.SetStateAction<string>>
  setButtons: Dispatch<React.SetStateAction<PopupButtonModel[]>>
  setHtmlElementModal: Dispatch<React.SetStateAction<JSX.Element>>
}

export const useModalController = (): ModalState => {
  const [show, setShow] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [buttons, setButtons] = useState<PopupButtonModel[]>([])
  const [htmlElement, setHtmlElement] = useState<JSX.Element>(<></>)

  return {
    show,
    title,
    description,
    buttons,
    htmlElementModal: htmlElement,
    setShow,
    setTitle,
    setDescription,
    setButtons,
    setHtmlElementModal: setHtmlElement,
  }
}
