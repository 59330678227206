import {
  OreRadioButton,
  OreStack,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
} from '@runroom/oreneta'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { getUndefinedDashString } from 'src/core/services'
import { EmployeesNurseryModel } from 'src/domain/models/employeesNursery'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'

interface Props {
  employeeTGD: EmployeesNurseryModel
  selectedEmployee: EmployeesNurseryModel | undefined
  setSelectedEmployee: Dispatch<SetStateAction<EmployeesNurseryModel | undefined>>
}

export const TGDSelectSingleEmployeeRow = ({
  employeeTGD,
  selectedEmployee,
  setSelectedEmployee,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const maxChilds = 3
  return (
    <OreTableRow>
      <OreTableCell align="left" as="td" size="auto">
        <OreStack direction="row">
          {employeeTGD.userSons?.length === maxChilds ? (
            <OreTooltip text={t(TGDOrderTranslation.employees.disabledTooltip)}>
              <OreRadioButton
                label=""
                name="userId"
                value={employeeTGD.userId}
                disabled={employeeTGD.userSons?.length === maxChilds}
                checked={selectedEmployee?.userId === employeeTGD.userId}
                onChange={e => setSelectedEmployee(employeeTGD)}
              />
            </OreTooltip>
          ) : (
            <OreRadioButton
              label=""
              name="userId"
              value={employeeTGD.userId}
              disabled={employeeTGD.userSons?.length === maxChilds}
              checked={selectedEmployee?.userId === employeeTGD.userId}
              onChange={e => setSelectedEmployee(employeeTGD)}
            />
          )}
          <div>
            <OreText bold size="text-sm" tone="neutral-900">
              {employeeTGD.userName}
            </OreText>
            <OreText size="text-sm" tone="neutral-500">
              {employeeTGD.userDni}
            </OreText>
          </div>
        </OreStack>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {getUndefinedDashString(employeeTGD.employeeNumber)}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {employeeTGD.userSons?.map(child => {
          return (
            <OreText size="text-sm" tone="neutral-900" key={child.userId + Math.random()}>
              {child.userFirstName}
            </OreText>
          )
        })}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {employeeTGD.userSons?.map(child => {
          return (
            <OreText size="text-sm" tone="neutral-900" key={child.userId + Math.random()}>
              {child.lastNurseryName}
            </OreText>
          )
        })}
      </OreTableCell>
    </OreTableRow>
  )
}
