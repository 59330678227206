import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import type { TextBoxRowModel } from '../../../../../domain/customComponents'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { SearchKindergartenRequestBody } from 'src/domain/models/TGDOrder/SearchKindergarten'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { forms } from 'src/domain/translations'
import { KindergartenService } from 'src/core/services/kindergartenService'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import { edenredProducts } from 'src/domain/enum'
import { useModalController } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'

export interface KindergartenSearchProps {
  searchKindergarten: () => void
  form: UseFormReturn<SearchKindergartenRequestBody, any>
  getSearchKindergartenForm: () => TextBoxRowModel<SearchKindergartenRequestBody>[]
  errorMessage: string
  clearFilters: () => void
  allowSearch: boolean
  onPageChange: (page: number) => void
  search: () => void
  nurseryList: KindergartenModel[]
  setNurseryList: Dispatch<SetStateAction<KindergartenModel[]>>
  page: number
  pageSize: number
  totalRows: number
  handleApiLoaded: (map: any, maps: any) => any
  kindergartenSelected: KindergartenModel | undefined
  setKindergartenSelected: Dispatch<SetStateAction<KindergartenModel>>
  showNotFound: boolean
  popupShow: boolean
  popupButtons: PopupButtonModel[]
  setShowPopup: Dispatch<SetStateAction<boolean>>
  getPopupButtons(): PopupButtonModel[]
  viewOnly: boolean
}

export const useTGDOrderFormKindergartenAssignment = (
  viewOnly: boolean,
  childIndex?: number
): KindergartenSearchProps => {
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  // const { addNotification } = useNotification()
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const [nurseryList, setNurseryList] = useState<KindergartenModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const pageSize = 5
  const [allowSearch, setAllowSearch] = useState<boolean>(false)
  const [kindergartenSelected, setKindergartenSelected] = useState<KindergartenModel>(
    {} as KindergartenModel
  )
  const [showNotFound, setShowNotFound] = useState<boolean>(false)
  const [query, setQuery] = useState<SearchKindergartenRequestBody>({
    productCode: edenredProducts.ticketGuarderia,
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
      orderByField: 'nurseryName',
      sortDescending: false,
    },
  })
  const formContext = useFormContext()

  const {
    show: popupShow,
    buttons: popupButtons,
    setShow: setShowPopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const getPopupButtons = (disabledButton?: boolean): PopupButtonModel[] => {
    let buttons: PopupButtonModel[] = []
    viewOnly
      ? (buttons = [
          {
            title: t(forms.buttons.close),
            category: 'primary',
            onClick: () => setShowPopup(false),
            size: 'large',
          },
        ])
      : (buttons = [
          {
            title: t(forms.buttons.cancel),
            category: 'secondary',
            onClick: () => setShowPopup(false),
            size: 'large',
          },
          {
            title: t(TGDOrderTranslation.kindergartenAssignment.modalForm.selectButton),
            category: 'primary',
            onClick: () => selectKindergarten(),
            size: 'large',
            disabled: disabledButton,
          },
        ])
    return buttons
  }

  const selectKindergarten = () => {
    if (!viewOnly && formContext) {
      formContext.setValue(`childs.${childIndex}.kindergartenData`, kindergartenSelected)
      formContext.setValue(`childs.${childIndex}.kindergartenId`, kindergartenSelected.id)
      formContext.trigger(`childs.${childIndex}.kindergartenId`)
      setKindergartenSelected({} as KindergartenModel)
      setShowPopup(false)
    }
  }

  const handleApiLoaded = (map: any, maps: any): any => {
    if (!kindergartenSelected) return
    return new maps.Marker({
      position: {
        lat: Number(kindergartenSelected.latitude),
        lng: Number(kindergartenSelected.longitude),
      },
      map,
      title: kindergartenSelected.name,
    })
  }

  const searchKindergartenSchema = Yup.object({
    productCode: Yup.number(),
    nurseryId: Yup.number()
      .max(999999, t(forms.errors.maxAllowed))
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable(),
    nurseryName: Yup.string(),
    zipCode: Yup.string(),
    city: Yup.string(),
    province: Yup.string(),
  })

  const form = useForm<SearchKindergartenRequestBody>({
    resolver: yupResolver(searchKindergartenSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: { ...query },
  })

  const searchKindergarten = (): void => {
    setStateErrorMessage('')
    startLoading()
    KindergartenService()
      .GetKindergartenList(query)
      .then(response => {
        if (response && response.data && response.data.rows) {
          setShowNotFound(response.data.totalRows === 0)
          setNurseryList(response.data.rows)
          setTotalRows(response.data.totalRows)
        }
      })
      .finally(() => stopLoading())
  }

  const getSearchKindergartenForm =
    (): TextBoxRowModel<SearchKindergartenRequestBody>[] => {
      return [
        {
          className: 'form-atom form-atom--half',
          textBoxes: [
            {
              id: 'nurseryName',
              label: t(TGDOrderTranslation.kindergartenAssignment.modalForm.name),
              name: 'nurseryName',
              required: false,
              maxLength: 50,
            },
            {
              id: 'nurseryId',
              label: t(
                TGDOrderTranslation.kindergartenAssignment.modalForm.affiliateCode
              ),
              placeHolder: t(
                TGDOrderTranslation.kindergartenAssignment.modalForm
                  .affiliateCodePlaceholder
              ),
              name: 'nurseryId',
              type: 'number',
              required: false,
              maxLength: 6,
            },
          ],
        },
        {
          className: 'form-atom form-atom--half',
          textBoxes: [
            {
              id: 'city',
              label: t(forms.address.city),
              placeHolder: t(
                TGDOrderTranslation.kindergartenAssignment.modalForm.cityPlaceholder
              ),
              name: 'city',
              required: false,
              maxLength: 60,
            },
            {
              id: 'zipCode',
              label: t(forms.address.zipCode),
              placeHolder: t(
                TGDOrderTranslation.kindergartenAssignment.modalForm.zipCodePlaceholder
              ),
              name: 'zipCode',
              required: false,
              maxLength: 5,
            },
          ],
        },
      ]
    }

  const clearFilters = (): void => {
    form.setValue('nurseryId', undefined)
    form.setValue('city', '')
    form.setValue('nurseryName', '')
    form.setValue('zipCode', '')
    setShowNotFound(false)
    setKindergartenSelected({} as KindergartenModel)
    setNurseryList([])
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...form.getValues(),
      productCode: edenredProducts.ticketGuarderia,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
        orderByField: 'nurseryName',
        sortDescending: true,
      },
    })
    setPage(page)
  }

  const search = (): void => {
    setQuery({
      ...form.getValues(),
      productCode: edenredProducts.ticketGuarderia,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
        orderByField: 'nurseryName',
        sortDescending: false,
      },
    })
    setPage(1)
  }

  useEffect(() => {
    setButtonsPopup(getPopupButtons(true))
  }, [])

  useEffect(() => {
    setButtonsPopup(
      getPopupButtons(kindergartenSelected && kindergartenSelected.id ? false : true)
    )
  }, [kindergartenSelected])

  useEffect(() => {
    if (
      (form.getValues('nurseryName') !== '' ||
        form.getValues('city') !== '' ||
        form.getValues('nurseryId') ||
        form.getValues('zipCode') !== '') &&
      Object.keys(form.formState.errors).length === 0
    )
      setAllowSearch(true)
    else setAllowSearch(false)
  }, [form.watch()])

  useEffect(() => {
    if (allowSearch) searchKindergarten()
  }, [query])

  return {
    form,
    searchKindergarten,
    getSearchKindergartenForm,
    errorMessage,
    clearFilters,
    allowSearch,
    search,
    onPageChange,
    nurseryList,
    page,
    pageSize,
    totalRows,
    handleApiLoaded,
    kindergartenSelected,
    setKindergartenSelected,
    setNurseryList,
    showNotFound,
    popupShow,
    popupButtons,
    setShowPopup,
    getPopupButtons,
    viewOnly,
  }
}
