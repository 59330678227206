import {
  OreButton,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTooltip,
  SvgEdit,
  SvgPlus,
} from '@runroom/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { TTUnloadSummaryState } from '../hooks/useTTUnloadSummaryController'
import { TTUnloadSummaryTableRow } from '../atoms'

interface Props {
  ttProps: TTUnloadSummaryState
}
export const TTUnloadSummaryTable = ({ ttProps }: Props) => {
  const { t } = useTranslation()
  const { header, onClickDelete, onClickEdit } = ttProps
  const { ttOrders, setTTEmployeesToConfig } = useTTOrder()
  const navigate = useNavigate()

  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption
          title={t(TTOrderTranslation.unload.summary.table.title)}
          counter={
            ttOrders.length + ' ' + t(TTOrderTranslation.unload.summary.table.subtitle)
          }
        />
        <OreTableHead>
          <OreTableRow>
            {header.headerElement && (
              <OreTableCell as="th">{header.headerElement}</OreTableCell>
            )}
            {header.headerLabel.map((header, index) =>
              !header.tooltip ? (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}
                </OreTableCell>
              ) : (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}&nbsp;
                  <OreTooltip placement="top" separation="small" text={header.tooltip}>
                    <span>{header.tooltipIcon}</span>
                  </OreTooltip>
                </OreTableCell>
              )
            )}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {ttOrders && ttOrders.length <= 0 ? (
            <TableEmpty
              colSpan={6}
              firstText={t(TTOrderTranslation.unload.summary.table.emptyTable.emptyText)}
            />
          ) : (
            ttOrders.map((ttOrder: TTOrderModel, index: number) => {
              return (
                <TTUnloadSummaryTableRow
                  key={'rows' + index}
                  ttOrder={ttOrder}
                  onClickDelete={() => onClickDelete(ttOrder.id.toString())}
                  onClickEdit={() => onClickEdit(ttOrder)}
                />
              )
            })
          )}
        </OreTableBody>
      </OreTable>
      <OreTableActions>
        <OreStack placeItems="start" direction="row">
          <OreButton
            category="highlight"
            icon={<SvgPlus />}
            size="large"
            onClick={() =>
              navigate(navigationRoutes.ttUnloadSelectMultipleEmployee, {
                state: {
                  from: navigationRoutes.ttUnloadSummary,
                },
              })
            }>
            {t(TTOrderTranslation.unload.summary.table.footer.createNewUnload)}
          </OreButton>
          <OreButton
            category="highlight"
            icon={<SvgEdit />}
            size="large"
            onClick={() => {
              setTTEmployeesToConfig(ttOrders)
              navigate(navigationRoutes.ttUnloadEmployeesConfiguration, {
                state: {
                  from: navigationRoutes.ttUnloadSummary,
                },
              })
            }}>
            {t(TTOrderTranslation.unload.summary.table.footer.editAll)}
          </OreButton>
        </OreStack>
      </OreTableActions>
    </OreTableContainer>
  )
}
