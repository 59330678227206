export const deliverySiteTranslation = {
  form: {
    title: 'deliverySiteTranslation.form.title',
    new: 'deliverySiteTranslation.form.new',
    add: 'deliverySiteTranslation.form.add',
    edit: 'deliverySiteTranslation.form.edit',
    delete: 'deliverySiteTranslation.form.delete',
    list: 'deliverySiteTranslation.form.list',
    name: 'deliverySiteTranslation.form.name',
    selectDeliverySite: 'deliverySiteTranslation.form.selectDeliverySite',
    favourites: 'deliverySiteTranslation.form.favorites',
    companies: 'deliverySiteTranslation.form.companies',
    others: 'deliverySiteTranslation.form.others',
    particulars: 'deliverySiteTranslation.form.particulars',
    courier: 'deliverySiteTranslation.form.courier',
    email: 'deliverySiteTranslation.form.email',
    create: 'deliverySiteTranslation.form.create',
    dimiss: 'deliverySiteTranslation.form.dimiss',
  },

  cards: {
    addressDetail: 'deliverySiteTranslation.cards.addressDetail',
    sendDetail: 'deliverySiteTranslation.cards.sendDetail',
    deliveryType: 'deliverySiteTranslation.cards.deliveryType',
    sendTo: 'deliverySiteTranslation.cards.sendTo',
    deliveryObservations: 'deliverySiteTranslation.cards.deliveryObservations',
  },
  grid: {
    cantBeDeletedMessage: 'deliverySiteTranslation.grid.cantBeDeletedMessage',
    elementCantBeDeleted: 'deliverySiteTranslation.grid.elementCantBeDeleted',
    unmarkedFavs: 'deliverySiteTranslation.grid.unmarkedFavs',
    markAsFavs: 'deliverySiteTranslation.grid.markAsFavs',
    markedFavsQuestion: 'deliverySiteTranslation.grid.markedFavsQuestion',
    mainCantBeDeleted: 'deliverySiteTranslation.grid.mainCantBeDeleted',
    activeUserLinked: 'deliverySiteTranslation.grid.activeUserLinked',
  },
  errors: {
    maxLenght100: 'deliverySiteTranslation.errors.maxLenght100',
  },
}
