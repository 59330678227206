import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
  OreTextArea,
} from '@runroom/oreneta'
import { forms } from 'src/domain/translations'
import {
  useProductsAdherence,
  productAdherenceTranslations,
  DeclineForm,
} from 'src/Flex/Orders/ui/order-adherence'
import { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

interface IProps {
  id: string
}

export const DeclineProductAdherenceModal = forwardRef<ConfigureModalActions, IProps>(
  ({ id }, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const { t } = useTranslation()
    const {
      acceptOrRejectProductAdherence,
      form: { register, formState, handleSubmit },
      form,
    } = useProductsAdherence()
    const { errors } = handleErrors(formState)

    const saveAndClose = async (id: string): Promise<void> => {
      handleSubmit(async (data: DeclineForm) => {
        const result = await acceptOrRejectProductAdherence(id, false, data)

        if (result) {
          setOpen(false)
        }
      })()
    }

    useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
      open: (): void => {
        form.reset({
          reason: '',
        })
        setOpen(true)
      },
      close,
    }))

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <form>
            <OreModalBoxBody>
              <OreHeading align="left" size="headline-md">
                {t(productAdherenceTranslations.modal.title)}
              </OreHeading>
              <OreStack>
                <OreText align="left">
                  {t(productAdherenceTranslations.modal.description)}
                </OreText>
                <OreTextArea
                  id="reason"
                  {...register('reason')}
                  {...errors('reason')}
                  label={t(productAdherenceTranslations.modal.textarea.label)}
                  placeholder={t(productAdherenceTranslations.modal.textarea.placeholder)}
                />
              </OreStack>
            </OreModalBoxBody>
            <OreModalBoxFooter>
              <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
                {t(forms.buttons.cancel)}
              </OreButton>
              <OreButton
                type="submit"
                size="small"
                disabled={!formState.isDirty}
                onClick={event => {
                  event.preventDefault()
                  saveAndClose(id)
                }}
                category="danger">
                {t(forms.buttons.decline)}
              </OreButton>
            </OreModalBoxFooter>
          </form>
        </OreModalBox>
      </OreModal>
    )
  }
)
