import {
  flexNavigationRoutes,
  navigationRoutes,
} from 'src/config/constants/navigationRoutes'
import { viewTag } from 'src/domain/enum/permissionTags'

export const getUserHomePage = (permissionsData: string[]): string => {
  if (permissionsData.includes(viewTag.VIEW_FL_HOME)) {
    return flexNavigationRoutes.flex
  }
  if (permissionsData.includes(viewTag.VIEW_SUB_CLIENTS)) {
    return navigationRoutes.clients
  } else {
    return navigationRoutes.invoices
  }
}

export const getSUBHomePage = (permissionsData: string[]) => {
  if (permissionsData.includes(viewTag.VIEW_SUB_CLIENTS)) {
    return navigationRoutes.clients
  }
  return navigationRoutes.invoices
}
