import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import {
  deliverySiteService,
  isMessengerServiceUnavailableInArea,
} from '../../../../../core/services'
import type { RadioButtonModel } from '../../../../../domain/customComponents'
import {
  MetaStatusCodes,
  deliveryType,
  edenredProducts,
} from '../../../../../domain/enum'
import type { BasicParameters } from '../../../../../domain/forms'
import type {
  DeliverySiteModel,
  LastDeliverySiteTypeModel,
} from '../../../../../domain/models'
import { deliverySiteFormTranslation } from '../../../../../domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { OreMessageBar, OreStack, OreText } from '@runroom/oreneta'

export interface ShipmentState {
  register: UseFormRegister<DeliverySiteModel>
  errors: any
  defaultValues: any
  t: TFunction<'translation', undefined>
  shipmentRadioButtons: RadioButtonModel[]
  radioDeliveryTypeValue: string | undefined
  onChangeRadioShipmentType: (event: ChangeEvent<HTMLInputElement>) => void
}
export const useShipmentController = (
  formParameters: BasicParameters<DeliverySiteModel>,
  setRequiredPhone: Dispatch<SetStateAction<boolean>>
): ShipmentState => {
  const [shipmentRadioButtons, setShipmentRadioButtons] = useState<RadioButtonModel[]>([])
  const [radioDeliveryTypeValue, setRadioDeliveryTypeValue] = useState<
    string | undefined
  >(deliveryType.messenger.toString())
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  const {
    register,
    formState: { errors, defaultValues },
    reset,
    resetField,
    getValues,
    setValue,
    watch,
  } = useFormContext<DeliverySiteModel>()

  const watchProvince = watch('province')

  const GetDeliveryType = async (): Promise<LastDeliverySiteTypeModel> => {
    startLoading()
    return new Promise<LastDeliverySiteTypeModel>(() => {
      deliverySiteService()
        .getByLastDeliveryType(
          formParameters.productCode
            ? formParameters.productCode
            : edenredProducts.ticketRestaurant
        )
        .then(response => {
          if (response.meta.status === MetaStatusCodes.SUCCESS) {
            setRadioDeliveryTypeValue(response.data.toString())
            setValue('deliveryType', response.data)
          }
        })
        .finally(() => stopLoading())
    })
  }

  const GetShipmentRadioButtons = () => {
    setShipmentRadioButtons([
      {
        label: !isMessengerServiceUnavailableInArea(watchProvince) ? (
          t(deliverySiteFormTranslation.shipment.messengerService)
        ) : (
          <OreStack space="xsmall">
            <OreText tone="neutral-900">
              {t(deliverySiteFormTranslation.shipment.messengerService)}
            </OreText>
            <div style={{ marginBottom: 'var(--spacing-xsmall)' }}>
              <OreMessageBar color="warning">
                {t(deliverySiteFormTranslation.shipment.messengerServiceUnavailable)}
              </OreMessageBar>
            </div>
          </OreStack>
        ),
        value: deliveryType.messenger.toString(),
        subtitle: t(deliverySiteFormTranslation.shipment.info),
        tooltip: false,
        disabled: isMessengerServiceUnavailableInArea(watchProvince),
      },

      {
        label: t(deliverySiteFormTranslation.shipment.mail),
        value: deliveryType.mail.toString(),
        tooltip: true,
        subtitle: t(deliverySiteFormTranslation.shipment.mailInfo),
      },
    ])
  }

  const onChangeRadioShipmentType = (event: ChangeEvent<HTMLInputElement>) => {
    setRadioDeliveryTypeValue(event.target.value)
  }

  const SetEditModeFormValues = () => {
    if (formParameters.entity) {
      setRadioDeliveryTypeValue(formParameters.entity?.deliveryType.toString())
      setValue('deliveryType', formParameters.entity.deliveryType)
    }
  }

  useEffect(() => {
    GetShipmentRadioButtons()
    if (isMessengerServiceUnavailableInArea(watchProvince)) {
      setRadioDeliveryTypeValue(deliveryType.mail.toString())
    }
  }, [watchProvince])

  useEffect(() => {
    if (formParameters && formParameters.editMode) {
      SetEditModeFormValues()
    } else {
      GetDeliveryType()
    }
  }, [formParameters])

  useEffect(() => {
    if (!radioDeliveryTypeValue) return
    setValue('deliveryType', +radioDeliveryTypeValue)
    if (deliveryType.messenger === +radioDeliveryTypeValue) {
      setRequiredPhone(true)
    } else {
      setRequiredPhone(false)
      const telephone = getValues('telephone')
      resetField('telephone', {
        defaultValue: telephone,
        keepTouched: true,
      })
    }
  }, [radioDeliveryTypeValue])

  return {
    register,
    errors,
    defaultValues,
    t,
    shipmentRadioButtons,
    radioDeliveryTypeValue,
    onChangeRadioShipmentType,
  }
}
