import { yupResolver } from '@hookform/resolvers/yup'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import * as Yup from 'yup'
import { loginSiteUrl } from '../../../../../config/constants/endpoints'
import {
  accountService,
  getReasonById,
  getReasonOptions,
} from '../../../../../core/services'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import type { DeleteAccountModel } from '../../../../../domain/models/myAccount/DeleteAccountModel'
import { forms } from '../../../../../domain/translations'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useNotification } from '../../../../context/notification/NotificationProvider'
import { useModalController } from '../../../Edenred'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { MessagesModel } from 'src/domain/models'

interface UserDataState {
  reasonOptions: ComboBoxOptionModel[]
  deleteAccount: () => void
  popupTitle: string
  popupButtons: PopupButtonModel[]
  popupDescription: string
  popupShow: boolean
  setShowPopup: Dispatch<SetStateAction<boolean>>
  form: UseFormReturn<DeleteAccountModel, any>
  errorMessage: string
  redirectToLogin: () => void
}

export const useDeleteAccountController = (): UserDataState => {
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const [reasonOptions, setReasonOptions] = useState<ComboBoxOptionModel[]>(
    getReasonOptions()
  )
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const formSchema = Yup.object({
    reasonId: Yup.number()
      .required(t(forms.errors.fieldRequired))
      .min(1, t(forms.errors.fieldRequired)),
    description: Yup.string(),
    acceptConditions: Yup.bool().oneOf([true], t(forms.errors.fieldRequired)),
  })

  const form = useForm<DeleteAccountModel>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      reasonId: 0,
      reason: '',
      acceptConditions: false,
      description: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const deleteAccount = (): void => {
    startLoading()
    const deleteData: DeleteAccountModel = {
      reasonId: form.getValues('reasonId'),
      reason: getReasonById(+form.getValues('reasonId')),
      description: form.getValues('description'),
      acceptConditions: form.getValues('acceptConditions'),
    }
    accountService()
      .deleteAccount(deleteData)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(
            t(accountSettingsTranslation.deleteAccount.deletedSuccess),
            NotificationSeverity.success
          )
          setShowPopup(false)
          navigate(navigationRoutes.accountDeleted)
        } else {
          if (response?.meta?.messages.length > 0) {
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                const errorOption = error.value === 'reason' ? 'reasonId' : error.value
                form.setError(errorOption as any, {
                  type: 'custom',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
        }
      })
      .catch(() => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      })
      .finally(() => stopLoading())
  }

  const getPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: form.handleSubmit(deleteAccount),
        size: 'large',
      },
    ]
  }

  const redirectToLogin = (): void => {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = loginSiteUrl
  }

  useEffect(() => {
    setTitlePopup(t(accountSettingsTranslation.deleteAccount.modal.title))
    setDescriptionPopup(t(accountSettingsTranslation.deleteAccount.modal.description))
    setButtonsPopup(getPopupButtons())
    form.setValue('reasonId', 0)
  }, [])

  return {
    popupButtons,
    setShowPopup,
    popupShow,
    popupTitle,
    deleteAccount,
    popupDescription,
    reasonOptions,
    form,
    errorMessage,
    redirectToLogin,
  }
}
