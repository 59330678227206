import {
  OreCardContainer,
  OreCardWrapper,
  OreSpinner,
  OreStack,
  OreText,
  SvgChevronDown,
  SvgChevronUp,
} from '@runroom/oreneta'
import React, { useState } from 'react'
import YouTube from '../../../../presentation/assets/img/YouTube.svg'
import { flexVideoTutorialTranslation } from './translations'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  youtubeUrl: string
}

export const VideoTutorial = ({ title, youtubeUrl }: Props) => {
  const { t } = useTranslation()
  const [ytOpen, setYtOpen] = useState<boolean>(false)
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false)

  return (
    <>
      <OreCardContainer>
        <OreCardWrapper>
          <OreStack direction="row" placeContent="space-between" space="large">
            <OreStack direction="row" placeItems="center">
              <img src={YouTube} alt="" style={{ width: '90%' }} />
              <OreText bold>{title}</OreText>
            </OreStack>
            <OreStack direction="row" placeContent="center">
              <OreText>{t(flexVideoTutorialTranslation.seeVideo)}</OreText>
              <div
                className="hover-cursor"
                onClick={() => {
                  setYtOpen(!ytOpen)
                  setIframeLoaded(false)
                }}>
                {ytOpen ? <SvgChevronUp /> : <SvgChevronDown />}
              </div>
            </OreStack>
          </OreStack>
          {ytOpen && (
            <div className="video-responsive mt-2">
              {!iframeLoaded && <OreSpinner />}
              <iframe
                onLoad={() => setIframeLoaded(true)}
                src={youtubeUrl}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </div>
          )}
        </OreCardWrapper>
      </OreCardContainer>
    </>
  )
}
