import { OreInput } from '@runroom/oreneta'
import { get } from 'lodash'
import { useEffect, useRef } from 'react'
import type { FieldValues } from 'react-hook-form'
import type { TextBoxAdressGoogleModel } from '../../../domain/customComponents'
import { useAddressSuggestion } from '../hooks/useAddressSuggestion'

export const AdressGoogleTextBox = <TFormValues extends FieldValues>(
  props: TextBoxAdressGoogleModel<TFormValues>
): JSX.Element => {
  const inputReference = useRef<HTMLInputElement | null>(null)
  const errorMessages = get(props.errors, props.texBox.name)
  const hasError = !!(props.errors && errorMessages)
  const { ref, ...rest } = props.register(props.texBox.name)
  const { initGoogle } = useAddressSuggestion()
  useEffect(() => {
    initGoogle(inputReference)
  }, [])
  return (
    <>
      <OreInput
        {...rest}
        name={props.texBox.name}
        ref={(input: HTMLInputElement) => {
          ref(input)
          inputReference.current = input
        }}
        id={props.texBox.id}
        label={props.texBox.label}
        placeholder={props.texBox.placeHolder}
        type={props.texBox.type}
        hasError={hasError}
        errorMessage={errorMessages?.message}
        maxLength={props.texBox.maxLength}
        legend={props.texBox.legend}
        required={props.texBox.required}
        autoComplete={props.texBox.autoComplete}
      />
    </>
  )
}
