import { EmployeesNurseryModel, UserSonModel } from 'src/domain/models'
import { EmployeeProfileParentForm } from './EmployeeProfileParentForm'
import { EmployeeProfileChildForm } from './EmployeeProfileChildForm'
import React, { Fragment } from 'react'
import { OreStack } from '@runroom/oreneta'

interface EmployeeProfileFormContainerProps {
  employeeData: EmployeesNurseryModel
}

export const EmployeeProfileFormContainer = ({
  employeeData,
}: EmployeeProfileFormContainerProps): JSX.Element => {
  return (
    <OreStack placeContent="normal" space="xlarge">
      <EmployeeProfileParentForm employeeData={employeeData} />
      {employeeData.userSons?.map((son: UserSonModel, i: number) => (
        <Fragment key={son.userId + i}>
          <EmployeeProfileChildForm sonData={son} />
        </Fragment>
      ))}
    </OreStack>
  )
}
