import { Modal, Table, Textbox } from '../../../../Edenred'
import { useLastOrders } from '../hooks/useLastOrders'

export const LastOrdersContainer = () => {
  const {
    header,
    rows,
    setRows,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    footer,
    filter,
    setFilter,
    onClickFilter,
    errors,
    register,
    getFormModalFavorite,
    showModalRepetExcel,
    buttonsModalRepetExcel,
    descriptionModalRepetExcel,
    titleModalRepetExcel,
    setShowModalRepetExcel,
    tableEmpty,
    htmlModalElement,
  } = useLastOrders({ pageSize: 8, showFilter: true })

  return (
    <div role="tabpanel" className="mt-2">
      <Table
        header={header}
        rows={rows}
        setRows={setRows}
        footer={footer ? footer : undefined}
        filter={filter}
        setFilter={setFilter}
        onClickFilter={onClickFilter}
        tableBodyValign="middle"
        tableEmpty={tableEmpty}
      />
      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
        children={
          <Textbox
            errors={errors}
            register={register}
            textBoxList={getFormModalFavorite()}
          />
        }
      />
      <Modal
        handleOnClose={setShowModalRepetExcel}
        open={showModalRepetExcel}
        title={titleModalRepetExcel}
        description={descriptionModalRepetExcel}
        htmlElement={htmlModalElement}
        buttons={buttonsModalRepetExcel}
      />
    </div>
  )
}
