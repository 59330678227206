import { TFunction } from 'react-i18next'
import { RequiredStringSchema } from 'yup/lib/string'
import { forms } from 'src/domain/translations'
import { string } from 'yup'

export const nonEmptyStringSchema = (
  t: TFunction<'translation', undefined>,
  chartLimit?: number
): RequiredStringSchema<string | undefined> =>
  string()
    .defined()
    .typeError(t(forms.errors.fieldRequired))
    .required(t(forms.errors.fieldRequired))
    .test(
      'len',
      t(forms.errors.maxAllowed) + ' ' + chartLimit,
      val => val !== undefined && val.length <= (chartLimit ?? 255) === true
    )
