import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { EmployeesNurseryModel } from 'src/domain/models'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import {
  MetaStatusCodes,
  NotificationSeverity,
  edenredProducts,
  queryParam,
} from 'src/domain/enum'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import {
  DeleteClientEmployee,
  DeleteClientEmployeeResponse,
} from 'src/domain/models/cardOrder/DeleteClientEmployees'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { EmployeesService } from 'src/core/services/employeesService'
import { forms } from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'

interface ControllerState {
  employeeData: EmployeesNurseryModel | undefined
  enabledAccount: boolean
  setEnabledAccount: Dispatch<SetStateAction<boolean>>
  setShowPopupDeleteProduct: Dispatch<SetStateAction<boolean>>
  popupShowDeleteProduct: boolean
  popupTitleDeleteProduct: string
  popupDescriptionDeleteProduct: string
  popupButtonsDeleteProduct: PopupButtonModel[]
  onClickDeleteProduct: (employeeId: number) => void
}

export const useEmployeeProfileController = (
  employeeData: EmployeesNurseryModel,
  addSuccessNotification?: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void,
  productCodes?: number[],
  setProductCodes?: Dispatch<SetStateAction<number[] | undefined>>
): ControllerState => {
  const { t } = useTranslation()
  const [enabledAccount, setEnabledAccount] = useState(false)
  const { addNotification } = useNotification()
  const { startLoading, stopLoading } = useLoader()
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    show: popupShowDeleteProduct,
    title: popupTitleDeleteProduct,
    description: popupDescriptionDeleteProduct,
    buttons: popupButtonsDeleteProduct,
    setShow: setShowPopupDeleteProduct,
    setDescription: setDescriptionPopupDeleteProduct,
    setTitle: setTitlePopupDeleteProduct,
    setButtons: setButtonsPopupDeleteProduct,
  } = useModalController()

  const SetPopupButtonsDeleteProduct = (employeeId: number): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowPopupDeleteProduct(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.unsubscribe),
        category: 'danger',
        onClick: () => deleteProduct(employeeId),
        size: 'large',
      },
    ]
  }

  const deleteProduct = (employeeId: number): void => {
    if (productCodes) {
      const ids: DeleteClientEmployee[] = [
        {
          userId: employeeId,
        },
      ]
      setShowPopupDeleteProduct(false)
      startLoading()
      EmployeesService()
        .deleteEmployees(ids)
        .then(response => {
          if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
            addSuccessNotification && addSuccessNotification(response.data, true)
            const productCodesFiltered = productCodes.filter(
              prod => prod !== edenredProducts.ticketRestaurant
            )
            setProductCodes && setProductCodes(productCodesFiltered)
            setSearchParams({
              [queryParam.product]: productCodesFiltered[0].toString(),
            })
          } else {
            addNotification(
              response?.meta?.messages[0].description,
              NotificationSeverity.error
            )
          }
        })
        .finally(() => stopLoading())
    }
  }

  const onClickDeleteProduct = (employeeId: number): void => {
    setShowPopupDeleteProduct(true)
    setButtonsPopupDeleteProduct(SetPopupButtonsDeleteProduct(employeeId))
  }

  useEffect(() => {
    setTitlePopupDeleteProduct(t(employeesTranslation.table.deleteProductModal.titleTGD))
  }, [])

  return {
    employeeData,
    enabledAccount,
    setEnabledAccount,
    setShowPopupDeleteProduct,
    popupShowDeleteProduct,
    popupTitleDeleteProduct,
    popupDescriptionDeleteProduct,
    popupButtonsDeleteProduct,
    onClickDeleteProduct,
  }
}
