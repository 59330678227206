import {
  OrePill,
  SvgAlert,
  SvgInfo,
  SvgOut,
  SvgSuccess,
  SvgWarning,
} from '@runroom/oreneta'

interface EmployeeProfilePillProps {
  children: React.ReactNode
  tone: 'error-600' | 'grey' | 'cobalt-600' | 'success-600' | 'warning-600'
}

export const EmployeeProfilePill = (props: EmployeeProfilePillProps): JSX.Element => {
  switch (props.tone) {
    case 'error-600':
      return (
        <OrePill icon={<SvgAlert aria-hidden />} tone="error-600">
          {props.children}
        </OrePill>
      )
    case 'warning-600':
      return (
        <OrePill icon={<SvgWarning aria-hidden />} tone="warning-600">
          {props.children}
        </OrePill>
      )
    case 'success-600':
      return (
        <OrePill icon={<SvgSuccess aria-hidden />} tone="success-600">
          {props.children}
        </OrePill>
      )
    case 'grey':
      return (
        <OrePill icon={<SvgOut aria-hidden />} tone="grey">
          {props.children}
        </OrePill>
      )
    default:
      return (
        <OrePill icon={<SvgInfo aria-hidden />} tone="cobalt-600">
          {props.children}
        </OrePill>
      )
  }
}
