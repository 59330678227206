import { RepositoryFactory } from '../factories/RepositoryFactory'
import { Repositories } from '../../domain/enum/repositories'
import type { ComboBoxOptionModel } from '../../domain/customComponents'
import { t } from 'i18next'
import { accountSettingsTranslation } from '../../domain/translations/myAccount/accountSettings'
import { DeleteAccountReason } from '../../domain/enum/account'

export const accountService = () => {
  return RepositoryFactory(Repositories.account)
}

export const getReasonOptions = (): ComboBoxOptionModel[] => {
  const options: ComboBoxOptionModel[] = [
    {
      options: [
        [
          t(accountSettingsTranslation.deleteAccount.modal.selectReason),
          DeleteAccountReason.noReason,
        ],
        [
          t(accountSettingsTranslation.deleteAccount.modal.reasonChangeManager),
          DeleteAccountReason.changeManager,
        ],
        [
          t(accountSettingsTranslation.deleteAccount.modal.reasonFinishWork),
          DeleteAccountReason.finishWorkInCompany,
        ],
        [
          t(accountSettingsTranslation.deleteAccount.modal.reasonFinishColaboration),
          DeleteAccountReason.finishColaborationWithEdenred,
        ],
        [
          t(accountSettingsTranslation.deleteAccount.modal.reasonOther),
          DeleteAccountReason.other,
        ],
      ],
    },
  ]
  return options
}

export const getReasonById = (reasonId: number): string => {
  let reason = ''
  switch (reasonId) {
    case DeleteAccountReason.changeManager:
      reason = t(accountSettingsTranslation.deleteAccount.modal.reasonChangeManager)
      break
    case DeleteAccountReason.finishWorkInCompany:
      reason = t(accountSettingsTranslation.deleteAccount.modal.reasonFinishWork)
      break
    case DeleteAccountReason.finishColaborationWithEdenred:
      reason = t(accountSettingsTranslation.deleteAccount.modal.reasonFinishColaboration)
      break
    case DeleteAccountReason.other:
      reason = t(accountSettingsTranslation.deleteAccount.modal.reasonOther)
      break
  }
  return reason
}
