export const employeesTranslation = {
  title: {
    employees: 'employees.title.employees',
  },
  table: {
    empty: 'employeesTranslation.table.empty',
    header: {
      titular: 'employees.table.header.titular',
      email: 'employees.table.header.email',
      employeeNumber: 'employees.table.header.employeeNumber',
      documentType: 'employees.table.header.documentType',
      identityNumber: 'employees.table.header.identityNumber',
      product: 'employees.table.header.product',
      balance: 'employees.table.header.balance',
      usageProfile: 'employees.table.header.usageProfile',
      cardStatus: 'employees.table.header.cardStatus',
      expired: 'employees.table.header.expired',
      existingUsers: 'employees.table.header.existingUsers',
      sonsInCharge: 'employees.table.header.tgd.sonsInCharge',
      childs: 'employees.table.header.tgd.childs',
      costCenter: 'employees.table.header.tgd.costCenter',
      kindergarten: 'employees.table.header.tgd.kindergarten',
      kindergartenAssigned: 'employees.table.header.tgd.kindergartenAssigned',
      showSelected: 'employees.table.header.tgd.showSelected',
      userStatus: 'employees.table.header.tgd.userStatus',
      actions: 'employees.table.header.actions',
      deleteTooltip: 'employees.table.header.deleteTooltip',
      editTooltip: 'employees.table.header.editTooltip',
      viewTooltip: 'employees.table.header.viewTooltip',
      reason: 'employees.table.header.reason',
      reasonTooltip: 'employees.table.header.reasonTooltip',
      deliverySite: 'employees.table.header.deliverySite',
    },
    caption: {
      employeesList: 'employees.table.caption.employeesList',
      totalEmployees: 'employees.table.caption.totalEmployees',
    },
    deleteModal: {
      title: 'employees.table.deleteModal.title',
      description: 'employees.table.deleteModal.description',
      confirmDeleteDescription: 'employees.table.deleteModal.confirmDeleteDescription',
    },
    deleteProductModal: {
      titleTT: 'employees.table.deleteProductModal.titleTT',
      titleTR: 'employees.table.deleteProductModal.titleTR',
      titleTGD: 'employees.table.deleteProductModal.titleTGD',
      description1: 'employees.table.deleteProductModal.description1',
      description2: 'employees.table.deleteProductModal.description2',
      description3: 'employees.table.deleteProductModal.description3',
      confirmDeleteDescription:
        'employees.table.deleteProductModal.confirmDeleteDescription',
    },
  },
  profile: {
    heading: {
      removeEmployee: 'employees.profile.heading.removeEmployee',
    },
    messageBar: {
      mixClient: 'employees.profile.messageBar.mixClient',
      flexOnly: 'employees.profile.messageBar.flexOnly',
    },
    card: {
      balance: 'employees.profile.card.balance',
      balanceTooltipText: 'employees.profile.card.balanceTooltipText',
      plasticCard: 'employees.profile.card.plasticCard',
      virtualCard: 'employees.profile.card.virtualCard',
      restaurantTicked: 'employees.profile.card.restaurantTicked',
      transportTicked: 'employees.profile.card.transportTicked',
      kindergartenTicked: 'employees.profile.card.kindergartenTicked',
      removeProduct: 'employees.profile.card.removeProduct',
      status: {
        logicDestrucction: 'employees.profile.card.status.logicDestrucction',
        requested: 'employees.profile.card.status.requested',
        sent: 'employees.profile.card.status.sent',
        rejected: 'employees.profile.card.status.rejected',
        confirmed: 'employees.profile.card.status.confirmed',
        activationResquested: 'employees.profile.card.status.activationResquested',
        active: 'employees.profile.card.status.active',
        lockRequested: 'employees.profile.card.status.lockRequested',
        locked: 'employees.profile.card.status.locked',
        unlockRequested: 'employees.profile.card.status.unlockRequested',
        cancelRequested: 'employees.profile.card.status.cancelRequested',
        cancelled: 'employees.profile.card.status.cancelled',
        activationRejected: 'employees.profile.card.status.activationRejected',
        lockRejected: 'employees.profile.card.status.lockRejected',
        unlockRejected: 'employees.profile.card.status.unlockRejected',
        cancellationRejected: 'employees.profile.card.status.cancellationRejected',
      },
    },
    table: {
      header: {
        serialNumber: 'employees.profile.table.header.serialNumber',
        expireDate: 'employees.profile.table.header.expireDate',
        lastCardStatus: 'employees.profile.table.header.lastCardStatus',
        virtualCardCompatibility:
          'employees.profile.table.header.virtualCardCompatibility',
        enableAccount: 'employees.profile.table.header.enableAccount',
      },
      body: {
        confirmed: 'employees.profile.table.body.confirmed',
        pending: 'employees.profile.table.body.pending',
        compatible: 'employees.profile.table.body.compatible',
        noCompatible: 'employees.profile.table.body.noCompatible',
        enableAccount: {
          yes: 'employees.profile.table.body.enableAccount.yes',
          no: 'employees.profile.table.body.enableAccount.no',
        },
      },
    },
    form: {
      title: 'employees.profile.form.title',
      childTitle: 'employees.profile.form.childTitle',
      email: 'employees.profile.form.email',
      phone: 'employees.profile.form.phone',
      employeeNumber: 'employees.profile.form.employeeNumber',
      profileUsage: 'employees.profile.form.profileUsage',
      costCenter: 'employees.profile.form.costCenter',
      costCenterTooltipText: 'employees.profile.form.costCenterTooltipText',
      lastKindergartenAssignment: 'employees.profile.form.lastKindergartenAssignment',
    },
  },
  caption: {
    employeesList: 'employees.table.caption.employeesList',
    totalEmployees: 'employees.table.caption.totalEmployees',
  },
  filter: {
    textBox: 'employeesTranslation.filter.textBox',
  },
  notification: {
    deleteEmployee: {
      success: 'employeesTranslation.notification.deleteEmployee.success',
      successAndUnload:
        'employeesTranslation.notification.deleteEmployee.successAndUnload',
    },
    deleteProduct: {
      success: 'employeesTranslation.notification.deleteProduct.success',
      successAndUnload:
        'employeesTranslation.notification.deleteProduct.successAndUnload',
    },
  },
}
