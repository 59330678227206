import { useEmployeesListTableTTController } from '../hooks/useEmployeesListTableTTController'
import { EmployeeListTableTTFooterButtons, EmployeeListTableTT } from '../molecules'

export const EmployeesListTableTTContainer = (): JSX.Element => {
  const props = useEmployeesListTableTTController()
  return (
    <div className="py-2">
      <EmployeeListTableTT employeesTTProps={props} />
      <EmployeeListTableTTFooterButtons employeesTTProps={props} />
    </div>
  )
}
