import { useEffect } from 'react'
import { useTTOrder } from '../../context/TTOrderProvider'
import { orderType } from 'src/domain/enum'
import { TTOrderImportExcelContainer } from './organism/TTOrderImportExcelContainer'

export const TTUnloadImportExcelPage = (): JSX.Element => {
  const { setOrderContext } = useTTOrder()

  useEffect(() => setOrderContext(orderType.Unload), [])

  return <TTOrderImportExcelContainer />
}
