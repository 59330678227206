export const flexPayrollsTranslations = {
  title: 'flex.payrolls.title',
  customTitle: 'flex.payrolls.customButton',
  subtitle: 'flex.payrolls.subtitle',
  disclaimer: 'flex.payrolls.disclaimer',
  table: {
    title: 'flex.payrolls.table.title',
    header: {
      name: 'flex.payrolls.table.header.name',
      collective: 'flex.payrolls.table.header.collective',
      exemptValue: 'flex.payrolls.table.header.exempt_value',
      nonExemptValue: 'flex.payrolls.table.header.non_exempt_value',
    },
    noRows: 'flex.payrolls.table.no_rows',
    noRowsWithFilters: 'flex.payrolls.table.no_rows_with_filters',
  },
  monthPicker: {
    label: 'flex.payrolls.month_picker.label',
    placeholder: 'flex.payrolls.month_picker.placeholder',
  },
  downloadButton: {
    label: 'flex.payrolls.download_button.label',
    excel: 'flex.payrolls.download_button.excelName',
  },
  customPayroll: {
    title: 'flex.payrolls.custom.title',
    descriptipn: 'flex.payrolls.custom.description',
    descriptipn1: 'flex.payrolls.custom.description1',
    descriptipn2: 'flex.payrolls.custom.description2',
    descriptipn3: 'flex.payrolls.custom.description3',
    descriptipn3a: 'flex.payrolls.custom.description3a',
    descriptipn3b: 'flex.payrolls.custom.description3b',
    lastDescription: 'flex.payrolls.custom.lastDescription',
    myPayroll: 'flex.payrolls.custom.myPayroll',
    hideItems: 'flex.payrolls.custom.hideItems',
    dragDeleteDescription: 'flex.payrolls.custom.dragDeleteDescription',
    dragDelete2Description: 'flex.payrolls.custom.dragDelete2Description',
    dragAddDescription: 'flex.payrolls.custom.dragAddDescription',
    rename: 'flex.payrolls.custom.rename',
    addItem: 'flex.payrolls.custom.addItem',
    newItemName: 'flex.payrolls.custom.newItemName',
    recoverItems: 'flex.payrolls.custom.recoverItems',
  },
}
