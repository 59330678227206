import { Seq } from 'immutable'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'

interface FilterInputState {
  onChange: (event: ChangeEvent<HTMLInputElement>, name: string) => void
}
export const useFilterInput = (
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
): FilterInputState => {
  const onChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    setFilter(prevFilter => {
      const newFilter = { ...prevFilter }
      if (newFilter.filterFields) {
        Seq(newFilter.filterFields).forEach((fields: FilterFieldModel) => {
          Seq(fields.filterFields).forEach((field: FilterControlModel) => {
            if (field.name === name) field.value = event.target.value
          })
        })
      }
      return newFilter
    })
  }

  return { onChange }
}
