import { TGDOrderRepository } from 'src/infrastructure/repository/TGDOrder/TGDOrderRepository'
import { TGDBasicOrderRepository } from 'src/infrastructure/repository/TGDOrder/TGDBasicOrderRepository'
import { Repositories } from '../../domain/enum'
import { RepositoryFactory } from '../factories'
import { EmployeesNurseryModel, UserSonModel } from 'src/domain/models'
import {
  EmployeesTGDOrderModel,
  TGDChildModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'

export const TGDOrderService = (): TGDOrderRepository => {
  return RepositoryFactory(Repositories.tgdOrder)
}

export const TGDBasicOrderService = (): TGDBasicOrderRepository => {
  return RepositoryFactory(Repositories.tgdBasicOrder)
}

export const TGDEmployeesNurseryMap = (
  nurseryModel: EmployeesNurseryModel,
  child: UserSonModel
): EmployeesTGDOrderModel => {
  const employees: EmployeesTGDOrderModel = {
    accountBalance: nurseryModel.accountBalance,
    clientCode: nurseryModel.clientCode,
    costCenter: nurseryModel.costCenter,
    documentTypeId: nurseryModel.documentTypeId,
    employeeNumber: nurseryModel.employeeNumber,
    productCode: nurseryModel.accountBalance,
    restrictionProfileId: nurseryModel.accountBalance,
    userBirthdayDate: nurseryModel.userBirthdayDate,
    userCorporativeEmail: nurseryModel.userCorporativeEmail,
    userCorporativeTelephone: nurseryModel.userCorporativeTelephone,
    userDni: nurseryModel.userDni,
    userEmail: nurseryModel.userEmail,
    userFirstName: nurseryModel.userFirstName,
    userId: nurseryModel.userId,
    userLastName: nurseryModel.userLastName,
    userLastName2: nurseryModel.userLastName2,
    userName: nurseryModel.userName,
    userTelephone: nurseryModel.userTelephone,
    validUser: nurseryModel.validUser,
    userSon: child,
  }
  return employees
}

export const hasRepeatedChilds = (childs: TGDChildModel[]): number => {
  const nameArray = childs.map(child => child.firstName.toUpperCase())
  const birthDateArray = childs.map(child => child.birthDate?.split('T')[0])

  const sameNameIndex = nameArray.findIndex(
    (value, index) => nameArray.indexOf(value) !== index
  )
  const sameBirthDateIndex = birthDateArray.findIndex(
    (value, index) => birthDateArray.indexOf(value) !== index
  )

  if (sameNameIndex > -1) {
    return sameNameIndex
  }

  if (sameBirthDateIndex > -1) {
    return sameBirthDateIndex
  }

  return -1
}

export const hasRepeatedChildsByName = (childs: TGDChildModel[]): number => {
  const nameArray = childs.map(
    child => child.firstName.toUpperCase() + child.lastName.toUpperCase()
  )

  const sameNameIndex = nameArray.findIndex(
    (value, index) => nameArray.indexOf(value) !== index
  )

  if (sameNameIndex > -1) return sameNameIndex

  return -1
}

export const hasRepeatedChildsByBirthDate = (childs: TGDChildModel[]): number => {
  const birthDateArray = childs.map(child => child.birthDate?.split('T')[0])

  const sameBirthDateIndex = birthDateArray.findIndex(
    (value, index) => birthDateArray.indexOf(value) !== index
  )

  if (sameBirthDateIndex > -1) return sameBirthDateIndex

  return -1
}
