export const generalTermsTranslation = {
  title: 'generalTerms.title',
  productTR: 'generalTerms.productTR',
  paymentData: {
    title: 'generalTerms.paymentData.title',
    paymentMode: 'generalTerms.paymentData.paymentMode',
    paymentTerm: 'generalTerms.paymentData.paymentTerm',
    invoiceType: 'generalTerms.paymentData.invoiceType',
    invoiceTypeElectronic: 'generalTerms.paymentData.invoiceTypeElectronic',
    invoiceTypePaper: 'generalTerms.paymentData.invoiceTypePaper',
    invoiceComment: 'generalTerms.paymentData.invoiceComment',
  },
  generalInformation: {
    title: 'generalTerms.generalInformation.title',
    contractOpenDate: 'generalTerms.generalInformation.contractOpenDate',
    contractClosedDate: 'generalTerms.generalInformation.contractClosedDate',
    totalUsers: 'generalTerms.generalInformation.totalUsers',
  },
  contacts: {
    table: {
      title: 'generalTerms.contacts.table.title',
      totalContacts: 'generalTerms.contacts.table.totalContacts',
      holder: 'generalTerms.contacts.table.holder',
      responsible: 'generalTerms.contacts.table.responsible',
      email: 'generalTerms.contacts.table.email',
      phone: 'generalTerms.contacts.table.phone',
    },
  },
  economicTerms: {
    title: 'generalTerms.economicTerms.title',
    cards: 'generalTerms.economicTerms.cards',
    comissions: 'generalTerms.economicTerms.comissions',
    management: 'generalTerms.economicTerms.management',
    invoices: 'generalTerms.economicTerms.invoices',
    shipment: 'generalTerms.economicTerms.shipment',
  },
}
