import { OreText } from '@runroom/oreneta'
import { AlignPosition } from '../../../../../domain/enum'
import { useDistributorController } from '../../../Edenred/distributor/hook/useDistributorController'
import { DistributorsContainer } from '../../../Edenred/distributor/organism/DistributorsContainer'
import { clientHomeTranslation } from '../../../../../domain/translations/client/home'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { Grid, GridItem } from '../../../Edenred/layout'

export const FrequentOperationsContainerTR = (): JSX.Element => {
  const { t } = useTranslation()
  const { getCardAndRechargeDistributorTR: getCardAndRechargeDistributor } =
    useDistributorController()

  return (
    <Grid gap="2rem" direction="column">
      <GridItem>
        <p className="mt-1" style={{ maxWidth: '612px' }}>
          <OreText size="text-sm" as="span">
            {t(clientHomeTranslation.ticketRestaurant.frequentDescription1)}
          </OreText>
          <Link to={navigationRoutes.myOrdersHistory}>
            <OreText size="text-sm" as="span">
              {t(clientHomeTranslation.ticketRestaurant.frequentDescription2)}
            </OreText>
          </Link>
          <OreText size="text-sm" as="span">
            {t(clientHomeTranslation.ticketRestaurant.frequentDescription3)}
          </OreText>
        </p>
      </GridItem>
      <DistributorsContainer
        position={AlignPosition.horizontal}
        distributors={getCardAndRechargeDistributor()}
      />
    </Grid>
  )
}
