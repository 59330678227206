import {
  getPendingContractAccession,
  indexEmployeesLastAdherenceList,
  indexProductsAdherenceAcceptRejectedList,
  indexProductsAdherenceList,
} from 'src/config/constants/endpoints'
import type {
  OrderAdherenceRepositoryContract,
  ProductAdherenceModel,
  AcceptOrRejectProductAdherenceDTO,
  ProductAdherenceDeclineModel,
} from 'src/Flex/Orders/domain'

import { HttpModel, PaginationModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { edenredProducts } from 'src/domain/enum'
import { TaskModel } from 'src/Flex/TaskManager/domain'
import { EmployeesLastAdherenceModel } from '../domain/EmployeesLastAdherenceModel'

type ApiProductAdherenceData = {
  id: string
  name: string
  userName: string
  userLastName: string
  document: string
  description: string
  productType: edenredProducts
  totalAmount: number
  applicationDate: string
  beneficiaryType: number
  numberOfMonths: number
  company: string
  beneficiary: string
  schoolName: string
}

type ApiProductsAdherenceData = HttpModel<{
  pendingMembershipApplications: {
    rows: ApiProductAdherenceData[]
    totalRows: number
  }
} | null>

const convertToDomain = (
  response: ApiProductsAdherenceData
): PaginationModel<ProductAdherenceModel> => {
  return {
    totalRows: response.data?.pendingMembershipApplications.rows.length ?? 0,
    rows:
      response.data?.pendingMembershipApplications.rows.map(
        (product: ApiProductAdherenceData) => ({
          id: product.id,
          name: product.name,
          userName: product.userName,
          userLastName: product.userLastName,
          document: product.document,
          description: product.description,
          productType: product.productType,
          totalAmount: product.totalAmount,
          applicationDate: product.applicationDate,
          kinship: product.beneficiaryType,
          numberOfMonths: product.numberOfMonths,
          company: product.company,
          beneficiary: product.beneficiary,
          schoolName: product.schoolName,
        })
      ) ?? [],
  }
}

export class OrderAdherenceRepository implements OrderAdherenceRepositoryContract {
  async GetProductsAdherence(
    page?: number,
    numberOfRecordsPerPage?: number
  ): Promise<HttpModel<PaginationModel<ProductAdherenceModel>>> {
    const query =
      page && numberOfRecordsPerPage
        ? `?numberOfRecordsPerPage=${numberOfRecordsPerPage}&pageNumber=${page}`
        : ''

    const response = await axiosFetch(
      indexProductsAdherenceList + query,
      HttpMethod.GET,
      {}
    )

    return {
      data: convertToDomain(response),
      meta: response.meta,
    }
  }

  async acceptOrRejectProductAdherence(
    id: string,
    isAccepted: boolean,
    data?: ProductAdherenceDeclineModel
  ): Promise<AcceptOrRejectProductAdherenceDTO> {
    return await axiosFetch(indexProductsAdherenceAcceptRejectedList, HttpMethod.POST, {
      id,
      isAccepted,
      reason: data?.reason,
    })
  }
  async PendingContractAccession(): Promise<HttpModel<TaskModel[]>> {
    return await axiosFetch(getPendingContractAccession, HttpMethod.GET, {})
  }

  async GetEmployeesLastAdherence(
    page?: number,
    numberOfRecordsPerPage?: number
  ): Promise<HttpModel<PaginationModel<EmployeesLastAdherenceModel>>> {
    let query =
      page && numberOfRecordsPerPage
        ? `?numberOfRecordsPerPage=${numberOfRecordsPerPage}&pageNumber=${page}`
        : `?numberOfRecordsPerPage=${numberOfRecordsPerPage}`
    query += '&orderByField=accessionDate&sortDescending=true'
    const response = await axiosFetch(
      indexEmployeesLastAdherenceList + query,
      HttpMethod.GET,
      {}
    )

    return {
      data: { rows: response.data.rows, totalRows: response.data.totalRows },
      meta: response.meta,
    }
  }
}
