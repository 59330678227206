import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { forms } from '../../../../../domain/translations'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'

interface Props {
  open: boolean
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>
  orderId: number
}

export const CardOrderHomeRemoveModal = ({ open, handleOnClose, orderId }: Props) => {
  const { removeOrder } = useCardOrder()
  const { t } = useTranslation()

  return (
    <OreModal open={open} handleOnClose={() => handleOnClose(false)}>
      <OreModalBox handleOnClose={() => handleOnClose(false)}>
        <OreModalBoxBody>
          <OreHeading size="headline-lg">
            {t(cardOrderTranslation.cardOrderHome.modalDeleteTitle)}
          </OreHeading>
          <OreText>{t(cardOrderTranslation.cardOrderHome.modalDeleteBody)}</OreText>
        </OreModalBoxBody>
        <OreModalBoxFooter>
          <div className="flex flex--row ml-auto">
            <OreButton
              category="tertiary"
              size="large"
              onClick={() => handleOnClose(false)}
            >
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              category="primary"
              size="large"
              onClick={() => {
                removeOrder(orderId)
                handleOnClose(false)
              }}
            >
              {t(forms.buttons.delete)}
            </OreButton>
          </div>
        </OreModalBoxFooter>
      </OreModalBox>
    </OreModal>
  )
}
