import { OreButton, OreHeading, OreSelect, SvgPlus } from '@runroom/oreneta'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import { Form } from '../../../deliverySite/deliverySiteForm/organism/Form'
import { Modal } from '../../../Edenred'
import { useShipmentDetailsController } from '../hooks'
import { DeliverySiteSelected } from './DeliverySiteSelected'
import { ComboBoxOptionModel } from 'src/domain/customComponents'

export const ShipmentDetails = ({ title }: { title?: string }): JSX.Element => {
  const {
    deliverySitesOptions,
    register,
    errors,
    t,
    onDeliverySiteChange,
    deliverySiteSelected,
    removeDeliverySiteSelected,
    cardOrderModalShow,
    setCardOrderModalShow,
    addDeliverySiteLabel,
    deliverySiteFormParameters,
    onClickDeliverySiteModal,
    setNewDeliverySite,
  } = useShipmentDetailsController()
  return (
    <>
      <section className="form-molecule mt-3">
        <div className="pl-05">
          <OreHeading as="h2" size="headline-md">
            {title}
          </OreHeading>
        </div>
        <div className="form-atom" style={{ marginBottom: '1rem' }}>
          <OreSelect
            {...register('sendData.deliverySiteId')}
            required={true}
            id="deliverySiteId"
            name="sendData.deliverySiteId"
            label={t(cardOrderFormTranslation.shipment.deliverySiteSelectLabel)}
            onInput={onDeliverySiteChange}
            hasError={!!errors?.sendData?.deliverySiteId}
            errorMessage={errors.sendData?.deliverySiteId?.message}>
            {deliverySitesOptions.map((option: ComboBoxOptionModel, index: number) => (
              <optgroup
                key={'optgroup_' + index}
                label={t(option.groupName ? option.groupName : '')}>
                {option.options.map((opt: [string, number], index: number) => (
                  <option key={'option_' + index} label={opt[0]} value={opt[1]} />
                ))}
              </optgroup>
            ))}
          </OreSelect>
        </div>
        <div style={{ clear: 'both', marginBottom: '1.5rem' }}>
          <DeliverySiteSelected
            deliverySite={deliverySiteSelected}
            handleDelete={removeDeliverySiteSelected}
            handleEdit={onClickDeliverySiteModal}
          />
        </div>
        <div style={{ display: 'flex', gap: 'var(--spacing-large)' }}>
          <OreButton
            size="large"
            category="highlight"
            icon={<SvgPlus />}
            onClick={event => onClickDeliverySiteModal(event, 'new')}>
            {addDeliverySiteLabel.current}
          </OreButton>
        </div>
      </section>
      <Modal
        handleOnClose={setCardOrderModalShow}
        open={cardOrderModalShow}
        children={
          <Form
            formParameters={deliverySiteFormParameters}
            setShowModal={setCardOrderModalShow}
            formMode="modal"
            setNewDeliverySite={setNewDeliverySite}
          />
        }
        size="large"
      />
    </>
  )
}
