import { getIncompleteCardOrderTypeDescriptionById } from '../../../core/services'
import type { IncompleteOrderModel } from '../../models/cardOrder/IncompleteOrderModel'

export const getIncompleteOrdersMap = (
  response: IncompleteOrderModel[]
): IncompleteOrderModel[] => {
  if (!response) return []

  const list: IncompleteOrderModel[] = []
  response.forEach((item: IncompleteOrderModel) => {
    const tmpItem: IncompleteOrderModel = {
      preOrderId: item.preOrderId,
      orderName: item.orderName,
      expiredDate: item.expiredDate
        ? new Date(item.expiredDate).toLocaleDateString()
        : '-',
      reminderDate: item.reminderDate
        ? new Date(item.reminderDate).toLocaleDateString()
        : '-',
      acceptConditions: item.acceptConditions,
      orderCreationDate: new Date(item.orderCreationDate).toLocaleDateString(),
      productCode: item.productCode,
      orderTypeId: item.orderTypeId,
      orderTypeDescription: getIncompleteCardOrderTypeDescriptionById(item.orderTypeId),
      billReference: item.billReference,
      initialChargeDate: item.initialChargeDate
        ? new Date(item.initialChargeDate).toLocaleDateString()
        : '-',
      cardOrders: [...item.cardOrders],
    }
    list.push(tmpItem)
  })
  return list
}
