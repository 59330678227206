import { createContext } from 'react'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { ValidateExcelCheckoutResponse } from 'src/domain/models'
import {
  EmployeesTGDOrderModel,
  TGDEmployeeAndChildsModel,
  TGDOrderSummaryForm,
} from 'src/domain/models/TGDOrder/TGDOrderModel'

type TGDOrderContextProps = {
  tgdOrders: TGDEmployeeAndChildsModel[]
  addTDGOrder: (tgdOrder: TGDEmployeeAndChildsModel) => void
  addTDGOrders: (tgdOrders: TGDEmployeeAndChildsModel[]) => void
  addOrUpdateTDGOrders: (tgdOrders: TGDEmployeeAndChildsModel[]) => void
  removeTGDOrder: (id: string) => void
  clearTGDOrders: () => void
  getTGDOrderById: (id?: string) => TGDEmployeeAndChildsModel | undefined
  updateTDGOrder: (tgdOrder: TGDEmployeeAndChildsModel) => void
  tgdSummaryForm: TGDOrderSummaryForm
  addTDGSummaryForm: (form: TGDOrderSummaryForm) => void
  clearTGDSummaryForm: () => void
  tgdOrderCheckout: CardOrderResumeDTO
  addTDGOrderCheckout: (checkout: CardOrderResumeDTO) => void
  clearTGDOrderCheckout: () => void
  setTGDEmployeesToConfig: (duplicates: EmployeesTGDOrderModel[]) => void
  getTGDEmployeesToConfig: () => any
}

export const TGDOrderContext = createContext<TGDOrderContextProps>(
  {} as TGDOrderContextProps
)
