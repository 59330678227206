import {
  ExcelUploadErrorKind,
  EmployeeExcelUpload,
  EmployeeExcelUploadResponse,
  ExcelRepositoryContract,
  EmployeeExcelConfirmResponse,
  EmployeeExcelConfirm,
  EmployeeExcelTemplateResponse,
} from 'src/Flex/Employees/domain'
import {
  employeeConfirmExcel,
  employeeDownloadExcel,
  employeeValidateExcel,
} from 'src/config/constants/endpoints'
import { MetaStatusCodes } from 'src/domain/enum'
import type { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'

type ApiUploadExcelRequest = {
  file: string
}

type ApiConfirmExcelRequest = {
  id: string
  acceptConditions: boolean
}

type ApiDownloadExcelResponse = HttpModel<{
  file: string
} | null>

type ApiUploadExcelResponse = HttpModel<{
  additionalData: string | null
  created: number
  deleted: number
  excelId: string
  subscribed: number
  unsubscribed: number
  updated: number
} | null>

type ApiConfirmExcelResponse = HttpModel<boolean | null>

const convertToDomain = (
  response: ApiUploadExcelResponse
): EmployeeExcelUploadResponse => ({
  excelWithErrors: response.data?.additionalData ?? undefined,
  created: response.data?.created ?? 0,
  deleted: response.data?.deleted ?? 0,
  excelId: response.data?.excelId,
  subscribed: response.data?.subscribed ?? 0,
  unsubscribed: response.data?.unsubscribed ?? 0,
  updated: response.data?.updated ?? 0,
  errors: response.meta.messages
    .filter(
      message =>
        message.level === MetaStatusCodes.ERROR || message.level === MetaStatusCodes.WARN
    )
    .map(message => ({
      row: message.value,
      kind:
        message.level === MetaStatusCodes.ERROR
          ? ExcelUploadErrorKind.Error
          : ExcelUploadErrorKind.Warning,
      message: message.description,
    })),
  hasWarnings: response.meta.messages.some(
    message => message.level === MetaStatusCodes.WARN
  ),
  hasErrors: response.meta.messages.some(
    message => message.level === MetaStatusCodes.ERROR
  ),
})

export class ExcelRepository implements ExcelRepositoryContract {
  async getTemplate(): Promise<HttpModel<EmployeeExcelTemplateResponse>> {
    const response: ApiDownloadExcelResponse = await axiosFetch(
      employeeDownloadExcel,
      HttpMethod.GET,
      {}
    )

    return {
      data: { excel: response.data?.file },
      meta: response.meta,
    }
  }

  async validateOrder(
    validateOrder: EmployeeExcelUpload
  ): Promise<HttpModel<EmployeeExcelUploadResponse>> {
    const data: ApiUploadExcelRequest = {
      file: validateOrder.excel,
    }

    const response: ApiUploadExcelResponse = await axiosFetch(
      employeeValidateExcel,
      HttpMethod.POST,
      data
    )

    return {
      data: convertToDomain(response),
      meta: response.meta,
    }
  }

  async confirmOrder(
    excel: EmployeeExcelConfirm
  ): Promise<HttpModel<EmployeeExcelConfirmResponse>> {
    const data: ApiConfirmExcelRequest = {
      id: excel.id,
      acceptConditions: excel.terms,
    }

    const response: ApiConfirmExcelResponse = await axiosFetch(
      employeeConfirmExcel,
      HttpMethod.POST,
      data
    )

    return {
      data: { success: response.data ?? false },
      meta: response.meta,
    }
  }
}
