import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useValidationModal } from './useValidationModal'
import useExcelFileValidation from './useExcelFileValidation'
import type { UploadExcelStepProps } from '../organism/UploadExcelStep'
import type { PopupButtonModel } from '../../../../../../domain/customComponents/Popup'
import { useCardOrder } from '../../../../../context/cardOrder/CardOrderProvider'
import { useModalController } from '../../../../Edenred'
import { cardOrderTranslation, forms } from '../../../../../../domain/translations'
import type {
  ExcelTemplateRequest,
  validateOrderExcelRequest,
} from '../../../../../../domain/bodyRequest/ExcelOrders'
import { NotificationSeverity, Repositories } from '../../../../../../domain/enum'
import { RepositoryFactory } from '../../../../../../core/factories'
import type {
  ExcelTemplateModel,
  HttpModel,
  ValidateExcelCheckoutResponse,
} from '../../../../../../domain/models'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../../config/constants/navigationRoutes'
import { GetExcelOrderTypeRequest } from '../../../../../../core/controllers/cardOrder/services/OrderService'
import { useNotification } from '../../../../../context/notification/NotificationProvider'

export const useExcelOrder = (uploadExcelStepProps: UploadExcelStepProps) => {
  const { showWaitingModal, setShowWaitingModal } = useValidationModal()
  const { schema } = useExcelFileValidation()
  const { t } = useTranslation()
  const [buttons, setButtons] = useState<PopupButtonModel[]>([])
  const [uploadError, setUploadError] = useState<boolean>(false)
  const {
    setOrderExcel,
    setOrderExcelAlerts,
    setCardOrderCheckout,
    clearAll,
    setBase64File,
    setBase64FileAlerts,
    orderContext,
  } = useCardOrder()
  const navigate = useNavigate()
  const { addNotification } = useNotification()

  const { show: showCancelModal, setShow: setShowCancelModal } = useModalController()

  const orderCancelConfirmed = () => {
    clearAll()
    setShowCancelModal(false)
    navigate(navigationRoutes.myOrders)
  }

  const getCancelButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: orderCancelConfirmed,
        size: 'large',
      },
    ]
  }

  const SetValidationModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowWaitingModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => setShowWaitingModal(false),
        size: 'large',
      },
    ]
  }

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result?.toString() || '')
      reader.onerror = error => reject(error)
    })
  }

  function getFile(fileList: FileList) {
    if (fileList.length === 0) return undefined
    const file = fileList[0]
    return file
  }

  async function Continue() {
    const isFormValid = await trigger('file')
    if (isFormValid) {
      const fileList = getFile(getValues('file'))
      if (fileList) {
        file2Base64(fileList).then(base64 => {
          base64 = base64.toString().replace(/^data:(.*,)?/, '')
          Validate(base64)
        })
      }
    }
    return undefined
  }

  function Cancel() {
    setShowCancelModal(true)
    return undefined
  }

  type FormTypes = {
    file: FileList
  }

  const {
    register,
    trigger,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  function downloadTemplate() {
    const downloadTemplateRequest: ExcelTemplateRequest = {
      orderExcelType: GetExcelOrderTypeRequest(orderContext.orderType),
      productType: uploadExcelStepProps.product,
      empty: true,
    }
    const repository = RepositoryFactory(Repositories.excel)

    function HandleTemplateResponse(response: HttpModel<ExcelTemplateModel>) {
      const link = document.createElement('a')
      link.href = `data:application/octet-stream;base64,${response.data}`
      link.download = 'template.xlsx'
      link.click()
      link.remove()
    }

    function HandleErrorTemplate(error: any): void {
      addNotification(
        t(cardOrderTranslation.excel.errors.downloadFileError),
        NotificationSeverity.error
      )
    }

    repository
      .getTemplate(downloadTemplateRequest)
      .then(response => HandleTemplateResponse(response))
      .catch(error => HandleErrorTemplate(error))
  }

  function Validate(base64: string, stoppers = true) {
    setShowWaitingModal(true)
    const uploadExcelRequest: validateOrderExcelRequest = {
      orderExcelType: GetExcelOrderTypeRequest(orderContext.orderType),
      productCode: uploadExcelStepProps.product,
      excel: base64,
      stopOnWarnings: stoppers,
    }

    const repository = RepositoryFactory(Repositories.excel)
    repository
      .validateOrder(uploadExcelRequest)
      .then((response: HttpModel<ValidateExcelCheckoutResponse>) =>
        HandleResponse(response, base64)
      )
      .catch(error => HandleErrorUpload())
  }

  function HandleResponse(
    response: HttpModel<ValidateExcelCheckoutResponse>,
    base64File: string
  ) {
    if (response.meta) {
      setOrderExcel(response.data.summary)
      setBase64FileAlerts(
        response.data.additionalData ? response.data.additionalData : ''
      )
      setCardOrderCheckout(response.data.summary)
      setCardOrderCheckout(response.data.summary)
      setOrderExcelAlerts(response.meta.messages)
      setBase64File(base64File)
      setShowWaitingModal(false)
      const containsStoppers = response.meta.messages?.length > 0
      if (containsStoppers) navigate(navigationRoutes.cardOrderExcelAlerts)
      else navigate(navigationRoutes.cardOrderExcelHome)
    }
  }

  function HandleErrorUpload(): void {
    setUploadError(true)
    setShowWaitingModal(false)
  }

  return {
    Cancel,
    Continue,
    setButtons,
    buttons,
    register,
    errors,
    SetValidationModalButtons,
    isValid,
    isDirty,
    getCancelButtons,
    showCancelModal,
    setShowCancelModal,
    setValidationWaitModal: setShowWaitingModal,
    validationWaitModal: showWaitingModal,
    uploadError,
    downloadTemplate,
  }
}
