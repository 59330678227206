import type { RemSpacing } from '../../domain/types'

interface DividerProps {
  marginTop?: RemSpacing
  marginBottom?: RemSpacing
}

const Divider = (props: DividerProps): JSX.Element => {
  return (
    <hr
      style={{
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
      }}
      className="divider"
    />
  )
}

export default Divider
