import { DeleteClientEmployeeResponse } from 'src/domain/models/cardOrder/DeleteClientEmployees'
import { useFetchEmployeeData } from '../hooks'
import { EmployeeProfileProductCardTR } from './EmployeeProfileProductCardTR'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  productCodes?: number[]
  addSuccessNotification: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void
  setProductCodes: Dispatch<SetStateAction<number[] | undefined>>
}

export const EmployeeProfileProductCardContainerTR = ({
  productCodes,
  addSuccessNotification,
  setProductCodes,
}: Props): JSX.Element => {
  const { employeeData, isEmployeeFlex } = useFetchEmployeeData()

  if (!employeeData) {
    return <></>
  }

  return (
    <EmployeeProfileProductCardTR
      employeeData={employeeData}
      isEmployeeFlex={isEmployeeFlex}
      productCodes={productCodes}
      addSuccessNotification={addSuccessNotification}
      setProductCodes={setProductCodes}
    />
  )
}
