import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { EmployeesService } from 'src/core/services/employeesService'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { EmployeesWithLastCardProduct } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

interface ControllerState {
  employeeProducts: EmployeesWithLastCardProduct[] | undefined
}

export const useFetchEmployeeProducts = (): ControllerState => {
  const { document } = useParams()
  const { startLoading, stopLoading } = useLoader()
  const [employeeProducts, setEmployeeProducts] =
    useState<EmployeesWithLastCardProduct[]>()
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const navigate = useNavigate()

  const fetchEmployeeProducts = async (): Promise<void> => {
    if (document) {
      startLoading()
      const { meta, data } = await EmployeesService().GetEmployeeProducts(document)

      // Handle API response
      if (meta.status === MetaStatusCodes.SUCCESS) {
        setEmployeeProducts(data)
        stopLoading()
      } else {
        addNotification(t(forms.errors.wrongUrl), NotificationSeverity.error)
        stopLoading()
        navigate(-1)
      }
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      navigate(-1)
    }
  }

  useEffect(() => {
    fetchEmployeeProducts()
  }, [document])

  return {
    employeeProducts,
  }
}
