import { createContext } from 'react'

export interface UIState {
  sidebarCollapsed: boolean
  openSidebar: () => void
  closeSidebar: () => void
  toggleSidebar: () => void
}

const uiVariablesInitialState = {
  sidebarCollapsed: false,
}

const uiMethodsInitialState = {
  openSidebar: () => null,
  closeSidebar: () => null,
  toggleSidebar: () => null,
}

export const uiInitialState = {
  ...uiVariablesInitialState,
  ...uiMethodsInitialState,
}

export const UIContext = createContext<UIState>(uiInitialState)
