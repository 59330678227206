export const historyConsumptions = {
  groupByUser: 1,
  groupByUserAndDate: 2,
}

export const accountOperationTypeCode = {
  all: 100, // All
  recharge: 1, // Credit
  unload: 3, // Adjustments
}
