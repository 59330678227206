import React from 'react'
import { useTGDOrderFormEditController } from './hooks/useTGDOrderFormEditController'
import { TGDOrderForm } from './organism/TGDOrderForm'

export const TGDOrderEditChildPage = (): JSX.Element => {
  const { tgdOrderItemToEdit } = useTGDOrderFormEditController()

  if (!tgdOrderItemToEdit) {
    return <></>
  }

  return <TGDOrderForm isChildForm tgdOrderItemToEdit={tgdOrderItemToEdit} />
}
