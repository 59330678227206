import {
  OreTableContainer,
  OreTable,
  OreTableCaption,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableBody,
  OreText,
  OreTableActions,
} from '@runroom/oreneta'

import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { FlexProductRow, useProducts } from 'src/Flex/Products/ui/product-list'
import type { ProductModel } from 'src/Flex/Products/domain'
import { getProductIconByProductType } from 'src/Flex/Products/ui/shared'
import type { HeadersModel } from 'src/domain/models/tables/Headers'
import { FlexProductForCollectivesRow } from '../molecules/FlexProductForCollectivesRow'

type Props = {
  title?: string
  noProducts?: string
  isGlobal?: boolean //product = true, collective = false
}

export const FlexProductsList = ({ title, noProducts, isGlobal }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { products, modal } = useProducts()

  const hasEditModals = products.some(
    (product: ProductModel) => modal(product.productType) !== null
  )

  !noProducts && (noProducts = t(flexProductsTranslations.table.noProducts))

  const productHeaders: HeadersModel[] = [
    { key: 'name', label: t(flexProductsTranslations.table.header.name) },
    { key: 'description', label: t(flexProductsTranslations.table.header.description) },
    { key: 'status', label: t(flexProductsTranslations.table.header.status) },
  ]

  const productForCollectivesHeaders: HeadersModel[] = [
    { key: 'name', label: t(flexProductsTranslations.table.header.name) },
    { key: 'details', label: t(flexProductsTranslations.table.header.details) },
    { key: 'active', label: t(flexProductsTranslations.table.header.active) },
    { key: 'actions', label: t(flexProductsTranslations.table.header.actions) },
  ]

  if (hasEditModals) {
    productHeaders.push({
      key: 'actions',
      label: t(flexProductsTranslations.table.header.actions),
    })
  }

  return (
    <OreTableContainer>
      <OreTable>
        {title ? <OreTableCaption title={title} /> : null}
        <OreTableHead valign="middle">
          <OreTableRow hover={false}>
            {isGlobal
              ? productHeaders.map((header: HeadersModel) => (
                  <OreTableCell as="th" key={header.key}>
                    {header.label}
                  </OreTableCell>
                ))
              : productForCollectivesHeaders.map((header: HeadersModel) => (
                  <OreTableCell as="th" key={header.key}>
                    {header.label}
                  </OreTableCell>
                ))}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody valign="top">
          {products.length
            ? products.map((product: ProductModel) => {
                const productModal = modal(product.productType)

                return isGlobal ? (
                  <FlexProductRow
                    key={product.productType}
                    product={product}
                    productIcon={getProductIconByProductType(product.productType)}
                    Modal={productModal?.modal}
                    ModalIcon={productModal?.modalIcon}
                  />
                ) : (
                  <FlexProductForCollectivesRow
                    key={product.productType}
                    product={product}
                    productIcon={getProductIconByProductType(product.productType)}
                    Modal={productModal?.modal}
                  />
                )
              })
            : null}
        </OreTableBody>
      </OreTable>
      {products.length === 0 && (
        <OreTableActions>
          <OreText size="text-sm" tone="neutral-900">
            {noProducts}
          </OreText>
        </OreTableActions>
      )}
    </OreTableContainer>
  )
}
