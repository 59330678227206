import React from 'react'
import { useTGDOrderSelectSingleEmployee } from '../hooks/useTGDOrderSelectSingleEmployee'
import { TGDSelectSingleEmployeeList } from '../molecules/TGDSelectSingleEmployeeList'
import { TGDSelectSingleEmployeeFooterButtons } from '../molecules/TGDSelectSingleEmployeeFooterButtons'
import { TGDSingleEmployeeNoOrderDescription } from '../molecules/TGDOrderSummaryNoOrderDescription'

export const TGDOrderSelectSingleEmployeeContainer = () => {
  const props = useTGDOrderSelectSingleEmployee()
  return (
    <div>
      <TGDSelectSingleEmployeeList employeesTGDProps={props} />
      {props.employeesTGD && props.employeesTGD.length <= 0 && (
        <TGDSingleEmployeeNoOrderDescription />
      )}
      <TGDSelectSingleEmployeeFooterButtons
        selectedEmployee={props.selectedEmployee}
        tableIsEmpty={props.employeesTGD && props.employeesTGD.length <= 0}
        addChild={props.addChild}
      />
    </div>
  )
}
