import { useEffect, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form';
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { TextBoxRowModel } from '../../../../../domain/customComponents'
import type { BasicParameters } from '../../../../../domain/forms'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { deliverySiteFormTranslation } from '../../../../../domain/translations/'

export interface DetailsState {
  register: UseFormRegister<DeliverySiteModel>
  errors: any
  detailsTextBox: TextBoxRowModel<DeliverySiteModel>[]
}
export const useDetailsController = (
  formParameters: BasicParameters<DeliverySiteModel>
): DetailsState => {
  const [detailsTextBox, setDetailsTextBox] = useState<
    TextBoxRowModel<DeliverySiteModel>[]
  >([])
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<DeliverySiteModel>()

  const GetDetailsTextsBox = () => {
    setDetailsTextBox([
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'deliveryPointName',
            label: t(deliverySiteFormTranslation.details.deliverySiteName),
            name: 'deliveryPointName',
            placeHolder: t(
              deliverySiteFormTranslation.details.deliverySiteNamePlaceHolder
            ),
            required: true,
            maxLength: 50,
            type: 'text',
            googleApi: false,
          },
        ],
      },

      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'alias',
            label: t(deliverySiteFormTranslation.details.alias),
            name: 'alias',
            placeHolder: t(deliverySiteFormTranslation.details.aliasPlaceHolder),
            required: true,
            maxLength: 15,
            type: 'text',
            legend: t(deliverySiteFormTranslation.details.aliasFooter),
            googleApi: false,
          },
        ],
      },
    ])
  }
  const SetEditModeFormValues = () => {
    reset(prevForm => {
      if (formParameters.entity)
        return {
          ...prevForm,
          deliveryPointId: formParameters.entity.deliveryPointId,
          deliveryPointName: formParameters.entity.deliveryPointName,
          alias: formParameters.entity.alias,
        }
      return prevForm
    })
  }

  useEffect(() => {
    GetDetailsTextsBox()
    if (formParameters) SetEditModeFormValues()
  }, [formParameters])

  return { register, errors, detailsTextBox }
}
