import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useTGDBasicOrder } from '../../context/TGDBasicOrderProvider'
import { TGDBasicEmployeeAndChildsModel } from 'src/domain/models/TGDOrder/TGDBasicOrderModel'

interface ControlState {
  tgdOrderItemToEdit: TGDBasicEmployeeAndChildsModel | undefined
}

export const useTGDBasicOrderFormEditController = (): ControlState => {
  const { id } = useParams()
  const [tgdOrderItemToEdit, setTgdOrderItemToEdit] =
    useState<TGDBasicEmployeeAndChildsModel>()
  const { tgdOrders } = useTGDBasicOrder()
  const navigate = useNavigate()

  const setTgdOrderItemData = (): void => {
    const tgdOrderItemToEditData = tgdOrders.find(tgdOrder => tgdOrder.id === id)

    if (!tgdOrderItemToEditData) {
      navigate(navigationRoutes.tgdBasicOrderSummary)
    }

    setTgdOrderItemToEdit(tgdOrderItemToEditData)
  }

  useEffect(() => {
    if (!id) {
      navigate(navigationRoutes.tgdBasicOrderSummary)
    }
    setTgdOrderItemData()
  }, [id])

  return {
    tgdOrderItemToEdit,
  }
}
