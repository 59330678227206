import { t } from 'i18next'
import { useNavigate } from 'react-router'
import { navigationRoutes } from '../../../../config/constants/navigationRoutes'
import { myOrders } from '../../../../domain/translations/cardOrder/myOrders'
import { PageHeader } from '../../../Templates/PageHeader'
import { useMyOrderPageController } from './hooks/useMyOrderPageController'
import { Container } from './organism/Container'

export const MyOrdersPage = (): JSX.Element => {
  const { myOrderTab } = useMyOrderPageController()
  const navigate = useNavigate()
  return (
    <PageHeader
      title={t(myOrders.title)}
      buttonText={t(myOrders.buttons.new)}
      buttonAction={() => navigate(navigationRoutes.orderHome)}
      organism={<Container navigateValue={myOrderTab} />}
    />
  )
}
