export const browserStorageKeys = {
  token: 'token',
  cardOrderDuplicateTmp: 'cardOrderDuplicateTmp',
  cardOrderDuplicate: 'cardOrderDuplicate',
  cardOrderRechargeTmp: 'cardOrderRechargeTmp',
  cardOrderRecharge: 'cardOrderRecharge',
  cardOrderUnload: 'cardOrderUnload',
  cardOrderSelectedEmployees: 'cardOrderSelectedEmployees',
  cardOrderEmployees: 'ttOrderEmployees',
  orderURIFallback: 'orderURIFallback',
  employeeProfile: 'employeeProfile',
  tgdOrder: 'tgdOrder',
  tgdOrderEmployees: 'tgdOrderEmployees',
  tgdOrderComplete: 'tgdOrderComplete',
  tgdPreOrderId: 'incompleteOrder',
  ttSelectedEmployees: 'ttSelectedEmployees',
  ttOrderEmployees: 'ttOrderEmployees',
  ttOrderComplete: 'ttOrderComplete',
}

export const cacheKeys = {
  getUserDuplicates: 'getUserDuplicates',
  getUsersRecharge: 'getUsersRecharge',
  getUsersUnload: 'getUsersUnload',
  GetInvoices: 'getInvoices',
}
