import { documentType, emptyValues, Repositories } from '../../domain/enum'
import { documentTypeEnumTranslation } from '../../domain/translations'
import type { EmployeesRepository } from '../../infrastructure/repository/employees/EmployeesRepository'
import { RepositoryFactory } from '../factories'

export const EmployeesService = (): EmployeesRepository => {
  return RepositoryFactory(Repositories.employees)
}

export const getDocumentTypeKey = (documentTypeId: number): string => {
  switch (documentTypeId) {
    case documentType.DniNie:
      return documentTypeEnumTranslation.DniNie
    case documentType.Passport:
      return documentTypeEnumTranslation.Passport
    default:
      return emptyValues.dash
  }
}
