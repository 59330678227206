import { Provider, createContext, useContext } from 'react'

export const createGenericContext = <T>(): [() => T, Provider<T | undefined>] => {
  const context = createContext<T | undefined>(undefined)

  const useGenericContext = (): T => {
    const contextIsDefined = useContext(context)

    if (!contextIsDefined) {
      throw new Error('useGenericContext must be used within a Provider')
    }

    return contextIsDefined
  }

  return [useGenericContext, context.Provider]
}
