import { t } from 'i18next'
import type {
  CardOrderCompleteModel,
  CardOrderCompleteSectionModel,
  TextOrderInfoSectionModel,
  TextSectionModel,
} from '../../domain/models'

import { cardOrderTranslation } from '../../domain/translations'
import { standard, deferred, gruped } from '../../domain/constants/InvoiceBillingType'
import { currency } from '../../domain/enum'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export class CardOrderCompleteContext {
  private strategy: Strategy
  constructor(strategy: Strategy) {
    this.strategy = strategy
  }
  SetStrategy(strategy: Strategy) {
    this.strategy = strategy
  }
  GetSections(
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string,
    userName?: string
  ): CardOrderCompleteSectionModel {
    return this.strategy.GetSections(data, invoiceBillingType)
  }
}

interface Strategy {
  GetSections: (
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string,
    userName?: string
  ) => CardOrderCompleteSectionModel
}

export class TransferSuccessStrategy implements Strategy {
  GetAviableSection(
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string
  ): TextSectionModel[] | undefined {
    let availableSection: TextSectionModel[] | undefined
    if (data && data.cardOrderId && data.chargeOrderId) {
      if (invoiceBillingType === standard)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.cardRecharge.standard
            ),
          },
        ]

      if (invoiceBillingType === deferred)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.cardRecharge.deferred
            ),
          },
        ]

      if (invoiceBillingType === gruped)
        availableSection = [
          {
            text: t(cardOrderTranslation.cardOrderComplete.transfer.cardRecharge.gruped),
          },
        ]
    }

    if (data && data.cardOrderId && !data.chargeOrderId) {
      if (invoiceBillingType === standard)
        availableSection = [
          { text: t(cardOrderTranslation.cardOrderComplete.transfer.card.standard) },
        ]

      if (invoiceBillingType === deferred)
        availableSection = [
          { text: t(cardOrderTranslation.cardOrderComplete.transfer.card.deferred) },
        ]

      if (invoiceBillingType === gruped)
        availableSection = [
          { text: t(cardOrderTranslation.cardOrderComplete.transfer.card.gruped) },
        ]
    }

    if (
      data &&
      ((!data.cardOrderId && data.chargeOrderId) ||
        (!data.cardOrderId && !data.chargeOrderId))
    ) {
      if (invoiceBillingType === standard)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.standard
            ),
          },
        ]

      if (invoiceBillingType === deferred)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.deferred
            ),
          },
        ]

      if (invoiceBillingType === gruped)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.gruped
            ),
          },
        ]
    }

    return availableSection
  }

  GetIbanSection(data?: CardOrderCompleteModel): TextSectionModel[] | undefined {
    let ibanSection: TextSectionModel[] | undefined
    if (data && data.iban)
      ibanSection = [
        { text: t(cardOrderTranslation.cardOrderComplete.successMessageIban) },
        { text: data.iban, isBold: true },
        { text: t(cardOrderTranslation.cardOrderComplete.successMessageIban2) },
        { text: data.concept, isBold: true },
      ]
    return ibanSection
  }

  GetOrdenInfoSection(
    data?: CardOrderCompleteModel
  ): TextOrderInfoSectionModel[] | undefined {
    const orderInfoSection: TextOrderInfoSectionModel[] = []
    if (data && data.totalAmount) {
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.totalAmount) },
          { text: data.totalAmount + currency.euro, isBold: true },
        ],
      })
    }
    if (data && data.cardOrderId) {
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.cardOrderId) },
          { text: '#' + data.cardOrderId, isBold: true },
        ],
      })
    }
    // TODO: unloadOrderId pendiente de backend para cambio de la propiedad de cardOrderId
    if (data && data.chargeOrderId && data.unloadOrderId === undefined) {
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.cardChargeOrderId) },
          { text: '#' + data.chargeOrderId, isBold: true },
        ],
      })
    }

    if (data && data.unloadOrderId)
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.unloadOrderId) },
          { text: '#' + data.unloadOrderId, isBold: true },
        ],
      })
    if (orderInfoSection.length === 0) return undefined
    return orderInfoSection
  }

  GetSections(
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string,
    userName?: string
  ): CardOrderCompleteSectionModel {
    let responsibleSection: TextSectionModel[] | undefined
    let infoSection: TextSectionModel[] | undefined
    let infoSectionAsWarning = false
    if (data && !data.unloadOrderId) {
      responsibleSection = [
        { text: t(cardOrderTranslation.cardOrderComplete.successMessage1) },
        { text: data.email, isBold: true },
        { text: t(cardOrderTranslation.cardOrderComplete.successMessage2) },
      ]
      infoSectionAsWarning = true
      infoSection = [
        { text: t(cardOrderTranslation.cardOrderComplete.transfer.info), isBold: true },
        { text: t(cardOrderTranslation.cardOrderComplete.transfer.info1) },
      ]
    }

    if (data && data.unloadOrderId) {
      responsibleSection = [
        { text: t(cardOrderTranslation.cardOrderComplete.successMessage3) },
        { text: data.email, isBold: true },
        { text: t(cardOrderTranslation.cardOrderComplete.successMessage2) },
      ]
      infoSection = [
        { text: t(cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.info) },
        {
          text: t(cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.info1),
          link: navigationRoutes.employeesHome,
        },
      ]
    }

    return {
      responsibleSection: responsibleSection,
      availableSection: !data?.unloadOrderId
        ? this.GetAviableSection(data, invoiceBillingType)
        : undefined,
      ibanSection: !data?.unloadOrderId ? this.GetIbanSection(data) : undefined,
      infoSection: infoSection,
      infoSectionAsWarning: infoSectionAsWarning,
      orderInfoSection: this.GetOrdenInfoSection(data),
    }
  }
}

export class DirectDebitSuccessStrategy implements Strategy {
  GetAviableSection(
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string
  ): TextSectionModel[] | undefined {
    let availableSection: TextSectionModel[] | undefined
    if (data && data.cardOrderId && data.chargeOrderId) {
      if (invoiceBillingType === standard)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.directDebit.cardRecharge.standard
            ),
          },
        ]

      if (invoiceBillingType === deferred)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.directDebit.cardRecharge.deferred
            ),
          },
        ]

      if (invoiceBillingType === gruped)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.directDebit.cardRecharge.gruped
            ),
          },
        ]
    }

    if (data && data.cardOrderId && !data.chargeOrderId) {
      if (invoiceBillingType === standard)
        availableSection = [
          { text: t(cardOrderTranslation.cardOrderComplete.transfer.card.standard) },
        ]

      if (invoiceBillingType === deferred)
        availableSection = [
          { text: t(cardOrderTranslation.cardOrderComplete.transfer.card.deferred) },
        ]

      if (invoiceBillingType === gruped)
        availableSection = [
          { text: t(cardOrderTranslation.cardOrderComplete.transfer.card.gruped) },
        ]
    }

    if (
      data &&
      ((!data.cardOrderId && data.chargeOrderId) ||
        (!data.cardOrderId && !data.chargeOrderId))
    ) {
      if (invoiceBillingType === standard)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.standard
            ),
          },
        ]

      if (invoiceBillingType === deferred)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.deferred
            ),
          },
        ]

      if (invoiceBillingType === gruped)
        availableSection = [
          {
            text: t(
              cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.gruped
            ),
          },
        ]
    }

    return availableSection
  }

  GetOrdenInfoSection(
    data?: CardOrderCompleteModel
  ): TextOrderInfoSectionModel[] | undefined {
    const orderInfoSection: TextOrderInfoSectionModel[] = []
    if (data && data.totalAmount) {
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.totalAmount) },
          { text: data.totalAmount + currency.euro, isBold: true },
        ],
      })
    }
    if (data && data.cardOrderId) {
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.cardOrderId) },
          { text: '#' + data.cardOrderId, isBold: true },
        ],
      })
    }
    // TODO: unloadOrderId pendiente de backend para cambio de la propiedad de cardOrderId
    if (data && data.chargeOrderId && data.unloadOrderId === undefined) {
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.cardChargeOrderId) },
          { text: '#' + data.chargeOrderId, isBold: true },
        ],
      })
    }

    if (data && data.unloadOrderId)
      orderInfoSection.push({
        info: [
          { text: t(cardOrderTranslation.cardOrderComplete.unloadOrderId) },
          { text: '#' + data.unloadOrderId, isBold: true },
        ],
      })

    return orderInfoSection
  }

  GetSections(
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string,
    userName?: string
  ): CardOrderCompleteSectionModel {
    let responsibleSection: TextSectionModel[] | undefined
    if (data) {
      responsibleSection = [
        { text: userName ? userName + ' ' : '' },
        { text: t(cardOrderTranslation.cardOrderComplete.successMessage1) },
        { text: data.email, isBold: true },
        { text: t(cardOrderTranslation.cardOrderComplete.successMessage2) },
      ]
    }

    return {
      responsibleSection: responsibleSection,
      availableSection: this.GetAviableSection(data, invoiceBillingType),
      orderInfoSection: this.GetOrdenInfoSection(data),
    }
  }
}

export class FailStrategy implements Strategy {
  GetSections(
    data?: CardOrderCompleteModel,
    invoiceBillingType?: string,
    userName?: string
  ): CardOrderCompleteSectionModel {
    const failSection: TextSectionModel[] = [
      { text: t(cardOrderTranslation.cardOrderComplete.errorMessage1) },
      { text: t(cardOrderTranslation.cardOrderComplete.errorMessage2) },
    ]

    return {
      failSection: failSection,
    }
  }
}
