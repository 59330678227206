import { cardOrderTranslation } from '../../../../domain/translations/cardOrder/cardOrder'
import { CardOrderHomeTemplate } from '../../../Templates'
import {
  CardOrderHomeFooter,
  CardOrderHomeTable,
  CardOrderHomeTableEmpty,
} from './molecules'
import { CardOrderHomeForm } from './organism'

export const CardOrderHomePage = (): JSX.Element => {
  return (
    <CardOrderHomeTemplate
      title={cardOrderTranslation.cardOrderHome.title}
      elementsnoOrders={[<CardOrderHomeTableEmpty />, <CardOrderHomeFooter />]}
      elementsWithOrders={[<CardOrderHomeTable />, <CardOrderHomeForm />]}
    />
  )
}
