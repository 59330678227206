export const tableSelector = {
  title: 'tableSelector.title',
  captionTitle: 'tableSelector.captionTitle',
  rowsTotalDescription: 'tableSelector.rowsTotalDescription',
  searchTitle: 'tableSelector.searchTitle',
  selectedRowsDescriptionOne: 'tableSelector.selectedRowsDescriptionOne',
  selectedRowsDescriptionOthers: 'tableSelector.selectedRowsDescriptionOthers',
  nextPageLabel: 'tableSelector.nextPageLabel',
  previousPageLabel: 'tableSelector.previousPageLabel',
  pageOf: 'tableSelector.pageOf',
  NextButtonLabel: 'tableSelector.NextButtonLabel',
  backButtonLabel: 'tableSelector.backButtonLabel',
  searchNotFoundFirst: 'tableSelector.searchNotFound.first',
  searchNotFoundLink: 'tableSelector.searchNotFound.link',
  searchNotFoundEnd: 'tableSelector.searchNotFound.end',
  rowsShowing: 'tableSelector.rowsShowing',
}
