import {
  OreDivider,
  OreHeading,
  OreIconButton,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
  SvgClose,
} from '@runroom/oreneta'
import type { PopupModel } from '../../../domain/customComponents/Popup'
import { ModalActionButtons } from './ModalActionButtons'

export const Modal = (props: PopupModel): JSX.Element => {
  return (
    <>
      <OreModal open={props.open} handleOnClose={() => props.handleOnClose(false)}>
        <OreModalBox
          handleOnClose={() => props.handleOnClose(false)}
          size={props.size}
          closeIcon={props.closeIcon}>
          <OreModalBoxBody>
            {props.topChildren}
            {props.title && (
              <>
                <OreHeading size="headline-md">{props.title}</OreHeading>
                {props.showDivider && <OreDivider />}
              </>
            )}
            {props.description && <OreText>{props.description}</OreText>}
            {props.htmlElement}
            {props.children}
          </OreModalBoxBody>
          {props.buttons && (
            <OreModalBoxFooter>
              <ModalActionButtons buttons={props.buttons} />
            </OreModalBoxFooter>
          )}
          {props.customCloseIcon && (
            <div className="close-modal-wrapper">
              <OreIconButton
                onClick={props.handleCustomCloseIcon}
                icon={<SvgClose aria-label="Bin" />}
              />
            </div>
          )}
        </OreModalBox>
      </OreModal>
    </>
  )
}
