import { Dispatch, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { MetaStatusCodes, NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { TGDSaveCardOrderModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotificationHandler } from 'src/presentation/hooks'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderService } from 'src/core/services/ttOrderService'
import { mapTTOrdersToCardOrders } from 'src/domain/adapters/ttOrderAdapter'
import { SaveCardOrderProtocol } from 'src/domain/protocols'
import { SaveCardOrderValidateDTO } from 'src/domain/dto'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { MessagesModel } from 'src/domain/models'

interface useCardOrderSaveAndCloseState {
  errorMessage: string
  setStateErrorMessage: Dispatch<React.SetStateAction<string>>
  saveAndCloseTTOrder: (data: TGDSaveCardOrderModel) => Promise<void>
  form: UseFormReturn<TGDSaveCardOrderModel, any>
}

export const useTTOrderSaveAndClose = (): useCardOrderSaveAndCloseState => {
  const { startLoading, stopLoading } = useLoader()
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { ttOrders, clearTTOrders } = useTTOrder()
  const { addNotification } = useNotification()

  const formSchema = Yup.object({
    orderName: Yup.string()
      .max(200, t(forms.errors.maxAllowed))
      .required(t(forms.errors.fieldRequired)),
  })

  const form = useForm<TGDSaveCardOrderModel>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      orderName: '',
    },
  })

  const saveAndCloseTTOrder = async (data: TGDSaveCardOrderModel): Promise<void> => {
    if (ttOrders) {
      startLoading()

      const saveTTOrderRequestBody: SaveCardOrderProtocol = {
        orderName: data.orderName,
        reminderDate: '',
        initialChargeDate: '',
        expiredDate: '',
        acceptConditions: false,
        billReference: '',
        productCode: edenredProducts.ticketTransporte,
        orderTypeId: ttOrders[0].orderTypeId, //obtengo del primero porque las ordenes son todas iguales por porceso (1=nuevo/duplic, 2=recarga, 3=descarga)
        cardOrders: mapTTOrdersToCardOrders(ttOrders),
      }

      TTOrderService()
        .saveTTOrder(saveTTOrderRequestBody)
        .then((response: SaveCardOrderValidateDTO) => {
          if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
            navigate(navigationRoutes.myOrdersTtTab)
            addNotification(t(forms.success.message), NotificationSeverity.success)
            clearTTOrders()
          } else {
            if (response?.meta.messages.length > 0) {
              addNotification(t(forms.errors.genericError), NotificationSeverity.error)
              response?.meta?.messages.forEach((error: MessagesModel) => {
                if (error.value) {
                  form.setError(error.value as any, {
                    type: 'value',
                    message: error.description,
                  })
                }
              })
            }
          }
        })
        .finally(() => stopLoading())
    }
  }

  return {
    errorMessage,
    setStateErrorMessage,
    saveAndCloseTTOrder,
    form,
  }
}
