export const rechargesConfigurationTranslations = {
  title: 'RechargesConfiguration.title',
  table: {
    caption: {
      title: 'RechargesConfiguration.table.caption.title',
      description: 'RechargesConfiguration.table.caption.description',
    },

    header: {
      name: 'RechargesConfiguration.table.header.name',
      amount: 'RechargesConfiguration.table.headers.amount',
      recharge: 'RechargesConfiguration.table.headers.recharge',
      rechargeTooltip: 'RechargesConfiguration.table.headers.rechargeTooltip',
      costs: 'RechargesConfiguration.table.headers.costs',
    },
    rows: {
      recharge: {
        placeHolder: 'RechargesConfiguration.table.rows.recharge.placeHolder',
        validation: {
          amount: 'RechargesConfiguration.table.rows.recharge.validation.amount',
          numeric: 'RechargesConfiguration.table.rows.recharge.validation.numeric',
          amountTwoDecimalsMax:
            'RechargesConfiguration.table.rows.recharge.validation.amountTwoDecimalsMax',
        },
      },
      costs: 'RechargesConfiguration.table.rows.costs.placeHolder',
    },
    footer: {
      action: 'RechargesConfiguration.table.footer.action',
    },
    comboBoxFields: {
      default: 'duplicatesConfiguration.table.option.default',
      reson: {
        optionOne: 'duplicatesConfiguration.table.reason.one',
        optionTwo: 'duplicatesConfiguration.table.reason.two',
      },
      type: {
        optionOne: 'duplicatesConfiguration.table.type.one',
        optionTwo: 'duplicatesConfiguration.table.type.two',
      },
    },
  },
  selector: {
    title: 'duplicatesConfiguration.selector.title',
    description: 'duplicatesConfiguration.selector.description',
    button: 'duplicatesConfiguration.selector.button',
  },
  modal: {
    title: 'duplicatesConfiguration.modal.title',
    description: 'duplicatesConfiguration.modal.description',
  },
  tableEmpty: {
    first: 'RechargesConfiguration.tableEmpty.first',
    link: 'RechargesConfiguration.tableEmpty.link',
    end: 'RechargesConfiguration.tableEmpty.end',
  },
}
