import { TFunction } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { orderImputationsTranslations } from 'src/Flex/Orders/ui/order-imputation'

export const getOrderImputationTableHeaders = (
  productType: edenredProducts,
  t: TFunction<'translation', undefined>
) => {
  const productHeaders: HeadersModel[] = [
    {
      key: 'owner',
      label: t(orderImputationsTranslations.table.header.owner),
      size: 'quarter',
    },
    {
      key: 'description',
      label: t(orderImputationsTranslations.table.header.description),
      size: 'quarter',
    },
    {
      key: 'import',
      label: t(orderImputationsTranslations.table.header.monthlyImport),
      tooltip: t(orderImputationsTranslations.table.headerTooltips.monthlyImport),
    },
    {
      key: 'remaining',
      label: t(orderImputationsTranslations.table.header.remaining),
      tooltip: t(orderImputationsTranslations.table.headerTooltips.remaining),
    },
    {
      key: 'total',
      label: t(orderImputationsTranslations.table.header.total),
    },
    {
      key: 'actions',
      label: t(orderImputationsTranslations.table.header.actions),
    },
  ]

  if (productType === edenredProducts.saludExterno) {
    productHeaders.splice(3, 1)
    productHeaders.splice(3, 1)
  }

  return productHeaders
}
