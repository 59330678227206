import { RepositoryFactory } from '../factories/RepositoryFactory'
import { Repositories } from '../../domain/enum/repositories'
import type { InvoiceRepository } from '../../infrastructure/repository/invoice/InvoiceRepository'
import { invoiceTranslation } from '../../domain/translations/payments/invoiceTranslation'
import type { TFunction } from 'i18next'
import { t } from 'i18next'
import { invoiceStatus, invoicesTabs } from '../../domain/enum'
import { GetInvoiceStatusDescription } from '../../domain/adapters/invoiceAdapter'

export const InvoicesService = (): InvoiceRepository => {
  return RepositoryFactory(Repositories.invoice)
}

export const getDownloadTooltip = (
  isInvoiceDownloadable: boolean,
  isInvoiceReadyToDownload: boolean,
  invoiceType: invoicesTabs
): string => {
  if (!isInvoiceDownloadable) return t(invoiceTranslation.tooltip.invoiceNotAvailable)
  if (!isInvoiceReadyToDownload) return t(invoiceTranslation.tooltip.invoiceXMLFormat)
  if (isInvoiceDownloadable && isInvoiceReadyToDownload) {
    switch (invoiceType) {
      case invoicesTabs.invoices:
        return t(invoiceTranslation.tooltip.invoiceAvailable)
      case invoicesTabs.creditNotes:
        return t(invoiceTranslation.tooltip.creditNoteAvailable)
    }
  }
  return ''
}

export const GetInvoiceStatusList = (t: TFunction): [string, number][] => {
  const statusList: [string, number][] = []
  statusList.push([
    GetInvoiceStatusDescription(invoiceStatus.pending),
    invoiceStatus.pending,
  ])
  statusList.push([GetInvoiceStatusDescription(invoiceStatus.paid), invoiceStatus.paid])
  statusList.push([
    GetInvoiceStatusDescription(invoiceStatus.partiallyPaid),
    invoiceStatus.partiallyPaid,
  ])
  statusList.push([
    GetInvoiceStatusDescription(invoiceStatus.expired),
    invoiceStatus.expired,
  ])
  statusList.push([
    GetInvoiceStatusDescription(invoiceStatus.cancelled),
    invoiceStatus.cancelled,
  ])
  return statusList
}
