import {
  SvgKindergarten,
  SvgNavBarBills,
  SvgNavBarDashboard,
  SvgNavBarHistory,
  SvgNavBarMembers,
  SvgNavBarOrders,
  SvgRestaurant,
  SvgTransport,
} from '@runroom/oreneta'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { SidebarMenuItemModel } from '../sidebar/Sidebar'
import { navigationTranslation } from '../../../../../domain/translations'
import { viewTag } from 'src/domain/enum/permissionTags'
import { edenredProducts } from 'src/domain/enum'
import {
  deliverySiteTRActiveLinks,
  deliverySiteTTActiveLinks,
  employeesActiveLinks,
  myOrdersActiveLinks,
  spendingRulesActiveLinks,
} from './activeLinks'

export const sidebarMenu: SidebarMenuItemModel[] = [
  {
    title: navigationTranslation.sidebar.items.home,
    icon: <SvgNavBarDashboard />,
    link: navigationRoutes.clientsHome,
    protectedBy: viewTag.VIEW_SUB_CLIENTS,
  },
  {
    items: [
      {
        title: navigationTranslation.sidebar.items.newOrder,
        icon: <SvgNavBarOrders />,
        link: navigationRoutes.orderHome,
        activeLinks: [...myOrdersActiveLinks],
      },
      {
        title: navigationTranslation.sidebar.items.myOrders,
        icon: <SvgNavBarHistory />,
        link: navigationRoutes.myOrdersHistory,
        activeLinks: [navigationRoutes.myOrdersHistory],
      },
    ],
    protectedBy: viewTag.VIEW_SUB_MYORDERS,
  },
  {
    items: [
      {
        title: navigationTranslation.sidebar.items.employees,
        icon: <SvgNavBarMembers />,
        link: navigationRoutes.employeesHome,
        activeLinks: [...employeesActiveLinks],
      },
    ],
    protectedBy: viewTag.VIEW_SUB_EMPLOYEES,
  },
  {
    items: [
      {
        title: navigationTranslation.sidebar.items.invoices,
        icon: <SvgNavBarBills />,
        link: navigationRoutes.invoicesHome,
      },
    ],
  },
  {
    title: navigationTranslation.sidebar.sections.products,
    items: [
      {
        title: navigationTranslation.sidebar.items.tr.ticketRestaurant,
        icon: <SvgRestaurant />,
        protectedBy: edenredProducts.ticketRestaurant.toString(),
        childs: [
          {
            title: navigationTranslation.sidebar.items.tr.overallConditions,
            link: navigationRoutes.generalTermsTR,
          },
          {
            title: navigationTranslation.sidebar.items.tr.deliveryPoints,
            link: navigationRoutes.deliverySiteTR,
            protectedBy: viewTag.VIEW_SUB_TR_DELIVERYSITE,
            activeLinks: [...deliverySiteTRActiveLinks],
          },
          {
            title: navigationTranslation.sidebar.items.tr.usageProfiles,
            link: navigationRoutes.spendingRules,
            protectedBy: viewTag.VIEW_SUB_TR_SPENDINGRULES,
            activeLinks: [...spendingRulesActiveLinks],
          },
          {
            title: navigationTranslation.sidebar.items.tr.historical,
            link: navigationRoutes.historySpending,
            activeLinks: [navigationRoutes.historyTR, navigationRoutes.historySpending],
          },
        ],
      },
      {
        title: navigationTranslation.sidebar.items.tt.ticketTransport,
        icon: <SvgTransport />,
        protectedBy: edenredProducts.ticketTransporte.toString(),
        childs: [
          {
            title: navigationTranslation.sidebar.items.tt.overallConditions,
            link: navigationRoutes.generalTermsTT,
          },
          {
            title: navigationTranslation.sidebar.items.tt.deliveryPoints,
            link: navigationRoutes.deliverySiteTT,
            protectedBy: viewTag.VIEW_SUB_TR_DELIVERYSITE,
            activeLinks: [...deliverySiteTTActiveLinks],
          },
          {
            title: navigationTranslation.sidebar.items.tt.historical,
            link: navigationRoutes.historyTT,
            activeLinks: [navigationRoutes.historyTT, navigationRoutes.historyTTHome],
          },
        ],
      },
      {
        title: navigationTranslation.sidebar.items.tgd.ticketKindergarten,
        icon: <SvgKindergarten />,
        protectedBy: edenredProducts.ticketGuarderia.toString(),
        childs: [
          {
            title: navigationTranslation.sidebar.items.tgd.overallConditions,
            link: navigationRoutes.generalTermsTGD,
          },
          {
            title: navigationTranslation.sidebar.items.tgd.historical,
            link: navigationRoutes.historyTGD,
            activeLinks: [navigationRoutes.historyTGD, navigationRoutes.historyTGDHome],
          },
        ],
      },
    ],
  },
]
