import { getUndefinedEmptyString } from '../../core/services'
import type { UserModel } from '../models'

export const getAccountsMap = (response: UserModel): UserModel => {
  const user: UserModel = {
    email: getUndefinedEmptyString(response.email),
    name: getUndefinedEmptyString(response.name),
    surname: getUndefinedEmptyString(response.surname),
    surname2: getUndefinedEmptyString(response.surname2),
    userName: getUndefinedEmptyString(response.userName),
    fullName: `${response.name} ${response.surname} ${
      response.surname2 ? response.surname2 : ''
    }`,
  }
  return user
}
