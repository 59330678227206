import { useTranslation } from 'react-i18next'
import { DeliverySiteContainer } from './organism'
import {
  deliverySiteFormTranslation,
  deliverySiteTranslation,
} from 'src/domain/translations'
import { SimpleHeaderWithAddButtonTemplate } from 'src/presentation/Templates'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { edenredProducts } from 'src/domain/enum'

export const DeliverySitePageTR = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <SimpleHeaderWithAddButtonTemplate
      title={t(deliverySiteTranslation.titleTR)}
      addButtonEnabled={true}
      buttonAction={() => navigate(navigationRoutes.deliverySiteNewTR)}
      buttonText={t(deliverySiteFormTranslation.form.new)}
      organism={<DeliverySiteContainer productCode={edenredProducts.ticketRestaurant} />}
    />
  )
}
