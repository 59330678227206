import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'

type Item = {
  active: boolean
}

type IUseBatchCheckboxes = {
  count: number
  indeterminate: boolean
  massiveSelect: boolean
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
}
type IUseBatchCheckboxesProps = {
  name: string
  form: UseFormReturn<any> // TODO: Replace any with a generic type
}

export const useBatchCheckboxes = ({
  name,
  form,
}: IUseBatchCheckboxesProps): IUseBatchCheckboxes => {
  const items: Item[] = form.watch(name) ?? []
  const activeItems = items.filter(item => item.active)

  const isIndeterminate = (): boolean =>
    activeItems.length > 0 && activeItems.length < items.length

  const isMassiveSelected = (): boolean => activeItems.length === items.length

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    form.setValue(
      name,
      items.map(item => ({ ...item, active: event.target.checked }))
    )
  }

  return {
    count: activeItems.length,
    massiveSelect: useMemo(() => isMassiveSelected(), [activeItems.length]),
    indeterminate: useMemo(() => isIndeterminate(), [activeItems.length]),
    handleSelectAllClick,
  }
}
