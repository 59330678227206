import { FormProvider } from 'react-hook-form'
import { OreButton, OreHeading, OreMessageBar, OreText } from '@runroom/oreneta'
import { Details } from '../molecules/Details'
import { Shipment } from '../molecules/Shipment'
import ShipmentContact from '../molecules/ShipmentContact'
import { Address } from '../molecules/Address'
import ConfirmationModal from '../../../Edenred/ConfirmationModal'
import { useFormController } from '../hooks'
import type { FormProps } from '../../../../../domain/forms/FormProps'
import type { DeliverySiteModel } from '../../../../../domain/models'
import type { Dispatch, SetStateAction } from 'react'
import { deliverySiteFormTranslation, forms } from '../../../../../domain/translations'
import { Divider } from 'src/presentation/layout'

interface DeliverySiteFormProps extends FormProps<DeliverySiteModel> {
  setNewDeliverySite?: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
}

export const Form = (props: DeliverySiteFormProps): JSX.Element => {
  const {
    form,
    t,
    onChangeRadioAdressType,
    enabledFavoriteCheckBox,
    isCheckedFavoriteCheckBox,
    onChangeCheckBoxFovarite,
    errorMessage,
    radioContactTypeValue,
    setShowShowAbandonModal,
    onSubmitWarnings,
    showAbandonWarning,
    showNoPhoneWarning,
    onAbandonConfirmation,
    onNoPhoneConfirmation,
    onUpdateSubmitWarnings,
    onNoPhoneUpdateConfirmation,
    onModalNoPhoneConfirmation,
    onModalSubmitWarnings,
    onModalAbandonConfirmation,
    requiredPhone,
    setRequiredPhone,
  } = useFormController(props.formParameters)
  return (
    <FormProvider {...form}>
      <form className="form-organism">
        {/* Title for new delivery site in modal form */}
        {props.formMode !== 'page' && !props.formParameters.entity?.deliveryPointName && (
          <OreHeading as="h1" size="title-sm">
            {t(deliverySiteFormTranslation.form.new)}
          </OreHeading>
        )}
        {/* Title for update delivery site in modal form */}
        {props.formMode !== 'page' && props.formParameters.entity?.deliveryPointName && (
          <div>
            <OreHeading as="h1" size="title-sm">
              {t(deliverySiteFormTranslation.form.editDeliverySite)}
            </OreHeading>
            <div className={'mt-1'} />
            <OreMessageBar color="warning">
              {t(deliverySiteFormTranslation.form.updateWarning)}
            </OreMessageBar>
          </div>
        )}
        <Details
          title={t(deliverySiteFormTranslation.details.title)}
          formParameters={props.formParameters}
        />
        <Address
          title={t(deliverySiteFormTranslation.address.title)}
          onValueChange={onChangeRadioAdressType}
          radioContactTypeValue={radioContactTypeValue}
          formParameters={props.formParameters}
        />
        <Shipment
          title={t(deliverySiteFormTranslation.shipment.title)}
          formParameters={props.formParameters}
          setRequiredPhone={setRequiredPhone}
        />
        <ShipmentContact
          title={t(deliverySiteFormTranslation.shipmentContact.title)}
          enabledFavoriteCheckBox={enabledFavoriteCheckBox}
          isCheckedFavoriteCheckBox={isCheckedFavoriteCheckBox}
          onValueChange={onChangeCheckBoxFovarite}
          formParameters={props.formParameters}
          requiredPhone={requiredPhone}
        />
        <section className="width-max mt-2">
          <OreText as="p" size="text-sm" tone="neutral-600">
            {t(forms.errors.fieldsRequired)}
          </OreText>
        </section>
      </form>

      <div className="mt-3">
        <Divider />
      </div>

      {errorMessage !== '' && <OreMessageBar color="error">{errorMessage}</OreMessageBar>}

      {/* buttons */}
      <div className="flex flex--row float-right">
        {/* abadon add delivery site */}
        <OreButton
          as="button"
          category="secondary"
          onClick={() => setShowShowAbandonModal(true)}
          size="large">
          {t(forms.buttons.cancel)}
        </OreButton>

        {/* submit  */}
        <OreButton
          as="button"
          category="primary"
          onClick={
            !props.formParameters.editMode
              ? props.formMode === 'page'
                ? form.handleSubmit(onSubmitWarnings)
                : form.handleSubmit(() =>
                    onModalSubmitWarnings(
                      'new',
                      props.setShowModal,
                      props.setNewDeliverySite
                    )
                  )
              : props.formMode === 'page'
              ? form.handleSubmit(onUpdateSubmitWarnings)
              : form.handleSubmit(() =>
                  onModalSubmitWarnings(
                    'edit',
                    props.setShowModal,
                    props.setNewDeliverySite
                  )
                )
          }
          size="large">
          {!props.formParameters.editMode
            ? t(deliverySiteFormTranslation.form.create)
            : t(deliverySiteFormTranslation.form.editDeliverySite)}
        </OreButton>
      </div>

      {/* abandon add delivery site form */}
      <ConfirmationModal
        isOpen={showAbandonWarning}
        confirm={() =>
          props.formMode === 'page'
            ? onAbandonConfirmation(true)
            : onModalAbandonConfirmation(true, props.setShowModal)
        }
        cancel={() =>
          props.formMode === 'page'
            ? onAbandonConfirmation(false)
            : onModalAbandonConfirmation(false, props.setShowModal)
        }
        title={
          !props.formParameters.editMode
            ? t(deliverySiteFormTranslation.modals.abandon.title)
            : t(deliverySiteFormTranslation.modals.abandon.titleUpdate)
        }
        body={t(deliverySiteFormTranslation.modals.abandon.description)}
        yesText={t(deliverySiteFormTranslation.modals.abandon.continue)}
        noText={t(deliverySiteFormTranslation.modals.abandon.backToEdit)}
      />

      {/* no phone warning */}
      <ConfirmationModal
        isOpen={showNoPhoneWarning}
        confirm={() =>
          !props.formParameters.editMode
            ? props.formMode === 'page'
              ? onNoPhoneConfirmation(true)
              : onModalNoPhoneConfirmation(
                  true,
                  'new',
                  props.setShowModal,
                  props.setNewDeliverySite
                )
            : props.formMode === 'page'
            ? onNoPhoneUpdateConfirmation(true)
            : onModalNoPhoneConfirmation(
                true,
                'edit',
                props.setShowModal,
                props.setNewDeliverySite
              )
        }
        cancel={() =>
          !props.formParameters.editMode
            ? onNoPhoneConfirmation(false)
            : onNoPhoneUpdateConfirmation(false)
        }
        title={t(deliverySiteFormTranslation.modals.confirmWithoutPhone.title)}
        body={t(deliverySiteFormTranslation.modals.confirmWithoutPhone.description)}
        yesText={t(deliverySiteFormTranslation.modals.confirmWithoutPhone.confirm)}
        noText={t(deliverySiteFormTranslation.modals.confirmWithoutPhone.backToEdit)}
      />
    </FormProvider>
  )
}
