import { useTranslation } from 'react-i18next'
import { duplicatesConfigurationTranslations } from '../../../../domain/translations/cardOrder/duplicatesConfiguration'
import { SimpleForm } from '../../../Templates'
import { EditContainer } from './organisms/EditContainer'

export const EditDuplicatesConfigurationPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SimpleForm
      title={t(duplicatesConfigurationTranslations.title)}
      organisms={[<EditContainer />]}
    />
  )
}
