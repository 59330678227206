import { useTranslation } from 'react-i18next'
import { OrePill, SvgAlert, SvgWarning } from '@runroom/oreneta'
import { ExcelUploadErrorKind } from 'src/Flex/Employees/domain'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

export type ErrorKindPillProps = {
  kind: ExcelUploadErrorKind
}

const errorKindTone = {
  [ExcelUploadErrorKind.Error]: 'error-600',
  [ExcelUploadErrorKind.Warning]: 'warning-600',
}

const errorKindPillText = {
  [ExcelUploadErrorKind.Error]: flexEmployeesTranslations.excel.errorPill.error,
  [ExcelUploadErrorKind.Warning]: flexEmployeesTranslations.excel.errorPill.warning,
}

const errorKindIcon = {
  [ExcelUploadErrorKind.Error]: SvgAlert,
  [ExcelUploadErrorKind.Warning]: SvgWarning,
}

export const ErrorKindPill = ({ kind }: ErrorKindPillProps): JSX.Element => {
  const { t } = useTranslation()
  const Icon = errorKindIcon[kind]
  const tone = errorKindTone[kind] as 'warning-600' | 'error-600'

  return (
    <OrePill icon={<Icon />} tone={tone}>
      {t(errorKindPillText[kind])}
    </OrePill>
  )
}
