import { useEffect } from 'react'
import ScrollTopRouter from './ScrollTopRouter'
import { useAuth } from '../context/auth/AuthProvider'
import { useLoader } from '../context/loader/LoaderProvider'
import PageApp from '../layout/PageApp'
import ClientRouter from './ClientRouter'
import { useUser } from '../context/user/UserProvider'
import Spinner from '../components/Edenred/spinner/Spinner'

const AppRouter = () => {
  const { isLogged, logIn } = useAuth()
  const { userContract } = useUser()
  const { startLoading, stopLoading } = useLoader()

  useEffect(() => {
    const startApp = () => {
      startLoading()
      logIn()
      stopLoading()
    }
    startApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLogged) {
      startLoading()
      userContract()
      stopLoading()
    }
  }, [isLogged])

  if (!isLogged) {
    return <Spinner />
  }

  return (
    <PageApp>
      <ScrollTopRouter>
        <ClientRouter />
      </ScrollTopRouter>
    </PageApp>
  )
}

export default AppRouter
