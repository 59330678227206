import React from 'react'
import { useLocation } from 'react-router-dom'
import { MessagesModel } from 'src/domain/models'
import { TTOrderCompleteError, TTOrderCompleteSuccess } from '../molecules'
import { TTOrderCompleteModel } from 'src/domain/models/TTOrder/TTOrderModel'

export interface TTOrderCompleteState {
  success: boolean
  errorMessage: MessagesModel[]
  data: TTOrderCompleteModel
  isExcel: boolean
  excel: string
}

export const TTOrderCompleteContainer = (): JSX.Element => {
  const location = useLocation()
  const orderCompleteData = location.state as TTOrderCompleteState

  return (
    <>
      {orderCompleteData?.success && (
        <TTOrderCompleteSuccess orderCompleteData={orderCompleteData.data} />
      )}
      {!orderCompleteData.success && (
        <TTOrderCompleteError
          errorMessages={orderCompleteData.errorMessage}
          excel={orderCompleteData.excel}
          isExcel={orderCompleteData.isExcel}
        />
      )}
    </>
  )
}
