import {
  OreButton,
  OreHeading,
  OrePagination,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreTooltip,
  SvgOut,
} from '@runroom/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { forms, unloadsConfigurationTranslations } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { UnloadConfigurationEmployeeState } from '../hooks/useUnloadConfigurationEmployees'
import { UnloadConfigurationEmployeesRow } from './UnloadConfigurationEmployeesRow'
import { FormProvider } from 'react-hook-form'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { CardOrderModel } from 'src/domain/models'

interface Props {
  employeesTRProps: UnloadConfigurationEmployeeState
}

export const UnloadConfigurationEmployeesList = ({
  employeesTRProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employeesTR,
    onPageChange,
    page,
    pageSize,
    selectedEmployees,
    onSelectEmployee,
    isSelected,
    showSelected,
    form,
    updateDataRowToTROrderEmployee,
    unloadAllBalance,
  } = employeesTRProps
  const { getTREmployeesToConfig } = useCardOrder()

  return (
    <>
      <FormProvider {...form}>
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <OreTableContainer>
            <div className="bg-white px-1 py-105">
              <OreHeading size="headline-md">
                {t(unloadsConfigurationTranslations.table.caption.title)}
              </OreHeading>
            </div>
            <OreTable>
              <OreTableHead>
                <OreTableRow>
                  {getTREmployeesToConfig().length > 1 && header.headerElement && (
                    <OreTableCell as="th">{header.headerElement}</OreTableCell>
                  )}
                  {header.headerLabel.map((header, index) =>
                    !header.isSortable ? (
                      <OreTableCell as="th" key={'lbl' + index}>
                        {header.label}&nbsp;
                        {header.tooltip && (
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        )}
                      </OreTableCell>
                    ) : (
                      <OreTableCell as="th" key={'lbl' + index}>
                        <OreTableSortLabel
                          active
                          direction={header.sortDirection}
                          onClick={header.onClick}>
                          {header.label}&nbsp;
                          {header.tooltip && (
                            <OreTooltip
                              placement="top"
                              separation="small"
                              text={header.tooltip}>
                              <span>{header.tooltipIcon}</span>
                            </OreTooltip>
                          )}
                        </OreTableSortLabel>
                      </OreTableCell>
                    )
                  )}
                </OreTableRow>
              </OreTableHead>
              <OreTableBody as="tbody" valign="top">
                {employeesTR && employeesTR.length <= 0 ? (
                  <TableEmpty colSpan={7} firstText={t(forms.table.emptyMessage)} />
                ) : (
                  employeesTR
                    .slice((page - 1) * pageSize, page * pageSize)
                    .map((employeeTR: CardOrderModel, rowIndex: number) => {
                      return (
                        <UnloadConfigurationEmployeesRow
                          key={'rows' + rowIndex}
                          employeeTR={employeeTR}
                          isSelected={isSelected}
                          onSelectEmployee={onSelectEmployee}
                          rowId={rowIndex}
                          page={page}
                          pageSize={pageSize}
                          form={form}
                          updateDataRowToTROrderEmployee={updateDataRowToTROrderEmployee}
                          unloadAllBalance={unloadAllBalance}
                        />
                      )
                    })
                )}
              </OreTableBody>
            </OreTable>
            <OreTableActions>
              <OreStack placeContent="space-between" direction="row">
                <OreButton
                  category="highlight"
                  icon={<SvgOut />}
                  size="small"
                  onClick={() => unloadAllBalance()}>
                  {t(
                    unloadsConfigurationTranslations.table.footer.unloadAllFromEmployees
                  )}
                </OreButton>
                <OrePagination
                  count={showSelected ? selectedEmployees.length : totalRows}
                  labelNextPage={t(forms.pagination.next)}
                  labelPreviousPage={t(forms.pagination.back)}
                  onPageChange={onPageChange}
                  page={page}
                  rowsPerPage={pageSize}
                  text={t(forms.pagination.of)}
                />
              </OreStack>
            </OreTableActions>
          </OreTableContainer>
        </form>
      </FormProvider>
    </>
  )
}
