import { OreSwitch } from '@runroom/oreneta'
import { useEffect, useRef, useState } from 'react'
import type { ProductModel } from 'src/Flex/Products/domain'
import {
  StatusToggleModal,
  ToggleModalActions,
} from 'src/Flex/Products/ui/product-toggle-status'

export const StatusToggle = ({
  product: { active, productType, toggleable, hasModifiedLimits },
}: {
  product: ProductModel
}): JSX.Element => {
  const ref = useRef<ToggleModalActions>(null)
  const [_active, setActive] = useState(active)

  const handleToggleModal = (): void => {
    ref?.current?.evaluate()
  }

  const changeState = (): void => {
    setActive(!_active)
  }

  useEffect(() => {
    setActive(active)
  }, [active])

  return (
    <>
      <OreSwitch checked={_active} disabled={!toggleable} onChange={handleToggleModal} />
      <StatusToggleModal
        ref={ref}
        productType={productType}
        current={_active ? true : false}
        toggleButton={changeState}
        hasModifiedLimits={hasModifiedLimits}
      />
    </>
  )
}
