import { useEffect, useState } from 'react'

import { UseFormReturn, useFormContext } from 'react-hook-form'
import { CollectiveModel } from 'src/Flex/Collectives/domain'
import { EmployeeForm, useDebounceCallback } from 'src/Flex/Employees/ui/employee-detail'
import { EmployeeRenumerationCalculatorModel } from 'src/Flex/Employees/domain'
import { employeesService } from 'src/Flex/Employees/application'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

interface IProps {
  collectives?: CollectiveModel[]
  form: UseFormReturn<EmployeeForm>
}

interface IUseRetributionCalculator {
  value: number
  collectiveFlexibleRemunerationPercentage: number
}

export const useRetributionCalculator = ({
  collectives,
  form,
}: IProps): IUseRetributionCalculator => {
  const { formState, watch } = useFormContext<EmployeeForm>()
  const { handleMetaResponse } = useMetaResponseHandler()

  const workingPercentage = watch('workingPercentage')
  const annualGrossSalary = watch('annualGrossSalary')
  const salaryInKind = watch('salaryInKind')
  const collectiveId = watch('collectiveId')

  const [mustBeCalculated, setMustBeCalculated] = useState<boolean>(false)
  const [calculatedValue, setCalculatedValue] = useState<number>(0)

  useEffect(() => {
    setMustBeCalculated(true)
  }, [workingPercentage, annualGrossSalary, salaryInKind, collectiveId])

  useEffect(() => {
    setCalculatedValue(calculateValue ?? 0)
  }, [mustBeCalculated])

  const collectiveFlexibleRemunerationPercentage =
    collectives?.find(collective => collective.id === collectiveId)
      ?.flexibleCompensationPlanPercentage ?? 0

  const canBeCalculated =
    workingPercentage &&
    formState.errors.workingPercentage?.message === undefined &&
    annualGrossSalary &&
    formState.errors.annualGrossSalary?.message === undefined &&
    collectiveFlexibleRemunerationPercentage !== 0

  const getFlexibleRenumerationAmount = async (
    data: EmployeeRenumerationCalculatorModel
  ): Promise<number> => {
    const response = await employeesService().GetFlexibleRenumerationAmount({
      workingPercentage: data.workingPercentage,
      annualGrossSalary: data.annualGrossSalary,
      salaryInKind: data.salaryInKind,
      collectiveFlexibleRemunerationPercentage:
        data.collectiveFlexibleRemunerationPercentage,
    })

    setMustBeCalculated(false)

    handleMetaResponse(response?.meta, form, { notifySuccess: false, resetForm: false })

    return response.data
  }

  const calculateValue = useDebounceCallback<number | null>(
    async () => {
      if (canBeCalculated && mustBeCalculated) {
        return await getFlexibleRenumerationAmount({
          workingPercentage,
          annualGrossSalary,
          salaryInKind,
          collectiveFlexibleRemunerationPercentage,
        })
      }

      return 0
    },
    null,
    800
  )

  return {
    value: calculatedValue,
    collectiveFlexibleRemunerationPercentage,
  }
}
