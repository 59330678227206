import { useTranslation } from 'react-i18next'
import { accountNotificationTranslation } from 'src/domain/translations/myAccount/accountNotification'
import { PageHeader } from '../../../Templates/PageHeader'
import { AccountNotificationsContainer } from './organism/AccountNotificationsContainer'

export const AccountNotificationsPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <PageHeader
      title={t(accountNotificationTranslation.title)}
      organism={<AccountNotificationsContainer />}
    />
  )
}
