import { OreButton, OreHeading, OreStack, OreText } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { ExcelLayout, useExcelUpload } from 'src/Flex/Employees/ui/employee-excel'
import SuccessLogo from 'src/presentation/assets/img/Success.svg'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

export const ExcelConfirmation = (): JSX.Element => {
  const { t } = useTranslation()
  const { goToEmployees } = useExcelUpload()

  return (
    <ExcelLayout>
      <OreStack space="2xlarge" placeContent="center" placeItems="center">
        <img src={SuccessLogo} alt="" />
        <OreStack>
          <OreHeading as="h1" size="title-sm" align="center">
            {t(flexEmployeesTranslations.excel.confirmSuccess.title)}
          </OreHeading>
        </OreStack>
        <OreButton category="primary" onClick={goToEmployees}>
          {t(flexEmployeesTranslations.excel.confirmSuccess.button)}
        </OreButton>
      </OreStack>
    </ExcelLayout>
  )
}
