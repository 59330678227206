import type { Dispatch, SetStateAction } from 'react'
import { memo } from 'react'
import { OreIconButton, OreButton, OreBookmark, OreTooltip } from '@runroom/oreneta'
import type {
  ActionControlModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { useCellAction } from '../../hooks/table/useCellAction'
import {
  buttonAction,
  deleteAction,
  downloadAction,
  editAction,
  favsAction,
  iconButtonAction,
  refreshAction,
} from '../../../../../domain/constants'

interface Props {
  action: ActionControlModel
  rowId: number
  cellIndex: number
  setRows: Dispatch<SetStateAction<RowModel[]>>
  setSelectedRows: Dispatch<SetStateAction<number[]>>
  setTotalRows: Dispatch<SetStateAction<number[]>>
  confirmDelete?: boolean
  modalShow: boolean
  setModalShow: Dispatch<SetStateAction<boolean>>
  deleteConfirm: boolean
  setDeleteConfirm: Dispatch<SetStateAction<boolean>>
}

export const CellAction = memo((props: Props): JSX.Element => {
  const { onDeleteRow, onConfirmDeleteRow } = useCellAction(
    props.setRows,
    props.setSelectedRows,
    props.setTotalRows,
    props.modalShow,
    props.setModalShow,
    props.deleteConfirm,
    props.setDeleteConfirm,
    props.action.onClick
  )

  return (
    <>
      {props.action.icon && props.action.type === deleteAction && (
        <>
          {props.action.tooltip ? (
            <OreTooltip text={props.action.tooltip} placement="top" separation="small">
              <OreIconButton
                type="button"
                icon={props.action.icon}
                disabled={props.action.disabled}
                onClick={event =>
                  props.confirmDelete
                    ? onConfirmDeleteRow(event, props.rowId)
                    : onDeleteRow(event, props.rowId)
                }
                id={props.action.id}
              />
            </OreTooltip>
          ) : (
            <OreIconButton
              type="button"
              icon={props.action.icon}
              disabled={props.action.disabled}
              onClick={event =>
                props.confirmDelete
                  ? onConfirmDeleteRow(event, props.rowId)
                  : onDeleteRow(event, props.rowId)
              }
              id={props.action.id}
            />
          )}
        </>
      )}
      {props.action.icon && props.action.type === editAction && (
        <OreIconButton
          type="button"
          icon={props.action.icon}
          disabled={props.action.disabled}
          onClick={event => props.action.onClick(event, props.rowId)}
          id={props.action.id}
        />
      )}
      {props.action.icon && props.action.type === downloadAction && (
        <>
          {props.action.tooltip ? (
            <OreTooltip text={props.action.tooltip} placement="top" separation="small">
              <OreIconButton
                type="button"
                icon={props.action.icon}
                onClick={props.action.onClick}
                disabled={props.action.disabled}
                id={props.action.id}
              />
            </OreTooltip>
          ) : (
            <OreIconButton
              type="button"
              icon={props.action.icon}
              disabled={props.action.disabled}
              onClick={props.action.onClick}
              id={props.action.id}
            />
          )}
        </>
      )}
      {props.action.icon && props.action.type === refreshAction && (
        <>
          {props.action.tooltip ? (
            <OreTooltip text={props.action.tooltip} placement="top" separation="small">
              <OreIconButton
                type="button"
                icon={props.action.icon}
                disabled={props.action.disabled}
                onClick={event => props.action.onClick(event, props.rowId)}
                id={props.action.id}
              />
            </OreTooltip>
          ) : (
            <OreIconButton
              type="button"
              icon={props.action.icon}
              disabled={props.action.disabled}
              onClick={event => props.action.onClick(event, props.rowId)}
              id={props.action.id}
            />
          )}
        </>
      )}
      {props.action.type === favsAction && (
        <>
          {props.action.tooltip ? (
            <OreTooltip text={props.action.tooltip} placement="top" separation="small">
              <OreBookmark
                name={props.action.name}
                disabled={props.action.disabled}
                onChange={event => props.action.onChange(event, props.rowId)}
                checked={props.action.checked}
                id={props.action.id}
              />
            </OreTooltip>
          ) : (
            <OreBookmark
              name={props.action.name}
              disabled={props.action.disabled}
              onChange={event => props.action.onChange(event, props.rowId)}
              checked={props.action.checked}
              id={props.action.id}
            />
          )}
        </>
      )}
      {props.action.type === buttonAction && (
        <OreButton
          as="button"
          category={props.action.category}
          icon={props.action.icon}
          iconAlign={props.action.iconAlign}
          size="small"
          onClick={() => props.action.onClick(props.rowId)}
          id={props.action.id}>
          {props.action.text}
        </OreButton>
      )}
      {props.action.type === iconButtonAction && props.action.icon && (
        <>
          {props.action.tooltip ? (
            <OreTooltip text={props.action.tooltip} placement="top" separation="small">
              <OreIconButton
                type="button"
                icon={props.action.icon}
                disabled={props.action.disabled}
                onClick={() => props.action.onClick(props.rowId)}
                id={props.action.id}
              />
            </OreTooltip>
          ) : (
            <OreIconButton
              type="button"
              icon={props.action.icon}
              disabled={props.action.disabled}
              onClick={() => props.action.onClick(props.rowId)}
              id={props.action.id}
            />
          )}
        </>
      )}
    </>
  )
})
