export const currency = (
  value: number,
  reduceZeroFraction = true,
  curr = 'EUR',
  int = 'es-ES'
): string => {
  if (reduceZeroFraction && value % 1 === 0) {
    return new Intl.NumberFormat(int, {
      style: 'currency',
      currency: curr,
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(value)
  }

  return new Intl.NumberFormat(int, {
    style: 'currency',
    currency: curr,
    minimumFractionDigits: 2,
  }).format(value)
}
export const agregarSeparadorMiles = (value: string) => {
  const partesNumero = value.toString().split(',')

  partesNumero[0] = partesNumero[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return partesNumero.join(',')
}
