export const flexOrdersTranslations = {
  adherence: {
    title: 'flex.products.adherence.title',
    subtitle: 'flex.products.adherence.subtitle',
    table: {
      noProducts: 'flex.products.adherence.table.no_products',
      title: 'flex.products.adherence.table.title',
      header: {
        name: 'flex.products.adherence.table.header.name',
        description: 'flex.products.adherence.table.header.description',
        totalAmount: 'flex.products.adherence.table.header.total_amount',
        applicationDate: 'flex.products.adherence.table.header.application_date',
        action: 'flex.products.adherence.table.header.action',
        tooltip: 'flex.products.adherence.table.header.tooltip',
      },
      row: {
        months: 'flex.products.adherence.table.row.months',
        oneMonth: 'flex.products.adherence.table.row.one_month',
        actions: {
          declineLabel: 'flex.products.adherence.table.row.actions.decline_label',
          acceptLabel: 'flex.products.adherence.table.row.actions.accept_label',
        },
      },
    },
    modal: {
      title: 'flex.products.adherence.modal.title',
      description: 'flex.products.adherence.modal.description',
      textarea: {
        label: 'flex.products.adherence.modal.textarea.label',
        placeholder: 'flex.products.adherence.modal.textarea.placeholder',
      },
    },
    notification: {
      accepted: 'flex.products.adherence.notification.accepted',
      rejected: 'flex.products.adherence.notification.rejected',
    },
  },
  kinship: {
    1: 'flex.orders.kinship.1',
    2: 'flex.orders.kinship.2',
    3: 'flex.orders.kinship.3',
    4: 'flex.orders.kinship.4',
    5: 'flex.orders.kinship.5',
  },
}
