import { useTranslation } from 'react-i18next'
import { accountService } from 'src/core/services'
import { LegalDocumentType, NotificationSeverity } from 'src/domain/enum'
import { forms, navigationTranslation } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

interface AccountState {
  downloadDocument: (documentType: number) => void
}

export const useAccountController = (): AccountState => {
  const { addNotification } = useNotification()
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()

  const documentName = (documentType: number): string => {
    let documentName = ''
    switch (documentType) {
      case LegalDocumentType.useWebConditions:
        documentName = t(navigationTranslation.header.user.menu.usageConditions)
        break
      case LegalDocumentType.cookiesPolicies:
        documentName = t(navigationTranslation.header.user.menu.cookiesPolicy)
        break
      case LegalDocumentType.legalAdvice:
        documentName = t(navigationTranslation.header.user.menu.legal)
        break
      case LegalDocumentType.privacyPolicies:
        documentName = t(navigationTranslation.header.user.menu.privacyPolicy)
        break
    }
    return documentName
  }

  const downloadDocument = (documentType: number): void => {
    startLoading()
    accountService()
      .downloadLegalDocument(documentType)
      .then(response => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${response.data.file}`
        link.download = documentName(documentType) + '.pdf'
        link.click()
        link.remove()
      })
      .catch(() => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      })
      .finally(() => stopLoading())
  }

  return {
    downloadDocument,
  }
}
