import { Seq } from 'immutable'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'

interface FilterDatePickerState {
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    name: string,
    defaultValue: string,
    min: string,
    max: string
  ) => void
}
export const useFilterDatePicker = (
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
): FilterDatePickerState => {
  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: string,
    defaultValue = '',
    min = '',
    max = ''
  ) => {
    setFilter(prevFilter => {
      const newFilter = { ...prevFilter }
      if (newFilter.filterFields) {
        Seq(newFilter.filterFields).forEach((fields: FilterFieldModel) => {
          Seq(fields.filterFields).forEach((field: FilterControlModel) => {
            if (field.name === name) {
              const selectedValue = event.target.value
              const selectedDate = Date.parse(selectedValue)
              const minDate = Date.parse(min)
              const maxDate = Date.parse(max)
              if (selectedDate < minDate) field.value = min
              else if (selectedDate > maxDate) field.value = max
              else field.value = event.target.value ? event.target.value : defaultValue
            }
          })
        })
      }
      return newFilter
    })
  }

  return { onChange }
}
