import { createContext } from 'react'
import type { RowModel } from '../../../domain/customComponents/table/TableModel'
import type { BasicParameters, OrderConfigParameters } from '../../../domain/forms'
import type {
  CardOrderResumeModel,
  EmployeesWithLastCard,
} from '../../../domain/models/cardOrder/'
import type { CardOrderModel } from '../../../domain/models/cardOrder/CardOrderModel'
import type { SaveCardOrder } from '../../../domain/protocols/CardOrderProtocol'
import type { MessagesModel } from '../../../domain/models'
import type { OrderContext } from '../../../domain/enum'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'

type CardOrderContextProps = {
  orders: CardOrderModel[]
  orderExcel: CardOrderResumeModel
  setOrderExcel: (orderExcel: CardOrderResumeModel) => void
  orderExcelAlerts: MessagesModel[]
  setOrderExcelAlerts: (orderExcelAlerts: MessagesModel[]) => void
  isOrderExcel: () => boolean
  addOrder: (order: CardOrderModel) => void
  removeOrder: (order: any) => void
  editOrder: (order: any) => void
  getOrderById: (id: number) => any
  clearOrders: () => void
  clearAll: () => void
  clearByOrderType: (orderType: number) => void
  updateOrder: (orderToEdit: CardOrderModel) => void
  addOrUpdateOrders: (newOrders: CardOrderModel[]) => void
  cardOrderData: SaveCardOrder | undefined
  setCardOrderData: React.Dispatch<React.SetStateAction<SaveCardOrder | undefined>>
  cardOrderCheckout: CardOrderResumeModel | undefined
  setCardOrderCheckout: React.Dispatch<
    React.SetStateAction<CardOrderResumeModel | undefined>
  >
  setFormMode: (formParameters: BasicParameters<CardOrderModel>) => void
  formParameters: BasicParameters<CardOrderModel> | undefined
  getCardOrderDuplicates: () => EmployeesWithLastCardNew[]
  setCardOrderDuplicates: (duplicates: EmployeesWithLastCardNew[]) => void
  getCardOrderRecharges: () => EmployeesWithLastCardNew[]
  setCardOrderRecharges: (recharges: EmployeesWithLastCardNew[]) => void
  getCardOrderUnload: () => EmployeesWithLastCardNew[]
  setCardOrderUnload: (duplicates: EmployeesWithLastCardNew[]) => void
  setTREmployeesToConfig: (employees: CardOrderModel[]) => void
  getTREmployeesToConfig: () => CardOrderModel[]
  setTRSelectedEmployees: (employees: EmployeesWithLastCard[]) => void
  getTRSelectedEmployees: () => EmployeesWithLastCard[]
  clearCardOrderDuplicates: () => void
  clearCardOrderRecharges: () => void
  clearCardOrderUnload: () => void
  clearOrderExcel: () => void
  clearOrderCheckout: () => void
  addOrders: (orders: CardOrderModel[]) => void
  removeRechargeOrder: (orderId: number) => void
  removeUnloadOrder: (orderId: number) => void
  getAllRechargeOrders: () => CardOrderModel[]
  getAllUnloadOrders: () => CardOrderModel[]
  setConfigurationMode: (rechargesRow: OrderConfigParameters<RowModel[]>) => void
  configurationParameters: OrderConfigParameters<RowModel[]> | undefined
  base64File: string
  setBase64File: (base64File: string) => void
  base64FileAlerts: string
  setBase64FileAlerts: (base64File: string) => void
  orderContext: OrderContext
  resetOrderContextAs: (orderContext: OrderContext) => void
  leaveOrderEnabled: boolean
  setLeaveOrderEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const CardOrderContext = createContext<CardOrderContextProps>(
  {} as CardOrderContextProps
)
