import { PropsWithChildren } from 'react'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import {
  IUseOrderValidationController,
  IUseOrderValidationProps,
  useOrderValidationController,
} from 'src/Flex/Orders/ui/order-validation'

type Context = IUseOrderValidationController
type Props = PropsWithChildren & IUseOrderValidationProps

const [useOrderValidation, StateContextProvider] = createGenericContext<Context>()

const OrderValidationProvider = ({ children, ...rest }: Props): JSX.Element => {
  const hook = useOrderValidationController(rest)

  return <StateContextProvider value={{ ...hook }}>{children}</StateContextProvider>
}

export { OrderValidationProvider, useOrderValidation }
