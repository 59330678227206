export const orderHomeTranslation = {
  title: 'orderHomeTranslation.title',
  kindergartenTabText: 'orderHomeTranslation.kindergartenTabText',
  distributors: {
    recharge: {
      title: 'orderHomeTranslation.distributors.recharge.title',
      subtitle: 'orderHomeTranslation.distributors.recharge.subtitle',
      newCardExcel: 'orderHomeTranslation.distributors.recharge.newCardExcel',
      newCardForm: 'orderHomeTranslation.distributors.recharge.newCardForm',
    },
    newCard: {
      title: 'orderHomeTranslation.distributors.newCard.title',
      subtitle: 'orderHomeTranslation.distributors.newCard.subtitle',
      newCardExcel: 'orderHomeTranslation.distributors.newCard.newCardExcel',
      newCardForm: 'orderHomeTranslation.distributors.newCard.newCardForm',
      newDuplicated: 'orderHomeTranslation.distributors.newCard.newDuplicated',
    },
    assignAndRegister: {
      title: 'orderHomeTranslation.distributors.assignAndRegister.title',
      subtitle: 'orderHomeTranslation.distributors.assignAndRegister.subtitle',
      AssignmentAndNewExcel:
        'orderHomeTranslation.distributors.assignAndRegister.AssignmentAndNewExcel',
      newEmployeeAndChildForm:
        'orderHomeTranslation.distributors.assignAndRegister.newEmployeeAndChildForm',
      newChildForm: 'orderHomeTranslation.distributors.assignAndRegister.newChildForm',
      newAssignmentForm:
        'orderHomeTranslation.distributors.assignAndRegister.newAssignmentForm',
    },
    unload: {
      title: 'orderHomeTranslation.distributors.unload.title',
      subtitle: 'orderHomeTranslation.distributors.unload.subtitle',
      newUnloadForm: 'orderHomeTranslation.distributors.unload.newUnloadForm',
      newUnloadExcel: 'orderHomeTranslation.distributors.unload.newUnloadExcel',
    },
    kindergarten: {
      title: 'orderHomeTranslation.distributors.kindergarten.title',
      subtitle: 'orderHomeTranslation.distributors.kindergarten.subtitle',
      newOrderExcel: 'orderHomeTranslation.distributors.kindergarten.newOrderExcel',
      newEmployeeWithSonForm:
        'orderHomeTranslation.distributors.kindergarten.newEmployeeWithSonForm',
      newEmployeeWithSonExcel:
        'orderHomeTranslation.distributors.kindergarten.newEmployeeWithSonExcel',
      newSonForm: 'orderHomeTranslation.distributors.kindergarten.newSonForm',
      kindergartenForm: 'orderHomeTranslation.distributors.kindergarten.kindergartenForm',
    },
    newUser: {
      title: 'orderHomeTranslation.distributors.newUser.title',
      subtitle: 'orderHomeTranslation.distributors.newUser.subtitle',
      newUserExcel: 'orderHomeTranslation.distributors.newUser.newUserExcel',
      newUserForm: 'orderHomeTranslation.distributors.newUser.newUserForm',
    },
    newEmployee: {
      title: 'orderHomeTranslation.distributors.newEmployee.title',
    },
  },
  incompleteTable: {
    action: 'orderHomeTranslation.incompleteTable.action',
  },
}
