const readableFileType = (fileType: string): string => {
  switch (fileType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xls'
    case 'application/vnd.ms-excel':
      return 'xlsx'
    default:
      return fileType
  }
}

export const readableFileTypes = (fileType: string | string[]): string[] => {
  const fileTypes = Array.isArray(fileType) ? fileType : [fileType]

  return fileTypes.map(readableFileType)
}
