export const cardOrderTranslation = {
  form: {
    title: 'cardOrderTranslation.form.title',
    new: 'cardOrderTranslation.form.new',
    edit: 'cardOrderTranslation.form.edit',
    errors: {
      validateMinDateBirth: 'cardOrderTranslation.form.errors.validateMinDateBirth',
      validateMaxDateBirth: 'cardOrderTranslation.form.errors.validateMaxDateBirth',
      amountTwoDecimalsMax: 'cardOrderTranslation.form.errors.amountTwoDecimalsMax',
      dateBeforeToday: 'cardOrderTranslation.form.errors.dateBeforeToday',
      dateBeforOneYear: 'cardOrderTranslation.form.errors.dateBeforOneYear',
      dateBeforeMilesima: 'cardOrderTranslation.form.errors.dateBeforeMilesima',
      maxInitialAmount: 'cardOrderTranslation.form.errors.maxInitialAmount',
      numberValue: 'cardOrderTranslation.form.errors.numberValue',
    },
  },
  grid: {
    new: 'cardOrderTranslation.grid.new',
    duplicated: 'cardOrderTranslation.grid.duplicated',
    virtual: 'cardOrderTranslation.grid.virtual',
    plastic: 'cardOrderTranslation.grid.plastic',
  },
  menu: {
    newCard: 'cardOrderTranslation.menu.newCard',
    orderHistory: 'cardOrderTranslation.menu.orderHistory',
  },
  cardOrderCreate: {
    dateMessage: 'cardOrderTranslation.cardOrderCreate.dateMessage',
    conditionMsg: 'cardOrderTranslation.cardOrderCreate.conditionMsg',
    completeOrder: 'cardOrderTranslation.cardOrderCreate.completeOrder',
    completeAllSteps: 'cardOrderTranslation.cardOrderCreate.completeAllSteps',
    stepper: {
      step1: 'cardOrderTranslation.cardOrderCreate.stepper.step1',
      step2: 'cardOrderTranslation.cardOrderCreate.stepper.step2',
      step3: 'cardOrderTranslation.cardOrderCreate.stepper.step3',
      step4: 'cardOrderTranslation.cardOrderCreate.stepper.step4',
    },
    modalSacardOrderTranslationveAndClose: {
      title: 'cardOrderTranslation.cardOrderCreate.modalSaveAndClose.title',
      subTitle: 'cardOrderTranslation.cardOrderCreate.modalSaveAndClose.subTitle',
      orderName: 'cardOrderTranslation.cardOrderCreate.modalSaveAndClose.orderName',
      endDate: 'cardOrderTranslation.cardOrderCreate.modalSaveAndClose.endDate',
      endDateErrorMessage:
        'cardOrderTranslation.cardOrderCreate.modalSaveAndClose.endDateErrorMessage',
    },
    modalConfirm: {
      title: 'cardOrderTranslation.cardOrderCreate.modalConfirm.title',
      subTitle: 'cardOrderTranslation.cardOrderCreate.modalConfirm.subTitle',
    },
  },
  cardOrderForm: {
    title: 'cardOrderTranslation.cardOrderForm.title',
    employeeData: 'cardOrderTranslation.cardOrderForm.employeeData',
    selectCompany: 'cardOrderTranslation.cardOrderForm.selectCompany',
    costArea: 'cardOrderTranslation.cardOrderForm.costArea',
    employeeNumber: 'cardOrderTranslation.cardOrderForm.employeeNumber',
    cardData: 'cardOrderTranslation.cardOrderForm.cardData',
    product: 'cardOrderTranslation.cardOrderForm.product',
    ticketRestaurant: 'cardOrderTranslation.cardOrderForm.ticketRestaurant',
    cardType: 'cardOrderTranslation.cardOrderForm.cardType',
    virtual: 'cardOrderTranslation.cardOrderForm.virtual',
    plastic: 'cardOrderTranslation.cardOrderForm.plastic',
    chargeAmount: 'cardOrderTranslation.cardOrderForm.chargeAmount',
    expiredChargeDate: 'cardOrderTranslation.cardOrderForm.expiredChargeDate',
    sendData: 'cardOrderTranslation.cardOrderForm.sendData',
  },
  cardOrderFeatures: {
    dateValidation: 'cardOrderTranslation.cardOrderFeatures.dateValidation',
    acceptOrderConditions: 'cardOrderTranslation.cardOrderFeatures.acceptOrderConditions',
    orderSpecs: 'cardOrderTranslation.cardOrderFeatures.orderSpecs',
    initialChargeDate: 'cardOrderTranslation.cardOrderFeatures.initialChargeDate',
    acceptanceConditions: 'cardOrderTranslation.cardOrderFeatures.acceptOrderConditions',
    condition1: 'cardOrderTranslation.cardOrderFeatures.condition1',
    condition2: 'cardOrderTranslation.cardOrderFeatures.condition2',
    readAndAcceptConditions:
      'cardOrderTranslation.cardOrderFeatures.readAndAcceptConditions',
    billReference: 'cardOrderTranslation.cardOrderFeatures.billReference',
  },
  cardOrderList: {
    grid: {
      employeeName: 'cardOrderTranslation.cardOrderList.grid.employeeName',
      document: 'cardOrderTranslation.cardOrderList.grid.document',
      orderType: 'cardOrderTranslation.cardOrderList.grid.orderType',
      cardType: 'cardOrderTranslation.cardOrderList.grid.cardType',
      initialAmount: 'cardOrderTranslation.cardOrderList.grid.initialAmount',
      deliverySite: 'cardOrderTranslation.cardOrderList.grid.deliverySite',
      actions: 'cardOrderTranslation.cardOrderList.grid.actions',
    },
    cardsComplete: 'cardOrderTranslation.cardOrderList.cardsComplete',
    addNewCard: 'cardOrderTranslation.cardOrderList.addNewCard',
    addDuplicated: 'cardOrderTranslation.cardOrderList.addDuplicated',
  },
  paymentMode: 'cardOrderTranslation.cardOrderList.paymentMode',
  cardOrderResume: {
    title: 'cardOrderTranslation.cardOrderResume.title',
    headerTitle: 'cardOrderTranslation.cardOrderResume.headerTitle',
    deliveryTermsHeaderTitle:
      'cardOrderTranslation.cardOrderResume.deliveryTermsHeaderTitle',
    initialChargeDate: 'cardOrderTranslation.cardOrderResume.initialChargeDate',
    initialDischargeDate: 'cardOrderTranslation.cardOrderResume.initialDischargeDate',
    billReference: 'cardOrderTranslation.cardOrderResume.billReference',
    expiredDate: 'cardOrderTranslation.cardOrderResume.expiredDate',
    paymentMode: 'cardOrderTranslation.cardOrderResume.paymentMode',
    newCardsTitle: 'cardOrderTranslation.cardOrderResume.newCardsTitle',
    chargeTitle: 'cardOrderTranslation.cardOrderResume.chargeTitle',
    unloads: 'cardOrderTranslation.cardOrderResume.unloads',
    services: 'cardOrderTranslation.cardOrderResume.services',
    unit: 'cardOrderTranslation.cardOrderResume.unit',
    cost: 'cardOrderTranslation.cardOrderResume.cost',
    subTotal: 'cardOrderTranslation.cardOrderResume.subTotal',
    iva: 'cardOrderTranslation.cardOrderResume.iva',
    total: 'cardOrderTranslation.cardOrderResume.total',
    totalAmount: 'cardOrderTranslation.cardOrderResume.totalAmount',
    confirmOrder: 'cardOrderTranslation.cardOrderResume.confirmOrder',
    reminderDatePlaceholder:
      'cardOrderTranslation.cardOrderResume.reminderDatePlaceholder',
    plasticCards: 'cardOrderTranslation.cardOrderResume.plasticCards',
    virtualCards: 'cardOrderTranslation.cardOrderResume.virtualCards',
    recharges: 'cardOrderTranslation.cardOrderResume.recharges',
    in: 'cardOrderTranslation.cardOrderResume.in',
    workingDays: 'cardOrderTranslation.cardOrderResume.5to7workingDays',
    immediate: 'cardOrderTranslation.cardOrderResume.immediate',
    rateHours: 'cardOrderTranslation.cardOrderResume.24to48hours',
    oncePaymentRecieved: 'cardOrderTranslation.cardOrderResume.oncePaymentRecieved',
  },
  cardOrderComplete: {
    titleSuccess: 'cardOrderTranslation.cardOrderComplete.titleSuccess',
    successMessage1: 'cardOrderTranslation.cardOrderComplete.successMessage1',
    successMessage2: 'cardOrderTranslation.cardOrderComplete.successMessage2',
    successMessage3: 'cardOrderTranslation.cardOrderComplete.successMessage3',
    successMessage4: 'cardOrderTranslation.cardOrderComplete.successMessage4',
    successMessageIban: 'cardOrderTranslation.cardOrderComplete.successMessageIban',
    successMessageIban2: 'cardOrderTranslation.cardOrderComplete.successMessageIban3',
    estimatedDeliveryDate: 'cardOrderTranslation.cardOrderComplete.estimatedDeliveryDate',
    totalAmount: 'cardOrderTranslation.cardOrderComplete.totalAmount',
    cardOrderId: 'cardOrderTranslation.cardOrderComplete.cardOrderId',
    cardChargeOrderId: 'cardOrderTranslation.cardOrderComplete.cardChargeOrderId',
    unloadOrderId: 'cardOrderTranslation.cardOrderComplete.unloadOrderId',
    checkStatus: 'cardOrderTranslation.cardOrderComplete.checkStatus',
    pageHeading: 'cardOrderTranslation.cardOrderComplete.pageHeading',
    pageHeading2: 'cardOrderTranslation.cardOrderComplete.pageHeading2',
    pageHeadingCard: 'cardOrderTranslation.cardOrderComplete.pageHeadingCard',
    pageHeadingRecharge: 'cardOrderTranslation.cardOrderComplete.pageHeadingRecharge',
    pageHeadingUnload: 'cardOrderTranslation.cardOrderComplete.pageHeadingUnload',

    titleError: 'cardOrderTranslation.cardOrderComplete.titleError',
    errorMessage1: 'cardOrderTranslation.cardOrderComplete.errorMessage1',
    errorMessage2: 'cardOrderTranslation.cardOrderComplete.errorMessage2',

    table: {
      title: 'cardOrderTranslation.cardOrderComplete.table.title',
      total: 'cardOrderTranslation.cardOrderComplete.table.total',
      errorType: 'cardOrderTranslation.cardOrderComplete.table.errorType',
      errorDocument: 'cardOrderTranslation.cardOrderComplete.table.errorDocument',
      errorDescription: 'cardOrderTranslation.cardOrderComplete.table.errorDescription',
      errorNeedFix: 'cardOrderTranslation.cardOrderComplete.table.errorNeedFix',
    },

    newOrder: 'cardOrderTranslation.cardOrderComplete.newOrder',
    downloadButton: 'cardOrderTranslation.cardOrderComplete.downloadButton',

    transfer: {
      info: 'cardOrderTranslation.cardOrderComplete.transfer.info',
      info1: 'cardOrderTranslation.cardOrderComplete.transfer.info1',
      cardRecharge: {
        standard: 'cardOrderTranslation.cardOrderComplete.transfer.cardRecharge.standard',
        deferred: 'cardOrderTranslation.cardOrderComplete.transfer.cardRecharge.deferred',
        gruped: 'cardOrderTranslation.cardOrderComplete.transfer.cardRecharge.gruped',
      },
      card: {
        standard: 'cardOrderTranslation.cardOrderComplete.transfer.card.standard',
        deferred: 'cardOrderTranslation.cardOrderComplete.transfer.card.deferred',
        gruped: 'cardOrderTranslation.cardOrderComplete.transfer.card.gruped',
      },
      rechargeUnload: {
        info: 'cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.info',
        info1: 'cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.info1',
        standard:
          'cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.standard',
        deferred:
          'cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.deferred',
        gruped: 'cardOrderTranslation.cardOrderComplete.transfer.rechargeUnload.gruped',
      },
    },
    directDebit: {
      cardRecharge: {
        standard:
          'cardOrderTranslation.cardOrderComplete.directDebit.cardRecharge.standard',
        deferred:
          'cardOrderTranslation.cardOrderComplete.directDebit.cardRecharge.deferred',
        gruped: 'cardOrderTranslation.cardOrderComplete.directDebit.cardRecharge.gruped',
      },
      card: {
        standard: 'cardOrderTranslation.cardOrderComplete.directDebit.card.standard',
        deferred: 'cardOrderTranslation.cardOrderComplete.directDebit.card.deferred',
        gruped: 'cardOrderTranslation.cardOrderComplete.directDebit.card.gruped',
      },
      rechargeUnload: {
        standard:
          'cardOrderTranslation.cardOrderComplete.directDebit.rechargeUnload.standard',
        deferred:
          'cardOrderTranslation.cardOrderComplete.directDebit.rechargeUnload.deferred',
        gruped:
          'cardOrderTranslation.cardOrderComplete.directDebit.rechargeUnload.gruped',
      },
    },
  },
  cardOrderHome: {
    title: 'cardOrderTranslation.cardOrderHome.title',
    tableTitle: 'cardOrderTranslation.cardOrderHome.tableTitle',
    tableCaption: 'cardOrderTranslation.cardOrderHome.tableCaption',
    tableBodyEmpty: 'cardOrderTranslation.cardOrderHome.tableBodyEmpty',
    addNewLink: 'cardOrderTranslation.cardOrderHome.addNewLink',
    addDuplicateLink: 'cardOrderTranslation.cardOrderHome.addDuplicateLink',

    tableHeadName: 'cardOrderTranslation.cardOrderHome.tableHeadName',
    tableHeadReason: 'cardOrderTranslation.cardOrderHome.tableHeadReason',
    tableHeadType: 'cardOrderTranslation.cardOrderHome.tableHeadType',
    tableHeadInitialAmount: 'cardOrderTranslation.cardOrderHome.tableHeadInitialAmount',
    tableHeadBalance: 'cardOrderTranslation.cardOrderHome.tableHeadBalance',
    tableHeadBalanceTooltip: 'cardOrderTranslation.cardOrderHome.tableHeadBalanceTooltip',
    tableHeadDeliverySite: 'cardOrderTranslation.cardOrderHome.tableHeadDeliverySite',
    tableHeadActions: 'cardOrderTranslation.cardOrderHome.tableHeadActions',

    newPlastic: 'cardOrderTranslation.cardOrderHome.newPlastic',
    plasticLostStolen: 'cardOrderTranslation.cardOrderHome.plasticLostStolen',
    plasticDamage: 'cardOrderTranslation.cardOrderHome.plasticDamage',
    newVirtual: 'cardOrderTranslation.cardOrderHome.newVirtual',
    virtualLostStolen: 'cardOrderTranslation.cardOrderHome.virtualLostStolen',
    virtualDamage: 'cardOrderTranslation.cardOrderHome.virtualDamage',

    duplicated: 'cardOrderTranslation.cardOrderHome.duplicated',

    buttonOrder: 'cardOrderTranslation.cardOrderHome.buttonOrder',
    formTitle: 'cardOrderTranslation.cardOrderHome.formTitle',
    formSubTitle: 'cardOrderTranslation.cardOrderHome.formSubTitle',
    formSubTitleUnload: 'cardOrderTranslation.cardOrderHome.formSubTitleUnload',
    initialDate: 'cardOrderTranslation.cardOrderHome.initialDate',
    initialDateTooltip: 'cardOrderTranslation.cardOrderHome.initialDateTooltip',
    initialDateUnload: 'cardOrderTranslation.cardOrderHome.initialDateUnload',
    initialDateUnloadTooltip:
      'cardOrderTranslation.cardOrderHome.initialDateUnloadTooltip',
    expiredDate: 'cardOrderTranslation.cardOrderHome.expiredDate',
    expiredDateTooltip: 'cardOrderTranslation.cardOrderHome.expiredDateTooltip',
    referenceTitle: 'cardOrderTranslation.cardOrderHome.referenceTitle',
    billReference: 'cardOrderTranslation.cardOrderHome.billReference',
    termsAcceptance: 'cardOrderTranslation.cardOrderHome.termsAcceptance',
    termsAcceptanceExcel1: 'cardOrderTranslation.cardOrderHome.termsAcceptanceExcel1',
    termsAcceptanceExcel2: 'cardOrderTranslation.cardOrderHome.termsAcceptanceExcel2',
    modalDismissTitle: 'cardOrderTranslation.cardOrderHome.modalDismissTitle',
    modalDismissBody: 'cardOrderTranslation.cardOrderHome.modalDismissBody',
    modalDeleteTitle: 'cardOrderTranslation.cardOrderHome.modalDeleteTitle',
    modalDeleteBody: 'cardOrderTranslation.cardOrderHome.modalDeleteBody',
    modalSaveTitle: 'cardOrderTranslation.cardOrderHome.modalSaveTitle',
    modalSaveBody: 'cardOrderTranslation.cardOrderHome.modalSaveBody',
    modalSaveInputDescription:
      'cardOrderTranslation.cardOrderHome.modalSaveInputDescription',
    modalSaveInputReminder: 'cardOrderTranslation.cardOrderHome.modalSaveInputReminder',
    modalSaveInputReminderLegend:
      'cardOrderTranslation.cardOrderHome.modalSaveInputReminderLegend',

    expiredDateError: 'cardOrderTranslation.cardOrderHome.expiredDateError',
  },
  stepper: {
    firstStep: 'cardOrderTranslation.stepper.firstStep',
    secondStep: 'cardOrderTranslation.stepper.secondStep',
    thirdStep: 'cardOrderTranslation.stepper.thirdStep',
    fourthStep: 'cardOrderTranslation.stepper.fourthStep',
  },
  excel: {
    dropFileOr: 'cardOrderTranslation.cardOrderExcel.dropFileOr',
    browseFile: 'cardOrderTranslation.cardOrderExcel.browseFile',
    deleteFileText: 'cardOrderTranslation.cardOrderExcel.deleteFileText',
    errorUploadMessage: 'cardOrderTranslation.cardOrderExcel.errorUploadMessage',
    supportsText: 'cardOrderTranslation.cardOrderExcel.supportsText',
    validMessage: 'cardOrderTranslation.cardOrderExcel.validMessage',
    validationTitle: 'cardOrderTranslation.cardOrderExcel.validationTitle',
    validationBody: 'cardOrderTranslation.cardOrderExcel.validationBody',
    requiredError: 'cardOrderTranslation.cardOrderExcel.requiredError',
    fileFormatError: 'cardOrderTranslation.cardOrderExcel.fileFormatError',
    filesSizeError: 'cardOrderTranslation.cardOrderExcel.filesSizeError',
    repeatOrderText: 'cardOrderTranslation.cardOrderExcel.repeatOrderText',
    repeatOrderLink: 'cardOrderTranslation.cardOrderExcel.repeatOrderLink',
    errors: {
      downloadFileError: 'cardOrderTranslation.cardOrderExcel.errors.downloadFileError',
    },
  },
  excelHome: {
    cardSummaryTitle: 'cardOrderTranslation.cardOrderExcelHome.cardSummaryTitle',
    rechargeSummaryTitle: 'cardOrderTranslation.cardOrderExcelHome.rechargeSummaryTitle',
    unloadSummaryTitle: 'cardOrderTranslation.cardOrderExcelHome.unloadSummaryTitle',
    concepts: 'cardOrderTranslation.cardOrderExcelHome.concepts',
    units: 'cardOrderTranslation.cardOrderExcelHome.units',
  },
  excelAlerts: {
    table: {
      title: 'cardOrderTranslation.cardOrderExcelAlerts.table.title',
      totalAlerts: 'cardOrderTranslation.cardOrderExcelAlerts.table.totalAlerts',
      rowNumber: 'cardOrderTranslation.cardOrderExcelAlerts.table.rowNumber',
      errorType: 'cardOrderTranslation.cardOrderExcelAlerts.table.errorType',
      errorDescription:
        'cardOrderTranslation.cardOrderExcelAlerts.table.errorDescription',
      warningLabel: 'cardOrderTranslation.cardOrderExcelAlerts.table.warningLabel',
      errorLabel: 'cardOrderTranslation.cardOrderExcelAlerts.table.errorLabel',
    },
    buttons: {
      downloadErrorsList:
        'cardOrderTranslation.cardOrderExcelAlerts.buttons.downloadErrorsList',
      changeFile: 'cardOrderTranslation.cardOrderExcelAlerts.buttons.changeFile',
    },
    content: {
      paragraph: 'cardOrderTranslation.cardOrderExcelAlerts.content.paragraph',
      firstBullet: 'cardOrderTranslation.cardOrderExcelAlerts.content.firstBullet',
      secondBullet: 'cardOrderTranslation.cardOrderExcelAlerts.content.secondBullet',
    },
    file: {
      heading: {
        no: 'cardOrderTranslation.cardOrderExcelAlerts.file.heading.no',
        type: 'cardOrderTranslation.cardOrderExcelAlerts.file.heading.type',
        rowNumber: 'cardOrderTranslation.cardOrderExcelAlerts.file.heading.rowNumber',
        message: 'cardOrderTranslation.cardOrderExcelAlerts.file.heading.message',
      },
      cells: {
        error: 'cardOrderTranslation.cardOrderExcelAlerts.file.cells.error',
        recommendation:
          'cardOrderTranslation.cardOrderExcelAlerts.file.cells.recommendation',
      },
    },
  },
}
