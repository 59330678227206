import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { cardOrderReason, cardRequestTypeId, cardType } from '../../../../../domain/enum'
import type { CardOrderModel } from '../../../../../domain/models'
import {
  duplicatesConfigurationTranslations,
  forms,
} from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useUser } from '../../../../context/user/UserProvider'
import { useModalController } from '../../../Edenred'
import { useTranslation } from 'react-i18next'
import { SvgInfo } from '@runroom/oreneta'

export interface EditConfigurationState {
  caption: CaptionModel
  header: HeaderModel
  validateTable: boolean
  setRows: Dispatch<SetStateAction<RowModel[]>>
  rows: RowModel[]
  ValidateTableFields: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  navigate: NavigateFunction
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  clearCardOrderDuplicates: () => void
}

export const useEditDuplicatesConfigurationController = (): EditConfigurationState => {
  const {
    configurationParameters,
    getOrderById,
    editOrder,
    clearCardOrderDuplicates,
    clearAll,
  } = useCardOrder()
  const navigate = useNavigate()
  const [rows, setRows] = useState<RowModel[]>([])
  const [validateTable, setValidateTable] = useState<boolean>(false)
  const { t } = useTranslation()

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const { userDeliverySitesById } = useUser()

  const caption: CaptionModel = {
    title: t(duplicatesConfigurationTranslations.table.caption.title),
    description: t(duplicatesConfigurationTranslations.table.caption.description),
  }

  const header: HeaderModel = {
    headerLabel: [
      { label: t(duplicatesConfigurationTranslations.table.header.name) },
      { label: t(duplicatesConfigurationTranslations.table.header.email) },
      {
        label: t(duplicatesConfigurationTranslations.table.header.reason),
        tooltip: (
          <div>
            <p>{t(duplicatesConfigurationTranslations.table.header.reasonTooltip1)}</p>
            <p className="pt-05">
              {t(duplicatesConfigurationTranslations.table.header.reasonTooltip2)}
            </p>
          </div>
        ),
        tooltipIcon: <SvgInfo aria-label="info" />,
      },
      { label: t(duplicatesConfigurationTranslations.table.header.type) },
      { label: t(duplicatesConfigurationTranslations.table.header.deliverySite) },
    ],
  }

  const ValidateTableFields = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    setValidateTable(true)
  }

  const GetErrors = (): boolean => {
    let hasError = false
    Seq(rows).forEach(row => {
      Seq(row.cells).forEach(cell => {
        if (cell.cellControll && cell.cellControll.hasError === true) {
          hasError = true
          return
        }
      })
    })
    return hasError
  }

  const editDuplicatesToCardOrder = () => {
    Seq(rows).forEach(row => {
      let email: string | undefined
      let reason: string | undefined
      let type: string | undefined
      let requestType: number = cardRequestTypeId.default
      const cardOrder: CardOrderModel | undefined = getOrderById(row.rowId)
      let deliverySiteId: string | undefined
      Seq(row.cells).forEach(cell => {
        if (cell.cellControll && cell.cellControll.name === 'email') {
          email = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'reason') {
          reason = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'type') {
          type = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'deliverySites') {
          deliverySiteId = cell.cellControll.value
        }
      })

      if (reason && parseInt(reason.trim()) === cardOrderReason.stolenLost) {
        if (type && parseInt(type.trim()) === cardType.Plastic)
          requestType = cardRequestTypeId.lostStolenPlastic
        else requestType = cardRequestTypeId.lostStolenPlasticless
      }
      if (reason && parseInt(reason.trim()) === cardOrderReason.damage) {
        if (type && parseInt(type.trim()) === cardType.Plastic)
          requestType = cardRequestTypeId.damagePlastic
        else requestType = cardRequestTypeId.damagePlasticless
      }

      if (cardOrder) {
        cardOrder.cardData.cardTypeId = type ? parseInt(type.trim()) : cardType.Plastic
        cardOrder.cardData.cardRequestTypeId = requestType
        cardOrder.employeeData.email = email
        userDeliverySitesById(Number(deliverySiteId)).then(deliverySiteResponse => {
          if (deliverySiteResponse && cardOrder?.sendData) {
            cardOrder.sendData.alias = deliverySiteResponse.alias
            cardOrder.sendData.city = deliverySiteResponse.city
            cardOrder.sendData.contactName = deliverySiteResponse.contactName
            cardOrder.sendData.deliverySiteId = deliverySiteResponse.deliveryPointId
            cardOrder.sendData.deliveryTypeId = deliverySiteResponse.deliveryType
            cardOrder.sendData.email = deliverySiteResponse.email
            cardOrder.sendData.name = deliverySiteResponse.deliveryPointName
            cardOrder.sendData.observation = deliverySiteResponse.observation
            cardOrder.sendData.province = deliverySiteResponse.province
            cardOrder.sendData.streetDescription = deliverySiteResponse.streetDescription
            cardOrder.sendData.streetName = deliverySiteResponse.streetName
            cardOrder.sendData.streetNumber = deliverySiteResponse.streetNumber
            cardOrder.sendData.telephone = deliverySiteResponse.telephone
            cardOrder.sendData.zipCode = deliverySiteResponse.zipCode
          }
        })
        editOrder(cardOrder)
      }
    })
    navigate(navigationRoutes.cardOrderHome)
  }

  const handleDiscardPopup = () => {
    clearAll()
    clearCardOrderDuplicates()
    setShowPopup(false)
    navigate(navigationRoutes.cardOrderHome)
  }

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => handleDiscardPopup(),
        size: 'large',
      },
    ]
  }

  useEffect(() => {
    setTitlePopup(t(duplicatesConfigurationTranslations.modal.title))
    setDescriptionPopup(t(duplicatesConfigurationTranslations.modal.description))
    setButtonsPopup(SetPopupButtons())
  }, [])

  useEffect(() => {
    if (validateTable) {
      if (!GetErrors()) editDuplicatesToCardOrder()
      setValidateTable(false)
    }
  }, [validateTable])

  useEffect(() => {
    if (configurationParameters && configurationParameters.entity)
      setRows(configurationParameters.entity)
  }, [configurationParameters])

  return {
    caption,
    header,
    validateTable,
    setRows,
    rows,
    ValidateTableFields,
    navigate,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    clearCardOrderDuplicates,
  }
}
