import i18n, { LOCALE_TYPES, LocaleTypes } from 'src/presentation/i18n/i18n'
import { dash } from '../../domain/constants/symbols'
import { DateMonthYear, dateEnum } from 'src/domain'
import { t } from 'i18next'
import { IMonths } from 'src/domain/interfaces/global/IMonths'
import { forms } from 'src/domain/translations'
import { addYears, differenceInYears, format, isWithinInterval } from 'date-fns'

export const minAge = () => bornDate(16)
export const maxAge = () => bornDate(100)

export const minChildAge = () => getToday()
export const maxChildAge = (max = 7) => bornDate(max)

export const bornDate = (age: number): string => {
  const today = new Date().toISOString().slice(0, 10).split(dash)
  const year = (Number(today[0]) - age).toString()

  return `${year}-${today[1]}-${today[2]}`
}

export const getToday = () => new Date().toISOString().split('T')[0]

export const getTomorrow = () => {
  const today = new Date()
  return new Date(today.setDate(today.getDate() + 1)).toISOString().split('T')[0]
}

export const getDaysAfter = (days: number): string => {
  const today = new Date()
  return new Date(today.setDate(today.getDate() + days)).toISOString().split('T')[0]
}

export const getLastDayOfMonthOneYearLater = (): string => {
  const today = new Date()
  const oneYearLater = new Date(today.getFullYear() + 1, today.getMonth() + 1, 0)
  oneYearLater.setDate(oneYearLater.getDate() + 1)
  return oneYearLater.toISOString().split('T')[0]
}

export const getLastMilesimaDate = (): string => {
  const today = new Date()
  const oneYearLater = new Date(today.getFullYear() + 1, 2, 30, 0) // current year + March of next year
  oneYearLater.setDate(oneYearLater.getDate() + 1)
  return oneYearLater.toISOString().split('T')[0]
}

export const getTTLastMilesimaDate = (): string => {
  const today = new Date()
  const oneYearLater = new Date(today.getFullYear() + 1, 3, 30, 0) // current year + April of next year
  oneYearLater.setDate(oneYearLater.getDate() + 1)
  return oneYearLater.toISOString().split('T')[0]
}

export const dateIsAfterLastDayOfMonthOneYearLater = (date: string): boolean => {
  const compareDate = new Date(date)
  const maxDate = new Date(getLastDayOfMonthOneYearLater())
  return compareDate > maxDate
}

export const dateIsAfterMilesimaDate = (date: string): boolean => {
  const compareDate = new Date(date)
  const maxDate = new Date(getLastMilesimaDate())
  return compareDate > maxDate
}

export const dateIsAfterTTMilesimaDate = (date: string): boolean => {
  const compareDate = new Date(date)
  const maxDate = new Date(getLastMilesimaDate())
  return compareDate > maxDate
}

export const getDateAfter = (initialDate: string, finalDate: string): boolean => {
  const startDate = Date.parse(initialDate)
  const endDate = Date.parse(finalDate)

  return startDate <= endDate
}

export const dateValueIsLowerThanTomorrow = (dateValue: string): boolean => {
  const today = new Date()
  const tomorrow = new Date(today.setDate(today.getDate() + 1))
    .toISOString()
    .slice(0, 10)
    .split(dash)
  const year = Number(tomorrow[0]).toString()
  const month = tomorrow[1]
  const day = tomorrow[2]
  const minDate = `${year}-${month}-${day}`
  const compareDate = Date.parse(minDate)
  const currentDate = Date.parse(dateValue)
  return (currentDate < compareDate) as boolean
}

export const dateValueIsLowerThanToday = (dateValue: string): boolean => {
  const inputDate = new Date(dateValue)
  const today = new Date()

  // Set the time of today's date to midnight to only compare the date part
  today.setHours(0, 0, 0, 0)

  return inputDate < today
}

interface DateFormatOptions {
  day: '2-digit'
  month: '2-digit'
  year: 'numeric'
}

const dateOptions: DateFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
}

export const GetDateFormatted = (date: string | Date): string => {
  return new Date(date).toLocaleDateString('es-ES', dateOptions)
}

export const GetFullDateFormatted = (date: string | Date): string => {
  return new Date(date).toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

export const GetDateFormattedLong = (date: string | Date): string => {
  return new Date(date).toLocaleDateString(
    LOCALE_TYPES[i18n.languages[0] as keyof LocaleTypes],
    {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }
  )
}

export const GetMonthFormattedWithYear = (date: string | Date): string => {
  return new Date(date).toLocaleDateString(
    LOCALE_TYPES[i18n.languages[0] as keyof LocaleTypes],
    {
      month: 'long',
      year: 'numeric',
    }
  )
}

export const ConvertESDateToISO = (date: string): string => {
  return date.split('/').reverse().join(dash)
}

export const getFirstDayOfMonthOneYearBefore = (): string => {
  const date = new Date()
  const firstDay = new Date(date.getFullYear() - 1, date.getMonth(), 2)
  return firstDay.toISOString().split('T')[0]
}

export const ConvertToISODateOnly = (isoDate: string): string => {
  if (isoDate.includes('T')) {
    return isoDate.split('T')[0]
  }
  return isoDate
}

export const ConvertDateToISO = (date?: Date): string => {
  if (date) {
    return date.toISOString()
  }
  return ''
}

/* ==================================

    !Important
   Use this function to convert to ISO when use "Date Input"
   
===================================== */
export const ConvertStringDateToISOOnly = (date?: string): string => {
  if (date) {
    const d = new Date(date.toString())
    return ConvertDateToISOPlusOne(d).split('T')[0]
  }
  return ''
}

// THIS FUNCTION RELPACES ConvertStringDateToISOOnly
export const formatDateToISO = (date: Date | string): string => {
  return format(new Date(date), 'yyyy-MM-dd')
}

//Porque el .toISOString resta 1 dia
export const ConvertDateToISOPlusOne = (date?: Date): string => {
  if (date && !isNaN(date.getTime())) {
    date.setDate(date.getDate() + 1)
    return date.toISOString()
  }
  return ''
}

export const getLastDayOfMonth = () => {
  const date = new Date()
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return lastDay.getDate()
}

export const GetMonthYear = (date: string): DateMonthYear => {
  const splitterDate = date.split('-')
  const monthYear: DateMonthYear = { month: '', stringMonth: '', year: '' }
  switch (parseInt(splitterDate[1])) {
    case dateEnum.january:
      monthYear.stringMonth = t(forms.months.january)
      break
    case dateEnum.february:
      monthYear.stringMonth = t(forms.months.february)
      break
    case dateEnum.march:
      monthYear.stringMonth = t(forms.months.march)
      break
    case dateEnum.april:
      monthYear.stringMonth = t(forms.months.april)
      break
    case dateEnum.may:
      monthYear.stringMonth = t(forms.months.may)
      break
    case dateEnum.june:
      monthYear.stringMonth = t(forms.months.june)
      break
    case dateEnum.july:
      monthYear.stringMonth = t(forms.months.july)
      break
    case dateEnum.august:
      monthYear.stringMonth = t(forms.months.august)
      break
    case dateEnum.september:
      monthYear.stringMonth = t(forms.months.september)
      break
    case dateEnum.october:
      monthYear.stringMonth = t(forms.months.october)
      break
    case dateEnum.november:
      monthYear.stringMonth = t(forms.months.november)
      break
    case dateEnum.december:
      monthYear.stringMonth = t(forms.months.december)
      break
    default:
      break
  }
  monthYear.month = splitterDate[1]
  monthYear.year = splitterDate[0]
  return monthYear
}

export const GetMonthsName = (months: IMonths): string => {
  const trueMonths: string[] = []
  Object.entries(months).forEach(([month, value]) => {
    if (value) {
      trueMonths.push(t(`forms.months.${month.toLowerCase()}`))
    }
  })
  return trueMonths.join(', ')
}

export const willTurnXDuringSchoolYear = (birthDate: string, x: number): boolean => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const xBirthday = addYears(new Date(birthDate), x)

  const schoolYearStart = new Date(currentYear, 8, 1) // September 1st of the current year
  const schoolYearEnd = new Date(currentYear + 1, 7, 31) // August 31st of the next year

  return isWithinInterval(xBirthday, { start: schoolYearStart, end: schoolYearEnd })
}

export const isOlderThan3OrWillTurn4DuringSchool = (birthDate?: string): boolean => {
  if (!birthDate) return false

  const today = new Date()
  const currentSchoolYEarStart = new Date(today.getFullYear(), 8, 1) // September 1st of the current year
  const currentSchoolYearEnd = new Date(today.getFullYear() + 1, 7, 31) // August 31st of the next year

  const childAge = differenceInYears(today, new Date(birthDate))

  if (childAge > 3) return true

  const xBirthday = addYears(new Date(birthDate), 3)

  return (
    !isWithinInterval(xBirthday, {
      start: currentSchoolYEarStart,
      end: currentSchoolYearEnd,
    }) && childAge === 3
  )
}

export const isOlderThan6OrWillTurn7DuringSchool = (birthDate: string): boolean => {
  const today = new Date()
  const currentSchoolYEarStart = new Date(today.getFullYear(), 8, 1) // September 1st of the current year
  const currentSchoolYearEnd = new Date(today.getFullYear() + 1, 7, 31) // August 31st of the next year

  const childAge = differenceInYears(today, new Date(birthDate))

  if (childAge > 6) return true

  const xBirthday = addYears(new Date(birthDate), 6)

  return (
    !isWithinInterval(xBirthday, {
      start: currentSchoolYEarStart,
      end: currentSchoolYearEnd,
    }) && childAge === 6
  )
}
