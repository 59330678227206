import { TFunction } from 'react-i18next'
import { StringSchema } from 'yup'
import { forms } from 'src/domain/translations'
import { stringSchema } from 'src/Flex/Shared/ui/Form'

export const phoneSchema = (
  t: TFunction<'translation', undefined>
): StringSchema<string | undefined> =>
  stringSchema(t)
    .test('phoneFormatValidate', t(forms.errors.phoneInvalid), value => {
      const isValid = true

      if (value !== '')
        return value?.match(/(6|7|8|9)[ -]*([0-9][ -]*){8}$/) === null ? false : true

      return isValid
    })
    .max(9, t(forms.errors.maxAllowed) + ' ' + 9)
