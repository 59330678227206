import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { PropsWithChildren } from 'react'

import {
  IUsePendingTasksController,
  usePendingTasksController,
} from 'src/Flex/TaskManager/ui/pending-tasks'

type Context = IUsePendingTasksController

type Props = PropsWithChildren & {
  actioner: (id: number) => void
}

const [usePendingTasks, StateContextProvider] = createGenericContext<Context>()

const PendingTasksProvider = ({ children, actioner }: Props): JSX.Element => {
  const hook = usePendingTasksController({ actioner })

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { PendingTasksProvider, usePendingTasks }
