import { OreButton, OreHeading, OreText, SvgArrowLeft, SvgSave } from '@runroom/oreneta'
import { currency, orderType } from '../../../../../domain/enum/cardOrder'
import { forms } from '../../../../../domain/translations'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { Divider } from '../../../../layout'
import { TTOrderCheckout } from '../molecules/TTOrderCheckout'
import { TTOrderCheckoutHeader } from '../molecules/TTOrderCheckoutHeader'
import { Modal } from 'src/presentation/components/Edenred'
import { useTTOrderCheckout } from '../hooks/useTTOrderCheckout'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { TTOrderSaveAndClose } from '../../TTOrderSaveAndClose/TTOrderSaveAndClose'

export const TTOrderCheckoutContainer = (): JSX.Element => {
  const {
    setShowCancelModal,
    showCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    confirmExcelOrder,
    confirmOrder,
    showSaveCloseModal,
    setShowSaveCloseModal,
    ttOrderCheckout,
    ttSummaryForm,
  } = useTTOrderCheckout()
  const location = useLocation()
  const data: any = location.state

  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!ttOrderCheckout) return <></>

  return (
    <>
      <TTOrderCheckoutHeader
        detail={{
          paymentMode: ttOrderCheckout.data
            ? ttOrderCheckout.data.detail.paymentMode
            : '',
          paymentDescription: ttOrderCheckout.data
            ? ttOrderCheckout.data.detail.paymentDescription
            : '',
          paymentTooltip: ttOrderCheckout.data
            ? ttOrderCheckout.data.detail.paymentTooltip
            : '',
          initialChargeDate: ttSummaryForm.initialChargeDate
            ? ttSummaryForm.initialChargeDate
            : ttOrderCheckout.data?.detail.initialChargeDate,
          billReference: ttSummaryForm.billReference
            ? ttSummaryForm.billReference
            : ttOrderCheckout.data?.detail.billReference,
        }}
      />
      <TTOrderCheckout
        data={ttOrderCheckout.data?.card}
        quantity={ttOrderCheckout.data?.card.quantity}
        title={t(cardOrderTranslation.cardOrderResume.newCardsTitle)}
      />
      <TTOrderCheckout
        data={ttOrderCheckout.data?.charge}
        quantity={ttOrderCheckout.data?.charge.quantity}
        title={
          ttSummaryForm.orderTypeId === orderType.Unload
            ? t(cardOrderTranslation.cardOrderResume.unloads)
            : t(cardOrderTranslation.cardOrderResume.chargeTitle)
        }
      />
      <section
        className="width-max"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <OreHeading as="h4" size="headline-md">
          {t(cardOrderTranslation.cardOrderResume.totalAmount)}
        </OreHeading>
        <OreHeading as="h4" size="headline-md">
          {ttOrderCheckout.data?.totalAmount + currency.euro}
        </OreHeading>
      </section>

      <section className="width-max">
        <Divider />
        <div className="flex flex--row mt-2 space-between">
          <div className="flex flex--row">
            <>
              <OreButton
                as="button"
                category="highlight"
                onClick={() => navigate(-1)}
                size="large"
                style={{ color: '#0F172A' }}
                icon={<SvgArrowLeft />}>
                {t(forms.buttons.goBack)}
              </OreButton>
              {!data ||
                (!data.isExcel && (
                  <OreButton
                    category="tertiary"
                    icon={<SvgSave />}
                    onClick={() => setShowSaveCloseModal(true)}
                    size="large">
                    {t(forms.buttons.saveAndClose)}
                  </OreButton>
                ))}
            </>
          </div>

          <div className="flex flex--row float-right">
            <OreButton
              as="button"
              category="secondary"
              onClick={() => onClickCancel()}
              size="large">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              onClick={() =>
                data && data.isExcel ? confirmExcelOrder() : confirmOrder()
              }
              size="large">
              {t(cardOrderTranslation.cardOrderResume.confirmOrder)}
            </OreButton>
          </div>
        </div>
      </section>

      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>

      <TTOrderSaveAndClose
        setShowSaveCloseModal={setShowSaveCloseModal}
        showSaveCloseModal={showSaveCloseModal}
      />
    </>
  )
}
