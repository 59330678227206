import {
  OrePill,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  SvgBankTransfer,
  SvgDomiciliaryReceipt,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import type { CardOrderHeaderDetailModel } from '../../../../../domain/models'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import React from 'react'
import { PaymentModes } from '../../../../../domain/enum'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { paymentMethodsTranslation } from 'src/domain/translations'
import { GetDateFormatted } from 'src/core/helpers/dates'

interface Props {
  detail?: CardOrderHeaderDetailModel
}

export const TGDOrderCheckoutHeader = ({ detail }: Props): JSX.Element => {
  const { t } = useTranslation()
  const initialChargeLabel = t(TGDOrderTranslation.checkout.initialDate)
  const initialChargeDate = GetDateFormatted(
    detail?.initialChargeDate ? detail?.initialChargeDate : ''
  )
  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption title={t(TGDOrderTranslation.checkout.headerTitle)} />
        <OreTableBody>
          {detail?.initialChargeDate && (
            <OreTableRow>
              <OreTableCell align="left">{initialChargeLabel} </OreTableCell>
              <OreTableCell align="right">
                <span className="bold">{initialChargeDate}</span>
              </OreTableCell>
            </OreTableRow>
          )}
          {detail?.expiredDate && (
            <OreTableRow>
              <OreTableCell align="left">
                {t(cardOrderTranslation.cardOrderResume.expiredDate)}
              </OreTableCell>
              <OreTableCell align="right">
                <span className="bold">{detail?.expiredDate}</span>
              </OreTableCell>
            </OreTableRow>
          )}
          {detail?.billReference && (
            <OreTableRow>
              <OreTableCell align="left">
                {t(cardOrderTranslation.cardOrderResume.billReference)}
              </OreTableCell>
              <OreTableCell align="right">
                <span className="bold">{detail?.billReference}</span>
              </OreTableCell>
            </OreTableRow>
          )}
          {detail?.paymentMode && (
            <OreTableRow>
              <OreTableCell align="left">
                {t(cardOrderTranslation.cardOrderResume.paymentMode)}
              </OreTableCell>
              <OreTableCell align="right">
                {detail.paymentMode === PaymentModes.wireTransfer && (
                  <div style={{ display: 'inline-block' }}>
                    <OrePill icon={<SvgBankTransfer aria-hidden />} tone="cobalt-600">
                      {t(paymentMethodsTranslation.wireTransfer.name)}
                    </OrePill>
                  </div>
                )}
                {detail.paymentMode === PaymentModes.directDebit && (
                  <div style={{ display: 'inline-block' }}>
                    <OrePill
                      icon={<SvgDomiciliaryReceipt aria-hidden />}
                      tone="cobalt-600">
                      {t(paymentMethodsTranslation.directDebit.name)}
                    </OrePill>
                  </div>
                )}
              </OreTableCell>
            </OreTableRow>
          )}
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
}
