import { UseFormReturn, useForm } from 'react-hook-form'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEffect, useState } from 'react'
import {
  TemplateConfigurationModel,
  templateTypes,
} from 'src/Flex/CommunicationsTemplates/domain'
import { communicationsTemplatesService } from 'src/Flex/CommunicationsTemplates/application/communicationsTemplatesService'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useTranslation, TFunction } from 'react-i18next'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'

import { publishEvent } from 'src/Flex/Shared/ui/Events/helpers'
import { Events as CommunicationsEvents } from 'src/Flex/CommunicationsTemplates/domain'

export interface TemplateForm {
  subject: string
  bodyEmail: string
  description?: string | undefined
  name: string | undefined
}

export interface IUseConfigureTemplateController {
  model: TemplateConfigurationModel | undefined
  form: UseFormReturn<TemplateForm>
  load: (id?: string) => Promise<void>
  save: (data: TemplateForm) => Promise<void>
  test: (id: string, email: string) => Promise<boolean>
  initialState: string
  loadVars: () => Promise<void>
  dirtyEditor: boolean
  setDirtyEditor: (value: boolean) => void
  varsError: string[]
}

export type IUseConfigureTemplateProps = {
  id?: string
}

const schema = (t: TFunction<'translation', undefined>, id: string | undefined) => {
  const o = {
    subject: Yup.string().required(t(forms.errors.fieldRequired)),
    bodyEmail: Yup.string().test('is-not-empty', t(forms.errors.fieldRequired), value => {
      value = value?.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '')

      return value === '' ? false : true
    }),
  }

  if (id === undefined) {
    const createValidation = {
      description: Yup.string(),
      name: Yup.string().required(t(forms.errors.fieldRequired)),
    }

    Object.assign(o, createValidation)
  }

  const scheme = Yup.object(o)

  return scheme
}

export const useConfigureTemplateController = ({
  id,
}: IUseConfigureTemplateProps): IUseConfigureTemplateController => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const navigate = useNavigate()
  const [varsError, setVarsError] = useState<string[]>([])
  const [dirtyEditor, setDirtyEditor] = useState<boolean>(false)
  const addError = (name: string): void => {
    if (varsError.indexOf(name) !== -1) {
      return
    }
    setVarsError([...varsError, name])
  }

  const [model, setModel] = useState<TemplateConfigurationModel>()
  const [initialState, setInitialState] = useState<string>('')

  useEffect(() => {
    if (id) {
      load(id)
    } else {
      loadVars()
    }
  }, [id])

  const form = useForm<TemplateForm>({
    resolver: yupResolver(schema(t, id)),
    mode: 'onChange',
  })

  const save = async (data: TemplateForm): Promise<void> => {
    setVarsError([])
    let validation = true

    model?.availableVars &&
      model?.availableVars.forEach(v => {
        if (v.required && data.bodyEmail.indexOf(v.name) === -1) {
          addError(v.name)
          validation = false
        }
      })

    if (!validation) {
      return
    }

    startLoading()

    const response = id
      ? await communicationsTemplatesService().SaveTemplate({
          ...data,
          id,
        })
      : await communicationsTemplatesService().CreateTemplate(data)

    stopLoading()

    if (handleMetaResponse(response?.meta, form)) {
      setDirtyEditor(false)
      id && publishEvent(CommunicationsEvents.EDIT_COMMUNICATION)

      if (undefined === id && typeof response.data === 'string') {
        navigate(
          flexNavigationRoutes.flexCommunicationsTemplatesEdit.replace(
            ':id',
            response.data
          )
        )
      }
    }
  }

  const load = async (id?: string): Promise<void> => {
    startLoading()

    const response = await communicationsTemplatesService().GetTemplateConfiguration(id)

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setModel(response.data)

      form.reset({
        ...form.getValues(),
        subject: response.data.subject,
        bodyEmail: response.data.bodyEmail,
      })

      setInitialState(response.data.bodyEmail)
    }
  }

  const loadVars = async (): Promise<void> => {
    startLoading()

    const response = await communicationsTemplatesService().GetAvailableVars()

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setModel({
        name: '',
        subject: '',
        bodyEmail: '',
        type: templateTypes.email,
        availableVars: response.data,
      })
    }
  }

  const test = async (id: string, email: string): Promise<boolean> => {
    startLoading()

    const response = await communicationsTemplatesService().TestTemplate(id, email)

    stopLoading()

    return handleMetaResponse(response?.meta, form, {
      successMessage: t(flexCommunicationsTemplatesTranslations.sendModal.success),
    })
  }

  return {
    model,
    form,
    load,
    save,
    test,
    initialState,
    loadVars,
    dirtyEditor,
    varsError,
    setDirtyEditor,
  }
}
