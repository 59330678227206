import { useContext, useState } from 'react'
import { TGDBasicOrderContext } from './TGDBasicOrderContext'
import {
  TGDBasicEmployeeAndChildsModel,
  TGDBasicOrderSummaryForm,
} from 'src/domain/models/TGDOrder/TGDBasicOrderModel'
import { CardOrderResumeDTO } from 'src/domain/dto'

export const TGDBasicOrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [tgdOrders, setTgdOrders] = useState<TGDBasicEmployeeAndChildsModel[]>([])
  const [tgdOrderCheckout, setTgdOrderCheckout] = useState<CardOrderResumeDTO>(
    {} as CardOrderResumeDTO
  )
  const [tgdSummaryForm, setTgdSummaryForm] = useState<TGDBasicOrderSummaryForm>(
    {} as TGDBasicOrderSummaryForm
  )

  const addTGDBasicOrder = (tgdOrder: TGDBasicEmployeeAndChildsModel): void => {
    setTgdOrders([...tgdOrders, { ...tgdOrder }])
  }

  const addTGDBasicOrders = (tgdOrders: TGDBasicEmployeeAndChildsModel[]) => {
    tgdOrders.forEach(order => {
      setTgdOrders(prevOrders => [...prevOrders, order])
    })
  }

  const addTGDBasicOrderCheckout = (checkout: CardOrderResumeDTO): void => {
    setTgdOrderCheckout({ ...checkout })
  }

  const addTGDBasicSummaryForm = (form: TGDBasicOrderSummaryForm): void => {
    setTgdSummaryForm({ ...form })
  }

  const updateTGDBasicOrder = (tgdOrder: TGDBasicEmployeeAndChildsModel): void => {
    const index: number = tgdOrders.findIndex(
      (o: TGDBasicEmployeeAndChildsModel) => o.id === tgdOrder.id
    )
    const updatedOrders: TGDBasicEmployeeAndChildsModel[] = [...tgdOrders]
    updatedOrders[index] = tgdOrder
    setTgdOrders(updatedOrders)
  }

  const removeTGDBasicOrder = (id: string): void => {
    setTgdOrders(tgdOrders => tgdOrders.filter(order => order.id !== id))
  }

  const clearTGDBasicOrders = (): void => {
    setTgdOrders([] as TGDBasicEmployeeAndChildsModel[])
  }

  const context = {
    tgdOrders,
    tgdOrderCheckout,
    tgdSummaryForm,
    addTGDBasicOrder,
    addTGDBasicOrders,
    addTGDBasicOrderCheckout,
    addTGDBasicSummaryForm,
    updateTGDBasicOrder,
    removeTGDBasicOrder,
    clearTGDBasicOrders,
  }

  return (
    <TGDBasicOrderContext.Provider value={context}>
      {children}
    </TGDBasicOrderContext.Provider>
  )
}

export const useTGDBasicOrder = () => useContext(TGDBasicOrderContext)
