import React, {
  ChangeEvent,
  Fragment,
  MouseEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreCheckbox,
  OreFormGroup,
  OreHeading,
  OreInput,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreRadioButton,
  OreText,
  OreStack,
  OreCardContainer,
  OreCardTitle,
  SvgFormation,
  OreSwitch,
  OreSelect,
  SvgPlus,
  OrePill,
  SvgMinus,
  SvgArrowDown,
  OreIcon,
  SvgNavBarNotificactions,
} from '@runroom/oreneta'
import { currency } from 'src/domain/enum'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { forms } from 'src/domain/translations'
import {
  FormationForm,
  ConfigureModalActions,
  useConfigureFormationController,
  FormationSchool,
} from 'src/Flex/Products/ui/product-configuration'
import { ResetButton } from 'src/Flex/Shared/ui/ResetButton'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { decimalValueInput } from 'src/core/helpers'
import { Divider } from 'src/presentation/layout'
import Discount from 'src/presentation/assets/img/discount.svg'

export const ConfigureFormationModalForm = forwardRef<ConfigureModalActions>((_, ref) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const { isGlobal, load, form, save, model } = useConfigureFormationController()
  const { errors } = handleErrors(form.formState)
  const { onChange, ...rest } = form.register('monthlyLimit')
  const manualSelection = form.watch('isEnteredManually')
  const [clickedButton, setClickedButton] = useState<string | null>(null)
  const [formationType, setFormationType] = useState<number>(0)

  const formationTypeRef = useRef<HTMLSelectElement>(null)

  const setFormationTypeToDefault = (): void => {
    if (formationTypeRef.current) {
      formationTypeRef.current.value = '0'
      formationTypeRef.current.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  const close = (): void => {
    setFormationTypeToDefault()
    setOpen(false)
  }

  const saveAndClose = (event: MouseEvent): void => {
    event.preventDefault()

    form.handleSubmit(async (data: FormationForm) => {
      const result = await save(data)

      if (result) {
        setFormationTypeToDefault()
        setOpen(false)
      }
    })()
  }

  useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
    open: (): void => {
      load().then(() => {
        setClickedButton(null)
        setOpen(true)
      })
    },
    close,
  }))

  const handleMonthlyLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()

    if (Number(event.target.value) % 1 !== 0) {
      form.setValue('monthlyLimit', Number(decimalValueInput(event.target.value)))
    }

    onChange(event)
  }

  const canUserEnterSmallerValueErrors = errors('canUserEnterSmallerValue')

  useEffect(() => {
    if (isGlobal && !manualSelection) {
      form.trigger(['canUserEnterSmallerValue', 'numberOfMonths'])
    } else if (!manualSelection) {
      form.trigger(['numberOfMonths'])
    }
  }, [manualSelection])

  const modalRef = React.useRef<HTMLDivElement>(null)

  const resetForm = () => {
    form.setValue('monthlyLimit', model?.clientMonthlyLimit)
    form.setValue('numberOfMonths', model?.clientNumberOfMonths)
    if (model?.trainingSchools) {
      const activeSchools = model.trainingSchools.map(school => ({
        ...school,
        active: true,
      }))
      form.setValue('trainingSchools', activeSchools)
    }
    if (modalRef.current) {
      modalRef.current.scrollTop = 0
    }
  }

  return (
    <OreModal open={isOpen}>
      <OreModalBox size="extralarge" handleOnClose={() => close()} ref={modalRef}>
        <form>
          <OreModalBoxBody noGap>
            <OreCardContainer hasShadow={false}>
              <OreCardTitle
                icon={
                  <OreIcon size="large" icon={<SvgFormation />} tone="flex-products" />
                }
                title={t(flexProductsTranslations.configure.formation.title)}
              />
              <Divider marginTop="1rem" marginBottom="2rem" />
              {model?.numberOfEmployeesContract !== undefined &&
                model?.numberOfEmployeesContract > 0 && (
                  <div className="info-message mb-2">
                    <OreStack direction="row" placeItems="center">
                      <SvgNavBarNotificactions />
                      <OreText size="text-sm">
                        {t(flexProductsTranslations.configure.formation.employees_1)}
                        <span className="bold">{model.numberOfEmployeesContract}</span>
                        <span className="bold">
                          {model?.numberOfEmployeesContract === 1
                            ? t(flexProductsTranslations.configure.formation.employee_2)
                            : t(flexProductsTranslations.configure.formation.employees_2)}
                        </span>
                        {t(flexProductsTranslations.configure.formation.employees_3)}
                      </OreText>
                    </OreStack>
                  </div>
                )}
              <OreStack placeContent="stretch" space="large">
                <OreStack placeContent="stretch" space="medium">
                  <OreText tone="neutral-600">
                    {model?.trainingSchools && model?.trainingSchools.length > 0
                      ? t(flexProductsTranslations.configure.formation.description_school)
                      : t(
                          flexProductsTranslations.configure.formation
                            .description_noSchool
                        )}
                  </OreText>
                  <OreText tone="neutral-600">
                    {t(flexProductsTranslations.configure.formation.description2)}
                  </OreText>
                  <OreText tone="neutral-600">
                    {t(
                      flexProductsTranslations.configure.formation.form.priceLimit.legend
                    )}
                  </OreText>
                  <OreInput
                    {...rest}
                    {...errors('monthlyLimit')}
                    id="monthlyLimit"
                    label={t(
                      flexProductsTranslations.configure.formation.form.priceLimit.label
                    )}
                    type="number"
                    startAdornment={currency.euro}
                    required
                    onChange={handleMonthlyLimitChange}
                  />
                  {isGlobal ? (
                    <OreCheckbox
                      {...form.register('isEnteredManually')}
                      {...errors('isEnteredManually')}
                      label={t(
                        flexProductsTranslations.configure.formation.form.manual.label
                      )}
                    />
                  ) : null}
                  {isGlobal && manualSelection && (
                    <div className="info-message">
                      <OreStack direction="row" placeItems="center">
                        <SvgNavBarNotificactions />
                        <OreText size="text-sm">
                          {t(
                            flexProductsTranslations.configure.formation.form.months
                              .warning
                          )}
                        </OreText>
                      </OreStack>
                    </div>
                  )}
                  {isGlobal || !manualSelection ? (
                    <OreInput
                      {...form.register('numberOfMonths')}
                      {...errors('numberOfMonths')}
                      id="numberOfMonths"
                      label={t(
                        flexProductsTranslations.configure.formation.form.months.label
                      )}
                      legend={t(
                        flexProductsTranslations.configure.formation.form.months.legend
                      )}
                      type="number"
                      disabled={isGlobal && manualSelection}
                      required
                    />
                  ) : null}
                  {isGlobal && !manualSelection ? (
                    <OreFormGroup
                      {...canUserEnterSmallerValueErrors}
                      label={t(
                        flexProductsTranslations.configure.formation.form.canBeLower.label
                      )}
                      required
                      direction="row">
                      <OreRadioButton
                        label={t(
                          flexProductsTranslations.configure.formation.form.canBeLower.yes
                        )}
                        value={'true'}
                        {...form.register('canUserEnterSmallerValue')}
                        hasError={canUserEnterSmallerValueErrors.hasError}
                      />
                      <OreRadioButton
                        label={t(
                          flexProductsTranslations.configure.formation.form.canBeLower.no
                        )}
                        value={'false'}
                        {...form.register('canUserEnterSmallerValue')}
                        hasError={canUserEnterSmallerValueErrors.hasError}
                      />
                    </OreFormGroup>
                  ) : null}
                </OreStack>
                {/* {form.formState.isDirty &&
                model?.monthlyLimit &&
                model.monthlyLimit > 0 ? (
                  <OreMessageBar color="warning">
                    {t(flexProductsTranslations.configure.common.collectivesChanges)}
                  </OreMessageBar>
                ) : null} */}
                {model?.trainingSchools && model?.trainingSchools.length > 0 && (
                  <>
                    <Divider marginTop=".25rem" marginBottom=".25rem" />
                    <OreStack direction="column" space="large">
                      <OreText>
                        {t(
                          flexProductsTranslations.configure.formation.form.school
                            .generalDescription
                        )}
                      </OreText>
                      <OreSelect
                        id="formationType"
                        ref={formationTypeRef}
                        label={t(
                          flexProductsTranslations.configure.formation.form.school.type
                            .label
                        )}
                        legend={t(
                          flexProductsTranslations.configure.formation.form.school.type
                            .legend
                        )}
                        onChange={e => setFormationType(+e.target.value)}>
                        <option value={0} defaultChecked>
                          {t(
                            flexProductsTranslations.configure.formation.form.school.type
                              .firstValue
                          )}
                        </option>
                        {model?.trainingTypes?.map(type => (
                          <option key={type.trainingTypeId} value={type.trainingTypeId}>
                            {type.trainingTypeName}
                          </option>
                        ))}
                      </OreSelect>
                      <OreStack direction="column" space="2xlarge" placeContent="normal">
                        {model?.trainingSchools
                          .map((school: FormationSchool) => {
                            if (formationType === 0) {
                              return school
                            }

                            const filteredCourses = school.courses.filter(
                              course => course.trainingType === formationType
                            )

                            if (filteredCourses.length > 0) {
                              return {
                                ...school,
                                courses: filteredCourses,
                              }
                            }

                            return null
                          })
                          .filter(Boolean)
                          .map((school: FormationSchool | null, index) => {
                            if (school === null) {
                              return null
                            }

                            const originalIndex = model.trainingSchools.findIndex(
                              s => s.schoolId === school.schoolId
                            )
                            return (
                              <div key={originalIndex}>
                                <div className="pb-2">
                                  <OreStack direction="row" placeContent="space-between">
                                    <OreStack direction="row" placeItems="center">
                                      <img
                                        src={`data:image/jpeg;base64,${school.schoolLogo}`}
                                        alt=""
                                        width={120}
                                      />
                                      <OreText size="text-xs" tone="neutral-500">
                                        {school.courses.length +
                                          ' ' +
                                          (school.courses.length > 1
                                            ? t(
                                                flexProductsTranslations.configure
                                                  .formation.form.school.courses
                                              )
                                            : t(
                                                t(
                                                  flexProductsTranslations.configure
                                                    .formation.form.school.course
                                                )
                                              ))}
                                      </OreText>
                                    </OreStack>
                                    <div>
                                      <OreSwitch
                                        {...form.register(
                                          `trainingSchools.${originalIndex}.active`
                                        )}
                                        name={`trainingSchools.${originalIndex}.active`}
                                        label={t(
                                          flexProductsTranslations.configure.formation
                                            .form.school.show
                                        )}
                                        secondLabel={t(
                                          flexProductsTranslations.configure.formation
                                            .form.school.hide
                                        )}
                                      />
                                    </div>
                                  </OreStack>
                                </div>
                                {school.courses.map((course, index) => (
                                  <Fragment key={index}>
                                    <OreStack
                                      direction="row"
                                      placeContent="space-between"
                                      space="xsmall">
                                      <div
                                        style={{
                                          paddingRight: '2px',
                                          paddingBottom: '20px',
                                        }}>
                                        <OreText>{course.name}</OreText>
                                      </div>
                                      <div style={{ width: '250px' }}>
                                        <OreStack
                                          direction="row"
                                          placeContent="space-between">
                                          {course.discountPercentage ? (
                                            <div style={{ width: '75px' }}>
                                              <OrePill
                                                tone="success-600"
                                                icon={<img src={Discount} alt="" />}>
                                                <OreText nowrap>
                                                  {course.discountPercentage}%
                                                </OreText>
                                              </OrePill>
                                            </div>
                                          ) : (
                                            <div style={{ width: '75px' }}></div>
                                          )}
                                          <div style={{ width: '75px' }}>
                                            <OreStack direction="column" space="xsmall">
                                              {course.discountPercentage ? (
                                                <>
                                                  <OreText align="right" bold>
                                                    {course.finalPrice}€
                                                  </OreText>
                                                  <div
                                                    style={{
                                                      color: '#94a3b8',
                                                      textDecoration: 'line-through',
                                                    }}>
                                                    <OreText
                                                      size="text-xs"
                                                      align="right"
                                                      tone="neutral-400">
                                                      {course.price}€
                                                    </OreText>
                                                  </div>
                                                </>
                                              ) : (
                                                <OreText align="right" bold>
                                                  {course.finalPrice}€
                                                </OreText>
                                              )}
                                            </OreStack>
                                          </div>
                                          <OreButton
                                            category="tertiary"
                                            as="a"
                                            icon={
                                              clickedButton === course.trainingId ? (
                                                <SvgMinus />
                                              ) : (
                                                <SvgPlus />
                                              )
                                            }
                                            onClick={() =>
                                              setClickedButton(
                                                clickedButton === course.trainingId
                                                  ? null
                                                  : course.trainingId
                                              )
                                            }
                                          />
                                        </OreStack>
                                      </div>
                                    </OreStack>
                                    {course.trainingId === clickedButton && (
                                      <div>
                                        <OreText size="text-lg" bold tone="neutral-900">
                                          {t(
                                            flexProductsTranslations.configure.formation
                                              .form.course.description
                                          )}
                                        </OreText>
                                        <OreText>{course.description}</OreText>
                                        <div className="pt-1">
                                          <OreButton
                                            icon={<SvgArrowDown />}
                                            size="small"
                                            onClick={e => {
                                              e.preventDefault()
                                              window.open(course.pdfLink, '_blank')
                                            }}>
                                            {t(
                                              flexProductsTranslations.configure.formation
                                                .form.course.downloadInfo
                                            )}
                                          </OreButton>
                                        </div>
                                      </div>
                                    )}
                                    <Divider marginTop=".5rem" />
                                  </Fragment>
                                ))}
                              </div>
                            )
                          })}
                      </OreStack>
                    </OreStack>
                  </>
                )}
                {!isGlobal ? (
                  <ResetButton
                    reset={resetForm}
                    text={t(
                      flexCollectivesTranslations.configure.edit.products.common
                        .resetValuesTitle
                    )}
                    button={t(
                      flexCollectivesTranslations.configure.edit.products.common
                        .resetValuesButton
                    )}
                  />
                ) : null}
                {isGlobal && (
                  <OreStack>
                    <OreHeading size="headline-md">
                      {t(
                        flexProductsTranslations.configure.ticketRestaurant.form.activate
                          .title
                      )}
                    </OreHeading>
                    <OreText>
                      {t(
                        flexProductsTranslations.configure.ticketRestaurant.form.activate
                          .subtitle
                      )}
                    </OreText>
                    <OreSwitch
                      {...form.register('active')}
                      label={t(
                        flexProductsTranslations.configure.ticketRestaurant.form.activate
                          .activate
                      )}
                      secondLabel={t(
                        flexProductsTranslations.configure.ticketRestaurant.form.activate
                          .deactivate
                      )}
                    />
                  </OreStack>
                )}
                <OreText>{t(forms.errors.fieldsRequired)}</OreText>
              </OreStack>
            </OreCardContainer>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={close} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              type="submit"
              onClick={saveAndClose}
              size="small"
              category="primary">
              {t(flexProductsTranslations.configure.common.submit)}
            </OreButton>
          </OreModalBoxFooter>
        </form>
      </OreModalBox>
    </OreModal>
  )
})
