import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { useModal } from 'src/presentation/hooks'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useModalController } from 'src/presentation/components/Edenred'
import { forms } from 'src/domain/translations'
import { NotificationSeverity, edenredProducts, excelRequestType } from 'src/domain/enum'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TGDOrderService } from 'src/core/services/tgdOrderService'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { TGDOrderSummaryForm } from 'src/domain/models/TGDOrder/TGDOrderModel'

export interface TGDOrderCheckoutState {
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  onCompleteOrder: (isExcel: boolean) => Promise<void>
  showSaveCloseModal: boolean
  setShowSaveCloseModal: Dispatch<SetStateAction<boolean>>
  tgdOrderCheckout: CardOrderResumeDTO
  tgdSummaryForm: TGDOrderSummaryForm
}
export const useTGDOrderCheckout = (): TGDOrderCheckoutState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const {
    tgdOrders,
    tgdSummaryForm,
    tgdOrderCheckout,
    clearTGDOrders,
    setTGDEmployeesToConfig,
  } = useTGDOrder()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()

  const navigate = useNavigate()
  const { addNotification } = useNotification()

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          clearTGDOrders()
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTgdTab)
        },
        size: 'large',
      },
    ]
  }

  async function confirmOrder() {
    if (tgdOrders && tgdSummaryForm) {
      startLoading()
      const { data, meta } = await TGDOrderService()
        .complete(tgdSummaryForm, tgdOrders)
        .finally(() => stopLoading())
      stopLoading()
      setTGDEmployeesToConfig([])
      meta.status === 'ERROR'
        ? navigate(navigationRoutes.tgdOrderCompleteError, {
            state: {
              success: false,
              errorMessage: meta.messages,
              isExcel: false,
              excel: data?.additionalData || '',
            },
          })
        : navigate(navigationRoutes.tgdOrderCompleteSuccess, {
            state: { success: true, data: { ...data }, isExcel: false, excel: '' },
          })
    }
  }

  async function confirmExcelOrder() {
    startLoading()
    const { data, meta } = await TGDOrderService()
      .completeExcelOrder({
        excel: tgdOrderCheckout.data?.additionalData
          ? tgdOrderCheckout.data?.additionalData
          : '',
        invoiceComment: tgdOrderCheckout.data?.detail.billReference
          ? tgdOrderCheckout.data?.detail.billReference
          : '',
        orderExcelType: excelRequestType.TgdOrder,
        productCode: edenredProducts.ticketGuarderia,
        stopOnWarnings: true,
        orderDate: tgdOrderCheckout.data?.detail.initialChargeDate
          ? tgdOrderCheckout.data?.detail.initialChargeDate
          : '',
      })
      .finally(() => stopLoading())
    stopLoading()
    setTGDEmployeesToConfig([])
    meta.status === 'ERROR'
      ? navigate(navigationRoutes.tgdOrderCompleteError, {
          state: {
            success: false,
            errorMessage: meta.messages,
            isExcel: true,
            excel: data.additionalData,
          },
        })
      : navigate(navigationRoutes.tgdOrderCompleteSuccess, {
          state: {
            success: true,
            data: { ...data },
            isExcel: true,
            excel: data.additionalData,
          },
        })
  }

  const onCompleteOrder = async (isExcel: boolean) => {
    if (isExcel) confirmExcelOrder()
    else await confirmOrder()
  }

  useEffect(() => {
    setCancelModalTitle(t(TGDOrderTranslation.summaryAssignment.cancelModal.title))
    setCancelModalDescription(
      t(TGDOrderTranslation.summaryAssignment.cancelModal.description)
    )

    if (!tgdOrderCheckout || (tgdOrderCheckout && !tgdOrderCheckout.data))
      navigate(navigationRoutes.tgdOrderSummary)
  }, [])

  return {
    setShowCancelModal,
    showCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    onCompleteOrder,
    showSaveCloseModal,
    setShowSaveCloseModal,
    tgdOrderCheckout,
    tgdSummaryForm,
  }
}
