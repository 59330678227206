import { yupResolver } from '@hookform/resolvers/yup'
import type { Dispatch } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useModalController } from '../../../Edenred/hooks/useModalController'
import { accountService, cardOrderService } from '../../../../../core/services'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import {
  MetaStatusCodes,
  cardRequestTypeId,
  cardType,
  deliveryType,
  edenredProducts,
  orderType,
} from '../../../../../domain/enum'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { BasicParameters } from '../../../../../domain/forms'
import type { AccountModel, CardOrderModel } from '../../../../../domain/models'
import { cardOrderFormTranslation, forms } from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useAsync, useNotificationHandler } from '../../../../hooks'
import { useCardOrderFormSchema } from '../../../../validations'

export interface FormState {
  ValidateCardOrderModel: (cardOrder: CardOrderModel) => void
  formParameters: BasicParameters<CardOrderModel> | undefined
  form: UseFormReturn<CardOrderModel, any>
  errorMessage: string
  t: TFunction<'translation', undefined>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  setShowPopup: Dispatch<React.SetStateAction<boolean>>
  submitPopupShow: boolean
  submitPopupTitle: string
  submitPopupButtons: PopupButtonModel[]
  submitSetShowPopup: Dispatch<React.SetStateAction<boolean>>
}

export const useFormController = (): FormState => {
  const { cardOrderFormSchema } = useCardOrderFormSchema()
  const form = useForm<CardOrderModel>({
    resolver: yupResolver(cardOrderFormSchema),
    defaultValues: {
      cardData: {
        productTypeId: edenredProducts.ticketRestaurant,
      },
    },
    mode: 'onChange',
    shouldFocusError: true,
  })
  const { startLoading, stopLoading } = useLoader()
  const { state: companyField } = useAsync<AccountModel>(() => accountService().get())
  const { addOrder, editOrder, formParameters } = useCardOrder()
  const { notificationHandler } = useNotificationHandler()
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const { t } = useTranslation()
  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const {
    show: submitPopupShow,
    title: submitPopupTitle,
    buttons: submitPopupButtons,
    setShow: submitSetShowPopup,
    setTitle: submitSetTitlePopup,
    setButtons: submitSetButtonsPopup,
  } = useModalController()

  const navigate = useNavigate()

  const HandleDiscardPopup = () => {
    setShowPopup(false)
    navigate(navigationRoutes.cardOrderHome)
  }

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => HandleDiscardPopup(),
        size: 'large',
      },
    ]
  }

  const SetSubmitPopupButtons = (cardOrder: CardOrderModel): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => submitSetShowPopup(false),
        size: 'large',
      },
      {
        title: t(cardOrderFormTranslation.shippingButton),
        category: 'primary',
        onClick: () => HandleValidateCardOrderModel(cardOrder),
        size: 'large',
      },
    ]
  }

  const HandleValidateCardOrderModel = (cardOrder: CardOrderModel) => {
    submitSetShowPopup(false)
    if (!cardOrder) return
    startLoading()
    cardOrder.productCode = edenredProducts.ticketRestaurant

    cardOrder.cardData.cardRequestTypeId =
      Number(cardOrder.cardData.cardTypeId) === cardType.Plastic
        ? cardRequestTypeId.newPlastic
        : cardRequestTypeId.newPlasticless
    cardOrder.orderTypeId = orderType.Card

    if (cardOrder.cardData.initialAmount?.toString() == '')
      cardOrder.cardData.initialAmount = null

    if (companyField) cardOrder.employeeData.companyId = companyField.code

    cardOrder.cardData.cardTypeId = +cardOrder.cardData.cardTypeId
    cardOrder.orderId = formParameters?.entity ? formParameters.entity.orderId : 0

    cardOrderService()
      .validate(cardOrder)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          stopLoading()
          if (formParameters?.editMode) editOrder(cardOrder)
          else {
            cardOrder.orderId = new Date().getTime()
            addOrder(cardOrder)
          }
          notificationHandler(response?.meta, true, null, navigationRoutes.cardOrderHome)
        } else {
          stopLoading()
          if (response?.meta?.messages.length > 0) {
            response?.meta?.messages.forEach(error => {
              if (error.value) {
                const errorOption = error.value.includes('.')
                  ? error.value.split('.').slice(1).toString().replaceAll(',', '.')
                  : error.value
                form.setError(errorOption as any, {
                  type: 'value',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
        }
      })
  }

  const ValidateCardOrderModel = async (cardOrder: CardOrderModel) => {
    if (
      +cardOrder.cardData.cardTypeId === cardType.Plastic &&
      cardOrder.sendData?.deliveryTypeId === deliveryType.mail
    ) {
      submitSetButtonsPopup(SetSubmitPopupButtons(cardOrder))
      submitSetShowPopup(true)
    } else {
      HandleValidateCardOrderModel(cardOrder)
    }
  }

  //Mode add or edit
  useEffect(() => {
    if (formParameters?.editMode && formParameters.entity)
      form.reset(formParameters.entity)
    setTitlePopup(t(cardOrderFormTranslation.popup.title))
    setDescriptionPopup(t(cardOrderFormTranslation.popup.description))
    setButtonsPopup(SetPopupButtons())
    submitSetTitlePopup(t(cardOrderFormTranslation.popup.titleSubmit))
  }, [formParameters])

  return {
    ValidateCardOrderModel,
    formParameters,
    form,
    errorMessage,
    t,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    setShowPopup,
    submitPopupShow,
    submitPopupTitle,
    submitPopupButtons,
    submitSetShowPopup,
  }
}
