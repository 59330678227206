import { KeyboardEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OreButton, OreHeading, OreInput, OreStack, OreText } from '@runroom/oreneta'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useTemplatesEdit } from 'src/Flex/CommunicationsTemplates/ui/templates-detail'
import { forms } from 'src/domain/translations'

interface TemplateTestingProps {
  id: string
}

export const TemplateTesting = ({ id }: TemplateTestingProps): JSX.Element => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const { test } = useTemplatesEdit()

  const handleEnterKey = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()

      if (email) {
        handleClick()
      }
    }
  }

  const handleClick = (): void => {
    if (validateEmail(email)) {
      setEmailError(false)
      test(id, email)
    } else {
      setEmailError(true)
    }
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  return (
    <OreStack space="medium" placeContent="stretch">
      <OreHeading size="headline-md">
        {t(flexCommunicationsTemplatesTranslations.configure.form.send.title)}
      </OreHeading>
      <OreText>
        {t(flexCommunicationsTemplatesTranslations.configure.form.send.subtitle)}
      </OreText>
      <OreText>
        {t(flexCommunicationsTemplatesTranslations.configure.form.send.subtitle_2)}
      </OreText>
      <OreStack direction="row" placeItems="start">
        <OreInput
          id="email"
          type="email"
          style={{ minWidth: '300px' }}
          label={t(flexCommunicationsTemplatesTranslations.configure.form.send.label)}
          placeholder={t(
            flexCommunicationsTemplatesTranslations.configure.form.send.placeholder
          )}
          onChange={event => setEmail(event.target.value)}
          onKeyDown={handleEnterKey}
          hasError={emailError}
          errorMessage={t(forms.errors.invalidFormat)}
        />
        <OreButton
          style={{ marginTop: '30px' }}
          category="primary"
          onClick={() => handleClick()}
          disabled={!email}>
          {t(flexCommunicationsTemplatesTranslations.configure.form.send.button)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}
