export const deliverySiteTranslation = {
  titleTR: 'deliverySiteTranslation.titleTR',
  titleTT: 'deliverySiteTranslation.titleTT',

  table: {
    head: {
      code: 'deliverySiteTranslation.table.head.code',
      name: 'deliverySiteTranslation.table.head.name',
      address: 'deliverySiteTranslation.table.head.address',
      contact: 'deliverySiteTranslation.table.head.contact',
      shippingType: 'deliverySiteTranslation.table.head.shippingType',
      addressType: 'deliverySiteTranslation.table.head.addressType',
      actions: 'deliverySiteTranslation.table.head.actions',
      deleteTooltip: 'deliverySiteTranslation.table.head.deleteTooltip',
      editTooltip: 'deliverySiteTranslation.table.head.editTooltip',
      favCheckedTooltip: 'deliverySiteTranslation.table.head.favCheckedTooltip',
      favUncheckedTooltip: 'deliverySiteTranslation.table.head.favUncheckedTooltip',
      messagingTooltip: 'deliverySiteTranslation.table.head.messagingTooltip',
      postOfficeTooltip: 'deliverySiteTranslation.table.head.postOfficeTooltip',
    },
    body: {
      shippingType: {
        messaging: 'deliverySiteTranslation.table.body.shippingType.messaging',
        postOffice: 'deliverySiteTranslation.table.body.shippingType.postOffice',
      },
      addressType: {
        business: 'deliverySiteTranslation.table.body.addressType.business',
        personal: 'deliverySiteTranslation.table.body.addressType.personal',
        others: 'deliverySiteTranslation.table.body.addressType.others',
      },
      actions: {
        delete: {
          mainCantBeDeleted:
            'deliverySiteTranslation.table.body.actions.delete.mainCantBeDeleted',
          activeUserLinked:
            'deliverySiteTranslation.table.body.actions.delete.activeUserLinked',
        },
      },
    },
    caption: {
      deliveryPoints: 'deliverySiteTranslation.table.caption.deliveryPoints',
    },
    filter: {
      searchPlaceholder: 'deliverySiteTranslation.table.filter.searchPlaceholder',
      resetButton: 'deliverySiteTranslation.table.filter.resetButton',
      addressType: 'deliverySiteTranslation.table.filter.addressType',
      businessAddress: 'deliverySiteTranslation.table.filter.businessAddress',
      personalAddress: 'deliverySiteTranslation.table.filter.personalAddress',
      others: 'deliverySiteTranslation.table.filter.others',
      zipCode: 'deliverySiteTranslation.table.filter.zipCode',
      zipCodePlaceholder: 'deliverySiteTranslation.table.filter.zipCodePlaceholder',
      location: 'deliverySiteTranslation.table.filter.location',
      locationPlaceholder: 'deliverySiteTranslation.table.filter.locationPlaceholder',
    },
  },
  modal: {
    title: 'deliverySiteTranslation.modal.title',
    description: 'deliverySiteTranslation.modal.description',
  },
}
