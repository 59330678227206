import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { browserStorageKeys } from 'src/domain/enum'
import { EmployeesWithLastCard } from 'src/domain/models'

interface ControllerState {
  employeeAvatar: EmployeesWithLastCard | undefined
}

export const useEmployeeProfilePageController = (): ControllerState => {
  const [employeeAvatar, setEmployeeAvatar] = useState<EmployeesWithLastCard>()
  const navigate = useNavigate()

  const getEmployeeAvatar = (): void => {
    const employeeSessionData = sessionStorage.getItem(browserStorageKeys.employeeProfile)
    if (employeeSessionData !== null) {
      setEmployeeAvatar(JSON.parse(employeeSessionData))
    } else {
      navigate(navigationRoutes.employeesHome)
    }
  }

  useEffect(() => {
    getEmployeeAvatar()
  }, [])

  return {
    employeeAvatar,
  }
}
