import { useEffect, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import type { TextBoxRowModel } from '../../../../../domain/customComponents'
import type { BasicParameters } from '../../../../../domain/forms'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { deliverySiteFormTranslation } from '../../../../../domain/translations/'

export interface ShipmenContactState {
  t: TFunction<'translation', undefined>
  register: UseFormRegister<DeliverySiteModel>
  errors: any
  shipmentContactTextBoxes: TextBoxRowModel<DeliverySiteModel>[]
}

export const useShipmentContactController = (
  formParameters: BasicParameters<DeliverySiteModel>,
  requiredPhone: boolean
): ShipmenContactState => {
  const [shipmentContactTextBoxes, setShipmentContactTextBoxes] = useState<
    TextBoxRowModel<DeliverySiteModel>[]
  >([])
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<DeliverySiteModel>()

  const GetShipmentContactTextsBox = () => {
    setShipmentContactTextBoxes([
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'contactName',
            label: t(deliverySiteFormTranslation.shipmentContact.name),
            name: 'contactName',
            placeHolder: t(deliverySiteFormTranslation.shipmentContact.namePlaceHolder),
            required: true,
            maxLength: 50,
            type: 'text',
            googleApi: false,
          },
        ],
      },

      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'email',
            label: t(deliverySiteFormTranslation.shipmentContact.email),
            name: 'email',
            placeHolder: t(deliverySiteFormTranslation.shipmentContact.emailPlaceHolder),
            required: true,
            maxLength: 50,
            type: 'email',
            googleApi: false,
          },

          {
            id: 'telephone',
            label: requiredPhone
              ? t(deliverySiteFormTranslation.shipmentContact.requiredPrhone)
              : t(deliverySiteFormTranslation.shipmentContact.phone),
            name: 'telephone',
            placeHolder: t(deliverySiteFormTranslation.shipmentContact.phonePlaceHolder),
            legend: t(deliverySiteFormTranslation.shipmentContact.phoneFooter),
            required: requiredPhone,
            maxLength: 9,
            type: 'tel',
            googleApi: false,
          },
        ],
      },
    ])
  }

  const SetEditModeFormValues = () => {
    reset(prevForm => {
      if (formParameters.entity)
        return {
          ...prevForm,
          contactName: formParameters.entity.contactName,
          email: formParameters.entity.email,
          telephone: formParameters.entity.telephone,
          observation:
            formParameters.entity.observation === null
              ? ''
              : formParameters.entity.observation,
          isFavorite: formParameters.entity.isFavorite,
        }
      return prevForm
    })
  }

  useEffect(() => {
    GetShipmentContactTextsBox()
    if (formParameters) SetEditModeFormValues()
  }, [formParameters])

  useEffect(() => {
    GetShipmentContactTextsBox()
  }, [requiredPhone])

  return { t, register, errors, shipmentContactTextBoxes }
}
