import { HttpModel, PaginationModel } from 'src/domain/models'
import { PasswordModel } from 'src/domain/models/myAccount/PasswordModel'
import {
  changePassword,
  createUserAndLicenses,
  deleteAccount,
  deleteUserAndLicenses,
  downloadCancellationTerms,
  downloadLegalDocument,
  editUserAndLicenses,
  getReceiveInformation,
  setReceiveInformation,
  singleClientData,
  updateClientData,
  updateClientEmail,
  usersAndLicenses,
} from '../../../config/constants/endpoints'
import type { AccountRepositoryContract } from '../../../core/contracts/myAccount/AccountRepositoryContract'
import type {
  AccountDTO,
  AccountModel,
} from '../../../domain/models/myAccount/AccountModel'
import type { DeleteAccountModel } from '../../../domain/models/myAccount/DeleteAccountModel'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../axios'
import { IFile } from 'src/domain/interfaces/global/IFile'
import {
  UsersAndLicensesAddDto,
  UsersAndLicensesFormModel,
  UsersAndLicensesRequest,
  UsersAndLicensesResponse,
} from 'src/domain/models/myAccount/UsersAndLicenses'
import { createQueryString } from 'src/core/helpers'

export interface UpdateAccount {
  clientData: AccountModel
}

export default class AccountRepository implements AccountRepositoryContract {
  async get(): Promise<AccountDTO> {
    const { url, method }: HttpRequest = {
      url: singleClientData,
      method: HttpMethod.GET,
    }
    const res = await axiosFetch(url, method)
    return res
  }

  async update(account: UpdateAccount): Promise<AccountDTO> {
    const { url, method, body }: HttpRequest = {
      url: updateClientData,
      method: HttpMethod.POST,
      body: account,
    }
    const res = await axiosFetch(url, method, body)
    return res
  }

  async editEmail(email: string): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: updateClientEmail,
      method: HttpMethod.POST,
      body: { email: email },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async changePassword(passwordModel: PasswordModel): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: changePassword,
      method: HttpMethod.POST,
      body: {
        newPassword: passwordModel.newPassword,
        newPasswordRepeat: passwordModel.newPasswordRepeat,
        oldPassword: passwordModel.oldPassword,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async deleteAccount(account: DeleteAccountModel): Promise<HttpModel<any>> {
    const { url, method, body }: HttpRequest = {
      url: deleteAccount,
      method: HttpMethod.POST,
      body: {
        reason: account.reason,
        description: account.description,
        acceptConditions: account.acceptConditions,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async updateNotification(receiveInfo: boolean): Promise<HttpModel<any>> {
    const { url, method, body }: HttpRequest = {
      url: setReceiveInformation,
      method: HttpMethod.POST,
      body: { optin: receiveInfo },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getReceiveInformation(): Promise<HttpModel<boolean>> {
    const { url, method, body }: HttpRequest = {
      url: getReceiveInformation,
      method: HttpMethod.GET,
      body: {},
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadCancellationTerms(): Promise<HttpModel<IFile>> {
    const { url, method, body }: HttpRequest = {
      url: downloadCancellationTerms,
      method: HttpMethod.GET,
      body: {},
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadLegalDocument(legalDocumentType: number): Promise<HttpModel<IFile>> {
    const { url, method, body }: HttpRequest = {
      url: downloadLegalDocument + '?documentId=' + legalDocumentType,
      method: HttpMethod.GET,
      body: {},
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async GetUsersAndLicenses(
    filters: UsersAndLicensesRequest
  ): Promise<HttpModel<UsersAndLicensesResponse>> {
    const { url, method, body }: HttpRequest = {
      url: usersAndLicenses,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters.paginationModel))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async addUserAndLicenses(
    model: UsersAndLicensesFormModel
  ): Promise<UsersAndLicensesAddDto> {
    const { url, method, body }: HttpRequest = {
      url: createUserAndLicenses,
      method: HttpMethod.POST,
      body: model,
    }
    const res = await axiosFetch(url, method, body)
    return res
  }

  async editUserAndLicenses(
    model: UsersAndLicensesFormModel
  ): Promise<UsersAndLicensesAddDto> {
    const { url, method, body }: HttpRequest = {
      url: editUserAndLicenses,
      method: HttpMethod.POST,
      body: {
        userName: model.userName,
        roleName: model.rolName,
      },
    }
    const res = await axiosFetch(url, method, body)
    return res
  }

  async deleteUserAndLicenses(userName: string): Promise<HttpModel<any>> {
    const { url, method, body }: HttpRequest = {
      url: deleteUserAndLicenses,
      method: HttpMethod.POST,
      body: { userNameToDelete: userName },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
