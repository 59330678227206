import { useAssingments } from '../hooks/useAssingments'
import { AssignmentsFooterButtons, AssignmentsTable } from '../molecules'

export const AssignmentsContainer = (): JSX.Element => {
  const props = useAssingments()
  return (
    <div className="py-2">
      <AssignmentsTable AssignmentsProps={props} />
      <AssignmentsFooterButtons useAssignment={props} />
    </div>
  )
}
