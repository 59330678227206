import { useCallback, useContext, useMemo, useReducer } from 'react'
import { UIContext, uiInitialState } from './UIContext'
import { uiReducer } from './UIReducer'
import { uiActionType } from '../../../domain/enum'

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(uiReducer, uiInitialState)

  const openSidebar = useCallback(
    () => dispatch({ type: uiActionType.openSidebar }),
    [dispatch]
  )

  const closeSidebar = useCallback(
    () => dispatch({ type: uiActionType.closeSidebar }),
    [dispatch]
  )

  const toggleSidebar = useCallback(
    () => dispatch({ type: uiActionType.toggleSidebar }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
    }),
    [state]
  )

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export const useUI = () => useContext(UIContext)
