import { useTranslation } from 'react-i18next'
import { SimpleForm } from '../../../Templates'
import React from 'react'
import { duplicatedTranslation } from '../../../../domain/translations/cardOrder/duplicated'
import { DuplicatedEmployees } from './organism'
import { LeaveOrderPrompt } from '../../Edenred/navigation'

export const DuplicatedEmployeesPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <SimpleForm
        title={t(duplicatedTranslation.title)}
        organisms={[<DuplicatedEmployees />]}
      />
      <LeaveOrderPrompt />
    </>
  )
}
