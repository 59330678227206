import React from 'react'
import { SimpleForm } from '../../../Templates'
import Container from './organism/Container'
import { useTranslation } from 'react-i18next'
import { unloadTranslation } from '../../../../domain/translations'

const UnloadHomePage = (): JSX.Element => {
  const { t } = useTranslation()
  return <SimpleForm title={t(unloadTranslation.title)} organisms={[<Container />]} />
}

export default UnloadHomePage
