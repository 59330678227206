import React from 'react'
import dinosaur from '../../assets/img/dinosaur.svg'
import { PageWrapper } from 'src/presentation/layout'
import { OreButton, OreHeading, OreStack, OreText } from '@runroom/oreneta'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useTranslation } from 'react-i18next'
import { notFound } from 'src/domain/translations'

export const NotFound = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <PageWrapper>
      <OreStack placeContent="center" placeItems="center" space="2xlarge">
        <img src={dinosaur} alt="404 image" />
        <OreStack placeContent="center" placeItems="center" space="small">
          <OreHeading size="title-xl">404</OreHeading>
          <OreHeading size="title-sm" align="center">
            {t(notFound.title)}
          </OreHeading>
          <OreText tone="neutral-600" align="center">
            {t(notFound.subtitle)}
          </OreText>
          <div className="pt-1">
            <OreButton
              onClick={() => navigate(navigationRoutes.clientsHome)}
              category="primary">
              {t(notFound.buttonLabel)}
            </OreButton>
          </div>
        </OreStack>
      </OreStack>
    </PageWrapper>
  )
}
