/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { LocaleTypes } from '../../i18n/i18n'
import { LOCALE_TYPES } from '../../i18n/i18n'
import { AppConfigContext } from './AppConfigContext'

export const AppConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()
  const [langCode, setLangCode] = useState('')
  const [langCulture, setLangCulture] = useState('')
  const [originalUrl, setOriginalUrl] = useState('')

  useEffect(() => {
    setLangCode(i18n.languages[0] === 'es-ES' ? 'es' : i18n.languages[0])
    setLangCulture(
      i18n.languages[0] === 'es-ES'
        ? 'es-ES'
        : LOCALE_TYPES[i18n.languages[0] as keyof LocaleTypes]
    )
    setOriginalUrl(
      `/${
        i18n.languages[0] === 'es-ES'
          ? 'es'
          : LOCALE_TYPES[i18n.languages[0] as keyof LocaleTypes]
      }/`
    )
  }, [i18n.languages[0]])

  const value = useMemo(
    () => ({
      langCode,
      langCulture,
      originalUrl,
      //whatever config we want to share in our app
    }),
    []
  )

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>
}
