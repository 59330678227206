import type {
  IInvoice,
  IInvoiceApiResponse,
} from '../interfaces/invoice/IInvoiceApiResponse'
import { GetDateFormatted } from '../../core/helpers'
import { creditNoteStatus, currency, emptyValues, invoiceStatus } from '../enum'
import { t } from 'i18next'
import { invoiceTranslation } from '../translations/payments/invoiceTranslation'
import type {
  ICreditNotesApiResponse,
  ICreditNotesMapped,
} from '../interfaces/invoice/ICreditNotesApiResponse'

export const GetInvoiceStatusDescription = (status: number): string => {
  switch (status) {
    case invoiceStatus.pending:
      return t(invoiceTranslation.invoices.status.pending)
    case invoiceStatus.paid:
      return t(invoiceTranslation.invoices.status.paid)
    case invoiceStatus.partiallyPaid:
      return t(invoiceTranslation.invoices.status.partiallyPaid)
    case invoiceStatus.expired:
      return t(invoiceTranslation.invoices.status.expired)
    case invoiceStatus.cancelled:
      return t(invoiceTranslation.invoices.status.canceled)
    default:
      return emptyValues.dash
  }
}

export const GetInvoiceMap = (invoiceApiList: IInvoiceApiResponse[]): IInvoice[] => {
  const copiedArray = invoiceApiList.map(invoice => ({
    ...invoice,
    creationDate: GetDateFormatted(invoice.creationDate),
    limitDate: GetDateFormatted(invoice.limitDate),
    statusDescription: GetInvoiceStatusDescription(invoice.invoiceStatus),
    totalAmountDescription: invoice.totalAmount + currency.euro,
    dueAmountDescription: invoice.dueAmount + currency.euro,
  }))
  return copiedArray
}

const GetCreditNoteStatusDescription = (status: number): string => {
  switch (status) {
    case creditNoteStatus.available:
      return t(invoiceTranslation.creditNotes.status.available)
    case creditNoteStatus.refunded:
      return t(invoiceTranslation.creditNotes.status.refunded)
    case creditNoteStatus.affected:
      return t(invoiceTranslation.creditNotes.status.affected)
    case creditNoteStatus.cancelled:
      return t(invoiceTranslation.creditNotes.status.cancelled)
    default:
      return emptyValues.dash
  }
}

export const GetCreditNotesMap = (
  creditNotesApiList: ICreditNotesApiResponse[]
): ICreditNotesMapped[] => {
  return creditNotesApiList.map((creditNote: ICreditNotesApiResponse) => ({
    ...creditNote,
    creationDate: GetDateFormatted(creditNote.creationDate),
    limitDate: GetDateFormatted(creditNote.limitDate),
    statusDescription: GetCreditNoteStatusDescription(creditNote.status),
    totalAmountDescription: creditNote.overallTotal + currency.euro,
    dueAmountDescription: creditNote.balanceDue + currency.euro,
  }))
}
