import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { PropsWithChildren } from 'react'

import {
  IUseTemplateController,
  useTemplateController,
} from 'src/Flex/CommunicationsTemplates/ui/templates-list'

type Context = IUseTemplateController

type Props = PropsWithChildren

const [useTemplates, StateContextProvider] = createGenericContext<Context>()

const CommunicationsTemplatesProvider = ({ children }: Props): JSX.Element => {
  const hook = useTemplateController()

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { CommunicationsTemplatesProvider, useTemplates }
