import type { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { GetDeliverySitesRequestBody } from '../../../../../core/controllers/cardOrder/services'
import {
  deliverySiteService,
  getDeliverySitesByGroup,
} from '../../../../../core/services'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type { BasicParameters } from '../../../../../domain/forms'
import type { CardOrderModel, DeliverySiteModel } from '../../../../../domain/models'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useAsync } from '../../../../hooks'
import { useModalController } from '../../../Edenred'

export interface ShipmentDetailsState {
  register: UseFormRegister<CardOrderModel>
  errors: any
  deliverySitesOptions: ComboBoxOptionModel[]
  t: TFunction<'translation', undefined>
  onDeliverySiteChange: (event: ChangeEvent<HTMLSelectElement>) => void
  deliverySiteSelected?: DeliverySiteModel
  removeDeliverySiteSelected?: () => void
  cardOrderModalShow: boolean
  setCardOrderModalShow: Dispatch<SetStateAction<boolean>>
  addDeliverySiteLabel: MutableRefObject<string>
  deliverySiteFormParameters: BasicParameters<DeliverySiteModel>
  onClickDeliverySiteModal: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'new' | 'edit',
    productTypeId?: number
  ) => void
  setNewDeliverySite: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  changeDeliverySite: (deliverySiteId: number) => void
}

export const useShipmentDetailsController = (isFlex = false): ShipmentDetailsState => {
  const { t } = useTranslation()
  const { show: cardOrderModalShow, setShow: setCardOrderModalShow } =
    useModalController()
  const addDeliverySiteLabel = useRef<string>(t(cardOrderFormTranslation.addDeliverySite))
  const [deliverySiteFormParameters, setDeliverySiteFormParameters] = useState<
    BasicParameters<DeliverySiteModel>
  >({})
  const [deliverySitesOptions, setDeliverySitesOptions] = useState<ComboBoxOptionModel[]>(
    []
  )

  const [deliverySiteSelected, setDeliverySiteSelected] = useState<DeliverySiteModel>()
  const [newDeliverySite, setNewDeliverySite] = useState<DeliverySiteModel>()

  const {
    register,
    formState: { errors },
    setValue,
    reset,
  } = useFormContext<CardOrderModel>()

  const { state: deliverySitesResponse, setRun } = useAsync<any>(() => {
    return deliverySiteService().getAll(GetDeliverySitesRequestBody(), isFlex)
  })

  const { formParameters } = useCardOrder()

  const GetSendDeliverySiteOptions = (deliverySites: DeliverySiteModel[]) => {
    const options: ComboBoxOptionModel[] = [
      {
        options: [
          [t(cardOrderFormTranslation.shipment.deliverySiteSelectPlaceholder), 0],
        ],
      },
    ]
    if (deliverySites) {
      const groupedOptions = getDeliverySitesByGroup(deliverySites)
      groupedOptions.map(option => {
        options.push(option)
      })
    }
    setDeliverySitesOptions(options)
  }

  const onDeliverySiteChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const deliverySite: DeliverySiteModel = deliverySitesResponse?.rows.find(
      (ds: DeliverySiteModel) => ds.deliveryPointId === +event.target.value
    )
    setDeliverySiteSelected(deliverySite)
    SetDeliverrySiteFormValues(deliverySite)
  }

  const changeDeliverySite = (deliverySiteId: number) => {
    const deliverySite: DeliverySiteModel = deliverySitesResponse?.rows.find(
      (ds: DeliverySiteModel) => ds.deliveryPointId === deliverySiteId
    )
    setDeliverySiteSelected(deliverySite)
    SetDeliverrySiteFormValues(deliverySite)
  }

  const SetDeliverrySiteFormValues = (deliverySite: DeliverySiteModel) => {
    if (!deliverySite) return
    setValue('sendData.alias', deliverySite.alias)
    setValue('sendData.name', deliverySite.deliveryPointName)
    setValue('sendData.city', deliverySite.city)
    setValue('sendData.contactName', deliverySite.contactName)
    setValue('sendData.deliveryTypeId', deliverySite.deliveryType)
    setValue('sendData.email', deliverySite.email)
    setValue('sendData.observation', deliverySite.observation)
    setValue('sendData.province', deliverySite.province)
    setValue('sendData.streetDescription', deliverySite.streetDescription)
    setValue('sendData.streetName', deliverySite.streetName)
    setValue('sendData.streetNumber', deliverySite.streetNumber)
    setValue('sendData.telephone', deliverySite.telephone)
    setValue('sendData.zipCode', deliverySite.zipCode)
    setValue('sendData.deliverySiteId', deliverySite.deliveryPointId)
  }

  const removeDeliverySiteSelected = () => {
    setValue('sendData.deliverySiteId', 0)
    setDeliverySiteSelected(undefined)
  }

  const onClickDeliverySiteModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'new' | 'edit',
    productTypeId?: number
  ) => {
    event.preventDefault()
    if (type === 'new')
      setDeliverySiteFormParameters({
        editMode: false,
        productCode: productTypeId,
      })
    else
      setDeliverySiteFormParameters({
        editMode: true,
        entity: deliverySiteSelected,
        productCode: productTypeId,
      })
    setCardOrderModalShow(true)
  }

  // set options and delivery site properties
  useEffect(() => {
    if (deliverySitesResponse?.rows.length > 0) {
      GetSendDeliverySiteOptions(deliverySitesResponse?.rows)
    }
  }, [deliverySitesResponse])

  useEffect(() => {
    if (formParameters?.editMode && formParameters.entity?.sendData?.deliverySiteId) {
      setValue('sendData.deliverySiteId', formParameters.entity?.sendData?.deliverySiteId)
      const deliverySite: DeliverySiteModel = deliverySitesResponse?.rows.find(
        (ds: DeliverySiteModel) =>
          ds.deliveryPointId.toString() ===
          formParameters.entity?.sendData?.deliverySiteId.toString()
      )
      setDeliverySiteSelected(deliverySite)
      if (deliverySite) SetDeliverrySiteFormValues(deliverySite)
    }
    if (newDeliverySite) {
      const deliverySite: DeliverySiteModel = deliverySitesResponse?.rows.find(
        (deliverySite: DeliverySiteModel) => deliverySite.alias === newDeliverySite.alias
      )
      setDeliverySiteSelected(deliverySite)
      reset(prevState => {
        if (prevState.sendData)
          return {
            ...prevState,
            sendData: {
              ...prevState.sendData,
              deliverySiteId: deliverySite.deliveryPointId,
            },
          }
        return prevState
      })
      SetDeliverrySiteFormValues(deliverySite)
    }
  }, [deliverySitesOptions])

  useEffect(() => {
    if (newDeliverySite) setRun(true)
  }, [newDeliverySite])

  return {
    register,
    errors,
    deliverySitesOptions,
    t,
    onDeliverySiteChange,
    deliverySiteSelected,
    removeDeliverySiteSelected,
    cardOrderModalShow,
    setCardOrderModalShow,
    addDeliverySiteLabel,
    deliverySiteFormParameters,
    onClickDeliverySiteModal,
    setNewDeliverySite,
    changeDeliverySite,
  }
}
