import {
  OreCheckbox,
  OreFormGroup,
  OreSelect,
  OreStack,
  OreText,
  OreTimePicker,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { SpendingRuleModel } from 'src/domain/models'
import { spendingRuleFormTranslation } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useFormContext } from 'react-hook-form'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { ChangeEvent } from 'react'
import { SpendingRulesType } from 'src/domain/enum'
import { Textbox } from 'src/presentation/components/Edenred'

interface SpendingRulesFormProps {
  spendingRuleToEdit?: SpendingRuleModel
  additionalRestrictions: string
  handleAdditionalRestrictionsChange: (e: ChangeEvent<HTMLSelectElement>) => void
  amountTextboxes: TextBoxRowModel<SpendingRuleModel>[]
  transactionTextboxes: TextBoxRowModel<SpendingRuleModel>[]
  handleChangeFav: (e: ChangeEvent<HTMLInputElement>) => void
}

export const SpendingRulesFormTR = (props: SpendingRulesFormProps): JSX.Element => {
  const { t } = useTranslation()
  const form = useFormContext<SpendingRuleModel>()

  return (
    <OreStack placeItems="stretch" placeContent="stretch" space="medium">
      <OreText>{t(spendingRuleFormTranslation.form.restrictionsLabel)}</OreText>

      <OreFormGroup
        label={t(spendingRuleFormTranslation.form.restrictionsPerDay)}
        direction="column"
        hasError={
          !!(
            form.formState.errors.timeZones &&
            form.formState.errors.timeZones[0]?.weekDays
          )
        }
        errorMessage={
          form.formState.errors.timeZones &&
          form.formState.errors.timeZones[0]?.weekDays?.message
        }
        required>
        <OreStack direction="row" space="medium">
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.monday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.monday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.monday`)}
          />
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.tuesday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.tuesday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.tuesday`)}
          />
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.wednesday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.wednesday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.wednesday`)}
          />
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.thursday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.thursday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.thursday`)}
          />
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.friday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.friday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.friday`)}
          />
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.saturday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.saturday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.saturday`)}
          />
          <OreCheckbox
            label={t(spendingRuleFormTranslation.form.day.sunday)}
            labelCheckIcon={`${t(
              spendingRuleFormTranslation.form.day.sunday
            )} Check Icon`}
            {...form.register(`timeZones.0.weekDays.sunday`)}
          />
        </OreStack>
      </OreFormGroup>
      <OreFormGroup
        direction="column"
        label={t(spendingRuleFormTranslation.form.timeRestrictionLabel)}>
        <OreStack direction="row" sameSize space="small">
          <OreTimePicker
            id="timeZoneFrom"
            label={t(spendingRuleFormTranslation.form.from)}
            required
            {...form.register('timeZones.0.timeZoneFrom')}
            hasError={
              !!(
                form.formState.errors.timeZones &&
                form.formState.errors.timeZones[0]?.timeZoneFrom
              )
            }
            errorMessage={
              form.formState.errors.timeZones &&
              form.formState.errors.timeZones[0]?.timeZoneFrom?.message
            }
          />
          <OreTimePicker
            id="timeZoneTo"
            label={t(spendingRuleFormTranslation.form.to)}
            required
            {...form.register('timeZones.0.timeZoneTo')}
            hasError={
              !!(
                form.formState.errors.timeZones &&
                form.formState.errors.timeZones[0]?.timeZoneTo
              )
            }
            errorMessage={
              form.formState.errors.timeZones &&
              form.formState.errors.timeZones[0]?.timeZoneTo?.message
            }
          />
        </OreStack>
      </OreFormGroup>
      <OreStack placeContent="stretch" placeItems="stretch" direction="column">
        <OreText bold>
          {t(spendingRuleFormTranslation.form.additionalRestrictionsLabel)}
        </OreText>
        <OreSelect
          id="additionalRestrictions"
          onInput={props.handleAdditionalRestrictionsChange}
          placeholder="Placeholder"
          title="Selecciona una opción"
          value={props.additionalRestrictions}>
          <option
            label={t(
              spendingRuleFormTranslation.form.additionalRestrictionsSelect.default
            )}
            value={SpendingRulesType.noRestriction.toString()}
          />
          ,
          <option
            label={t(
              spendingRuleFormTranslation.form.additionalRestrictionsSelect.byTransaction
            )}
            value={SpendingRulesType.byTransaction.toString()}
          />
          ,
          <option
            label={t(
              spendingRuleFormTranslation.form.additionalRestrictionsSelect.byAmount
            )}
            value={SpendingRulesType.byAmount.toString()}
          />
        </OreSelect>

        {props.additionalRestrictions === SpendingRulesType.byTransaction.toString() && (
          <OreFormGroup
            direction="column"
            hasError={!!form.formState.errors.amount}
            errorMessage={form.formState.errors.amount?.message}
            required>
            <Textbox
              register={form.register}
              errors={form.formState.errors}
              textBoxList={props.transactionTextboxes}
            />
          </OreFormGroup>
        )}
        {props.additionalRestrictions === SpendingRulesType.byAmount.toString() && (
          <OreFormGroup
            direction="column"
            hasError={!!form.formState.errors.amount}
            errorMessage={form.formState.errors.amount?.message}
            required>
            <Textbox
              register={form.register}
              errors={form.formState.errors}
              textBoxList={props.amountTextboxes}
            />
          </OreFormGroup>
        )}
      </OreStack>
      <section className="form-molecule mt-1">
        <OreCheckbox
          label={t(spendingRuleFormTranslation.form.favoriteLabel)}
          labelCheckIcon="Favorito Check Icon"
          onInput={props.handleChangeFav}
          {...form.register('isDefault')}
        />
      </section>
    </OreStack>
  )
}
