import { OreButton, OreInput, OreStack, OreText, OreToolbar } from '@runroom/oreneta'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  duplicatedTranslation,
  duplicatesConfigurationTranslations,
  forms,
} from 'src/domain/translations'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface ApplyButtonClick {
  selectedEmployees: number
  onAssignMultipleValues: (initialAmount: string, costCenter: string) => void
}

export const TTUnloadConfigurationToolbar: React.FC<ApplyButtonClick> = ({
  selectedEmployees,
  onAssignMultipleValues,
}): JSX.Element => {
  const [initialAmount, setInitialAmount] = useState<string>('')
  const [costCenter, setCostCenter] = useState('')
  const { t } = useTranslation()

  const Apply = (): void => {
    onAssignMultipleValues(initialAmount, costCenter)
    setInitialAmount('')
    setCostCenter('')
  }

  return (
    <>
      <OreToolbar
        first_section={
          <>
            <OreText
              bold
              size="text-md"
              style={{ marginBottom: '4px' }}
              tone="neutral-900">
              {t(duplicatesConfigurationTranslations.selector.title)}
            </OreText>
            <OreText size="text-xs">
              {selectedEmployees} {t(duplicatedTranslation.selectedRowsDescriptionOthers)}
            </OreText>
          </>
        }
        second_section={
          <OreStack direction="row" space="small">
            <OreInput
              label={t(TTOrderTranslation.unload.employeesConfiguration.unloadBalance)}
              placeholder={t(forms.placeholders.order.amount)}
              type="number"
              value={initialAmount}
              onChange={e => setInitialAmount(e.target.value)}
            />
            <OreInput
              label={t(forms.employee.costCenter)}
              placeholder={t(forms.placeholders.employee.costCenter)}
              type="text"
              value={costCenter}
              maxLength={50}
              onChange={e => setCostCenter(e.target.value)}
            />
            <OreStack placeContent="end">
              <OreButton
                as="button"
                onClick={Apply}
                disabled={!initialAmount && !costCenter}>
                {t(TGDOrderTranslation.employees.configuration.buttons.applyToSelection)}
              </OreButton>
            </OreStack>
          </OreStack>
        }
        sticky
      />
    </>
  )
}
