import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { accountService } from '../../../../../core/services'
import { forms } from '../../../../../domain/translations'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useNotification } from '../../../../context/notification/NotificationProvider'
import { UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { AccountNotificationModel } from 'src/domain/models'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'

interface UserDataState {
  form: UseFormReturn<AccountNotificationModel, any>
  saveChanges: () => void
  errorMessage: string
}

export const useAccountNotificationController = (): UserDataState => {
  const { startLoading, stopLoading } = useLoader()
  const { addNotification } = useNotification()
  const { t } = useTranslation()
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const formSchema = Yup.object({
    receiveInformation: Yup.bool(),
  })

  const form = useForm<AccountNotificationModel>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      receiveInformation: false,
    },
  })

  const getReceiveInformation = (): void => {
    startLoading()
    accountService()
      .getReceiveInformation()
      .then(response => {
        if (response?.meta?.status === 'SUCCESS') {
          form.setValue('receiveInformation', response.data)
        }
      })
      .finally(() => stopLoading())
  }

  const saveChanges = (): void => {
    setStateErrorMessage('')
    startLoading()
    accountService()
      .updateNotification(form.getValues('receiveInformation'))
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(t(forms.success.message), NotificationSeverity.success)
        } else {
          if (response?.meta?.messages.length > 0) {
            const description = response?.meta?.messages[0].description
            const errorField = response?.meta?.messages[0].value
            let errorNotification = description
            if (errorField !== null) errorNotification += ': ' + errorField
            setStateErrorMessage(errorNotification)
          } else {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
          }
        }
      })
      .finally(() => stopLoading())
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
  }

  useEffect(() => {
    getReceiveInformation()
  }, [])

  return {
    form,
    saveChanges,
    errorMessage,
  }
}
