import { yupResolver } from '@hookform/resolvers/yup'
import { Seq } from 'immutable'
import { useEffect, useState } from 'react'
import type { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEmployeeProfileController } from './useEmployeeProfileController'
import { ComboBoxOptionModel, TextBoxRowModel } from 'src/domain/customComponents'
import {
  EmployeeUsageProfileUpdated,
  EmployeesWithLastCard,
  EmployeesWithLastCardUpdated,
  MessagesModel,
  SpendingRuleModel,
} from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEmployeeEditProfileSchemaTR } from 'src/presentation/validations'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { cardOrderFormTranslation, forms } from 'src/domain/translations'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import {
  MetaStatusCodes,
  NotificationSeverity,
  TextBoxesTypes,
  edenredProducts,
} from 'src/domain/enum'
import { ConvertESDateToISO, minAge } from 'src/core/helpers'
import { isMixClient, spendingRuleService } from 'src/core/services'
import { GetSpendingRulesRequestBody } from 'src/core/controllers/cardOrder/services'
import { EmployeesService } from 'src/core/services/employeesService'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface ControllerState {
  GetEmployeeDataTextBoxes: () => TextBoxRowModel<EmployeesWithLastCard>[]
  register: UseFormRegister<EmployeesWithLastCard>
  errors: any
  spendingRulesOptions: ComboBoxOptionModel[]
  updateEmployee: (updatedEmployee: EmployeesWithLastCard) => void
  handleSubmit: UseFormHandleSubmit<EmployeesWithLastCard>
  enableEdit: boolean
  handleEnableEdit: () => void
  errorMessage: string
}

export const useEmployeeProfileFormController = (
  employeeData: EmployeesWithLastCard,
  isEmployeeFlex?: boolean
): ControllerState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { permissionTags } = useAuth()
  const { employeeEditProfileSchema } = useEmployeeEditProfileSchemaTR()
  const { addNotification } = useNotification()
  const [usageProfile, setUsageProfile] = useState('')
  const [usageProfileRows, setUsageProfileRows] = useState<SpendingRuleModel[]>()
  const [enableEdit, setEnableEdit] = useState(false)
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const [spendingRulesOptions, setSpendingRulesOptions] = useState<ComboBoxOptionModel[]>(
    [
      {
        options: [[t(cardOrderFormTranslation.cardDetail.spendingRulesPlaceholder), 0]],
      },
    ]
  )

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<EmployeesWithLastCard>({
    resolver: yupResolver(employeeEditProfileSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      corporativeEmail: employeeData?.corporativeEmail,
      corporativeTelephone: employeeData?.corporativeTelephone,
      birthDate: employeeData?.birthDate,
      userId: employeeData?.userId,
    },
  })

  const GetEmployeeDataTextBoxes = (): TextBoxRowModel<EmployeesWithLastCard>[] => {
    return [
      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'email',
            label: t(employeesTranslation.profile.form.email),
            name: 'corporativeEmail',
            placeHolder: t('marta@gmail.com'),
            required: true,
            type: 'email',
            googleApi: false,
            maxLength: 75,
            disabled: !enableEdit,
          },

          {
            id: 'telephone',
            label: t(employeesTranslation.profile.form.phone),
            name: 'corporativeTelephone',
            placeHolder: t('677777777'),
            required: false,
            type: 'tel',
            googleApi: false,
            maxLength: 9,
            disabled: !enableEdit,
          },
        ],
      },

      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'reminderDate',
            label: t(forms.contact.birthdate),
            name: 'birthDate',
            required: true,
            controlType: TextBoxesTypes.datepicker,
            minDate: '1922-01-01',
            maxDate: minAge(),
            disabled: !enableEdit,
          },
          {
            id: 'employeeNumber',
            label: t(employeesTranslation.profile.form.employeeNumber),
            name: 'employeeNumber',
            placeHolder: t('123456'),
            required: false,
            type: 'text',
            googleApi: false,
            maxLength: 10,
            disabled: !enableEdit,
          },
        ],
      },
    ]
  }

  const fetchSpendingRules = async (): Promise<SpendingRuleModel[]> => {
    return new Promise<SpendingRuleModel[]>(resolve => {
      spendingRuleService()
        .getAll(GetSpendingRulesRequestBody())
        .then(response => {
          if (response.data?.usageProfiles?.rows) {
            setUsageProfileRows(response.data.usageProfiles.rows)
            resolve(response.data.usageProfiles.rows)
          }
          resolve([])
        })
    })
  }

  const GetSpendingRulesOptions = (spendingRules: SpendingRuleModel[]): void => {
    let options: ComboBoxOptionModel[] = [...spendingRulesOptions]
    if (spendingRules) {
      options = [
        {
          options: [[t(cardOrderFormTranslation.cardDetail.spendingRulesPlaceholder), 0]],
        },
      ]
      Seq(spendingRules).forEach((spendingRule: SpendingRuleModel) => {
        options[0].options.push([spendingRule.profileName, spendingRule.profileId])
      })
    }
    setSpendingRulesOptions(options)
  }

  const updateEmployee = (updatedEmployee: EmployeesWithLastCard): void => {
    startLoading()
    const newEmployee: EmployeesWithLastCardUpdated = {
      userId: employeeData?.userId || '',
      productCode: edenredProducts.ticketRestaurant,
      corporativeEmail: updatedEmployee.corporativeEmail,
      corporativeTelephone: updatedEmployee.corporativeTelephone,
      birthDate: updatedEmployee.birthDate,
      employeeNumber: updatedEmployee.employeeNumber,
      usageProfileId: updatedEmployee.usageProfile,
    }

    const newSpendingRule: EmployeeUsageProfileUpdated = {
      userId: +employeeData?.userId,
      usageProfileId: +updatedEmployee.usageProfile,
      productCode: edenredProducts.ticketRestaurant,
    }

    const request: Promise<any> =
      isEmployeeFlex && isMixClient(permissionTags)
        ? EmployeesService().updateEmployeeUsageProfile(newSpendingRule)
        : EmployeesService().updateEmployee(newEmployee)

    request
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(t(forms.success.message), NotificationSeverity.success)
          setEnableEdit(false)
        } else {
          if (response?.meta.messages.length > 0) {
            if (isMixClient(permissionTags)) {
              addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            }
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
          setEnableEdit(true)
        }
      })
      .finally(() => stopLoading())
  }

  const setDefaultValues = (): void => {
    setValue('corporativeEmail', employeeData?.corporativeEmail || '')
    setValue('corporativeTelephone', employeeData?.corporativeTelephone || '')
    setValue('birthDate', ConvertESDateToISO(employeeData?.birthDate || ''))
    setValue('employeeNumber', employeeData?.employeeNumber || '')
  }

  const setUsageProfiles = (): void => {
    if (usageProfileRows) {
      GetSpendingRulesOptions(usageProfileRows)
      if (employeeData && employeeData.usageProfile) {
        const currentSpendingRule = usageProfileRows.find(
          (sr: any) => sr.profileName === employeeData.usageProfile
        )
        if (currentSpendingRule) {
          setUsageProfile(currentSpendingRule.profileId.toString())
        }
      }
    }
  }

  const handleEnableEdit = (): void => {
    setEnableEdit(true)
  }

  //set options
  useEffect(() => {
    setUsageProfiles()
  }, [usageProfileRows])

  useEffect(() => {
    setValue('usageProfile', usageProfile)
  }, [usageProfile])

  useEffect(() => {
    setDefaultValues()
  }, [employeeData])

  useEffect(() => {
    fetchSpendingRules()
  }, [])

  return {
    GetEmployeeDataTextBoxes,
    register,
    errors,
    spendingRulesOptions,
    updateEmployee,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  }
}
