import { useDuplicatedEmployees } from '../hooks/useDuplicatedEmployees'
import { DuplicatedEmployeesFooterButtons, DuplicatedEmployeesTable } from '../molecules'

export const DuplicatedEmployees = (): JSX.Element => {
  const props = useDuplicatedEmployees()
  return (
    <>
      <DuplicatedEmployeesTable useDuplicatedEmployees={props} />
      <DuplicatedEmployeesFooterButtons useDuplicatedEmployees={props} />
    </>
  )
}
