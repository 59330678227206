import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { PropsWithChildren } from 'react'

import {
  usePayrollController,
  IUsePayrollController,
} from 'src/Flex/Payrolls/ui/payroll-list'

type Context = IUsePayrollController

type Props = PropsWithChildren

const [usePayrolls, StateContextProvider] = createGenericContext<Context>()

const PayrollsProvider = ({ children }: Props): JSX.Element => {
  const hook = usePayrollController()

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { PayrollsProvider, usePayrolls }
