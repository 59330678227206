export const duplicatedTranslation = {
  title: 'duplicatedTranslation.title',
  captionTitle: 'duplicatedTranslation.captionTitle',
  rowsTotalDescription: 'duplicatedTranslation.rowsTotalDescription',
  searchTitle: 'duplicatedTranslation.searchTitle',
  showSelected: 'duplicatedTranslation.showSelected',
  selectedRowsDescriptionOne: 'duplicatedTranslation.selectedRowsDescriptionOne',
  selectedRowsDescriptionOthers: 'duplicatedTranslation.selectedRowsDescriptionOthers',
  userUnavailable: 'duplicatedTranslation.userUnavailable',
  nextPageLabel: 'duplicatedTranslation.nextPageLabel',
  previousPageLabel: 'duplicatedTranslation.previousPageLabel',
  pageOf: 'duplicatedTranslation.pageOf',
  NextButtonLabel: 'duplicatedTranslation.NextButtonLabel',
  backButtonLabel: 'duplicatedTranslation.backButtonLabel',
  tableHeader: {
    owner: 'duplicatedTranslation.tableHeader.owner',
    employeeNumber: 'duplicatedTranslation.tableHeader.employeeNumber',
    balance: 'duplicatedTranslation.tableHeader.balance',
    balanceTooltip: 'duplicatedTranslation.tableHeader.balanceTooltip',
    cardStatus: 'duplicatedTranslation.tableHeader.cardStatus',
    expiredDate: 'duplicatedTranslation.tableHeader.expiredDate',
    type: 'duplicatedTranslation.tableHeader.type',
  },
  searchNoFound: {
    first: 'duplicatedTranslation.searchNoFound.first',
    link: 'duplicatedTranslation.searchNoFound.link',
    end: 'duplicatedTranslation.searchNoFound.end',
  },
  cancelModal: {
    title: 'duplicatedTranslation.cancelModal.title',
    subtitle: 'duplicatedTranslation.cancelModal.subtitle',
  },
}
