import React from 'react'
import { Table } from '../../../Edenred'
import { CardOrderHomeForm } from '../../cardOrderHome/organism'
import { useRechargeHomeController } from '../hook/useRechargeHomeController'
import { useTranslation } from 'react-i18next'
import { recharge } from '../../../../../domain/translations'
import { CardOrderHomeFooter } from '../../cardOrderHome/molecules'
import { LeaveOrderPrompt } from 'src/presentation/components/Edenred/navigation'

const Container = () => {
  const { rows, header, footer, setRows, tableEmpty, caption, selector } =
    useRechargeHomeController()
  const { t } = useTranslation()

  return (
    <>
      <Table
        header={header}
        rows={rows}
        setRows={setRows}
        selector={selector}
        footer={footer()}
        tableBodyValign="middle"
        tableEmpty={tableEmpty}
        caption={caption}
        confirmDelete={true}
        confirmDeleteTitle={t(recharge.tableAction.delete.title)}
        confirmDeleteDescription={t(recharge.tableAction.delete.description)}>
        <>{rows.length > 0 ? <CardOrderHomeForm /> : <CardOrderHomeFooter />}</>
      </Table>

      <LeaveOrderPrompt />
    </>
  )
}

export default Container
