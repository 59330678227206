export enum invoicesTabs {
  invoices = 0,
  creditNotes = 1,
}

export const invoiceStatus = {
  pending: 1,
  expired: 2,
  partiallyPaid: 3,
  paid: 4,
  cancelled: 5,
}

export const creditNoteStatus = {
  available: 1,
  refunded: 2,
  affected: 3,
  cancelled: 4,
}
