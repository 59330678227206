import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import type { UploadExcelStepProps } from './organism/UploadExcelStep';
import { UploadExcelStep } from './organism/UploadExcelStep'
import { SimpleForm } from '../../../../Templates'
import { edenredProducts } from '../../../../../domain/enum'

export const OrderExcelPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const uploadExcelStepProps: UploadExcelStepProps = {
    product: edenredProducts.ticketRestaurant,
  }

  return (
    <SimpleForm
      title={t(cardOrderFormTranslation.title)}
      organisms={[<UploadExcelStep {...uploadExcelStepProps} />]}
    />
  )
}
