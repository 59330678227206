import { t } from 'i18next'
import { employeesService } from 'src/Flex/Employees/application'
import { type EmployeeModel } from 'src/Flex/Employees/domain'
import { FilterResultModel, SearchParamsModel } from 'src/Flex/Shared/domain'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useBackendTable } from 'src/Flex/Shared/ui/Table'
import { HttpModel, PaginationModel } from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { flexEmployeesTranslations } from '../../translations'

export interface IUseEmployeeController {
  loadEmployees: () => Promise<boolean>
  confirmContractReception: (employee: EmployeeModel) => Promise<boolean>
  numberOfRecordsPerPage: number
  employees: EmployeeModel[]
  page: number
  count: number
  onPageChange: (page: number) => void
  search: (search: string, filters?: FilterResultModel[]) => void
  searchParameters: SearchParamsModel
  toggleState: (employee: EmployeeModel) => Promise<boolean>
}

export const useEmployeeController = (): IUseEmployeeController => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()

  const load = (
    page: number,
    itemsPerPage: number,
    searchParameters: SearchParamsModel
  ): Promise<HttpModel<PaginationModel<EmployeeModel>>> => {
    return employeesService().GetEmployees(
      page,
      itemsPerPage,
      searchParameters.search,
      searchParameters.filters
    )
  }

  const { items, loadItems, actionOnItem, itemsPerPage, ...rest } =
    useBackendTable<EmployeeModel>(load)

  const confirmContractReception = (employee: EmployeeModel): Promise<boolean> =>
    actionOnItem(item => employeesService().ConfirmReceivedContract(item.id), employee)

  const toggleState = async (employee: EmployeeModel): Promise<boolean> => {
    startLoading()

    const response = await employeesService().SaveEmployeeStatus(employee.id)

    stopLoading()

    const valid = !employee.active
      ? handleMetaResponse(response?.meta, undefined, {
          successMessage: t(flexEmployeesTranslations.subscribe.success),
        })
      : handleMetaResponse(response?.meta, undefined, {
          successMessage: t(flexEmployeesTranslations.unsubscribe.success),
        })

    if (valid && employee) {
      loadItems()
    }

    return valid
  }

  return {
    loadEmployees: loadItems,
    toggleState,
    numberOfRecordsPerPage: itemsPerPage,
    confirmContractReception,
    employees: items,
    ...rest,
  }
}
