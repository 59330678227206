export const duplicatesConfigurationTranslations = {
  title: 'duplicatesConfiguration.title',
  headerDescription: 'duplicatesConfiguration.headerDescription',
  table: {
    caption: {
      title: 'duplicatesConfiguration.table.caption.title',
      description: 'duplicatesConfiguration.table.caption.description',
    },
    header: {
      name: 'duplicatesConfiguration.table.header.name',
      email: 'duplicatesConfiguration.table.headers.email',
      reason: 'duplicatesConfiguration.table.headers.reason',
      reasonTooltip1: 'duplicatesConfiguration.table.headers.reasonTooltip1',
      reasonTooltip2: 'duplicatesConfiguration.table.headers.reasonTooltip2',
      type: 'duplicatesConfiguration.table.headers.type',
      deliverySite: 'duplicatesConfiguration.table.headers.deliverySite',
    },
    footer: {
      action: 'duplicatesConfiguration.table.footer.action',
    },
    comboBoxFields: {
      default: 'duplicatesConfiguration.table.option.default',
      reson: {
        optionOne: 'duplicatesConfiguration.table.reason.one',
        optionTwo: 'duplicatesConfiguration.table.reason.two',
      },
      type: {
        optionOne: 'duplicatesConfiguration.table.type.one',
        optionTwo: 'duplicatesConfiguration.table.type.two',
      },
    },
  },
  selector: {
    title: 'duplicatesConfiguration.selector.title',
    description: 'duplicatesConfiguration.selector.description',
    button: 'duplicatesConfiguration.selector.button',
  },
  modal: {
    title: 'duplicatesConfiguration.modal.title',
    description: 'duplicatesConfiguration.modal.description',
  },
  tableEmpty: {
    first: 'duplicatesConfiguration.tableEmpty.first',
    link: 'duplicatesConfiguration.tableEmpty.link',
    end: 'duplicatesConfiguration.tableEmpty.end',
  },
}
