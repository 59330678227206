import { PropsWithChildren } from 'react'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import {
  IUseEmployeePendingAdherenceController,
  useEmployeesPendingAdherenceController,
} from '../hooks/useEmployeesPendingAdherenceController'

type Context = IUseEmployeePendingAdherenceController

type Props = PropsWithChildren

const [useEmployeesPendingAdherence, StateContextProvider] =
  createGenericContext<Context>()

const EmployeesPendingAdherenceProvider = ({ children }: Props): JSX.Element => {
  const hook = useEmployeesPendingAdherenceController()

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { EmployeesPendingAdherenceProvider, useEmployeesPendingAdherence }
