import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { forms } from 'src/domain/translations'
import { DeleteClientEmployeeResponse } from 'src/domain/models/cardOrder/DeleteClientEmployees'
import { EmployeesService } from 'src/core/services/employeesService'
import { useModalController } from 'src/presentation/components/Edenred'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export interface DeleteEmployeeProps {
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  onClickDeleteEmployee: (employeeNumber: string) => void
}

export const useDeleteEmployeeController = (
  addSuccessNotification: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void
): DeleteEmployeeProps => {
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const SetPopupButtonsDeleteEmployee = (employeeNumber: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.unsubscribe),
        category: 'danger',
        onClick: () => deleteEmployee(employeeNumber),
        size: 'large',
      },
    ]
  }

  const deleteEmployee = (employeeNumber: string): void => {
    setShowPopup(false)
    startLoading()
    EmployeesService()
      .deleteEmployeeByDocument(employeeNumber)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addSuccessNotification(response.data, false)
          navigate(navigationRoutes.employeesHome)
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .finally(() => stopLoading())
  }

  const onClickDeleteEmployee = (employeeNumber: string): void => {
    setShowPopup(true)
    setButtonsPopup(SetPopupButtonsDeleteEmployee(employeeNumber))
  }

  useEffect(() => {
    setTitlePopup(t(employeesTranslation.table.deleteModal.title))
    setDescriptionPopup(t(employeesTranslation.table.deleteModal.description))
  }, [])

  return {
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    onClickDeleteEmployee,
  }
}
