import { OreTooltip } from '@runroom/oreneta'
import React from 'react'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface RestrictedComponentProps {
  children: JSX.Element
  by: string | string[]
  disabled?: boolean
  tooltipText?: string
  tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom'
  tooltipSeparation?: 'xsmall' | 'small' | 'large'
}

export const RestrictedComponent = ({
  children,
  by,
  disabled,
  tooltipText,
  tooltipPlacement = 'top',
  tooltipSeparation = 'small',
}: RestrictedComponentProps): JSX.Element => {
  let allowed = false
  const { permissionTags } = useAuth()

  if (typeof by === 'object' && by.some(tag => permissionTags.includes(tag))) {
    allowed = true
  }

  if (typeof by === 'string' && permissionTags.includes(by)) {
    allowed = true
  }

  const getDisabledComponent = (): JSX.Element => {
    return React.cloneElement(children, {
      disabled: true,
      onClick: () => null,
    })
  }

  const addTooltip = (children: JSX.Element): JSX.Element => {
    if (tooltipText) {
      return (
        <OreTooltip
          text={tooltipText}
          placement={tooltipPlacement}
          separation={tooltipSeparation}>
          {children}
        </OreTooltip>
      )
    }
    return children
  }

  if (!allowed) {
    if (disabled) {
      return addTooltip(getDisabledComponent())
    }
    return <></>
  }

  return children
}
