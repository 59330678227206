export enum stepper {
  firstStep = 1,
  secondStep = 2,
  thirdStep = 3,
  fourthStep = 4,
}

export enum TextBoxesTypes {
  textbox = 'textbox',
  datepicker = 'datepicker',
}

export enum AlignPosition {
  vertical = 1,
  horizontal = 2,
}
