import { number } from 'yup'
import { TFunction } from 'react-i18next'
import { RequiredNumberSchema } from 'yup/lib/number'
import { forms } from 'src/domain/translations'

export const nonEmptyNumericChoiceSchema = (
  t: TFunction<'translation', undefined>,
  choices: number[]
): RequiredNumberSchema<number | undefined> =>
  number()
    .typeError(t(forms.errors.fieldRequired))
    .oneOf(choices, t(forms.errors.fieldRequired))
    .required(t(forms.errors.fieldRequired))
