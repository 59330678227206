import { OreInput } from '@runroom/oreneta'
import type { Dispatch, SetStateAction } from 'react'
import type {
  FilterControlModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'
import { useFilterInput } from '../../hooks/table/useFilterInput'

export const FilterInput = (props: {
  input: FilterControlModel
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
}): JSX.Element => {
  const { onChange } = useFilterInput(props.setFilter)
  return (
    <OreInput
      name={props.input.name}
      label={props.input.label ? props.input.label : ''}
      placeholder={props.input.placeHolder}
      value={props.input.value}
      hasError={props.input.hasError}
      errorMessage={props.input.errorMassage}
      onChange={event => onChange(event, props.input.name)}
    />
  )
}
