import { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { FlexDataModel, UserRepository } from 'src/Flex/User/domain'
import { axiosFetch } from 'src/infrastructure/axios'
import { getFlexInitialData } from 'src/config/constants/endpoints'
import { KPI, KPIUnit } from 'src/Flex/Reporting/domain'

export function createApiUserRepository(): UserRepository {
  return new ApiUserRepository()
}

type ApiKPI = {
  title: string
  value: number
  type: number
}

type ApiInitialData = HttpModel<{
  isOnboardingDone: boolean
  onboardingStep: number
  KPIs: ApiKPI[]
} | null>

const allowedKPITypes = [0, 1, 2]

const convertKPIToDomain = (kpi: ApiKPI): KPI => ({
  name: kpi.title,
  units:
    kpi.type === 0
      ? KPIUnit.Number
      : kpi.type === 1
      ? KPIUnit.Percentage
      : KPIUnit.Currency,
  value: kpi.value,
})

const convertToDomain = (response: ApiInitialData): FlexDataModel => {
  return {
    isOnboardingDone: response.data?.isOnboardingDone ?? false,
    onboardingStep: response.data?.onboardingStep ?? 0,
    kpis:
      response.data?.KPIs.filter(kpi => allowedKPITypes.includes(kpi.type)).map(
        convertKPIToDomain
      ) ?? [],
  }
}

class ApiUserRepository implements UserRepository {
  async GetInitialFlexData(): Promise<HttpModel<FlexDataModel>> {
    const response: ApiInitialData = await axiosFetch(
      getFlexInitialData,
      HttpMethod.GET,
      {}
    )
    return {
      data: convertToDomain(response),
      meta: response.meta,
    }
  }
}
