import { PropsWithChildren } from 'react'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import {
  IUseEmployeesController,
  useEmployeesAdherenceController,
} from 'src/Flex/Orders/ui/order-adherence'

type Context = IUseEmployeesController

type Props = PropsWithChildren

const [useEmployeesAdherence, StateContextProvider] = createGenericContext<Context>()

const EmployeesAdherenceProvider = ({ children }: Props): JSX.Element => {
  const hook = useEmployeesAdherenceController()

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { EmployeesAdherenceProvider, useEmployeesAdherence }
