import { useEmployeesListTableTGDController } from '../hooks/useEmployeesListTableTGDController'
import { EmployeeListTableTGDFooterButtons, EmployeeListTableTGD } from '../molecules'

export const EmployeesListTableTGDContainer = (): JSX.Element => {
  const props = useEmployeesListTableTGDController()
  return (
    <div className="py-2">
      <EmployeeListTableTGD employeesTGDProps={props} />
      <EmployeeListTableTGDFooterButtons employeesTGDProps={props} />
    </div>
  )
}
