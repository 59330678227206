import { t } from 'i18next'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { RepositoryFactory } from 'src/core/factories/RepositoryFactory'
import { excelRequestType } from 'src/domain/enum/cardOrder'
import { edenredProducts } from 'src/domain/enum/edenredProducts'
import { Repositories } from 'src/domain/enum/repositories'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { ExcelRepository } from 'src/infrastructure/repository/ExcelRepository'

export const excelService = (): ExcelRepository => {
  return RepositoryFactory(Repositories.excel)
}

export interface OrderExcelToRepeat {
  excelTemplate: number
  filename: string
  redirectUrlAfterDownload: string
}

export const GetExcelorderType = (productId: number): OrderExcelToRepeat => {
  let excelOrder: OrderExcelToRepeat
  switch (productId) {
    case edenredProducts.ticketGuarderia:
      excelOrder = {
        filename: t(TGDOrderTranslation.excel.repeat.assignmentFilename),
        excelTemplate: excelRequestType.TgdOrder,
        redirectUrlAfterDownload: navigationRoutes.tgdOrderExcel,
      }
      break
    default:
      excelOrder = {
        filename: t(TGDOrderTranslation.excel.repeat.rechargeFilename),
        excelTemplate: excelRequestType.Recharge,
        redirectUrlAfterDownload: navigationRoutes.cardOrderRechargeExcel,
      }
      break
  }
  return excelOrder
}
