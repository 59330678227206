import {
  OreButton,
  OreInput,
  OreSelect,
  OreStack,
  OreText,
  OreToolbar,
} from '@runroom/oreneta'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ComboBoxOptionModel } from 'src/domain/customComponents'
import { cardOrderReason, cardRequestTypeId } from 'src/domain/enum'
import { DeliverySiteModel } from 'src/domain/models'
import {
  duplicatedTranslation,
  duplicatesConfigurationTranslations,
  forms,
} from 'src/domain/translations'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'

interface ApplyButtonClick {
  selectedEmployees: number
  onAssignMultipleValues: (
    reason: number,
    deliverySiteId: number,
    deliverySite: DeliverySiteModel
  ) => void
  deliverySitesOptions: ComboBoxOptionModel[]
  deliverySites: DeliverySiteModel[]
}

export const TTOrderConfigurationToolbar: React.FC<ApplyButtonClick> = ({
  selectedEmployees,
  onAssignMultipleValues,
  deliverySitesOptions,
  deliverySites,
}): JSX.Element => {
  const [reason, setReason] = useState<number>(0)
  const [deliverySiteId, setDeliverySiteId] = useState<number>(0)
  const [deliverySite, setDeliverySite] = useState<DeliverySiteModel>(
    {} as DeliverySiteModel
  )
  const { t } = useTranslation()

  const onDeliverySiteChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const deliverySite = deliverySites.find(
      (ds: DeliverySiteModel) => ds.deliveryPointId === +event.target.value
    )
    if (deliverySite) {
      setDeliverySiteId(+event.target.value)
      setDeliverySite(deliverySite)
    }
  }

  const Apply = (): void => {
    onAssignMultipleValues(reason, deliverySiteId, deliverySite)
    setReason(0)
    setDeliverySiteId(0)
    setDeliverySite({} as DeliverySiteModel)
  }

  return (
    <>
      <OreToolbar
        first_section={
          <>
            <OreText
              bold
              size="text-md"
              style={{ marginBottom: '4px' }}
              tone="neutral-900">
              {t(duplicatesConfigurationTranslations.selector.title)}
            </OreText>
            <OreText size="text-xs">
              {selectedEmployees} {t(duplicatedTranslation.selectedRowsDescriptionOthers)}
            </OreText>
          </>
        }
        second_section={
          <OreStack direction="row" space="xsmall">
            <OreSelect
              onChange={e => setReason(+e.target.value)}
              label={t(employeesTranslation.table.header.reason)}
              value={reason}>
              <option label={t(forms.placeholders.select)} value={0} />
              ,
              <option
                label={t(
                  duplicatesConfigurationTranslations.table.comboBoxFields.reson.optionOne
                )}
                value={cardRequestTypeId.lostStolenPlastic}
              />
              ,
              <option
                label={t(
                  duplicatesConfigurationTranslations.table.comboBoxFields.reson.optionTwo
                )}
                value={cardRequestTypeId.damagePlastic}
              />
            </OreSelect>
            <OreSelect
              onInput={onDeliverySiteChange}
              value={deliverySiteId}
              label={t(employeesTranslation.table.header.deliverySite)}>
              {deliverySitesOptions.map((option: ComboBoxOptionModel, index: number) => (
                <optgroup
                  key={'optgroup_' + index}
                  label={t(option.groupName ? option.groupName : '')}>
                  {option.options.map((opt: [string, number], index: number) => (
                    <option key={'option_' + index} label={opt[0]} value={opt[1]} />
                  ))}
                </optgroup>
              ))}
            </OreSelect>
            <OreStack placeContent="end">
              <OreButton
                as="button"
                onClick={Apply}
                disabled={!deliverySiteId && !reason}>
                {t(TGDOrderTranslation.employees.configuration.buttons.applyToSelection)}
              </OreButton>
            </OreStack>
          </OreStack>
        }
        sticky
      />
    </>
  )
}
