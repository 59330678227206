import { OreTableCell, OreTableRow, OreText } from '@runroom/oreneta'
import { GetDietSpendingMap } from 'src/domain/adapters/historyConsumptionAdapters'
import { DietSpendingModel } from 'src/domain/models/historySpendings'

interface Props {
  dietSpending: DietSpendingModel
}

export const DietSpendingsRow = ({ dietSpending }: Props): JSX.Element => {
  const dietSpendingMap = GetDietSpendingMap(dietSpending)
  return (
    <OreTableRow>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {dietSpendingMap.titular}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {dietSpendingMap.nif}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {dietSpendingMap.employeeNumber}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {dietSpendingMap.commerce}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {dietSpendingMap.commerceAdress}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {dietSpendingMap.zipCode}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {dietSpendingMap.city}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {dietSpendingMap.amount + '€'}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {dietSpendingMap.dateValue}
      </OreTableCell>
    </OreTableRow>
  )
}
