export const clientHomeTranslation = {
  title: 'clientHomeTranslation.title',
  subtitle: 'clientHomeTranslation.subtitle',
  ticketRestaurant: {
    frequentTitle: 'clientHomeTranslation.ticketRestaurant.frequentTitle',
    frequentDescription1: 'clientHomeTranslation.ticketRestaurant.frequentDescription1',
    frequentDescription2: 'clientHomeTranslation.ticketRestaurant.frequentDescription2',
    frequentDescription3: 'clientHomeTranslation.ticketRestaurant.frequentDescription3',
    repetOrder: {
      caption: 'clientHomeTranslation.ticketRestaurant.repetOrder.caption',
      rowText: 'clientHomeTranslation.ticketRestaurant.repetOrder.rowText',
      emptyTableSubtitle:
        'clientHomeTranslation.ticketRestaurant.repetOrder.emptyTableSubtitle',
      emptyTableDescription:
        'clientHomeTranslation.ticketRestaurant.repetOrder.emptyTableDescription',
    },
    favoriteOrder: {
      caption: {
        title: 'clientHomeTranslation.ticketRestaurant.favoriteOrder.caption.title',
        description:
          'clientHomeTranslation.ticketRestaurant.favoriteOrder.caption.description',
        emptyTableSubtitle:
          'clientHomeTranslation.ticketRestaurant.favoriteOrder.emptyTableSubtitle',
        emptyTableDescription:
          'clientHomeTranslation.ticketRestaurant.favoriteOrder.emptyTableDescription',
      },
      rowText: 'clientHomeTranslation.ticketRestaurant.favoriteOrder.rowText',
    },
    invoices: {
      caption: {
        title: 'clientHomeTranslation.ticketRestaurant.invoices.caption.title',
      },
      recharge: 'clientHomeTranslation.ticketRestaurant.invoices.rechargue',
      unload: 'clientHomeTranslation.ticketRestaurant.invoices.unload',
      emptyTableSubtitle:
        'clientHomeTranslation.ticketRestaurant.invoices.emptyTableSubtitle',
      emptyTableDescription:
        'clientHomeTranslation.ticketRestaurant.invoices.emptyTableDescription',
    },
  },
  kindergarten: {
    frequentTitle: 'clientHomeTranslation.kindergarten.frequentTitle',
    frequentDescription1: 'clientHomeTranslation.kindergarten.frequentDescription1',
    frequentDescription2: 'clientHomeTranslation.kindergarten.frequentDescription2',
    frequentDescription3: 'clientHomeTranslation.kindergarten.frequentDescription3',
  },
  ticketTransport: {
    frequentDescription1: 'clientHomeTranslation.ticketTransport.frequentDescription1',
  },
}
