import {
  ApiGroupedTasks,
  ApiTasksObject,
  GroupedTasks,
  TaskModel,
  TaskTypeEnum,
  TasksRepositoryContract,
} from 'src/Flex/TaskManager/domain'
import { getPendingTasks } from 'src/config/constants/endpoints'
import { gruped } from 'src/domain/constants'
import { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'

const groupByType = (arr: TaskModel[] | null): ApiGroupedTasks | null => {
  if (!arr) return null

  const initValues: ApiGroupedTasks = {
    [TaskTypeEnum.pendingImputation]: [],
    [TaskTypeEnum.pendingValidation]: [],
    [TaskTypeEnum.pendingContract]: [],
    [TaskTypeEnum.pendingAdhesion]: [],
    [TaskTypeEnum.pendingWelcomeCommunication]: [],
    [TaskTypeEnum.pendingOnboardingSteps]: [],
  }

  return arr.reduce((memo, x) => {
    if (!memo[x['type']]) {
      memo[x['type']] = []
    }
    memo[x['type']].push(x)
    return memo
  }, initValues)
}

export class TasksRepository implements TasksRepositoryContract {
  async GetPendingTasks(): Promise<HttpModel<GroupedTasks>> {
    const t: ApiTasksObject = await axiosFetch(getPendingTasks, HttpMethod.GET, {})

    const apiGroupedTasks: ApiGroupedTasks | null = groupByType(t.data)

    const groupedTasks: GroupedTasks = {
      [TaskTypeEnum.pendingImputation]: [],
      [TaskTypeEnum.pendingValidation]: [],
      [TaskTypeEnum.pendingOnboardingSteps]: [],
      employees: [],
      general: [],
    }

    if (!apiGroupedTasks)
      return {
        data: groupedTasks,
        meta: t.meta,
      }

    groupedTasks[TaskTypeEnum.pendingImputation] =
      apiGroupedTasks[TaskTypeEnum.pendingImputation]

    groupedTasks[TaskTypeEnum.pendingValidation] =
      apiGroupedTasks[TaskTypeEnum.pendingValidation]

    groupedTasks[TaskTypeEnum.pendingOnboardingSteps] =
      apiGroupedTasks[TaskTypeEnum.pendingOnboardingSteps]

    groupedTasks['general'] = [
      ...apiGroupedTasks[TaskTypeEnum.pendingAdhesion],
      ...apiGroupedTasks[TaskTypeEnum.pendingWelcomeCommunication],
    ]

    groupedTasks['employees'] = [...apiGroupedTasks[TaskTypeEnum.pendingContract]]

    return {
      data: groupedTasks,
      meta: t.meta,
    }
  }
}
