import { t } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'
import type React from 'react'
import { useEffect, useState } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import type {
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import {
  MetaStatusCodes,
  NotificationSeverity,
  Repositories,
  edenredProducts,
  emptyValues,
} from '../../../../../domain/enum'
import type { HttpModel } from '../../../../../domain/models'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import {
  forms,
  historyTranslation,
  productsTranslate,
} from '../../../../../domain/translations'
import { Seq } from 'immutable'
import { RepositoryFactory } from '../../../../../core/factories'
import type { IUserConsumptionMapped, IUserConsumptionResponse } from 'src/domain/history'
import { mapHistoryConsumptionList } from 'src/domain/adapters/historyConsumptionAdapters'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { GetHistoryConsumptionsGroupByFilter } from 'src/core/services/historySpendingService'
import { myOrders } from '../../../../../domain/translations/cardOrder/myOrders'
import { historyConsumptionsTranslations } from 'src/domain/translations/history/historyConsumption'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import type {
  HistorySpendingParams,
  IPostPaginationModel,
  useHistorySpendingProps,
} from 'src/domain/interfaces/history/HistorySpending'
import { HistorySpendingRequestParams } from 'src/domain/interfaces/history/HistorySpending'
import { historyConsumptions } from 'src/domain/enum/history'
import { HistoryService } from 'src/core/services/historyService'

interface IUseHistorySpending {
  header: HeaderModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  footer: FooterModel
  tableEmpty: TableEmptyModel
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  pageSize: number
  downloadExcel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export const useHistorySpending = ({
  pageSize,
  showFilter,
  productCode,
}: useHistorySpendingProps): IUseHistorySpending => {
  const [consumptionList, setConsumptionList] = useState<IUserConsumptionMapped[]>([])
  const { startLoading, stopLoading } = useLoader()
  const [filter, setFilter] = useState<FilterModel>({})
  const [rows, setRows] = useState<RowModel[]>([])
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState<number>(0)
  const isServerSide = true
  const [queryFilters, setQueryFilters] = useState<HistorySpendingParams>({})
  const { addNotification } = useNotification()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      { label: t(employeesTranslation.table.header.identityNumber) },
      { label: t(employeesTranslation.profile.form.employeeNumber) },
      { label: t(historyConsumptionsTranslations.consumed) },
      { label: t(historyConsumptionsTranslations.consumptionDate) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 5,
    firstText: t(historyTranslation.table.emptyMessage),
  }

  const HandleResponse = (response: HttpModel<IUserConsumptionResponse>): void => {
    const responseRows = response?.data?.rows ? response.data.rows : []
    const rowsMap = mapHistoryConsumptionList(responseRows)
    setTotalRows(response?.data?.totalRows ? response.data.totalRows : 0)
    setConsumptionList(rowsMap)
  }

  function SetPagination(): IPostPaginationModel {
    return {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    }
  }

  const RequestTablePage = (page: number, filters: HistorySpendingParams = {}): void => {
    const repository = RepositoryFactory(Repositories.history)

    ValidateFilters()
    const pagination: IPostPaginationModel = SetPagination()

    const response = repository.GetUserConsumptionList(filters, pagination)
    startLoading()
    response
      .then((response: HttpModel<IUserConsumptionResponse>) => {
        HandleResponse(response)
      })
      .catch(error => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      })
      .finally(() => stopLoading())

    function ValidateFilters() {
      if (filters[HistorySpendingRequestParams.productCode] === undefined) {
        filters[HistorySpendingRequestParams.productCode] = productCode
      }
      if (filters[HistorySpendingRequestParams.multiFilter] === undefined) {
        filters[HistorySpendingRequestParams.multiFilter] = emptyValues.empty
      }
      if (filters[HistorySpendingRequestParams.groupBy] === undefined) {
        filters[HistorySpendingRequestParams.groupBy] = historyConsumptions.groupByUser
      }
      if (filters[HistorySpendingRequestParams.beginDate] === undefined) {
        filters[HistorySpendingRequestParams.beginDate] = GetMinAllowedDate()
      }
      if (filters[HistorySpendingRequestParams.endDate] === undefined) {
        filters[HistorySpendingRequestParams.endDate] = GetMaxAllowedDate()
      }
    }
  }

  const onClickFilter = (): void => {
    // event.preventDefault()
    OnSearch()
  }

  const GetFilterValues = (): HistorySpendingParams => {
    const tableFilters: HistorySpendingParams = {}

    // Textbox filter: Name, nif/nie, employee number
    if (filter.search && filter.search.textBox.value !== '')
      tableFilters[HistorySpendingRequestParams.multiFilter] =
        filter.search.textBox.value?.trim()

    // Dropdown filters: dates, group by
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          // Group by filter, person or date
          if (field.name === HistorySpendingRequestParams.groupBy && field.value !== '')
            tableFilters[HistorySpendingRequestParams.groupBy] = Number(field.value)

          // Filtro min allowed date
          if (field.name === HistorySpendingRequestParams.beginDate && field.value !== '')
            tableFilters[HistorySpendingRequestParams.beginDate] = field.value

          // Filtro max allowed date
          if (field.name === HistorySpendingRequestParams.endDate && field.value !== '')
            tableFilters[HistorySpendingRequestParams.endDate] = field.value
        })
      })
    }
    return tableFilters
  }

  const OnSearch = (): void => {
    const tableFilters: HistorySpendingParams = GetFilterValues()
    setQueryFilters(tableFilters)
    RequestTablePage(1, tableFilters)
    setPage(1)
  }

  const GetRows = (): void => {
    const consumptionsRows: RowModel[] = []
    Seq(consumptionList).forEach((consumption: IUserConsumptionMapped, index: number) => {
      consumptionsRows.push({
        rowId: index,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: consumption.titular,
                name: t(employeesTranslation.table.header.titular),
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: consumption.dniNie,
                name: t(employeesTranslation.table.header.identityNumber),
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: consumption.numEmpleado,
                name: t(employeesTranslation.profile.form.employeeNumber),
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: consumption.importe,
                name: t(historyConsumptionsTranslations.amount),
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: consumption.dateValue,
                name: t(historyConsumptionsTranslations.consumptionDate),
                size: 'text-sm',
              },
            ],
          },
        ],
      })
    })
    setRows(consumptionsRows)
  }

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: isServerSide,
        totalRows: totalRows,
      },
    })
  }

  const GetMinAllowedDate = (): string => {
    const today = new Date()
    today.setDate(1)
    today.setMonth(today.getMonth() - 12)
    const minAllowedDate = today.toISOString().split('T')[0]
    return minAllowedDate
  }

  const GetMaxAllowedDate = (): string => {
    return new Date().toISOString().split('T')[0]
  }

  const SetFilters = (): void => {
    setFilter({
      showOpen: true,
      search: {
        textBox: {
          fields: [HistorySpendingRequestParams.multiFilter],
          placeHolder: t(historyConsumptionsTranslations.filters.searchBoxPlaceholder),
          value: '',
        },
        searchButton: t(myOrders.filter.search.button),
      },
      filerOptions: [],
      resetButton: t(myOrders.filter.resetButtons),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom form-atom--three',
          filterFields: [
            {
              filterType: 'datePicker',
              name: HistorySpendingRequestParams.beginDate,
              label: t(myOrders.filter.filterFields.dateFrom.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateFrom.placeholder
              ).toUpperCase(),
              value: GetMinAllowedDate(),
              defaultValue: GetMinAllowedDate(),
              min: GetMinAllowedDate(),
              max: GetMaxAllowedDate(),
            },
            {
              filterType: 'datePicker',
              name: HistorySpendingRequestParams.endDate,
              label: t(myOrders.filter.filterFields.dateTo.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateTo.placeholder
              ).toUpperCase(),
              value: GetMaxAllowedDate(),
              defaultValue: GetMaxAllowedDate(),
              min: GetMinAllowedDate(),
              max: GetMaxAllowedDate(),
            },
            {
              filterType: 'comboBox',
              name: HistorySpendingRequestParams.groupBy,
              label: t(historyConsumptionsTranslations.filters.groupby),
              placeHolder: t(myOrders.filter.filterFields.status.placeholder),
              value: historyConsumptions.groupByUser.toString(),
              defaultValue: historyConsumptions.groupByUser.toString(),
              options: [{ options: GetHistoryConsumptionsGroupByFilter(t) }],
            },
          ],
        },
      ],
    })
  }

  const getProductLabelPrefix = (): string => {
    if (productCode === edenredProducts.ticketRestaurant) {
      return t(productsTranslate.ticketRestaurant).replace(' ', '')
    } else {
      return t(productsTranslate.ticketTransporte)
    }
  }

  const downloadExcel = (): void => {
    startLoading()
    HistoryService()
      .downloadConsumptionsExcel(queryFilters)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = `${getProductLabelPrefix()}_Consumos.xlsx`
          link.click()
          link.remove()
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  // Set filters first render only
  useEffect(() => {
    if (!showFilter) return
    SetFilters()
  }, [])

  // After an API call response update rows and page numbers
  useEffect(() => {
    GetRows()
    getFooter()
  }, [consumptionList])

  // On page change make a new request with the new page
  useEffect(() => {
    RequestTablePage(page, queryFilters)
  }, [page])

  return {
    header,
    rows,
    setRows,
    footer,
    tableEmpty,
    filter,
    setFilter,
    onClickFilter,
    pageSize,
    downloadExcel,
  }
}
