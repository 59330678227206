import { useTTOrderConfigurationEmployees } from '../hooks/useTTOrderConfigurationEmployees'
import { TTOrderConfigurationEmployeesList } from '../molecules/TTOrderConfigurationEmployeesList'
import { TTOrderConfigurationEmployeesFooterButtons } from '../molecules/TTOrderConfigurationEmployeesFooterButtons'
import { TTOrderConfigurationToolbar } from '../molecules/TTOrderConfigurationToolbar'

export const TTOrderConfigurationEmployeeContainer = () => {
  const props = useTTOrderConfigurationEmployees()

  return (
    <div>
      <TTOrderConfigurationEmployeesList employeesTTProps={props} />
      <TTOrderConfigurationEmployeesFooterButtons
        tableIsEmpty={props.employeesTT && props.employeesTT.length <= 0}
        addConfigToOrder={props.addConfigToOrder}
        form={props.form}
        cancelPopupButtons={props.cancelPopupButtons}
        onClickCancel={props.onClickCancel}
        setShowCancelModal={props.setShowCancelModal}
        showCancelModal={props.showCancelModal}
        errorMessage={props.errorMessage}
      />
      {props.selectedEmployees.length > 1 && (
        <TTOrderConfigurationToolbar
          selectedEmployees={props.selectedEmployees.length}
          onAssignMultipleValues={props.onAssignMultipleValues}
          deliverySites={props.deliverySites}
          deliverySitesOptions={props.deliverySitesOptions}
        />
      )}
    </div>
  )
}
