import React from 'react'
import { useSpendingRulesEdit } from '../hooks'
import { SpendingRulesForm } from '../../spendingRulesForm/organism'
import { OreDivider, OreMessageBar } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { spendingRuleTranslation } from 'src/domain/translations'
import { SpendingRulesType } from 'src/domain/enum'

export const SpendingRulesEditContainer = () => {
  const { spendingRule } = useSpendingRulesEdit()
  const { t } = useTranslation()

  if (!spendingRule) {
    return <></>
  }

  return (
    <>
      <OreDivider space="medium" />
      {spendingRule.profileTypeId !== SpendingRulesType.timezones && (
        <div className="mb-205 pt-1">
          <OreMessageBar color="warning">
            {t(spendingRuleTranslation.editPage.warningMessage)}
          </OreMessageBar>
        </div>
      )}
      <SpendingRulesForm spendingRuleToEdit={spendingRule} />
    </>
  )
}
