import { PropsWithChildren } from 'react'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import {
  IUseConfigureEmployeeController,
  IUseConfigureEmployeeControllerParams,
  useConfigureEmployeeController,
} from 'src/Flex/Employees/ui/employee-detail'

type Context = IUseConfigureEmployeeController

type Props = PropsWithChildren & IUseConfigureEmployeeControllerParams

const [useConfigureEmployee, StateContextProvider] = createGenericContext<Context>()

const ConfigureEmployeeProvider = ({ children, ...rest }: Props): JSX.Element => {
  const hook = useConfigureEmployeeController(rest)

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { ConfigureEmployeeProvider, useConfigureEmployee }
