export const allowedRoutesToNavigate = [
  '/MisPedidos/Tarjeta/Inicio',
  '/MisPedidos/PedidoDeTarjeta/nuevo',
  '/MisPedidos/ResumenPedido',
  '/MisPedidos/PedidoDeTarjeta/editar',
  '/MisPedidos/PedidoDeDuplicado',
  '/MisPedidos/Duplicado',
  '/MisPedidos/Recarga',
  '/MisPedidos/Descarga',
  '/MisPedidos/RecargaExcel',
  '/MisPedidos/DescargaExcel',
  '/MisPedidos/Recarga/Inicio',
  '/MisPedidos/Descarga/Inicio',
  '/MisPedidos/ConfiguracionDuplicado',
  '/MisPedidos/ConfiguracionDuplicado/Editar',
  '/MisPedidos/ConfiguracionRecarga',
  '/MisPedidos/ConfiguracionRecarga/Editar',
  '/MisPedidos/ConfiguracionDescarga',
  '/MisPedidos/ConfiguracionDescarga/Editar',
  '/MisPedidos/Excel',
  '/MisPedidos/ResumenExcel',
  '/MisPedidos/AlertasExcel',
  '/MisPedidos/Error',
  '/MisPedidos/PedidoCompletado',
]
