import { Dispatch, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { MetaStatusCodes } from 'src/domain/enum'
import { TGDSaveCardOrderModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotificationHandler } from 'src/presentation/hooks'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { TGDOrderService } from 'src/core/services/tgdOrderService'

interface useCardOrderSaveAndCloseState {
  errorMessage: string
  setStateErrorMessage: Dispatch<React.SetStateAction<string>>
  saveAndCloseTGDOrder: (data: TGDSaveCardOrderModel) => Promise<void>
  form: UseFormReturn<TGDSaveCardOrderModel, any>
}

export const useTGDOrderSaveAndClose = (): useCardOrderSaveAndCloseState => {
  const { notificationHandler } = useNotificationHandler()
  const { startLoading, stopLoading } = useLoader()
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tgdOrders, clearTGDOrders } = useTGDOrder()

  const formSchema = Yup.object({
    orderName: Yup.string()
      .max(200, t(forms.errors.maxAllowed))
      .required(t(forms.errors.fieldRequired)),
  })

  const form = useForm<TGDSaveCardOrderModel>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      orderName: '',
    },
  })

  const saveAndCloseTGDOrder = async (data: TGDSaveCardOrderModel): Promise<void> => {
    if (tgdOrders) {
      startLoading()
      const { meta } = await TGDOrderService().saveAndClose(data.orderName, tgdOrders)
      stopLoading()

      // Handle API response
      if (meta.status === MetaStatusCodes.SUCCESS) {
        notificationHandler(meta, true, null)
        navigate(navigationRoutes.myOrdersTgdTab, {
          state: {
            unlockNavigation: true,
          },
        })
        clearTGDOrders()
      } else {
        // has any message?
        if (meta.messages.length > 0) {
          const description = meta.messages[0].description
          const errorField = meta.messages[0].value
          let errorNotification = description

          if (errorField !== null) errorNotification += ': ' + errorField

          setStateErrorMessage(errorNotification)
        }
      }
    }
  }

  return {
    errorMessage,
    setStateErrorMessage,
    saveAndCloseTGDOrder,
    form,
  }
}
