import { useLocation } from 'react-router-dom'
import { Divider } from '../../../layout'
import { simpleFormDividerPaths } from './simpleFormDividerData'

const SimpleFormDivider = (): JSX.Element => {
  const { pathname } = useLocation()
  const match = simpleFormDividerPaths.find(path => pathname === path)

  if (!match) {
    return <></>
  }

  return (
    <>
      <Divider />
      <div style={{ marginTop: '40px' }}></div>
    </>
  )
}

export default SimpleFormDivider
