import {
  OreStack,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { PayrollModel } from 'src/Flex/Payrolls/domain'

import { getProductIconByProductType } from 'src/Flex/Products/ui/shared'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { currency } from 'src/core/helpers'

interface IProps {
  payroll: PayrollModel
}

export const FlexPayrollRow = ({
  payroll: { name, document, collective, product, exemptValue, nonExemptValue },
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const icon = getProductIconByProductType(product)

  return (
    <OreTableRow valign="middle">
      <OreTableCell>
        {icon ? (
          <OreTooltip
            placement="top"
            key={product}
            text={t(flexProductsTranslations.ids[product])}>
            {icon}
          </OreTooltip>
        ) : null}
      </OreTableCell>
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack space="2xsmall">
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {name}
            </OreText>
            <OreText size="text-sm" tone="neutral-600">
              {document}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{collective}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{currency(exemptValue)}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{currency(nonExemptValue)}</OreText>
      </OreTableCell>
    </OreTableRow>
  )
}
