import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  OreButton,
  OreIcon,
  OrePill,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgBin,
  SvgCardDuplicate,
  SvgCardNew,
  SvgEdit,
  SvgInfo,
  SvgMessengerService,
  SvgPlus,
  SvgPostalMail,
} from '@runroom/oreneta'
import { useModal } from '../../../../hooks'
import { CardOrderHomeRemoveModal } from './CardOrderHomeRemoveModal'
import React, { useState } from 'react'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import {
  cardRequestTypeId,
  cardType,
  deliveryType,
  emptyValues,
  orderType,
} from '../../../../../domain/enum'
import {
  addCurrency,
  getCardOrderTypeLength,
  getCardReasonByCardRequestTypeId,
  getNewCardReasonByCardRequestTypeId,
} from '../../../../../core/services'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import { useCardOrderHomeTableController } from '../hooks/useCardOrderHomeTableController'

export const CardOrderHomeTable = () => {
  const navigate = useNavigate()
  const { onClickEdit, orders, setFormMode, getInitialImport } =
    useCardOrderHomeTableController()
  const { showModal: showRemoverCardModal, setShowModal: setShowRemoveCardModal } =
    useModal()
  const { t } = useTranslation()
  const [orderId, setOrderId] = useState<number>(0)

  return (
    <>
      <OreTableContainer>
        <OreTable>
          <OreTableCaption
            title={t(cardOrderTranslation.cardOrderHome.tableTitle)}
            counter={`${getCardOrderTypeLength(orders)} ${t(
              cardOrderTranslation.cardOrderHome.tableCaption
            )}`}
          />
          <OreTableHead>
            <OreTableRow>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderHome.tableHeadName)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderHome.tableHeadReason)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderHome.tableHeadType)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderHome.tableHeadInitialAmount)}
              </OreTableCell>
              <OreTableCell as="th" size="auto">
                {t(cardOrderTranslation.cardOrderHome.tableHeadBalance)}
                <OreTooltip
                  placement="top"
                  separation="small"
                  text={t(cardOrderTranslation.cardOrderHome.tableHeadBalanceTooltip)}>
                  <span>
                    {' '}
                    <SvgInfo />
                  </span>
                </OreTooltip>
              </OreTableCell>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderHome.tableHeadDeliverySite)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderHome.tableHeadActions)}
              </OreTableCell>
            </OreTableRow>
          </OreTableHead>
          <OreTableBody>
            {orders
              .filter(order => order.orderTypeId === orderType.Card)
              .map(order => (
                <React.Fragment key={order.orderId}>
                  {order.orderTypeId !== orderType.Recharge && (
                    <OreTableRow>
                      {
                        <>
                          <OreTableCell as="td">
                            <OreText size="text-sm" tone="neutral-900">
                              <b>
                                {order.employeeData.name +
                                  ' ' +
                                  order.employeeData.firstSurname +
                                  ' ' +
                                  order.employeeData.secondSurname}
                              </b>
                            </OreText>
                            <OreText size="text-sm">
                              {order.employeeData.document}
                            </OreText>
                            <OreText size="text-sm">{order.employeeData.email}</OreText>
                          </OreTableCell>
                          <OreTableCell as="td">
                            {order.cardData.cardRequestTypeId ===
                              cardRequestTypeId.newPlastic ||
                            order.cardData.cardRequestTypeId ===
                              cardRequestTypeId.newPlasticless ? (
                              <OrePill icon={<SvgCardNew />} tone="cobalt-600">
                                {t(
                                  getNewCardReasonByCardRequestTypeId(
                                    order.cardData.cardRequestTypeId
                                  )
                                )}
                              </OrePill>
                            ) : (
                              <OrePill icon={<SvgCardDuplicate />} tone="grey">
                                {t(
                                  getNewCardReasonByCardRequestTypeId(
                                    order.cardData.cardRequestTypeId
                                      ? order.cardData.cardRequestTypeId
                                      : 0
                                  )
                                )}
                              </OrePill>
                            )}
                          </OreTableCell>
                          <OreTableCell as="td">
                            {order.cardData.cardTypeId === cardType.Plastic &&
                              t(cardOrderFormTranslation.cardDetail.physical)}
                            {order.cardData.cardTypeId === cardType.Virtual &&
                              t(cardOrderFormTranslation.cardDetail.virtual)}
                          </OreTableCell>
                          <OreTableCell as="td">
                            {order.cardData.initialAmount
                              ? addCurrency(order.cardData.initialAmount)
                              : getInitialImport(order.cardData.balance)}
                          </OreTableCell>
                          <OreTableCell as="td">
                            {order.cardData.balance
                              ? order.cardData.balance
                              : emptyValues.none}
                          </OreTableCell>

                          <OreTableCell align="left" as="td" size="auto">
                            {order.sendData && (
                              <OreStack space="small" direction="row" placeItems="center">
                                {/* icon */}
                                <div>
                                  {order.sendData.deliveryTypeId ===
                                    deliveryType.messenger && (
                                    <OreIcon
                                      size="extra-small"
                                      icon={<SvgMessengerService />}
                                      tone="generic-dark"
                                    />
                                  )}
                                  {order.sendData.deliveryTypeId ===
                                    deliveryType.mail && (
                                    <OreIcon
                                      size="extra-small"
                                      icon={<SvgPostalMail />}
                                      tone="postal-service"
                                    />
                                  )}
                                </div>
                                <div>
                                  <OreText size="text-sm" tone="neutral-600">
                                    {`${order.sendData.alias} - ${order.sendData.name}`}
                                  </OreText>
                                  <OreText size="text-sm" tone="neutral-600">
                                    {order.sendData.contactName}
                                  </OreText>
                                </div>
                              </OreStack>
                            )}
                          </OreTableCell>

                          <OreTableCell>
                            <OreButton
                              as="button"
                              category="tertiary"
                              onClick={() => {
                                setOrderId(order.orderId)
                                setShowRemoveCardModal(true)
                              }}
                              size="large">
                              {<SvgBin />}
                            </OreButton>
                            <OreButton
                              as="button"
                              category="tertiary"
                              onClick={event => onClickEdit(event, order)}
                              size="large">
                              {<SvgEdit />}
                            </OreButton>
                          </OreTableCell>
                        </>
                      }
                    </OreTableRow>
                  )}
                </React.Fragment>
              ))}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <div className="flex flex--row float-right">
            <OreButton
              as="a"
              category="highlight"
              onClick={() => {
                setFormMode({ editMode: false })
                navigate(navigationRoutes.cardOrderNew)
              }}
              icon={<SvgPlus />}
              size="large">
              {t(cardOrderTranslation.cardOrderHome.addNewLink)}
            </OreButton>
            <OreButton
              as="a"
              category="highlight"
              onClick={() => {
                navigate(navigationRoutes.duplicatedCard)
              }}
              icon={<SvgPlus />}
              size="large">
              {t(cardOrderTranslation.cardOrderHome.addDuplicateLink)}
            </OreButton>
          </div>
        </OreTableActions>
      </OreTableContainer>
      {showRemoverCardModal && (
        <CardOrderHomeRemoveModal
          open={showRemoverCardModal}
          handleOnClose={setShowRemoveCardModal}
          orderId={orderId}
        />
      )}
    </>
  )
}
