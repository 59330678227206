import { useLeaveOrderPrompt } from './hooks'
import { Modal } from '../../Modal'

export const LeaveOrderPrompt = (): JSX.Element => {
  const { showPrompt, setShow, title, description, buttons, handleCancelNavigation } =
    useLeaveOrderPrompt()
  return (
    <Modal
      handleOnClose={setShow}
      open={showPrompt}
      title={title}
      description={description}
      buttons={buttons}
      closeIcon={false}
      customCloseIcon={true}
      handleCustomCloseIcon={handleCancelNavigation}
    />
  )
}
