import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { spendingRuleService } from 'src/core/services'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { SpendingRuleModel } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

interface ControllerState {
  spendingRule: SpendingRuleModel | undefined
}

export const useSpendingRulesEdit = (): ControllerState => {
  const { id } = useParams()
  const { startLoading, stopLoading } = useLoader()
  const [spendingRule, setSpendingRule] = useState<SpendingRuleModel>()
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const navigate = useNavigate()

  const fetchSpendingRule = async (): Promise<void> => {
    if (id) {
      startLoading()
      const { meta, data } = await spendingRuleService().getById(+id)
      stopLoading()

      // Handle API response
      if (meta.status === MetaStatusCodes.SUCCESS) {
        setSpendingRule(data)
      } else {
        addNotification(t(forms.errors.wrongUrl), NotificationSeverity.error)
        navigate(navigationRoutes.spendingRules)
      }
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      navigate(navigationRoutes.spendingRules)
    }
  }

  useEffect(() => {
    fetchSpendingRule()
  }, [id])

  return {
    spendingRule,
  }
}
