import React from 'react'
import { useTGDBasicOrderFormEditController } from './hooks/useTGDBasicOrderFormEditController'
import { TGDBasicOrderForm } from './organism/TGDBasicOrderForm'

export const TGDBasicOrderEditEmployeeAndChildPage = (): JSX.Element => {
  const { tgdOrderItemToEdit } = useTGDBasicOrderFormEditController()

  if (!tgdOrderItemToEdit) {
    return <></>
  }

  return <TGDBasicOrderForm tgdOrderItemToEdit={tgdOrderItemToEdit} />
}
