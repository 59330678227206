import { EmptyTableImage } from 'src/presentation/components/Edenred/EmptyTableImage'
import { myOrders } from '../../../../../domain/translations'
import { useIncompleteOrders } from '../../../cardOrder/myOrders/incompleteOrders/hooks/useIncompleteOrders'
import { Modal, Table } from '../../../Edenred'
import { useIncompleteOrderClientHome } from '../hooks/useIncompleteOrderClientHome'
import IncompleteOrder from '../../../../assets/img/IncompleteOrder.svg'
import { OreHeading, OreText } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'

export const IncompleteOrderClientHome = (): JSX.Element => {
  const {
    rows,
    setRows,
    caption,
    tableEmpty,
    setShowPopupDelete,
    popupDeleteShow,
    popupDeleteButtons,
  } = useIncompleteOrders({
    pageSize: 3,
    showFilter: false,
    showAllTotalRow: false,
    isExtended: false,
  })
  const { footer } = useIncompleteOrderClientHome()
  const { t } = useTranslation()
  if (rows.length > 0)
    return (
      <>
        <Table
          caption={caption}
          rows={rows}
          setRows={setRows}
          footer={footer}
          confirmDelete={true}
          confirmDeleteTitle={t(myOrders.incompleteOrders.modal.delete.title)}
          confirmDeleteDescription={t(myOrders.incompleteOrders.modal.delete.description)}
          isDeleteDanger={true}
          tableBodyValign="middle"
          tableEmpty={tableEmpty}
        />
        <Modal
          handleOnClose={setShowPopupDelete}
          open={popupDeleteShow}
          buttons={popupDeleteButtons}
          closeIcon={false}>
          <>
            <OreHeading size="headline-lg">
              {t(myOrders.incompleteOrders.modal.delete.title)}
            </OreHeading>
            <OreText align="left" as="p" size="text-md" tone="neutral">
              {t(myOrders.incompleteOrders.modal.delete.description)}
            </OreText>
          </>
        </Modal>
      </>
    )
  else
    return (
      <EmptyTableImage
        image={IncompleteOrder}
        description={t(myOrders.incompleteOrders.table.empty.description)}
        title={caption.title}
        subtitle={t(myOrders.incompleteOrders.table.empty.subtitle)}
      />
    )
}
