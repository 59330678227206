import { OreNavBarItemParent } from '@runroom/oreneta'
import { Dispatch, Fragment, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useUI } from '../../../../../context/ui/UIProvider'
import { SidebarLink, computeDisabled } from '../atoms'
import type { SidebarItemModel } from '../Sidebar'
import { useUserData } from 'src/Flex/User/ui/context'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface SidebarParentLinkProps {
  title: string
  icon: JSX.Element
  hover: boolean
  childs: SidebarItemModel[]
  isFlex?: boolean
  index: number
  currentParentOpen: number
  setCurrentParentOpen: Dispatch<SetStateAction<number>>
  toggleParent: boolean
  setToggleParent: Dispatch<SetStateAction<boolean>>
  // protectedBy?: string
}

const SidebarParentLink = (props: SidebarParentLinkProps): JSX.Element => {
  const { sidebarCollapsed } = useUI()
  const { t } = useTranslation()
  const { permissionTags } = useAuth()

  const { flexData } = useUserData()

  const handleClickParent = (): void => {
    props.setCurrentParentOpen(prevState => {
      if (props.index === prevState) {
        props.setToggleParent(!props.toggleParent)
      } else {
        props.setToggleParent(true) // Allow open when click a different parent
      }

      return props.index
    })
  }

  return (
    <OreNavBarItemParent
      // active={true}
      title={t(props.title)}
      icon={props.icon}
      open={props.currentParentOpen === props.index && props.toggleParent}
      handleClick={handleClickParent}
      collapse={sidebarCollapsed}
      hover={props.hover}
      disabled={
        props.isFlex
          ? computeDisabled(
              flexData,
              props.childs.map(p => p.link || '')
            )
          : false
      }>
      {props.childs.map((childItem: SidebarItemModel, index: number): JSX.Element => {
        const { protectedBy } = childItem

        if (protectedBy && !permissionTags.includes(protectedBy)) {
          return <Fragment key={index}></Fragment>
        }

        return (
          <SidebarLink
            title={t(childItem.title || '')}
            link={childItem.link}
            hover={props.hover}
            key={index}
            pillText={childItem.pillText}
            activeLinks={childItem.activeLinks}
          />
        )
      })}
    </OreNavBarItemParent>
  )
}

export default SidebarParentLink
