import {
  OreCardTitle,
  OreHeading,
  OreText,
  OreTooltip,
  SvgCardhand,
  SvgInfo,
  SvgTransport,
} from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { employeesTranslation } from '../../../../../../domain/translations/cardOrder/employees'
import { Grid } from '../../../../Edenred/layout'
import { useEmployeeProfileController } from '../hooks'
import { addCurrencyPPS } from '../../../../../../core/services/stringService'
import { EmployeesWithLastCard } from 'src/domain/models'

interface EmployeeProfileCardDataTTProps {
  employeeData: EmployeesWithLastCard
}

export const EmployeeProfileCardDataTT = ({
  employeeData,
}: EmployeeProfileCardDataTTProps): JSX.Element => {
  const { getCardType } = useEmployeeProfileController(employeeData)
  const { t } = useTranslation()

  if (!employeeData) {
    return <></>
  }

  return (
    <Grid justify="space-between" items="center">
      <OreCardTitle
        icon={<SvgTransport />}
        subtitle={getCardType()}
        subtitleIcon={<SvgCardhand />}
        title={t(employeesTranslation.profile.card.transportTicked)}
        tone="transport"
      />
      <Grid direction="column" items="flex-end">
        <Grid items="center" className="mb-025">
          <OreText bold={true} tone="neutral-600">
            {t(employeesTranslation.profile.card.balance)}
          </OreText>
          <OreTooltip
            text={t(employeesTranslation.profile.card.balanceTooltipText)}
            placement="top">
            <div className="ml-05">
              <SvgInfo aria-label="info" />
            </div>
          </OreTooltip>
        </Grid>
        <OreHeading size="headline-lg">
          {addCurrencyPPS(employeeData.balance, employeeData.isPPSBalanceRetrieved)}
        </OreHeading>
      </Grid>
    </Grid>
  )
}
