import { OreButton, OreInput, OreSwitch } from '@runroom/oreneta'
import { useState, KeyboardEvent } from 'react'
import { forms, unloadTranslation } from 'src/domain/translations'
import { UnloadEmployeesState } from '../hooks/useUnloadEmployees'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  unloadEmpoyeesProps: UnloadEmployeesState
}

export const UnloadEmployeesCaption = ({ unloadEmpoyeesProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { search, showSelected, setShowSelected, employeesSelected, setPage } =
    unloadEmpoyeesProps
  const [filter, setFilter] = useState<string>('')

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      search(filter)
    }
  }

  return (
    <div className="bg-white px-2 py-1">
      <Grid justify="space-between" items="center" gap="1rem">
        <Grid items="center" gap=".5rem" className="width-50">
          <OreInput
            placeholder={t(unloadTranslation.searchTitle)}
            onChange={event => setFilter(event.target.value)}
            value={filter}
            type="search"
            onKeyUp={e => onEnter(e)}
          />
          <OreButton
            as="button"
            category="primary"
            onClick={() => {
              search(filter)
            }}
            size="small">
            {t(forms.buttons.search)}
          </OreButton>
        </Grid>
        <div className="flex space-between items-center gap-05">
          <OreSwitch
            label={t(unloadTranslation.showSelected)}
            name="showSelected"
            onChange={event => {
              setShowSelected(event.target.checked)
              setPage(1)
              if (!event.target.checked) search(filter)
            }}
            checked={showSelected}
            disabled={employeesSelected.length <= 0}
          />
        </div>
      </Grid>
    </div>
  )
}
