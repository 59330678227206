import axios from 'axios'
import { GATEWAY_URL } from '../../config/constants/gateway'
import type { HttpMethod } from '../../domain/protocols'
import { HttpStatusCode } from '../../domain/protocols'
import { loginSiteUrl } from '../../config/constants/endpoints'
import { browserStorageKeys } from '../../domain/enum'
import i18n, { LOCALE_TYPES, LocaleTypes } from 'src/presentation/i18n/i18n'

export const getGatewayConfigClean = axios.create({
  baseURL: GATEWAY_URL,
})

const isDevelopment = process.env.NODE_ENV === 'development'

export const axiosFetch = async (url: string, method: HttpMethod, values?: object) => {
  try {
    const { data } = await getGatewayConfigClean({
      url: url,
      method: method,
      data: values,
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem(browserStorageKeys.token),
        'Accept-Language': LOCALE_TYPES[i18n.languages[0] as keyof LocaleTypes],
      },
    })
    return data
  } catch (err: any) {
    if (
      !isDevelopment &&
      HttpStatusCode.unauthorized === err.response.status &&
      loginSiteUrl !== ''
    ) {
      window.location.href = loginSiteUrl
    }
    return err.response.data
  }
}
