import type {
  GeneralTermsContactsModel,
  GeneralTermsModel,
} from '../models/generalTerms/GeneralTermsModel'

import { GetDateFormatted } from '../../core/helpers'
import { getUndefinedDashString } from '../../core/services'

export const generalTermsAdapter = (response: GeneralTermsModel): GeneralTermsModel => {
  const contacts: GeneralTermsContactsModel[] = []
  if (response.contacts) {
    response.contacts.forEach((contact: GeneralTermsContactsModel) =>
      contacts.push({
        email: getUndefinedDashString(contact.email),
        name: getUndefinedDashString(contact.name),
        roleName: getUndefinedDashString(contact.roleName),
        phone: getUndefinedDashString(contact.phone),
        roleCode: contact.roleCode,
      })
    )
  }
  return {
    clientCode: response.clientCode,
    invoiceComment: getUndefinedDashString(response.invoiceComment),
    isElectronicInvoice: response.isElectronicInvoice,
    paymentMode: response.paymentMode,
    paymentModeName: getUndefinedDashString(response.paymentModeName),
    paymentTermCode: response.paymentTermCode,
    paymentTermName: getUndefinedDashString(response.paymentTermName),
    productCode: response.productCode,
    totalUsers: response.totalUsers,
    contractOpenDate: getUndefinedDashString(GetDateFormatted(response.contractOpenDate)),
    contractClosedDate: response.contractClosedDate
      ? GetDateFormatted(response.contractClosedDate)
      : getUndefinedDashString(response.contractClosedDate),
    contacts: contacts,
    services: response.services,
  } as GeneralTermsModel
}
