import { OreInput } from '@runroom/oreneta'
import type { Dispatch} from 'react';
import { memo } from 'react'
import type {
  CellControlModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { useCellTextBox } from '../../hooks/table/useCellTextBox'

export const CellTextBox = memo(
  (props: {
    textBox: CellControlModel
    rowIndex: number
    cellIndex: number
    setRows: Dispatch<React.SetStateAction<RowModel[]>>
  }): JSX.Element => {
    const { onChangeCellTextBox, IsValidValue, ErrorMessage } = useCellTextBox(
      props.rowIndex,
      props.cellIndex,
      props.setRows
    )
    return (
      <>
        <OreInput
          name={props.textBox.name}
          type={props.textBox.type}
          disabled={props.textBox.disabled}
          maxLength={props.textBox.maxLength}
          defaultValue={props.textBox.defaultValue}
          value={props.textBox.value}
          placeholder={props.textBox.placeHolder}
          onChange={event => onChangeCellTextBox(event)}
          hasError={props.textBox.hasError}
          errorMessage={props.textBox.errorMassage}
          startAdornment={props.textBox.startAdornment}
        />
      </>
    )
  }
)
