import { OreStepper } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export interface StepsForModel {
  steps: string[]
  allowedPaths?: string[]
  stepsByPaths?: StepByPathsModel[]
}

export interface StepByPathsModel {
  step: string
  paths: string[]
}

interface StepperProps {
  currentStep?: number
  stepsFor: StepsForModel
  paddingTop?: number
  paddingBottom?: number
  error?: boolean
}

const Stepper = ({
  paddingTop = 30,
  paddingBottom = 30,
  currentStep = 1,
  ...props
}: StepperProps): JSX.Element => {
  const { t } = useTranslation()
  const translatedSteps = props.stepsFor.steps.map(s => t(s))

  const { pathname } = useLocation()

  const getCurrentStep = () => {
    let activeStep = currentStep
    if (props.stepsFor.stepsByPaths) {
      props.stepsFor.stepsByPaths.forEach((stepByPath, i) => {
        if (stepByPath.paths.some(path => pathname === path)) {
          activeStep = i + 1
        }
      })
    }
    return activeStep
  }

  const getAllowedPaths = () => {
    if (props.stepsFor.allowedPaths) {
      return props.stepsFor.allowedPaths
    }
    if (props.stepsFor.stepsByPaths) {
      return props.stepsFor.stepsByPaths.map(stepByPaths => stepByPaths.paths).flat()
    }
    return []
  }

  const match = getAllowedPaths().find(path => pathname === path)

  if ((props.stepsFor.allowedPaths || props.stepsFor.stepsByPaths) && !match) {
    return <></>
  }

  return (
    <div
      style={{
        paddingTop: `${paddingTop}px`,
        paddingBottom: `${paddingBottom}px`,
      }}
    >
      <OreStepper
        activeStep={getCurrentStep()}
        errorStep={props.error ? getCurrentStep() : undefined}
        steps={translatedSteps}
      />
    </div>
  )
}

export default Stepper
