import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { myOrdersTabs } from '../../../../../domain/enum'
import type { MyOrdersTabModel } from '../../../../../domain/models/navigate'

interface MyOrderPageState {
  myOrderTab: myOrdersTabs | undefined
}

export const useMyOrderPageController = (): MyOrderPageState => {
  const { state } = useLocation()
  const [myOrderTab, setMyOrderTab] = useState<myOrdersTabs>()

  useEffect(() => {
    if (state) {
      const tab = state as MyOrdersTabModel
      setMyOrderTab(tab.navigateValue)
    }
  }, [state])

  return { myOrderTab }
}
