import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type {
  CardOrderCompleteSectionModel,
  MessagesModel,
} from '../../../../../domain/models'

import { useUser } from '../../../../context/user/UserProvider'
import type { CustomState } from '../CardOrderCompletePage'
import type { UserContract } from '../../../../../domain/models'
import { edenredProducts } from '../../../../../domain/enum'
import { PaymentModes } from '../../../../../domain/enum/PaymentModes'
import {
  CardOrderCompleteContext,
  TransferSuccessStrategy,
  DirectDebitSuccessStrategy,
  FailStrategy,
} from '../../../../../core/strategies'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table'
import { Seq } from 'immutable'
import { OrePill, SvgAlert } from '@runroom/oreneta'
import { cardOrderTranslation } from '../../../../../domain/translations'
import { useTranslation } from 'react-i18next'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'

export interface ContainerState {
  sections: CardOrderCompleteSectionModel | undefined
  rows: RowModel[]
  header: HeaderModel
  caption: CaptionModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
}

export const useContainerController = (
  orderCompleteData: CustomState
): ContainerState => {
  const [sections, setSections] = useState<CardOrderCompleteSectionModel>()
  const [contract, setContract] = useState<UserContract>()
  const [userName, setUserName] = useState<string>()
  const [rows, setRows] = useState<RowModel[]>([])
  const { t } = useTranslation()
  const { isOrderExcel } = useCardOrder()
  const { contracts, company } = useUser()

  const GetSections = (
    orderCompleteData: CustomState,
    paymentMode: number,
    invoiceBillingType: string,
    userName?: string
  ) => {
    if (orderCompleteData.success) {
      if (paymentMode === Number(PaymentModes.wireTransfer)) {
        const section: CardOrderCompleteContext = new CardOrderCompleteContext(
          new TransferSuccessStrategy()
        )
        setSections(
          section.GetSections(orderCompleteData.data, invoiceBillingType, userName)
        )
      }
      if (paymentMode === Number(PaymentModes.directDebit)) {
        const section: CardOrderCompleteContext = new CardOrderCompleteContext(
          new DirectDebitSuccessStrategy()
        )
        setSections(
          section.GetSections(orderCompleteData.data, invoiceBillingType, userName)
        )
      }
    } else {
      const section: CardOrderCompleteContext = new CardOrderCompleteContext(
        new FailStrategy()
      )
      setSections(section.GetSections(orderCompleteData.data, invoiceBillingType))
    }
  }

  const caption: CaptionModel = {
    description: t(cardOrderTranslation.cardOrderComplete.table.total),
    title: t(cardOrderTranslation.cardOrderComplete.table.title),
  }

  const header: HeaderModel = {
    headerLabel: [
      { label: '#' },
      { label: t(cardOrderTranslation.cardOrderComplete.table.errorType), size: 'third' },
      { label: t(cardOrderTranslation.cardOrderComplete.table.errorDocument) },
      { label: t(cardOrderTranslation.cardOrderComplete.table.errorDescription) },
    ],
  }

  const GetRows = () => {
    const errorRows: RowModel[] = []
    Seq(orderCompleteData.errorMessage).forEach(
      (message: MessagesModel, index: number) => {
        const errorRow: RowModel = {
          rowId: index + 1,
          cells: [
            {
              type: 'text',
              cellText: [
                {
                  name: 'errorId',
                  text: isOrderExcel() ? message.value : (index + 1).toString(),
                },
              ],
            },
            {
              type: 'element',
              cellElement: (
                <OrePill icon={<SvgAlert />} tone={'error-600'}>
                  {t(cardOrderTranslation.cardOrderComplete.table.errorNeedFix)}
                </OrePill>
              ),
            },
            {
              type: 'text',
              cellText: [{ name: 'document', text: JSON.parse(message.value).document }],
            },
            {
              type: 'text',
              cellText: [{ name: 'description', text: message.description }],
            },
          ],
        }
        errorRows.push(errorRow)
      }
    )
    setRows(errorRows)
  }

  useEffect(() => {
    if (orderCompleteData && contract) {
      GetSections(
        orderCompleteData,
        contract.paymentMode,
        contract.invoiceBillingType,
        userName
      )
      GetRows()
    }
  }, [orderCompleteData, contract])

  useEffect(() => {
    if (contracts && contracts.length > 0)
      setContract(
        contracts?.find(
          contract => contract.productCode === edenredProducts.ticketRestaurant
        )
      )
  }, [contracts])
  useEffect(() => {
    if (company) setUserName(company.name)
  }, [company])
  return { sections, rows, header, caption, setRows }
}
