export const unloadsConfigurationTranslations = {
  title: 'UnloadsConfiguration.title',
  warningMessage: 'UnloadsConfiguration.warningMessage',
  table: {
    caption: {
      title: 'UnloadsConfiguration.table.caption.title',
      description: 'UnloadsConfiguration.table.caption.description',
    },

    header: {
      name: 'UnloadsConfiguration.table.header.name',
      amount: 'UnloadsConfiguration.table.headers.amount',
      amountTooltip: 'UnloadsConfiguration.table.headers.amountTooltip',
      unload: 'UnloadsConfiguration.table.headers.unload',
      unloadTooltip: 'UnloadsConfiguration.table.headers.unloadTooltip',
      costs: 'UnloadsConfiguration.table.headers.costs',
    },
    rows: {
      unload: {
        placeHolder: 'UnloadsConfiguration.table.rows.unload.placeHolder',
        validation: {
          amount: 'UnloadsConfiguration.table.rows.unload.validation.amount',
          numeric: 'UnloadsConfiguration.table.rows.unload.validation.numeric',
        },
      },
      costs: 'UnloadsConfiguration.table.rows.costs.placeHolder',
    },
    actions: {
      unloadAllBalance: 'UnloadsConfiguration.table.actions.unloadAllBalance',
    },
    footer: {
      action: 'UnloadsConfiguration.table.footer.action',
      unloadAllFromEmployees: 'UnloadsConfiguration.table.footer.unloadAllFromEmployees',
    },
    comboBoxFields: {
      default: 'duplicatesConfiguration.table.option.default',
      reson: {
        optionOne: 'duplicatesConfiguration.table.reason.one',
        optionTwo: 'duplicatesConfiguration.table.reason.two',
      },
      type: {
        optionOne: 'duplicatesConfiguration.table.type.one',
        optionTwo: 'duplicatesConfiguration.table.type.two',
      },
    },
  },
  selector: {
    title: 'duplicatesConfiguration.selector.title',
    description: 'duplicatesConfiguration.selector.description',
    button: 'duplicatesConfiguration.selector.button',
  },
  modal: {
    title: 'duplicatesConfiguration.modal.title',
    description: 'duplicatesConfiguration.modal.description',
  },
  tableEmpty: {
    first: 'UnloadsConfiguration.tableEmpty.first',
    link: 'UnloadsConfiguration.tableEmpty.link',
    end: 'UnloadsConfiguration.tableEmpty.end',
  },
}
