import { OreFormGroup, OreRadioButton, OreTooltip } from '@runroom/oreneta'
import type { Dispatch, SetStateAction } from 'react';
import { Fragment } from 'react'
import type {
  FilterControlModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'
import { useFilterRadioButton } from '../../hooks/table/useFilterRadioButton'
import { Seq } from 'immutable'
import type { RadioButtonModel } from '../../../../../domain/customComponents/RadioButtonModel'

export const FilterRadioButton = (props: {
  control: FilterControlModel
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
}): JSX.Element => {
  const { onChange } = useFilterRadioButton(props.setFilter)
  if (!props.control.radioButtons) return <></>
  return (
    <OreFormGroup direction="row" label={props.control.label} required={false}>
      <div className="flex flex--row">
        {Seq(props.control.radioButtons).map((radio: RadioButtonModel, index: number) => {
          return (
            <Fragment key={'fragment_' + index}>
              <OreRadioButton
                name={props.control.name}
                label={radio.label}
                value={radio.value}
                onChange={event => onChange(event, props.control.name)}
                checked={props.control.value?.toString() === radio.value}
              />
              {radio.tooltip ? (
                <OreTooltip
                  text={radio.tooltipText ? radio.tooltipText : ''}
                  placement="top">
                  <span>{radio.tooltipIcon}</span>
                </OreTooltip>
              ) : (
                <></>
              )}
            </Fragment>
          )
        })}
      </div>
    </OreFormGroup>
    // <RadioButton
    //   name={props.control.control.name}
    //   label={props.control.control.label ? props.control.control.label : ''}
    //   direction="row"
    //   required={false}
    //   radioButtons={props.control.control.radioButtons ? props.control.control.radioButtons : []}
    //   onValueChange={event => onChange(event, props.control.control.name)}
    //   selectedValue={props.control.control.value}
    // />
  )
}
