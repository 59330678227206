import { OreButton } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { forms } from '../../../../../domain/translations'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import Divider from '../../../../layout/Divider'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'

export const CardOrderHomeFooter = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Divider />
      <div className="mt-2">
        <div className="flex flex--row float-right">
          <OreButton
            as="button"
            category="secondary"
            onClick={() => {
              navigate(navigationRoutes.myOrders, {
                state: {
                  cancelModal: true,
                },
              })
            }}
            size="large">
            {t(forms.buttons.cancel)}
          </OreButton>
          <OreButton as="button" category="primary" size="large" disabled>
            {t(cardOrderTranslation.cardOrderHome.buttonOrder)}
          </OreButton>
        </div>
      </div>
    </>
  )
}
