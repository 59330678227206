import type { Dispatch} from 'react';
import { useCallback } from 'react'
import type { RowModel } from '../../../../../domain/customComponents/table/TableModel'

interface CellSelectionState {
  onChangeCellSelection: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const useCellSelection = (
  rowIndex: number,
  rowId: number,
  setSelectedRows: Dispatch<React.SetStateAction<number[]>>,
  setRows: Dispatch<React.SetStateAction<RowModel[]>>
): CellSelectionState => {
  const onChangeCellSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rowSelected: boolean = event.target.checked
      // add selected rows
      if (rowSelected) setSelectedRows(prevSelectedRows => [...prevSelectedRows, rowId])
      else
        setSelectedRows(prevSelectedRows => [
          ...prevSelectedRows.filter(prevRowId => prevRowId !== rowId),
        ])

      // onChange event
      setRows(prevRows =>
        prevRows.map((prevRow: RowModel, prevRowIndex: number) => {
          if (prevRowIndex === rowIndex)
            return {
              ...prevRow,
              isSelected: rowSelected,
            }
          return prevRow
        })
      )
    },
    []
  )

  return { onChangeCellSelection }
}
