export enum edenredProducts {
  ticketGuarderia = 7,
  ticketTransporte = 16,
  ticketRestaurant = 19,
  saludExterno = 23,
  formacion = 98,
  salud = 99,
}

export enum edenredProductsTabs {
  ticketRestaurant = 0,
  ticketTransporte = 1,
  ticketGuarderia = 2,
}

export enum edenredSubProducts {
  dieta = 23,
  guarderia = 7,
  guarderiaPlus = 21,
}
