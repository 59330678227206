import { axiosFetch } from '../../axios'
import type { DeliverySiteRepositoryContract } from '../../../core/contracts/deliverySites/DeliverySitesRepositoryContract'
import type {
  DeliverySiteDeleteDTO,
  DeliverySiteExcelDTO,
  DeliverySiteExcelUploadDTO,
  DeliverySiteGetAllDTO,
  DeliverySiteGetByIdDTO,
  DeliverySiteResponse,
  DeliverySiteModel,
  DeliverySiteNewEditDTO,
  DeliverySiteUpdateFavoriteDTO,
  LastDeliverySiteTypeDTO,
} from '../../../domain/models/deliverySites'
import {
  createDeliverySite,
  createOrderExcel,
  indexDeliveryPoints,
  indexOrderTemplate,
  removeDeliveryPoint,
  singleByLastDeliveryTypeProductCode,
  singleDeliveryPointById,
  updateDeliverySite,
  updateFavoriteDeliveryPoint,
} from '../../../config/constants/endpoints'
import type {
  DeliverySiteDeleteProtocol,
  DeliverySiteGetExcelProtocol,
  DeliverySiteProtocol,
  DeliverySiteUpdateFavoriteProtocol,
  DeliverySiteUploadExcelProtocol,
  HttpRequest,
} from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { deliverySiteGetByIdAdapter } from '../../../domain/adapters'
import type { HttpModel } from 'src/domain/models'
import { createQueryString } from 'src/core/helpers'

export default class DeliverySiteRepository implements DeliverySiteRepositoryContract {
  async add(deliverySite: DeliverySiteModel): Promise<DeliverySiteNewEditDTO> {
    const { url, method, body }: HttpRequest = {
      url: createDeliverySite,
      method: HttpMethod.POST,
      body: { deliveryPoint: deliverySite },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getById(deliverySiteId: number): Promise<DeliverySiteGetByIdDTO> {
    const { url, method, body }: HttpRequest = {
      url: singleDeliveryPointById,
      method: HttpMethod.POST,
      body: { deliveryPointId: deliverySiteId },
    }
    const response: DeliverySiteGetByIdDTO = await axiosFetch(url, method, body)
    const deliverySiteadapter = deliverySiteGetByIdAdapter(response)
    const resAdapted = {
      data: deliverySiteadapter,
      meta: { ...response.meta },
    }
    return resAdapted
  }

  async update(deliverySite: DeliverySiteModel): Promise<DeliverySiteNewEditDTO> {
    const { url, method, body }: HttpRequest = {
      url: updateDeliverySite,
      method: HttpMethod.POST,
      body: { deliveryPoint: deliverySite },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getByLastDeliveryType(productCode: number): Promise<LastDeliverySiteTypeDTO> {
    const { url, method }: HttpRequest = {
      url: singleByLastDeliveryTypeProductCode + productCode,
      method: HttpMethod.GET,
    }
    return await axiosFetch(url, method)
  }

  async getAll(
    deliverySiteRequestProtocol: DeliverySiteProtocol,
    isFlex = false
  ): Promise<DeliverySiteGetAllDTO> {
    const { url, method }: HttpRequest = {
      url: `${indexDeliveryPoints}${
        !isFlex ? '?productCode=' + deliverySiteRequestProtocol.productCode : ''
      }${!isFlex ? '&companyAdress=' : '?companyAdress='}${
        deliverySiteRequestProtocol.companyAdress
      }&particularAdress=${deliverySiteRequestProtocol.particularAdress}&otherAdress=${
        deliverySiteRequestProtocol.otherAdress
      }`,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method)

    return response
  }

  async getAllQuery(
    filters: DeliverySiteProtocol
  ): Promise<HttpModel<DeliverySiteResponse>> {
    const { url, method, body }: HttpRequest = {
      url: indexDeliveryPoints,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async delete(
    deliverySiteDelete: DeliverySiteDeleteProtocol
  ): Promise<DeliverySiteDeleteDTO> {
    const { url, method, body }: HttpRequest = {
      url: removeDeliveryPoint,
      method: HttpMethod.POST,
      body: deliverySiteDelete,
    }
    const response = await axiosFetch(url, method, body)

    return response
  }

  async updateFavorite(
    deliverySiteUpdateFavorite: DeliverySiteUpdateFavoriteProtocol
  ): Promise<DeliverySiteUpdateFavoriteDTO> {
    const { url, method, body }: HttpRequest = {
      url: updateFavoriteDeliveryPoint,
      method: HttpMethod.POST,
      body: deliverySiteUpdateFavorite,
    }
    const response = await axiosFetch(url, method, body)

    return response
  }

  async getExcel(
    deliverySiteExcel: DeliverySiteGetExcelProtocol
  ): Promise<DeliverySiteExcelDTO> {
    const { url, method, body }: HttpRequest = {
      url: indexOrderTemplate,
      method: HttpMethod.POST,
      body: deliverySiteExcel,
    }
    const response = await axiosFetch(url, method, body)

    return response
  }

  async addExcel(
    uploadDeliverySiteExcel: DeliverySiteUploadExcelProtocol
  ): Promise<DeliverySiteExcelUploadDTO> {
    const { url, method, body }: HttpRequest = {
      url: createOrderExcel,
      method: HttpMethod.POST,
      body: uploadDeliverySiteExcel,
    }
    const response = await axiosFetch(url, method, body)

    return response
  }
}
