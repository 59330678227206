import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { CardOrderCompleteModel } from '../../../../domain/models/cardOrder/CardOrderCompleteModel'
import ErrorLogo from '../../../assets/img/Warning.svg'
import SuccessLogo from '../../../assets/img/Success.svg'
import { SimpleComplete } from '../../../Templates/SimpleComplete'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation } from '../../../../domain/translations/cardOrder/cardOrder'
import { useCardOrder } from '../../../context/cardOrder/CardOrderProvider'
import type { MessagesModel } from '../../../../domain/models'
import { Container } from './organism/Container'
import { orderType } from 'src/domain/enum'

export interface CustomState {
  success: boolean
  errorMessage: MessagesModel[]
  data: CardOrderCompleteModel
  excel: string
}

export const CardOrderCompletePage = () => {
  const location = useLocation()
  const orderCompleteData = location.state as CustomState
  const { t } = useTranslation()
  const { clearAll, orderContext } = useCardOrder()

  const getHeading = (): string => {
    let heading = ''
    switch (orderContext.orderType) {
      case orderType.Card:
        heading = t(cardOrderTranslation.cardOrderComplete.pageHeadingCard)
        break
      case orderType.Recharge:
        heading = t(cardOrderTranslation.cardOrderComplete.pageHeadingRecharge)
        break
      case orderType.Unload:
        heading = t(cardOrderTranslation.cardOrderComplete.pageHeadingUnload)
        break
    }
    return heading
  }

  useEffect(() => {
    return () => {
      clearAll()
    }
  }, [])

  return (
    <SimpleComplete
      imgSrc={orderCompleteData.success ? SuccessLogo : ErrorLogo}
      title={
        orderCompleteData.success
          ? t(cardOrderTranslation.cardOrderComplete.titleSuccess)
          : t(cardOrderTranslation.cardOrderComplete.titleError)
      }
      heading={getHeading()}
      success={orderCompleteData.success}
      organisms={<Container orderCompleteData={orderCompleteData} />}
    />
  )
}
