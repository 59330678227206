import {
  OreButton,
  OreDivider,
  OreHeading,
  OreStack,
  OreText,
  SvgArrowLeft,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { EmployeesTROrderFormModel } from '../hooks/useUnloadConfigurationEmployees'
import { UseFormReturn } from 'react-hook-form'
import { MessageBar, Modal } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { Dispatch, SetStateAction } from 'react'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { usePreviousURL } from 'src/presentation/components/Edenred/navigation/hooks'
import { queryParam } from 'src/domain/enum'

interface Props {
  tableIsEmpty: boolean
  addConfigToOrder(): void
  form: UseFormReturn<EmployeesTROrderFormModel, any>
  showCancelModal: boolean
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  errorMessage: string
}

export const UnloadConfigurationEmployeesFooterButtons = ({
  tableIsEmpty,
  addConfigToOrder,
  form,
  showCancelModal,
  setShowCancelModal,
  cancelPopupButtons,
  onClickCancel,
  errorMessage,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const isEdit = searchParams.get(queryParam.id)

  return (
    <div className="py-2">
      {errorMessage && (
        <MessageBar color="error" message={errorMessage} className="pb-1" />
      )}
      <OreDivider />
      <div className="py-1">
        <OreStack direction="row" placeContent="space-between" placeItems="normal">
          {!isEdit ? (
            <OreButton
              category="tertiary"
              icon={<SvgArrowLeft />}
              onClick={() => navigate(-1)}>
              {t(forms.buttons.goBack)}
            </OreButton>
          ) : (
            <div></div>
          )}
          <OreStack direction="row" space="medium">
            <OreButton
              as="button"
              category="secondary"
              size="large"
              onClick={isEdit ? () => navigate(-1) : onClickCancel}>
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              size="large"
              disabled={!form.formState.isValid || tableIsEmpty}
              onClick={() => addConfigToOrder()}>
              {t(TGDOrderTranslation.employees.configuration.buttons.addToOrder)}
            </OreButton>
          </OreStack>
        </OreStack>
      </div>
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {t(TGDOrderTranslation.form.child.cancelModalTitle)}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {t(TGDOrderTranslation.form.child.cancelModalDescription)}
          </OreText>
        </>
      </Modal>
    </div>
  )
}
