import { useEffect, useState } from 'react'
import { invoicesTabs } from '../../../../domain/enum'

interface ContainerState {
  handleTabClick: (index: number) => void
  selectedTab: invoicesTabs
}

export const useContainerController = (navigateValue?: invoicesTabs): ContainerState => {
  const [selectedTab, setSelectedTab] = useState(invoicesTabs.invoices)
  const handleTabClick = (index: number): void => setSelectedTab(index)
  useEffect(() => {
    if (navigateValue) setSelectedTab(navigateValue)
  }, [navigateValue])
  return { handleTabClick, selectedTab }
}
