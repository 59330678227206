import { useCardOrder } from '../context/cardOrder/CardOrderProvider'
import { navigationRoutes } from '../../config/constants/navigationRoutes'
import {
  excelCard,
  excelRecharge,
  excelUnload,
  formNewCardsOrDuplicate,
  formRecharge,
  formUnload,
  orderType,
} from '../../domain/enum'
import { useLocation, useNavigate } from 'react-router-dom'
import { browserStorageKeys } from '../../domain/enum/browserStorageKeys'
import { useEffect } from 'react'

// The purpose of this hook is to set and clear order context used via forms and excels within the purchasing process.
// Depending of the current URI in the router, the context will be set to the correct value, and/or clear the context based on start location arrays.
export const useOrderAssistant = () => {
  const { orderContext, clearAll, resetOrderContextAs, clearByOrderType } = useCardOrder()
  const navigate = useNavigate()
  const location = useLocation()

  // Uris that will trigger context clearing.
  const formStartLocation = [
    navigationRoutes.cardOrderNew,
    navigationRoutes.cardOrderDuplicated,
    navigationRoutes.duplicatedCard,
    navigationRoutes.rechargeCard,
    navigationRoutes.unloadCard,
  ]

  // Uris that will trigger a new order process using excel files.
  const excelStartLocation = [
    navigationRoutes.cardOrderNewExcel,
    navigationRoutes.cardOrderRechargeExcel,
    navigationRoutes.cardOrderUnloadExcel,
  ]

  // Uris that requires order context.
  const orderStepWithOrderContextRequired = [
    navigationRoutes.cardOrderExcelAlerts,
    navigationRoutes.cardOrderExcelHome,
    navigationRoutes.orderCheckout,
  ]

  // The purpose of this function  it to handle loses of required order context provoked by a browsers refresh,
  // use of bookmarks,shared links,etc)
  function HandleEmptyContext(pathname: string) {
    if (orderContext.orderType !== orderType.Undefined) return
    // Empty order context
    const redirectRoute = sessionStorage.getItem(browserStorageKeys.orderURIFallback)
    if (redirectRoute !== null) {
      navigate(redirectRoute.toString())
    }
  }

  // Handler to clear contexts
  function HandleFormContext(pathname: string) {
    switch (pathname) {
      case navigationRoutes.cardOrderNew:
        resetOrderContextAs(formNewCardsOrDuplicate)
        clearByOrderType(orderType.Card)
        break
      case navigationRoutes.cardOrderDuplicated:
      case navigationRoutes.duplicatedCard:
        resetOrderContextAs(formNewCardsOrDuplicate)
        clearByOrderType(orderType.Card)
        break
      case navigationRoutes.rechargeCard:
        resetOrderContextAs(formRecharge)
        clearByOrderType(orderType.Recharge)
        break
      case navigationRoutes.unloadCard:
        resetOrderContextAs(formUnload)
        clearByOrderType(orderType.Unload)
        break
    }
  }

  // Will set order context to assist in an order process using excel files.
  function HandleExcelContext(pathname: string) {
    clearAll()
    switch (pathname) {
      case navigationRoutes.cardOrderNewExcel:
        resetOrderContextAs(excelCard) // and store in session
        break
      case navigationRoutes.cardOrderRechargeExcel:
        resetOrderContextAs(excelRecharge) // and store in session
        break
      case navigationRoutes.cardOrderUnloadExcel:
        resetOrderContextAs(excelUnload) // and store in session
        break
    }
  }

  useEffect(() => {
    updateStates(location.pathname)
  }, [location])

  // This function is called from the router component and will check for issues in the order process.
  // Will handle the order context and redirect to the correct page if needed.
  function updateStates(pathname: string) {
    if (formStartLocation.includes(pathname)) {
      HandleFormContext(pathname)
    } else if (excelStartLocation.includes(pathname)) {
      HandleExcelContext(pathname)
    } else if (orderStepWithOrderContextRequired.includes(pathname)) {
      HandleEmptyContext(pathname)
    }
  }

  return {
    updateStates,
  }
}
