import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { accountService } from '../../../../../core/services'
import type { TextBoxRowModel } from '../../../../../domain/customComponents'
import type { PasswordModel } from '../../../../../domain/models/myAccount/PasswordModel'
import { forms } from '../../../../../domain/translations'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useNotification } from '../../../../context/notification/NotificationProvider'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { MessagesModel } from 'src/domain/models'

interface UserDataState {
  changePassword: () => void
  form: UseFormReturn<PasswordModel, any>
  getPasswordForm: () => TextBoxRowModel<PasswordModel>[]
  errorMessage: string
}

export const useChangePasswordController = (): UserDataState => {
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const formSchema = Yup.object({
    oldPassword: Yup.string().required(t(forms.errors.fieldRequired)),
    newPassword: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .notOneOf(
        [Yup.ref('oldPassword'), null],
        t(accountSettingsTranslation.changePassword.newPasswordError)
      ),
    newPasswordRepeat: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .oneOf(
        [Yup.ref('newPassword'), null],
        t(accountSettingsTranslation.changePassword.repeatPasswordError)
      ),
  })

  const form = useForm<PasswordModel>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const changePassword = (): void => {
    setStateErrorMessage('')
    startLoading()
    accountService()
      .changePassword({ ...form.getValues() })
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          // Remove local token. This will force to get a new one from the server
          localStorage.clear()

          addNotification(
            t(accountSettingsTranslation.changePassword.changeSuccess),
            NotificationSeverity.success
          )
        } else {
          if (response?.meta?.messages.length > 0) {
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                form.setError(error.value as any, {
                  type: 'custom',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
        }
      })
      .finally(() => stopLoading())
  }

  const getPasswordForm = (): TextBoxRowModel<PasswordModel>[] => {
    return [
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'oldPassword',
            label: t(accountSettingsTranslation.changePassword.currentPassword),
            name: 'oldPassword',
            required: true,
            type: 'password',
            maxLength: 100,
            autoComplete: 'off',
          },
          {
            id: 'newPassword',
            label: t(accountSettingsTranslation.changePassword.newPassword),
            name: 'newPassword',
            required: true,
            type: 'password',
            maxLength: 100,
            legend: t(accountSettingsTranslation.changePassword.newPasswordLegend),
            autoComplete: 'off',
          },
          {
            id: 'newPasswordRepeat',
            label: t(accountSettingsTranslation.changePassword.repeatPassword),
            name: 'newPasswordRepeat',
            required: true,
            type: 'password',
            maxLength: 100,
            autoComplete: 'off',
          },
        ],
      },
    ]
  }

  return { form, changePassword, getPasswordForm, errorMessage }
}
