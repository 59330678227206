import { Modal } from 'src/presentation/components/Edenred'
import { OreHeading, OreText } from '@runroom/oreneta'
import { TTOrderSaveAndClose } from '../../TTOrderSaveAndClose/TTOrderSaveAndClose'
import {
  TTUnloadSummaryButtons,
  TTUnloadSummaryForm,
  TTUnloadSummaryTable,
} from '../molecules'
import { useTTUnloadSummaryController } from '../hooks/useTTUnloadSummaryController'

export const TTUnloadSummaryContainer = (): JSX.Element => {
  const props = useTTUnloadSummaryController()
  const {
    showDeleteModal,
    setShowDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
  } = props
  return (
    <>
      <TTUnloadSummaryTable ttProps={props} />
      <TTUnloadSummaryForm ttProps={props} />
      <TTUnloadSummaryButtons ttProps={props} />
      <Modal
        handleOnClose={setShowDeleteModal}
        open={showDeleteModal}
        buttons={deletePopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{deleteModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {deleteModalDescription}
          </OreText>
        </>
      </Modal>
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>
      <TTOrderSaveAndClose
        setShowSaveCloseModal={props.setShowSaveCloseModal}
        showSaveCloseModal={props.showSaveCloseModal}
      />
    </>
  )
}
