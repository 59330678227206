import {
  checkoutTGDOrder,
  completeTGDOrder,
  createOrderExcel,
  downloadTGDExcel,
  repeatNurseryAssigmentOrder,
  saveTGDOrder,
  validateTGDOrder,
} from 'src/config/constants/endpoints'
import { createQueryString } from 'src/core/helpers'
import {
  SetTGDRequestBody,
  SetTGDRequestCheckoutBody,
  SetTGDSaveAndCloseBody,
} from 'src/domain/adapters/tgdOrderAdapter'
import { LoadExcelOrder } from 'src/domain/bodyRequest/ExcelOrders'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { browserStorageKeys, edenredProducts, excelRequestType } from 'src/domain/enum'
import { HttpModel } from 'src/domain/models'
import {
  TGDEmployeeAndChildsModel,
  TGDOrderCompleteDTO,
  TGDOrderSummaryForm,
  TGDOrderValidateDTO,
  TGDRepeatOrderResponse,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { HttpMethod, HttpRequest } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'

export class TGDOrderRepository {
  async validate(order: TGDEmployeeAndChildsModel): Promise<TGDOrderValidateDTO> {
    const { url, method, body }: HttpRequest = {
      url: validateTGDOrder,
      method: HttpMethod.POST,
      body: SetTGDRequestBody(order),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async checkout(
    summary: TGDOrderSummaryForm,
    tgdOrders: TGDEmployeeAndChildsModel[]
  ): Promise<CardOrderResumeDTO> {
    const { url, method, body }: HttpRequest = {
      url: checkoutTGDOrder,
      method: HttpMethod.POST,
      body: SetTGDRequestCheckoutBody(summary, tgdOrders),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async saveAndClose(
    orderName: string,
    tgdOrders: TGDEmployeeAndChildsModel[]
  ): Promise<TGDOrderValidateDTO> {
    const preOrderId = sessionStorage.getItem(browserStorageKeys.tgdPreOrderId)
      ? Number(sessionStorage.getItem(browserStorageKeys.tgdPreOrderId))
      : undefined
    sessionStorage.removeItem(browserStorageKeys.tgdPreOrderId)
    const { url, method, body }: HttpRequest = {
      url: saveTGDOrder,
      method: HttpMethod.POST,
      body: SetTGDSaveAndCloseBody(orderName, tgdOrders, preOrderId),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async complete(
    summary: TGDOrderSummaryForm,
    tgdOrders: TGDEmployeeAndChildsModel[]
  ): Promise<TGDOrderCompleteDTO> {
    const { url, method, body }: HttpRequest = {
      url: completeTGDOrder,
      method: HttpMethod.POST,
      body: SetTGDRequestCheckoutBody(summary, tgdOrders),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadTGDExcelTemplate(): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: downloadTGDExcel,
      method: HttpMethod.POST,
      body: {
        orderExcelType: excelRequestType.TgdOrder,
        productType: edenredProducts.ticketGuarderia,
        orderId: 0,
        empty: true,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async completeExcelOrder(loadExcelOrder: LoadExcelOrder): Promise<HttpModel<any>> {
    const { url, method, body }: HttpRequest = {
      url: createOrderExcel,
      method: HttpMethod.POST,
      body: loadExcelOrder,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async repeatAssignmentOrder(orderId: number): Promise<TGDRepeatOrderResponse> {
    const { url, method, body }: HttpRequest = {
      url: repeatNurseryAssigmentOrder,
      method: HttpMethod.GET,
    }

    const response = await axiosFetch(url + orderId, method, body)
    return response
  }
}
