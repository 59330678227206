import {
  OreButton,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { orderValidationsTranslations } from '../orderValidationsTranslations'
import { useTranslation } from 'react-i18next'
import { useOrderValidation } from '../context'

export const OrderValidationEmptyRows = (): JSX.Element => {
  const { t } = useTranslation()
  const { productType, cutoffDate, backToPayroll } = useOrderValidation()
  return (
    <>
      <OreStack placeContent="stretch" space="2xlarge">
        <OreTableContainer>
          <OreTable>
            <OreTableCaption title={t(orderValidationsTranslations.emptyRows.title)} />
            <OreTableBody valign="top">
              <OreTableRow>
                <OreTableCell>
                  <OreText size="text-sm" tone="neutral-600">
                    {t(orderValidationsTranslations.emptyRows.description1)}
                    {t(flexProductsTranslations.ids[productType])}
                    {t(orderValidationsTranslations.emptyRows.description2)}
                  </OreText>
                  <br />
                  {t(orderValidationsTranslations.emptyRows.description3)}
                  <b>{cutoffDate}</b>
                </OreTableCell>
              </OreTableRow>
            </OreTableBody>
          </OreTable>
        </OreTableContainer>
      </OreStack>
      {t(orderValidationsTranslations.emptyRows.payroll_legend)}
      <OreStack placeContent="start">
        <OreButton category="primary" type="submit" onClick={backToPayroll}>
          {t(orderValidationsTranslations.emptyRows.go_payroll)}
        </OreButton>
      </OreStack>
    </>
  )
}
