import React, { useEffect } from 'react'
import { TGDOrderConfigurationEmployeesList } from '../molecules/TGDOrderConfigurationEmployeesList'
import { TGDOrderConfigurationEmployeesFooterButtons } from '../molecules/TGDOrderConfigurationEmployeesFooterButtons'
import { useTGDOrderConfigurationEmployees } from '../hooks/useTGDOrderConfigurationEmployees'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TGDOrderConfigurationToolbar } from '../molecules/TGDOrderConfigurationToolbar'

export const TGDOrderConfigurationEmployeesContainer = () => {
  const props = useTGDOrderConfigurationEmployees()
  const navigate = useNavigate()

  useEffect(() => {
    if (props.employeesTGD && props.employeesTGD.length <= 0)
      navigate(navigationRoutes.tgdOrderSelectMultipleEmployee)
  }, [props.employeesTGD])
  return (
    <div>
      <TGDOrderConfigurationEmployeesList employeesTGDProps={props} />
      <TGDOrderConfigurationEmployeesFooterButtons
        selectedEmployees={props.selectedEmployees}
        tableIsEmpty={props.employeesTGD && props.employeesTGD.length <= 0}
        addConfigToOrder={props.addConfigToOrder}
        form={props.form}
        cancelPopupButtons={props.cancelPopupButtons}
        onClickCancel={props.onClickCancel}
        setShowCancelModal={props.setShowCancelModal}
        showCancelModal={props.showCancelModal}
        errorMessage={props.errorMessage}
      />
      {props.selectedEmployees.length > 1 && (
        <TGDOrderConfigurationToolbar
          selectedEmployees={props.selectedEmployees.length}
          onAssignMultipleValues={props.onAssignMultipleValues}
        />
      )}
    </div>
  )
}
