import { OreButton, OreText } from '@runroom/oreneta'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react';
import { Fragment, memo } from 'react'
import type {
  CellModel,
  RowModel,
  SelectorModel} from '../../../../../domain/customComponents/table/TableModel';


import { useSelector } from '../../hooks/table/useSelector'
import { SelectorComboBox } from '../atoms/SelectorComboBox'
import { SelectorTextBox } from '../atoms/SelectorTextBox'

export const Selector = memo(
  (props: {
    selectedRows: number[]
    selector: SelectorModel
    rows: RowModel[]
    setRows: Dispatch<SetStateAction<RowModel[]>>
  }): JSX.Element => {
    const { selectorState, setSelectorState, onClickSelectorButton } = useSelector(
      props.selector.selectors,
      props.rows,
      props.setRows
    )
    return (
      <div
        className="form-selector"
        style={{
          borderRadius: '1rem',
          background: '#E2E8F0',
          padding: '1rem',
          margin: '1.5rem 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem',
            paddingBottom: '0.5rem',
          }}
        >
          <div>
            <OreText
              bold
              size="text-md"
              style={{ marginBottom: '4px' }}
              tone="neutral-900"
            >
              {props.selector.title}
            </OreText>
          </div>
          <div>
            <OreText size="text-xs">
              {' '}
              {props.selectedRows.length + ' ' + props.selector.description}
            </OreText>
          </div>
        </div>
        <div
          className="form-selector__fields-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.selector.selectors &&
            Seq(props.selector.selectors).map((selector: CellModel, index: number) => (
              <Fragment key={'selector_' + index}>
                {selector.type === 'comboBox' && selector.cellControll && (
                  <SelectorComboBox
                    comboBox={selector.cellControll}
                    selectorState={selectorState}
                    setSelectorState={setSelectorState}
                  />
                )}
                {selector.type === 'textBox' && selector.cellControll && (
                  <SelectorTextBox
                    textBox={selector.cellControll}
                    selectorState={selectorState}
                    setSelectorState={setSelectorState}
                  />
                )}
              </Fragment>
            ))}
          <OreButton
            as="button"
            disabled={
              selectorState.filter(state => state[1] !== '').length > 0
                ? props.selectedRows.length > 0
                  ? false
                  : true
                : true
            }
            onClick={event => onClickSelectorButton(event)}
          >
            {props.selector.buttonTitle}
          </OreButton>
        </div>
      </div>
    )
  }
)
