import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { historyTabs } from '../../../../domain/enum'
import type { IHistoryTabModel } from '../../../../domain/models/navigate/historyTabModel'

interface HistoryPageState {
  historyTab: historyTabs | undefined
}

export const useHistoryPageController = (): HistoryPageState => {
  const { state } = useLocation()
  const [historyTab, setHistoryTab] = useState<historyTabs>()

  useEffect(() => {
    if (state) {
      const tab = state as IHistoryTabModel
      setHistoryTab(tab.navigateValue)
    }
  }, [state])

  return { historyTab }
}
