import { useTranslation } from 'react-i18next'
import {
  dateIsAfterLastDayOfMonthOneYearLater,
  dateValueIsLowerThanToday,
  getDateAfter,
} from 'src/core/helpers'
import { edenredProducts } from 'src/domain/enum'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { useUser } from 'src/presentation/context/user/UserProvider'
import * as Yup from 'yup'

export const useTTUnloadSummarySchema = () => {
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketTransporte
  )

  const TTUnloadSummarySchema = Yup.object({
    billReference: currentContract?.isCommentRequiredFromClient
      ? Yup.string().required(t(forms.errors.fieldRequired))
      : Yup.string().nullable(),
    acceptConditions: Yup.boolean().oneOf([true], t(forms.errors.termsAndConditions)),
    initialChargeDate: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .test('testDate', t(cardOrderTranslation.form.errors.dateBeforeToday), value => {
        const error = value ? dateValueIsLowerThanToday(value) : null
        return !error
      })
      .test(
        'testDateMax',
        () => t(cardOrderTranslation.form.errors.dateBeforOneYear),
        value => {
          if (value) {
            const compareDate = dateIsAfterLastDayOfMonthOneYearLater(value)
            return !compareDate
          }
          return false
        }
      ),
    expiredDate: currentContract?.allowLoadExpiredDate
      ? Yup.string()
          .required(t(forms.errors.fieldRequired))
          .test(
            'testDueDate',
            t(cardOrderTranslation.cardOrderHome.expiredDateError),
            (value, ctx) => {
              const error = value
                ? getDateAfter(value, ctx.parent.initialChargeDate)
                : null
              return !error
            }
          )
      : Yup.string(),
  })

  return { TTUnloadSummarySchema }
}
