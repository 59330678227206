import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction} from 'react';
import { useCallback, useEffect, useState } from 'react'
import type {
  CellModel,
  RowModel} from '../../../../../domain/customComponents/table/TableModel';


import { forms } from '../../../../../domain/translations'

interface SelectorState {
  selectorState: [string, string][]
  setSelectorState: Dispatch<SetStateAction<[string, string][]>>
  onClickSelectorButton: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const useSelector = (
  selectors: CellModel[],
  rows: RowModel[],
  setRows: Dispatch<SetStateAction<RowModel[]>>
): SelectorState => {
  const [selectorState, setSelectorState] = useState<[string, string][]>([])

  const IsValidValue = (value: string | undefined): boolean => {
    if (value === '' || value === undefined) {
      return false
    }

    return true
  }

  const ErrorMessage = (value: string | undefined): string => {
    if (value === '' || value === undefined) {
      return t(forms.errors.fieldRequired)
    }

    return ''
  }

  const SetTableSelector = useCallback(() => {
    const tableSelectorState: [string, string][] = []
    if (selectors) {
      Seq(selectors).forEach((selector: CellModel, index: number) => {
        if (selector.cellControll)
          tableSelectorState.push([selector.cellControll.name, ''])
      })
    }
    setSelectorState(tableSelectorState)
  }, [selectors])

  const onClickSelectorButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      Seq(rows).forEach((row: RowModel, rowIndex: number) => {
        if (row.isSelected === true) {
          Seq(selectorState).forEach(
            (selector: [string, string], selectorIndex: number) => {
              if (selector[1] !== '') {
                const selectedCell = Seq(row.cells)
                  .filter(cell => cell.cellControll?.name === selector[0])
                  .get(0)
                if (selectedCell) {
                  const cellIndex = row.cells.indexOf(selectedCell)
                  setRows(prevRows =>
                    prevRows.map((prevRow: RowModel, prevRowIndex: number) => {
                      if (prevRow.isSelected === true) {
                        return {
                          ...prevRow,
                          cells: prevRow.cells.map(
                            (prevCell: CellModel, prevCellIndex: number) => {
                              if (
                                prevCellIndex === cellIndex &&
                                prevCell.cellControll?.name === selector[0]
                              ) {
                                return {
                                  ...prevCell,
                                  cellControll: {
                                    ...prevCell.cellControll,
                                    value: selector[1],
                                    errorMassage: ErrorMessage(selector[1]),
                                    hasError: !IsValidValue(selector[1]),
                                  },
                                }
                              }
                              return prevCell
                            }
                          ),
                        }
                      }
                      return prevRow
                    })
                  )
                  setSelectorState(prevState =>
                    prevState.map((state, stateIndex) => {
                      if (stateIndex === selectorIndex) {
                        return {
                          ...state,
                          '1': '',
                        }
                      }
                      return state
                    })
                  )
                }
              }
            }
          )
        }
      })
      const newSelectorStates: [string, string][] = []
      Seq(selectorState).forEach(selector => {
        newSelectorStates.push([selector[0], ''])
      })
      setSelectorState(newSelectorStates)
    },
    [selectorState]
  )

  useEffect(() => {
    SetTableSelector()
  }, [selectors])
  return { selectorState, setSelectorState, onClickSelectorButton }
}
