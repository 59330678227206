import { singleGeneralTerms } from '../../../config/constants/endpoints'
import type { HttpModel } from '../../../domain/models'
import type { GeneralTermsModel } from '../../../domain/models/generalTerms/GeneralTermsModel'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../axios'

export class GeneralTermsRepository {
  async GetGeneralTerms(productCode: number): Promise<HttpModel<GeneralTermsModel>> {
    const { url, method, body }: HttpRequest = {
      url: singleGeneralTerms + '?productCode=' + productCode,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
