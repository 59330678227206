import { OreButton, OreDivider } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EmployeesNurseryModel } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  selectedEmployee: EmployeesNurseryModel | undefined
  tableIsEmpty: boolean
  addChild(employee?: EmployeesNurseryModel): void
}

export const TGDSelectSingleEmployeeFooterButtons = ({
  selectedEmployee,
  tableIsEmpty,
  addChild,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="py-2">
      <OreDivider />
      <div className="py-1">
        <Grid justify="flex-end" gap="1.5rem">
          <OreButton
            as="button"
            category="secondary"
            size="large"
            onClick={() => navigate(-1)}>
            {t(forms.buttons.cancel)}
          </OreButton>
          <OreButton
            as="button"
            category="primary"
            size="large"
            disabled={!selectedEmployee || tableIsEmpty}
            onClick={() => addChild(selectedEmployee)}>
            {t(TGDOrderTranslation.summaryAssignment.table.footer.addChild)}
          </OreButton>
        </Grid>
      </div>
    </div>
  )
}
