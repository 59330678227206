import { useTranslation } from 'react-i18next'
import { SimpleForm } from '../../../Templates'
import React from 'react'
import { RechargeEmployees } from './organism'
import { recharge } from 'src/domain/translations'
import { LeaveOrderPrompt } from '../../Edenred/navigation'

export const RechargeEmployeesPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <SimpleForm title={t(recharge.title)} organisms={[<RechargeEmployees />]} />
      <LeaveOrderPrompt />
    </>
  )
}
