import { useEffect, useState } from 'react'
import { GeneralTermsService } from '../../../../core/services/generalTermsService'
import { generalTermsAdapter } from '../../../../domain/adapters/generalTermsAdapter'
import type { HttpModel } from '../../../../domain/models'
import type { GeneralTermsModel } from '../../../../domain/models/generalTerms/GeneralTermsModel'
import { useLoader } from '../../../context/loader/LoaderProvider'

interface GeneralTermsState {
  generalTerms: GeneralTermsModel | undefined
}

export const useGeneralTermsController = (productCode: number): GeneralTermsState => {
  const [generalTerms, setGeneralTerms] = useState<GeneralTermsModel>()
  const { startLoading, stopLoading } = useLoader()

  const GetGeneralTerms = (): void => {
    startLoading()
    GeneralTermsService()
      .GetGeneralTerms(productCode)
      .then(response => {
        setGeneralTerms(generalTermsAdapter(response.data))
      })
      .finally(() => stopLoading())
  }

  useEffect(() => {
    GetGeneralTerms()
  }, [productCode])

  return { generalTerms }
}
