import { OreText } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation } from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { hasUnloadOrders } from '../../../../../core/services'

export const CardOrderDatesParagraph = (): JSX.Element => {
  const { t } = useTranslation()
  const { orders } = useCardOrder()

  if (hasUnloadOrders(orders)) {
    return (
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.formSubTitleUnload)}
      </OreText>
    )
  }
  return (
    <OreText size="text-md">{t(cardOrderTranslation.cardOrderHome.formSubTitle)}</OreText>
  )
}
