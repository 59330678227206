import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import type { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { EmployeesWithLastCard, MessagesModel } from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEmployeeEditProfileSchemaTT } from 'src/presentation/validations'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { forms } from 'src/domain/translations'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import {
  MetaStatusCodes,
  NotificationSeverity,
  TextBoxesTypes,
  edenredProducts,
} from 'src/domain/enum'
import { ConvertESDateToISO, minAge } from 'src/core/helpers'
import { EmployeesService } from 'src/core/services/employeesService'

interface ControllerState {
  GetEmployeeDataTextBoxes: () => TextBoxRowModel<EmployeesWithLastCard>[]
  register: UseFormRegister<EmployeesWithLastCard>
  errors: any
  updateEmployee: (updatedEmployee: EmployeesWithLastCard) => void
  handleSubmit: UseFormHandleSubmit<EmployeesWithLastCard>
  enableEdit: boolean
  handleEnableEdit: () => void
  errorMessage: string
}

export const useEmployeeProfileFormController = (
  employeeData: EmployeesWithLastCard
): ControllerState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { employeeEditProfileSchema } = useEmployeeEditProfileSchemaTT()
  const { addNotification } = useNotification()
  const [enableEdit, setEnableEdit] = useState(false)
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<EmployeesWithLastCard>({
    resolver: yupResolver(employeeEditProfileSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      corporativeEmail: employeeData?.corporativeEmail,
      corporativeTelephone: employeeData?.corporativeTelephone,
      birthDate: employeeData?.birthDate,
      userId: employeeData?.userId,
    },
  })

  const GetEmployeeDataTextBoxes = (): TextBoxRowModel<EmployeesWithLastCard>[] => {
    return [
      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'email',
            label: t(employeesTranslation.profile.form.email),
            name: 'corporativeEmail',
            placeHolder: t('marta@gmail.com'),
            required: true,
            type: 'email',
            googleApi: false,
            maxLength: 75,
            disabled: !enableEdit,
          },

          {
            id: 'telephone',
            label: t(employeesTranslation.profile.form.phone),
            name: 'corporativeTelephone',
            placeHolder: t('677777777'),
            required: false,
            type: 'tel',
            googleApi: false,
            maxLength: 9,
            disabled: !enableEdit,
          },
        ],
      },

      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'reminderDate',
            label: t(forms.contact.birthdate),
            name: 'birthDate',
            required: true,
            controlType: TextBoxesTypes.datepicker,
            minDate: '1922-01-01',
            maxDate: minAge(),
            disabled: !enableEdit,
          },
          {
            id: 'employeeNumber',
            label: t(employeesTranslation.profile.form.employeeNumber),
            name: 'employeeNumber',
            placeHolder: t('123456'),
            required: false,
            type: 'text',
            googleApi: false,
            maxLength: 10,
            disabled: !enableEdit,
          },
        ],
      },
    ]
  }

  const updateEmployee = (updatedEmployee: EmployeesWithLastCard): void => {
    startLoading()
    const newEmployee = {
      userId: employeeData?.userId || '',
      productCode: edenredProducts.ticketTransporte,
      corporativeEmail: updatedEmployee.corporativeEmail,
      corporativeTelephone: updatedEmployee.corporativeTelephone,
      birthDate: updatedEmployee.birthDate,
      employeeNumber: updatedEmployee.employeeNumber,
      usageProfileId: updatedEmployee.usageProfile,
    }
    EmployeesService()
      .updateEmployee(newEmployee)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(t(forms.success.message), NotificationSeverity.success)
          setEnableEdit(false)
        } else {
          if (response?.meta.messages.length > 0) {
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
          setEnableEdit(true)
        }
      })
      .finally(() => stopLoading())
  }

  const setDefaultValues = (): void => {
    setValue('corporativeEmail', employeeData?.corporativeEmail || '')
    setValue('corporativeTelephone', employeeData?.corporativeTelephone || '')
    setValue('birthDate', ConvertESDateToISO(employeeData?.birthDate || ''))
    setValue('employeeNumber', employeeData?.employeeNumber || '')
  }

  const handleEnableEdit = (): void => {
    setEnableEdit(true)
  }

  useEffect(() => {
    setDefaultValues()
  }, [employeeData])

  return {
    GetEmployeeDataTextBoxes,
    register,
    errors,
    updateEmployee,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  }
}
