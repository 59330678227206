import { useState } from 'react'

export const useModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  return {
    showModal,
    setShowModal,
    showErrorModal,
    setShowErrorModal,
    showSuccessModal,
    setShowSuccessModal,
    showWarningModal,
    setShowWarningModal,
  }
}

export default useModal
