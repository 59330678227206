import {
  OreCheckbox,
  OreHeading,
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreText,
  OreTooltip,
} from '@runroom/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { useTranslation } from 'react-i18next'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'
import { getCardOrderStatusLabelById } from 'src/core/services'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { RechargeEmployeesCaption } from './RechargeEmployeesCaption'
import { RechargeEmployeesState } from '../hooks/useRechargeEmployees'
import { recharge } from 'src/domain/translations'
import { RechargeEmployeesRow } from './RechargeEmployeesRow'
import { Fragment } from 'react'

interface Props {
  rechargeEmpoyeesProps: RechargeEmployeesState
}

export const RechargeEmployeesTable = ({ rechargeEmpoyeesProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employees,
    onPageChange,
    page,
    pageSize,
    employeesSelected,
    onSelectEmployee,
    isSelected,
    showSelected,
  } = rechargeEmpoyeesProps

  return (
    <OreTableContainer>
      <div className="bg-white">
        <Grid items="center" columnGap="1rem" className="px-1 pt-1">
          <OreHeading size="headline-md">{t(recharge.captionTitle)}</OreHeading>
          <OreText tone="neutral-500" size="text-sm">
            {totalRows + ' ' + t(recharge.rowsTotalDescription)}
          </OreText>
        </Grid>
        <RechargeEmployeesCaption rechargeEmpoyeesProps={rechargeEmpoyeesProps} />
      </div>
      <OreTable>
        <OreTableHead>
          <OreTableRow>
            {header.headerElement && (
              <OreTableCell as="th">{header.headerElement}</OreTableCell>
            )}
            {header.headerLabel.map((header, index) =>
              !header.isSortable ? (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}&nbsp;
                  {header.tooltip && (
                    <OreTooltip placement="top" separation="small" text={header.tooltip}>
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  )}
                </OreTableCell>
              ) : (
                <OreTableCell as="th" key={'lbl' + index}>
                  {showSelected ? (
                    <>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <Fragment key={'tooltip_icon_' + index}>
                          {' '}
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        </Fragment>
                      )}
                    </>
                  ) : (
                    <OreTableSortLabel
                      active
                      direction={header.sortDirection}
                      onClick={header.onClick}>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <Fragment key={'tooltip_icon_' + index}>
                          {' '}
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        </Fragment>
                      )}
                    </OreTableSortLabel>
                  )}
                </OreTableCell>
              )
            )}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {employees && employees.length <= 0 ? (
            <TableEmpty
              colSpan={7}
              firstText={t(recharge.searchNoFound.first)}
              linkText={t(recharge.searchNoFound.link)}
              endText={t(recharge.searchNoFound.end)}
              linkUrl={''}
            />
          ) : showSelected && employeesSelected.length > 0 ? (
            employeesSelected
              .slice((page - 1) * pageSize, page * pageSize)
              .map((employee: EmployeesWithLastCardNew, index: number) => {
                return (
                  <RechargeEmployeesRow
                    key={'rows' + index}
                    employee={employee}
                    isSelected={isSelected}
                    onSelectEmployee={onSelectEmployee}
                  />
                )
              })
          ) : (
            employees.map((employee: EmployeesWithLastCardNew, index: number) => {
              return (
                <RechargeEmployeesRow
                  key={'rows' + index}
                  employee={employee}
                  isSelected={isSelected}
                  onSelectEmployee={onSelectEmployee}
                />
              )
            })
          )}
        </OreTableBody>
      </OreTable>
      <OreTableActions>
        <Grid gap="2rem">
          <OreText as="span" size="text-sm" tone="neutral">
            {employeesSelected.length}{' '}
            {employeesSelected.length == 1
              ? t(recharge.selectedRowsDescription)
              : t(recharge.selectedRowsDescriptionOthers)}
          </OreText>
        </Grid>
        <OrePagination
          count={showSelected ? employeesSelected.length : totalRows}
          labelNextPage={t(recharge.nextPageLabel)}
          labelPreviousPage={t(recharge.previousPageLabel)}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={pageSize}
          text={t(recharge.pageOf)}
        />
      </OreTableActions>
    </OreTableContainer>
  )
}
