import {
  deletePreOrder,
  employeeWithLastCardUnload,
  employeeWithLastCardDuplicate,
  employeeWithLastCardRecharge,
  indexOrderTemplate,
  saveCardOrder,
  singleGetIncompleteOrders,
  singleGetInvoiceDocument,
  singleGetLastOrderProforma,
  singleGetLastOrders,
  updateLastOrderFav,
  validateCardOrder,
} from '../../../config/constants/endpoints'
import type { CardOrderRepositoryContract } from '../../../core/contracts/cardOrder'
import { createQueryString } from '../../../core/helpers/queryString'
import { SetRequestBody } from '../../../domain/adapters/cardOrder/'
import type { IncompleteOrdersRequestBody } from '../../../domain/bodyRequest/IncompleteOrdersRequestBody'
import type { LastOrderRequestBody } from '../../../domain/bodyRequest/LastOrdersRequestBody'
import type {
  CardOrderCreateDTO,
  CardOrderResumeDTO,
  CardOrderValidateDTO,
  SaveCardOrderValidateDTO,
} from '../../../domain/dto'
import type { HttpModel } from '../../../domain/models'
import type { CardOrderModel } from '../../../domain/models/cardOrder/CardOrderModel'
import type { IncompleteOrderModelResponse } from '../../../domain/models/cardOrder/IncompleteOrderModel'
import type {
  LastOrderFavoriteModel,
  LastOrderModelResponse,
} from '../../../domain/models/cardOrder/LastOrderModel'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import type {
  CardOrderProtocol,
  SaveCardOrderProtocol,
} from '../../../domain/protocols/CardOrderProtocol'
import { axiosFetch } from '../../axios'
import type { IInvoiceFile } from '../../../domain/interfaces/invoice/IInvoiceFile'

import { EmployeesWithLastCardRequest } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardRequest'
import { EmployeesWithLastCardResponse } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'

export default class CardOrderRepository implements CardOrderRepositoryContract {
  async validate(
    cardOrderValidateProtocol: CardOrderModel
  ): Promise<CardOrderValidateDTO> {
    const { url, method, body }: HttpRequest = {
      url: validateCardOrder,
      method: HttpMethod.POST,
      body: SetRequestBody(cardOrderValidateProtocol),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async saveCardOrder(
    saveCardOrderProtocol: SaveCardOrderProtocol
  ): Promise<SaveCardOrderValidateDTO> {
    const { url, method, body }: HttpRequest = {
      url: saveCardOrder,
      method: HttpMethod.POST,
      body: saveCardOrderProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getCheckout(
    cardOrderProtocol: CardOrderProtocol,
    endpoint: string
  ): Promise<CardOrderResumeDTO> {
    const { url, method, body }: HttpRequest = {
      url: endpoint,
      method: HttpMethod.POST,
      body: cardOrderProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async createCardOrder(
    cardOrderProtocol: CardOrderProtocol,
    endpoint: string
  ): Promise<CardOrderCreateDTO> {
    const { url, method, body }: HttpRequest = {
      url: endpoint,
      method: HttpMethod.POST,
      body: { ...cardOrderProtocol },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getEmployeesWithLastCardDuplicates(
    filters?: EmployeesWithLastCardRequest
  ): Promise<HttpModel<EmployeesWithLastCardResponse>> {
    const { url, method, body }: HttpRequest = {
      url: employeeWithLastCardDuplicate,
      method: HttpMethod.POST,
      body: { ...filters },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getEmployeesWithLastCardRecharge(
    filters?: EmployeesWithLastCardRequest
  ): Promise<HttpModel<EmployeesWithLastCardResponse>> {
    const { url, method, body }: HttpRequest = {
      url: employeeWithLastCardRecharge,
      method: HttpMethod.POST,
      body: { ...filters },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getEmployeesWithLastCardUnload(
    filters?: EmployeesWithLastCardRequest
  ): Promise<HttpModel<EmployeesWithLastCardResponse>> {
    const { url, method, body }: HttpRequest = {
      url: employeeWithLastCardUnload,
      method: HttpMethod.POST,
      body: { ...filters },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getLastOrders(
    filters?: LastOrderRequestBody
  ): Promise<HttpModel<LastOrderModelResponse>> {
    const { url, method, body }: HttpRequest = {
      url: singleGetLastOrders,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async getIncompleteOrders(
    filters?: IncompleteOrdersRequestBody
  ): Promise<HttpModel<IncompleteOrderModelResponse>> {
    const { url, method, body }: HttpRequest = {
      url: singleGetIncompleteOrders,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async updateLastOrderFav(lastCardOrder: LastOrderFavoriteModel): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: updateLastOrderFav,
      method: HttpMethod.POST,
      body: lastCardOrder,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadLastOrderDocument(
    orderId: number,
    proformaExcelType: number
  ): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: singleGetLastOrderProforma,
      method: HttpMethod.POST,
      body: { orderId: orderId, proformaExcelType: proformaExcelType },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadOrderTemplate(
    orderExcelType: number,
    productCode: number,
    empty: boolean,
    orderId?: number
  ): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: indexOrderTemplate,
      method: HttpMethod.POST,
      body: {
        orderExcelType: orderExcelType,
        productType: productCode,
        orderId: orderId,
        empaty: empty,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async deletePreOrder(preOrderId: number): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: deletePreOrder + '?preOrderId=' + preOrderId,
      method: HttpMethod.POST,
      body: {},
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadInvoice(invoiceId: number): Promise<HttpModel<IInvoiceFile>> {
    const { url, method, body }: HttpRequest = {
      url: singleGetInvoiceDocument + '?documentId=' + invoiceId,
      method: HttpMethod.GET,
      body: {},
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
