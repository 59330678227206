import {
  OreButton,
  OreHeading,
  OreSelect,
  OreStack,
  OreText,
  OreTooltip,
  SvgEdit,
  SvgInfo,
} from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEmployeeProfileFormController } from '../hooks'
import { ComboBoxOptionModel } from 'src/domain/customComponents'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { Divider } from 'src/presentation/layout'
import { MessageBar } from 'src/presentation/components/Edenred'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { getUndefinedDashString, isMixClient } from 'src/core/services'
import { forms } from 'src/domain/translations'
import { EmployeesWithLastCard } from 'src/domain/models'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface EmployeeProfileFormLabelsTRProps {
  employeeData: EmployeesWithLastCard
}

export const EmployeeProfileFormLabelsTR = ({
  employeeData,
}: EmployeeProfileFormLabelsTRProps): JSX.Element => {
  const {
    register,
    errors,
    spendingRulesOptions,
    updateEmployee,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  } = useEmployeeProfileFormController(employeeData)
  const { permissionTags } = useAuth()
  const { t } = useTranslation()

  if (!employeeData) {
    return <></>
  }

  return (
    <div className="pt-3 pb-2">
      <OreHeading size="headline-sm">
        {t(employeesTranslation.profile.form.title)}
      </OreHeading>
      <Divider marginTop="1rem" marginBottom="2rem" />
      <div className="mb-105">
        <div className="mb-105">
          <OreStack direction="row">
            <OreStack>
              <OreText bold>{t(forms.contact.email)}:</OreText>
              <OreText bold>{t(forms.contact.phone)}:</OreText>
              <OreText bold>{t(forms.contact.birthdate)}:</OreText>
              <OreText bold>
                {t(employeesTranslation.profile.form.employeeNumber)}:
              </OreText>
              <OreText bold>{t(employeesTranslation.profile.form.costCenter)}</OreText>
            </OreStack>
            <OreStack>
              <OreText>{employeeData.corporativeEmail} &nbsp;</OreText>
              <OreText>{employeeData.corporativeTelephone} &nbsp;</OreText>
              <OreText>{employeeData.birthDate} &nbsp;</OreText>
              <OreText>{employeeData.userId} &nbsp;</OreText>
              <OreText>{getUndefinedDashString(employeeData.costCenter)} &nbsp;</OreText>
            </OreStack>
          </OreStack>
        </div>
        {isMixClient(permissionTags) && (
          <div className="form-row">
            <div className="form-atom form-atom--half">
              <OreSelect
                {...register('usageProfile')}
                required={true}
                id="spendingRuleId"
                name="usageProfile"
                label={t(employeesTranslation.profile.form.profileUsage)}
                disabled={!enableEdit}
                hasError={!!errors?.usageProfile}
                errorMessage={errors.usageProfile?.message}>
                {spendingRulesOptions.map(
                  (option: ComboBoxOptionModel, index: number) => (
                    <optgroup
                      key={'optgroup_' + index}
                      label={t(option.groupName ? option.groupName : '')}>
                      {option.options.map((opt: [string, number], index: number) => (
                        <option key={'option_' + index} label={opt[0]} value={opt[1]} />
                      ))}
                    </optgroup>
                  )
                )}
              </OreSelect>
            </div>
          </div>
        )}
      </div>

      {isMixClient(permissionTags) && (
        <>
          <MessageBar color="error" message={errorMessage} />

          {enableEdit ? (
            <OreButton category="primary" onClick={handleSubmit(updateEmployee)}>
              {t(forms.buttons.save)}
            </OreButton>
          ) : (
            <OreButton icon={<SvgEdit />} onClick={handleEnableEdit}>
              {t(forms.buttons.editData)}
            </OreButton>
          )}
        </>
      )}
    </div>
  )
}
