import { OreButton, OreInput } from '@runroom/oreneta'
import { FormProvider } from 'react-hook-form'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { EmployeesTTState } from '../hooks/useEmployeesListTableTTController'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'

interface Props {
  employeesTTProps: EmployeesTTState
}

export const EmployeeListTableTTCaption = ({ employeesTTProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { form, onEnter, onSearch } = employeesTTProps

  return (
    <FormProvider {...form}>
      <form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <div className="bg-white px-2 py-2">
          <Grid justify="space-between" items="center" gap="1rem">
            <Grid items="center" gap="1.5rem" className="width-60">
              <OreInput
                {...form.register('userNameOrDNIFilter')}
                id="userNameOrDNIFilter"
                placeholder={t(employeesTranslation.filter.textBox)}
                name="userNameOrDNIFilter"
                type="search"
                onKeyUp={e => onEnter(e)}
              />
              <OreButton as="button" category="primary" onClick={onSearch} size="small">
                {t(forms.buttons.search)}
              </OreButton>
            </Grid>
          </Grid>
        </div>
      </form>
    </FormProvider>
  )
}
