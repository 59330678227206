import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreText,
} from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { Modal } from '../../../Edenred'
import { useDeleteAccountController } from '../hooks/useDeleteAccountController'
import { DeleteAccountModalForm } from './DeleteAccountModalForm'

export const DeleteAccount = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    popupTitle,
    popupButtons,
    popupDescription,
    setShowPopup,
    popupShow,
    form,
    reasonOptions,
    errorMessage,
  } = useDeleteAccountController()

  return (
    <>
      <OreCardContainer>
        <OreCardWrapper space="large">
          <OreHeading as="h1" size="headline-sm">
            {t(accountSettingsTranslation.deleteAccount.title)}
          </OreHeading>

          <OreDivider space="medium" />

          <div>
            <OreText>{t(accountSettingsTranslation.deleteAccount.description)}</OreText>
          </div>
          <div className="py-2">
            <OreButton
              as="button"
              category="primary"
              onClick={() => setShowPopup(true)}
              size="large">
              {t(accountSettingsTranslation.deleteAccount.deleteButton)}
            </OreButton>
          </div>
        </OreCardWrapper>
      </OreCardContainer>
      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
        children={
          <DeleteAccountModalForm
            form={form}
            reasonOptions={reasonOptions}
            errorMessage={errorMessage}
          />
        }
      />
    </>
  )
}
