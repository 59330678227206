import { Table } from '../../../Edenred'
import { useInvoices } from '../hooks/useInvoices'

export const InvoicesContainer = (): JSX.Element => {
  const { header, rows, setRows, footer, filter, setFilter, onClickFilter, tableEmpty } =
    useInvoices({ pageSize: 8, showFilter: true })

  return (
    <div role="tabpanel" style={{ marginTop: '2rem' }}>
      <Table
        header={header}
        rows={rows}
        setRows={setRows}
        footer={footer}
        tableBodyValign="middle"
        filter={filter}
        setFilter={setFilter}
        onClickFilter={onClickFilter}
        tableEmpty={tableEmpty}
      />
    </div>
  )
}
