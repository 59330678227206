import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { UploadExcelStepProps } from '.';
import { UploadExcelStep } from '.'
import { edenredProducts } from '../../../../../domain/enum'
import { SimpleForm } from '../../../../Templates'
import { unloadTranslation } from '../../../../../domain/translations'

export const OrderUnloadExcelPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const uploadExcelStepProps: UploadExcelStepProps = {
    product: edenredProducts.ticketRestaurant,
  }

  return (
    <div>
      <SimpleForm
        title={t(unloadTranslation.title)}
        organisms={[<UploadExcelStep {...uploadExcelStepProps} />]}
      />
    </div>
  )
}
