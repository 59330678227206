import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { PropsWithChildren } from 'react'
import {
  IUseOrderImputationController,
  IUseOrderImputationProps,
  useOrderImputationController,
} from 'src/Flex/Orders/ui/order-imputation'

type Context = IUseOrderImputationController
type Props = PropsWithChildren & IUseOrderImputationProps

const [useOrders, StateContextProvider] = createGenericContext<Context>()

const OrdersImputationProvider = ({ children, ...rest }: Props): JSX.Element => {
  const hook = useOrderImputationController(rest)

  return <StateContextProvider value={{ ...hook }}>{children}</StateContextProvider>
}

export { OrdersImputationProvider, useOrders }
