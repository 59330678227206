import type { TFunction } from 'react-i18next'
import { array, object } from 'yup'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { edenredProducts } from 'src/domain/enum'
import { useConfigureProduct } from 'src/Flex/Products/ui/product-configuration'
import type {
  ExternalHealthInsuranceConfigurationModel,
  ExternalHealthPolicyModel,
} from 'src/Flex/Products/domain'
import {
  nonEmptyPositiveNumberSchema,
  nonEmptyStringSchema,
  stringSchema,
} from 'src/Flex/Shared/ui/Form'

export interface ExternalHealthForm {
  policies: {
    id: string
    policyName: string
    company: string
    monthlyPrice: number | undefined
    totalContracts?: number
    isWarningPolicyName?: boolean
    isWarningCompany?: boolean
    isWarningMonthlyPrice?: boolean
    isWarningArePhoneRequired?: boolean
    isWarningAreAddressRequired?: boolean
    isNew?: boolean
    forceDelete?: boolean
    arePhoneRequired: 'yes' | 'no'
    areAddressRequired: 'yes' | 'no'
  }[]
  active: boolean
}

export const useConfigureExternalHealthInsuranceController = () =>
  useConfigureProduct<ExternalHealthForm, edenredProducts.saludExterno>({
    productType: edenredProducts.saludExterno,
    formProps: {
      defaultValues: {
        policies: [
          {
            id: '',
            policyName: '',
            company: '',
            monthlyPrice: undefined,
            arePhoneRequired: 'no',
            areAddressRequired: 'no',
          },
        ],
        active: true,
      },
    },
    formValidationScheme: (t: TFunction<'translation', undefined>) =>
      object({
        policies: array()
          .of(
            object({
              id: stringSchema(t),
              policyName: nonEmptyStringSchema(t),
              company: nonEmptyStringSchema(t),
              monthlyPrice: nonEmptyPositiveNumberSchema(t),
              arePhoneRequired: nonEmptyStringSchema(t),
              areAddressRequired: nonEmptyStringSchema(t),
            })
          )
          .min(
            1,
            t(flexProductsTranslations.configure.healthExternal.form.policies.error)
          ),
      }),
    convertFromAPIToForm: (data): ExternalHealthForm => {
      const _data = data as ExternalHealthInsuranceConfigurationModel

      return {
        policies: _data.policies.map(
          ({ id = '', monthlyLimit, arePhoneRequired, areAddressRequired, ...rest }) => ({
            ...rest,
            id,
            monthlyPrice:
              monthlyLimit !== 0 ? Math.round(monthlyLimit * 100) / 100 : undefined,
            arePhoneRequired: arePhoneRequired ? 'yes' : 'no',
            areAddressRequired: areAddressRequired ? 'yes' : 'no',
            isWarningPolicyName: false,
            isWarningCompany: false,
            isWarningMonthlyPrice: false,
            isWarningArePhoneRequired: false,
            isWarningAreAddressRequired: false,
            isNew: false,
            forceDelete: false,
          })
        ),
        active: _data.active,
      }
    },
    convertFromFormToAPI: (
      data: ExternalHealthForm
    ): ExternalHealthInsuranceConfigurationModel => {
      const policiesToApi: ExternalHealthPolicyModel[] = []
      data.policies.map(policy => {
        policiesToApi.push({
          id: policy.id ? policy.id : undefined,
          monthlyLimit: policy.monthlyPrice !== undefined ? policy.monthlyPrice : 0,
          arePhoneRequired: policy.arePhoneRequired === 'yes',
          areAddressRequired: policy.areAddressRequired === 'yes',
          policyName: policy.policyName,
          company: policy.company,
        })
      })
      return { active: data.active, policies: policiesToApi }
    },
  })
