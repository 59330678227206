import { string } from 'yup'
import { TFunction } from 'react-i18next'
import { RequiredStringSchema } from 'yup/lib/string'
import { forms } from 'src/domain/translations'

export const nonEmptyChoiceSchema = (
  t: TFunction<'translation', undefined>,
  choices: string[]
): RequiredStringSchema<string | undefined> =>
  string()
    .typeError(t(forms.errors.fieldRequired))
    .oneOf(choices, t(forms.errors.fieldRequired))
    .required(t(forms.errors.fieldRequired))
