import { FormProvider } from 'react-hook-form'
import { useTTOrderFormController } from '../hooks'
import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDatePicker,
  OreDivider,
  OreHeading,
  OreInput,
  OreSelect,
  OreSelectPlaceholder,
  OreSpinner,
  OreStack,
  OreText,
  SvgArrowLeft,
  SvgPlus,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { Modal, Textbox } from 'src/presentation/components/Edenred'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { documentTypeEnumTranslation, forms } from 'src/domain/translations'
import { useNavigate } from 'react-router-dom'
import { documentType } from 'src/domain/enum'
import { maxAge, minAge } from 'src/core/helpers'
import { ComboBoxOptionModel } from 'src/domain/customComponents'
import { DeliverySiteSelected } from 'src/presentation/components/cardOrder/cardOrderForm/molecules/DeliverySiteSelected'
import { Form } from 'src/presentation/components/deliverySite/deliverySiteForm/organism/Form'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { usePreviousURL } from 'src/presentation/components/Edenred/navigation/hooks'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

interface Props {
  ttOrderItemToEdit?: TTOrderModel
}

export const TTOrderForm = ({ ttOrderItemToEdit }: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const comesFromSummary = usePreviousURL(navigationRoutes.ttOrderSummary)
  const {
    form,
    formTextBoxes,
    onCreateNewCard,
    deliverySitesOptions,
    onDeliverySiteChange,
    selectedDeliverySite,
    removeSelectedDeliverySite,
    onNewOrEditDeliverySite,
    cardOrderModalShow,
    setCardOrderModalShow,
    deliverySiteFormParameters,
    setNewDeliverySite,
    deliverySiteSelectLoading,
    onClickCancel,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
  } = useTTOrderFormController(ttOrderItemToEdit)

  return (
    <>
      <FormProvider {...form}>
        <div style={{ width: '100%' }}>
          <OreStack placeContent="normal" space="xlarge">
            <OreCardContainer>
              <OreCardWrapper space="xlarge">
                <OreStack placeContent="normal" space="2xlarge">
                  <div>
                    <OreHeading size="headline-lg">
                      {ttOrderItemToEdit
                        ? t(TTOrderTranslation.form.editTitle)
                        : t(TTOrderTranslation.form.title)}
                    </OreHeading>
                    <OreDivider space="larger-bottom" />
                    <>
                      <div className="pb-1">
                        <OreHeading size="headline-md">
                          {t(TTOrderTranslation.form.user.title)}
                        </OreHeading>
                      </div>

                      <OreStack placeContent="normal" space="xsmall">
                        <Textbox
                          register={form.register}
                          errors={form.formState.errors}
                          textBoxList={formTextBoxes.slice(0, 2)}
                        />
                        <div className="form-row form-row-nopadding">
                          <div className="form-atom form-atom--half">
                            <OreSelect
                              {...form.register('documentTypeId')}
                              required={true}
                              id="documentTypeId"
                              name="documentTypeId"
                              label={t(forms.contact.documentTypeId)}
                              // disabled={!enableEdit}
                              hasError={!!form.formState.errors?.documentTypeId}
                              legend={t(
                                TTOrderTranslation.form.user.documentType.helpText
                              )}
                              errorMessage={
                                form.formState.errors?.documentTypeId?.message
                              }>
                              <OreSelectPlaceholder
                                label={t(forms.placeholders.select)}
                              />
                              ,
                              <option
                                key={'option_' + documentType.DniNie}
                                label={t(documentTypeEnumTranslation.DniNie)}
                                value={documentType.DniNie}
                              />
                              ,
                              <option
                                key={'option_' + documentType.Passport}
                                label={t(documentTypeEnumTranslation.Passport)}
                                value={documentType.Passport}
                              />
                            </OreSelect>
                          </div>
                          <div className="form-atom form-atom--half">
                            <OreInput
                              id="name"
                              label={t(forms.contact.document)}
                              {...form.register('document')}
                              placeholder={t(forms.placeholders.document)}
                              required
                              type="text"
                              maxLength={14}
                              autoComplete="off"
                              hasError={!!form.formState.errors?.document}
                              errorMessage={form.formState.errors?.document?.message}
                            />
                          </div>
                        </div>
                        <div className="form-row form-row-nopadding">
                          <div className="form-atom">
                            <OreDatePicker
                              {...form.register('birthDate')}
                              id="birthDate"
                              label={t(forms.contact.birthdate)}
                              legend={t(TTOrderTranslation.form.user.birthdate.helpText)}
                              min={maxAge()}
                              max={minAge()}
                              required
                              hasError={!!form.formState.errors?.birthDate}
                              errorMessage={form.formState.errors?.birthDate?.message}
                            />
                          </div>
                        </div>
                        <Textbox
                          register={form.register}
                          errors={form.formState.errors}
                          textBoxList={formTextBoxes.slice(2, 4)}
                        />
                      </OreStack>
                    </>
                  </div>

                  {/* Card Form */}
                  <OreStack placeContent="normal" space="large">
                    <OreStack
                      direction="row"
                      placeContent="space-between"
                      placeItems="center"
                      space="medium">
                      <OreHeading size="headline-md">
                        {t(TTOrderTranslation.form.card.title)}
                      </OreHeading>
                    </OreStack>

                    <OreStack placeContent="normal" space="xsmall">
                      <Textbox
                        register={form.register}
                        errors={form.formState.errors}
                        textBoxList={formTextBoxes.slice(4)}
                      />
                    </OreStack>
                  </OreStack>

                  {/* Shipping Form */}
                  <OreStack placeContent="normal" space="large">
                    <OreStack
                      direction="row"
                      placeContent="space-between"
                      placeItems="center"
                      space="medium">
                      <OreHeading size="headline-md">
                        {t(TTOrderTranslation.form.shipping.title)}
                      </OreHeading>
                    </OreStack>

                    <div className="form-row form-row-nopadding">
                      <div className="form-atom">
                        <OreSelect
                          {...form.register('deliverySiteId')}
                          required={true}
                          id="deliverySiteId"
                          label={t(
                            TTOrderTranslation.form.shipping.deliverySiteSelect.label
                          )}
                          legend={t(
                            TTOrderTranslation.form.shipping.deliverySiteSelect.helpText
                          )}
                          onInput={onDeliverySiteChange}
                          hasError={!!form.formState.errors.deliverySiteId}
                          errorMessage={form.formState.errors.deliverySiteId?.message}
                          disabled={deliverySiteSelectLoading}>
                          {deliverySitesOptions.map(
                            (option: ComboBoxOptionModel, index: number) => (
                              <optgroup
                                key={'optgroup_' + index}
                                label={t(option.groupName ? option.groupName : '')}>
                                {option.options.map(
                                  (opt: [string, number], index: number) => (
                                    <option
                                      key={'option_' + index}
                                      label={opt[0]}
                                      value={opt[1]}
                                    />
                                  )
                                )}
                              </optgroup>
                            )
                          )}
                        </OreSelect>
                      </div>
                    </div>
                    <OreStack placeContent="normal" placeItems="start" space="medium">
                      {deliverySiteSelectLoading ? (
                        <div className="flex justify-center" style={{ width: '100%' }}>
                          <OreSpinner />
                        </div>
                      ) : (
                        <DeliverySiteSelected
                          deliverySite={selectedDeliverySite}
                          handleDelete={removeSelectedDeliverySite}
                          handleEdit={onNewOrEditDeliverySite}
                        />
                      )}
                      <OreButton
                        size="large"
                        category="highlight"
                        icon={<SvgPlus />}
                        onClick={event => onNewOrEditDeliverySite(event, 'new')}>
                        {t(TTOrderTranslation.form.shipping.addNewDeliverySite)}
                      </OreButton>
                    </OreStack>
                    <Modal
                      handleOnClose={setCardOrderModalShow}
                      open={cardOrderModalShow}
                      children={
                        <Form
                          formParameters={deliverySiteFormParameters}
                          setShowModal={setCardOrderModalShow}
                          formMode="modal"
                          setNewDeliverySite={setNewDeliverySite}
                        />
                      }
                      size="large"
                    />
                  </OreStack>

                  <OreText>{t(TTOrderTranslation.form.mandatoryFields)}</OreText>
                </OreStack>
              </OreCardWrapper>
            </OreCardContainer>

            <div>
              <OreDivider space="medium" />

              <OreStack direction="row" placeContent="space-between" placeItems="normal">
                {ttOrderItemToEdit || comesFromSummary ? (
                  <div> </div> // empty
                ) : (
                  <OreButton
                    category="tertiary"
                    icon={<SvgArrowLeft />}
                    onClick={() => navigate(-1)}>
                    {t(forms.buttons.goBack)}
                  </OreButton>
                )}
                <OreStack direction="row" space="medium">
                  <OreButton onClick={() => onClickCancel()}>
                    {t(forms.buttons.cancel)}
                  </OreButton>
                  <OreButton
                    category="primary"
                    onClick={form.handleSubmit(onCreateNewCard)}>
                    {ttOrderItemToEdit
                      ? t(forms.buttons.saveChanges)
                      : t(forms.buttons.createNewCard)}
                  </OreButton>
                </OreStack>
              </OreStack>
            </div>
          </OreStack>
        </div>
      </FormProvider>
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>
    </>
  )
}
