import { Dispatch, SetStateAction, createContext } from 'react'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { EmployeesWithLastCard } from 'src/domain/models'
import { TTOrderModel, TTOrderSummaryForm } from 'src/domain/models/TTOrder/TTOrderModel'

type TTOrderContextProps = {
  ttOrders: TTOrderModel[]
  addTTOrder: (tgdOrder: TTOrderModel) => void
  addTTOrders: (tgdOrders: TTOrderModel[]) => void
  updateTTOrder: (orderToEdit: TTOrderModel) => void
  removeTTOrder: (id: string) => void
  clearTTOrders: () => void
  setTTSelectedEmployees: (employees: EmployeesWithLastCard[]) => void
  getTTSelectedEmployees: () => EmployeesWithLastCard[]
  setTTEmployeesToConfig: (employees: TTOrderModel[]) => void
  getTTEmployeesToConfig: () => TTOrderModel[]
  addOrUpdateTTOrders: (order: TTOrderModel[]) => void
  addTTSummaryForm: (form: TTOrderSummaryForm) => void
  addTTOrderCheckout: (checkout: CardOrderResumeDTO) => void
  clearTTOrderCheckout: () => void
  ttSummaryForm: TTOrderSummaryForm
  ttOrderCheckout: CardOrderResumeDTO
  orderContext: number
  setOrderContext: Dispatch<SetStateAction<number>>
  requiresRechargeDate: () => boolean
}

export const TTOrderContext = createContext<TTOrderContextProps>(
  {} as TTOrderContextProps
)
