import { ComponentProps, ElementType } from 'react'
import {
  ExcelUploadProvider,
  ExcelUploader,
  ExcelUploaderErrors,
  ExcelValidation,
  ExcelConfirmation,
  Steps,
  useExcelUpload,
} from 'src/Flex/Employees/ui/employee-excel'
import { EmployeeExcelUploadResponse } from 'src/Flex/Employees/domain'

const getStepComponent = (
  step: Steps,
  uploadResponse: EmployeeExcelUploadResponse | undefined
): ComponentProps<ElementType> => {
  switch (step) {
    case Steps.Upload:
      if (uploadResponse?.hasErrors || uploadResponse?.hasWarnings)
        return ExcelUploaderErrors

      return ExcelUploader
    case Steps.Validation:
      return ExcelValidation
    case Steps.Confirmation:
      return ExcelConfirmation
  }
}

const EmployeeExcelPageInner = (): JSX.Element => {
  const { step, uploadResponse } = useExcelUpload()

  if (!step) return <></>

  const StepComponent = getStepComponent(step, uploadResponse)

  return <StepComponent />
}

export const EmployeeExcelPage = (): JSX.Element => (
  <ExcelUploadProvider>
    <EmployeeExcelPageInner />
  </ExcelUploadProvider>
)
