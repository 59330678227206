import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { TGDEmployeeAndChildsModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

interface ControlState {
  tgdOrderItemToEdit: TGDEmployeeAndChildsModel | undefined
}

export const useTGDOrderFormEditController = (): ControlState => {
  const { id } = useParams()
  const [tgdOrderItemToEdit, setTgdOrderItemToEdit] =
    useState<TGDEmployeeAndChildsModel>()
  const { tgdOrders } = useTGDOrder()
  const navigate = useNavigate()

  const setTgdOrderItemData = (): void => {
    const tgdOrderItemToEditData = tgdOrders.find(tgdOrder => tgdOrder.id === id)

    if (!tgdOrderItemToEditData) {
      navigate(navigationRoutes.tgdOrderSummary)
    }

    setTgdOrderItemToEdit(tgdOrderItemToEditData)
  }

  useEffect(() => {
    if (!id) {
      navigate(navigationRoutes.tgdOrderSummary)
    }
    setTgdOrderItemData()
  }, [id])

  return {
    tgdOrderItemToEdit,
  }
}
