import { useState, useContext, useCallback } from 'react'
import { LoaderContext } from './LoaderContext'

export const LoaderProvider = ({ children }: { children: React.ReactNode }) => {
  //state
  const [isLoading, setIsLoading] = useState(false)
  //actions
  const startLoading = useCallback(() => setIsLoading(true), [])
  const stopLoading = useCallback(() => setIsLoading(false), [])
  //value
  const value = { isLoading, startLoading, stopLoading }

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
}

export const useLoader = () => useContext(LoaderContext)
