export const apiExceptions = [
  'CLAERR0001',
  'CLAERR0002',
  'CLAERR0003',
  'CLAERR0014',
  'CLAERRCOV20001',
  'CLAERRCOV20004',
  'CLAERRCOV20010',
  'CLAERRCOV20011',
]

export const statusErrors = [
  { type: 'CLAERR0001' },
  { type: 'CLAERR0002' },
  { type: 'CLAERR0003' },
  { type: 'CLAERR0004' },
  { type: 'CLAERRCOV2' },
]

export const contentErrors = [
  { type: 'CLAERR0005' },
  { type: 'CLAERR0006' },
  { type: 'CLAERR0007' },
  { type: 'CLAERR0013' },
  { type: 'CLAERRCOV2200109' },
  { type: 'CLAERRCOV2200309' },
  { type: 'CLAERRCOV20007' },
  { type: 'CLAERRCOV2200310' },
  { type: 'CLAERRCOV2200310' },
  { type: 'CLAERRCOV2200313' },
  { type: 'CLAERRCOV20010' },
  { type: 'CLAERRCOV2100032' },
  { type: 'CLAERRCOV2100278' },
  { type: 'CLAERRCOV2100171' },
]

export const intialDataResponseErrors = [{ type: 'CLAERR0032' }, { type: 'CLAERR0033' }]
