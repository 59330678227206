import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { queryParam } from 'src/domain/enum'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface MedalliaTagProps {
  src: string
  async?: boolean
}

declare const window: any

export const MedalliaTag = ({ src, async = false }: MedalliaTagProps): null => {
  const { user } = useAuth()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const showUnsuccessfulOrderSurvey = searchParams.get(queryParam.unOrder)

  const appendScript = (): void => {
    const script = document.createElement('script')
    script.src = src
    script.type = 'text/javascript'
    script.async = async

    document.body.appendChild(script)
  }

  const updateMedalliaPageView = () => {
    if (
      window.KAMPYLE_ONSITE_SDK &&
      typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function'
    ) {
      window.KAMPYLE_ONSITE_SDK.updatePageView()
    }
  }

  const setMedalliaData = () => {
    !window.medalliaData && (window.medalliaData = {})

    window.medalliaData = {
      cuscode: user?.code?.toString() || '',
      unOrder: !!showUnsuccessfulOrderSurvey,
    }
  }

  useEffect(() => {
    appendScript()
    setMedalliaData()
  }, [user])

  useEffect(() => {
    updateMedalliaPageView()
    setMedalliaData()
  }, [location])

  return null
}
