import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react';
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'
import { FilterComboBox } from '../atoms/FilterComboBox'
import { FilterDatePicker } from '../atoms/FilterDatePicker'
import { FilterInput } from '../atoms/FilterInput'
import { FilterRadioButton } from '../atoms/FilterRadioButton'

export const FilterField = (props: {
  fields: FilterFieldModel[]
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
}): JSX.Element => {
  return (
    <>
      {Seq(props.fields).map((fields: FilterFieldModel, index: number) => (
        <div className="form-row" key={'filterRow_' + index}>
          {Seq(fields.filterFields).map(
            (field: FilterControlModel, controlIndex: number) => (
              <div className={fields.formClass} key={'control_' + index + controlIndex}>
                {field.filterType === 'comboBox' && (
                  <FilterComboBox comboBox={field} setFilter={props.setFilter} />
                )}
                {field.filterType === 'datePicker' && (
                  <FilterDatePicker datePicker={field} setFilter={props.setFilter} />
                )}
                {field.filterType === 'input' && (
                  <FilterInput input={field} setFilter={props.setFilter} />
                )}
                {field.filterType === 'radio' && (
                  <FilterRadioButton control={field} setFilter={props.setFilter} />
                )}
              </div>
            )
          )}
        </div>
      ))}
    </>
  )
}
