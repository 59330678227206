import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { edenredProducts, queryParam } from 'src/domain/enum'
import { useFetchEmployeeProducts } from './useFetchEmployeeProducts'
import { useSearchParams } from 'react-router-dom'
import { EmployeesWithLastCardProduct } from 'src/domain/models'

interface ControllerState {
  selectedTab: number
  handleTabClick: (index: number) => void
  employeeProducts: EmployeesWithLastCardProduct[] | undefined
  productCodes: number[] | undefined
  setProductCodes: Dispatch<SetStateAction<number[] | undefined>>
}

export const useEmployeeProfileContainerController = (): ControllerState => {
  const { employeeProducts } = useFetchEmployeeProducts()
  const [searchParams, setSearchParams] = useSearchParams()
  const [productCodes, setProductCodes] = useState<number[]>()
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const selectTab = (index: number): void => {
    setSelectedTab(index)
    setSearchParams({ product: index.toString() })
  }

  const handleTabClick = (index: number): void => {
    selectTab(index)
  }

  const updateQueryParam = (): void => {
    const productParam = searchParams.get(queryParam.product)
    if (productParam && employeeProducts) {
      const product = Number(productParam)
      switch (product) {
        case edenredProducts.ticketRestaurant:
          setSelectedTab(edenredProducts.ticketRestaurant)
          break
        case edenredProducts.ticketTransporte:
          setSelectedTab(edenredProducts.ticketTransporte)
          break
        case edenredProducts.ticketGuarderia:
          setSelectedTab(edenredProducts.ticketGuarderia)
          break
        default:
          selectTab(employeeProducts[0]?.productCode)
          break
      }
    }
    if (productParam === null && employeeProducts) {
      selectTab(employeeProducts[0]?.productCode)
    }
  }

  const PackProductCodesIntoArray = (): void => {
    if (employeeProducts) {
      const productsArray = employeeProducts.map(product => product.productCode)
      setProductCodes(productsArray)
    }
  }

  useEffect(() => {
    updateQueryParam()
  }, [searchParams, employeeProducts])

  useEffect(() => {
    PackProductCodesIntoArray()
  }, [employeeProducts])

  return {
    selectedTab,
    handleTabClick,
    employeeProducts,
    productCodes,
    setProductCodes,
  }
}
