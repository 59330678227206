import { useEffect, useState } from 'react'
import { ordersAdherenceService } from 'src/Flex/Orders/application'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { EmployeesLastAdherenceModel } from 'src/Flex/Orders/domain/EmployeesLastAdherenceModel'

export interface IUseEmployeesController {
  lastEmployeesAdherence: EmployeesLastAdherenceModel[]
  loadEmployeesLastAdherence: () => Promise<void>
  changePage: (page: number) => void
  numberOfRecordsPerPage: number
  totalRows: number
  page: number
}

export const useEmployeesAdherenceController = (
  initPage: number | null = 0
): IUseEmployeesController => {
  const [lastEmployeesAdherence, setLastEmployeesAdherence] = useState<
    EmployeesLastAdherenceModel[]
  >([])
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState<number | null>(initPage)
  const numberOfRecordsPerPage = 5

  const loadEmployeesLastAdherence = async (page: number | null = 0): Promise<void> => {
    startLoading()

    const response =
      page !== null
        ? await ordersAdherenceService().GetEmployeesLastAdherence(
            page,
            numberOfRecordsPerPage
          )
        : await ordersAdherenceService().GetEmployeesLastAdherence()

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setLastEmployeesAdherence(response.data.rows)
      setTotalRows(response.data.totalRows)
    }
  }

  const changePage = (page: number): void => {
    setPage(page)
  }

  useEffect(() => {
    loadEmployeesLastAdherence(page)
  }, [page])

  return {
    lastEmployeesAdherence,
    loadEmployeesLastAdherence,
    changePage,
    numberOfRecordsPerPage,
    totalRows,
    page: (page ?? 0) + 1,
  }
}
