import { OreButton, OreText, SvgArrowLeft } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import React, { memo } from 'react'
import {
  duplicatesConfigurationTranslations,
  forms,
} from '../../../../../domain/translations'
import { Modal, Table } from '../../../Edenred'
import { useEditDuplicatesConfigurationController } from '../hooks/useEditDuplicatesConfigurationController'

export const EditContainer = memo((): JSX.Element => {
  const {
    header,
    caption,
    validateTable,
    setRows,
    rows,
    navigate,
    setShowPopup,
    ValidateTableFields,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    clearCardOrderDuplicates,
  } = useEditDuplicatesConfigurationController()
  const { t } = useTranslation()

  return (
    <>
      <div className="pb-105" style={{ width: '65%' }}>
        <OreText>{t(duplicatesConfigurationTranslations.headerDescription)}</OreText>
      </div>
      <Table
        header={header}
        multiSelected={false}
        rows={rows}
        caption={caption}
        setRows={setRows}
        validateTable={validateTable}>
        <div className="mt-2 column-wrapper-buttons flex space-between">
          <OreButton
            as="button"
            category="highlight"
            onClick={() => {
              navigate(-1)
            }}
            size="large"
            style={{ color: '#0F172A' }}
            icon={<SvgArrowLeft />}>
            {t(forms.buttons.goBack)}
          </OreButton>

          <div style={{ marginLeft: 'auto' }}>
            <div className="flex flex--row float-right">
              <OreButton
                as="button"
                category="secondary"
                onClick={() => setShowPopup(true)}
                size="large">
                {t(forms.buttons.cancel)}
              </OreButton>
              <OreButton
                as="button"
                category="primary"
                onClick={event => ValidateTableFields(event)}
                disabled={rows.length === 0}
                size="large">
                {t(forms.buttons.addToOrder)}
              </OreButton>
            </div>
          </div>
        </div>
      </Table>
      <Modal
        handleOnClose={() => setShowPopup(false)}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
      />
    </>
  )
})
