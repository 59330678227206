export const myOrders = {
  tabs: {
    lastOrders: 'myOrders.tabs.lastOrders',
    incompleteOrders: 'myOrders.tabs.incompleteOrders',
  },
  title: 'myOrders.title',
  buttons: {
    new: 'myOrders.buttons.new',
  },
  filter: {
    search: {
      lastOrderPlaceHolder: 'myOrders.filter.search.lastOrderPlaceHolder',
      incompleteOrderPlaceHolder: 'myOrders.filter.search.incompleteOrderPlaceHolder',
      button: 'myOrders.filter.search.button',
    },
    resetButtons: 'myOrders.filter.resetButton',
    filterOptions: {
      label: 'myOrders.filter.filterOptions.label',
    },
    filterFields: {
      product: {
        label: 'myOrders.filter.filterFields.product.label',
        placeholder: 'myOrders.filter.filterFields.product.placeHolder',
      },
      invoiceStatus: {
        label: 'myOrders.filter.filterFields.invoiceStatus.label',
        placeholder: 'myOrders.filter.filterFields.invoiceStatus.placeHolder',
      },
      status: {
        label: 'myOrders.filter.filterFields.status.label',
        placeholder: 'myOrders.filter.filterFields.status.placeHolder',
      },
      dateFrom: {
        label: 'myOrders.filter.filterFields.dateFrom.label',
        placeholder: 'myOrders.filter.filterFields.dateFrom.placeHolder',
      },
      dateTo: {
        label: 'myOrders.filter.filterFields.dateTo.label',
        placeholder: 'myOrders.filter.filterFields.dateTo.placeHolder',
      },
      orderType: {
        label: 'myOrders.filter.filterFields.orderType.label',
        placeholder: 'myOrders.filter.filterFields.orderType.placeHolder',
      },
    },
  },
  lastOrders: {
    table: {
      header: {
        orderCreationDate: 'myOrders.lastOrders.table.header.orderCreationDate',
        orderId: 'myOrders.lastOrders.table.header.orderId',
        invoiceNumber: 'myOrders.lastOrders.table.header.invoiceNumber',
        productDescription: 'myOrders.lastOrders.table.header.productDescription',
        orderTypeDescription: 'myOrders.lastOrders.table.header.orderTypeDescription',
        statusDescription: 'myOrders.lastOrders.table.header.statusDescription',
        amount: 'myOrders.lastOrders.table.header.amount',
        actions: 'myOrders.lastOrders.table.header.actions',
      },
      caption: {
        title: 'myOrders.lastOrders.table.caption.title',
        orders: 'myOrders.lastOrders.table.caption.orders',
      },
      action: {
        proformaRechargeFileName:
          'myOrders.lastOrders.table.action.proformaRechargeFileName',
        proformaCardsFileName: 'myOrders.lastOrders.table.action.proformaCardsFileName',
        proformaTGDFileName: 'myOrders.lastOrders.table.action.proformaTGDFileName',
      },
    },
    modal: {
      favs: {
        title: 'myOrders.lastOrders.modal.favs.title',
        description: 'myOrders.lastOrders.modal.favs.description',
        orderName: 'myOrders.lastOrders.modal.favs.orderName',
        orderNamePlaceHolder: 'myOrders.lastOrders.modal.favs.orderNamePlaceHolder',
        reminderDate: 'myOrders.lastOrders.modal.favs.reminderDate',
        placeholder: 'myOrders.lastOrders.modal.favs.reminderDatePlaceHolder',
        reminderDateLegend: 'myOrders.lastOrders.modal.favs.reminderDateLegend',
        email: 'myOrders.lastOrders.modal.favs.email',
        emailPlaceholder: 'myOrders.lastOrders.modal.favs.emailPlaceholder',
        emailLegend: 'myOrders.lastOrders.modal.favs.emailLegend',
      },
    },
    orderTypes: {
      cards: 'myOrders.lastOrders.orderTypes.cards',
      charge: 'myOrders.lastOrders.orderTypes.charge',
      unload: 'myOrders.lastOrders.orderTypes.unload',
      assignment: 'myOrders.lastOrders.orderTypes.assignment',
      assignments: 'myOrders.lastOrders.orderTypes.assignments',
    },
    repetExcel: {
      download: 'myOrders.lastOrders.repetExcel.download',
      cancel: 'myOrders.lastOrders.repetExcel.cancel',
      title: 'myOrders.lastOrders.repetExcel.title',
      description: 'myOrders.lastOrders.repetExcel.description',
    },
  },
  incompleteOrders: {
    title: 'myOrders.incompleteOrders.title',
    buttons: {
      new: 'myOrders.incompleteOrders.buttons.new',
    },
    table: {
      header: {
        orderName: 'myOrders.incompleteOrders.table.header.orderName',
        reminderDate: 'myOrders.incompleteOrders.table.header.reminderDate',
        productDescription: 'myOrders.incompleteOrders.table.header.productDescription',
        orderTypeDescription:
          'myOrders.incompleteOrders.table.header.orderTypeDescription',
        actions: 'myOrders.incompleteOrders.table.header.actions',
      },
      caption: {
        title: 'myOrders.incompleteOrders.table.caption.title',
        orders: 'myOrders.incompleteOrders.table.caption.orders',
      },
      empty: {
        message: 'myOrders.incompleteOrders.table.empty.message',
        subtitle: 'myOrders.incompleteOrders.table.empty.subtitle',
        description: 'myOrders.incompleteOrders.table.empty.description',
      },
    },
    modal: {
      delete: {
        title: 'myOrders.incompleteOrders.modal.delete.title',
        description: 'myOrders.incompleteOrders.modal.delete.description',
      },
    },
    orderTypes: {
      newAndDuplicated: 'myOrders.incompleteOrders.orderTypes.newAndDuplicated',
      charge: 'myOrders.incompleteOrders.orderTypes.charge',
      discharge: 'myOrders.incompleteOrders.orderTypes.discharge',
    },
  },
}
