import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useTranslation } from 'react-i18next'
import {
  cardOrderFormTranslation,
  recharge,
  unloadTranslation,
} from '../../../../../domain/translations'
import { orderType } from '../../../../../domain/enum'

export const useTitleTranslation = () => {
  const GetExcelTitle = () => {
    const { orderContext } = useCardOrder()
    const { t } = useTranslation()
    if (orderContext.orderType === orderType.Recharge) {
      return t(recharge.title)
    }
    if (orderContext.orderType === orderType.Unload) {
      return t(unloadTranslation.title)
    }
    return t(cardOrderFormTranslation.title)
  }

  return {
    getExcelTitle: GetExcelTitle,
  }
}
