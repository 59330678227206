import { PropsWithChildren } from 'react'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import {
  IUseProductController,
  useProductAdherenceController,
} from 'src/Flex/Orders/ui/order-adherence'

type Context = IUseProductController

type Props = PropsWithChildren

const [useProductsAdherence, StateContextProvider] = createGenericContext<Context>()

const ProductsAdherenceProvider = ({ children }: Props): JSX.Element => {
  const hook = useProductAdherenceController()

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { ProductsAdherenceProvider, useProductsAdherence }
