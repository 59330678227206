import {
  PayrollApiModel,
  PayrollExcelTemplateResponse,
  PayrollModel,
  PayrollRepositoryContract,
} from 'src/Flex/Payrolls/domain'
import {
  getEmployeesPayrollList,
  payrollDownloadExcel,
  payrollExcelConfiguration,
} from 'src/config/constants/endpoints'
import { createQueryString } from 'src/core/helpers'
import { edenredProducts, MetaStatusCodes } from 'src/domain/enum'
import { HttpModel, PaginationModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { PayrollExcelConfigurationResponse } from '../domain/PayrollCustomModel'

type ApiDownloadExcelResponse = HttpModel<{
  file: string
} | null>

export class PayrollRepository implements PayrollRepositoryContract {
  async GetPayrolls(
    pageNumber: number,
    numberOfRecordsPerPage: number,
    date: string,
    search?: string
  ): Promise<HttpModel<PaginationModel<PayrollModel>>> {
    const response = await axiosFetch(
      getEmployeesPayrollList.concat(
        createQueryString({ pageNumber, numberOfRecordsPerPage, date, search })
      ),
      HttpMethod.GET,
      {}
    )

    return {
      meta: response.meta,
      data: {
        rows:
          response.data?.rows.map((row: PayrollApiModel) => ({
            name: `${row.userFirstName} ${row.userLastName ? row.userLastName : ''} ${
              row.userLastName2 ? row.userLastName2 : ''
            }`,
            document: row.document || '',
            collective: row.collective || '',
            product: row.productType || edenredProducts.ticketRestaurant,
            exemptValue: row.exemptAmount || 0,
            nonExemptValue: row.nonExemptAmount || 0,
          })) || [],
        totalRows: response.data?.totalRows || 1,
      },
    }
  }

  async GetPayrollExcell(date: Date): Promise<HttpModel<PayrollExcelTemplateResponse>> {
    const response: ApiDownloadExcelResponse = await axiosFetch(
      payrollDownloadExcel.concat(createQueryString({ date: date.toISOString() })),
      HttpMethod.GET,
      {}
    )

    return {
      data: { excel: response.data?.file },
      meta: response.meta,
    }
  }

  async GetPayrollExcelConfiguration(): Promise<
    HttpModel<PayrollExcelConfigurationResponse>
  > {
    return await axiosFetch(payrollExcelConfiguration, HttpMethod.GET, {})
  }
}
