import {
  OreCheckbox,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
} from '@runroom/oreneta'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { getCardOrderStatusLabelById } from 'src/core/services'
import { lastCardStatus } from 'src/domain/enum'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'
import { duplicatedTranslation } from 'src/domain/translations'

interface Props {
  employee: EmployeesWithLastCardNew
  onSelectEmployee: (
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCardNew
  ) => void
  isSelected: (employee: EmployeesWithLastCardNew) => boolean
}

export const DuplicatedEmployeesRow = ({
  employee,
  onSelectEmployee,
  isSelected,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreTableRow valign="middle">
      <OreTableCell>
        {employee.cardStatusId < +lastCardStatus.confirmed ? (
          <OreTooltip
            placement="bottom"
            separation="small"
            text={t(duplicatedTranslation.userUnavailable)}>
            <div>
              <OreCheckbox
                name="check"
                onChange={event => onSelectEmployee(event, employee)}
                role="checkbox"
                aria-checked={isSelected(employee)}
                checked={isSelected(employee)}
                disabled={employee.cardStatusId < +lastCardStatus.confirmed}
              />
            </div>
          </OreTooltip>
        ) : (
          <OreCheckbox
            name="check"
            onChange={event => onSelectEmployee(event, employee)}
            role="checkbox"
            aria-checked={isSelected(employee)}
            checked={isSelected(employee)}
            disabled={employee.cardStatusId < +lastCardStatus.confirmed}
          />
        )}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {employee.userName}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {employee.document}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {employee.email}
        </OreText>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        {employee.employeeNumber}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {employee.balance}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {t(getCardOrderStatusLabelById(employee.cardStatusId))}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {employee.expiredDate}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {employee.cardType}
      </OreTableCell>
    </OreTableRow>
  )
}
