import { t } from 'i18next'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import type {
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { myOrders } from '../../../../../domain/translations/cardOrder/myOrders'
import type {
  IInvoice,
  IInvoiceResponse,
} from '../../../../../domain/interfaces/invoice/IInvoiceApiResponse'
import { invoiceTranslation } from '../../../../../domain/translations/payments/invoiceTranslation'
import { OrePill, SvgDownload } from '@runroom/oreneta'
import {
  emptyValues,
  invoicesTabs,
  invoiceStatus,
  NotificationSeverity,
  orderStatus,
  Repositories,
} from '../../../../../domain/enum'
import {
  getDownloadTooltip,
  GetInvoiceStatusList,
} from '../../../../../core/services/InvoiceService'
import type { HttpModel } from '../../../../../domain/models'
import { GetInvoiceMap } from '../../../../../domain/adapters/invoiceAdapter'
import { OrePillTones } from '../../../../../domain/runrom/IOrePillTones'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { cardOrderService, GetUrSearchParams } from '../../../../../core/services'
import { forms } from '../../../../../domain/translations'
import { Seq } from 'immutable'
import { GetEdenredProducts } from '../../../../../core/services/productsService'
import { RepositoryFactory } from '../../../../../core/factories'
import { getToday } from 'src/core/helpers'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useInvoiceSharedController } from '../../hooks/useInvoiceSharedController'

interface IUseInvoices {
  header: HeaderModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  footer: FooterModel
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  pageSize: number
  tableEmpty: TableEmptyModel
}

const invoiceParams = {
  invoiceId: 'invoiceId',
  invoiceNumber: 'invoiceNumber',
}

enum InvoiceRequestParamsNames {
  invoiceId = 'invoiceId',
  invoiceNumber = 'invoiceNumber',
  orderNumberOrInvoiceNumber = 'orderNumberOrInvoiceNumber',
  productCode = 'productCode',
  invoiceStatus = 'invoiceStatus',
  beginDate = 'beginDate',
  endDate = 'endDate',
}

interface InvoiceRequestParams {
  [InvoiceRequestParamsNames.orderNumberOrInvoiceNumber]?: string
  [InvoiceRequestParamsNames.productCode]?: number
  [InvoiceRequestParamsNames.invoiceStatus]?: number
  [InvoiceRequestParamsNames.beginDate]?: string
  [InvoiceRequestParamsNames.endDate]?: string
}

interface useInvoicesProps {
  showFilter: boolean
  pageSize: number
  showAllTotalRow?: boolean
}

export const useInvoices = ({ pageSize, showFilter }: useInvoicesProps): IUseInvoices => {
  const { startLoading, stopLoading } = useLoader()
  const { addNotification } = useNotification()
  const [invoicesList, setInvoicesList] = useState<IInvoice[]>([])
  const [filter, setFilter] = useState<FilterModel>({})
  const [rows, setRows] = useState<RowModel[]>([])
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState<number>(0)
  const isServerSide = true
  const [queryFilters, setQueryFilters] = useState<string>(emptyValues.empty)
  const { GetProductIconByCode } = useInvoiceSharedController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(invoiceTranslation.invoices.table.header.date) },
      { label: t(invoiceTranslation.invoices.table.header.invoiceNumber) },
      { label: t(invoiceTranslation.invoices.table.header.orderNumber) },
      { label: t(invoiceTranslation.invoices.table.header.product) },
      { label: t(invoiceTranslation.invoices.table.header.expireDate) },
      { label: t(invoiceTranslation.invoices.table.header.state) },
      { label: t(invoiceTranslation.invoices.table.header.amountTotal) },
      { label: t(invoiceTranslation.invoices.table.header.amountPending) },
      { label: t(invoiceTranslation.invoices.table.header.actions) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(invoiceTranslation.invoices.table.tableEmpty.message),
  }

  const HandleResponse = (response: HttpModel<IInvoiceResponse>): void => {
    const responseRows = response?.data?.rows ? response.data.rows : []
    setTotalRows(response?.data?.totalRows ? response.data.totalRows : 0)
    const invoicesMapped = GetInvoiceMap(responseRows)
    setInvoicesList(invoicesMapped)
  }

  const HandleError = (error: any): void => {
    addNotification(t(forms.errors.genericError), NotificationSeverity.error)
  }

  const RequestTablePage = (page: number, filters = ''): void => {
    startLoading()
    const repository = RepositoryFactory(Repositories.invoice)
    let query = `?paginationModel.numberOfRecordsPerPage=${pageSize}&paginationModel.pageNumber=${
      page - 1
    }&paginationModel.orderByField=creationDate&paginationModel.sortDescending=true`
    if (filters !== emptyValues.empty) {
      query += `&${filters}`
    }
    repository
      .GetInvoices(query)
      .then((response: HttpModel<IInvoiceResponse>) => HandleResponse(response))
      .catch(error => HandleError(error))
      .finally(() => stopLoading())
  }

  const onClickFilter = (): void => {
    // event.preventDefault()
    OnSearch()
  }

  const GetRequestParams = (): InvoiceRequestParams => {
    const tableFilters: InvoiceRequestParams = {}
    if (filter.search && filter.search.textBox.value !== emptyValues.empty)
      tableFilters[InvoiceRequestParamsNames.orderNumberOrInvoiceNumber] =
        filter.search.textBox.value?.trim()
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          if (
            field.name === InvoiceRequestParamsNames.productCode &&
            field.value !== emptyValues.empty
          )
            tableFilters[InvoiceRequestParamsNames.productCode] = Number(field.value)
          if (
            field.name === InvoiceRequestParamsNames.invoiceStatus &&
            field.value !== emptyValues.empty
          )
            tableFilters[InvoiceRequestParamsNames.invoiceStatus] = Number(field.value)
          if (
            field.name === InvoiceRequestParamsNames.beginDate &&
            field.value !== emptyValues.empty
          )
            tableFilters[InvoiceRequestParamsNames.beginDate] = field.value
          if (
            field.name === InvoiceRequestParamsNames.endDate &&
            field.value !== emptyValues.empty
          )
            tableFilters[InvoiceRequestParamsNames.endDate] = field.value
        })
      })
    }
    return tableFilters
  }

  const GetFilterValues = (): string => {
    const stringFilters = GetUrSearchParams(GetRequestParams())
    return stringFilters
  }

  const OnSearch = (): void => {
    const tableFilters: string = GetFilterValues()
    setQueryFilters(tableFilters)
    RequestTablePage(1, tableFilters)
    setPage(1)
  }

  const GetPillInvoiceTone = (status: number) => {
    switch (status) {
      case invoiceStatus.expired:
        return OrePillTones.error
      case invoiceStatus.paid:
        return OrePillTones.success
      case invoiceStatus.pending:
        return OrePillTones.warning
      case orderStatus.cancelled:
        return OrePillTones.error
      case orderStatus.inProcess:
        return OrePillTones.cobalt
      default:
        return OrePillTones.gray
    }
  }

  const GetPill = (status: number, statusLabel: string): JSX.Element => {
    return <OrePill tone={GetPillInvoiceTone(status)}>{statusLabel}</OrePill>
  }

  const GetRows = (): void => {
    const invoiceRows: RowModel[] = []
    invoicesList.forEach((invoice: IInvoice, index: number) => {
      invoiceRows.push({
        rowId: index,
        cells: [
          {
            type: 'text',
            cellText: [
              { text: invoice.creationDate, name: 'creationDate', size: 'text-sm' },
            ],
          },
          {
            type: 'text',
            cellText: [
              { text: invoice.invoiceNumber, name: 'invoiceNumber', size: 'text-sm' },
            ],
          },
          {
            type: 'text',
            cellText: [
              { text: invoice.orderNumber, name: 'orderNumber', size: 'text-sm' },
            ],
          },
          { type: 'element', cellElement: GetProductIconByCode(invoice.productCode) },
          {
            type: 'text',
            cellText: [{ text: invoice.limitDate, name: 'limitDate', size: 'text-sm' }],
          },
          {
            type: 'element',
            cellElement: GetPill(invoice.invoiceStatus, invoice.statusDescription),
          },
          {
            type: 'text',
            cellText: [
              {
                text: invoice.totalAmountDescription,
                name: 'totalAmount',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              { text: invoice.dueAmountDescription, name: 'dueAmount', size: 'text-sm' },
            ],
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'download',
                icon: <SvgDownload />,
                type: 'download',
                tooltip: getDownloadTooltip(
                  invoice.isInvoiceDownloadable,
                  invoice.isInvoiceReadyToDownload,
                  invoicesTabs.invoices
                ),
                disabled:
                  !invoice.isInvoiceDownloadable || !invoice.isInvoiceReadyToDownload,
                onClick: () =>
                  downloadInvoice(
                    invoice.invoiceId,
                    invoice.invoiceNumber,
                    invoice.isInvoiceDownloadable,
                    invoice.isInvoiceReadyToDownload
                  ),
              },
            ],
          },
        ],
      })
    })
    setRows(invoiceRows)
  }

  const downloadInvoice = (
    creditNoteId: number,
    invoiceNumber: string,
    isInvoiceDownloadable: boolean,
    isInvoiceReadyToDownload: boolean
  ): void => {
    if (!isInvoiceDownloadable || !isInvoiceReadyToDownload) return
    startLoading()
    cardOrderService()
      .downloadInvoice(creditNoteId)
      .then(response => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${response.data.file}`
        link.download = invoiceNumber ? invoiceNumber + '.pdf' : 'invoice.pdf'
        link.click()
        link.remove()
      })
      .catch(() => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      })
      .finally(() => {
        stopLoading()
      })
  }

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: isServerSide,
        totalRows: totalRows,
      },
    })
  }

  const FourYearsAgo = (): string => {
    const today = new Date()
    const lastYear = new Date(today.getFullYear() - 4, today.getMonth(), today.getDate())
    lastYear.setDate(lastYear.getDate() + 1)
    const isoDate = lastYear.toISOString()
    const final = isoDate.split('T')[0]
    return final
  }

  const SetFilters = (): void => {
    const filterValues = GetRequestParams()
    setFilter({
      showOpen: true,
      search: {
        textBox: {
          fields: [invoiceParams.invoiceId, invoiceParams.invoiceNumber],
          placeHolder: t(myOrders.filter.search.lastOrderPlaceHolder),
          value: emptyValues.empty,
        },
        searchButton: t(myOrders.filter.search.button),
      },
      filerOptions: [],
      resetButton: t(myOrders.filter.resetButtons),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'datePicker',
              name: InvoiceRequestParamsNames.beginDate,
              label: t(myOrders.filter.filterFields.dateFrom.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateFrom.placeholder
              ).toUpperCase(),
              value:
                filterValues && filterValues.beginDate
                  ? filterValues.beginDate.toString()
                  : emptyValues.empty,
              min: FourYearsAgo(),
              max: getToday(),
            },
            {
              filterType: 'datePicker',
              name: InvoiceRequestParamsNames.endDate,
              label: t(myOrders.filter.filterFields.dateTo.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateTo.placeholder
              ).toUpperCase(),
              value:
                filterValues && filterValues.endDate
                  ? filterValues.endDate.toString()
                  : emptyValues.empty,
              min: FourYearsAgo(),
              max: getToday(),
            },
          ],
        },
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'comboBox',
              name: InvoiceRequestParamsNames.productCode,
              label: t(myOrders.filter.filterFields.product.label),
              placeHolder: t(myOrders.filter.filterFields.product.placeholder),
              value:
                filterValues && filterValues.productCode
                  ? filterValues.productCode.toString()
                  : emptyValues.empty,
              options: [{ options: GetEdenredProducts() }],
            },
            {
              filterType: 'comboBox',
              name: InvoiceRequestParamsNames.invoiceStatus,
              label: t(myOrders.filter.filterFields.invoiceStatus.label),
              placeHolder: t(myOrders.filter.filterFields.status.placeholder),
              value:
                filterValues && filterValues.invoiceStatus
                  ? filterValues.invoiceStatus.toString()
                  : emptyValues.empty,
              options: [{ options: GetInvoiceStatusList(t) }],
            },
          ],
        },
      ],
    })
  }

  useEffect(() => {
    GetRows()
    getFooter()
  }, [invoicesList])

  useEffect(() => {
    RequestTablePage(page, queryFilters)
  }, [page])

  useEffect(() => {
    if (rows.length > 0) {
      if (!showFilter) return
      SetFilters()
    }
  }, [rows])

  return {
    header,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    pageSize,
    tableEmpty,
  }
}
