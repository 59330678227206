import { useEffect, useMemo, useState } from 'react'
import { useTranslation, TFunction } from 'react-i18next'
import { UseFormReturn, useForm } from 'react-hook-form'
import { AnySchema, array, number, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ordersImputationService } from 'src/Flex/Orders/application'
import {
  ChargePayrollMonthOptions,
  IOrderImputation,
  OrderImputationModel,
  SummaryImputation,
} from 'src/Flex/Orders/domain'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { edenredProducts } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { orderImputationsTranslations } from 'src/Flex/Orders/ui/order-imputation'
import {
  GetDateFormattedLong,
  GetFullDateFormatted,
  GetMonthFormattedWithYear,
} from 'src/core/helpers'
import {
  checkboxSchema,
  nonEmptyPositiveNumberSchema,
  nonEmptyStringSchema,
  useMetaResponseHandler,
} from 'src/Flex/Shared/ui/Form'
import { ProductTypeSlugs, Steps, chargePayrollSchema } from '../../order-validation'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useUserData } from 'src/Flex/User/ui/context'
import { HttpModel, MessagesModel } from 'src/domain/models'

export type IUseOrderImputationProps = {
  productType: edenredProducts
}
interface ImputationFormItem {
  id: string
  active: boolean
  value: number | null
  isEditable: boolean
}

interface DatesFromApi {
  cutoffDate: string
  imputationMonth: string
  nextMonth: string
  updatedDate: string
}
interface ConditionsFromApi {
  doOrderNextMonth: boolean
  isOrderDoneThisMonth: boolean
  canOrderBeUpdate: boolean
}
export interface ImputationForm {
  chargePayrollMonth: string
  orders: ImputationFormItem[]
}

export interface IUseOrderImputationController {
  productType: edenredProducts
  orders: OrderImputationModel[]
  emptyRows: boolean
  dates: DatesFromApi
  conditions: ConditionsFromApi | undefined
  form: UseFormReturn<ImputationForm>
  loadOrders: () => Promise<void>
  deleteOrder: (id: string) => Promise<boolean>
  imputeOrders: (data: ImputationForm) => Promise<boolean>
  calculate: (data: ImputationForm) => Promise<void>
  search: (value: string) => void
  searchParam: string
  numberOfRecordsPerPage: number
  onPageChange: (page: number) => void
  page: number
  rows: OrderImputationModel[]
  summaryImputation: SummaryImputation | undefined
  back: () => void
  confirm: () => Promise<boolean>
  backToHome: () => void
  backToPayroll: () => void
  forceUpdate: () => Promise<void>
  errorData: MessagesModel[] | undefined
  backToStart: () => void
}

const schema = (
  t: TFunction<'translation', undefined>,
  orders: OrderImputationModel[],
  producType: edenredProducts
) => {
  const orderSchema: {
    id: AnySchema
    active: AnySchema
    value?: AnySchema
    isEditable?: AnySchema
  } = {
    id: nonEmptyStringSchema(t),
    active: checkboxSchema(t),
  }

  const valueSchema = number().transform(value => (Number.isNaN(value) ? 0 : value))
  orderSchema.value = valueSchema.when('active', {
    is: true,
    then:
      producType === edenredProducts.formacion
        ? nonEmptyPositiveNumberSchema(t, undefined, false, 0).test(
            'availableAmount',
            t(forms.errors.remaining),
            (value, context) => {
              const order = orders.find(order => order.id === context.parent.id)

              return order?.remaining !== undefined
                ? order.remaining >= (value ?? 0)
                  ? true
                  : false
                : true
            }
          )
        : nonEmptyPositiveNumberSchema(t, undefined, false, 0),
  })
  orderSchema.isEditable = checkboxSchema(t)

  return object({
    chargePayrollMonth: chargePayrollSchema(t),
    orders: array().of(object(orderSchema)),
  })
}

export const useOrderImputationController = ({
  productType,
}: IUseOrderImputationProps): IUseOrderImputationController => {
  const { step, productType: productTypeSlug } = useParams<{
    step: Steps
    productType: ProductTypeSlugs
  }>()
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const navigate = useNavigate()
  const [orders, setOrders] = useState<OrderImputationModel[]>([])
  const [summaryImputation, setSummaryImputation] = useState<SummaryImputation>()
  const [successData, setSuccessData] = useState<boolean>()
  const [imputationProductType, setImputationProductType] = useState<edenredProducts>()
  const [dates, setDates] = useState<DatesFromApi>({
    cutoffDate: '',
    imputationMonth: '',
    nextMonth: '',
    updatedDate: '',
  })
  const [conditions, setConditions] = useState<ConditionsFromApi>()
  const [emptyRows, setEmptyRows] = useState<boolean>(false)

  const numberOfRecordsPerPage = 40
  const [page, setPage] = useState<number>(1)
  const [searchParam, setSearchParam] = useState<string>('')
  const [errorData, setErrorData] = useState<MessagesModel[]>()
  const { getAllProducts } = useUserData()

  const form = useForm<ImputationForm>({
    resolver: yupResolver(schema(t, orders, productType)),
    mode: 'onChange',
  })

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const forceUpdate = async (): Promise<void> => {
    startLoading()

    search('')
    const response = await ordersImputationService().GetRefreshOrders(productType)
    stopLoading()
    completeLoad(response, true)
  }

  const completeLoad = (response: HttpModel<IOrderImputation>, isForced: boolean) => {
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      const defaulOrders: ImputationForm = {
        orders: [],
        chargePayrollMonth: ChargePayrollMonthOptions.Current,
      }

      response.data?.orders?.forEach(order => {
        defaulOrders.orders.push({
          id: order.id,
          active: true,
          value: order.monthlyImport,
          isEditable: order.isEditable,
        })
      })

      setImputationProductType(productType)

      form.reset({
        orders: defaulOrders.orders,
        chargePayrollMonth: ChargePayrollMonthOptions.Current,
      })

      const nextMonth = new Date(response.data?.imputationMonth)
      nextMonth.setMonth(nextMonth.getMonth() + 1)
      setDates({
        cutoffDate: GetDateFormattedLong(response.data?.cutoffDate),
        imputationMonth: GetMonthFormattedWithYear(response.data?.imputationMonth),
        nextMonth: GetMonthFormattedWithYear(nextMonth),
        updatedDate: GetFullDateFormatted(response.data?.updatedDate),
      })
      setConditions({
        doOrderNextMonth: response.data?.doOrderNextMonth,
        isOrderDoneThisMonth: response.data?.isOrderDoneThisMonth,
        canOrderBeUpdate: response.data.canOrderBeUpdate,
      })
      setOrders(response.data?.orders ?? [])
      setEmptyRows(response.data?.orders.length > 0)
      setSummaryImputation(undefined)
    } else if (!isForced) {
      setEmptyRows(false)
      setImputationProductType(undefined)
    }
  }

  const initialize = async (): Promise<void> => {
    if (productType === imputationProductType) return

    loadOrders()
  }

  const loadOrders = async (): Promise<void> => {
    startLoading()

    search('')

    const response = await ordersImputationService().GetOrders(productType)

    stopLoading()
    completeLoad(response, false)
  }

  const deleteOrder = async (id: string): Promise<boolean> => {
    startLoading()

    const response = await ordersImputationService().DeleteOrder(id, productType)

    stopLoading()

    const valid = handleMetaResponse(response?.meta, undefined, {
      successMessage: t(orderImputationsTranslations.configure.delete.notification),
    })

    if (valid) loadOrders()

    return valid
  }

  const imputeOrders = async (data: ImputationForm): Promise<boolean> => {
    startLoading()

    const response = await ordersImputationService().ImputeOrders(data, productType)

    stopLoading()

    const valid = handleMetaResponse(response?.meta, undefined, {
      successMessage: t(orderImputationsTranslations.configure.impute.notification),
    })

    if (valid) loadOrders()

    return valid
  }

  const calculate = async (data: ImputationForm): Promise<void> => {
    startLoading()

    const response = await ordersImputationService().CalculateImputations(
      data,
      productType
    )

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setSummaryImputation(response.data)
      navigate(
        flexNavigationRoutes.flexProductsImputation
          .replace(':productType', productTypeSlug as string)
          .replace(':step', Steps.StepTwo)
      )
    }
  }

  const confirm = async (): Promise<boolean> => {
    startLoading()

    const response = await ordersImputationService().ConfirmImputations({
      productType: productType,
      summaryId: summaryImputation?.summaryId ? summaryImputation?.summaryId : '',
    })

    stopLoading()

    const isSuccess = handleMetaResponse(response?.meta, undefined, {
      notifySuccess: false,
    })

    if (isSuccess) {
      setOrders([])
      setSummaryImputation(undefined)
      navigate(
        flexNavigationRoutes.flexProductsImputation
          .replace(':productType', productTypeSlug as string)
          .replace(':step', Steps.StepThree)
      )
    } else {
      setErrorData(response.meta.messages)
    }
    setSuccessData(isSuccess)
    return isSuccess
  }

  const search = (s: string): void => {
    setPage(1)
    setSearchParam(s)
  }

  const back = (): void => {
    navigate(
      flexNavigationRoutes.flexProductsImputation
        .replace(':productType', productTypeSlug as string)
        .replace(':step', Steps.StepOne)
    )

    setSummaryImputation(undefined)
  }

  const backToStart = (): void => {
    navigate(
      flexNavigationRoutes.flexProductsImputation
        .replace(':productType', productTypeSlug as string)
        .replace(':step', Steps.StepOne)
    )
    executeInitialize()
  }

  const backToHome = (): void => {
    navigate(flexNavigationRoutes.flex)
  }

  const backToPayroll = (): void => {
    navigate(flexNavigationRoutes.flexPayrolls)
  }

  const validateInitialize = async (): Promise<void> => {
    if (
      (step === Steps.StepTwo && !summaryImputation) ||
      (step === Steps.StepThree && !successData)
    ) {
      backToStart()
      return
    }

    if (
      step !== Steps.StepOne ||
      (orders && !successData && productType === imputationProductType)
    ) {
      return
    }

    await executeInitialize()
  }

  const executeInitialize = async (): Promise<void> => {
    search('')
    setDates({ cutoffDate: '', imputationMonth: '', nextMonth: '', updatedDate: '' })
    setOrders([])
    setSummaryImputation(undefined)
    setSuccessData(undefined)
    setErrorData(undefined)
    await loadOrders()
  }

  const getProductIsActive = (productType: number): boolean => {
    const prod = getAllProducts.find(x => x.productType === productType)
    if (prod) {
      return !!prod.active
    }
    return false
  }

  useEffect(() => {
    if (getProductIsActive(productType)) {
      validateInitialize()
    }
  }, [step, productType])

  const rows = useMemo(() => {
    if (!orders) return []

    if (searchParam === '') return orders

    return orders.filter(
      orderLine =>
        orderLine?.owner?.toLowerCase().includes(searchParam.toLowerCase()) ||
        orderLine?.ownerId?.toLowerCase().includes(searchParam.toLowerCase()) ||
        orderLine?.name?.toLowerCase().includes(searchParam.toLowerCase())
    )
  }, [page, orders, searchParam])

  return {
    productType,
    orders,
    emptyRows,
    dates,
    conditions,
    form,
    loadOrders,
    deleteOrder,
    imputeOrders,
    calculate,
    search,
    searchParam,
    numberOfRecordsPerPage,
    onPageChange,
    page,
    rows,
    summaryImputation,
    back,
    confirm,
    backToHome,
    backToPayroll,
    forceUpdate,
    errorData,
    backToStart,
  }
}
