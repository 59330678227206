import React from 'react'
import { useTGDOrderSelectMultipleEmployees } from '../hooks/useTGDOrderSelectMultipleEmployees'

import { TGDOrderSelectMultipleEmployeesList } from '../molecules/TGDOrderSelectMultipleEmployeesList'
import { TGDOrderSelectMultipleEmployeesFooterButtons } from '../molecules/TGDOrderSelectMultipleEmployeesFooterButtons'

export const TGDOrderSelectMultipleEmployeesContainer = () => {
  const props = useTGDOrderSelectMultipleEmployees()
  return (
    <div>
      <TGDOrderSelectMultipleEmployeesList employeesTGDProps={props} />
      <TGDOrderSelectMultipleEmployeesFooterButtons
        selectedEmployees={props.selectedEmployees}
        tableIsEmpty={props.employeesTGD && props.employeesTGD.length <= 0}
        addChilds={props.addChilds}
      />
    </div>
  )
}
