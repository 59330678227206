import { useTranslation } from 'react-i18next'
import { SimpleHeaderWithAddButtonTemplate } from 'src/presentation/Templates'
import { spendingRuleTranslation } from 'src/domain/translations'
import { SpendingRulesContainer } from './organism/SpendingRulesContainer'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'

export const SpendingRulesPage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <SimpleHeaderWithAddButtonTemplate
      title={t(spendingRuleTranslation.title)}
      addButtonEnabled={true}
      buttonAction={() => navigate(navigationRoutes.spendingRulesNew)}
      buttonText={t(spendingRuleTranslation.addButton)}
      organism={<SpendingRulesContainer />}
    />
  )
}
