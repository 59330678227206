import { AccountUsersAndLicensesUserData } from './AccountUsersAndLicensesUserData'
import { AccountUsersAndLicensesUserProfiles } from './AccountUsersAndLicensesUserProfiles'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { UsersAndLicensesFormModel } from 'src/domain/models/myAccount/UsersAndLicenses'
import { UseFormReturn } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'
import { OreMessageBar, OreStack, OreText } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { AccountUsersAndLicensesEditUserData } from './AccountUsersAndLicensesEditUserData'

interface Props {
  form: UseFormReturn<UsersAndLicensesFormModel, any>
  getUserDataForm: () => TextBoxRowModel<UsersAndLicensesFormModel>[]
  errorMessage: string
  isEditing?: boolean
  userData?: UsersAndLicensesFormModel
}

export const AccountUsersAndLicensesForm = ({
  form,
  getUserDataForm,
  errorMessage,
  isEditing,
  userData,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <FormProvider {...form}>
        <form>
          <OreStack space="xlarge">
            {!isEditing && (
              <AccountUsersAndLicensesUserData
                form={form}
                getUserDataForm={getUserDataForm}
              />
            )}
            {isEditing && userData && (
              <AccountUsersAndLicensesEditUserData userData={userData} />
            )}
            <AccountUsersAndLicensesUserProfiles form={form} />
            {errorMessage !== '' && (
              <OreMessageBar color="error">{errorMessage}</OreMessageBar>
            )}
            {!isEditing && (
              <OreText>
                {t(accountUsersLicensesTranslations.add.mandatoryFields)} (*)
              </OreText>
            )}
          </OreStack>
        </form>
      </FormProvider>
    </>
  )
}
