import { FieldValues, Path, UseFormReturn } from 'react-hook-form'
import { MessagesModel } from 'src/domain/models'

export const attachFormErrors = <T extends FieldValues>(
  form: UseFormReturn<T>,
  errors: MessagesModel[] | undefined = []
): boolean => {
  let errorCount = 0

  errors.forEach(error => {
    if (!error.value) return

    errorCount++

    form.setError(
      error.value as Path<T>,
      {
        type: 'server',
        message: error.description,
      },
      { shouldFocus: true }
    )
  })

  return errorCount > 0
}
