import { t } from 'i18next'
import { rechargesConfigurationTranslations } from '../../../../domain/translations/cardOrder/rechargesConfiguration'
import { SimpleForm } from '../../../Templates'
import { Container } from './organism/Container'

export const RechargesConfigurationPage = (): JSX.Element => {
  return (
    <SimpleForm
      title={t(rechargesConfigurationTranslations.title)}
      organisms={[<Container />]}
    />
  )
}
