import {
  OreIconButton,
  OreStack,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgBin,
  SvgEdit,
} from '@runroom/oreneta'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useTranslation } from 'react-i18next'
import {
  TGDChildModel,
  TGDEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { GetDateFormatted } from 'src/core/helpers'
import { currency, tgdOrderType } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { addComma } from 'src/core/services'

interface Props {
  tgdOrder: TGDEmployeeAndChildsModel
  onClickDelete: (id: string) => void
  onClickEdit: (tgdOrder: TGDEmployeeAndChildsModel) => void
}

export const TGDOrderSummaryTableRow = ({
  onClickDelete,
  onClickEdit,
  tgdOrder,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const GetTooltipText = (orderType: tgdOrderType): string => {
    switch (orderType) {
      case tgdOrderType.assignmentOnly:
        return t(TGDOrderTranslation.summaryAssignment.tooltips.editAssignment)
      case tgdOrderType.childOnly:
        return t(TGDOrderTranslation.summaryAssignment.tooltips.editNewChild)
      case tgdOrderType.employeeAndChild:
        return t(TGDOrderTranslation.summaryAssignment.tooltips.newEmployeeChild)
      default:
        return ''
    }
  }

  return (
    <OreTableRow valign="middle">
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {tgdOrder.name + ' ' + tgdOrder.firstSurname + ' ' + tgdOrder.secondSurname}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {tgdOrder.document}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreStack space="xsmall" placeContent="space-between">
          {tgdOrder.childs &&
            tgdOrder.childs.length > 0 &&
            tgdOrder.childs.map((child: TGDChildModel, index: number) => {
              return (
                <div key={index + child.firstName}>
                  <OreText bold size="text-sm" tone="neutral-900">
                    {child.firstName + ' ' + child.lastName + ' ' + child.lastName2}
                  </OreText>
                  <OreText size="text-sm" tone="neutral-500">
                    {child.birthDate && GetDateFormatted(child.birthDate)}
                  </OreText>
                </div>
              )
            })}
        </OreStack>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreStack space="medium" placeContent="space-between">
          {tgdOrder.childs &&
            tgdOrder.childs.length > 0 &&
            tgdOrder.childs.map((child: TGDChildModel, index: number) => {
              return (
                <OreText size="text-sm" tone="neutral-900" key={index}>
                  {child.initialAmount ? child.initialAmount + currency.euro : '-'}
                </OreText>
              )
            })}
        </OreStack>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <OreStack space="medium" placeContent="space-between">
          {tgdOrder.childs &&
            tgdOrder.childs.length > 0 &&
            tgdOrder.childs.map((child: TGDChildModel, index: number) => {
              return (
                child.kindergartenData && (
                  <OreText
                    size="text-sm"
                    tone="neutral-900"
                    key={index + child.kindergartenId}>
                    {addComma(child.kindergartenData?.name) +
                      addComma(child.kindergartenData?.zipCode) +
                      child.kindergartenData?.city}
                  </OreText>
                )
              )
            })}
        </OreStack>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <Grid gap=".5rem">
          <OreTooltip text={t(forms.buttons.delete)} placement="top" separation="small">
            <OreIconButton onClick={() => onClickDelete(tgdOrder.id)} icon={<SvgBin />} />
          </OreTooltip>
          <OreTooltip
            text={GetTooltipText(tgdOrder.tgdOrderType)}
            placement="top"
            separation="small">
            <OreIconButton onClick={() => onClickEdit(tgdOrder)} icon={<SvgEdit />} />
          </OreTooltip>
        </Grid>
      </OreTableCell>
    </OreTableRow>
  )
}
