import type { UseFormRegister } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type {
  ComboBoxOptionModel,
  TextBoxRowModel,
} from '../../../../../domain/customComponents'
import { documentType } from '../../../../../domain/enum'
import type { CardOrderModel } from '../../../../../domain/models'
import {
  cardOrderFormTranslation,
  documentTypeEnumTranslation,
} from '../../../../../domain/translations'

export interface EmployeeDetailState {
  GetEmployeeDetailFirstSectionTextBoxes: () => TextBoxRowModel<CardOrderModel>[]
  GetEmployeeDetailSecondtSectionTextBoxes: () => TextBoxRowModel<CardOrderModel>[]
  GetEmployeeDetailDocumentTypeOptions: () => ComboBoxOptionModel[]
  register: UseFormRegister<CardOrderModel>
  errors: any
  disableEnterKey: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const useEmployeeDetailController = (): EmployeeDetailState => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CardOrderModel>()
  const { t } = useTranslation()

  const GetEmployeeDetailFirstSectionTextBoxes =
    (): TextBoxRowModel<CardOrderModel>[] => {
      return [
        {
          className: 'form-atom',
          textBoxes: [
            {
              id: 'name',
              label: t(cardOrderFormTranslation.employeeDetail.name),
              name: 'employeeData.name',
              placeHolder: t(cardOrderFormTranslation.employeeDetail.namePlaceHolder),
              required: true,
              type: 'text',
              googleApi: false,
              maxLength: 20,
              onKeyDown: disableEnterKey,
            },
          ],
        },

        {
          className: 'form-atom form-atom--half',
          textBoxes: [
            {
              id: 'firstSurname',
              label: t(cardOrderFormTranslation.employeeDetail.surname),
              name: 'employeeData.firstSurname',
              placeHolder: t(cardOrderFormTranslation.employeeDetail.surnamePlaceHolder),
              required: true,
              type: 'text',
              googleApi: false,
              maxLength: 20,
              onKeyDown: disableEnterKey,
            },

            {
              id: 'secondSurname',
              label: t(cardOrderFormTranslation.employeeDetail.secondSurname),
              name: 'employeeData.secondSurname',
              placeHolder: t(
                cardOrderFormTranslation.employeeDetail.secondSurnamePlaceHolder
              ),
              required: false,
              type: 'text',
              googleApi: false,
              maxLength: 20,
              onKeyDown: disableEnterKey,
            },
          ],
        },
      ]
    }
  const GetEmployeeDetailSecondtSectionTextBoxes =
    (): TextBoxRowModel<CardOrderModel>[] => {
      return [
        {
          className: 'form-atom form-atom--half',
          textBoxes: [
            {
              id: 'email',
              label: t(cardOrderFormTranslation.employeeDetail.email),
              name: 'employeeData.email',
              placeHolder: t(cardOrderFormTranslation.employeeDetail.emailPlaceHolder),
              legend: t(cardOrderFormTranslation.employeeDetail.emailLegend),
              required: true,
              type: 'email',
              googleApi: false,
              maxLength: 75,
              onKeyDown: disableEnterKey,
            },

            {
              id: 'telephone',
              label: t(cardOrderFormTranslation.employeeDetail.phone),
              name: 'employeeData.telephone',
              placeHolder: t(cardOrderFormTranslation.employeeDetail.phonePlaceHolder),
              required: false,
              type: 'tel',
              googleApi: false,
              maxLength: 9,
              onKeyDown: disableEnterKey,
            },
          ],
        },

        {
          className: 'form-atom form-atom--half',
          textBoxes: [
            {
              id: 'costCenter',
              label: t(cardOrderFormTranslation.employeeDetail.costCenter),
              name: 'employeeData.costCenter',
              placeHolder: t(
                cardOrderFormTranslation.employeeDetail.costCenterPlaceHolder
              ),
              required: false,
              type: 'text',
              googleApi: false,
              maxLength: 50,
              onKeyDown: disableEnterKey,
            },

            {
              id: 'employeeNumber',
              label: t(cardOrderFormTranslation.employeeDetail.employeeNumber),
              name: 'employeeData.employeeNumber',
              placeHolder: t(
                cardOrderFormTranslation.employeeDetail.employeeNumberPlaceHolder
              ),
              required: false,
              type: 'text',
              googleApi: false,
              maxLength: 10,
              onKeyDown: disableEnterKey,
            },
          ],
        },
      ]
    }
  const GetEmployeeDetailDocumentTypeOptions = (): ComboBoxOptionModel[] => {
    return [
      {
        options: [
          [t(documentTypeEnumTranslation.Default), documentType.Default],
          [t(documentTypeEnumTranslation.DniNie), documentType.DniNie],
          [t(documentTypeEnumTranslation.Passport), documentType.Passport],
        ],
      },
    ]
  }

  const disableEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  return {
    GetEmployeeDetailFirstSectionTextBoxes,
    GetEmployeeDetailSecondtSectionTextBoxes,
    GetEmployeeDetailDocumentTypeOptions,
    register,
    errors,
    disableEnterKey,
  }
}
