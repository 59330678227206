import { createContext } from 'react'

type AppConfigContextProps = {
  langCode: string //es en ca
  langCulture: string
  originalUrl: string // "/es/" to change url according to languages
  // version
  // env
  // add relevant data of the app config
  // initialpage
}

export const AppConfigContext = createContext<AppConfigContextProps>(
  {} as AppConfigContextProps
)
