import { Modal, Table } from '../../../../Edenred'
import { useIncompleteOrders } from '../hooks/useIncompleteOrders'
import { myOrders } from '../../../../../../domain/translations/cardOrder/myOrders'
import { t } from 'i18next'
import type { FooterModel } from '../../../../../../domain/customComponents/table'
import { OreHeading, OreText } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'

interface Props {
  showFilter: boolean
  showCaption: boolean
  pageSize: number
  showAllTotalRow?: boolean
  navigateFooter?: FooterModel
  isExtended: boolean
  productCode?: number
}

export const IncompleteOrders = (props: Props) => {
  const {
    caption,
    header,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    tableEmpty,
    setShowPopupDelete,
    popupDeleteShow,
    popupDeleteButtons,
  } = useIncompleteOrders(props)

  const { t } = useTranslation()

  return (
    <div role="tabpanel" className="mt-2">
      <Table
        caption={props.showCaption ? caption : undefined}
        header={header}
        rows={rows}
        setRows={setRows}
        footer={props.navigateFooter ? props.navigateFooter : footer}
        tableBodyValign="middle"
        filter={props.showFilter ? filter : undefined}
        setFilter={setFilter}
        onClickFilter={onClickFilter}
        tableEmpty={tableEmpty}
      />
      <Modal
        handleOnClose={setShowPopupDelete}
        open={popupDeleteShow}
        buttons={popupDeleteButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {t(myOrders.incompleteOrders.modal.delete.title)}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {t(myOrders.incompleteOrders.modal.delete.description)}
          </OreText>
        </>
      </Modal>
    </div>
  )
}
