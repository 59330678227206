import { t } from 'i18next'
import { Seq } from 'immutable'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../domain/customComponents/table'
import type {
  GeneralTermsContactsModel,
  GeneralTermsModel,
} from '../../domain/models/generalTerms/GeneralTermsModel'
import { generalTermsTranslation } from '../../domain/translations/myProducts/generalTerms/generalTermsTranslation'

export class GeneralTermsTablesContext {
  private strategy: Strategy
  constructor(strategy: Strategy) {
    this.strategy = strategy
  }

  SetStrategy(strategy: Strategy) {
    this.strategy = strategy
  }

  GetCaption(totalRow?: number): CaptionModel {
    return this.strategy.GetCaption(totalRow)
  }

  GetRows(generalTerms: GeneralTermsModel): RowModel[] {
    return this.strategy.GetRows(generalTerms)
  }

  GetHeader(): HeaderModel | undefined {
    return this.strategy.GetHeader ? this.strategy.GetHeader() : undefined
  }
}

interface Strategy {
  GetCaption: (totalRow?: number) => CaptionModel
  GetRows: (generalTerms: GeneralTermsModel) => RowModel[]
  GetHeader?: () => HeaderModel
}

export class PaymentAndInvoiceTable implements Strategy {
  GetCaption(): CaptionModel {
    return {
      title: t(generalTermsTranslation.paymentData.title),
      description: '',
    }
  }

  GetRows(generalTerms: GeneralTermsModel): RowModel[] {
    return [
      {
        rowId: 1,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.paymentData.paymentMode),
                name: 'paymentMode',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.paymentModeName,
                name: 'paymentModeName',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
      {
        rowId: 2,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.paymentData.paymentTerm),
                name: 'paymentTerm',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.paymentTermName,
                name: 'paymentTermName',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
      {
        rowId: 3,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.paymentData.invoiceType),
                name: 'invoiceType',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.isElectronicInvoice
                  ? t(generalTermsTranslation.paymentData.invoiceTypeElectronic)
                  : t(generalTermsTranslation.paymentData.invoiceTypePaper),
                name: 'invoiceTypeName',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
      {
        rowId: 4,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.paymentData.invoiceComment),
                name: 'invoiceComment',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.invoiceComment,
                name: 'invoiceComment',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
    ]
  }
}

export class GeneralInformationTable implements Strategy {
  GetCaption(): CaptionModel {
    return {
      title: t(generalTermsTranslation.generalInformation.title),
      description: '',
    }
  }

  GetRows(generalTerms: GeneralTermsModel): RowModel[] {
    return [
      {
        rowId: 1,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.generalInformation.contractOpenDate),
                name: 'contractOpenDateLabel',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.contractOpenDate,
                name: 'contractOpenDate',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
      {
        rowId: 2,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.generalInformation.contractClosedDate),
                name: 'contractClosedDateLabel',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.contractClosedDate,
                name: 'contractClosedDate',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
      {
        rowId: 3,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: t(generalTermsTranslation.generalInformation.totalUsers),
                name: 'totalUsersLabel',
                size: 'text-sm',
                isBold: true,
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: generalTerms.totalUsers ? generalTerms.totalUsers.toString() : '',
                name: 'totalUsers',
                size: 'text-sm',
              },
            ],
          },
        ],
        hover: false,
      },
    ]
  }
}

export class ContactsTable implements Strategy {
  GetCaption(): CaptionModel {
    return {
      title: t(generalTermsTranslation.contacts.table.title),
      description: t(generalTermsTranslation.contacts.table.totalContacts),
    }
  }

  GetRows(generalTerms: GeneralTermsModel): RowModel[] {
    if (!generalTerms.contacts) return []
    const contactsRows: RowModel[] = []
    Seq(generalTerms.contacts).forEach(
      (contact: GeneralTermsContactsModel, index: number) => {
        const lastOrderRow: RowModel = {
          rowId: index + 1,
          cells: [
            {
              type: 'text',
              cellText: [{ text: contact.name, name: 'name', size: 'text-sm' }],
            },
            {
              type: 'text',
              cellText: [{ text: contact.roleName, name: 'roleName', size: 'text-sm' }],
            },
            {
              type: 'text',
              cellText: [{ text: contact.email, name: 'email', size: 'text-sm' }],
            },
            {
              type: 'text',
              cellText: [{ text: contact.phone, name: 'phone', size: 'text-sm' }],
            },
          ],
          hover: false,
        }
        contactsRows.push(lastOrderRow)
      }
    )
    return contactsRows
  }

  GetHeader(): HeaderModel {
    return {
      headerLabel: [
        { label: t(generalTermsTranslation.contacts.table.holder) },
        { label: t(generalTermsTranslation.contacts.table.responsible) },
        { label: t(generalTermsTranslation.contacts.table.email) },
        { label: t(generalTermsTranslation.contacts.table.phone) },
      ],
    }
  }
}
