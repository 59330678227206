import { t } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'
import type React from 'react'
import { useEffect, useState } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
} from '../../../../../domain/customComponents/table'
import type {
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import {
  MetaStatusCodes,
  NotificationSeverity,
  Repositories,
  currency,
  edenredProducts,
  emptyValues,
} from '../../../../../domain/enum'
import type { HttpModel } from '../../../../../domain/models'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { forms, productsTranslate } from '../../../../../domain/translations'
import { Seq } from 'immutable'
import { RepositoryFactory } from '../../../../../core/factories'
import {
  UsersAccountOperationsRequestParams,
  type UsersAccountOperationsParams,
  type UsersAccountOperationsResponse,
  IUsersAccountOperations,
} from 'src/domain/history'
import {} from 'src/domain/adapters/historyConsumptionAdapters'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import {
  GetHistoryConsumptionsGroupByFilter,
  GetHistoryRechargeUnloadTypeFilter,
} from 'src/core/services/historySpendingService'
import { myOrders } from '../../../../../domain/translations/cardOrder/myOrders'
import { historyConsumptionsTranslations } from 'src/domain/translations/history/historyConsumption'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import type {
  IPostPaginationModel,
  useHistorySpendingProps,
} from 'src/domain/interfaces/history/HistorySpending'
import { accountOperationTypeCode, historyConsumptions } from 'src/domain/enum/history'
import { HistoryService } from 'src/core/services/historyService'
import { getUndefinedDashString } from 'src/core/services'
import { GetDateFormatted } from 'src/core/helpers'

interface ControlState {
  header: HeaderModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  footer: FooterModel
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  pageSize: number
  downloadExcel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export const useRechargeAndUnloadSpending = ({
  pageSize,
  showFilter,
  productCode,
}: useHistorySpendingProps): ControlState => {
  const [consumptionList, setConsumptionList] = useState<IUsersAccountOperations[]>([])
  const { startLoading, stopLoading } = useLoader()
  const [filter, setFilter] = useState<FilterModel>({})
  const [rows, setRows] = useState<RowModel[]>([])
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [usersAccountOperationsParams, setUsersAccountOperationsParams] =
    useState<UsersAccountOperationsParams>()

  const isServerSide = true
  const { addNotification } = useNotification()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      { label: t(employeesTranslation.table.header.identityNumber) },
      { label: t(employeesTranslation.profile.form.employeeNumber) },
      { label: t(historyConsumptionsTranslations.type) },
      { label: t(historyConsumptionsTranslations.amount) },
      { label: t(historyConsumptionsTranslations.date) },
    ],
  }

  const HandleResponse = (response: HttpModel<UsersAccountOperationsResponse>): void => {
    const responseRows = response?.data?.rows ? response.data.rows : []
    setTotalRows(response?.data?.totalRows ? response.data.totalRows : 0)
    setConsumptionList(responseRows)
  }

  function SetPagination(): IPostPaginationModel {
    return {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    }
  }

  const RequestTablePage = (
    page: number,
    tableFilters: UsersAccountOperationsParams = {}
  ): void => {
    const repository = RepositoryFactory(Repositories.history)

    ValidateFilters()
    const pagination: IPostPaginationModel = SetPagination()

    const response = repository.GetUserRechargeUnloadComsumtionList(
      tableFilters,
      pagination
    )
    startLoading()
    response
      .then((response: HttpModel<UsersAccountOperationsResponse>) => {
        HandleResponse(response)
      })
      .catch(error => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      })
      .finally(() => stopLoading())

    function ValidateFilters() {
      if (tableFilters[UsersAccountOperationsRequestParams.productCode] === undefined) {
        tableFilters[UsersAccountOperationsRequestParams.productCode] = productCode
      }
      if (tableFilters[UsersAccountOperationsRequestParams.multiFilter] === undefined) {
        tableFilters[UsersAccountOperationsRequestParams.multiFilter] = emptyValues.empty
      }
      if (
        tableFilters[UsersAccountOperationsRequestParams.accountOperationTypeCode] ===
        undefined
      ) {
        tableFilters[UsersAccountOperationsRequestParams.accountOperationTypeCode] =
          accountOperationTypeCode.all
      }
      if (tableFilters[UsersAccountOperationsRequestParams.groupBy] === undefined) {
        tableFilters[UsersAccountOperationsRequestParams.groupBy] =
          historyConsumptions.groupByUser
      }
      if (tableFilters[UsersAccountOperationsRequestParams.beginDate] === undefined) {
        tableFilters[UsersAccountOperationsRequestParams.beginDate] = GetMinAllowedDate()
      }
      if (tableFilters[UsersAccountOperationsRequestParams.endDate] === undefined) {
        tableFilters[UsersAccountOperationsRequestParams.endDate] = GetMaxAllowedDate()
      }
    }
  }

  const onClickFilter = (): void => {
    OnSearch()
  }

  const GetFilterValues = (): UsersAccountOperationsParams => {
    const tableFilters: UsersAccountOperationsParams = {}

    // Textbox filter: Name, nif/nie, employee number
    if (filter.search && filter.search.textBox.value !== '')
      tableFilters[UsersAccountOperationsRequestParams.multiFilter] =
        filter.search.textBox.value?.trim()

    // Dropdown filters: dates, group by
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          // "Type" filter, all, recharge or unload
          if (field.name === UsersAccountOperationsRequestParams.accountOperationTypeCode)
            tableFilters[UsersAccountOperationsRequestParams.accountOperationTypeCode] =
              Number(field.value ? field.value : accountOperationTypeCode.all)

          // Group by filter, person or date
          if (
            field.name === UsersAccountOperationsRequestParams.groupBy &&
            field.value !== ''
          )
            tableFilters[UsersAccountOperationsRequestParams.groupBy] = Number(
              field.value
            )

          // Filtro min allowed date
          if (
            field.name === UsersAccountOperationsRequestParams.beginDate &&
            field.value !== ''
          )
            tableFilters[UsersAccountOperationsRequestParams.beginDate] = field.value

          // Filtro max allowed date
          if (
            field.name === UsersAccountOperationsRequestParams.endDate &&
            field.value !== ''
          )
            tableFilters[UsersAccountOperationsRequestParams.endDate] = field.value
        })
      })
    }
    return tableFilters
  }

  const OnSearch = (): void => {
    const tableFilters: UsersAccountOperationsParams = GetFilterValues()
    setUsersAccountOperationsParams(tableFilters)
    RequestTablePage(1, tableFilters)
    setPage(1)
  }

  const GetRows = (): void => {
    const consumptionsRows: RowModel[] = []
    Seq(consumptionList).forEach(
      (consumption: IUsersAccountOperations, index: number) => {
        consumptionsRows.push({
          rowId: index,
          cells: [
            {
              type: 'text',
              cellText: [
                {
                  text: consumption.userName,
                  name: 'userName',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: consumption.userDni,
                  name: 'userDni',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: getUndefinedDashString(consumption.employeeNumber),
                  name: 'employeeNumber',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: consumption.accountOperationType,
                  name: 'accountOperationType',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: consumption.amount.toString() + currency.euro,
                  name: 'amount',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text:
                    consumption.dateValue === null
                      ? emptyValues.dash
                      : GetDateFormatted(consumption.dateValue),
                  name: 'dateValue',
                  size: 'text-sm',
                },
              ],
            },
          ],
        })
      }
    )
    setRows(consumptionsRows)
  }

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: isServerSide,
        totalRows: totalRows,
      },
    })
  }

  const GetMinAllowedDate = (): string => {
    const today = new Date()
    today.setDate(1)
    today.setMonth(today.getMonth() - 12)
    const minAllowedDate = today.toISOString().split('T')[0]
    return minAllowedDate
  }

  const GetMaxAllowedDate = (): string => {
    return new Date().toISOString().split('T')[0]
  }

  const SetFilters = (): void => {
    setFilter({
      showOpen: true,
      search: {
        textBox: {
          fields: [UsersAccountOperationsRequestParams.multiFilter],
          placeHolder: t(historyConsumptionsTranslations.filters.searchBoxPlaceholder),
          value: '',
        },
        searchButton: t(myOrders.filter.search.button),
      },
      filerOptions: [],
      resetButton: t(myOrders.filter.resetButtons),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'datePicker',
              name: UsersAccountOperationsRequestParams.beginDate,
              label: t(myOrders.filter.filterFields.dateFrom.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateFrom.placeholder
              ).toUpperCase(),
              value: GetMinAllowedDate(),
              min: GetMinAllowedDate(),
              max: GetMaxAllowedDate(),
            },
            {
              filterType: 'datePicker',
              name: UsersAccountOperationsRequestParams.endDate,
              label: t(myOrders.filter.filterFields.dateTo.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateTo.placeholder
              ).toUpperCase(),
              value: GetMaxAllowedDate(),
              min: GetMinAllowedDate(),
              max: GetMaxAllowedDate(),
            },
          ],
        },
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'comboBox',
              name: UsersAccountOperationsRequestParams.groupBy,
              label: t(historyConsumptionsTranslations.filters.groupby),
              placeHolder: t(myOrders.filter.filterFields.status.placeholder),
              value: historyConsumptions.groupByUser.toString(),
              options: [{ options: GetHistoryConsumptionsGroupByFilter(t) }],
            },
            {
              filterType: 'comboBox',
              name: UsersAccountOperationsRequestParams.accountOperationTypeCode,
              label: t(historyConsumptionsTranslations.type),
              placeHolder: t(myOrders.filter.filterFields.status.placeholder),
              value: '',
              options: [{ options: GetHistoryRechargeUnloadTypeFilter(t) }],
            },
          ],
        },
      ],
    })
  }

  const getProductLabelPrefix = (): string => {
    if (productCode === edenredProducts.ticketRestaurant) {
      return t(productsTranslate.ticketRestaurant).replace(' ', '')
    } else {
      return t(productsTranslate.ticketTransporte)
    }
  }

  const downloadExcel = (): void => {
    startLoading()
    HistoryService()
      .downloadRechargeAndUnloadExcel({
        ...usersAccountOperationsParams,
        productCode: productCode,
      })
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = `${getProductLabelPrefix()}_Consumos_Recargas_y_Descargas.xlsx`
          link.click()
          link.remove()
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  // Set filters first render only
  useEffect(() => {
    if (!showFilter) return
    SetFilters()
  }, [])

  // After an API call response update rows and page numbers
  useEffect(() => {
    GetRows()
    getFooter()
  }, [consumptionList])

  // On page change make a new request with the new page
  useEffect(() => {
    const filter = GetFilterValues()
    setUsersAccountOperationsParams(filter)
    RequestTablePage(page, filter)
  }, [page])

  return {
    header,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    pageSize,
    downloadExcel,
  }
}
