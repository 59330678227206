import { EmptyTableImage } from 'src/presentation/components/Edenred/EmptyTableImage'
import { clientOrderTableHomeType } from '../../../../../domain/enum'
import { Modal, Table } from '../../../Edenred'
import { useOrderClienteHome } from '../hooks/useOrderClienteHome'
import { useRepeatLastOrdersController } from '../hooks/useRepeatLastOrdersController'
import { t } from 'i18next'
import FavoriteOrders from '../../../../assets/img/FavoriteOrders.svg'
import { clientHomeTranslation } from 'src/domain/translations'

export const RepeatFavoriteOrdersClientHome = () => {
  const { footer } = useRepeatLastOrdersController()
  const {
    caption,
    rows,
    setRows,
    showModalRepetExcel,
    setShowModalRepetExcel,
    titleModalRepetExcel,
    descriptionModalRepetExcel,
    buttonsModalRepetExcel,
  } = useOrderClienteHome(clientOrderTableHomeType.favorite)

  if (rows.length > 0)
    return (
      <>
        <Table caption={caption} rows={rows} setRows={setRows} footer={footer} />
        <Modal
          handleOnClose={setShowModalRepetExcel}
          open={showModalRepetExcel}
          title={titleModalRepetExcel}
          description={descriptionModalRepetExcel}
          buttons={buttonsModalRepetExcel}
        />
      </>
    )
  else
    return (
      <EmptyTableImage
        image={FavoriteOrders}
        description={t(
          clientHomeTranslation.ticketRestaurant.favoriteOrder.caption
            .emptyTableDescription
        )}
        title={caption.title}
        subtitle={t(
          clientHomeTranslation.ticketRestaurant.favoriteOrder.caption.emptyTableSubtitle
        )}
      />
    )
}
