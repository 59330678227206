import { OreButton, OreDivider } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EmployeesNurseryModel } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useTGDOrder } from '../../context/TGDOrderProvider'

interface Props {
  selectedEmployees: EmployeesNurseryModel[]
  tableIsEmpty: boolean
  addChilds(employees?: EmployeesNurseryModel[]): void
}

export const TGDOrderSelectMultipleEmployeesFooterButtons = ({
  selectedEmployees,
  tableIsEmpty,
  addChilds,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setTGDEmployeesToConfig } = useTGDOrder()

  return (
    <div className="py-2">
      <OreDivider />
      <div className="py-1">
        <Grid justify="flex-end" gap="1.5rem">
          <OreButton
            as="button"
            category="secondary"
            size="large"
            onClick={() => {
              setTGDEmployeesToConfig([])
              navigate(-1)
            }}>
            {t(forms.buttons.cancel)}
          </OreButton>
          <OreButton
            as="button"
            category="primary"
            size="large"
            disabled={
              (selectedEmployees && selectedEmployees.length <= 0) || tableIsEmpty
            }
            onClick={() => addChilds(selectedEmployees)}>
            {t(TGDOrderTranslation.employees.multipleSelect.configAssignmentButton)}
          </OreButton>
        </Grid>
      </div>
    </div>
  )
}
