import { useUser } from 'src/presentation/context/user/UserProvider'
import { getFlexMenu, sidebarMenu } from '../data'
import { SidebarMenuItemModel } from '../sidebar/Sidebar'
import { useUserData } from 'src/Flex/User/ui/context'

interface ManagedNavigationState {
  isFlex: boolean
  getSidebarMenu: (currentTab?: number) => SidebarMenuItemModel[]
}

export const useManagedNavigation = (): ManagedNavigationState => {
  const { contracts } = useUser()
  const { getAllProducts: activeProducts } = useUserData()
  const isFlex = !!contracts?.some(
    contract => contract.ssaAllowFlex === true && contract.closedDate === null
  )

  const getSidebarMenu = (currentTab?: number): SidebarMenuItemModel[] => {
    if (isFlex) {
      if (currentTab === 0) {
        return sidebarMenu
      } else {
        return getFlexMenu(activeProducts)
      }
    }

    return sidebarMenu
  }

  return {
    isFlex,
    getSidebarMenu,
  }
}
