import { useEffect, useState } from 'react'
import { TGDEmployeeAndChildsModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { browserStorageKeys } from 'src/domain/enum'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

interface ControlState {
  tgdOrderItemEmployee: TGDEmployeeAndChildsModel | undefined
}

export const useTGDOrderNewChildFormController = (): ControlState => {
  const [tgdOrderItemEmployee, setTgdOrderItemEmployee] =
    useState<TGDEmployeeAndChildsModel>()
  const navigate = useNavigate()

  const getTGDEmployeeToConfig = (): void => {
    const employeeData = sessionStorage.getItem(browserStorageKeys.tgdOrder)

    if (!employeeData) navigate(navigationRoutes.tgdOrderSummary)

    setTgdOrderItemEmployee(employeeData ? JSON.parse(employeeData) : [])
  }

  useEffect(() => {
    getTGDEmployeeToConfig()
  }, [])

  return {
    tgdOrderItemEmployee,
  }
}
