import { edenredProducts } from 'src/domain/enum'
import { MetaModel } from 'src/domain/models'

export enum TaskTypeEnum {
  pendingContract = 1,
  pendingAdhesion = 2,
  pendingImputation = 3,
  pendingValidation = 4,
  pendingWelcomeCommunication = 5,
  pendingOnboardingSteps = 6,
}

export enum StepEnum {
  productsConfiguration = 1,
  collectivesConfiguration = 2,
  welcomeCommunicationConfiguration = 3,
  employeesConfiguration = 4,
}

interface TaskOnboardingModel {
  step?: StepEnum
  active?: boolean
}

interface TaskProductModel {
  productType?: edenredProducts
  amount?: number
}

export type TaskModel = TaskProductModel &
  TaskOnboardingModel & {
    type: TaskTypeEnum
    title: string
  }

export interface ApiTasksObject {
  data: TaskModel[] | null
  meta: MetaModel
}

export interface ApiGroupedTasks {
  [TaskTypeEnum.pendingImputation]: TaskModel[]
  [TaskTypeEnum.pendingValidation]: TaskModel[]
  [TaskTypeEnum.pendingContract]: TaskModel[]
  [TaskTypeEnum.pendingAdhesion]: TaskModel[]
  [TaskTypeEnum.pendingWelcomeCommunication]: TaskModel[]
  [TaskTypeEnum.pendingOnboardingSteps]: TaskModel[]
}

export interface GroupedTasks {
  [TaskTypeEnum.pendingImputation]: TaskModel[]
  [TaskTypeEnum.pendingValidation]: TaskModel[]
  [TaskTypeEnum.pendingOnboardingSteps]: TaskModel[]
  employees: TaskModel[]
  general: TaskModel[]
}
