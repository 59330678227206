import { useModalController } from '../../../../Edenred'

export const useValidationModal = () => {
  const { show, setShow } = useModalController()

  return {
    showWaitingModal: show,
    setShowWaitingModal: setShow,
  }
}
