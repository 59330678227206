import { useTTRechargeSelectMultipleEmployees } from '../hooks/useTTRechargeSelectMultipleEmployees'
import { TTRechargeSelectMultipleEmployeesFooterButtons } from '../molecules/TTRechargeSelectMultipleEmployeesFooterButtons'
import { TTRechargeSelectMultipleEmployeesList } from '../molecules/TTRechargeSelectMultipleEmployeesList'

export const TTRechargeSelectMultipleEmployeesContainer = (): JSX.Element => {
  const props = useTTRechargeSelectMultipleEmployees()
  return (
    <div>
      <TTRechargeSelectMultipleEmployeesList employeesTTProps={props} />
      <TTRechargeSelectMultipleEmployeesFooterButtons
        selectedEmployees={props.selectedEmployees}
        tableIsEmpty={props.employeesTT && props.employeesTT.length <= 0}
        goToConfig={props.goToConfig}
      />
    </div>
  )
}
