export const listToColumnsByTemplate = (
  template: string[],
  list: string[],
  columns: number
) => {
  const orderedList: string[] = []
  for (let c = 0; c < columns; c++) {
    template.forEach((m: string, index: number) => {
      if (index % columns === c) orderedList.push(m)
    })
  }

  return orderedList.filter((m: string) => list.includes(m))
}
