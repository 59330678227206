import { OreButton, OreHeading, SvgPlus } from '@runroom/oreneta'
import React from 'react'
import { Grid, GridItem } from '../components/Edenred/layout'
import { Divider } from '../layout'

interface Props {
  title: string
  buttonAction: () => void
  buttonText?: string
  organism?: React.ReactNode
  addButtonEnabled: boolean
}

export const SimpleHeaderWithAddButtonTemplate = (props: Props): JSX.Element => {
  return (
    <>
      <Grid items="flex-end" justify="space-between" gap="2rem">
        <OreHeading as="h1" size="title-md">
          {props.title}
        </OreHeading>
        {props.addButtonEnabled && (
          <>
            {props.buttonText && props.buttonAction ? (
              <GridItem className="text-nowrap">
                <OreButton
                  size="large"
                  icon={<SvgPlus />}
                  category="primary"
                  onClick={() => props.buttonAction()}>
                  {props.buttonText}
                </OreButton>
              </GridItem>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
      <Divider marginBottom="3rem" />
      {props.organism}
    </>
  )
}
