import { useEffect, useState } from 'react'
import { spendingRuleService } from 'src/core/services'
import { edenredProducts } from 'src/domain/enum'
import { SpendingRuleModel } from 'src/domain/models'

interface ControllerState {
  spendingRules: SpendingRuleModel[]
}

export const useReplaceSpendingRuleSelect = (): ControllerState => {
  const [spendingRules, setSpendingRules] = useState<SpendingRuleModel[]>([])

  const fetchAllSpendingRules = async (): Promise<void> => {
    const { data } = await spendingRuleService().getAll({
      productCode: edenredProducts.ticketRestaurant,
    })
    if (data) {
      setSpendingRules(data.usageProfiles.rows)
    }
  }

  useEffect(() => {
    fetchAllSpendingRules()
  }, [])

  return {
    spendingRules,
  }
}
