import {
  AnswerDTO,
  ChargePayrollMonthOptions,
  ConfirmInputations,
  IOrderImputation,
  OrderImputationApiModel,
  OrdersImputationRepositoryContract,
  SummaryImputationDTO,
} from 'src/Flex/Orders/domain'
import { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { ImputationForm } from 'src/Flex/Orders/ui/order-imputation'
import {
  confirmImputations,
  deleteImputationOrder,
  getImputationOrders,
  imputeImputationOrders,
  sammuryImputations,
  getRefreshImputations,
} from 'src/config/constants/endpoints'
import { edenredProducts } from 'src/domain/enum'
import { createQueryString } from 'src/core/helpers'

const ordersImputationFromApiToDomain = (
  imputationData: OrderImputationApiModel
): IOrderImputation => {
  const orders = imputationData?.pendingImputations?.rows.map((order, index) => ({
    id: order.id,
    position: index,
    owner: order.userName + ' ' + order.userLastName,
    ownerId: order.document,
    name: order.name,
    duration: order.numberOfMonths,
    totalImport: order.totalAmount,
    monthlyImport: order.monthAmount,
    remaining: order.remainingAmount,
    isEditable: order.canBeEdited,
    kinship: order.kinship,
    company: order.company,
    beneficiary: order.beneficiary,
    description: order.description,
  }))

  return {
    imputationMonth: imputationData?.imputationMonth,
    cutoffDate: imputationData?.cutoffDate,
    orders,
    doOrderNextMonth: imputationData?.doOrderNextMonth,
    isOrderDoneThisMonth: imputationData?.isOrderDoneThisMonth,
    updatedDate: imputationData?.updatedDate,
    canOrderBeUpdate: imputationData?.canOrderBeUpdate,
  }
}

export interface ImputationBodyModel {
  productType: edenredProducts
  imputations: ImputationSingleModel[]
  doOrderNextMonth?: boolean
}
export interface ImputationSingleModel {
  id: string
  manualImputation: number
}

export class OrdersImputationRepository implements OrdersImputationRepositoryContract {
  async GetOrders(productType: edenredProducts): Promise<HttpModel<IOrderImputation>> {
    const response = await axiosFetch(
      getImputationOrders +
        createQueryString({
          productType,
        }),
      HttpMethod.GET,
      {}
    )
    const data = ordersImputationFromApiToDomain(response.data)

    return {
      data,
      meta: response.meta,
    }
  }

  async GetRefreshOrders(
    productType: edenredProducts
  ): Promise<HttpModel<IOrderImputation>> {
    const response = await axiosFetch(
      getRefreshImputations.replace('{productType}', productType.toString()),
      HttpMethod.GET,
      {}
    )

    const data = ordersImputationFromApiToDomain(response.data)

    return {
      data,
      meta: response.meta,
    }
  }

  async DeleteOrder(id: string, productType: edenredProducts): Promise<AnswerDTO> {
    return await axiosFetch(
      deleteImputationOrder +
        createQueryString({
          id,
          productType,
        }),
      HttpMethod.DELETE,
      {}
    )
  }

  async ImputeOrders(
    data: ImputationForm,
    productType: edenredProducts
  ): Promise<AnswerDTO> {
    const imputableOrders = data.orders.filter(o => o.active)
    const orders: ImputationSingleModel[] = []

    imputableOrders.forEach(imputableOrder => {
      const order: ImputationSingleModel = { id: imputableOrder.id, manualImputation: 0 }

      if (imputableOrder.isEditable) {
        order.manualImputation = imputableOrder.value ?? 0
      }
      orders.push(order)
    })
    const imputation: ImputationBodyModel = {
      productType: productType,
      imputations: orders,
    }
    return await axiosFetch(
      imputeImputationOrders +
        createQueryString({
          productType,
        }),
      HttpMethod.POST,
      imputation
    )
  }

  async CalculateImputations(
    data: ImputationForm,
    productType: edenredProducts
  ): Promise<SummaryImputationDTO> {
    const imputableOrders = data.orders.filter(o => o.active)
    const orders: ImputationSingleModel[] = []

    imputableOrders.forEach(imputableOrder => {
      const order: ImputationSingleModel = { id: imputableOrder.id, manualImputation: 0 }

      if (imputableOrder.isEditable) {
        order.manualImputation = imputableOrder.value ?? 0
      }
      orders.push(order)
    })
    const imputation: ImputationBodyModel = {
      productType: productType,
      imputations: orders,
      doOrderNextMonth:
        data.chargePayrollMonth === ChargePayrollMonthOptions.Next ? true : false,
    }
    return await axiosFetch(sammuryImputations, HttpMethod.POST, imputation)
  }
  async ConfirmImputations(data: ConfirmInputations): Promise<AnswerDTO> {
    return await axiosFetch(confirmImputations, HttpMethod.POST, data)
  }
}
