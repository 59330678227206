import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import {
  ContactsTable,
  GeneralInformationTable,
  GeneralTermsTablesContext,
  PaymentAndInvoiceTable,
} from '../../../../core/strategies/GeneralTermsTables'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../domain/customComponents/table'

import type { GeneralTermsModel } from '../../../../domain/models/generalTerms/GeneralTermsModel'
import { generalTermsType } from '../../../../domain/enum/generalTerms'

interface GeneralTermsState {
  caption: CaptionModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  header?: HeaderModel
}

export const useGeneralTermsStrategiesController = (
  type: number,
  generalTerms: GeneralTermsModel
): GeneralTermsState => {
  const GetStrategyByType = (type: number): GeneralTermsTablesContext => {
    switch (type) {
      case generalTermsType.paymentData:
        return new GeneralTermsTablesContext(new PaymentAndInvoiceTable())
      case generalTermsType.generalInfo:
        return new GeneralTermsTablesContext(new GeneralInformationTable())
      case generalTermsType.contacts:
        return new GeneralTermsTablesContext(new ContactsTable())
      default:
        return new GeneralTermsTablesContext(new PaymentAndInvoiceTable())
    }
  }

  const [strategy, setStrategy] = useState<GeneralTermsTablesContext>(
    GetStrategyByType(type)
  )
  const [caption, setCaption] = useState<CaptionModel>({ description: '', title: '' })
  const [rows, setRows] = useState<RowModel[]>([])
  const [header, setHeader] = useState<HeaderModel>()

  //Load orders
  useEffect(() => {
    setStrategy(GetStrategyByType(type))
    setCaption(strategy.GetCaption(generalTerms.contacts?.length))
    setRows(strategy.GetRows(generalTerms))
    if (strategy.GetHeader) setHeader(strategy.GetHeader())
  }, [type, generalTerms])

  return { caption, rows, setRows, header }
}
