import cx from 'classnames'
import type { Gap, RemSpacing } from '../../../../../domain/types'

interface GridProps {
  columns?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 // converts to grid
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  items?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  gap?: RemSpacing
  rowGap?: RemSpacing
  columnGap?: RemSpacing
  children: React.ReactNode
  className?: string
}

const Grid = (props: GridProps): JSX.Element => {
  const getGap = (): Gap => {
    if (props.rowGap || props.columnGap) {
      return {
        rowGap: props.rowGap,
        columnGap: props.columnGap,
      }
    }
    return { gap: props.gap }
  }

  return (
    <div
      style={{
        justifyContent: props.justify,
        alignItems: props.items,
        flexDirection: props.direction,
        ...getGap(),
      }}
      className={cx(
        props.columns ? 'grid' : 'flex',
        { [`grid-col-${props.columns}`]: props.columns },
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

export default Grid
