import { useTranslation } from 'react-i18next'
import { accountSettingsTranslation } from '../../../../domain/translations/myAccount/accountSettings'
import { PageHeader } from '../../../Templates/PageHeader'
import { AccountSettingsContainer } from './organism/AccountSettingsContainer'

export const AccountSettingsPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <PageHeader
      title={t(accountSettingsTranslation.title)}
      organism={<AccountSettingsContainer />}
    />
  )
}
