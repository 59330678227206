import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreHeading,
  OreStack,
  OreText,
  OreWysiwyg,
  SvgChevronDown,
  SvgChevronUp,
} from '@runroom/oreneta'
import art from 'src/presentation/assets/logo/calculator_design.png'
import {
  EmployeeForm,
  useRetributionCalculator,
} from 'src/Flex/Employees/ui/employee-detail'
import { CollectiveModel } from 'src/Flex/Collectives/domain'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { UseFormReturn } from 'react-hook-form'

interface IProps {
  collectives?: CollectiveModel[]
  form: UseFormReturn<EmployeeForm>
}

export const RetributionCalculator = ({ collectives, form }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { value, collectiveFlexibleRemunerationPercentage } = useRetributionCalculator({
    collectives,
    form,
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <OreStack placeContent="stretch" space="large">
      <OreText size="text-md" tone="neutral" align="right" bold>
        {t(flexEmployeesTranslations.configure.calculator.percentage)}{' '}
        {collectiveFlexibleRemunerationPercentage
          ? collectiveFlexibleRemunerationPercentage + '%'
          : '-'}
      </OreText>
      <OreCardContainer hasShadow={false} tone="neutral-100">
        <OreCardWrapper>
          <OreStack space="medium" placeContent="stretch">
            <OreStack direction="row" placeContent="space-between">
              <OreHeading size="headline-sm" tone="neutral">
                {t(flexEmployeesTranslations.configure.calculator.title)}{' '}
                {value ? value : '-'}
              </OreHeading>
              <OreButton
                category="tertiary"
                onClick={() => setIsOpen(isOpen => !isOpen)}
                icon={isOpen ? <SvgChevronUp /> : <SvgChevronDown />}
                iconAlign="right">
                {t(flexEmployeesTranslations.configure.calculator.subtitle)}
              </OreButton>
            </OreStack>
            <OreStack
              style={{ display: isOpen ? 'grid' : 'none' }}
              direction="row"
              sameSize
              space="large">
              <OreWysiwyg>
                <p>
                  {t(flexEmployeesTranslations.configure.calculator.details.introduction)}
                </p>
                <ul>
                  <li>
                    <b>
                      {t(
                        flexEmployeesTranslations.configure.calculator.details.bullets
                          .first.field
                      )}
                    </b>{' '}
                    {t(
                      flexEmployeesTranslations.configure.calculator.details.bullets.first
                        .value
                    )}
                  </li>
                  <li>
                    <b>
                      {t(
                        flexEmployeesTranslations.configure.calculator.details.bullets
                          .second.field
                      )}
                    </b>{' '}
                    {t(
                      flexEmployeesTranslations.configure.calculator.details.bullets
                        .second.value
                    )}
                  </li>
                  <li>
                    <b>
                      {t(
                        flexEmployeesTranslations.configure.calculator.details.bullets
                          .third.field
                      )}
                    </b>{' '}
                    {t(
                      flexEmployeesTranslations.configure.calculator.details.bullets.third
                        .value
                    )}
                  </li>
                </ul>
                <p>
                  {t(flexEmployeesTranslations.configure.calculator.details.disclaimer)}
                </p>
              </OreWysiwyg>
              <img src={art} alt="" role="presentation" style={{ width: '100%' }} />
            </OreStack>
          </OreStack>
        </OreCardWrapper>
      </OreCardContainer>
    </OreStack>
  )
}
