import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type {
  CellHiddenModel,
  CellModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import { forms } from '../../../../../domain/translations'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useModalController } from '../useModalController'
import type {
  FilterModel,
  FilterOptionModel,
} from '../../../../../domain/customComponents/table'

interface NewTableState {
  rows: RowModel[]
  selectedRows: number[]
  totalRows: number[]
  setSelectedRows: Dispatch<React.SetStateAction<number[]>>
  setTotalRows: Dispatch<React.SetStateAction<number[]>>
  setRows: Dispatch<SetStateAction<RowModel[]>>
  modalTitle: string
  modalDescription: string
  setModalShow: Dispatch<SetStateAction<boolean>>
  modalShow: boolean
  modalButtons: PopupButtonModel[]
  deleteConfirm: boolean
  setDeleteConfirm: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  FilterRows: () => RowModel[]
  isDeleteDanger?: boolean
}

export const useNewTable = (
  rowsTable: RowModel[],
  setRowsTable: Dispatch<SetStateAction<RowModel[]>>,
  validateTable?: boolean,
  modalDeleteTitle?: string,
  modalDeleteDescription?: string,
  filter?: FilterModel,
  isDeleteDanger?: boolean
): NewTableState => {
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [totalRows, setTotalRows] = useState<number[]>([])
  const [rows, setRows] = useState<RowModel[]>([])
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const { stopLoading, isLoading } = useLoader()

  const {
    show: modalShow,
    title: modalTitle,
    description: modalDescription,
    buttons: modalButtons,
    setShow: setModalShow,
    setDescription: setModalDescription,
    setTitle: setModalTitle,
    setButtons: setModalButtons,
  } = useModalController()

  const GetTotalRows = () => {
    const newTotalRows: number[] = []
    Seq(rowsTable).forEach(row => {
      newTotalRows.push(row.rowId)
    })
    setTotalRows(newTotalRows)
  }

  const IsValidValue = (
    value: string | undefined,
    type?: 'number' | 'text' | 'email' | 'tel' | 'url' | undefined
  ): boolean => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    if (value === '' || value === undefined) {
      return false
    }

    if (type === 'email') {
      if (!value.match(mailformat)) {
        return false
      }
    }

    return true
  }

  const ErrorMessage = (
    value: string | undefined,
    type?: 'number' | 'text' | 'email' | 'tel' | 'url' | undefined
  ): string => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    if (value === '' || value === undefined) {
      return t(forms.errors.fieldRequired)
    }

    if (type === 'email') {
      if (!value.match(mailformat)) {
        return t(forms.errors.invalidFormat)
      }
    }

    return ''
  }

  const ValidateAllTable = useCallback(() => {
    setRows(prevRows =>
      prevRows.map((prevRow: RowModel, prevRowIndex: number) => {
        return {
          ...prevRow,
          cells: prevRow.cells.map((prevCell: CellModel, prevCellIndex: number) => {
            if (
              prevCell.type !== 'text' &&
              prevCell.type !== 'actions' &&
              prevCell.cellControll?.name
            )
              return {
                ...prevCell,
                align: prevCell.align,
                cellControll: {
                  ...prevCell.cellControll,
                  errorMassage:
                    prevCell.type === 'comboBox'
                      ? ErrorMessage(
                          prevCell.cellControll.value,
                          prevCell.cellControll.type
                        )
                      : prevCell.cellControll.massageErrorValidate
                      ? prevCell.cellControll.massageErrorValidate(
                          prevCell.cellControll.value
                        )
                      : '',
                  hasError:
                    prevCell.type === 'comboBox'
                      ? !IsValidValue(
                          prevCell.cellControll.value,
                          prevCell.cellControll.type
                        )
                      : prevCell.cellControll.errorValidate
                      ? prevCell.cellControll.errorValidate(prevCell.cellControll.value)
                      : false,
                },
              }
            return prevCell
          }),
        }
      })
    )
  }, [])

  const SetPopupButtons = () => {
    setModalButtons([
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setModalShow(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: !isDeleteDanger ? 'primary' : 'danger',
        onClick: () => {
          setDeleteConfirm(true)
          setModalShow(false)
        },
        size: 'large',
      },
    ])
  }

  const FilterRows = (): RowModel[] => {
    const filterRows: RowModel[] = []
    if (filter && filter.filerOptions)
      Seq(filter.filerOptions).forEach((option: FilterOptionModel) => {
        if (option.checked) {
          Seq(rows).forEach((row: RowModel) => {
            if (row.cellHidden) {
              Seq(row.cellHidden).forEach((hidden: CellHiddenModel) => {
                if (option.name === hidden.name && option.checked === hidden.value)
                  filterRows.push(row)
              })
            }
          })
        }
      })
    if (filterRows.length === 0) return rows
    return filterRows
  }

  const SetTableValuesAfterFillRows = (): void => {
    GetTotalRows()
    SetPopupButtons()
    if (modalDeleteTitle) setModalTitle(modalDeleteTitle)
    if (modalDeleteDescription) setModalDescription(modalDeleteDescription)
  }

  useEffect(() => {
    setRows(rowsTable)
    SetTableValuesAfterFillRows()
    // if (rowsTable.length > 0) {
    // }
  }, [rowsTable])

  useEffect(() => {
    if (validateTable === true) {
      ValidateAllTable()
    }
  }, [validateTable, rows.length > 0])

  useEffect(() => {
    if (rows.length > 0) {
      setRowsTable(rows)
      stopLoading()
    }
  }, [rows])

  // useEffect(() => {
  //     if(filter && filter.filerOptions && filter.filerOptions.length > 0){
  //         console.log(FilterRows());
  //     }
  // },[filter])

  return {
    rows,
    selectedRows,
    totalRows,
    setSelectedRows,
    setTotalRows,
    setRows,
    modalTitle,
    modalDescription,
    setModalShow,
    modalShow,
    modalButtons,
    deleteConfirm,
    setDeleteConfirm,
    isLoading,
    FilterRows,
  }
}
