import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { EmployeesService } from 'src/core/services/employeesService'
import { MetaStatusCodes, NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { EmployeesNurseryModel, EmployeesNurseryResponse } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

interface ControllerState {
  employeeData: EmployeesNurseryModel | undefined
  isEmployeeFlex: boolean
}

export const useFetchEmployeeData = (): ControllerState => {
  const { document } = useParams()
  const { startLoading, stopLoading } = useLoader()
  const [employeeData, setEmployeeData] = useState<EmployeesNurseryModel>()
  const [isEmployeeFlex, setIsEmployeeFlex] = useState<boolean>(false)
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const navigate = useNavigate()

  const fetchEmployeeData = async (): Promise<void> => {
    if (document) {
      startLoading()
      const { meta, data } = await EmployeesService().GetSingleEmployeeNursery({
        productCode: edenredProducts.ticketGuarderia,
        userDocument: document,
      })

      const { meta: meta2, data: isFlex } = await EmployeesService().isClientEmployeeFlex(
        document
      )

      // Handle API response
      if (
        meta.status === MetaStatusCodes.SUCCESS &&
        meta2.status === MetaStatusCodes.SUCCESS
      ) {
        setEmployeeData(data.rows[0])
        setIsEmployeeFlex(isFlex)
        stopLoading()
      } else {
        addNotification(t(forms.errors.wrongUrl), NotificationSeverity.error)
        stopLoading()
        navigate(-1)
      }
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      navigate(-1)
    }
  }

  useEffect(() => {
    fetchEmployeeData()
  }, [document])

  return {
    employeeData,
    isEmployeeFlex,
  }
}
