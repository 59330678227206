export const generalTermsType = {
  paymentData: 1,
  generalInfo: 2,
  contacts: 3,
}

export const generalTermsServiceType = {
  card: 'CARD',
  comission: 'COMMISSION',
  magagement: 'MANAGEMENT',
  delivery: 'DELIVERY',
  invoice: 'INVOICE',
}
