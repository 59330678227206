import { OreHeading, OreStack } from '@runroom/oreneta'
import React from 'react'
import SuccessLogo from 'src/presentation/assets/img/Success.svg'

interface OrderValidationSuccessProps {
  title: string
  body?: React.ReactNode
  ending?: React.ReactNode
}

export const OrderValidationSuccess = ({
  title,
  body,
  ending,
}: OrderValidationSuccessProps) => (
  <div style={{ maxWidth: '600px', margin: '0 auto' }}>
    <OreStack placeContent="center" placeItems="center" space="large">
      <img src={SuccessLogo} alt="" />
      <OreHeading as="h1" size="title-sm" align="center">
        {title}
      </OreHeading>
      {body ? body : null}
      {ending ? ending : null}
    </OreStack>
  </div>
)
