import {
  deleteTemplate,
  getAvailableTemplateVars,
  getAvailableTemplates,
  getTemplateConfiguration,
  indexTemplatesList,
  sendPendingWelcomeEmails,
  sendTemplate,
  testTemplate,
} from 'src/config/constants/endpoints'

import type { HttpModel, PaginationModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { createQueryString } from 'src/core/helpers'
import {
  SaveTemplateDTO,
  TemplateConfigurationModel,
  EmailSentModel,
  CommunicationsTemplatesRepositoryContract,
  TemplateModel,
  TemplateTargetEnum,
  CreateTemplateDTO,
  AvailableVarsModel,
  DeleteTemplateDTO,
} from 'src/Flex/CommunicationsTemplates/domain'

export class CommunicationsTemplatesRepository
  implements CommunicationsTemplatesRepositoryContract
{
  async GetTemplates(
    page: number,
    numberOfRecordsPerPage: number
  ): Promise<HttpModel<PaginationModel<TemplateModel>>> {
    return await axiosFetch(
      indexTemplatesList.concat(
        createQueryString({
          pageNumber: page - 1,
          numberOfRecordsPerPage,
        })
      ),
      HttpMethod.GET,
      {}
    )
  }

  async CreateTemplate(data: TemplateConfigurationModel): Promise<CreateTemplateDTO> {
    return await axiosFetch(getTemplateConfiguration, HttpMethod.POST, data)
  }

  async GetTemplateConfiguration(
    id?: string
  ): Promise<HttpModel<TemplateConfigurationModel>> {
    const response = await axiosFetch(
      getTemplateConfiguration.concat(createQueryString({ id })),
      HttpMethod.GET,
      {}
    )

    return {
      meta: response.meta,
      data: {
        name: response.data?.name,
        subject: response.data?.subject,
        description: response.data?.description,
        bodyEmail: response.data?.text,
        id: response.data?.id,
        type: response.data?.type,
        defaultText: response.data?.defaultText,
        availableVars: response.data?.availableVars,
        downloadOption: response.data?.downloadOption,
        optionalText: response.data?.optionalText,
      },
    }
  }

  async SaveTemplate(data: TemplateConfigurationModel): Promise<SaveTemplateDTO> {
    return await axiosFetch(getTemplateConfiguration, HttpMethod.PUT, {
      id: data.id,
      subject: data.subject,
      text: data.bodyEmail,
    })
  }

  async DeleteTemplateById(templateId: string): Promise<DeleteTemplateDTO> {
    return await axiosFetch(
      deleteTemplate.replace('{templateId}', templateId),
      HttpMethod.DELETE,
      {}
    )
  }

  async GetAvailableVars(): Promise<HttpModel<AvailableVarsModel[]>> {
    return await axiosFetch(getAvailableTemplateVars, HttpMethod.GET, {})
  }

  async GetAvailableTemplatesById(
    id: string,
    target: TemplateTargetEnum
  ): Promise<HttpModel<Partial<TemplateModel>[]>> {
    return await axiosFetch(
      getAvailableTemplates.concat(createQueryString({ id, type: target })),
      HttpMethod.GET,
      {}
    )
  }

  async SendTemplateById(
    id: string[],
    target: TemplateTargetEnum,
    templateId: string
  ): Promise<EmailSentModel> {
    const response = await axiosFetch(sendTemplate, HttpMethod.POST, {
      idsToSend: id,
      type: target,
      templateId,
    })

    return {
      count: response.data?.emailsSended,
      meta: response.meta,
    }
  }

  async SendPendingEmails(): Promise<EmailSentModel> {
    const response = await axiosFetch(sendPendingWelcomeEmails, HttpMethod.POST, {})
    return {
      count: response.data?.emailsSended,
      meta: response.meta,
    }
  }

  async TestTemplate(templateId: string, email: string): Promise<EmailSentModel> {
    const response = await axiosFetch(testTemplate, HttpMethod.POST, {
      templateId,
      email,
    })

    return {
      count: response.data?.emailsSended,
      meta: response.meta,
    }
  }
}
