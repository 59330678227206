import type { CardOrderModel } from '../models/cardOrder/CardOrderModel'

export interface SaveCardOrder {
  orderName: string
  expiredDate: string
  acceptConditions: boolean
  billReference: string
  initialChargeDate: string
  reminderDate: string
  orderTypeId: number
  productCode: number
  preOrderId?: number
}

export const EmptySaveCardOrder: SaveCardOrder = {
  orderName: '',
  expiredDate: '',
  acceptConditions: true,
  billReference: '',
  initialChargeDate: '',
  reminderDate: '',
  orderTypeId: 0,
  productCode: 0,
}

export interface SaveCardOrderProtocol extends SaveCardOrder {
  //guardar y salir
  cardOrders: CardOrderModel[]
}

export interface CardOrderProtocol {
  // tramitar pedido
  cardOrders: CardOrderModel[]
  productCode: number
}
