import React from 'react'
import { SimpleForm } from '../../../Templates'
import Container from './organism/Container'
import { useTranslation } from 'react-i18next'
import { recharge } from '../../../../domain/translations'

const RechargeHomePage = (): JSX.Element => {
  const { t } = useTranslation()
  return <SimpleForm title={t(recharge.title)} organisms={[<Container />]} />
}

export default RechargeHomePage
