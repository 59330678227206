import { edenredProducts } from 'src/domain/enum'
import { ChargePayrollMonthOptions } from 'src/Flex/Orders/domain'

export interface OrderValidationModel {
  orderLines: OrderValidationLine[]
  cutoffDate: Date | undefined
  chargePayrollMonth: ChargePayrollMonthOptions | undefined
  finished: boolean
  month: Date | undefined
  isCommentRequiredFromClient?: boolean | undefined
  updateDate?: string
  canOrderBeUpdate?: boolean
}

type OrderEmployee = {
  name: string
  document: string
  number: string
}

type OrderBeneficiary = {
  name: string
}

export interface OrderValidationLine {
  id: string
  position: number
  product: edenredProducts
  employee: OrderEmployee
  beneficiary?: OrderBeneficiary // Only for Ticket Guarderia
  kindergarten?: string // Only for Ticket Guarderia
  defaultAmount: number
  maxAmount: number
  deliverySite: string
  costCenter: string | undefined
}

export const isReadyToValidate = (orderValidation: OrderValidationModel): boolean => {
  let isReady = true
  if (orderValidation.finished) isReady = false
  if (!orderValidation.cutoffDate) isReady = false

  const today = new Date()
  return isReady
}
