import { SimpleHeader } from '../../../Templates/SimpleHeader'
import { Checkout } from './organism/Checkout'
import { useTitleTranslation } from '../cardOrderFormExcel/hooks/useTitleTranslation'
import { LeaveOrderPrompt } from '../../Edenred/navigation'

export const CardOrderCheckoutPage = (): JSX.Element => {
  const { getExcelTitle } = useTitleTranslation()
  return (
    <>
      <SimpleHeader title={getExcelTitle()} organisms={<Checkout />} />
      <LeaveOrderPrompt />
    </>
  )
}
