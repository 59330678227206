import { useTTUnloadSelectMultipleEmployees } from '../hooks/useTTUnloadSelectMultipleEmployees'
import { TTUnloadSelectMultipleEmployeesFooterButtons } from '../molecules/TTUnloadSelectMultipleEmployeesFooterButtons'
import { TTUnloadSelectMultipleEmployeesList } from '../molecules/TTUnloadSelectMultipleEmployeesList'

export const TTUnloadSelectMultipleEmployeesContainer = (): JSX.Element => {
  const props = useTTUnloadSelectMultipleEmployees()
  return (
    <div>
      <TTUnloadSelectMultipleEmployeesList employeesTTProps={props} />
      <TTUnloadSelectMultipleEmployeesFooterButtons
        selectedEmployees={props.selectedEmployees}
        tableIsEmpty={props.employeesTT && props.employeesTT.length <= 0}
        goToConfig={props.goToConfig}
      />
    </div>
  )
}
