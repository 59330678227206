import { currency, emptyValues } from '../enum'
import type {
  IUserConsumption,
  IUserConsumptionMapped,
  IUsersAccountOperations,
  IUsersAccountOperationsMapped,
} from '../history'
import { accountOperationTypeCode, historyConsumptions } from '../enum/history'
import { t } from 'i18next'
import { historyConsumptionsTranslations } from '../translations/history/historyConsumption'
import { getUndefinedDashString } from 'src/core/services'
import { GetDateFormatted } from 'src/core/helpers'
import { DietSpendingModel } from '../models/historySpendings'
import { AssignmentsModel } from '../models/historySpendings/AssignmentsModel'

export const mapHistoryConsumptionList = (
  userConsumption: IUserConsumption[]
): IUserConsumptionMapped[] => {
  const copiedArray = userConsumption.map(userConsumption => ({
    ...userConsumption,
    numEmpleado: getUndefinedDashString(userConsumption.numEmpleado),
    importe: userConsumption.importe + currency.euro,
    dateValue: GetDateValue(userConsumption),
  }))
  return copiedArray

  function GetDateValue(userConsumption: IUserConsumption): string {
    const dateValue = userConsumption.dateValue
    if (dateValue === null) {
      return emptyValues.dash
    } else {
      return GetDateFormatted(dateValue)
    }
  }
}

export const GetConsumptionGroupBy = (status: number): string => {
  switch (status) {
    case historyConsumptions.groupByUser:
      return t(historyConsumptionsTranslations.filters.groupByUser)
    case historyConsumptions.groupByUserAndDate:
      return t(historyConsumptionsTranslations.filters.groupByUserAndDate)
    default:
      return emptyValues.dash
  }
}

export const GetAccountOperationsType = (status: number): string => {
  switch (status) {
    case accountOperationTypeCode.recharge:
      return t(historyConsumptionsTranslations.recharge)
    case accountOperationTypeCode.unload:
      return t(historyConsumptionsTranslations.unload)
    default:
      return emptyValues.dash
  }
}

export const GetDietSpendingMap = (model: DietSpendingModel): DietSpendingModel => {
  let date = ''
  if (model.dateValue) {
    const newDate = new Date(model.dateValue)
    date =
      newDate.toLocaleDateString() +
      ' - ' +
      ((newDate.getHours() < 10 ? '0' : '') + newDate.getHours()) +
      ':' +
      ((newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes())
  }
  const spending: DietSpendingModel = {
    city: model.city,
    commerce: model.commerce,
    commerceAdress: model.commerceAdress,
    employeeNumber: model.employeeNumber,
    nif: model.nif,
    titular: model.titular,
    zipCode: model.zipCode,
    amount: model.amount,
    dateValue: date,
  }
  return spending
}

export const GetAssignmentsMap = (model: AssignmentsModel): AssignmentsModel => {
  return model
  // let date = ''
  // if (model.dateValue) {
  //   const newDate = new Date(model.dateValue)
  //   date =
  //     newDate.toLocaleDateString() +
  //     ' - ' +
  //     ((newDate.getHours() < 10 ? '0' : '') + newDate.getHours()) +
  //     ':' +
  //     ((newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes())
  // }
  // const spending: DietSpendingModel = {
  //   city: model.city,
  //   commerce: model.commerce,
  //   commerceAdress: model.commerceAdress,
  //   employeeNumber: model.employeeNumber,
  //   nif: model.nif,
  //   titular: model.titular,
  //   zipCode: model.zipCode,
  //   amount: model.amount,
  //   dateValue: date,
  // }
  // return spending
}
