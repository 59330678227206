import { useState, useEffect } from 'react'

import { useLoader } from '../context/loader/LoaderProvider'
import { useNotificationHandler } from './index'

type FetchState<T> = null | T | undefined

export const useAsync = <T>(
  method: () => any,
  pagination = 0,
  runFetch?: any,
  reset?: any
) => {
  const [state, setState] = useState<FetchState<T>>(null)
  const { startLoading } = useLoader()
  const { notificationHandler } = useNotificationHandler()
  const [run, setRun] = useState<boolean>(true)
  useEffect(() => {
    if (!run) return
    const fetcher = async () => {
      startLoading()
      const res = await method()
      notificationHandler(res.meta, false, setState(res.data))
    }
    fetcher()
    setRun(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, runFetch, reset, run])

  return { state, setState, setRun }
}
