import { OreNavBarHeader } from '@runroom/oreneta'
import { useUI } from '../../../../context/ui/UIProvider'
import { DevLogin, NavbarUser } from './molecules'
import { Help } from './molecules/Help'
import MultiCIF from './molecules/MultiCIF'

const Header = (): JSX.Element => {
  const { sidebarCollapsed, toggleSidebar } = useUI()

  return (
    <OreNavBarHeader handleClick={toggleSidebar} activeBurger={sidebarCollapsed}>
      <MultiCIF />
      <DevLogin />
      <Help />
      <NavbarUser />
    </OreNavBarHeader>
  )
}

export default Header
