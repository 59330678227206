import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import {
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import {
  orderValidationsTranslations,
  PaymentModePill,
} from 'src/Flex/Orders/ui/order-validation'
import {
  OrderValidationData,
  OrderValidationResumeDetailModel,
} from 'src/Flex/Orders/domain'
import { GetDateFormatted } from 'src/core/helpers'

type OrderValidationSummaryGeneralProps = {
  name: string
  detail: OrderValidationResumeDetailModel
  form: UseFormReturn<OrderValidationData>
}

export const OrderValidationSummaryGeneral = ({
  name,
  detail,
  form,
}: OrderValidationSummaryGeneralProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption title={name} />
        <OreTableBody>
          <OreTableRow>
            <OreTableCell>
              {t(orderValidationsTranslations.step2.general.initialChargeDate)}
            </OreTableCell>
            <OreTableCell>
              <OreText bold align="right" size="text-sm">
                {GetDateFormatted(form.getValues('chargeDate'))}
              </OreText>
            </OreTableCell>
          </OreTableRow>

          {form.getValues('orderReference') !== '' ? (
            <OreTableRow>
              <OreTableCell>
                {t(orderValidationsTranslations.step2.general.billReference)}
              </OreTableCell>
              <OreTableCell>
                <OreText bold align="right" size="text-sm">
                  {form.getValues('orderReference')}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          ) : null}

          {detail.paymentMode ? (
            <OreTableRow>
              <OreTableCell>
                {t(orderValidationsTranslations.step2.general.paymentMode)}
              </OreTableCell>
              <OreTableCell>
                <OreStack placeContent="end">
                  <PaymentModePill paymentMode={detail.paymentMode} />
                </OreStack>
              </OreTableCell>
            </OreTableRow>
          ) : null}
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
}
