export const historyTranslation = {
  title: 'history.title',
  tabs: {
    spending: 'history.tabs.spending',
    dietSpendings: 'history.tabs.dietSpendings',
    cards: 'history.tabs.cards',
    recharge: 'history.tabs.recharge',
    unload: 'history.tabs.unload',
    rechargeAndUnload: 'history.tabs.rechargeAndUnload',
    assingments: 'history.tabs.assingments',
  },
  table: {
    emptyMessage: 'historyTranslation.table.emptyMessage',
  },
}
