import { useEffect, useState } from 'react'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../../../domain/customComponents/table/TableModel'
import { useCardOrder } from '../../../../../context/cardOrder/CardOrderProvider'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation } from '../../../../../../domain/translations'
import { orderType, OSCCode } from '../../../../../../domain/enum'

export const useExcelSummaryController = () => {
  const { orderExcel, orderContext } = useCardOrder()
  const [cardRows, setCardRows] = useState<RowModel[]>([])
  const [chargeRows, setChargeRows] = useState<RowModel[]>([])
  const { t } = useTranslation()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(cardOrderTranslation.excelHome.concepts) },
      { label: t(cardOrderTranslation.excelHome.units), align: 'right' },
    ],
  }

  const cardCaption: CaptionModel = {
    title: t(cardOrderTranslation.excelHome.cardSummaryTitle),
    description: '',
  }

  const chargeCaption: CaptionModel = {
    title:
      orderContext.orderType === orderType.Recharge
        ? t(cardOrderTranslation.excelHome.rechargeSummaryTitle)
        : t(cardOrderTranslation.excelHome.unloadSummaryTitle),
    description: '',
  }

  const getCardOrderRows = () => {
    return orderExcel.abstractCardOrder.resumeData.filter(
      item =>
        item.resumeCode !== OSCCode.recharge_1 && item.resumeCode !== OSCCode.recharge_2
    )
  }

  const getRechargeRows = () => {
    return orderExcel.abstractCardOrder.resumeData.filter(
      item =>
        item.resumeCode === OSCCode.recharge_1 || item.resumeCode === OSCCode.recharge_2
    )
  }
  const getUnloadRows = () => {
    return orderExcel.abstractCardOrder.resumeData.filter(
      item => item.resumeCode === OSCCode.unload_1 || item.resumeCode === OSCCode.unload_2
    )
  }

  const GetCardRows = () => {
    const orderRows: RowModel[] = []
    getCardOrderRows().forEach((row, i) => {
      const orderRow: RowModel = {
        rowId: Math.ceil(Math.random() * 1000000),
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: row.name,
                name: 'description',
                tone: 'neutral-900',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: row.value,
                name: 'quantity',
                tone: 'neutral-900',
                size: 'text-sm',
                align: 'right',
              },
            ],
          },
        ],
      }
      orderRows.push(orderRow)
    })
    // WIP: new push
    setCardRows(orderRows)
  }

  const GetChargeRows = () => {
    const orderRows: RowModel[] = []
    const chargeRows =
      orderContext.orderType === orderType.Recharge ? getRechargeRows() : getUnloadRows()
    chargeRows.forEach((row, i) => {
      const orderRow: RowModel = {
        rowId: Math.ceil(Math.random() * 1000000),
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: row.name,
                name: 'description',
                tone: 'neutral-900',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: row.value,
                name: 'quantity',
                tone: 'neutral-900',
                size: 'text-sm',
                align: 'right',
              },
            ],
          },
        ],
      }
      orderRows.push(orderRow)
    })
    // WIP: new push
    setChargeRows(orderRows)
  }

  useEffect(() => {
    GetCardRows()
    GetChargeRows()
  }, [])

  return {
    cardRows,
    setCardRows,
    chargeRows,
    setChargeRows,
    header,
    cardCaption,
    chargeCaption,
  }
}
