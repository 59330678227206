import { viewTag } from 'src/domain/enum/permissionTags'

export const is100PercentSub = (userTags: string[]): boolean => {
  if (
    userTags.includes(viewTag.VIEW_SUB_CLIENTS) &&
    !userTags.includes(viewTag.VIEW_FL_HOME)
  ) {
    return true
  }

  return false
}

export const is100PercentFlex = (userTags: string[]): boolean => {
  if (
    !userTags.includes(viewTag.VIEW_SUB_CLIENTS) &&
    userTags.includes(viewTag.VIEW_FL_HOME)
  ) {
    return true
  }

  return false
}

export const isMixClient = (userTags: string[]): boolean => {
  if (
    userTags.includes(viewTag.VIEW_SUB_CLIENTS) &&
    userTags.includes(viewTag.VIEW_FL_HOME)
  ) {
    return true
  }

  return false
}
