import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgEdit,
  OreStack,
  OreTooltip,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import type { PolicyCollectiveModel, ProductModel } from 'src/Flex/Products/domain'
import type { ComponentType, RefAttributes } from 'react'
import { useRef } from 'react'
import type { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { StatusToggle } from 'src/Flex/Products/ui/product-toggle-status'
import { edenredProducts } from 'src/domain/enum'
import { GetDateFormatted, GetMonthsName } from 'src/core/helpers'

interface IProps {
  product: ProductModel
  productIcon: JSX.Element | null
  Modal?: ComponentType<RefAttributes<ConfigureModalActions>>
}

export const FlexProductForCollectivesRow = ({
  product,
  productIcon,
  Modal,
}: IProps): JSX.Element => {
  const ref = useRef<ConfigureModalActions>(null)
  const { t } = useTranslation()

  const handleClick = (): void => {
    ref?.current?.open()
  }

  return (
    <OreTableRow valign="middle">
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack
            space="xsmall"
            placeContent="center"
            placeItems="center"
            direction="row">
            {productIcon}
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {product.name}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="small">
          <ul>
            {product.productType !== edenredProducts.ticketGuarderia &&
              product.isVirtual !== null &&
              (product.isVirtual ? (
                <li>
                  <OreText size="text-sm">
                    {t(flexProductsTranslations.table.row.details.cardType) + ': '}
                    <span className="bold">
                      {t(flexProductsTranslations.table.row.details.virtual)}
                    </span>
                  </OreText>
                </li>
              ) : (
                <li>
                  <OreText size="text-sm">
                    {t(flexProductsTranslations.table.row.details.cardType) + ': '}
                    <span className="bold">
                      {t(flexProductsTranslations.table.row.details.plasctic)}
                    </span>
                  </OreText>
                </li>
              ))}
            {product.startDate && (
              <li>
                <OreText size="text-sm">
                  {t(flexProductsTranslations.table.row.details.startDate) + ': '}
                  <span className="bold">{GetDateFormatted(product.startDate)}</span>
                </OreText>
              </li>
            )}
            {product.monthlyLimit && product.monthlyLimit > 0 && (
              <li>
                <OreText size="text-sm">
                  {(product.productType === edenredProducts.ticketTransporte
                    ? t(flexProductsTranslations.table.row.details.yearLimit)
                    : product.productType === edenredProducts.formacion
                    ? t(flexProductsTranslations.table.row.details.maxAmount)
                    : t(flexProductsTranslations.table.row.details.monthlyLimit)) + ': '}
                  <span className="bold">{product.monthlyLimit}€</span>
                </OreText>
              </li>
            )}
            {product.months && (
              <li>
                <OreText size="text-sm">
                  {t(flexProductsTranslations.table.row.details.months) + ': '}
                  <span className="bold">{GetMonthsName(product.months)}</span>
                </OreText>
              </li>
            )}
            {product.numberOfMonths && (
              <li>
                <OreText size="text-sm">
                  {t(flexProductsTranslations.table.row.details.monthNumber) + ': '}
                  <span className="bold">{product.numberOfMonths}</span>
                </OreText>
              </li>
            )}
            {product.policies &&
              product.policies
                .filter((policy: PolicyCollectiveModel) => policy.active === true)
                .map((policy: PolicyCollectiveModel, index: number) => (
                  <li key={index}>
                    <OreText size="text-sm">
                      {policy.name} <span className="bold">{policy.description}</span>
                    </OreText>
                  </li>
                ))}
          </ul>
        </OreStack>
      </OreTableCell>
      <OreTableCell>
        <StatusToggle product={product} />
      </OreTableCell>
      <OreTableCell>
        <OreStack space="2xsmall" direction="row">
          {Modal && (
            <>
              <OreTooltip
                text={t(flexProductsTranslations.table.row.actions.edit_config)}
                placement="top">
                <OreIconButton icon={<SvgEdit />} onClick={handleClick} />
              </OreTooltip>
              <Modal ref={ref} />
            </>
          )}
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
