import { createContext } from 'react'
import { CardOrderResumeDTO } from 'src/domain/dto'
import {
  TGDBasicEmployeeAndChildsModel,
  TGDBasicOrderSummaryForm,
} from 'src/domain/models/TGDOrder/TGDBasicOrderModel'

type TGDBasicOrderContextProps = {
  tgdOrders: TGDBasicEmployeeAndChildsModel[]
  tgdOrderCheckout: CardOrderResumeDTO
  tgdSummaryForm: TGDBasicOrderSummaryForm
  addTGDBasicOrder: (tgdOrder: TGDBasicEmployeeAndChildsModel) => void
  addTGDBasicOrders: (tgdOrders: TGDBasicEmployeeAndChildsModel[]) => void
  addTGDBasicOrderCheckout: (checkout: CardOrderResumeDTO) => void
  addTGDBasicSummaryForm: (form: TGDBasicOrderSummaryForm) => void
  updateTGDBasicOrder: (tgdOrder: TGDBasicEmployeeAndChildsModel) => void
  removeTGDBasicOrder: (id: string) => void
  clearTGDBasicOrders: () => void
}

export const TGDBasicOrderContext = createContext<TGDBasicOrderContextProps>(
  {} as TGDBasicOrderContextProps
)
