import React from 'react'
import { useTTOrderSelectMultipleEmployees } from '../hooks/useTTOrderSelectMultipleEmployees'

import { TTOrderSelectMultipleEmployeesList } from '../molecules/TTOrderSelectMultipleEmployeesList'
import { TTOrderSelectMultipleEmployeesFooterButtons } from '../molecules/TTOrderSelectMultipleEmployeesFooterButtons'

export const TTOrderSelectMultipleEmployeesContainer = () => {
  const props = useTTOrderSelectMultipleEmployees()
  return (
    <div>
      <TTOrderSelectMultipleEmployeesList employeesTTProps={props} />
      <TTOrderSelectMultipleEmployeesFooterButtons
        selectedEmployees={props.selectedEmployees}
        tableIsEmpty={props.employeesTT && props.employeesTT.length <= 0}
        goToConfig={props.goToConfig}
      />
    </div>
  )
}
