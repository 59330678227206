import { ComponentType, RefAttributes } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { OreHeading, OreDivider, OreText, OreStack, SvgConsult } from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import { edenredProducts } from 'src/domain/enum'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import {
  ConfigureTicketGuarderiaModalForm,
  ConfigureExternalHealthInsuranceModalForm,
  ConfigureTicketRestaurantModalForm,
  ConfigureHealthInsuranceModalForm,
  ConfigureTicketTransporteModalForm,
  ConfigureFormationModalForm,
  ConfigureModalActions,
} from 'src/Flex/Products/ui/product-configuration'
import { FlexProductsList, ProductsProvider } from 'src/Flex/Products/ui/product-list'
import { FlexProductsCutOffDate } from 'src/Flex/Products/ui/product-cutoff-date'
import { useUserData } from 'src/Flex/User/ui/context'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const productConfigurations = (): {
  [key: number]: {
    modal: ComponentType<RefAttributes<ConfigureModalActions>>
    modalIcon?: ComponentType
  }
} => ({
  [edenredProducts.ticketRestaurant]: { modal: ConfigureTicketRestaurantModalForm },
  [edenredProducts.saludExterno]: { modal: ConfigureExternalHealthInsuranceModalForm },
  [edenredProducts.ticketTransporte]: { modal: ConfigureTicketTransporteModalForm },
  [edenredProducts.ticketGuarderia]: { modal: ConfigureTicketGuarderiaModalForm },
  [edenredProducts.formacion]: { modal: ConfigureFormationModalForm },
  [edenredProducts.salud]: {
    modal: ConfigureHealthInsuranceModalForm,
    modalIcon: SvgConsult,
  },
})

export const FlexProductsPage = (): JSX.Element => {
  const { t } = useTranslation()
  const { flexData } = useUserData()

  return (
    <PageWrapper>
      <OreHeading as="h1" size="title-md">
        {t(flexProductsTranslations.title)}
      </OreHeading>
      <OreDivider space="larger-bottom" />

      <OreStack space="large">
        {!flexData?.isOnboardingDone ? (
          <OreText>
            <Trans t={t}>{t(flexProductsTranslations.onboarding)}</Trans>
          </OreText>
        ) : null}
        <ProductsProvider productConfigurations={productConfigurations}>
          <FlexProductsCutOffDate />
          <FlexProductsList
            title={t(flexProductsTranslations.table.title)}
            isGlobal={true}
          />
          <VideoTutorial
            title={t(flexProductsTranslations.videoTutorial.products)}
            youtubeUrl="https://www.youtube.com/embed/xkU2ywvgHYY?si=LLuYQw9hHNZ-rOts"
          />
        </ProductsProvider>
      </OreStack>
    </PageWrapper>
  )
}
