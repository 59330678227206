import { OreSelect, OreSelectPlaceholder } from '@runroom/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type {
  FilterControlModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'
import { useFilterComboBox } from '../../hooks/table'

export const FilterComboBox = (props: {
  comboBox: FilterControlModel
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
}): JSX.Element => {
  const { onChange } = useFilterComboBox(props.setFilter)
  return (
    <OreSelect
      name={props.comboBox.name}
      label={props.comboBox.label}
      disabled={props.comboBox.disabled}
      value={props.comboBox.value}
      hasError={props.comboBox.hasError}
      errorMessage={props.comboBox.errorMassage}
      onChange={event => onChange(event, props.comboBox.name)}>
      {props.comboBox.placeHolder && (
        <OreSelectPlaceholder label={props.comboBox.placeHolder} />
      )}
      {props.comboBox.options &&
        Seq(props.comboBox.options).map((option: ComboBoxOptionModel, index: number) =>
          props.comboBox.group ? (
            <optgroup
              key={'optgroup_' + index}
              label={t(option.groupName ? option.groupName : '')}>
              {Seq(option.options).map((option: [string, number], index: number) => (
                <option key={'option_' + index} label={t(option[0])} value={option[1]} />
              ))}
            </optgroup>
          ) : (
            Seq(option.options).map((option: [string, number], index: number) => (
              <option key={'option_' + index} label={t(option[0])} value={option[1]} />
            ))
          )
        )}
    </OreSelect>
  )
}
