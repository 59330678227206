import { EmptyTableImage } from 'src/presentation/components/Edenred/EmptyTableImage'
import { Table } from '../../../Edenred'
import { useInvoiceClientHome } from '../hooks/useInvoiceClientHome'
import { clientHomeTranslation } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import EpayTutorial from '../../../../assets/img/EpayTutorial.svg'

export const InvoicesOrderClientHome = (): JSX.Element => {
  const { rows, caption, setRows, footer } = useInvoiceClientHome()
  const { t } = useTranslation()

  if (rows.length > 0)
    return <Table caption={caption} rows={rows} setRows={setRows} footer={footer} />
  else
    return (
      <EmptyTableImage
        image={EpayTutorial}
        description={t(
          clientHomeTranslation.ticketRestaurant.invoices.emptyTableDescription
        )}
        title={caption.title}
        subtitle={t(clientHomeTranslation.ticketRestaurant.invoices.emptyTableSubtitle)}
      />
    )
}
