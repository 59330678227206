import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { collectivesService } from 'src/Flex/Collectives/application'
import { CollectiveConfigurationModel } from 'src/Flex/Collectives/domain'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import {
  nonEmptyPositiveNumberSchema,
  nonEmptyStringSchema,
  useMetaResponseHandler,
} from 'src/Flex/Shared/ui/Form'

export interface CollectiveForm {
  name: string
  flexibleCompensationPlanPercentage: number | undefined
  id?: string
}

interface IUseConfigureCollectiveController {
  collective: CollectiveConfigurationModel | undefined
  load: (id: string) => Promise<void>
  form: UseFormReturn<CollectiveForm>
  save: (data: CollectiveForm) => Promise<void>
}

export const useConfigureCollectiveController = (
  id?: string
): IUseConfigureCollectiveController => {
  const { handleMetaResponse } = useMetaResponseHandler()
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()
  const [collective, setCollective] = useState<CollectiveConfigurationModel>()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      load(id)
    }
  }, [id])

  const form = useForm<CollectiveForm>({
    resolver: yupResolver(
      object({
        name: nonEmptyStringSchema(t),
        flexibleCompensationPlanPercentage: nonEmptyPositiveNumberSchema(t, 30, true),
      })
    ),
    mode: 'onChange',
  })

  const load = async (id: string): Promise<void> => {
    startLoading()

    const response = await collectivesService().GetCollectiveConfiguration(id)

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      form.reset({
        name: response.data.name || '',
        flexibleCompensationPlanPercentage:
          response.data.flexibleCompensationPlanPercentage || 0,
      })

      setCollective(response.data)
    }
  }

  const save = async (data: CollectiveForm): Promise<void> => {
    const model: CollectiveConfigurationModel = {
      name: data.name,
      flexibleCompensationPlanPercentage: data.flexibleCompensationPlanPercentage ?? 0,
      id,
    }

    startLoading()

    const response = id
      ? await collectivesService().SaveCollective(model)
      : await collectivesService().CreateCollective(model)

    stopLoading()

    if (
      handleMetaResponse(response?.meta, form) &&
      undefined === id &&
      typeof response.data === 'string'
    ) {
      navigate(flexNavigationRoutes.flexCollectivesEdit.replace(':id', response.data))
    }
  }

  return { collective, load, form, save }
}
