export const spendingRuleFormTranslation = {
  page: {
    title: 'spendingRuleFormTranslation.page.title',
  },
  form: {
    title: 'spendingRuleFormTranslation.form.title',
    name: 'spendingRuleFormTranslation.form.name',
    namePlaceholder: 'spendingRuleFormTranslation.form.namePlaceholder',
    profileTypeLabelTR: 'spendingRuleFormTranslation.form.profileTypeLabelTR',
    profileTypeSubtitleTR: 'spendingRuleFormTranslation.form.profileTypeSubtitleTR',
    profileTypeLabelDiet: 'spendingRuleFormTranslation.form.profileTypeLabelDiet',
    profileTypeSubtitleDiet: 'spendingRuleFormTranslation.form.profileTypeSubtitleDiet',
    restrictionsLabel: 'spendingRuleFormTranslation.form.restrictionsLabel',
    restrictionsPerDay: 'spendingRuleFormTranslation.form.restrictionsPerDay',
    day: {
      monday: 'spendingRuleFormTranslation.form.day.monday',
      tuesday: 'spendingRuleFormTranslation.form.day.tuesday',
      wednesday: 'spendingRuleFormTranslation.form.day.wednesday',
      thursday: 'spendingRuleFormTranslation.form.day.thursday',
      friday: 'spendingRuleFormTranslation.form.day.friday',
      saturday: 'spendingRuleFormTranslation.form.day.saturday',
      sunday: 'spendingRuleFormTranslation.form.day.sunday',
    },
    timeRestrictionLabel: 'spendingRuleFormTranslation.form.timeRestrictionLabel',
    from: 'spendingRuleFormTranslation.form.from',
    to: 'spendingRuleFormTranslation.form.to',
    additionalRestrictionsLabel:
      'spendingRuleFormTranslation.form.additionalRestrictionsLabel',
    additionalRestrictionsSelect: {
      default: 'spendingRuleFormTranslation.form.additionalRestrictionsSelect.default',
      byTransaction:
        'spendingRuleFormTranslation.form.additionalRestrictionsSelect.byTransaction',
      byAmount: 'spendingRuleFormTranslation.form.additionalRestrictionsSelect.byAmount',
    },
    restrictionByTransactions: {
      transactionsPerDay:
        'spendingRuleFormTranslation.form.restrictionByTransactions.transactionsPerDay',
      transactionsPerWeek:
        'spendingRuleFormTranslation.form.restrictionByTransactions.transactionsPerWeek',
      transactionsPerMonth:
        'spendingRuleFormTranslation.form.restrictionByTransactions.transactionsPerMonth',
      transactionsPerYear:
        'spendingRuleFormTranslation.form.restrictionByTransactions.transactionsPerYear',
      maxPerTransaction:
        'spendingRuleFormTranslation.form.restrictionByTransactions.maxPerTransaction',
      placeholderPrefix:
        'spendingRuleFormTranslation.form.restrictionByTransactions.placeholderPrefix',
    },
    restrictionByAmount: {
      maxAmountPerDay:
        'spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerDay',
      maxAmountPerWeek:
        'spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerWeek',
      maxAmountPerMonth:
        'spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerMonth',
      maxAmountPerYear:
        'spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerYear',
    },
    diet: {
      stripeTitle: 'spendingRuleFormTranslation.form.diet.stripeTitle',
      addStripeDescription: 'spendingRuleFormTranslation.form.diet.addStripeDescription',
      addStripeButton: 'spendingRuleFormTranslation.form.diet.addStripeButton',
      deleteStripeButton: 'spendingRuleFormTranslation.form.diet.deleteStripeButton',
      maxPerStripe: 'spendingRuleFormTranslation.form.diet.maxPerStripe',
      overlap: {
        start: 'spendingRuleFormTranslation.form.diet.overlap.start',
        matches: 'spendingRuleFormTranslation.form.diet.overlap.matches',
        and: 'spendingRuleFormTranslation.form.diet.overlap.and',
      },
    },
    favoriteLabel: 'spendingRuleFormTranslation.form.favoriteLabel',
    mandatoryFields: 'spendingRuleFormTranslation.form.mandatoryFields',
    submitButtonLabel: 'spendingRuleFormTranslation.form.submitButtonLabel',
    submitEditButtonLabel: 'spendingRuleFormTranslation.form.submitEditButtonLabel',
    favoriteModal: {
      title: 'spendingRuleFormTranslation.form.favoriteModal.title',
      description: 'spendingRuleFormTranslation.form.favoriteModal.description',
      confirmationButtonLabel:
        'spendingRuleFormTranslation.form.favoriteModal.confirmationButtonLabel',
    },
    errors: {
      atLeastOneDaySelected:
        'spendingRuleFormTranslation.form.errors.atLeastOneDaySelected',
      timeToGreaterThanTimeForm:
        'spendingRuleFormTranslation.form.errors.timeToGreaterThanTimeForm',
      atLeastOneRestriction:
        'spendingRuleFormTranslation.form.errors.atLeastOneRestriction',
      mustBeInteger: 'spendingRuleFormTranslation.form.errors.mustBeInteger',
      numberMustBeLessThanOrEqual:
        'spendingRuleFormTranslation.form.errors.numberMustBeLessThanOrEqual',
      numberMustBeGreaterThan:
        'spendingRuleFormTranslation.form.errors.numberMustBeGreaterThan',
      amountMustBeLessThanOrEqual:
        'spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual',
      amountMustBeGreaterThanOrEqual:
        'spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual',
      amountMustContainMaxTwoDecimals:
        'spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals',
    },
    cancelModal: {
      title: 'spendingRuleFormTranslation.form.cancelModal.title',
      description: 'spendingRuleFormTranslation.form.cancelModal.description',
      editModeTitle: 'spendingRuleFormTranslation.form.cancelModal.editModeTitle',
    },
  },
}
