import { useTranslation } from 'react-i18next'
import { Form } from './organism/Form'
import { deliverySiteFormTranslation } from '../../../../domain/translations'
import { SimpleForm } from '../../../Templates'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import type { BasicParameters } from '../../../../domain/forms'
import type { DeliverySiteModel } from '../../../../domain/models'

interface Props {
  productCode: number
}

export const DeliverySiteFormPage = ({ productCode }: Props): JSX.Element => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const parameters = state as BasicParameters<DeliverySiteModel>
  const [formParameters, setFormParameters] = useState<
    BasicParameters<DeliverySiteModel>
  >(
    parameters && parameters.entity
      ? {
          editMode: parameters.editMode,
          entity: { ...parameters.entity, productTypeId: productCode },
          productCode: productCode,
        }
      : { editMode: false, productCode: productCode }
  )

  useEffect(() => {
    const parameters = state as BasicParameters<DeliverySiteModel>
    if (state) {
      if (parameters.entity) {
        setFormParameters({
          productCode: productCode,
          editMode: parameters.editMode,
          entity: { ...parameters.entity, productTypeId: productCode },
        })
      } else {
        setFormParameters({
          productCode: productCode,
          editMode: parameters.editMode,
          entity: parameters.entity,
        })
      }
    }
  }, [state])

  useEffect(() => {
    if (formParameters.entity)
      setFormParameters({
        ...formParameters,
        entity: { ...formParameters.entity, productTypeId: productCode },
      })
  }, [productCode])

  return (
    <SimpleForm
      title={
        !formParameters.editMode
          ? t(deliverySiteFormTranslation.form.new)
          : t(deliverySiteFormTranslation.form.update)
      }
      organisms={[<Form formParameters={formParameters} formMode="page" />]}
    />
  )
}
