import {
  OreButton,
  OreList,
  OreListItem,
  OreMessageBar,
  OreText,
  SvgDownload,
  SvgRepeat,
} from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  cardOrderTranslation,
  duplicatedTranslation,
  forms,
} from '../../../../../domain/translations'
import { Modal, Table } from '../../../Edenred'
import { useExcelAlerts } from '../hooks/useExcelAlerts'
import { OrderFooter } from '../../cardOrderForm/molecules'

const ExcelAlertsContainer = () => {
  const {
    rows,
    header,
    setRows,
    caption,
    footer,
    hasStoppers,
    changeFileHandle,
    downloadListHandle,
    onMainButtonClick,
    showCancelModal,
    setShowCancelModal,
    getCancelButtons,
    Cancel,
    hasValidationError,
  } = useExcelAlerts()
  const { t } = useTranslation()

  return (
    <Table
      header={header}
      rows={rows}
      setRows={setRows}
      selector={{ buttonTitle: '', description: '', selectors: [], title: '' }}
      caption={caption}
      footer={footer}
    >
      <>
        <div className="mt-3 mb-2" style={{ maxWidth: 650 }}>
          <OreText>{t(cardOrderTranslation.excelAlerts.content.paragraph)}</OreText>
          <br />
          <div style={{ marginLeft: '2rem' }}>
            <OreList bullet>
              <OreListItem>
                <OreText>
                  {t(cardOrderTranslation.excelAlerts.content.secondBullet)}
                </OreText>
              </OreListItem>
              <OreListItem>
                <OreText>
                  {t(cardOrderTranslation.excelAlerts.content.firstBullet)}
                </OreText>
              </OreListItem>
            </OreList>
          </div>
        </div>
        <div className="mb-3 flex flex--row">
          <OreButton
            as="button"
            category="secondary"
            icon={<SvgDownload />}
            size="large"
            onClick={downloadListHandle}
          >
            {t(cardOrderTranslation.excelAlerts.buttons.downloadErrorsList)}
          </OreButton>
          <OreButton
            as="button"
            category="secondary"
            icon={<SvgRepeat />}
            size="large"
            onClick={changeFileHandle}
          >
            {t(cardOrderTranslation.excelAlerts.buttons.changeFile)}
          </OreButton>
        </div>

        {hasValidationError && (
          <OreMessageBar color="error">{t(forms.modals.error)}</OreMessageBar>
        )}

        <OrderFooter
          continueButtonLabel={t(forms.buttons.continueToCheckout)}
          onCancelClick={() => Cancel()}
          onContinueClick={onMainButtonClick}
          isMainButtonEnabled={!hasStoppers}
        />
        {/*{"EXIT MODAL"}*/}
        <Modal
          handleOnClose={setShowCancelModal}
          open={showCancelModal}
          title={t(duplicatedTranslation.cancelModal.title)}
          description={t(duplicatedTranslation.cancelModal.subtitle)}
          buttons={getCancelButtons()}
          size="small"
        />
      </>
    </Table>
  )
}

export default ExcelAlertsContainer
