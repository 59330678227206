import {
  OreIcon,
  SvgAddEmployees,
  SvgEnvelope,
  SvgExternalHealth,
  SvgFormation,
  SvgGeneralConditions,
  SvgInternalHealth,
  SvgKindergarten,
  SvgNavBarCollectives,
  SvgNavBarMembers,
  SvgNavBarProducts,
  SvgRestaurant,
  SvgTransport,
} from '@runroom/oreneta'
import type { ComponentType } from 'react'
import { StepEnum, TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import { edenredProducts } from 'src/domain/enum'

export interface IAvailableIcons {
  [key: string]: {
    svg: ComponentType
    tone: 'restaurant' | 'transport' | 'kindergarten' | 'flex-products' | 'generic-dark'
  }
}

export const getTaskIconByTaskType = (
  task: TaskTypeEnum,
  product?: edenredProducts | undefined,
  step?: StepEnum | undefined,
  active = true
): JSX.Element => {
  switch (task) {
    case TaskTypeEnum.pendingContract:
      return (
        <OreIcon
          size="extra-small"
          icon={<SvgGeneralConditions />}
          tone={active ? 'generic-dark' : 'disabled'}
        />
      )
    case TaskTypeEnum.pendingAdhesion:
      return (
        <OreIcon
          size="extra-small"
          icon={<SvgNavBarProducts />}
          tone={active ? 'generic-dark' : 'disabled'}
        />
      )
    case TaskTypeEnum.pendingWelcomeCommunication:
      return (
        <OreIcon
          size="extra-small"
          icon={<SvgEnvelope />}
          tone={active ? 'generic-dark' : 'disabled'}
        />
      )
    case TaskTypeEnum.pendingValidation: {
      const availableIcons: IAvailableIcons = {
        [edenredProducts.ticketRestaurant]: {
          svg: SvgRestaurant,
          tone: 'restaurant',
        },
        [edenredProducts.ticketTransporte]: {
          svg: SvgTransport,
          tone: 'transport',
        },
        [edenredProducts.ticketGuarderia]: {
          svg: SvgKindergarten,
          tone: 'kindergarten',
        },
      }

      const Icon = availableIcons[product ?? 'SvgRestaurant'].svg
      const tone = availableIcons[product ?? 'restaurant'].tone

      return (
        <OreIcon size="extra-small" icon={<Icon />} tone={active ? tone : 'disabled'} />
      )
    }
    case TaskTypeEnum.pendingImputation: {
      const availableIcons: IAvailableIcons = {
        [edenredProducts.formacion]: {
          svg: SvgFormation,
          tone: 'flex-products',
        },
        [edenredProducts.salud]: {
          svg: SvgInternalHealth,
          tone: 'flex-products',
        },
        [edenredProducts.saludExterno]: {
          svg: SvgExternalHealth,
          tone: 'flex-products',
        },
      }

      const Icon = availableIcons[product ?? 'SvgFormation'].svg
      const tone = availableIcons[product ?? 'flex-products'].tone

      return (
        <OreIcon size="extra-small" icon={<Icon />} tone={active ? tone : 'disabled'} />
      )
    }
    case TaskTypeEnum.pendingOnboardingSteps: {
      const availableIcons: IAvailableIcons = {
        [StepEnum.productsConfiguration]: {
          svg: SvgNavBarProducts,
          tone: 'generic-dark',
        },
        [StepEnum.collectivesConfiguration]: {
          svg: SvgNavBarCollectives,
          tone: 'generic-dark',
        },
        [StepEnum.welcomeCommunicationConfiguration]: {
          svg: SvgEnvelope,
          tone: 'generic-dark',
        },
        [StepEnum.employeesConfiguration]: {
          svg: SvgNavBarMembers,
          tone: 'generic-dark',
        },
      }

      const Icon = availableIcons[step ?? 'SvgFormation'].svg
      const tone = availableIcons[step ?? 'flex-products'].tone

      return (
        <OreIcon size="extra-small" icon={<Icon />} tone={active ? tone : 'disabled'} />
      )
    }
    default:
      return <></>
  }
}
