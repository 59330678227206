import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation, forms } from '../../domain/translations'
import { GetDateFormatted, maxAge, minAge } from '../../core/helpers'
import { dash } from '../../domain/constants/symbols'

export const useEmployeeEditProfileSchemaTT = () => {
  const { t } = useTranslation()
  const employeeEditProfileSchema = Yup.object().shape({
    corporativeEmail: Yup.string()
      .email(t(forms.errors.invalidFormat))
      .required(t(forms.errors.fieldRequired)),
    corporativeTelephone: Yup.string().test(
      'validatePhone',
      t(forms.errors.phoneInvalid),
      value => {
        if (value && value.length > 0) return /(6|7|8|9)[ -]*([0-9][ -]*){8}$/.test(value)
        return true
      }
    ),
    birthDate: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .test(
        'validateMinDateBirth',
        t(cardOrderTranslation.form.errors.validateMinDateBirth) +
          ' ' +
          GetDateFormatted(maxAge()),
        value => {
          if (value) {
            const maximumAge = maxAge()
            return (Date.parse(maximumAge) <= Date.parse(value)) as boolean
          }
          return true
        }
      )
      .test(
        'validateMaxDateBirth',
        t(cardOrderTranslation.form.errors.validateMaxDateBirth) +
          ' ' +
          minAge().split(dash).sort().join(dash),
        value => {
          if (value) {
            const minimumAge = minAge()
            return (Date.parse(minimumAge) >= Date.parse(value)) as boolean
          }
          return true
        }
      ),
    employeeNumber: Yup.string(),
  })
  return { employeeEditProfileSchema }
}
