import { TFunction } from 'react-i18next'
import { StringSchema } from 'yup'
import { forms } from 'src/domain/translations'
import { bornDate, getToday } from 'src/core/helpers'
import { dateSchema } from 'src/Flex/Shared/ui/Form'

export const ageValidationSchema = (
  t: TFunction<'translation', undefined>,
  maxAge?: number,
  minAge?: number
): StringSchema<string | undefined> => {
  let schema = dateSchema(t, undefined, new Date(getToday()))

  if (maxAge)
    schema = schema.test({
      name: 'can-not-be-before-min',
      exclusive: true,
      message: t(forms.errors.maxAge, { age: maxAge }),
      test: (value: string | undefined) =>
        value === undefined || new Date(value) >= new Date(bornDate(maxAge)),
    })

  if (minAge)
    schema = schema.test({
      name: 'can-not-be-after-max',
      exclusive: true,
      message: t(forms.errors.minAge, { age: minAge }),
      test: (value: string | undefined) =>
        value === undefined || new Date(value) <= new Date(bornDate(minAge)),
    })

  return schema
}
