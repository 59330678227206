export const flexReportingTranslations = {
  title: 'flex.reporting.title',
  downloadReport: {
    text: 'flex.reporting.download_report.text',
    button: 'flex.reporting.download_report.button',
    fileName: 'flex.reporting.fileName',
  },
  initialDataError: {
    generic: 'flex.reporting.initialDataError.generic',
    smi1: 'flex.reporting.initialDataError.smi1',
    smi2: 'flex.reporting.initialDataError.smi2',
    smi3: 'flex.reporting.initialDataError.smi3',
    smi4: 'flex.reporting.initialDataError.smi4',
    smi5: 'flex.reporting.initialDataError.smi5',
    smi6: 'flex.reporting.initialDataError.smi6',
  },
}
