import { OreCardContainer, OreCardWrapper, OreStack } from '@runroom/oreneta'
import { FilterModel, FilterResultModel } from 'src/Flex/Shared/domain'
import { InputType } from 'src/Flex/Shared/ui/TableSearcher'

interface FiltersContainerProps {
  filters: FilterModel[]
  setFilters: (filters: Array<FilterResultModel | undefined>) => void
  currentFilters: Array<FilterResultModel | undefined>
}

export const FiltersContainer = ({
  filters,
  setFilters,
  currentFilters,
}: FiltersContainerProps): JSX.Element => {
  const computeFilterRow = (index: number, doubleRow: boolean): JSX.Element => {
    return (
      <OreStack direction="row" placeContent="stretch" sameSize key={index}>
        <InputType
          {...filters[index]}
          setValue={setFilters}
          currentValue={currentFilters}
          index={index}
        />
        {doubleRow ? (
          <InputType
            {...filters[index + 1]}
            setValue={setFilters}
            currentValue={currentFilters}
            index={index + 1}
          />
        ) : null}
      </OreStack>
    )
  }

  return (
    <OreCardContainer tone="neutral-100" hasShadow={false}>
      <OreCardWrapper>
        <OreStack space="large" placeContent="stretch">
          {filters.map((_, index) => {
            if (index % 2 === 0) {
              return computeFilterRow(index, index + 1 < filters.length)
            }
            return null
          })}
        </OreStack>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
