import { TFunction } from 'react-i18next'
import { NumberSchema, number } from 'yup'
import { forms } from 'src/domain/translations'
import { emptyStringToZero } from 'src/presentation/sharedForms/helpers'

export const numberSchema = (
  t: TFunction<'translation', undefined>
): NumberSchema<number | undefined> =>
  number().typeError(t(forms.errors.fieldRequired)).transform(emptyStringToZero)

export const positiveNumberSchema = (
  t: TFunction<'translation', undefined>,
  max = Number.MAX_SAFE_INTEGER,
  isInteger = false
): NumberSchema<number | undefined> => {
  let schema = numberSchema(t)
    .min(0, t(forms.errors.notZero))
    .max(max, t(forms.errors.maxAllowed) + ' ' + max)

  if (isInteger) {
    schema = schema.test(
      'is-integer',
      t(forms.errors.notInteger),
      number => number === undefined || Number.isInteger(number)
    )
  }

  return schema
}
