import { t } from 'i18next'
import { PageHeader } from '../../Templates/PageHeader'
import { Container } from './organism/Container'
import { useInvoicePageController } from './hooks/useInvoicePageController'
import { invoiceTranslation } from '../../../domain/translations/payments/invoiceTranslation'

export const InvoicesPage = (): JSX.Element => {
  const { invoiceTab } = useInvoicePageController()
  return (
    <PageHeader
      title={t(invoiceTranslation.title)}
      organism={<Container navigateValue={invoiceTab} />}
    />
  )
}
