import { OreButton, OreDivider } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EmployeesWithLastCard } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface Props {
  selectedEmployees: EmployeesWithLastCard[]
  tableIsEmpty: boolean
  goToConfig: (employees?: EmployeesWithLastCard[]) => void
}

export const TTUnloadSelectMultipleEmployeesFooterButtons = ({
  selectedEmployees,
  tableIsEmpty,
  goToConfig,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setTTEmployeesToConfig, setTTSelectedEmployees } = useTTOrder()

  return (
    <div className="py-2">
      <OreDivider />
      <div className="py-1">
        <Grid justify="flex-end" gap="1.5rem">
          <OreButton
            as="button"
            category="secondary"
            size="large"
            onClick={() => {
              setTTSelectedEmployees([])
              setTTEmployeesToConfig([])
              navigate(-1)
            }}>
            {t(forms.buttons.cancel)}
          </OreButton>
          <OreButton
            as="button"
            category="primary"
            size="large"
            disabled={
              (selectedEmployees && selectedEmployees.length <= 0) || tableIsEmpty
            }
            onClick={() => goToConfig()}>
            {t(TTOrderTranslation.unload.employeeSelection.buttons.configureUnload)}
          </OreButton>
        </Grid>
      </div>
    </div>
  )
}
