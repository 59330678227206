import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEventListener } from 'src/Flex/Shared/ui/Events/hooks'
import { UserContract } from 'src/Flex/User/application'
import { ErrorDataModel, FlexDataModel, UserRepository } from 'src/Flex/User/domain'
import { Events as ProductEvents, ProductModel } from 'src/Flex/Products/domain'
import { Events as EmployeesEvents } from 'src/Flex/Employees/domain'
import { Events as CommunicationsEvents } from 'src/Flex/CommunicationsTemplates/domain'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { isFlexRoute } from 'src/presentation/components/Edenred/navigation/helpers'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { productsService } from 'src/Flex/Products/application'
import { MetaStatusCodes } from 'src/domain/enum'
import { intialDataResponseErrors } from 'src/domain/definitions'

interface IUseUserController {
  flexData: FlexDataModel | null
  errorData: ErrorDataModel
  getAllProducts: ProductModel[]
  setAllProducts: Dispatch<SetStateAction<ProductModel[]>>
  getInitialFlexData: () => Promise<void>
}

export const useUserController = (repository: UserRepository): IUseUserController => {
  const [flexData, setFlexData] = useState<FlexDataModel>({
    isOnboardingDone: true,
    kpis: [],
    onboardingStep: 5,
  })
  const [isFlex, setIsFlex] = useState<boolean>(false)
  const [getAllProducts, setAllProducts] = useState<ProductModel[]>([])
  const location = useLocation()
  const { handleMetaResponse } = useMetaResponseHandler()
  const [errorData, setErrorData] = useState<ErrorDataModel>({
    messages: [],
    status: MetaStatusCodes.SUCCESS,
  })
  const shouldFetchFlexData = (): boolean =>
    // Home page requires fresh Flex data
    location.pathname === flexNavigationRoutes.flex ||
    (!flexData && isFlexRoute(location))

  const getInitialFlexData = async (): Promise<void> => {
    const response = await UserContract(repository).getInitialFlexData()
    if (
      response.meta.messages.length === 0 ||
      !intialDataResponseErrors.find(code => code.type === response.meta.messages[0].code)
    ) {
      if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
        setFlexData(response.data)
      }
    }
    if (response.meta.status === MetaStatusCodes.ERROR) {
      setErrorData({ status: response.meta.status, messages: response.meta.messages })
    }
  }

  useEventListener(ProductEvents.SAVE_PRODUCT_STATE, () => {
    !flexData?.isOnboardingDone && getInitialFlexData()
  })

  useEventListener(EmployeesEvents.CREATE_NEW_EMPLOYEE, () => {
    !flexData?.isOnboardingDone && getInitialFlexData()
  })

  useEventListener(CommunicationsEvents.EDIT_COMMUNICATION, () => {
    !flexData?.isOnboardingDone && getInitialFlexData()
  })

  const getProducts = async (): Promise<void> => {
    const response = await productsService().GetProducts()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setAllProducts(response.data ? response.data : [])
    }
  }

  useEffect(() => {
    if (shouldFetchFlexData()) {
      setErrorData({
        messages: [],
        status: MetaStatusCodes.SUCCESS,
      })
      getInitialFlexData()
    }
    if (isFlexRoute(location) && getAllProducts.length === 0) {
      getProducts()
    }
  }, [location])

  return {
    flexData,
    errorData,
    getAllProducts,
    setAllProducts,
    getInitialFlexData,
  }
}
