import { cardOrderTranslation } from 'src/domain/translations'
import { StepsForModel } from './Stepper'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export const TTOrderStepsData: StepsForModel = {
  steps: [
    cardOrderTranslation.stepper.firstStep,
    cardOrderTranslation.stepper.secondStep,
    cardOrderTranslation.stepper.thirdStep,
    cardOrderTranslation.stepper.fourthStep,
  ],
  stepsByPaths: [
    {
      step: cardOrderTranslation.stepper.firstStep,
      paths: [
        navigationRoutes.ttOrderForm,
        navigationRoutes.ttOrderFormEdit,
        navigationRoutes.ttOrderSelectSingleEmployee,
        navigationRoutes.ttOrderSelectMultipleEmployee,
        navigationRoutes.ttOrderEmployeesConfiguration,
        navigationRoutes.ttOrderExcel,
        navigationRoutes.ttNewOrderExcel,
        navigationRoutes.ttNewOrderImportExcel,
        // TT Recharge
        navigationRoutes.ttRechargeExcel,
        navigationRoutes.ttRechargeImportExcel,
        navigationRoutes.ttRechargeSelectMultipleEmployee,
        navigationRoutes.ttRechargeEmployeesConfiguration,
        navigationRoutes.ttRechargeEmployeesConfigurationEdit,
        // TT Unload
        navigationRoutes.ttUnloadExcel,
        navigationRoutes.ttUnloadImportExcel,
        navigationRoutes.ttUnloadSelectMultipleEmployee,
        navigationRoutes.ttUnloadEmployeesConfiguration,
        navigationRoutes.ttUnloadEmployeesConfigurationEdit,
      ],
    },
    {
      step: cardOrderTranslation.stepper.secondStep,
      paths: [
        navigationRoutes.ttOrderSummary,
        navigationRoutes.ttRechargeSummary,
        navigationRoutes.ttUnloadSummary,
        navigationRoutes.ttOrderExcelSummary,
      ],
    },
    {
      step: cardOrderTranslation.stepper.thirdStep,
      paths: [
        navigationRoutes.ttOrderCheckout,
        navigationRoutes.ttRechargeCheckout,
        navigationRoutes.ttUnloadCheckout,
        navigationRoutes.ttOrderExcelCheckout,
      ],
    },
    {
      step: cardOrderTranslation.stepper.fourthStep,
      paths: [
        navigationRoutes.ttOrderCompleteError,
        navigationRoutes.ttOrderCompleteSuccess,
        navigationRoutes.ttRechargeCompleteError,
        navigationRoutes.ttRechargeCompleteSuccess,
        navigationRoutes.ttUnloadCompleteError,
        navigationRoutes.ttUnloadCompleteSuccess,
      ],
    },
  ],
}
