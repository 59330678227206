import { useEffect, useState } from 'react'

export const useDebounceCallback = <T>(
  value: () => Promise<T>,
  defaultValue: T,
  delay?: number
): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue)

  useEffect(() => {
    const timer = setTimeout(async () => setDebouncedValue(await value()), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
