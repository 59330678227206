import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { PropsWithChildren } from 'react'

import {
  IUseEmployeeController,
  useEmployeeController,
} from 'src/Flex/Employees/ui/employee-list'

type Context = IUseEmployeeController

type Props = PropsWithChildren

const [useEmployees, StateContextProvider] = createGenericContext<Context>()

const EmployeeProvider = ({ children }: Props): JSX.Element => {
  const hook = useEmployeeController()

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { EmployeeProvider, useEmployees }
