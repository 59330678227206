import { Seq } from 'immutable'
import type { ChangeEvent, Dispatch, SetStateAction} from 'react';
import type {
  RowModel} from '../../../../../domain/customComponents/table/TableModel';



interface HeaderState {
  onChangeSelectedAllRows: (event: ChangeEvent<HTMLInputElement>) => void
}

export const useHeader = (
  rows: RowModel[],
  setSelectedRows: Dispatch<SetStateAction<number[]>>,
  setRows: Dispatch<SetStateAction<RowModel[]>>
): HeaderState => {
  const onChangeSelectedAllRows = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked: boolean = event.target.checked
    const selectedRows: number[] = []
    if (isChecked) {
      Seq(rows).forEach(row => {
        selectedRows.push(row.rowId)
      })
      setSelectedRows(selectedRows)
    } else setSelectedRows([])
    setRows(prevRows =>
      prevRows.map((prevRow: RowModel, prevRowIndex: number) => {
        return {
          ...prevRow,
          isSelected: isChecked,
        }
      })
    )
  }

  return { onChangeSelectedAllRows }
}
