import { CSSProperties, PropsWithChildren } from 'react'

type Span = number | 'auto'

type IProps = PropsWithChildren & {
  colSpan?: Span
  rowSpan?: Span
}

const convertToSpan = (span: Span): string =>
  span === 'auto' ? 'auto' : `span ${span} / span ${span}`

export const OreGridItem = ({
  colSpan = 'auto',
  rowSpan = 'auto',
  children,
}: IProps): JSX.Element => {
  const style: CSSProperties = {}

  if (colSpan) style.gridColumn = convertToSpan(colSpan)
  if (rowSpan) style.gridRow = convertToSpan(rowSpan)

  return <div style={style}>{children}</div>
}
