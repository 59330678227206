import { IncompleteOrders } from '../../myOrders/incompleteOrders/organism/IncompleteOrders'
import { useContainerController } from '../hooks/useContainerController'

interface Props {
  productCode?: number
}

export const IncompleteOrderHomeContainer = (props: Props): JSX.Element => {
  const { footer } = useContainerController()
  return (
    <IncompleteOrders
      pageSize={4}
      showFilter={false}
      showCaption={true}
      showAllTotalRow={true}
      navigateFooter={footer}
      isExtended={true}
      productCode={props.productCode}
    />
  )
}
