import { OreTableCell } from '@runroom/oreneta'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import React, { memo } from 'react'
import { CellAction, CellSelection } from '../..'
import { CellComboBox } from '../atoms/CellComboBox'
import { CellText } from '../atoms/CellText'
import { CellTextBox } from '../atoms/CellTextBox'
import type {
  CellModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'

export const Cells = memo(
  (props: {
    cells: CellModel[]
    rowIndex: number
    rowId: number
    multiSelected: boolean | undefined
    selectedRows: number[] | undefined
    setRows: Dispatch<React.SetStateAction<RowModel[]>>
    setSelectedRows: Dispatch<React.SetStateAction<number[]>>
    setTotalRows: Dispatch<SetStateAction<number[]>>
    modalShow: boolean
    setModalShow: Dispatch<SetStateAction<boolean>>
    deleteConfirm: boolean
    setDeleteConfirm: Dispatch<SetStateAction<boolean>>
    confirmDelete?: boolean
  }): JSX.Element => {
    return (
      <>
        {props.multiSelected && (
          <OreTableCell>
            <CellSelection
              rowIndex={props.rowIndex}
              rowId={props.rowId}
              selectedRows={props.selectedRows}
              setRows={props.setRows}
              setSelectedRows={props.setSelectedRows}
            />
          </OreTableCell>
        )}

        {Seq(props.cells)
          .valueSeq()
          .map((cell: CellModel, index: number) => (
            <OreTableCell key={'cell' + props.rowIndex + '_' + index} align={cell.align}>
              {cell.type === 'text' && cell.cellText && (
                <CellText text={cell.cellText} rowIndex={props.rowIndex} />
              )}
              {cell.type === 'textBox' && cell.cellControll && (
                <CellTextBox
                  cellIndex={index}
                  rowIndex={props.rowIndex}
                  textBox={cell.cellControll}
                  setRows={props.setRows}
                />
              )}
              {cell.type === 'comboBox' && cell.cellControll && (
                <CellComboBox
                  cellIndex={index}
                  rowIndex={props.rowIndex}
                  comboBox={cell.cellControll}
                  setRows={props.setRows}
                />
              )}
              {cell.type === 'element' && cell.cellElement}
              {cell.type === 'actions' && cell.cellActions && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 'var(--spacing-small)',
                    whiteSpace: 'nowrap',
                  }}>
                  {Seq(cell.cellActions).map((action, actionIndex) => (
                    <CellAction
                      key={actionIndex}
                      action={action}
                      cellIndex={index}
                      rowId={props.rowId}
                      setRows={props.setRows}
                      setSelectedRows={props.setSelectedRows}
                      setTotalRows={props.setTotalRows}
                      modalShow={props.modalShow}
                      setModalShow={props.setModalShow}
                      deleteConfirm={props.deleteConfirm}
                      setDeleteConfirm={props.setDeleteConfirm}
                      confirmDelete={props.confirmDelete}
                    />
                  ))}
                </div>
              )}
            </OreTableCell>
          ))}
      </>
    )
  }
)
