import { OreHeading } from '@runroom/oreneta'
import type { FormProps } from '../../../../../domain/forms/FormProps'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { Textbox } from '../../../Edenred'
import { useDetailsController } from '../hooks'

export const Details = (props: FormProps<DeliverySiteModel>): JSX.Element => {
  const { register, errors, detailsTextBox } = useDetailsController(props.formParameters)
  return (
    <>
      <section className="form-molecule mt-1">
        <div className="pl-05">
          <OreHeading as="h2" size="headline-md">
            {props.title}
          </OreHeading>
        </div>

        <Textbox register={register} errors={errors} textBoxList={detailsTextBox} />
      </section>
    </>
  )
}
