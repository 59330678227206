import { createContext } from 'react'

type NotificationContextProps = {
  notification: Notification
  addNotification: (message: string, severity: Severity) => void
  removeNotification: () => void
}

export interface Notification {
  message: any
  severity: Severity
}

export type Severity = 'error' | 'warning' | 'success'

export const NotificationContext = createContext<NotificationContextProps>(
  {} as NotificationContextProps
)
