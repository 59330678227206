import { createContext } from 'react'
import type {
  AccountModel,
  DeliverySiteModel,
  HttpModel,
  IncompleteOrderModel,
  UserContract,
} from '../../../domain/models'
import { MultiCIFModel } from 'src/domain/models/user/userMultiCIF'

type UserContextProps = {
  contracts: UserContract[] | undefined
  userContract: () => void
  company: AccountModel | undefined
  userCompany: () => void
  userDeliverySites: (
    productType: number
  ) => Promise<HttpModel<DeliverySiteModel[]> | undefined>
  userDeliverySitesById: (
    deliverySiteId: number
  ) => Promise<DeliverySiteModel | undefined>
  userRechargeOrderById: (orderId: number) => Promise<IncompleteOrderModel | undefined>
  clientsMultiCIF: MultiCIFModel[] | undefined
  updateMultiCIF: (code: number) => Promise<boolean>
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps)
