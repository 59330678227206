import { OreButton, OreStack, SvgDownload } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { cardOrderTranslation, forms } from '../../../../../domain/translations'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

export const Buttons = ({
  success,
  excel,
}: {
  success: boolean
  excel: string
}): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const downloadFile = async () => {
    if (excel === '') return

    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${excel}`
    link.download = 'listado-errores.xlsx'
    link.click()
    link.remove()
  }

  return (
    <section className="flex flex--column mt-2 space-between">
      <OreStack placeContent="center">
        {success ? (
          <OreButton
            as="button"
            category="primary"
            onClick={() => navigate(navigationRoutes.myOrdersHistory)}
            size="large">
            {t(cardOrderTranslation.cardOrderComplete.checkStatus)}
          </OreButton>
        ) : excel ? (
          <OreButton
            as="button"
            category="primary"
            size="large"
            icon={<SvgDownload />}
            onClick={() => downloadFile()}>
            {t(cardOrderTranslation.cardOrderComplete.downloadButton)}
          </OreButton>
        ) : (
          <OreButton
            as="button"
            category="primary"
            onClick={() => navigate(navigationRoutes.cardOrderHome)}
            size="large">
            {t(cardOrderTranslation.cardOrderComplete.newOrder)}
          </OreButton>
        )}
      </OreStack>
      <div className="mt-1">
        <OreButton
          as="button"
          category="highlight"
          onClick={() => navigate(navigationRoutes.clientsHome)}
          size="large"
          style={{ color: '#0F172A' }}>
          {t(forms.buttons.goHome)}
        </OreButton>
      </div>
    </section>
  )
}
