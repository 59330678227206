import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import {
  MetaStatusCodes,
  NotificationSeverity,
  browserStorageKeys,
  edenredProducts,
  queryParam,
} from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { EmployeesService } from 'src/core/services/employeesService'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import { EmployeesNurseryModel, EmployeesNurseryRequest } from 'src/domain/models'
import { useEmployeesSharedController } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export interface EmployeesTGDState {
  header: HeaderModel
  totalRows: number
  employeesTGD: EmployeesNurseryModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  search(filter: EmployeesNurseryRequest): void
  downloadExcel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onEnter: (event: KeyboardEvent<HTMLInputElement>) => void
  form: UseFormReturn<EmployeesNurseryRequest, any>
  onSearch: () => void
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  showDeleteModal: boolean
  deleteModalTitle: string
  deleteModalDescription: string
  deletePopupButtons: PopupButtonModel[]
  onClickDelete(document: string): void
  onClickEdit(employee: EmployeesNurseryModel): void
}

export const useEmployeesListTableTGDController = (): EmployeesTGDState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [employeesTGD, setEmployeesTGD] = useState<EmployeesNurseryModel[]>([])
  const { addNotification } = useNotification()
  const { addSuccessNotification } = useEmployeesSharedController()
  const navigate = useNavigate()

  const {
    show: showDeleteModal,
    title: deleteModalTitle,
    description: deleteModalDescription,
    buttons: deletePopupButtons,
    setShow: setShowDeleteModal,
    setDescription: setDeleteModalDescription,
    setTitle: setDeleteModalTitle,
    setButtons: setDeleteModalButtons,
  } = useModalController()

  const defaultValues: EmployeesNurseryRequest = {
    productCode: edenredProducts.ticketGuarderia,
    userNameOrDNIFilter: '',
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  }

  const [query, setQuery] = useState<EmployeesNurseryRequest>({ ...defaultValues })

  const header: HeaderModel = {
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      { label: t(employeesTranslation.table.header.employeeNumber) },
      { label: t(employeesTranslation.table.header.sonsInCharge) },
      { label: t(employeesTranslation.table.header.actions) },
    ],
  }

  const getEmployees = (): void => {
    startLoading()
    EmployeesService()
      .GetEmployeesNursery(query)
      .then(response => {
        setEmployeesTGD(response.data.rows)
        setTotalRows(response.data.totalRows)
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const search = (filters: EmployeesNurseryRequest): void => {
    setQuery({
      ...filters,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const downloadExcel = (): void => {
    startLoading()
    EmployeesService()
      .downloadExcel(edenredProducts.ticketGuarderia)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = 'ListaEmpleados.xlsx'
          link.click()
          link.remove()
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const formSchema = Yup.object({
    productCode: Yup.number(),
    userNameOrDNIFilter: Yup.string(),
  })

  const form = useForm<EmployeesNurseryRequest>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      productCode: edenredProducts.ticketGuarderia,
      userNameOrDNIFilter: '',
    },
  })

  const onSearch = (): void => {
    const filters: EmployeesNurseryRequest = {
      productCode: edenredProducts.ticketGuarderia,
      userNameOrDNIFilter: form.getValues('userNameOrDNIFilter'),
    }
    search(filters)
  }

  const onClickDelete = (document: string): void => {
    setShowDeleteModal(true)
    setDeleteModalButtons(getDeleteModalButtons(document))
  }

  const getDeleteModalButtons = (document: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowDeleteModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.unsubscribe),
        category: 'danger',
        onClick: () => deleteEmployee(document),
        size: 'large',
      },
    ]
  }

  const deleteEmployee = async (document: string): Promise<void> => {
    setShowDeleteModal(false)
    startLoading()
    await EmployeesService()
      .deleteEmployeeByDocument(document)
      .then(response => {
        if (response.meta.status === MetaStatusCodes.SUCCESS) {
          setQuery({
            ...query,
            paginationModel: {
              numberOfRecordsPerPage: pageSize,
              pageNumber: page - 1,
            },
          })
          setPage(page)
          addSuccessNotification(response.data, false)
        } else {
          if (response.meta.messages.length > 0) {
            addNotification(
              response.meta?.messages[0].description,
              NotificationSeverity.error
            )
          }
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  const onClickEdit = (employee: EmployeesNurseryModel): void => {
    const employeeToEdit = employeesTGD.find(
      (empl: EmployeesNurseryModel) => empl.userId === employee.userId
    )
    sessionStorage.setItem(
      browserStorageKeys.employeeProfile,
      JSON.stringify(employeeToEdit)
    )
    const employeeDocument = employeeToEdit?.userDni || ''
    navigate(
      `${navigationRoutes.employeeProfile.replace(':document', employeeDocument)}?${
        queryParam.product
      }=${edenredProducts.ticketGuarderia}`
    )
  }

  useEffect(() => {
    getEmployees()
  }, [query])

  useEffect(() => {
    setDeleteModalTitle(t(employeesTranslation.table.deleteModal.title))
    setDeleteModalDescription(t(employeesTranslation.table.deleteModal.description))
  }, [])

  return {
    header,
    totalRows,
    employeesTGD,
    page,
    setPage,
    pageSize,
    onPageChange,
    search,
    downloadExcel,
    onEnter,
    form,
    onSearch,
    setShowDeleteModal,
    showDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    onClickDelete,
    onClickEdit,
  }
}
