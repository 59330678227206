import { Fragment } from 'react'
import type { FieldValues } from 'react-hook-form'
import { OreInput, OreDatePicker } from '@runroom/oreneta'
import { Seq } from 'immutable'
import { get } from 'lodash'
import type {
  TextBoxRowModel,
  TextBoxModel,
  TextBoxListModel,
} from '../../../domain/customComponents'
import cx from 'classnames'
import { AdressGoogleTextBox } from './AdressGoogleTextBox'
import { TextBoxesTypes } from '../../../domain/enum/forms'

export const Textbox = <TFormValues extends FieldValues>(
  props: TextBoxListModel<TFormValues>
): JSX.Element => {
  return (
    <>
      {Seq(props.textBoxList).map(
        (textBoxRow: TextBoxRowModel<TFormValues>, indexRow: number) => {
          return (
            <div
              className={cx('form-row', {
                'form-row-nopadding': textBoxRow.rowNoSidePadding,
              })}
              key={'div_' + indexRow}>
              {Seq(textBoxRow.textBoxes).map(
                (textBox: TextBoxModel<TFormValues>, index: number) => {
                  const errorMessages = get(props.errors, textBox.name)
                  const hasError = !!(props.errors && errorMessages)
                  if (textBox.googleApi && props.register) {
                    const { ref, ...rest } = props.register(textBox.name)
                    return (
                      <Fragment key={'fragment_' + index}>
                        <div className={textBoxRow.className} key={textBox.name}>
                          <AdressGoogleTextBox
                            texBox={textBox}
                            register={props.register}
                            errors={props.errors}
                          />
                        </div>
                      </Fragment>
                    )
                  } else
                    return (
                      <Fragment key={'fragment_' + index}>
                        <div className={textBoxRow.className} key={textBox.name}>
                          {textBox.controlType === TextBoxesTypes.datepicker ? (
                            <OreDatePicker
                              {...(props.register && props.register(textBox.name))}
                              name={textBox.name}
                              id={textBox.id}
                              label={textBox.label}
                              hasError={hasError}
                              errorMessage={errorMessages?.message}
                              legend={textBox.legend}
                              required={textBox.required}
                              disabled={textBox.disabled}
                              min={textBox.minDate}
                              max={textBox.maxDate}
                            />
                          ) : (
                            <OreInput
                              {...(props.register && props.register(textBox.name))}
                              name={textBox.name}
                              id={textBox.id}
                              label={textBox.label}
                              placeholder={textBox.placeHolder}
                              type={textBox.type}
                              hasError={hasError}
                              errorMessage={errorMessages?.message}
                              maxLength={textBox.maxLength}
                              legend={textBox.legend}
                              required={textBox.required}
                              disabled={textBox.disabled}
                              autoComplete={textBox.autoComplete}
                              onKeyDown={textBox.onKeyDown}
                              onKeyUp={textBox.onKeyUp}
                              onInput={textBox.onInput}
                              startAdornment={textBox.startAdornment}
                            />
                          )}
                        </div>
                      </Fragment>
                    )
                }
              )}
            </div>
          )
        }
      )}
    </>
  )
}
