import { OreHeading, OreStack } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import {
  PendingTaskCard,
  PendingTasksTitle,
  usePendingTasks,
} from 'src/Flex/TaskManager/ui/pending-tasks'
import {
  flexHomeVideTutorialTranslations,
  flexPendingTasksTranslations,
} from 'src/Flex/TaskManager/ui/translations'
import { GridItem } from 'src/presentation/components/Edenred/layout'
import { PendigTaskModule } from '../molecules/PendigTaskModule'
import { Fragment } from 'react'
import { KPIsGrid } from 'src/Flex/Reporting/ui/kpis'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const PendingTasksList = (): JSX.Element => {
  const { t } = useTranslation()
  const { tasks, showEmployeeTables } = usePendingTasks()

  const drawOrderSingleTable = ['general', 'employees']

  const drawOrderDoubleTable = [
    TaskTypeEnum.pendingValidation,
    TaskTypeEnum.pendingImputation,
  ]

  const subtitle = {
    [TaskTypeEnum.pendingValidation]: t(
      flexPendingTasksTranslations.tasks.subtitles.pendingValidate
    ),
    [TaskTypeEnum.pendingImputation]: t(
      flexPendingTasksTranslations.tasks.subtitles.pendingImputation
    ),
    ['general']: t(flexPendingTasksTranslations.tasks.subtitles.pendingGeneral),
    ['employees']: t(flexPendingTasksTranslations.tasks.subtitles.employees),
  }

  return (
    <OreStack space="2xlarge" placeContent="stretch">
      <div className="mt-3">
        <OreStack placeContent="normal" space="large">
          <PendingTasksTitle />
          {tasks[TaskTypeEnum.pendingOnboardingSteps] &&
          tasks[TaskTypeEnum.pendingOnboardingSteps].length > 0 ? (
            <OreStack placeContent="stretch" space="2xlarge">
              <OreStack placeContent="stretch" space="medium">
                {subtitle[
                  TaskTypeEnum.pendingOnboardingSteps as keyof typeof subtitle
                ] ? (
                  <OreHeading as="h2" size="headline-sm">
                    {
                      subtitle[
                        TaskTypeEnum.pendingOnboardingSteps as keyof typeof subtitle
                      ]
                    }
                  </OreHeading>
                ) : null}
                <PendingTaskCard tasks={tasks[TaskTypeEnum.pendingOnboardingSteps]} />
              </OreStack>
            </OreStack>
          ) : (
            <>
              <OreStack
                direction="row"
                space="large"
                placeItems="normal"
                placeContent="space-between"
                sameSize>
                {Object.keys(tasks).length > 0
                  ? drawOrderDoubleTable.map(
                      (key: string | TaskTypeEnum, index: number) => (
                        <Fragment key={'module_' + index}>
                          <div>
                            <PendigTaskModule
                              tasks={tasks[key as keyof typeof tasks]}
                              title={subtitle[key as keyof typeof subtitle]}
                              taskType={key as keyof typeof tasks}
                            />
                          </div>
                        </Fragment>
                      )
                    )
                  : null}
              </OreStack>
              <OreStack
                direction="row"
                space="large"
                placeItems="normal"
                placeContent="space-between"
                sameSize>
                {showEmployeeTables && Object.keys(tasks).length > 0
                  ? drawOrderSingleTable.map(
                      (key: string | TaskTypeEnum, index: number) => (
                        <Fragment key={'general_' + index}>
                          <GridItem>
                            <PendigTaskModule
                              key={'table_' + index}
                              tasks={tasks[key as keyof typeof tasks]}
                              title={subtitle[key as keyof typeof subtitle]}
                              taskType={key as keyof typeof tasks}
                            />
                          </GridItem>
                        </Fragment>
                      )
                    )
                  : null}
              </OreStack>
            </>
          )}
        </OreStack>
      </div>
      {tasks[TaskTypeEnum.pendingOnboardingSteps] &&
      tasks[TaskTypeEnum.pendingOnboardingSteps].length > 0 ? (
        <></>
      ) : (
        <>
          <KPIsGrid />
          <VideoTutorial
            title={t(flexHomeVideTutorialTranslations.videoTutorial)}
            youtubeUrl="https://www.youtube.com/embed/LTDKE7tXQPc?si=B7TyHb9_-k6ZZz_R"
          />
        </>
      )}
    </OreStack>
  )
}
