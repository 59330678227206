import { useFormContext } from 'react-hook-form'
import { apiKey, loadAsyncScript, mapApiJs } from '../../../config/googleMaps'
import type { DeliverySiteModel } from '../../../domain/models/deliverySites'

export const useAddressSuggestion = () => {
  const formContext = useFormContext<DeliverySiteModel>()

  const initGoogle = async (searchInput: any) => {
    await initMapScript()
    await initAutocomplete(searchInput)
  }

  //init & load google script
  const initMapScript = () => {
    //if script already loaded
    if (window.google) {
      return Promise.resolve()
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`
    return loadAsyncScript(src)
  }

  //ininit autocomplete
  const initAutocomplete = (searchInput: any) => {
    if (searchInput == null) return
    const autocomplete = new window.google.maps.places.Autocomplete(searchInput?.current)
    autocomplete.setFields(['address_component', 'geometry'])
    autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete))
  }

  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace()
    const location = extractAddress(place)
    formContext.setValue('streetName', location.streetName, {
      shouldValidate: true,
    })
    formContext.setValue('city', location.city, {
      shouldValidate: true,
    })
    formContext.setValue('province', location.province, {
      shouldValidate: true,
    })
    formContext.setValue(
      'streetNumber',
      location.streetNumber ? location.streetNumber : 's/n',
      { shouldValidate: true }
    )
    formContext.setValue('streetDescription', location.streetDescription, {
      shouldValidate: true,
    })
    formContext.setValue('zipCode', location.zipCode, {
      shouldValidate: true,
    })
    formContext.clearErrors('streetName')
  }

  // extract address
  const extractAddress = (place: any) => {
    const address = {
      streetName: '',
      streetNumber: '',
      streetDescription: '',
      city: '',
      province: '',
      zipCode: '',
    }

    if (!Array.isArray(place?.address_components)) {
      return address
    }

    place.address_components.forEach((component: any) => {
      const types = component.types
      const value = component.long_name

      if (types.includes('route')) {
        address.streetName = value
      }
      if (types.includes('premise')) {
        address.streetName = value
      }
      if (types.includes('street_number')) {
        address.streetNumber = value
      }
      if (types.includes('subpremise')) {
        address.streetDescription = value
      }
      if (types.includes('locality')) {
        address.city = value
      }
      if (types.includes('administrative_area_level_2')) {
        address.province = value
      }
      if (types.includes('postal_code')) {
        address.zipCode = value
      }
    })
    return address
  }
  return { initGoogle }
}
