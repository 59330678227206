import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreMessageBar,
  OreStack,
  OreText,
  SvgDownload,
} from '@runroom/oreneta'
import { KPI, useKPIsController } from 'src/Flex/Reporting/ui/kpis'
import { OreGrid, OreGridItem } from 'src/Flex/Shared/ui/OreGrid'
import { flexReportingTranslations } from 'src/Flex/Reporting/ui/translations'
import { useUserData } from 'src/Flex/User/ui/context'
import { MetaStatusCodes } from 'src/domain/enum'
import { intialDataResponseErrors } from 'src/domain/definitions'
import { MessagesModel } from 'src/domain/models'
import { Link } from 'react-router-dom'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'

export const KPIsGrid = (): JSX.Element => {
  const { downloadReport } = useKPIsController()
  const { flexData, errorData } = useUserData()
  const { t } = useTranslation()
  if (
    (!flexData || !flexData.isOnboardingDone || flexData.kpis.length === 0) &&
    !errorData
  )
    return <></>
  return (
    <>
      {errorData.status === MetaStatusCodes.ERROR &&
      !intialDataResponseErrors.find(code => code.type === errorData.messages[0].code) ? (
        <OreMessageBar color="error" icon>
          {t(flexReportingTranslations.initialDataError.generic)}
        </OreMessageBar>
      ) : errorData.status === MetaStatusCodes.ERROR &&
        intialDataResponseErrors.find(
          code => code.type === errorData.messages[0].code
        ) ? (
        <OreMessageBar color="error" icon={false}>
          <OreStack direction="column">
            <OreText tone="error-600">
              <b>{t(flexReportingTranslations.initialDataError.smi1)}</b>
              {t(flexReportingTranslations.initialDataError.smi2)}
              <Link to={flexNavigationRoutes.flexEmployees}>
                {t(flexReportingTranslations.initialDataError.smi3)}
              </Link>
              {t(flexReportingTranslations.initialDataError.smi4)}
              <Link to={flexNavigationRoutes.flexEmployeesExcel}>
                {t(flexReportingTranslations.initialDataError.smi5)}
              </Link>
              {t(flexReportingTranslations.initialDataError.smi6)}
            </OreText>
            <OreStack space="xsmall">
              {errorData.messages.map((message: MessagesModel, index: number) => {
                return (
                  <OreText key={index} tone="error-600">
                    {index + 1 + '. ' + message.description}
                  </OreText>
                )
              })}
            </OreStack>
          </OreStack>
        </OreMessageBar>
      ) : (
        <OreGrid rowSpace="medium" templateColumns="1fr 1fr" columnSpace="large">
          {flexData && flexData.kpis && flexData.kpis.length > 0 && (
            <>
              <OreGridItem colSpan={2}>
                <OreHeading size="headline-lg">
                  {t(flexReportingTranslations.title)}
                </OreHeading>
              </OreGridItem>
              <OreGridItem colSpan={2}>
                <OreStack direction="column" space="medium" placeContent="normal">
                  <OreStack direction="row" space="large" placeContent="stretch" sameSize>
                    <KPI kpi={flexData?.kpis[0]} />
                    <KPI kpi={flexData?.kpis[2]} />
                    <KPI kpi={flexData?.kpis[1]} />
                  </OreStack>
                  <OreStack direction="row" space="large" placeContent="stretch" sameSize>
                    <KPI kpi={flexData?.kpis[4]} />
                    <KPI kpi={flexData?.kpis[5]} />
                    <KPI kpi={flexData?.kpis[3]} />
                  </OreStack>
                </OreStack>
              </OreGridItem>
              <OreGridItem colSpan={2}>
                <OreText>{t(flexReportingTranslations.downloadReport.text)}</OreText>
              </OreGridItem>
              <OreGridItem colSpan={2}>
                <OreButton onClick={downloadReport} icon={<SvgDownload />}>
                  {t(flexReportingTranslations.downloadReport.button)}
                </OreButton>
              </OreGridItem>
            </>
          )}
        </OreGrid>
      )}
    </>
  )
}
