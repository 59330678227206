import { ProductTypeSlugsImputation } from 'src/Flex/Orders/ui/order-imputation'
import { ProductTypeSlugs } from 'src/Flex/Orders/ui/order-validation'
import {
  flexNavigationRoutes,
  navigationRoutes,
} from 'src/config/constants/navigationRoutes'

export const myOrdersActiveLinks = [
  navigationRoutes.orderHome,
  navigationRoutes.cardOrderHome,
  navigationRoutes.myOrders,
  navigationRoutes.myOrdersTgdTab,
  navigationRoutes.myOrdersTtTab,
  navigationRoutes.orderCheckout,
  navigationRoutes.cardOrderEdit,
  navigationRoutes.cardOrderDuplicated,
  navigationRoutes.duplicatedCard,
  navigationRoutes.rechargeCard,
  navigationRoutes.unloadCard,
  navigationRoutes.cardOrderRechargeExcel,
  navigationRoutes.cardOrderUnloadExcel,
  navigationRoutes.rechargeHome,
  navigationRoutes.unloadHome,
  navigationRoutes.cardOrderDuplicateConfiguration,
  navigationRoutes.cardOrderDuplicateConfigurationEdit,
  navigationRoutes.cardOrderRechargeConfiguration,
  navigationRoutes.cardOrderRechargeConfigurationEdit,
  navigationRoutes.cardOrderUnloadConfiguration,
  navigationRoutes.cardOrderUnloadConfigurationEdit,
  navigationRoutes.cardOrderNew,
  navigationRoutes.cardOrderNewExcel,
  navigationRoutes.cardOrderExcelHome,
  navigationRoutes.cardOrderExcelAlerts,
  navigationRoutes.cardOrderCompleteError,
  navigationRoutes.cardOrderCompleteSuccess,
  navigationRoutes.tgdOrder,
  navigationRoutes.tgdOrderForm,
  navigationRoutes.tgdOrderFormEdit,
  navigationRoutes.tgdOrderChildForm,
  navigationRoutes.tgdOrderChildFormEdit,
  navigationRoutes.tgdOrderExcel,
  navigationRoutes.tgdOrderStoppersAndWarnings,
  navigationRoutes.tgdOrderSummary,
  navigationRoutes.tgdOrderCheckout,
  navigationRoutes.tgdOrderCompleteError,
  navigationRoutes.tgdOrderCompleteSuccess,
  navigationRoutes.tgdOrderSelectSingleEmployee,
  navigationRoutes.tgdOrderSelectMultipleEmployee,
  navigationRoutes.tgdOrderEmployeesConfiguration,
  navigationRoutes.tgdBasicOrder,
  navigationRoutes.tgdBasicOrderForm,
  navigationRoutes.tgdBasicOrderFormEdit,
  navigationRoutes.tgdBasicOrderChildForm,
  navigationRoutes.tgdBasicOrderChildFormEdit,
  navigationRoutes.tgdBasicOrderSummary,
  navigationRoutes.tgdBasicOrderCheckout,
  navigationRoutes.tgdBasicOrderCompleteError,
  navigationRoutes.tgdBasicOrderCompleteSuccess,
  navigationRoutes.ttOrder,
  navigationRoutes.ttOrderForm,
  navigationRoutes.ttOrderFormEdit,
  navigationRoutes.ttOrderSummary,
  navigationRoutes.ttOrderCheckout,
  navigationRoutes.ttOrderCompleteError,
  navigationRoutes.ttOrderCompleteSuccess,
  navigationRoutes.ttOrderSelectSingleEmployee,
  navigationRoutes.ttOrderSelectMultipleEmployee,
  navigationRoutes.ttOrderEmployeesConfiguration,
  navigationRoutes.ttOrderEmployeesConfigurationEdit,
  navigationRoutes.ttRecharge,
  navigationRoutes.ttRechargeSelectMultipleEmployee,
  navigationRoutes.ttRechargeEmployeesConfiguration,
  navigationRoutes.ttRechargeEmployeesConfigurationEdit,
  navigationRoutes.ttRechargeSummary,
  navigationRoutes.ttRechargeCheckout,
  navigationRoutes.ttRechargeCompleteError,
  navigationRoutes.ttRechargeCompleteSuccess,
  navigationRoutes.ttUnload,
  navigationRoutes.ttUnloadSelectMultipleEmployee,
  navigationRoutes.ttUnloadEmployeesConfiguration,
  navigationRoutes.ttUnloadEmployeesConfigurationEdit,
  navigationRoutes.ttUnloadSummary,
  navigationRoutes.ttUnloadCheckout,
  navigationRoutes.ttUnloadCompleteError,
  navigationRoutes.ttUnloadCompleteSuccess,
  navigationRoutes.ttOrderExcel,
  navigationRoutes.ttOrderExcelSummary,
  navigationRoutes.ttOrderExcelCheckout,
  navigationRoutes.ttOrderExcelCompleteError,
  navigationRoutes.ttOrderExcelCompleteSuccess,
  navigationRoutes.ttNewOrderExcel,
  navigationRoutes.ttNewOrderImportExcel,
  navigationRoutes.ttRechargeExcel,
  navigationRoutes.ttRechargeImportExcel,
  navigationRoutes.ttRechargeSummaryExcel,
  navigationRoutes.ttRechargeExcelCheckout,
  navigationRoutes.ttUnloadExcel,
  navigationRoutes.ttUnloadImportExcel,
  navigationRoutes.ttUnloadSummaryExcel,
  navigationRoutes.ttUnloadExcelCheckout,
]

export const employeesActiveLinks = [
  navigationRoutes.employees,
  navigationRoutes.employeesHome,
  navigationRoutes.employeeProfile,
]

export const deliverySiteTRActiveLinks = [
  navigationRoutes.deliverySiteTR,
  navigationRoutes.deliverySiteNewTR,
  navigationRoutes.deliverySiteNewExcelTR,
  navigationRoutes.deliverySiteEditTR,
  navigationRoutes.deliverySiteEditByIdTR,
]

export const deliverySiteTTActiveLinks = [
  navigationRoutes.deliverySiteTT,
  navigationRoutes.deliverySiteNewTT,
  navigationRoutes.deliverySiteNewExcelTT,
  navigationRoutes.deliverySiteEditTT,
  navigationRoutes.deliverySiteEditByIdTT,
]

export const spendingRulesActiveLinks = [
  navigationRoutes.spendingRules,
  navigationRoutes.spendingRulesNew,
  navigationRoutes.spendingRulesEditById,
]

export const flexEmployeesActiveLinks = [
  flexNavigationRoutes.flexEmployees,
  flexNavigationRoutes.flexEmployeesExcel,
  flexNavigationRoutes.flexEmployeesCreate,
  flexNavigationRoutes.flexEmployeesEdit,
]

export const flexColectivesActiveLinks = [
  flexNavigationRoutes.flexCollectives,
  flexNavigationRoutes.flexCollectivesCreate,
  flexNavigationRoutes.flexCollectivesEdit,
]

export const flexCommunicationsActiveLinks = [
  flexNavigationRoutes.flexCommunicationsTemplates,
  flexNavigationRoutes.flexCommunicationsTemplatesCreate,
  flexNavigationRoutes.flexCommunicationsTemplatesEdit,
]

export const flexValidateTRActiveLinks = [
  flexNavigationRoutes.flexOrdersValidate.replace(
    ':productType',
    ProductTypeSlugs.TicketRestaurant
  ),
]

export const flexValidateTGDActiveLinks = [
  flexNavigationRoutes.flexOrdersValidate.replace(
    ':productType',
    ProductTypeSlugs.Guarderia
  ),
]

export const flexValidateTTActiveLinks = [
  flexNavigationRoutes.flexOrdersValidate.replace(
    ':productType',
    ProductTypeSlugs.Movilidad
  ),
]

export const flexImputationFormationActiveLinks = [
  flexNavigationRoutes.flexProductsImputation.replace(
    ':productType',
    ProductTypeSlugsImputation.Formacion
  ),
]

export const flexImputationSaludExternoActiveLinks = [
  flexNavigationRoutes.flexProductsImputation.replace(
    ':productType',
    ProductTypeSlugsImputation.SaludExterno
  ),
]
