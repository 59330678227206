import { SvgBin, SvgEdit, SvgInfo, SvgPlus } from '@runroom/oreneta'
import { Seq } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
  SelectorModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type {
  FooterModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import type { CardOrderModel } from '../../../../../domain/models'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation, recharge } from '../../../../../domain/translations'
import { currency, emptyValues, orderType } from '../../../../../domain/enum'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import {
  addCurrency,
  getTotalAmountRecharge,
  getUndefinedDashString,
} from '../../../../../core/services'
import { deleteAction, editAction } from '../../../../../domain/constants'
import { FillRechargeConfigurationRows } from '../../../../../core/services/cardOrder'

export const useRechargeHomeController = () => {
  const { orders, removeRechargeOrder, setConfigurationMode } = useCardOrder()
  const navigate = useNavigate()
  const [rows, setRows] = useState<RowModel[]>([])
  const { t } = useTranslation()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(recharge.tableHeader.owner) },
      {
        label: t(recharge.tableHeader.initialBalance),
        tooltip: t(recharge.tableHeader.initialBalanceTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(recharge.tableHeader.rechargeAmount) },
      {
        label: t(recharge.tableHeader.finalBalance),
        tooltip: t(recharge.tableHeader.finalBalanceTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(recharge.tableHeader.center) },
      { label: t(recharge.tableHeader.actions) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(cardOrderTranslation.cardOrderHome.tableBodyEmpty),
  }

  const caption: CaptionModel = {
    title: t(recharge.tableCaption.title),
    description: t(recharge.tableCaption.description),
  }

  const selector: SelectorModel = {
    buttonTitle: '',
    description: '',
    selectors: [],
    title: '',
  }

  const onClickEdit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowId: number
  ) => {
    const cardOrder: CardOrderModel | undefined = Seq(orders).find(
      (order: CardOrderModel) =>
        order.orderId === rowId && order.orderTypeId === orderType.Recharge
    )
    if (cardOrder) {
      const rechargeRows: RowModel[] = FillRechargeConfigurationRows([cardOrder])
      setConfigurationMode({ editMode: true, entity: rechargeRows })
      navigate(navigationRoutes.cardOrderRechargeConfigurationEdit)
    }
  }

  const onClickEditAll = () => {
    const cardOrder = orders.filter(
      (order: CardOrderModel) => order.orderTypeId === orderType.Recharge
    )
    if (cardOrder) {
      const rechargeRows: RowModel[] = FillRechargeConfigurationRows(cardOrder)
      setConfigurationMode({ editMode: true, entity: rechargeRows })
      navigate(navigationRoutes.cardOrderRechargeConfigurationEdit)
    }
  }

  const GetRows = () => {
    const orderRows: RowModel[] = []
    Seq(orders).forEach((order: CardOrderModel) => {
      if (order.orderTypeId === orderType.Recharge) {
        const orderRow: RowModel = {
          rowId: order.orderId,
          cells: [
            {
              type: 'text',
              cellText: [
                {
                  text: getUndefinedDashString(order.employeeData.name),
                  name: 'name',
                  tone: 'neutral-900',
                  size: 'text-sm',
                  isBold: true,
                },
                {
                  text: getUndefinedDashString(order.employeeData.document),
                  name: 'document',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text:
                    order.cardData.balance !== undefined &&
                    order.cardData.balance !== null &&
                    order.cardData.balance !== emptyValues.dash
                      ? order.cardData.balance.toString().includes(currency.euro)
                        ? order.cardData.balance
                        : order.cardData.balance + currency.euro
                      : emptyValues.dash,
                  name: 'balance',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: addCurrency(order.cardData.initialAmount),
                  name: 'initialAmount',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: getTotalAmountRecharge(order),
                  name: 'totalAmount',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: getUndefinedDashString(order.employeeData.costCenter),
                  name: 'costCenter',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'actions',
              cellActions: [
                {
                  name: 'deleteRow',
                  icon: <SvgBin />,
                  type: deleteAction,
                  onClick: onClickDelete,
                },
                {
                  name: 'editRow',
                  icon: <SvgEdit />,
                  type: editAction,
                  onClick: onClickEdit,
                },
              ],
            },
          ],
        }
        orderRows.push(orderRow)
      }
    })

    setRows(orderRows)
  }

  const onClickDelete = (rowId: number) => {
    if (rowId !== undefined) {
      removeRechargeOrder(rowId)
    }
  }

  const footer = () => {
    const footerObj: FooterModel = {
      actionButtons: [
        {
          onClick: () => navigate(navigationRoutes.rechargeCard),
          text: t(recharge.tableFooter.addRecharge),
          category: 'highlight',
          size: 'large',
          icon: <SvgPlus />,
        },
      ],
    }
    if (orders.some(order => order.orderTypeId === orderType.Recharge)) {
      footerObj.actionButtons?.push({
        onClick: onClickEditAll,
        text: t(recharge.tableFooter.editAll),
        icon: <SvgEdit />,
        category: 'highlight',
        size: 'large',
      })
    }
    return footerObj
  }

  useEffect(() => {
    GetRows()
  }, [])

  return {
    rows,
    setRows,
    header,
    footer,
    tableEmpty,
    caption,
    selector,
  }
}
