import { number } from 'yup'
import { RequiredNumberSchema } from 'yup/lib/number'
import { TFunction } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { emptyStringToUndefined } from 'src/presentation/sharedForms/helpers'

export const nonEmptyPositiveNumberSchema = (
  t: TFunction<'translation', undefined>,
  max = Number.MAX_SAFE_INTEGER,
  isInteger = false,
  min = 1
): RequiredNumberSchema<number | undefined> => {
  let schema = number()
    .typeError(t(forms.errors.fieldRequired))
    .transform(emptyStringToUndefined)
    .min(min, t(forms.errors.notZero))
    .max(max, t(forms.errors.maxAllowed) + ' ' + max)
    .required(t(forms.errors.fieldRequired))

  if (isInteger) {
    schema = schema.test(
      'is-integer',
      t(forms.errors.notInteger),
      number => number === undefined || Number.isInteger(number)
    )
  }

  return schema
}
