import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { historyTabs } from '../../../../domain/enum'

interface ContainerState {
  handleTabClick: (index: number) => void
  selectedTab: historyTabs
  setSelectedTab: Dispatch<SetStateAction<historyTabs>>
}

export const useContainerController = (navigateValue?: historyTabs): ContainerState => {
  const [selectedTab, setSelectedTab] = useState(historyTabs.spending)
  const handleTabClick = (index: number): void => setSelectedTab(index)
  useEffect(() => {
    if (navigateValue) setSelectedTab(navigateValue)
  }, [navigateValue])
  return { handleTabClick, selectedTab, setSelectedTab }
}
