import { FormProvider } from 'react-hook-form'
import { Divider } from '../../../../layout'
import { EmployeeDetail, ShipmentDetails } from '../molecules'
import { cardOrderFormTranslation, forms } from '../../../../../domain/translations/'
import { CardDetail } from '../molecules/CardDetail'
import { OreButton, OreHeading, OreStack, OreText, SvgArrowLeft } from '@runroom/oreneta'
import { MessageBar, Modal } from '../../../Edenred'
import { useFormController } from '../hooks'
import { useNavigate } from 'react-router'

export const Form = (): JSX.Element => {
  const {
    ValidateCardOrderModel,
    errorMessage,
    form,
    formParameters,
    t,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    setShowPopup,
    submitPopupShow,
    submitPopupTitle,
    submitPopupButtons,
    submitSetShowPopup,
  } = useFormController()
  const navigate = useNavigate()

  return (
    <FormProvider {...form}>
      <form className="form-organism">
        <div className="pl-05">
          <OreHeading size="headline-lg">
            {t(cardOrderFormTranslation.createNewCard)}
          </OreHeading>
          <Divider />
        </div>
        <EmployeeDetail title={t(cardOrderFormTranslation.employeeDetail.title)} />
        <CardDetail title={t(cardOrderFormTranslation.cardDetail.title)} />
        <ShipmentDetails title={t(cardOrderFormTranslation.shipment.title)} />
        <section className="width-max mt-2">
          <OreText as="p" size="text-sm" tone="neutral-600">
            {t(forms.errors.fieldsRequired)}
          </OreText>
        </section>
      </form>

      <div className="mt-3">
        <Divider />
      </div>

      <MessageBar color="error" message={errorMessage} />
      <div className="mt-2 flex space-between">
        <div>
          <OreButton
            as="button"
            category="highlight"
            onClick={() => navigate(-1)}
            size="large"
            style={{ color: '#0F172A' }}
            icon={<SvgArrowLeft />}>
            {t(forms.buttons.goBack)}
          </OreButton>
        </div>
        <div className="flex flex--row float-right">
          <OreButton
            as="button"
            category="secondary"
            onClick={() => setShowPopup(true)}
            size="large">
            {t(forms.buttons.cancel)}
          </OreButton>

          <OreButton
            as="button"
            category="primary"
            onClick={form.handleSubmit(ValidateCardOrderModel)}
            size="large">
            {!formParameters?.editMode
              ? t(cardOrderFormTranslation.createNewCard)
              : t(cardOrderFormTranslation.modifyCard)}
          </OreButton>
        </div>
      </div>

      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
      />

      <Modal
        handleOnClose={submitSetShowPopup}
        open={submitPopupShow}
        title={submitPopupTitle}
        buttons={submitPopupButtons}
        size="small">
        <OreStack direction="column" space="small">
          <OreText>{t(cardOrderFormTranslation.popup.submitDescription1)}</OreText>
          <OreText>{t(cardOrderFormTranslation.popup.submitDescription2)}</OreText>
          <OreText>{t(cardOrderFormTranslation.popup.submitDescription3)}</OreText>
        </OreStack>
      </Modal>
    </FormProvider>
  )
}
