export interface FilterModel {
  id: string
  label: string
  placeholder?: string
  type: 'text' | 'select' | 'date'
  options?: {
    label: string
    value: string
  }[]
}

export interface FilterResultModel {
  name: string
  value: string
}

export interface SearchParamsModel {
  search: string
  filters: FilterResultModel[]
}

export const convertFiltersToQuery = (
  filters?: FilterResultModel[]
): object | undefined => {
  return filters?.reduce((o, f) => Object.assign(o, { [f.name]: f.value }), {})
}
