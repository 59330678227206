export enum contactType {
  'company' = 1,
  'personal' = 2,
  'other' = 3,
}

export enum deliveryType {
  Default = 0,
  'messenger' = 1,
  'mail' = 2,
}

export enum phonePrefix {
  spain = '+34',
}
