import { useTranslation } from 'react-i18next'
import {
  OrePill,
  OreStack,
  SvgBankTransfer,
  SvgDomiciliaryReceipt,
} from '@runroom/oreneta'
import { PaymentModes } from 'src/domain/enum'
import { orderValidationsTranslations } from 'src/Flex/Orders/ui/order-validation/orderValidationsTranslations'

export type PaymentModePillProps = {
  paymentMode: PaymentModes
}

const paymentModePillText = {
  [PaymentModes.wireTransfer]: orderValidationsTranslations.step2.general.paymentModeBank,
  [PaymentModes.directDebit]:
    orderValidationsTranslations.step2.general.paymentModeDomiciliary,
}

const paymentModeIcon = {
  [PaymentModes.wireTransfer]: SvgBankTransfer,
  [PaymentModes.directDebit]: SvgDomiciliaryReceipt,
}

export const PaymentModePill = ({ paymentMode }: PaymentModePillProps): JSX.Element => {
  const { t } = useTranslation()
  const Icon = paymentModeIcon[paymentMode]
  const text = paymentModePillText[paymentMode]

  return (
    <OrePill>
      <OreStack direction="row" placeContent="center" space="2xsmall">
        <Icon />
        {t(text)}
      </OreStack>
    </OrePill>
  )
}
