export enum Steps {
  StepOne = 'StepOne',
  StepTwo = 'StepTwo',
  StepThree = 'StepThree'
}

export enum  ControlSteps {
  StepError = 3,
  StepSucces = 0
}
