import { useTranslation } from 'react-i18next'
import { employeesTranslation } from '../../../domain/translations/cardOrder/employees'
import { PageHeader } from 'src/presentation/Templates/PageHeader'
import { useEmployeesPageController } from './hooks/useEmployeesPageController'
import { EmployeesPageContainer } from './organism'

const EmployeesPage = (): JSX.Element => {
  const { t } = useTranslation()
  const { productTab } = useEmployeesPageController()
  return (
    <PageHeader
      title={t(employeesTranslation.title.employees)}
      organism={<EmployeesPageContainer navigateValue={productTab} />}
    />
  )
}

export default EmployeesPage
