import { t } from 'i18next'
import type { Dispatch} from 'react';
import { useCallback } from 'react'
import type {
  CellModel,
  RowModel} from '../../../../../domain/customComponents/table/TableModel';


import { forms } from '../../../../../domain/translations'

interface CellComboBoxState {
  onChangeCellComboBox: (event: React.ChangeEvent<HTMLSelectElement>) => void
  IsValidValue: (value: string | undefined) => boolean
  ErrorMessage: (value: string | undefined) => string
}

export const useCellComboBox = (
  rowIndex: number,
  cellIndex: number,
  setRows: Dispatch<React.SetStateAction<RowModel[]>>
): CellComboBoxState => {
  const IsValidValue = useCallback((value: string | undefined): boolean => {
    if (value === '' || value === undefined) {
      return false
    }

    return true
  }, [])

  const ErrorMessage = useCallback((value: string | undefined): string => {
    if (value === '' || value === undefined) {
      return t(forms.errors.fieldRequired)
    }

    return ''
  }, [])

  const onChangeCellComboBox = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setRows(prevRows =>
        prevRows.map((prevRow: RowModel, prevRowIndex: number) => {
          if (prevRowIndex === rowIndex)
            return {
              ...prevRow,
              cells: prevRow.cells.map((prevCell: CellModel, prevCellIndex: number) => {
                if (prevCellIndex === cellIndex && prevCell.cellControll?.name)
                  return {
                    ...prevCell,
                    cellControll: {
                      ...prevCell.cellControll,
                      value: event.target.value,
                      errorMassage: ErrorMessage(event.target.value),
                      hasError: !IsValidValue(event.target.value),
                    },
                  }
                return prevCell
              }),
            }
          return prevRow
        })
      )
    },
    []
  )

  return { onChangeCellComboBox, IsValidValue, ErrorMessage }
}
