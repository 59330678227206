import { edenredProducts } from '../../domain/enum'
import { productsTranslate } from '../../domain/translations/productsTranslate'

export const GetEdenredProducts = (): [string, number][] => {
  const options: [string, number][] = []
  options.push(
    [productsTranslate.ticketRestaurant, edenredProducts.ticketRestaurant],
    [productsTranslate.ticketTransporte, edenredProducts.ticketTransporte],
    [productsTranslate.ticketGuarderia, edenredProducts.ticketGuarderia]
  )
  return options
}
