import { ITimeZones } from 'src/domain/models'
import { SpendingRulesStripe } from '../molecules'
import { OreButton, OreStack, OreText, SvgPlus } from '@runroom/oreneta'
import { useFormContext } from 'react-hook-form'
import { spendingRuleFormTranslation } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'

export const SpendingRulesStripesContainer = (): JSX.Element => {
  const { t } = useTranslation()
  const newStripe = {
    weekDays: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
    },
    timeZoneFrom: '00:00',
    timeZoneTo: '23:59',
    transactionAmount: '',
  }
  const maxStripes = 3

  const form = useFormContext()
  const formValues = form.watch()

  const handleAddStripe = (): void => {
    form.setValue('timeZones', [...form.getValues('timeZones'), newStripe])
  }

  return (
    <>
      {formValues.timeZones?.map((_: ITimeZones, index: number) => (
        <SpendingRulesStripe
          stripeIndex={index}
          totalStripes={formValues.timeZones.length}
          key={'stripe_' + index}
        />
      ))}

      {formValues.timeZones.length < maxStripes && (
        <OreStack space="small" placeItems="start">
          <OreText>
            {t(spendingRuleFormTranslation.form.diet.addStripeDescription)}
          </OreText>
          <OreButton category="highlight" icon={<SvgPlus />} onClick={handleAddStripe}>
            {t(spendingRuleFormTranslation.form.diet.addStripeButton)}
          </OreButton>
        </OreStack>
      )}
    </>
  )
}
