import {
  OreAvatar,
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDatePicker,
  OreDivider,
  OreHeading,
  OreInput,
  OreSelect,
  OreSelectPlaceholder,
  OreStack,
  OreText,
  SvgArrowLeft,
  SvgBin,
  SvgPlus,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { Modal, Textbox } from 'src/presentation/components/Edenred'
import { documentTypeEnumTranslation, forms } from 'src/domain/translations'
import { documentType } from 'src/domain/enum'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { maxChildAge, minChildAge } from 'src/core/helpers'
import { TGDOrderChildLabel } from '../atoms/TGDOrderChildLabel'
import { getAcronym } from 'src/core/services'
import { useTGDBasicOrder } from '../../context/TGDBasicOrderProvider'
import { useTGDBasicOrderFormController } from '../hooks'
import { TGDBasicOrderTranslation } from 'src/domain/translations/tgdOrders/tgdBasicOrderTranslate'
import {
  TGDBasicChildModel,
  TGDBasicEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDBasicOrderModel'

interface Props {
  tgdOrderItemToEdit?: TGDBasicEmployeeAndChildsModel
  isChildForm?: boolean
  isNew?: boolean
}

export const TGDBasicOrderForm = ({
  tgdOrderItemToEdit,
  isChildForm,
  isNew,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    form,
    employeeTextBoxes,
    getChildTextBoxes,
    handleAddChild,
    onClickDelete,
    onRegisterAndAssign,
    setShowDeleteModal,
    showDeleteModal,
    deletePopupButtons,
    setShowCancelModal,
    showCancelModal,
    cancelPopupButtons,
    onClickCancel,
  } = useTGDBasicOrderFormController(tgdOrderItemToEdit, isChildForm, isNew)
  const { tgdOrders } = useTGDBasicOrder()

  const maxChilds = 3

  const formValues = form.watch()

  return (
    <>
      <FormProvider {...form}>
        <div style={{ width: '100%' }}>
          <OreStack placeContent="normal" space="xlarge">
            <OreCardContainer>
              <OreCardWrapper space="xlarge">
                <OreStack placeContent="normal" space="2xlarge">
                  <div>
                    <OreHeading size="headline-lg">
                      {isChildForm
                        ? t(TGDBasicOrderTranslation.form.childTitle)
                        : t(TGDBasicOrderTranslation.form.title)}
                    </OreHeading>
                    <OreDivider space="larger-bottom" />
                    {/* User Form/Labels */}
                    {isChildForm ? (
                      <OreStack placeContent="normal" space="large">
                        <OreAvatar
                          placeholderText={getAcronym(`${tgdOrderItemToEdit?.name}
                          ${tgdOrderItemToEdit?.firstSurname}
                        `)}
                          size="medium"
                          subtitle={
                            tgdOrderItemToEdit?.document +
                            (tgdOrderItemToEdit?.email
                              ? ` | ${tgdOrderItemToEdit.email}`
                              : '') +
                            (tgdOrderItemToEdit?.employeeNumber
                              ? ` | ${t(forms.employee.employeeNumber2)}: ${
                                  tgdOrderItemToEdit?.employeeNumber
                                }`
                              : '')
                          }
                          title={`${tgdOrderItemToEdit?.name}
                            ${tgdOrderItemToEdit?.firstSurname}
                            ${tgdOrderItemToEdit?.secondSurname}
                          `}
                        />
                        {formValues.existingChilds &&
                          formValues.existingChilds.length > 0 && (
                            <OreStack placeContent="normal">
                              <OreHeading size="headline-sm">
                                {t(TGDBasicOrderTranslation.form.user.childTitle)}
                              </OreHeading>
                              {formValues.existingChilds.map(
                                (child: TGDBasicChildModel, i: number) => (
                                  <TGDOrderChildLabel
                                    key={formValues.document + i}
                                    child={child}
                                  />
                                )
                              )}
                            </OreStack>
                          )}
                      </OreStack>
                    ) : (
                      <>
                        <div className="pb-1">
                          <OreHeading size="headline-md">
                            {t(TGDBasicOrderTranslation.form.user.title)}
                          </OreHeading>
                        </div>

                        <OreStack placeContent="normal" space="xsmall">
                          <Textbox
                            register={form.register}
                            errors={form.formState.errors}
                            textBoxList={employeeTextBoxes.slice(0, 2)}
                          />
                          <div className="form-row form-row-nopadding">
                            <div className="form-atom form-atom--half">
                              <OreSelect
                                {...form.register('documentTypeId')}
                                required={true}
                                id="documentTypeId"
                                name="documentTypeId"
                                label={t(forms.contact.documentTypeId)}
                                // disabled={!enableEdit}
                                hasError={!!form.formState.errors?.documentTypeId}
                                errorMessage={
                                  form.formState.errors?.documentTypeId?.message
                                }>
                                <OreSelectPlaceholder
                                  label={t(forms.placeholders.select)}
                                />
                                ,
                                <option
                                  key={'option_' + documentType.DniNie}
                                  label={t(documentTypeEnumTranslation.DniNie)}
                                  value={documentType.DniNie}
                                />
                                ,
                                <option
                                  key={'option_' + documentType.Passport}
                                  label={t(documentTypeEnumTranslation.Passport)}
                                  value={documentType.Passport}
                                />
                              </OreSelect>
                            </div>
                            <div className="form-atom form-atom--half">
                              <OreInput
                                id="name"
                                label={t(forms.contact.document)}
                                {...form.register('document')}
                                placeholder="Placeholder"
                                required
                                type="text"
                                maxLength={14}
                                autoComplete="off"
                                hasError={!!form.formState.errors?.document}
                                errorMessage={form.formState.errors?.document?.message}
                              />
                            </div>
                          </div>
                          <Textbox
                            register={form.register}
                            errors={form.formState.errors}
                            textBoxList={employeeTextBoxes.slice(2)}
                          />
                        </OreStack>
                      </>
                    )}
                  </div>

                  {/* Child Form */}
                  {formValues.childs?.map((_: TGDBasicChildModel, index: number) => (
                    <OreStack placeContent="normal" space="large" key={'child_' + index}>
                      <OreStack
                        direction="row"
                        placeContent="space-between"
                        placeItems="center"
                        space="medium">
                        <OreHeading size="headline-md">
                          {t(TGDBasicOrderTranslation.form.child.title)}
                        </OreHeading>
                        {formValues.childs.length > 1 && (
                          <OreButton
                            icon={<SvgBin />}
                            onClick={() => onClickDelete(index)}>
                            {t(TGDBasicOrderTranslation.form.child.deleteChild)}
                          </OreButton>
                        )}
                      </OreStack>

                      <OreStack placeContent="normal" space="xsmall">
                        <Textbox
                          register={form.register}
                          errors={form.formState.errors}
                          textBoxList={getChildTextBoxes(index).slice(0, 2)}
                        />
                        <div className="form-row form-row-nopadding">
                          <div className="form-atom">
                            <OreDatePicker
                              {...form.register(`childs.${index}.birthDate`)}
                              id={`childs_${index}_birthDate`}
                              label={t(forms.contact.birthdate)}
                              legend={t(
                                TGDBasicOrderTranslation.form.child.birthdate.helpText
                              )}
                              max={minChildAge()}
                              min={maxChildAge(6)}
                              required
                              hasError={
                                !!(
                                  form.formState.errors.childs &&
                                  Array.isArray(form.formState.errors.childs) &&
                                  form.formState.errors.childs[index]?.birthDate
                                )
                              }
                              errorMessage={
                                form.formState.errors.childs &&
                                Array.isArray(form.formState.errors.childs) &&
                                form.formState.errors.childs[index]?.birthDate?.message
                              }
                            />
                          </div>
                        </div>
                      </OreStack>

                      {/* Kindergarten Assignment */}
                      {/* <OreStack placeContent="normal" space="medium">
                        <OreHeading size="headline-sm">
                          {t(TGDBasicOrderTranslation.form.child.kindergartenAssignment)}
                        </OreHeading>
                        <OreFormGroup
                          direction="column"
                          hasError={
                            !!(
                              form.formState.errors.childs &&
                              Array.isArray(form.formState.errors.childs) &&
                              form.formState.errors.childs[index]?.kindergartenId
                            )
                          }
                          errorMessage={
                            form.formState.errors.childs &&
                            Array.isArray(form.formState.errors.childs) &&
                            form.formState.errors.childs[index]?.kindergartenId?.message
                          }
                          required>
                          <TGDOrderFormKindergartenAssignment childIndex={index} />
                        </OreFormGroup>
                      </OreStack> */}
                      <Textbox
                        register={form.register}
                        errors={form.formState.errors}
                        textBoxList={getChildTextBoxes(index).slice(-1)}
                      />
                    </OreStack>
                  ))}

                  {formValues.childs.length +
                    Number(formValues.existingChilds?.length || 0) <
                    maxChilds && (
                    <div>
                      <OreButton icon={<SvgPlus />} onClick={handleAddChild}>
                        {t(TGDBasicOrderTranslation.form.child.addAnotherChild)}
                      </OreButton>
                    </div>
                  )}
                  <OreText>{t(TGDBasicOrderTranslation.form.mandatoryFields)}</OreText>
                </OreStack>
              </OreCardWrapper>
            </OreCardContainer>

            <div>
              <OreDivider space="medium" />

              <OreStack direction="row" placeContent="space-between" placeItems="normal">
                <OreButton
                  category="tertiary"
                  icon={<SvgArrowLeft />}
                  onClick={() => navigate(-1)}>
                  {t(forms.buttons.goBack)}
                </OreButton>
                <OreStack direction="row" space="medium">
                  {!tgdOrderItemToEdit && !tgdOrders.length && (
                    <OreButton onClick={() => onClickCancel()}>
                      {t(forms.buttons.cancel)}
                    </OreButton>
                  )}
                  <OreButton
                    category="primary"
                    onClick={form.handleSubmit(onRegisterAndAssign)}>
                    {tgdOrderItemToEdit
                      ? isChildForm && isNew
                        ? t(TGDBasicOrderTranslation.form.addToOrder)
                        : t(forms.buttons.saveChanges)
                      : t(TGDBasicOrderTranslation.form.addToOrder)}
                  </OreButton>
                </OreStack>
              </OreStack>
            </div>
          </OreStack>
        </div>
      </FormProvider>
      <Modal
        handleOnClose={setShowDeleteModal}
        open={showDeleteModal}
        buttons={deletePopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {t(TGDBasicOrderTranslation.form.child.deleteModalTitle)}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {t(TGDBasicOrderTranslation.form.child.deleteModalDescription)}
          </OreText>
        </>
      </Modal>
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {t(TGDBasicOrderTranslation.form.child.cancelModalTitle)}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {t(TGDBasicOrderTranslation.form.child.cancelModalDescription)}
          </OreText>
        </>
      </Modal>
    </>
  )
}
