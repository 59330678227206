import { OreButton, OreHeading, OreStack, OreText, SvgDownload } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDownloader } from '../hooks'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { EmployeeDocumentType } from 'src/domain/enum'

interface Props {
  employeeId: string
}

export const DownloadDocuments = ({ employeeId }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { downloadEmployeeDocument } = useDownloader()
  return (
    <React.Fragment key=".0">
      <OreStack>
        <div>
          <OreHeading size="headline-md">
            {t(flexProductsTranslations.download.title)}
          </OreHeading>
        </div>
        <OreText>{t(flexProductsTranslations.download.subtitle)}</OreText>
        <OreStack direction="row">
          <OreButton
            icon={<SvgDownload />}
            category="secondary"
            onClick={async (): Promise<void> =>
              downloadEmployeeDocument(
                employeeId,
                EmployeeDocumentType.signedNovationContract,
                t(flexProductsTranslations.download.contract_button)
              )
            }>
            {t(flexProductsTranslations.download.contract_button)}
          </OreButton>
          <OreButton
            icon={<SvgDownload />}
            category="secondary"
            onClick={async (): Promise<void> =>
              downloadEmployeeDocument(
                employeeId,
                EmployeeDocumentType.signedNovationContractCertificate,
                t(flexProductsTranslations.download.certificate_button)
              )
            }>
            {t(flexProductsTranslations.download.certificate_button)}
          </OreButton>
        </OreStack>
      </OreStack>
    </React.Fragment>
  )
}
