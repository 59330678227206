// arrow function
import { excelRequestType, orderType } from '../../../../domain/enum'



export const GetExcelOrderTypeRequest = (_orderType: number) => {
  if (_orderType == orderType.Card) return excelRequestType.CardsOptionalRecharge
  if (_orderType == orderType.Recharge) return excelRequestType.Recharge
  if (_orderType == orderType.Unload) return excelRequestType.Unload
  return excelRequestType.none
}
