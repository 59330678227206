export const invoiceTranslation = {
  tabs: {
    invoices: 'invoices.tabs.invoices',
    payments: 'invoices.tabs.payments',
  },
  title: 'invoices.title',
  tooltip: {
    creditNoteAvailable: 'invoices.tooltip.creditNoteAvailable',
    invoiceAvailable: 'invoices.tooltip.invoiceAvailable',
    invoiceNotAvailable: 'invoices.tooltip.invoiceNotAvailable',
    invoiceXMLFormat: 'invoices.tooltip.invoiceXMLFormat',
  },
  invoices: {
    table: {
      header: {
        date: 'invoices.table.header.orderDate',
        invoiceNumber: 'invoices.table.header.invoiceNumber',
        orderNumber: 'invoices.table.header.orderNumber',
        product: 'invoices.table.header.product',
        expireDate: 'invoices.table.header.expireDate',
        state: 'invoices.table.header.state',
        amountTotal: 'invoices.table.header.amountTotal',
        amountPending: 'invoices.table.header.amountPending',
        actions: 'invoices.table.header.actions',
      },
      pagination: {
        next: 'invoices.lastOrders.table.pagination.next',
        back: 'invoices.lastOrders.table.pagination.back',
        of: 'invoices.lastOrders.table.pagination.of',
      },
      caption: {
        invoices: 'invoices.lastOrders.table.caption.orders',
      },
      tableEmpty: {
        message: 'invoices.table.tableEmty.message',
      },
    },
    status: {
      paid: 'invoices.status.paid',
      expired: 'invoices.status.expired',
      pending: 'invoices.status.pending',
      partiallyPaid: 'invoices.status.partiallyPaid',
      canceled: 'invoices.status.canceled',
    },
  },
  creditNotes: {
    table: {
      header: {
        date: 'creditNotes.table.header.orderDate',
        creditNoteNumber: 'creditNotes.table.header.creditNoteNumber',
        orderNumber: 'creditNotes.table.header.orderNumber',
        product: 'creditNotes.table.header.product',
        expireDate: 'creditNotes.table.header.expireDate',
        status: 'creditNotes.table.header.status',
        amountTotal: 'creditNotes.table.header.amountTotal',
        balanceDue: 'creditNotes.table.header.balanceDue',
        actions: 'creditNotes.table.header.actions',
      },
      pagination: {
        next: 'creditNotes.table.pagination.next',
        back: 'creditNotes.table.pagination.back',
        of: 'creditNotes.table.pagination.of',
      },
    },
    status: {
      available: 'creditNotes.status.available',
      refunded: 'creditNotes.status.refunded',
      affected: 'creditNotes.status.affected',
      cancelled: 'creditNotes.status.cancelled',
    },
    filter: {
      textBox: 'creditNotes.filter.textBox',
      searchButton: 'creditNotes.filter.searchButton',
      resetButton: 'creditNotes.filter.resetButtons',
      product: 'creditNotes.filter.product',
      dateFrom: 'creditNotes.filter.dateFrom',
      dateTo: 'creditNotes.filter.dateTo',
      status: 'creditNotes.filter.status',
      comboBoxPlaceHolder: 'creditNotes.filter.comboBoxPlaceHolder',
      datePickerPlaceHolder: 'creditNotes.filter.datePickerPlaceHolder',
    },
  },
}
