import type { TFunction } from 'react-i18next'
import { array, object } from 'yup'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { forms } from 'src/domain/translations'
import { edenredProducts } from 'src/domain/enum'
import type { TicketTransporteConfigurationModel } from 'src/Flex/Products/domain'
import {
  useConfigureProduct,
  listToColumnsByTemplate,
} from 'src/Flex/Products/ui/product-configuration'
import { GetDateFormatted, getToday } from 'src/core/helpers'
import {
  dateMinTest,
  dateSchema,
  nonEmptyPositiveNumberSchema,
  stringSchema,
} from 'src/Flex/Shared/ui/Form'

export interface TicketTransporteForm {
  globalStartDate?: string
  globalMonths?: string[]
  globalMonthlyLimit?: number

  startDate: string
  monthlyLimit: number | undefined
  months: string[]

  editableDate?: boolean
  active: boolean
}

export const useConfigureTicketTransporteController = () =>
  useConfigureProduct<TicketTransporteForm, edenredProducts.ticketTransporte>({
    productType: edenredProducts.ticketTransporte,
    formProps: {
      defaultValues: {
        startDate: '',
        monthlyLimit: undefined,
        months: [],
        active: true,
      },
    },
    globalValues: form => ({
      startDate: form.getValues('globalStartDate') ?? form.getValues('startDate'),
      monthlyLimit:
        form.getValues('globalMonthlyLimit') ?? form.getValues('monthlyLimit'),
      months: form.getValues('globalMonths') ?? form.getValues('months'),
      active: form.getValues('active'),
    }),
    formValidationScheme: (t: TFunction<'translation', undefined>) =>
      object({
        startDate: stringSchema(t).when(
          'editableDate',
          (editableDate: boolean | undefined, schema) =>
            editableDate === false
              ? schema
              : dateSchema(t, new Date(getToday())).when(
                  'globalStartDate',
                  (globalStartDate: string | undefined, schema) =>
                    globalStartDate && new Date(globalStartDate) > new Date(getToday())
                      ? dateMinTest(
                          schema,
                          t(
                            flexCollectivesTranslations.configure.edit.products.error
                              .date,
                            {
                              globalStartDate: GetDateFormatted(globalStartDate),
                            }
                          ),
                          new Date(globalStartDate)
                        )
                      : schema
                )
        ),
        monthlyLimit: nonEmptyPositiveNumberSchema(t).when(
          'globalMonthlyLimit',
          (globalMonthlyLimit: number | undefined, schema) =>
            globalMonthlyLimit
              ? schema.max(
                  globalMonthlyLimit,
                  t(flexCollectivesTranslations.configure.edit.products.error.exceded, {
                    globalMonthlyLimit,
                  })
                )
              : schema
        ),
        months: array()
          .min(1, t(forms.errors.minMonths))
          .when('globalMonths', (globalMonths: string[] | undefined, schema) =>
            schema.test(
              'contains-different-than-global-months',
              t(flexCollectivesTranslations.configure.edit.products.error.months),
              (value: string[]) =>
                !globalMonths || value.every(month => globalMonths.includes(month))
            )
          ),
      }),
    convertFromAPIToForm: (
      data: TicketTransporteConfigurationModel
    ): TicketTransporteForm => {
      const months: string[] = []
      Object.entries(data.months).forEach(([key, value]) => {
        if (value) months.push(key)
      })

      const globalMonths: string[] = []
      Object.entries(data.clientMonths ?? {}).forEach(([key, value]) => {
        if (value) globalMonths.push(key)
      })

      return {
        globalStartDate:
          undefined !== data.clientStartDate
            ? new Date(data.clientStartDate).toISOString().substring(0, 10)
            : undefined,
        globalMonths: globalMonths.length > 0 ? globalMonths : undefined,
        globalMonthlyLimit: data.clientMonthlyLimit,
        startDate: new Date(data.startDate).toISOString().substring(0, 10),
        monthlyLimit:
          data.monthlyLimit === 0 ? undefined : Math.round(data.monthlyLimit * 100) / 100,
        months: listToColumnsByTemplate(Object.keys(forms.months), months, 4),
        editableDate: data.canUpdateStartDate,
        active: data.active,
      }
    },
    convertFromFormToAPI: (
      data: TicketTransporteForm
    ): TicketTransporteConfigurationModel => ({
      startDate: new Date(data.startDate).toISOString(),
      monthlyLimit: data.monthlyLimit ?? 0,
      months: {
        january: data.months.includes('january'),
        february: data.months.includes('february'),
        march: data.months.includes('march'),
        april: data.months.includes('april'),
        may: data.months.includes('may'),
        june: data.months.includes('june'),
        july: data.months.includes('july'),
        august: data.months.includes('august'),
        september: data.months.includes('september'),
        october: data.months.includes('october'),
        november: data.months.includes('november'),
        december: data.months.includes('december'),
      },
      active: data.active,
    }),
  })
