export const spendingRuleTranslation = {
  title: 'spendingRuleTranslation.title',
  addButton: 'spendingRuleTranslation.addButton',
  description1: 'spendingRuleTranslation.description1',
  description2: 'spendingRuleTranslation.description2',
  totalCards: 'spendingRuleTranslation.totalCards',
  table: {
    title: 'spendingRuleTranslation.table.title',
    caption: 'spendingRuleTranslation.table.caption',
    header: {
      name: 'spendingRuleTranslation.table.header.name',
      days: 'spendingRuleTranslation.table.header.days',
      timesheet: 'spendingRuleTranslation.table.header.timesheet',
      types: 'spendingRuleTranslation.table.header.types',
      actions: 'spendingRuleTranslation.table.header.actions',
    },
  },
  weekDays: {
    firstLetter: {
      monday: 'spendingRuleTranslation.weekDays.firstLetter.monday',
      tuesday: 'spendingRuleTranslation.weekDays.firstLetter.tuesday',
      wednesday: 'spendingRuleTranslation.weekDays.firstLetter.wednesday',
      thursday: 'spendingRuleTranslation.weekDays.firstLetter.thursday',
      friday: 'spendingRuleTranslation.weekDays.firstLetter.friday',
      saturday: 'spendingRuleTranslation.weekDays.firstLetter.saturday',
      sunday: 'spendingRuleTranslation.weekDays.firstLetter.sunday',
    },
  },
  restrictions: {
    byAmount: {
      title: 'spendingRuleTranslation.restrictions.byAmount.title',
      day: 'spendingRuleTranslation.restrictions.byAmount.day',
      week: 'spendingRuleTranslation.restrictions.byAmount.week',
      month: 'spendingRuleTranslation.restrictions.byAmount.month',
      year: 'spendingRuleTranslation.restrictions.byAmount.year',
    },
    byTransaction: {
      title: 'spendingRuleTranslation.restrictions.byTransaction.title',
      day: 'spendingRuleTranslation.restrictions.byTransaction.day',
      week: 'spendingRuleTranslation.restrictions.byTransaction.week',
      month: 'spendingRuleTranslation.restrictions.byTransaction.month',
      year: 'spendingRuleTranslation.restrictions.byTransaction.year',
      maxTransactions:
        'spendingRuleTranslation.restrictions.byTransaction.maxTransactions',
    },
    maxTransactionAmount: 'spendingRuleTranslation.restrictions.maxTransactionAmount',
  },
  actions: {
    favorite: {
      successMessage: 'spendingRuleTranslation.actions.favorite.successMessage',
    },
    delete: {
      modal: {
        title: 'spendingRuleTranslation.actions.delete.modal.title',
        description: 'spendingRuleTranslation.actions.delete.modal.description',
        delete: 'spendingRuleTranslation.actions.delete.modal.delete',
      },
      deleteFavTooltip: 'spendingRuleTranslation.actions.delete.deleteFavTooltip',
    },
    deleteAndReplace: {
      modal: {
        description: 'spendingRuleTranslation.actions.deleteAndReplace.modal.description',
        selectLabel: 'spendingRuleTranslation.actions.deleteAndReplace.modal.selectLabel',
        selectPlaceholder:
          'spendingRuleTranslation.actions.deleteAndReplace.modal.selectPlaceholder',
        delete: 'spendingRuleTranslation.actions.deleteAndReplace.modal.delete',
      },
    },
  },
  editPage: {
    title: 'spendingRuleTranslation.editPage.title',
    warningMessage: 'spendingRuleTranslation.editPage.warningMessage',
  },
}
