import {
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableFooter,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import { CardOrderDetailModel } from 'src/domain/models'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { orderValidationsTranslations } from 'src/Flex/Orders/ui/order-validation/orderValidationsTranslations'
import { useTranslation } from 'react-i18next'

type OrderValidationSummaryDetailProps = {
  name: string
  counter: string
  detail: CardOrderDetailModel
}

export const OrderValidationSummaryDetail = ({
  name,
  counter,
  detail,
}: OrderValidationSummaryDetailProps): JSX.Element => {
  const { t } = useTranslation()
  const summaryHeaders: HeadersModel[] = [
    {
      key: 'services',
      label: t(orderValidationsTranslations.step2.detail.services),
    },
    {
      key: 'units',
      label: t(orderValidationsTranslations.step2.detail.units),
    },
    {
      key: 'cost',
      label: t(orderValidationsTranslations.step2.detail.cost),
      align: 'right',
    },
  ]

  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption title={name} counter={counter} />
        <OreTableHead>
          <OreTableRow>
            {summaryHeaders.map((header: HeadersModel) => (
              <OreTableCell as="th" key={header.key} align={header.align}>
                {header.label}
              </OreTableCell>
            ))}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody>
          {detail.services.map((service, index) => (
            <OreTableRow key={index}>
              <OreTableCell size="half">{service.label}</OreTableCell>
              <OreTableCell>{service.quantity}</OreTableCell>
              <OreTableCell align="right">{service.value}</OreTableCell>
            </OreTableRow>
          ))}
        </OreTableBody>
        <OreTableFooter>
          <OreTableRow>
            <OreTableCell size="half"></OreTableCell>
            <OreTableCell>
              <OreText size="text-sm">
                {t(orderValidationsTranslations.step2.detail.subtotal)}
              </OreText>
              <OreText size="text-sm">
                {t(orderValidationsTranslations.step2.detail.iva)}
              </OreText>
              <OreText bold tone="neutral-800" size="text-sm">
                {t(orderValidationsTranslations.step2.detail.total)}
              </OreText>
            </OreTableCell>
            <OreTableCell>
              <OreText size="text-sm" align="right">
                {detail.subtotal}
              </OreText>
              <OreText size="text-sm" align="right">
                {detail.iva}
              </OreText>
              <OreText size="text-sm" bold tone="neutral-800" align="right">
                {detail.total}
              </OreText>
            </OreTableCell>
          </OreTableRow>
        </OreTableFooter>
      </OreTable>
    </OreTableContainer>
  )
}
