import { ReportingRepositoryContract } from 'src/Flex/Reporting/domain'
import { downloadPlanReport } from 'src/config/constants/endpoints'
import { IFile } from 'src/domain/interfaces/global/IFile'
import { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'

export class ReportingRepository implements ReportingRepositoryContract {
  downloadReport(): Promise<HttpModel<IFile>> {
    return axiosFetch(downloadPlanReport, HttpMethod.GET)
  }
}
