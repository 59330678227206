import { useEffect, useState } from 'react'
import type { CaptionModel } from '../../../../../domain/customComponents/table/TableModel'

interface CaptionState {
  description: string
}

export const useCaption = (totalRows: number, caption: CaptionModel): CaptionState => {
  const [description, setDescription] = useState<string>('')

  const GetDescription = (): void => {
    let text: string = description
    if (caption.description !== '') {
      if (caption.rowCount) text = caption.rowCount.toString() + ' ' + caption.description
      else text = totalRows.toString() + ' ' + caption.description
      setDescription(text)
    }
  }

  useEffect(() => {
    GetDescription()
  }, [caption, totalRows])
  return { description }
}
