import {
  OreButton,
  OreDivider,
  OreHeading,
  OrePagination,
  OrePill,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  SvgAlert,
  SvgDownload,
  SvgRepeat,
  SvgWarning,
} from '@runroom/oreneta'
import { Modal } from 'src/presentation/components/Edenred'
import { useTranslation } from 'react-i18next'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { TGDOrderExcelState } from '../hooks/useTGDOrderImportExcel'
import { MessagesModel } from 'src/domain/models'
import { excelAlert } from 'src/domain/enum'
import { useEffect, useState } from 'react'

interface Props {
  props: TGDOrderExcelState
}

export const TGDOrderStoppersAndWarnings = ({ props }: Props): JSX.Element => {
  const { t } = useTranslation()
  const [hasError, setHasError] = useState<boolean>(false)

  const getAlertLevel = (alertLevel: string): JSX.Element => {
    if (alertLevel === excelAlert.error) {
      return (
        <OrePill icon={<SvgAlert />} tone="error-600">
          {t(cardOrderTranslation.excelAlerts.table.errorLabel)}
        </OrePill>
      )
    }
    if (alertLevel === excelAlert.warning) {
      return (
        <OrePill icon={<SvgWarning />} tone="warning-600">
          {t(cardOrderTranslation.excelAlerts.table.warningLabel)}
        </OrePill>
      )
    }
    return <></>
  }

  const checkHasError = (): void => {
    const error = props.orderExcelAlerts.find(alert => alert.level === excelAlert.error)
    setHasError(!!error)
  }

  const downloadFile = async () => {
    if (props.errorsFile === '') return
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${props.errorsFile}`
    link.download = 'listado-errores.xlsx'
    link.click()
    link.remove()
  }

  useEffect(() => {
    checkHasError()
  }, [])

  return (
    <>
      <div className="width-90">
        {hasError ? (
          <OreStack>
            <OreText bold>
              {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.title)}
            </OreText>
            <OreText>
              {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.description)}
            </OreText>
            <OreStack space="xsmall">
              <ul className="pl-1">
                <li>
                  {' '}
                  <OreText>
                    {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.option1)}
                    <span style={{ fontWeight: 'bold' }}>
                      {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.option1bold)}
                    </span>
                    {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.option11)}
                  </OreText>
                </li>
                <li>
                  {' '}
                  <OreText>
                    {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.option2)}
                    <span style={{ fontWeight: 'bold' }}>
                      {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.option2bold)}
                    </span>
                    {t(TGDOrderTranslation.stoppersAndWarnings.stoppers.option22)}
                  </OreText>
                </li>
              </ul>
            </OreStack>
          </OreStack>
        ) : (
          <OreStack>
            <OreText>
              {t(TGDOrderTranslation.stoppersAndWarnings.warnings.description1)}
              <span style={{ fontWeight: 'bold' }}>
                {t(TGDOrderTranslation.stoppersAndWarnings.warnings.description2)}
              </span>
            </OreText>
          </OreStack>
        )}
      </div>
      <OreTableContainer>
        <div className="bg-white px-1">
          <div className="py-105">
            <OreHeading size="headline-md">
              {t(TGDOrderTranslation.stoppersAndWarnings.grid.title)}
            </OreHeading>
          </div>
        </div>
        <OreTable>
          <OreTableHead>
            <OreTableRow hover={false}>
              <OreTableCell as="th">
                {t(TGDOrderTranslation.stoppersAndWarnings.grid.row)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(TGDOrderTranslation.stoppersAndWarnings.grid.errorType)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(TGDOrderTranslation.stoppersAndWarnings.grid.errorDescription)}
              </OreTableCell>
            </OreTableRow>
          </OreTableHead>
          <OreTableBody as="tbody" valign="top">
            {props.orderExcelAlerts
              .slice((props.page - 1) * props.pageSize, props.page * props.pageSize)
              .map((message: MessagesModel, index: number) => {
                return (
                  <OreTableRow valign="middle" key={index} hover={false}>
                    <OreTableCell align="left" as="td" size="auto">
                      <OreText>{message.value}</OreText>
                    </OreTableCell>
                    <OreTableCell align="left" as="td" size="auto">
                      <div className="whitespace-nowrap">
                        {getAlertLevel(message.level)}
                      </div>
                    </OreTableCell>
                    <OreTableCell align="left" as="td" size="auto">
                      <OreText>{message.description}</OreText>
                    </OreTableCell>
                  </OreTableRow>
                )
              })}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <OrePagination
            count={props.orderExcelAlerts.length}
            labelNextPage={t(forms.pagination.next)}
            labelPreviousPage={t(forms.pagination.back)}
            onPageChange={props.onPageChange}
            page={props.page}
            rowsPerPage={props.pageSize}
            text={t(forms.pagination.of)}
          />
        </OreTableActions>
      </OreTableContainer>
      <div>
        <Grid justify="flex-start" gap="1.5rem">
          <OreButton
            as="button"
            category="secondary"
            size="large"
            icon={<SvgDownload />}
            onClick={() => downloadFile()}>
            {t(TGDOrderTranslation.stoppersAndWarnings.downloadButton)}
          </OreButton>
          <OreButton
            as="button"
            category="secondary"
            size="large"
            icon={<SvgRepeat />}
            onClick={() => {
              props.setHasStoppersOrWarnings(false)
              props.setErrorsFile('')
              props.setOrderExcelAlerts([])
            }}>
            {t(TGDOrderTranslation.stoppersAndWarnings.changeFile)}
          </OreButton>
        </Grid>
      </div>
      <div>
        <OreDivider />
        <div className="py-1">
          <Grid justify="flex-end" gap="1.5rem">
            <OreButton
              as="button"
              category="secondary"
              size="large"
              onClick={props.onClickCancel}>
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              size="large"
              disabled={hasError}
              onClick={props.warningsContinue}>
              {t(TGDOrderTranslation.stoppersAndWarnings.continueToResume)}
            </OreButton>
          </Grid>
        </div>
      </div>
    </>
  )
}
