import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgShow,
  SvgUnsubscribeUser,
} from '@runroom/oreneta'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useTranslation } from 'react-i18next'
import { getUndefinedDashString } from 'src/core/services'
import { EmployeesNurseryModel } from 'src/domain/models/employeesNursery'

interface Props {
  employeeTGD: EmployeesNurseryModel
  onClickDelete(document: string): void
  onClickEdit(employee: EmployeesNurseryModel): void
}

export const EmployeeListTableTGDRow = ({
  employeeTGD,
  onClickDelete,
  onClickEdit,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreTableRow>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {employeeTGD.userName}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {employeeTGD.userDni}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {getUndefinedDashString(employeeTGD.employeeNumber)}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {employeeTGD.userSons ? employeeTGD.userSons.length : 0}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <Grid gap=".5rem">
          <OreTooltip
            text={t(employeesTranslation.table.header.deleteTooltip)}
            placement="top"
            separation="small">
            <OreIconButton
              onClick={() => onClickDelete(employeeTGD.userDni)}
              icon={<SvgUnsubscribeUser />}
            />
          </OreTooltip>
          <OreTooltip
            text={t(employeesTranslation.table.header.viewTooltip)}
            placement="top"
            separation="small">
            <OreIconButton onClick={() => onClickEdit(employeeTGD)} icon={<SvgShow />} />
          </OreTooltip>
        </Grid>
      </OreTableCell>
    </OreTableRow>
  )
}
