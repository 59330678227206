import { ComponentType } from 'react'
import {
  OreIcon,
  SvgFormation,
  SvgExternalHealth,
  SvgInternalHealth,
  SvgKindergarten,
  SvgRestaurant,
  SvgTransport,
} from '@runroom/oreneta'
import { edenredProducts } from 'src/domain/enum'

export interface IAvailableIcons {
  [key: string]: {
    svg: ComponentType
    tone: 'restaurant' | 'transport' | 'kindergarten' | 'flex-products'
  }
}

export const getProductIconByProductType = (id: edenredProducts): JSX.Element => {
  const availableIcons: IAvailableIcons = {
    [edenredProducts.ticketRestaurant]: {
      svg: SvgRestaurant,
      tone: 'restaurant',
    },
    [edenredProducts.ticketTransporte]: {
      svg: SvgTransport,
      tone: 'transport',
    },
    [edenredProducts.ticketGuarderia]: {
      svg: SvgKindergarten,
      tone: 'kindergarten',
    },
    [edenredProducts.formacion]: {
      svg: SvgFormation,
      tone: 'flex-products',
    },
    [edenredProducts.salud]: {
      svg: SvgInternalHealth,
      tone: 'flex-products',
    },
    [edenredProducts.saludExterno]: {
      svg: SvgExternalHealth,
      tone: 'flex-products',
    },
  }

  if (!(id in availableIcons)) {
    return <OreIcon size="extra-small" />
  }

  const Icon = availableIcons[id].svg
  const tone = availableIcons[id].tone

  return <OreIcon size="extra-small" icon={<Icon />} tone={tone} />
}
