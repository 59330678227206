import {
  OreButton,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import { orderImputationsTranslations } from '../orderImputationsTranslations'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { useOrders } from '../contexts'

export const FlexOrderImputationEmptyRows = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    dates: { cutoffDate },
    productType,
    backToPayroll,
  } = useOrders()

  return (
    <>
      <OreStack placeContent="stretch" space="2xlarge">
        <OreTableContainer>
          <OreTable>
            <OreTableCaption title={t(orderImputationsTranslations.emptyRows.title)} />
            <OreTableBody valign="top">
              <OreTableRow>
                <OreTableCell>
                  <OreText size="text-sm" tone="neutral-600">
                    {t(orderImputationsTranslations.emptyRows.description1)}
                    {t(flexProductsTranslations.ids[productType])}
                    {t(orderImputationsTranslations.emptyRows.description2)}
                  </OreText>
                  <br />
                  {t(orderImputationsTranslations.emptyRows.description3)}{' '}
                  <b>{cutoffDate}</b>
                </OreTableCell>
              </OreTableRow>
            </OreTableBody>
          </OreTable>
        </OreTableContainer>
      </OreStack>
      {t(orderImputationsTranslations.emptyRows.payroll_legend)}
      <OreStack placeContent="start">
        <OreButton category="primary" type="submit" onClick={backToPayroll}>
          {t(orderImputationsTranslations.emptyRows.go_payroll)}
        </OreButton>
      </OreStack>
    </>
  )
}
