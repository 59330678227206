import { OreHeading } from '@runroom/oreneta'
import { stepper } from '../../domain/enum'
import { OrderAndDuplicateStepsData } from '../components/Edenred/stepper'
import Stepper from '../components/Edenred/stepper/Stepper'
import Divider from '../layout/Divider'

interface SimpleCompleteProps {
  imgSrc: string
  title: string
  heading: string
  organisms: React.ReactNode
  success?: boolean
}

export const SimpleComplete = ({
  imgSrc,
  title,
  heading,
  organisms,
  success,
}: SimpleCompleteProps) => (
  <>
    <header className="width-max">
      <OreHeading as="h1" size="title-sm">
        {heading}
      </OreHeading>
    </header>
    <Stepper
      currentStep={stepper.fourthStep}
      stepsFor={OrderAndDuplicateStepsData}
      paddingBottom={60}
      error={!success}
    />
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        textAlign: 'center',
        margin: '0 20%',
      }}>
      <div>
        <figure>
          <img src={imgSrc} alt="" />
        </figure>
      </div>
      <div>
        <header className="width-max mt-2">
          <OreHeading as="h1" size="title-sm" align="center">
            {title}
          </OreHeading>
          <Divider />
        </header>
      </div>
      <div style={{}}>{organisms}</div>
    </div>
  </>
)
