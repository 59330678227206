export const unloadTranslation = {
  name: 'unload.name',
  title: 'unload.title',
  rowsTotalDescription: 'unload.rowsTotalDescription',
  searchTitle: 'unload.searchTitle',
  captionTitle: 'unload.captionTitle',
  showSelected: 'unload.showSelected',
  selectedRowsDescription: 'unload.selectedRowsDescription',
  selectedRowsDescriptionOthers: 'unload.selectedRowsDescriptionOthers',
  nextPageLabel: 'unload.nextPageLabel',
  previousPageLabel: 'unload.previousPageLabel',
  pageOf: 'unload.pageOf',
  NextButtonLabel: 'unload.NextButtonLabel',
  backButtonLabel: 'unload.backButtonLabel',
  tableHeader: {
    owner: 'unload.tableHeader.owner',
    employeeNumber: 'unload.tableHeader.employeeNumber',
    balance: 'unload.tableHeader.balance',
    balanceTooltip: 'tooltip',
    center: 'unload.tableHeader.center',
    type: 'unload.tableHeader.type',
    status: 'unload.tableHeader.status',
    initialBalance: 'unload.tableHeader.initialBalance',
    initialBalanceTooltip: 'unload.tableHeader.initialBalanceTooltip',
    finalBalance: 'unload.tableHeader.finalBalance',
    finalBalanceTooltip: 'unload.tableHeader.finalBalanceTooltip',
    unloadAmount: 'unload.tableHeader.unloadAmount',
    actions: 'unload.tableHeader.actions',
  },
  searchNoFound: {
    first: 'unload.searchNoFound.first',
    link: 'unload.searchNoFound.link',
    end: 'unload.searchNoFound.end',
  },
  tableFooter: {
    addUnload: 'unload.tableFooter.addUnload',
    makeUnload: 'unload.tableFooter.makeUnload',
    editAll: 'unload.tableFooter.editAll',
  },
  tableEmpty: {
    message: 'unload.tableEmpty.message',
  },
  tableCaption: {
    title: 'unload.tableCaption.title',
    description: 'unload.tableCaption.description',
  },
  tableAction: {
    delete: {
      title: 'unload.tableAction.delete.title',
      description: 'unload.tableAction.delete.description',
    },
  },
  rechargeCards: 'unload.rechargeCards',
  cancelModal: {
    title: 'unload.cancelModal.title',
    subtitle: 'unload.cancelModal.subtitle',
  },
}
