import { TFunction } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { orderValidationsTranslations } from 'src/Flex/Orders/ui/order-validation'

export const getOrderValidationTableHeaders = (
  productType: edenredProducts,
  t: TFunction<'translation', undefined>
) => {
  const productHeaders: HeadersModel[] = [
    {
      key: 'owner',
      label: t(orderValidationsTranslations.step1.table.header.owner),
    },
    {
      key: 'monthAmount',
      label: t(orderValidationsTranslations.step1.table.header.monthlyImport),
    },
    {
      key: 'deliverySite',
      label: t(orderValidationsTranslations.step1.table.header.deliveryPoint),
    },
    {
      key: 'costCenter',
      label: t(orderValidationsTranslations.step1.table.header.center),
    },
  ]

  if (productType === edenredProducts.ticketGuarderia) {
    productHeaders.splice(2, 1)
    productHeaders.splice(1, 0, {
      key: 'active',
      label: t(orderValidationsTranslations.step1.table.header.kindergarten),
    })
  }

  return productHeaders
}
