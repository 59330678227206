import {
  OreButton,
  OreCardContainer,
  OreDatePicker,
  OreInput,
  OreToggleButton,
  SvgFilter,
} from '@runroom/oreneta'
import { FormProvider } from 'react-hook-form'
import { forms } from 'src/domain/translations'
import { AssignmentsState } from '../hooks/useAssingments'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { dietSpendingsTranslations } from 'src/domain/translations/history/dietSpendings'
import { getFirstDayOfMonthOneYearBefore, getToday } from 'src/core/helpers'

interface Props {
  assignmentsProps: AssignmentsState
}

export const AssignmentsCaption = ({
  assignmentsProps: assignmentsProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { form, onEnter, onSearch, clearFilters, showMoreFilters, setShowMoreFilters } =
    assignmentsProps

  return (
    <FormProvider {...form}>
      <form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <div className="bg-white px-2 py-2">
          <Grid justify="space-between" items="center" gap="1rem">
            <Grid items="center" gap="1.5rem" className="width-60">
              <OreInput
                {...form.register('multiFilter')}
                id="multiFilter"
                placeholder={t(dietSpendingsTranslations.table.filters.inputPlaceHolder)}
                name="multiFilter"
                type="search"
                onKeyUp={e => onEnter(e)}
              />
              {!showMoreFilters && (
                <OreButton as="button" category="primary" onClick={onSearch} size="small">
                  {t(forms.buttons.search)}
                </OreButton>
              )}
            </Grid>
            <Grid>
              <OreToggleButton
                icon={<SvgFilter aria-label="Filtros" />}
                onClick={() => setShowMoreFilters(!showMoreFilters)}>
                {t(dietSpendingsTranslations.table.filters.addFilter)}
              </OreToggleButton>
            </Grid>
          </Grid>
          {showMoreFilters && (
            <Grid className="pt-2">
              <OreCardContainer tone="neutral-100" hasShadow={false}>
                <Grid columns={2} className="form-atom px-1 pt-105" columnGap="1rem">
                  <div>
                    <OreDatePicker
                      {...form.register('beginDate')}
                      id="beginDate"
                      label={t(dietSpendingsTranslations.table.filters.dateFrom)}
                      min={getFirstDayOfMonthOneYearBefore()}
                      max={getToday()}
                      required
                      hasError={!!form.formState.errors.beginDate}
                      errorMessage={
                        form.formState.errors.beginDate &&
                        form.formState.errors.beginDate?.message
                      }
                    />
                  </div>
                  <div>
                    <OreDatePicker
                      {...form.register('endDate')}
                      id="endDate"
                      label={t(dietSpendingsTranslations.table.filters.dateTo)}
                      min={getFirstDayOfMonthOneYearBefore()}
                      max={getToday()}
                      required
                      hasError={!!form.formState.errors.endDate}
                      errorMessage={
                        form.formState.errors.endDate &&
                        form.formState.errors.endDate?.message
                      }
                    />
                  </div>
                </Grid>

                {showMoreFilters && (
                  <Grid gap="1.5rem" className="px-1 py-1">
                    <OreButton
                      as="button"
                      category="primary"
                      onClick={onSearch}
                      size="small">
                      {t(forms.buttons.search)}
                    </OreButton>
                    <OreButton
                      as="button"
                      category="secondary"
                      onClick={() => clearFilters()}
                      size="small">
                      {t(dietSpendingsTranslations.table.filters.clearFilter)}
                    </OreButton>
                  </Grid>
                )}
              </OreCardContainer>
            </Grid>
          )}
        </div>
      </form>
    </FormProvider>
  )
}
