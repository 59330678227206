import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreSelect,
  OreSelectPlaceholder,
  OreStack,
  OreText,
} from '@runroom/oreneta'
import { forms } from 'src/domain/translations'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { CollectiveModel } from 'src/Flex/Collectives/domain'

export type DeleteModalActions = {
  open: (id: string, hasActiveHires: boolean, isalud: boolean) => void
}

interface IProps {
  onConfirmation: (id: string, newCollectiveId: string) => void
  collectives: CollectiveModel[]
}

export const DeleteCollectiveModal = forwardRef<DeleteModalActions, IProps>(
  ({ onConfirmation, collectives }, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [id, setId] = useState<string>('')
    const [hasActiveHires, setHasActiveHires] = useState<boolean>(false)
    const [isalud, setIsalud] = useState<boolean>(false)
    const { t } = useTranslation()

    const [collectiveId, setCollectiveId] = useState<string>('')

    let collectivesList: {
      label: string
      value: string
    }[] = []

    if (collectives) {
      collectivesList = collectives
        .filter(collective => collective.products.length > 0)
        .map(collective => ({
          value: collective.id,
          label: collective.name,
        }))
    }

    useImperativeHandle(ref, () => ({
      open: async (
        id: string,
        hasActiveHires: boolean,
        isalud: boolean
      ): Promise<void> => {
        setId(id)
        setHasActiveHires(hasActiveHires)
        setIsalud(isalud)
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      onConfirmation(id, collectiveId)
      setOpen(false)
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexCollectivesTranslations.configure.delete.form.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(
                  flexCollectivesTranslations.configure.delete.form[
                    hasActiveHires ? (isalud ? 'isalud' : 'active') : 'inactive'
                  ]
                )}
              </OreText>
              <OreText align="left">
                {t(flexCollectivesTranslations.configure.delete.select.disclaimer)}
              </OreText>
              <OreText align="left">
                {t(flexCollectivesTranslations.configure.delete.select.disclaimer2)}
              </OreText>
              <OreSelect
                label={t(flexCollectivesTranslations.configure.delete.select.label)}
                onChange={e => setCollectiveId(e.target.value)}>
                <OreSelectPlaceholder
                  label={t(
                    flexCollectivesTranslations.configure.delete.select.placeholder
                  )}
                />
                {collectivesList
                  ? collectivesList.map(
                      o =>
                        id !== o.value && (
                          <option value={o.value} key={o.value}>
                            {o.label}
                          </option>
                        )
                    )
                  : null}
              </OreSelect>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              onClick={saveAndClose}
              size="small"
              category="danger"
              disabled={collectiveId === ''}>
              {t(forms.buttons.delete)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
