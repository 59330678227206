import type { Dispatch, SetStateAction} from 'react';
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { RowModel } from '../../../../../domain/customComponents/table/TableModel'

interface CellActionState {
  onDeleteRow: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowId: number
  ) => void
  onConfirmDeleteRow: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowId: number
  ) => void
}

export const useCellAction = (
  setRows: Dispatch<SetStateAction<RowModel[]>>,
  setSelectedRows: Dispatch<React.SetStateAction<number[]>>,
  setTotalRows: Dispatch<SetStateAction<number[]>>,
  modalShow: boolean,
  setModalShow: Dispatch<SetStateAction<boolean>>,
  deleteConfirm: boolean,
  setDeleteConfirm: Dispatch<SetStateAction<boolean>>,
  onClick?: any
): CellActionState => {
  const [deleteRowId, setDeleteRowId] = useState<number>()
  const navigate = useNavigate()

  const onConfirmDeleteRow = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: number) => {
      event.preventDefault()
      setDeleteRowId(rowId)
      setModalShow(true)
    },
    []
  )

  const onDeleteRow = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: number) => {
      event.preventDefault()
      setSelectedRows(oldArray => [
        ...oldArray.filter(selectedRow => selectedRow !== rowId),
      ])
      setRows(oldArray => [...oldArray.filter(row => row.rowId !== rowId)])
      setTotalRows(oldArray => [...oldArray.filter(row => row !== rowId)])
      onClick && onClick(rowId)
    },
    []
  )

  const DeleteRow = useCallback(() => {
    setSelectedRows(oldArray => [
      ...oldArray.filter(selectedRow => selectedRow !== deleteRowId),
    ])
    setRows(oldArray => [...oldArray.filter(row => row.rowId !== deleteRowId)])
    setTotalRows(oldArray => [...oldArray.filter(row => row !== deleteRowId)])
    setDeleteConfirm(false)
    onClick && onClick(deleteRowId)
  }, [deleteRowId])

  useEffect(() => {
    if (deleteConfirm) DeleteRow()
  }, [deleteConfirm])

  useEffect(() => {
    if (!deleteConfirm && !modalShow) {
      setDeleteRowId(0)
    }
  }, [modalShow])

  return { onDeleteRow, onConfirmDeleteRow }
}
