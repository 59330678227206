import { useEffect, useState } from 'react'

import { payrollsService } from 'src/Flex/Payrolls/application'
import { PayrollModel } from 'src/Flex/Payrolls/domain'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { GetMonthYear, downloadExcel } from 'src/core/helpers'
import { SearchParamsModel } from 'src/Flex/Shared/domain'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useTranslation } from 'react-i18next'
import { flexPayrollsTranslations } from '../../translations'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

export interface IUsePayrollController {
  getPayrolls: (date: string) => Promise<void>
  search: (s: string) => void
  changePage: (page: number) => void
  changeDate: (date: string) => void
  numberOfRecordsPerPage: number
  payrolls: PayrollModel[]
  totalRows: number
  page: number
  shownDate: Date | undefined
  download: () => Promise<void>
  searchParameters: SearchParamsModel
}

export const usePayrollController = (): IUsePayrollController => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { user } = useAuth()

  const [payrolls, setPayrolls] = useState<PayrollModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)

  const [page, setPage] = useState<number>(0)
  const [searchParameters, setSearchParameters] = useState<SearchParamsModel>({
    search: '',
    filters: [],
  })

  const [date, setDate] = useState<string>('')
  const [shownDate, setShownDate] = useState<Date>()

  const { handleMetaResponse } = useMetaResponseHandler()

  const numberOfRecordsPerPage = 8

  const getPayrolls = async (date: string, search?: string): Promise<void> => {
    if (date === '') {
      reset()

      return
    }

    startLoading()

    const response = await payrollsService().GetPayrolls(
      page,
      numberOfRecordsPerPage,
      date,
      search
    )
    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setShownDate(new Date(date))
      setPayrolls(response.data.rows)
      setTotalRows(response.data.totalRows)
    } else {
      reset()
    }
  }

  const download = async (): Promise<void> => {
    if (!shownDate) return
    startLoading()
    const monthYear = GetMonthYear(date)

    const response = await payrollsService().GetPayrollExcell(shownDate)

    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, { notifySuccess: false }) &&
      response.data.excel
    ) {
      downloadExcel(
        response.data.excel,
        t(flexPayrollsTranslations.downloadButton.excel) +
          user?.code +
          '_' +
          monthYear.stringMonth +
          '_' +
          monthYear.year
      )
    }
  }

  const reset = (): void => {
    setShownDate(undefined)
    setPayrolls([])
    setTotalRows(0)
  }

  const changePage = (page: number): void => {
    setPage(page - 1)
  }

  const changeDate = (date: string): void => {
    setDate(date)
  }

  const search = async (s: string): Promise<void> => {
    setSearchParameters({ search: s, filters: [] })
    setPage(0)
  }

  useEffect(() => {
    getPayrolls(date, searchParameters.search || undefined)
  }, [page, searchParameters])

  useEffect(() => {
    setPage(0)
    getPayrolls(date)
  }, [date])
  return {
    getPayrolls,
    search,
    changePage,
    changeDate,
    numberOfRecordsPerPage,
    payrolls,
    totalRows,
    page: page ?? 0,
    shownDate,
    download,
    searchParameters,
  }
}
