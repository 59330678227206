import {
  createSpendingRule,
  indexSpendingRule,
  removeSpendingRule,
  setFavSpendingRule,
  singleSpendingRuleById,
  updateSpendingRule,
} from '../../../config/constants/endpoints'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../axios'
import type {
  SpendingRuleAddDTO,
  SpendingRuleDeleteDTO,
  SpendingRuleGetAllDTO,
  SpendingRuleGetByIdDTO,
  SpendingRuleUpdateDTO,
} from '../../../domain/dto/SpendingRuleDTO'
import type { SpendingRuleRepositoryContract } from '../../../core/contracts/spendingRules/SpendingRuleRepositoryContract'
import type {
  SpendingRuleAddProtocol,
  SpendingRuleDeleteProtocol,
  SpendingRuleGetAllProtocol,
  SpendingRuleUpdateProtocol,
} from '../../../domain/protocols/SpendinRuleProtocol'
import { createQueryString } from 'src/core/helpers'

export default class SpendingRuleRepository implements SpendingRuleRepositoryContract {
  async getAll(filters: SpendingRuleGetAllProtocol): Promise<SpendingRuleGetAllDTO> {
    const { url, method, body }: HttpRequest = {
      url: indexSpendingRule,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(
      url.concat(createQueryString(filters)),
      method,
      body
    )
    return response
  }

  async delete(
    SpendingRuleDeleteProtocol: SpendingRuleDeleteProtocol
  ): Promise<SpendingRuleDeleteDTO> {
    const { url, method, body }: HttpRequest = {
      url: removeSpendingRule,
      method: HttpMethod.POST,
      body: SpendingRuleDeleteProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async getById(SpendingRuleGetByProtocol: number): Promise<SpendingRuleGetByIdDTO> {
    const { url, method, body }: HttpRequest = {
      url: singleSpendingRuleById + SpendingRuleGetByProtocol,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async update(
    SpendingRuleUpdateProtocol: SpendingRuleUpdateProtocol
  ): Promise<SpendingRuleUpdateDTO> {
    const { url, method, body }: HttpRequest = {
      url: updateSpendingRule,
      method: HttpMethod.POST,
      body: SpendingRuleUpdateProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async setFav(spendingRuleId: number): Promise<SpendingRuleUpdateDTO> {
    const { url, method, body }: HttpRequest = {
      url: setFavSpendingRule,
      method: HttpMethod.POST,
      body: { usageProfileId: spendingRuleId },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async add(
    SpendingRuleAddProtocol: SpendingRuleAddProtocol
  ): Promise<SpendingRuleAddDTO> {
    const { url, method, body }: HttpRequest = {
      url: createSpendingRule,
      method: HttpMethod.POST,
      body: SpendingRuleAddProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
