import { Modal } from 'src/presentation/components/Edenred'
import { OreHeading, OreText } from '@runroom/oreneta'
import { TTOrderSaveAndClose } from '../../TTOrderSaveAndClose/TTOrderSaveAndClose'
import { useTTRechargeSummaryController } from '../hooks/useTTRechargeSummaryController'
import {
  TTRechargeSummaryButtons,
  TTRechargeSummaryForm,
  TTRechargeSummaryTable,
} from '../molecules'

export const TTRechargeSummaryContainer = (): JSX.Element => {
  const props = useTTRechargeSummaryController()
  const {
    showDeleteModal,
    setShowDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
  } = props
  return (
    <>
      <TTRechargeSummaryTable ttProps={props} />
      <TTRechargeSummaryForm ttProps={props} />
      <TTRechargeSummaryButtons ttProps={props} />
      <Modal
        handleOnClose={setShowDeleteModal}
        open={showDeleteModal}
        buttons={deletePopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{deleteModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {deleteModalDescription}
          </OreText>
        </>
      </Modal>
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>
      <TTOrderSaveAndClose
        setShowSaveCloseModal={props.setShowSaveCloseModal}
        showSaveCloseModal={props.showSaveCloseModal}
      />
    </>
  )
}
