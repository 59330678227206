import { navigationTranslation } from 'src/domain/translations'
import { SidebarMenuItemModel } from '../sidebar/Sidebar'
import {
  SvgAdherence,
  SvgCommunications,
  SvgImputations,
  SvgNavBarCollectives,
  SvgNavBarDashboard,
  SvgNavBarMembers,
  SvgNavBarProducts,
  SvgPayroll,
  SvgValidations,
} from '@runroom/oreneta'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { ProductTypeSlugs, Steps } from 'src/Flex/Orders/ui/order-validation'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { edenredProducts } from 'src/domain/enum'
import { ProductTypeSlugsImputation } from 'src/Flex/Orders/ui/order-imputation'
import { viewTag } from 'src/domain/enum/permissionTags'
import { ProductModel } from 'src/Flex/Products/domain'
import {
  flexColectivesActiveLinks,
  flexCommunicationsActiveLinks,
  flexEmployeesActiveLinks,
  flexImputationFormationActiveLinks,
  flexImputationSaludExternoActiveLinks,
  flexValidateTGDActiveLinks,
  flexValidateTRActiveLinks,
  flexValidateTTActiveLinks,
} from './activeLinks'

const getPillText = (activeProducts: ProductModel[], productCode: number): string => {
  const prod = activeProducts.find(x => x.productType === productCode)
  return prod && prod.active ? '+' : ''
}

export const getFlexMenu = (activeProducts: ProductModel[]): SidebarMenuItemModel[] => {
  const flexMenu = [
    {
      title: navigationTranslation.sidebar.items.home,
      icon: <SvgNavBarDashboard />,
      link: flexNavigationRoutes.flex,
    },
    {
      title: navigationTranslation.sidebar.sections.flex,
      protectedBy: viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
      items: [
        {
          title: navigationTranslation.sidebar.items.flexProductsAdherence,
          icon: <SvgAdherence />,
          link: flexNavigationRoutes.flexProductsAdherence,
        },
        {
          title: navigationTranslation.sidebar.items.flexOrders.title,
          icon: <SvgValidations />,
          childs: [
            {
              title: flexProductsTranslations.ids[edenredProducts.ticketRestaurant],
              link: flexNavigationRoutes.flexOrdersValidate
                .replace(':productType', ProductTypeSlugs.TicketRestaurant)
                .replace(':step', Steps.StepOne),
              pillText: getPillText(activeProducts, edenredProducts.ticketRestaurant),
              activeLinks: [...flexValidateTRActiveLinks],
            },
            {
              title: flexProductsTranslations.ids[edenredProducts.ticketTransporte],
              link: flexNavigationRoutes.flexOrdersValidate
                .replace(':productType', ProductTypeSlugs.Movilidad)
                .replace(':step', Steps.StepOne),
              pillText: getPillText(activeProducts, edenredProducts.ticketTransporte),
              activeLinks: [...flexValidateTTActiveLinks],
            },
            {
              title: flexProductsTranslations.ids[edenredProducts.ticketGuarderia],
              link: flexNavigationRoutes.flexOrdersValidate
                .replace(':productType', ProductTypeSlugs.Guarderia)
                .replace(':step', Steps.StepOne),
              pillText: getPillText(activeProducts, edenredProducts.ticketGuarderia),
              activeLinks: [...flexValidateTGDActiveLinks],
            },
          ],
        },
        {
          title: navigationTranslation.sidebar.items.flexImputations,
          icon: <SvgImputations />,
          childs: [
            {
              title: flexProductsTranslations.ids[edenredProducts.formacion],
              link: flexNavigationRoutes.flexProductsImputation
                .replace(':productType', ProductTypeSlugsImputation.Formacion)
                .replace(':step', Steps.StepOne),
              activeLinks: [...flexImputationFormationActiveLinks],
            },
            {
              title: flexProductsTranslations.ids[edenredProducts.saludExterno],
              link: flexNavigationRoutes.flexProductsImputation
                .replace(':productType', ProductTypeSlugsImputation.SaludExterno)
                .replace(':step', Steps.StepOne),
              activeLinks: [...flexImputationSaludExternoActiveLinks],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: navigationTranslation.sidebar.items.flexProducts,
          icon: <SvgNavBarProducts />,
          link: flexNavigationRoutes.flexProducts,
        },
      ],
      protectedBy: viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
    },
    {
      items: [
        {
          title: navigationTranslation.sidebar.items.flexEmployees,
          icon: <SvgNavBarMembers />,
          link: flexNavigationRoutes.flexEmployees,
          activeLinks: [...flexEmployeesActiveLinks],
        },
      ],
      protectedBy: viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
    },
    {
      items: [
        {
          title: navigationTranslation.sidebar.items.flexCollectives,
          icon: <SvgNavBarCollectives />,
          link: flexNavigationRoutes.flexCollectives,
          activeLinks: [...flexColectivesActiveLinks],
        },
      ],
      protectedBy: viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
    },
    {
      items: [
        {
          title: navigationTranslation.sidebar.items.flexCommunicationsTemplates,
          icon: <SvgCommunications />,
          link: flexNavigationRoutes.flexCommunicationsTemplates,
          activeLinks: [...flexCommunicationsActiveLinks],
        },
      ],
      protectedBy: viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
    },
    {
      items: [
        {
          title: navigationTranslation.sidebar.items.flexPayrolls,
          icon: <SvgPayroll />,
          link: flexNavigationRoutes.flexPayrolls,
        },
      ],
    },
  ]
  return flexMenu
}
