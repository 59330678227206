import {
  indexCollectiveList,
  getCollectiveConfiguration,
} from 'src/config/constants/endpoints'
import {
  CollectiveModel,
  CollectiveConfigurationModel,
  CollectiveRepositoryContract,
  SaveCollectiveDTO,
  CreateCollectiveDTO,
  DeleteCollectiveDTO,
} from 'src/Flex/Collectives/domain'
import { createQueryString } from 'src/core/helpers'
import { HttpModel, PaginationModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { edenredProducts } from 'src/domain/enum'

type ApiCollectiveList = HttpModel<PaginationModel<{
  flexibleCompensationPlanPercentage: number
  hasActiveHires: boolean
  id: string
  name: string
  numberOfEmployees: number
  products: number[]
}> | null>

const convertToDomain = (
  response: ApiCollectiveList
): PaginationModel<CollectiveModel> => ({
  rows:
    response.data?.rows?.map<CollectiveModel>(collective => ({
      flexibleCompensationPlanPercentage: collective.flexibleCompensationPlanPercentage,
      hasActiveHires: collective.hasActiveHires,
      id: collective.id,
      name: collective.name,
      numberOfEmployees: collective.numberOfEmployees,
      products: collective.products.filter(product =>
        Object.values(edenredProducts).includes(product)
      ),
    })) ?? [],
  totalRows: response.data?.totalRows ?? 0,
})

export class CollectiveRepository implements CollectiveRepositoryContract {
  async GetCollectives(
    page?: number,
    numberOfRecordsPerPage?: number
  ): Promise<HttpModel<PaginationModel<CollectiveModel>>> {
    const response: ApiCollectiveList = await axiosFetch(
      indexCollectiveList.concat(
        createQueryString({ numberOfRecordsPerPage, pageNumber: page })
      ),
      HttpMethod.GET,
      {}
    )

    return {
      data: convertToDomain(response),
      meta: response.meta,
    }
  }

  async GetCollectiveConfiguration(
    id: string
  ): Promise<HttpModel<CollectiveConfigurationModel>> {
    return await axiosFetch(
      getCollectiveConfiguration.concat(createQueryString({ id })),
      HttpMethod.GET,
      {}
    )
  }

  async CreateCollective({
    name,
    flexibleCompensationPlanPercentage,
  }: CollectiveConfigurationModel): Promise<CreateCollectiveDTO> {
    return await axiosFetch(indexCollectiveList, HttpMethod.POST, {
      name,
      flexibleCompensationPlanPercentage,
    })
  }

  async DeleteCollective(
    id: string,
    newCollectiveId: string
  ): Promise<DeleteCollectiveDTO> {
    return await axiosFetch(
      indexCollectiveList.concat(
        createQueryString({
          id,
          newCollectiveId,
        })
      ),
      HttpMethod.DELETE,
      {}
    )
  }

  async SaveCollective({
    id,
    name,
    flexibleCompensationPlanPercentage,
  }: CollectiveConfigurationModel): Promise<SaveCollectiveDTO> {
    return await axiosFetch(indexCollectiveList, HttpMethod.PUT, {
      name,
      flexibleCompensationPlanPercentage,
      id,
    })
  }
}
