import {
  OreHeading,
  OreTable,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export const TGDOrderSummaryExcelTable = () => {
  const { t } = useTranslation()
  const { tgdOrderCheckout } = useTGDOrder()
  const navigate = useNavigate()

  if (!tgdOrderCheckout || !tgdOrderCheckout.data) {
    navigate(navigationRoutes.tgdOrderExcel)
    return <></>
  }
  return (
    <OreTableContainer>
      <div className="bg-white px-1">
        <div className="py-105">
          <OreHeading size="headline-md">
            {t(TGDOrderTranslation.summaryAssignment.table.title)}
          </OreHeading>
        </div>
      </div>
      <OreTable>
        <OreTableHead>
          <OreTableRow hover={false}>
            <OreTableCell as="th">
              {t(TGDOrderTranslation.excel.table.header.concept)}
            </OreTableCell>
            <OreTableCell as="th" align="right">
              {t(TGDOrderTranslation.excel.table.header.units)}
            </OreTableCell>
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {tgdOrderCheckout.data.abstractCardOrder.resumeData.map((data, index) => {
            return (
              <OreTableRow valign="middle" hover={false} key={index}>
                <OreTableCell align="left" as="td" size="auto">
                  <OreText size="text-sm" tone="neutral-500">
                    {data.name}
                  </OreText>
                </OreTableCell>
                <OreTableCell align="left" as="td" size="auto">
                  <OreText size="text-sm" tone="neutral-500" align="right">
                    {data.value}
                  </OreText>
                </OreTableCell>
              </OreTableRow>
            )
          })}
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
}
