import { CSSProperties, PropsWithChildren } from 'react'

type Spaces =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | '2-x-small'
  | 'xsmall'
  | '2-x-large'
  | '3-x-large'
  | '4-x-large'
  | '5-x-large'
  | '6-x-large'

type IProps = PropsWithChildren & {
  space?: Spaces
  rowSpace?: Spaces
  columnSpace?: Spaces
  templateRows?: string
  templateColumns?: string
}

const convertToVariable = (space: Spaces): string => `var(--spacing-${space})`

export const OreGrid = ({
  space,
  rowSpace,
  columnSpace,
  templateRows,
  templateColumns,
  children,
}: IProps): JSX.Element => {
  const style: CSSProperties = {
    display: 'grid',
  }

  if (space) style.gap = convertToVariable(space)
  if (rowSpace) style.rowGap = convertToVariable(rowSpace)
  if (columnSpace) style.columnGap = convertToVariable(columnSpace)
  if (templateRows) style.gridTemplateRows = templateRows
  if (templateColumns) style.gridTemplateColumns = templateColumns

  return <div style={style}>{children}</div>
}
