import { OreCheckbox, OreText, OreTextArea } from '@runroom/oreneta'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type { DeleteAccountModel } from '../../../../../domain/models/myAccount/DeleteAccountModel'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { ComboBox, MessageBar } from '../../../Edenred'
import { accountService } from 'src/core/services'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { forms } from 'src/domain/translations'
import { NotificationSeverity } from 'src/domain/enum'

interface Props {
  form: UseFormReturn<DeleteAccountModel, any>
  reasonOptions: ComboBoxOptionModel[]
  errorMessage: string
}

const CheckboxLabel = (): JSX.Element => {
  const { t } = useTranslation()
  const { addNotification } = useNotification()

  const downloadTermsAndConditions = (): void => {
    accountService()
      .downloadCancellationTerms()
      .then(response => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${response.data.file}`
        link.download = 'CancellationsTerms.pdf'
        link.click()
        link.remove()
      })
      .catch(() => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      })
  }

  return (
    <div>
      <OreText size="text-md">
        {t(accountSettingsTranslation.deleteAccount.modal.acceptConditions1)}
        <span className="terms-conditions-link" onClick={downloadTermsAndConditions}>
          {t(accountSettingsTranslation.deleteAccount.modal.acceptConditions2)}
        </span>
        {t(accountSettingsTranslation.deleteAccount.modal.acceptConditions3)}
      </OreText>
    </div>
  )
}

export const DeleteAccountModalForm = ({
  form,
  reasonOptions,
  errorMessage,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <section className="form-molecule">
      <FormProvider {...form}>
        <form>
          <div className="form-atom">
            <ComboBox
              id="reasonId"
              name="reasonId"
              label={t(accountSettingsTranslation.deleteAccount.modal.reason)}
              required={true}
              options={reasonOptions}
              register={form.register}
              errors={form.formState.errors}
            />
          </div>
          <div className="form-atom">
            <OreTextArea
              {...form.register('description')}
              name="description"
              id="description"
              label={t(accountSettingsTranslation.deleteAccount.modal.addMore)}
              placeholder={t(
                accountSettingsTranslation.deleteAccount.modal.addMorePlaceholder
              )}
              rows={3}
              maxLength={400}
            />
          </div>

          <MessageBar color="error" message={errorMessage} />
          <div className="form-atom float-left mt-1 inline-flex">
            <OreCheckbox
              {...form.register('acceptConditions')}
              label={<CheckboxLabel />}
              name="acceptConditions"
              hasError={!!form.formState.errors?.acceptConditions}
              errorMessage={form.formState.errors?.acceptConditions?.message}
            />
          </div>
        </form>
      </FormProvider>
    </section>
  )
}
