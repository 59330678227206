import { OreHeading } from '@runroom/oreneta'
import {
  OrderAndDuplicateStepsData,
  OrderExcelStepsData,
  RechargeAndUnloadStepsData,
} from '../components/Edenred/stepper/TRStepsData'
import Stepper from '../components/Edenred/stepper/Stepper'
import { useCardOrder } from '../context/cardOrder/CardOrderProvider'
import { SimpleFormDivider } from '../components/Edenred/simpleFormDivider'
import { stepper } from '../../domain/enum'

interface SimpleFormProps {
  title: string
  organisms: React.ReactNode[]
}

export const SimpleForm = ({ title, organisms }: SimpleFormProps) => {
  const { getAllRechargeOrders, getAllUnloadOrders } = useCardOrder()

  return (
    <div className="column-wrapper">
      <div>
        <header className="width-max">
          <OreHeading as="h1" size="title-sm">
            {title}
          </OreHeading>
        </header>

        <Stepper
          currentStep={stepper.firstStep}
          stepsFor={OrderAndDuplicateStepsData}
          paddingBottom={40}
        />
        <Stepper
          currentStep={
            [...getAllRechargeOrders(), ...getAllUnloadOrders()].length === 0
              ? stepper.firstStep
              : stepper.secondStep
          }
          stepsFor={RechargeAndUnloadStepsData}
          paddingBottom={40}
        />
        <Stepper stepsFor={OrderExcelStepsData} paddingBottom={40} />

        <SimpleFormDivider />

        <div>
          {organisms.map((organism, index) => {
            return (
              <div key={index} className="width-max">
                {organism}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
