import { Repositories, orderType } from 'src/domain/enum'
import { TTOrderRepository } from 'src/infrastructure/repository/TTOrder/TTOrderRepository'
import { RepositoryFactory } from '../factories'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'

export const TTOrderService = (): TTOrderRepository => {
  return RepositoryFactory(Repositories.ttOrder)
}

export const isUnloadTTOrder = (orders: TTOrderModel[]): boolean => {
  if (orders.length === 0) return false
  return orderType.Unload === orders[0].orderTypeId
}
