import { useTranslation } from 'react-i18next'
import { SimpleForm } from 'src/presentation/Templates'
import { SpendingRulesForm } from './organism'
import { spendingRuleFormTranslation } from 'src/domain/translations'

export const SpendingRulesFormPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SimpleForm
      title={t(spendingRuleFormTranslation.page.title)}
      organisms={[<SpendingRulesForm />]}
    />
  )
}
