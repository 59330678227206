import { useTTRechargeConfigurationEmployees } from '../hooks/useTTRechargeConfigurationEmployees'
import { TTRechargeConfigurationEmployeesList } from '../molecules/TTRechargeConfigurationEmployeesList'
import { TTRechargeConfigurationEmployeesFooterButtons } from '../molecules/TTRechargeConfigurationEmployeesFooterButtons'
import { TTRechargeConfigurationToolbar } from '../molecules/TTRechargeConfigurationToolbar'

export const TTRechargeConfigurationEmployeeContainer = () => {
  const props = useTTRechargeConfigurationEmployees()

  return (
    <div>
      <TTRechargeConfigurationEmployeesList employeesTTProps={props} />
      <TTRechargeConfigurationEmployeesFooterButtons
        tableIsEmpty={props.employeesTT && props.employeesTT.length <= 0}
        addConfigToOrder={props.addConfigToOrder}
        form={props.form}
        cancelPopupButtons={props.cancelPopupButtons}
        onClickCancel={props.onClickCancel}
        setShowCancelModal={props.setShowCancelModal}
        showCancelModal={props.showCancelModal}
        errorMessage={props.errorMessage}
      />
      {props.selectedEmployees.length > 1 && (
        <TTRechargeConfigurationToolbar
          selectedEmployees={props.selectedEmployees.length}
          onAssignMultipleValues={props.onAssignMultipleValues}
        />
      )}
    </div>
  )
}
