export enum Steps {
  Upload = 1,
  Validation = 2,
  Confirmation = 3,
}

const stepSlugs = {
  [Steps.Upload]: 'CargaExcel',
  [Steps.Validation]: 'ResumenExcel',
  [Steps.Confirmation]: 'ConfirmacionExcel',
}

export const stepSlug = (step: Steps): string => stepSlugs[step]
