import { OreHeading, OreStack, OreStepper } from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import {
  FlexOrderImputationList,
  FlexOrderImputationSuccess,
  OrderImputationStepTwo,
  OrdersImputationProvider,
  ProductTypeSlugsImputation,
  orderImputationsTranslations,
} from 'src/Flex/Orders/ui/order-imputation'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate, useParams } from 'react-router-dom'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { Steps, orderValidationsTranslations } from '../order-validation'
import { ComponentProps, ElementType, useEffect, useState } from 'react'
import { useUserData } from 'src/Flex/User/ui/context'
import { FlexOrderImputationInactiveProduct } from './molecules/FlexOrderImputationInactiveProduct'

const getProductType = (
  productType: ProductTypeSlugsImputation | undefined
): edenredProducts | undefined => {
  switch (productType) {
    case ProductTypeSlugsImputation.Formacion:
      return edenredProducts.formacion
    case ProductTypeSlugsImputation.SaludExterno:
      return edenredProducts.saludExterno
  }
}
const getStep = (step: Steps | undefined): ComponentProps<ElementType> | undefined => {
  switch (step) {
    case Steps.StepOne:
      return FlexOrderImputationList
    case Steps.StepTwo:
      return OrderImputationStepTwo
    case Steps.StepThree:
      return FlexOrderImputationSuccess
  }
}

export const FlexOrderImputationPage = (): JSX.Element => {
  const { t } = useTranslation()
  const { step, productType: rawProductType } = useParams<{
    step: Steps
    productType: ProductTypeSlugsImputation
  }>()
  const Step = getStep(step)
  const productType = getProductType(rawProductType)
  const navigate = useNavigate()
  const [stepError, setStepError] = useState<number>(0)
  const [productIsActive, setProductIsActive] = useState<boolean>(true)
  const { getAllProducts } = useUserData()

  const getProductIsActive = (productType: number): boolean => {
    const prod = getAllProducts.find(x => x.productType === productType)
    if (prod) {
      return !!prod.active
    }
    return false
  }

  if (!Step || !productType) {
    navigate(
      flexNavigationRoutes.flexProductsImputation
        .replace(':productType', 'Formacion')
        .replace(':step', Steps.StepOne)
    )
    return <></>
  }

  const stepNumber =
    Step === FlexOrderImputationList ? 1 : Step === OrderImputationStepTwo ? 2 : 3

  useEffect(() => {
    if (Step === FlexOrderImputationList) {
      setStepError(0)
    }
  }, [Step])

  useEffect(() => {
    setProductIsActive(getProductIsActive(productType))
  }, [productType])

  return (
    <OrdersImputationProvider productType={productType}>
      <PageWrapper>
        <OreStack space="large" placeContent="stretch">
          <OreHeading as="h1" size="title-md">
            {t(orderImputationsTranslations.title, {
              product: t(flexProductsTranslations.ids[productType]),
            })}
          </OreHeading>
          {productIsActive ? (
            <>
              <OreStepper
                activeStep={stepNumber}
                errorStep={stepError}
                steps={[
                  t(orderValidationsTranslations.step1.title),
                  t(orderValidationsTranslations.step2.title),
                  t(orderValidationsTranslations.step3.title),
                ]}
              />
              <Step />
            </>
          ) : (
            <FlexOrderImputationInactiveProduct productType={productType} />
          )}
        </OreStack>
      </PageWrapper>
    </OrdersImputationProvider>
  )
}
