import { orderType } from 'src/domain/enum'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderImportExcelContainer } from './organism/TTOrderImportExcelContainer'
import { useEffect } from 'react'

export const TTOrderImportExcelPage = (): JSX.Element => {
  const { setOrderContext } = useTTOrder()

  useEffect(() => setOrderContext(orderType.Card), [])

  return <TTOrderImportExcelContainer />
}
