import type {
  EmployeeCardEnableDisable,
  EmployeeSonUpdated,
  EmployeeUsageProfileUpdated,
  EmployeesWithLastCardResponse,
  EmployeesWithLastCardUpdated,
  HttpModel,
  SingleEmployeeNurseryRequestBody,
} from '../../../domain/models'

import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../axios'
import type {
  SingleEmployeeRequestBody,
  EmployeesRequestBody,
} from '../../../domain/bodyRequest/EmployeesRequestBody'
import {
  deleteEmployeeByDocument,
  deleteEmployees,
  downloadEmployeeExcel,
  downloadEmployeeTTExcel,
  enableDisableEmployeeCard,
  getEmployeeProducts,
  getEmployeesNursery,
  getEmployeesTGDExcel,
  isClientEmployeeFlex,
  singleGetEmployeeWithLastCard,
  updateEmployeeSon,
  updateEmployeeWithLastCardUsageProfile,
  updateGetEmployeeWithLastCard,
} from '../../../config/constants/endpoints'
import { createQueryString } from '../../../core/helpers/queryString'
import {
  DeleteClientEmployee,
  DeleteClientEmployeeResponse,
} from 'src/domain/models/cardOrder/DeleteClientEmployees'
import {
  EmployeesNurseryRequest,
  EmployeesNurseryResponse,
} from 'src/domain/models/employeesNursery'
import { edenredProducts } from 'src/domain/enum'

export class EmployeesRepository {

  async EmployeeNurseryExists(document: string): Promise<boolean> {
    const query: SingleEmployeeNurseryRequestBody = {
      productCode: edenredProducts.ticketGuarderia,
      userDocument: document
    }
    const response: HttpModel<EmployeesNurseryResponse> = await this.GetEmployeesNursery(query)
    return response.data.totalRows > 0
  }

  async GetEmployees(
    filters: EmployeesRequestBody
  ): Promise<HttpModel<EmployeesWithLastCardResponse>> {
    const { url, method, body }: HttpRequest = {
      url: singleGetEmployeeWithLastCard,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async GetSingleEmployee(
    filters: SingleEmployeeRequestBody
  ): Promise<HttpModel<EmployeesWithLastCardResponse>> {
    const { url, method, body }: HttpRequest = {
      url: singleGetEmployeeWithLastCard,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async GetEmployeesNursery(
    filters: EmployeesNurseryRequest
  ): Promise<HttpModel<EmployeesNurseryResponse>> {
    const { url, method, body }: HttpRequest = {
      url: getEmployeesNursery,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async GetSingleEmployeeNursery(
    filters: SingleEmployeeNurseryRequestBody
  ): Promise<HttpModel<EmployeesNurseryResponse>> {
    const { url, method, body }: HttpRequest = {
      url: getEmployeesNursery,
      method: HttpMethod.GET,
    }
    let fullUrl = url
    if (filters) {
      fullUrl = url.concat(createQueryString(filters))
    }
    const response = await axiosFetch(fullUrl, method, body)
    return response
  }

  async GetEmployeeProducts(document: string): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: getEmployeeProducts + '?userDocument=' + document,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async updateEmployee(employee: EmployeesWithLastCardUpdated): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: updateGetEmployeeWithLastCard,
      method: HttpMethod.POST,
      body: employee,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async updateEmployeeUsageProfile(
    usageProfileUpdated: EmployeeUsageProfileUpdated
  ): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: updateEmployeeWithLastCardUsageProfile,
      method: HttpMethod.POST,
      body: usageProfileUpdated,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async updateEmployeeSon(employee: EmployeeSonUpdated): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: updateEmployeeSon,
      method: HttpMethod.POST,
      body: employee,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async isClientEmployeeFlex(document: string): Promise<any> {
    const { url, method, body }: HttpRequest = {
      url: isClientEmployeeFlex,
      method: HttpMethod.POST,
      body: { document },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async enableDisableEmployee(
    employee: EmployeeCardEnableDisable
  ): Promise<HttpModel<boolean>> {
    const { url, method, body }: HttpRequest = {
      url: enableDisableEmployeeCard,
      method: HttpMethod.POST,
      body: employee,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async deleteEmployees(
    employeeIds: DeleteClientEmployee[]
  ): Promise<HttpModel<DeleteClientEmployeeResponse>> {
    const { url, method, body }: HttpRequest = {
      url: deleteEmployees,
      method: HttpMethod.POST,
      body: {
        usersIds: employeeIds,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async deleteEmployeeByDocument(
    document: string
  ): Promise<HttpModel<DeleteClientEmployeeResponse>> {
    const { url, method, body }: HttpRequest = {
      url: deleteEmployeeByDocument,
      method: HttpMethod.POST,
      body: {
        userDocument: document,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadExcel(productCode: number): Promise<HttpModel<string>> {
    let downloadURL = downloadEmployeeExcel
    switch (productCode) {
      case edenredProducts.ticketRestaurant:
        downloadURL = downloadEmployeeExcel
        break
      case edenredProducts.ticketTransporte:
        downloadURL = downloadEmployeeTTExcel
        break
      case edenredProducts.ticketGuarderia:
        downloadURL = getEmployeesTGDExcel
        break
    }
    const { url, method, body }: HttpRequest = {
      url: downloadURL + '?productCode=' + productCode,
      method: HttpMethod.GET,
      body: {},
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
