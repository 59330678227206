import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { PropsWithChildren } from 'react'
import {
  IUseConfigureTemplateController,
  IUseConfigureTemplateProps,
  useConfigureTemplateController,
} from 'src/Flex/CommunicationsTemplates/ui/templates-detail'

type Context = IUseConfigureTemplateController

type Props = PropsWithChildren & IUseConfigureTemplateProps

const [useTemplatesEdit, StateContextProvider] = createGenericContext<Context>()

const CommunicationsTemplatesEditProvider = ({
  children,
  ...rest
}: Props): JSX.Element => {
  const hook = useConfigureTemplateController(rest)

  return <StateContextProvider value={hook}>{children}</StateContextProvider>
}

export { CommunicationsTemplatesEditProvider, useTemplatesEdit }
