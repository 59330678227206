import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
  OreStack,
} from '@runroom/oreneta'
import { forwardRef, useState, useImperativeHandle } from 'react'
import { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { forms } from 'src/domain/translations'
import { useStatusToggleProduct } from 'src/Flex/Products/ui/product-toggle-status'
import { edenredProducts } from 'src/domain/enum'

export type ToggleModalActions = {
  evaluate: () => void
}

interface IProps {
  productType: edenredProducts
  current: boolean
  toggleButton: () => void
  ref: React.Ref<ConfigureModalActions>
  hasModifiedLimits: boolean | undefined
}

export const StatusToggleModal = forwardRef<ToggleModalActions, IProps>(
  ({ productType, current, toggleButton, hasModifiedLimits }, ref): JSX.Element => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { save } = useStatusToggleProduct()

    const isCollective = hasModifiedLimits !== undefined
    const isOpenISalud =
      productType === edenredProducts.salud && isCollective && !hasModifiedLimits
    const isOpenExternal =
      productType === edenredProducts.saludExterno && isCollective && !hasModifiedLimits

    useImperativeHandle(ref, () => ({
      evaluate: async (): Promise<void> => {
        const shouldOpen = productType !== edenredProducts.salud
        if ((current && shouldOpen) || (current && isCollective)) {
          setOpen(true)
        } else {
          if (isOpenISalud || isOpenExternal) {
            setOpen(true)
          } else {
            const saved = await save(productType)
            saved && toggleButton()
          }
        }
      },
    }))

    const close = (): void => {
      setOpen(false)
    }

    const saveAndClose = async (): Promise<void> => {
      const result = await save(productType)

      if (result) {
        toggleButton()
      }

      setOpen(false)
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="small" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {(isOpenISalud && !current) || (isOpenExternal && !current)
                ? t(flexProductsTranslations.configure.health.toggle.open.title)
                : t(flexProductsTranslations.toggle.form.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {(isOpenISalud && !current) || (isOpenExternal && !current)
                  ? t(flexProductsTranslations.configure.health.toggle.open.paragraph_0)
                  : productType === edenredProducts.salud
                  ? t(flexProductsTranslations.configure.health.toggle.close.paragraph_0)
                  : t(flexProductsTranslations.toggle.form.paragraph0)}
              </OreText>
              <OreText align="left">
                {(isOpenISalud && !current) || (isOpenExternal && !current)
                  ? t(flexProductsTranslations.configure.health.toggle.open.paragraph_1)
                  : productType === edenredProducts.salud
                  ? t(flexProductsTranslations.configure.health.toggle.close.paragraph_1)
                  : t(flexProductsTranslations.toggle.form.paragraph1)}
              </OreText>
              <OreText align="left">
                {productType !== edenredProducts.salud
                  ? t(flexProductsTranslations.toggle.form.paragraph2)
                  : null}
              </OreText>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={close} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="primary">
              {t(flexProductsTranslations.configure.common.submit)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
