import type { Dispatch } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEmployeeProfileController } from './useEmployeeProfileController'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { MetaStatusCodes, NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { EmployeesService } from 'src/core/services/employeesService'
import { forms } from 'src/domain/translations'
import { EmployeesWithLastCard } from 'src/domain/models'

interface ControllerState {
  checked: boolean
  setChecked: Dispatch<React.SetStateAction<boolean>>
  handleChange: () => void
}

export const useEmployeeProfileSwtichController = (
  employeeData: EmployeesWithLastCard
): ControllerState => {
  const [checked, setChecked] = useState(false)
  const { addNotification } = useNotification()
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  const { enabledAccount, setEnabledAccount } = useEmployeeProfileController(employeeData)

  const fetchEnableDisable = async (): Promise<void> => {
    const query = {
      productCode: edenredProducts.ticketTransporte,
      document: employeeData?.document || '',
      blockCard: !enabledAccount,
    }

    startLoading()

    const response = await EmployeesService().enableDisableEmployee(query)

    if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
      setEnabledAccount(!enabledAccount) // updates the current isCardBlocked value if the user changes again
      addNotification(t(forms.success.message), NotificationSeverity.success)
    } else {
      setChecked(!enabledAccount) // Restore switch to the original position when request fail
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }

    stopLoading()
  }

  const handleChange = (): void => {
    setChecked(!checked)
    fetchEnableDisable()
  }

  useEffect(() => {
    setChecked(!enabledAccount)
  }, [enabledAccount])

  return {
    checked,
    setChecked,
    handleChange,
  }
}
