import { TFunction } from 'react-i18next'
import { mixed } from 'yup'
import { MixedSchema } from 'yup/lib/mixed'
import {
  checkFileType,
  dropZoneTranslations,
  prettySize,
  readableFileTypes,
} from 'src/Flex/Shared/ui/DropZone'

export const fileSchema = (
  t: TFunction<'translation', undefined>,
  validFileFormat: string | string[],
  maxFileSize: number
): MixedSchema<string | undefined> =>
  mixed()
    .test(
      'file-exists',
      t(dropZoneTranslations.validation.fileExists),
      value => value !== null && value.length > 0
    )
    .test(
      'file-format',
      t(dropZoneTranslations.validation.fileFormat, {
        fileTypes: readableFileTypes(validFileFormat).join(
          t(dropZoneTranslations.formatOr)
        ),
      }),
      value =>
        value !== null && value.length > 0
          ? checkFileType(value[0], validFileFormat)
          : true
    )
    .test(
      'file-size',
      t(dropZoneTranslations.validation.fileSize, {
        fileSize: prettySize(maxFileSize),
      }),
      value => (value !== null && value.length > 0 ? value[0].size < maxFileSize : true)
    )
