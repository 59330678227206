export type OrePillTonesTypes =
  | 'grey'
  | 'cobalt-600'
  | 'error-600'
  | 'success-600'
  | 'warning-600'

export interface IOrePillTones {
  success: OrePillTonesTypes
  error: OrePillTonesTypes
  cobalt: OrePillTonesTypes
  warning: OrePillTonesTypes
  gray: OrePillTonesTypes
}

export const OrePillTones: IOrePillTones = {
  success: 'success-600',
  warning: 'warning-600',
  error: 'error-600',
  cobalt: 'cobalt-600',
  gray: 'grey',
}
