import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { PageWrapper } from 'src/presentation/layout'
import {
  PendingTasksList,
  PendingTasksProvider,
  SendPendingMailsModal,
  SendPendingMailsModalActions,
} from 'src/Flex/TaskManager/ui/pending-tasks'
import { TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import {
  flexHomeVideTutorialTranslations,
  flexTaskManagerTranslations,
} from 'src/Flex/TaskManager/ui/translations'

import { HomeTemplate } from 'src/presentation/Templates/HomeTemplate'

import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const FlexPendingTasksPage = (): JSX.Element => {
  const { user } = useAuth()

  const { t } = useTranslation()
  const ref = useRef<SendPendingMailsModalActions>(null)

  const actioner = (id: number) => {
    if (id === TaskTypeEnum.pendingWelcomeCommunication && ref.current) {
      ref.current.open()
    }
  }

  return (
    <PageWrapper>
      <HomeTemplate
        homeHero
        homeFlex
        title={`${t(flexTaskManagerTranslations.title)} ${user?.name?.split(' ')[0]}! 👋`}
        subtitle={t(flexTaskManagerTranslations.subtitle)}
        organisms={[
          <PendingTasksProvider actioner={actioner}>
            <PendingTasksList />
            <SendPendingMailsModal ref={ref} />
          </PendingTasksProvider>,
        ]}
        titleSize="title-md"
      />
    </PageWrapper>
  )
}
