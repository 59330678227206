import { FieldPath, FieldValues, FormState, UseFormGetFieldState } from 'react-hook-form'

type ErrorsType = { hasError: boolean; errorMessage: string | undefined }

type HandleErrorsType<T extends FieldValues> = {
  errors: (name: FieldPath<T>) => ErrorsType
}

export const handleErrors = <T extends FieldValues>(
  formState: FormState<T>,
  getFieldState?: UseFormGetFieldState<T>
): HandleErrorsType<T> => ({
  errors: (name: FieldPath<T>): ErrorsType => {
    const error = getFieldState
      ? getFieldState(name, formState).error
      : formState.errors[name]

    return {
      hasError: !!error,
      errorMessage: error?.message as string | undefined,
    }
  },
})
