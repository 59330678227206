import { cardOrderTranslation } from 'src/domain/translations'
import { StepsForModel } from './Stepper'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export const TGDOrderStepsData: StepsForModel = {
  steps: [
    cardOrderTranslation.stepper.firstStep,
    cardOrderTranslation.stepper.secondStep,
    cardOrderTranslation.stepper.thirdStep,
    cardOrderTranslation.stepper.fourthStep,
  ],
  allowedPaths: [
    navigationRoutes.tgdOrderForm,
    navigationRoutes.tgdOrderSummary,
    navigationRoutes.tgdOrderCheckout,
    navigationRoutes.tgdOrderCompleteError,
    navigationRoutes.tgdOrderCompleteSuccess,
    navigationRoutes.tgdOrderSelectSingleEmployee,
    navigationRoutes.tgdOrderSelectMultipleEmployee,
    navigationRoutes.tgdOrderEmployeesConfiguration,
    navigationRoutes.tgdOrderFormEdit,
    navigationRoutes.tgdOrderChildForm,
    navigationRoutes.tgdOrderChildFormEdit,
    navigationRoutes.tgdOrderExcel,
    navigationRoutes.tgdOrderStoppersAndWarnings,
  ],
  stepsByPaths: [
    {
      step: cardOrderTranslation.stepper.firstStep,
      paths: [
        navigationRoutes.tgdOrderForm,
        navigationRoutes.tgdOrderFormEdit,
        navigationRoutes.tgdOrderSelectSingleEmployee,
        navigationRoutes.tgdOrderSelectMultipleEmployee,
        navigationRoutes.tgdOrderEmployeesConfiguration,
        navigationRoutes.tgdOrderChildForm,
        navigationRoutes.tgdOrderChildFormEdit,
        navigationRoutes.tgdOrderExcel,
        navigationRoutes.tgdOrderStoppersAndWarnings,
      ],
    },
    {
      step: cardOrderTranslation.stepper.secondStep,
      paths: [navigationRoutes.tgdOrderSummary],
    },
    {
      step: cardOrderTranslation.stepper.thirdStep,
      paths: [navigationRoutes.tgdOrderCheckout],
    },
    {
      step: cardOrderTranslation.stepper.fourthStep,
      paths: [
        navigationRoutes.tgdOrderCompleteError,
        navigationRoutes.tgdOrderCompleteSuccess,
      ],
    },
  ],
}

export const TGDBasicStepsData: StepsForModel = {
  steps: [
    cardOrderTranslation.stepper.firstStep,
    cardOrderTranslation.stepper.secondStep,
    cardOrderTranslation.stepper.thirdStep,
    cardOrderTranslation.stepper.fourthStep,
  ],
  allowedPaths: [
    navigationRoutes.tgdBasicOrderForm,
    navigationRoutes.tgdBasicOrderSummary,
    navigationRoutes.tgdBasicOrderCheckout,
    navigationRoutes.tgdBasicOrderCompleteError,
    navigationRoutes.tgdBasicOrderCompleteSuccess,
    navigationRoutes.tgdBasicOrderFormEdit,
  ],
  stepsByPaths: [
    {
      step: cardOrderTranslation.stepper.firstStep,
      paths: [navigationRoutes.tgdBasicOrderForm, navigationRoutes.tgdBasicOrderFormEdit],
    },
    {
      step: cardOrderTranslation.stepper.secondStep,
      paths: [navigationRoutes.tgdBasicOrderSummary],
    },
    {
      step: cardOrderTranslation.stepper.thirdStep,
      paths: [navigationRoutes.tgdBasicOrderCheckout],
    },
    {
      step: cardOrderTranslation.stepper.fourthStep,
      paths: [
        navigationRoutes.tgdBasicOrderCompleteSuccess,
        navigationRoutes.tgdBasicOrderCompleteError,
      ],
    },
  ],
}
