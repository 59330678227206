import { file2Base64 } from 'src/core/helpers'

export const getFileInBase64 = async (
  fileList: FileList | null
): Promise<string | undefined> => {
  if (fileList) {
    const file = fileList[0]

    return file2Base64(file)
      .then(base64 => {
        return base64.toString().replace(/^data:(.*,)?/, '')
      })
      .catch(() => {
        return undefined
      })
  }

  return undefined
}
