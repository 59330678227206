import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { spendingRuleService } from 'src/core/services'
import { getSRTypeFromTypeId } from 'src/core/services/dietSpendingService'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import _ from 'lodash'
import {
  MetaStatusCodes,
  NotificationSeverity,
  SpendingRulesTRType,
  SpendingRulesType,
  currency,
  edenredProducts,
} from 'src/domain/enum'
import { ITimeZones, IWeekDays, SpendingRuleModel } from 'src/domain/models'
import { forms, spendingRuleFormTranslation } from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useNotificationHandler } from 'src/presentation/hooks'
import { useSpendingRulesSchema } from 'src/presentation/validations'

interface ControlState {
  form: UseFormReturn<SpendingRuleModel, any>
  nameTextbox: TextBoxRowModel<SpendingRuleModel>[]
  additionalRestrictions: string
  handleAdditionalRestrictionsChange: (e: ChangeEvent<HTMLSelectElement>) => void
  amountTextboxes: TextBoxRowModel<SpendingRuleModel>[]
  transactionTextboxes: TextBoxRowModel<SpendingRuleModel>[]
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  setShowPopup: Dispatch<SetStateAction<boolean>>
  handleChangeFav: (e: ChangeEvent<HTMLInputElement>) => void
  validateStripesDontOverlap: (newSpendingRule: SpendingRuleModel) => Promise<void>
  setShowCancelPopup: Dispatch<SetStateAction<boolean>>
  cancelPopupShow: boolean
  cancelPopupTitle: string
  cancelPopupDescription: string
  cancelPopupButtons: PopupButtonModel[]
  errorMessage: string
  handleTRTypeOptionChange: (e: ChangeEvent<HTMLInputElement>) => void
  srType: string
}

export const useSpendingRulesForm = (
  spendingRuleToEdit?: SpendingRuleModel
): ControlState => {
  const [nameTextbox, setNameTextbox] = useState<TextBoxRowModel<SpendingRuleModel>[]>([])
  const [additionalRestrictions, setAdditionalRestrictions] = useState<string>('0')
  const [transactionTextboxes, setTransactionTextboxes] = useState<
    TextBoxRowModel<SpendingRuleModel>[]
  >([])
  const [amountTextboxes, setAmountTextboxes] = useState<
    TextBoxRowModel<SpendingRuleModel>[]
  >([])
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const [srType, setSrType] = useState<string>(
    spendingRuleToEdit // Select default SRType if edit mode
      ? getSRTypeFromTypeId(spendingRuleToEdit.profileTypeId)
      : SpendingRulesTRType.TRCard
  )
  const { spendingRulesSchema } = useSpendingRulesSchema(+additionalRestrictions, srType)
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { notificationHandler } = useNotificationHandler()
  const { addNotification } = useNotification()
  const navigate = useNavigate()

  const defaultValues = {
    productCode: edenredProducts.ticketRestaurant,
    profileTypeId: SpendingRulesType.noRestriction,
    timeZones: [
      {
        weekDays: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        },
        timeZoneFrom: '00:00',
        timeZoneTo: '23:59',
      },
    ],
  }

  const defaultValuesToEdit = {
    // Setting nullable values
    ...spendingRuleToEdit,
    amount: {
      dayAmount:
        spendingRuleToEdit?.amount.dayAmount === 0
          ? ''
          : spendingRuleToEdit?.amount.dayAmount,
      weekAmount:
        spendingRuleToEdit?.amount.weekAmount === 0
          ? ''
          : spendingRuleToEdit?.amount.weekAmount,
      monthAmount:
        spendingRuleToEdit?.amount.monthAmount === 0
          ? ''
          : spendingRuleToEdit?.amount.monthAmount,
      yearAmount:
        spendingRuleToEdit?.amount.yearAmount === 0
          ? ''
          : spendingRuleToEdit?.amount.yearAmount,
    },
  }

  const form = useForm<SpendingRuleModel>({
    resolver: yupResolver(spendingRulesSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: spendingRuleToEdit ? defaultValuesToEdit : defaultValues,
  })

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const {
    show: cancelPopupShow,
    title: cancelPopupTitle,
    description: cancelPopupDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelPopup,
    setDescription: setDescriptionCancelPopup,
    setTitle: setTitleCancelPopup,
    setButtons: setButtonsCancelPopup,
  } = useModalController()

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => handleCancelFavPopup(),
        size: 'large',
      },
      {
        title: t(spendingRuleFormTranslation.form.favoriteModal.confirmationButtonLabel),
        category: 'primary',
        onClick: () => handleConfirmFavPopup(),
        size: 'large',
      },
    ]
  }

  const SetCancelPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.backToEdit),
        category: 'secondary',
        onClick: () => setShowCancelPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.leave),
        category: 'primary',
        onClick: () => handleConfirmCancelPopup(),
        size: 'large',
      },
    ]
  }

  const handleCancelFavPopup = (): void => {
    form.setValue('isDefault', false)
    setShowPopup(false)
  }

  const handleConfirmFavPopup = (): void => {
    setShowPopup(false)
  }

  const handleConfirmCancelPopup = (): void => {
    setShowPopup(false)
    form.reset()
    navigate(navigationRoutes.spendingRules)
  }

  const handleTRTypeOptionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSrType(e.target.value)
    form.reset()
  }

  const InitNameTextbox = (): void => {
    setNameTextbox([
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'profileName',
            label: t(spendingRuleFormTranslation.form.name),
            name: 'profileName',
            placeHolder: t(spendingRuleFormTranslation.form.namePlaceholder),
            required: true,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
    ])
  }

  const InitByTransactionTextboxes = (): void => {
    setTransactionTextboxes([
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'dayAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByTransactions
                .transactionsPerDay
            ),
            name: 'amount.dayAmount',
            placeHolder:
              t(
                spendingRuleFormTranslation.form.restrictionByTransactions
                  .placeholderPrefix
              ) + ' 2',
            required: false,
            type: 'number',
            autoComplete: 'off',
          },
          {
            id: 'weekAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByTransactions
                .transactionsPerWeek
            ),
            name: 'amount.weekAmount',
            placeHolder:
              t(
                spendingRuleFormTranslation.form.restrictionByTransactions
                  .placeholderPrefix
              ) + ' 10',
            required: false,
            type: 'number',
            autoComplete: 'off',
          },
          {
            id: 'monthAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByTransactions
                .transactionsPerMonth
            ),
            name: 'amount.monthAmount',
            placeHolder:
              t(
                spendingRuleFormTranslation.form.restrictionByTransactions
                  .placeholderPrefix
              ) + ' 20',
            required: false,
            type: 'number',
            autoComplete: 'off',
          },
          {
            id: 'yearAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByTransactions
                .transactionsPerYear
            ),
            name: 'amount.yearAmount',
            placeHolder:
              t(
                spendingRuleFormTranslation.form.restrictionByTransactions
                  .placeholderPrefix
              ) + ' 200',
            required: false,
            type: 'number',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'transactionAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByTransactions.maxPerTransaction
            ),
            name: 'timeZones.0.transactionAmount',
            placeHolder: '20,00',
            required: false,
            type: 'number',
            autoComplete: 'off',
            startAdornment: currency.euro,
          },
        ],
      },
    ])
  }

  const InitByAmountTextboxes = (): void => {
    setAmountTextboxes([
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'dayAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerDay
            ),
            name: 'amount.dayAmount',
            placeHolder: '20,00',
            required: false,
            type: 'number',
            autoComplete: 'off',
            startAdornment: currency.euro,
          },
          {
            id: 'weekAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerWeek
            ),
            name: 'amount.weekAmount',
            placeHolder: '20,00',
            required: false,
            type: 'number',
            autoComplete: 'off',
            startAdornment: currency.euro,
          },
          {
            id: 'monthAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerMonth
            ),
            name: 'amount.monthAmount',
            placeHolder: '20,00',
            required: false,
            type: 'number',
            autoComplete: 'off',
            startAdornment: currency.euro,
          },
          {
            id: 'yearAmount',
            label: t(
              spendingRuleFormTranslation.form.restrictionByAmount.maxAmountPerYear
            ),
            name: 'amount.yearAmount',
            placeHolder: '20,00',
            required: false,
            type: 'number',
            autoComplete: 'off',
            startAdornment: currency.euro,
          },
        ],
      },
    ])
  }

  const handleAdditionalRestrictionsChange = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    const value = e.target.value
    setAdditionalRestrictions(value)
    form.setValue('profileTypeId', +value)
    form.setValue('amount.dayAmount', '')
    form.setValue('amount.weekAmount', '')
    form.setValue('amount.monthAmount', '')
    form.setValue('amount.yearAmount', '')
    form.clearErrors('amount.dayAmount')
    form.clearErrors('amount.weekAmount')
    form.clearErrors('amount.monthAmount')
    form.clearErrors('amount.yearAmount')
  }

  const handleChangeFav = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setShowPopup(true)
    }
  }

  const buildErrorMessage = (
    currentStripeIndex: number,
    stripesMatching: string[]
  ): string => {
    const start = t(spendingRuleFormTranslation.form.diet.overlap.start)
    const matches = t(spendingRuleFormTranslation.form.diet.overlap.matches)
    const and = t(spendingRuleFormTranslation.form.diet.overlap.and)

    let message = ''
    message = start + (currentStripeIndex + 1) + matches + stripesMatching[0]
    if (stripesMatching.length > 1) {
      message += and + stripesMatching[1]
    }

    return message
  }

  const setMatchStripesError = (
    currentStripeIndex: number,
    stripesMatching: string[]
  ): void => {
    form.setError(`timeZones.${currentStripeIndex}.timeZoneFrom`, {})
    form.setError(`timeZones.${currentStripeIndex}.timeZoneTo`, {})
    form.setError(`timeZones.${currentStripeIndex}.timeZoneTimeGroup`, {
      message: buildErrorMessage(currentStripeIndex, stripesMatching),
    })
  }

  const getSelectedDays = (weekDays: IWeekDays): string[] => {
    return Object.entries(weekDays)
      .map(([day, value]) => ({ [day]: value })) // convert into [{monday: true}, {tuesday: true}] format
      .filter(day => Object.values(day)[0] === true) // get only selected days
      .map(day => Object.keys(day)[0])
  }

  const isMatchingDays = (
    currentTZWeekDays: IWeekDays,
    compareTZWeekDays: IWeekDays
  ): boolean => {
    const selectedCurrentDays = getSelectedDays(currentTZWeekDays)
    const selectedCompareDays = getSelectedDays(compareTZWeekDays)
    const matchingDays = selectedCurrentDays.filter(d => selectedCompareDays.includes(d))
    return matchingDays.length > 0
  }

  const isMatchingTimeTange = (currentTZ: ITimeZones, compareTZ: ITimeZones): boolean => {
    const currentTimeFrom = new Date(`1970-01-01T${currentTZ.timeZoneFrom}`)
    const currentTimeTo = new Date(`1970-01-01T${currentTZ.timeZoneTo}`)
    const compareTimeFrom = new Date(`1970-01-01T${compareTZ.timeZoneFrom}`)
    const compareTimeTo = new Date(`1970-01-01T${compareTZ.timeZoneTo}`)
    if (currentTimeTo < compareTimeFrom || compareTimeTo < currentTimeFrom) {
      return false
    }
    if (compareTimeTo < currentTimeFrom || currentTimeTo < compareTimeFrom) {
      return false
    }
    return true
  }

  const validateStripe2 = (stripe1: ITimeZones, stripe2: ITimeZones): boolean => {
    const stripe2Index = 1
    const daysMatchWithStripe1 = isMatchingDays(stripe2.weekDays, stripe1.weekDays)
    const timeMatchWithStripe1 = isMatchingTimeTange(stripe2, stripe1)
    if (daysMatchWithStripe1 && timeMatchWithStripe1) {
      setMatchStripesError(stripe2Index, ['1'])
      return true
    }
    return false
  }

  const validateStripe3 = (
    stripe1: ITimeZones,
    stripe2: ITimeZones,
    stripe3: ITimeZones
  ): boolean => {
    const stripe3Index = 2
    const stripesMatching: string[] = []
    const daysMatchWithStripe1 = isMatchingDays(stripe3.weekDays, stripe1.weekDays)
    const daysMatchWithStripe2 = isMatchingDays(stripe3.weekDays, stripe2.weekDays)
    const timeMatchWithStripe1 = isMatchingTimeTange(stripe3, stripe1)
    const timeMatchWithStripe2 = isMatchingTimeTange(stripe3, stripe2)

    if (daysMatchWithStripe1 && timeMatchWithStripe1) {
      stripesMatching.push('1')
    }
    if (daysMatchWithStripe2 && timeMatchWithStripe2) {
      stripesMatching.push('2')
    }

    if (
      (daysMatchWithStripe1 && timeMatchWithStripe1) ||
      (daysMatchWithStripe2 && timeMatchWithStripe2)
    ) {
      setMatchStripesError(stripe3Index, stripesMatching)
      return true
    }
    return false
  }

  const validateStripesDontOverlap = async (
    newSpendingRule: SpendingRuleModel
  ): Promise<void> => {
    const tz = newSpendingRule.timeZones
    const stripe1 = 0
    const stripe2 = 1
    const stripe3 = 2

    let hasErrors = false

    if (tz.length === 2) {
      hasErrors = validateStripe2(tz[stripe1], tz[stripe2])
    }
    if (tz.length === 3) {
      const val1 = validateStripe2(tz[stripe1], tz[stripe2])
      const val2 = validateStripe3(tz[stripe1], tz[stripe2], tz[stripe3])
      if (val1 || val2) {
        hasErrors = true
      }
    }

    if (!hasErrors) {
      await createOrUpdateSpendingRule(newSpendingRule)
    }
  }

  const createOrUpdateSpendingRule = async (
    newSpendingRule: SpendingRuleModel
  ): Promise<void> => {
    const spendingRulePayload = {
      productCode: edenredProducts.ticketRestaurant,
      usageProfile: {
        profileTypeId:
          srType === SpendingRulesTRType.TRDiet
            ? SpendingRulesType.timezones // 3
            : +additionalRestrictions, // 0, 1, 2
        amount: {
          dayAmount: newSpendingRule.amount?.dayAmount,
          weekAmount: newSpendingRule.amount?.weekAmount,
          monthAmount: newSpendingRule.amount?.monthAmount,
          yearAmount: newSpendingRule.amount?.yearAmount,
        },
        timeZones:
          srType === SpendingRulesTRType.TRCard
            ? [newSpendingRule.timeZones[0]]
            : newSpendingRule.timeZones,
        profileId: spendingRuleToEdit ? spendingRuleToEdit.profileId : 0,
        productCode: edenredProducts.ticketRestaurant,
        productName: '',
        profileName: newSpendingRule.profileName,
        description: '',
        usersCount: 0,
        fullDescription: '',
        isUpgradeable: false,
        isDefault: newSpendingRule.isDefault,
      },
    }

    startLoading()
    const { meta } = await (spendingRuleToEdit
      ? spendingRuleService().update(spendingRulePayload)
      : spendingRuleService().add(spendingRulePayload))
    stopLoading()

    // Handle API response
    if (meta.status === MetaStatusCodes.SUCCESS) {
      notificationHandler(meta, true, null, navigationRoutes.spendingRules)
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      // has any message?
      if (meta.messages.length > 0) {
        setStateErrorMessage(meta?.messages[0].description)
      }
    }
  }

  const InitFavoritesPopup = (): void => {
    setTitlePopup(t(spendingRuleFormTranslation.form.favoriteModal.title))
    setDescriptionPopup(t(spendingRuleFormTranslation.form.favoriteModal.description))
    setButtonsPopup(SetPopupButtons())
  }

  const InitCancelPopup = (): void => {
    setTitleCancelPopup(
      spendingRuleToEdit
        ? t(spendingRuleFormTranslation.form.cancelModal.editModeTitle)
        : t(spendingRuleFormTranslation.form.cancelModal.title)
    )
    setDescriptionCancelPopup(t(spendingRuleFormTranslation.form.cancelModal.description))
    setButtonsPopup(SetPopupButtons())
    setButtonsCancelPopup(SetCancelPopupButtons())
  }

  useEffect(() => {
    if (spendingRuleToEdit) {
      setAdditionalRestrictions(spendingRuleToEdit?.profileTypeId.toString())
    }
  }, [spendingRuleToEdit])

  useEffect(() => {
    InitNameTextbox()
    InitByTransactionTextboxes()
    InitByAmountTextboxes()
    InitFavoritesPopup()
    InitCancelPopup()
  }, [])

  return {
    form,
    nameTextbox,
    additionalRestrictions,
    handleAdditionalRestrictionsChange,
    amountTextboxes,
    transactionTextboxes,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    setShowPopup,
    handleChangeFav,
    validateStripesDontOverlap,
    setShowCancelPopup,
    cancelPopupShow,
    cancelPopupTitle,
    cancelPopupDescription,
    cancelPopupButtons,
    errorMessage,
    handleTRTypeOptionChange,
    srType,
  }
}
