export enum KPIUnit {
  Number,
  Percentage,
  Currency,
}

export type KPI = {
  name: string
  value: number
  units: KPIUnit
}
