import { FlexDataModel } from 'src/Flex/User/domain'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'

const computeDisabled = (
  flexData: FlexDataModel | null,
  link: string | string[] | undefined
): boolean => {
  const { isOnboardingDone, onboardingStep } = flexData || {}

  let disabled = true

  if (
    isOnboardingDone ||
    onboardingStep === undefined ||
    !link ||
    link === flexNavigationRoutes.flex
  )
    return false

  if (onboardingStep >= 4 && disabled) {
    disabled =
      !link.includes(flexNavigationRoutes.flexEmployees) &&
      link !== flexNavigationRoutes.flexEmployees
  }

  if (onboardingStep >= 3 && disabled) {
    disabled =
      !link.includes(flexNavigationRoutes.flexCommunicationsTemplates) &&
      link !== flexNavigationRoutes.flexCommunicationsTemplates
  }

  if (onboardingStep >= 2 && disabled) {
    disabled =
      !link.includes(flexNavigationRoutes.flexCollectives) &&
      link !== flexNavigationRoutes.flexCollectives
  }

  if (onboardingStep >= 1 && disabled) {
    disabled =
      !link.includes(flexNavigationRoutes.flexProducts) &&
      link !== flexNavigationRoutes.flexProducts
  }

  return disabled
}

export { computeDisabled }
