import type { CardOrderRequestBody } from '../../bodyRequest/CardOrderRequestBody'
import { edenredProducts } from '../../enum'
import type { CardOrderModel } from '../../models'

export const SetRequestBody = (cardOrder: CardOrderModel): CardOrderRequestBody => {
  return {
    productCode: cardOrder.productCode
      ? cardOrder.productCode
      : edenredProducts.ticketRestaurant,
    cardOrders: [
      {
        cardData: {
          cardRequestTypeId: Number(cardOrder.cardData.cardRequestTypeId),
          initialAmount: Number(cardOrder.cardData.initialAmount),
          spendingRuleId: Number(cardOrder.cardData.spendingRuleId),
        },
        employeeData: {
          birthDate: cardOrder.employeeData.birthDate,
          companyId: cardOrder.employeeData.companyId,
          costCenter: cardOrder.employeeData.costCenter,
          document: cardOrder.employeeData.document,
          documentTypeId: cardOrder.employeeData.documentTypeId,
          email: cardOrder.employeeData.email,
          employeeNumber: cardOrder.employeeData.employeeNumber,
          firstSurname: cardOrder.employeeData.firstSurname,
          name: cardOrder.employeeData.name,
          secondSurname: cardOrder.employeeData.secondSurname,
          telephone: cardOrder.employeeData.telephone,
        },
        sendData: {
          deliverySiteId: cardOrder.sendData?.deliverySiteId,
        },
      },
    ],
  }
}
