import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import type { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { MessagesModel, UserSonModel } from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEmployeeEditProfileSchemaTGD } from 'src/presentation/validations'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { cardOrderFormTranslation, forms } from 'src/domain/translations'
import { MetaStatusCodes, NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { ConvertESDateToISO, GetDateFormatted, maxAge, minAge } from 'src/core/helpers'
import { isMixClient, spendingRuleService } from 'src/core/services'
import { GetSpendingRulesRequestBody } from 'src/core/controllers/cardOrder/services'
import { EmployeesService } from 'src/core/services/employeesService'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

interface ControllerState {
  GetEmployeeDataTextBoxes: () => TextBoxRowModel<UserSonModel>[]
  register: UseFormRegister<UserSonModel>
  errors: any
  updateSon: (updatedEmployee: UserSonModel) => void
  handleSubmit: UseFormHandleSubmit<UserSonModel>
  enableEdit: boolean
  handleEnableEdit: () => void
  errorMessage: string
}

export const useChildProfileFormController = (sonData: UserSonModel): ControllerState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { permissionTags } = useAuth()
  const { employeeEditChildProfileSchema } = useEmployeeEditProfileSchemaTGD()
  const { addNotification } = useNotification()
  const [enableEdit, setEnableEdit] = useState(false)
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<UserSonModel>({
    resolver: yupResolver(employeeEditChildProfileSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      userFirstName: sonData?.userFirstName,
      userLastName: sonData?.userLastName,
      userLastName2: sonData?.userLastName2,
      userBirthdayDate: sonData?.userBirthdayDate
        ? ConvertESDateToISO(GetDateFormatted(sonData?.userBirthdayDate))
        : '',
    },
  })

  const GetEmployeeDataTextBoxes = (): TextBoxRowModel<UserSonModel>[] => {
    return [
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'userFirstName',
            label: t(forms.contact.name),
            name: 'userFirstName',
            placeHolder: t(forms.placeholders.name),
            required: true,
            type: 'text',
            googleApi: false,
            maxLength: 75,
            disabled: !enableEdit,
          },
        ],
      },

      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'userLastName',
            label: t(forms.contact.lastName),
            name: 'userLastName',
            placeHolder: t(forms.placeholders.firstLastname),
            required: true,
            type: 'text',
            googleApi: false,
            maxLength: 75,
            disabled: !enableEdit,
          },
          {
            id: 'userLastName2',
            label: t(forms.contact.secondLastName),
            name: 'userLastName2',
            placeHolder: t(forms.placeholders.secondLastname),
            required: false,
            type: 'text',
            googleApi: false,
            maxLength: 75,
            disabled: !enableEdit,
          },
        ],
      },
    ]
  }

  const updateSon = (updatedSon: UserSonModel): void => {
    startLoading()
    const newSon = {
      userId: sonData?.userId,
      productCode: edenredProducts.ticketGuarderia,
      firstName: updatedSon.userFirstName,
      lastName: updatedSon.userLastName,
      lastName2: updatedSon.userLastName2,
      birthDate: ConvertESDateToISO(GetDateFormatted(updatedSon.userBirthdayDate)),
    }
    EmployeesService()
      .updateEmployeeSon(newSon)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(t(forms.success.message), NotificationSeverity.success)
          setEnableEdit(false)
        } else {
          if (response?.meta.messages.length > 0) {
            if (isMixClient(permissionTags)) {
              addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            }
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
          setEnableEdit(true)
        }
      })
      .finally(() => stopLoading())
  }

  const setDefaultValues = (): void => {
    setValue('userFirstName', sonData?.userFirstName || '')
    setValue('userLastName', sonData?.userLastName || '')
    setValue('userLastName2', sonData?.userLastName2 || '')
    setValue(
      'userBirthdayDate',
      ConvertESDateToISO(GetDateFormatted(sonData?.userBirthdayDate)) || ''
    )
  }

  const handleEnableEdit = (): void => {
    setEnableEdit(true)
  }

  useEffect(() => {
    setDefaultValues()
  }, [sonData])

  return {
    GetEmployeeDataTextBoxes,
    register,
    errors,
    updateSon,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  }
}
