export const assignmentsTranslations = {
  table: {
    filters: {
      addFilter: 'assignments.filters.addFilter',
      inputPlaceHolder: 'assignments.filters.inputPlaceHolder',
      results: 'assignments.filters.results',
      addFilters: 'assignments.filters.addFilters',
      dateFrom: 'assignments.filters.dateFrom',
      dateTo: 'assignments.filters.dateTo',
      search: 'assignments.filters.search',
      clearFilter: 'assignments.filters.clearFilter',
    },
    header: {
      employee: 'assignments.header.employee',
      child: 'assignments.header.child',
      kindergartenAndDate: 'assignments.header.kindergartenAndDate',
      assignmentDate: 'assignments.header.assignmentDate',
      amount: 'assignments.header.amount',
    },
  },
  totalAmount: 'assignments.totalAmount',
  downloadFile: 'assignments.downloadFile',
}
