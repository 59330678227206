export const dietSpendingsTranslations = {
  table: {
    filters: {
      addFilter: 'dietSpendings.filters.addFilter',
      inputPlaceHolder: 'dietSpendings.filters.inputPlaceHolder',
      results: 'dietSpendings.filters.results',
      addFilters: 'dietSpendings.filters.addFilters',
      dateFrom: 'dietSpendings.filters.dateFrom',
      dateTo: 'dietSpendings.filters.dateTo',
      hsFrom: 'dietSpendings.filters.hsFrom',
      hsTo: 'dietSpendings.filters.hsTo',
      postalCode: 'dietSpendings.filters.postalCode',
      postalCodePlaceholder: 'dietSpendings.filters.postalCodePlaceholder',
      city: 'dietSpendings.filters.city',
      cityPlaceholder: 'dietSpendings.filters.cityPlaceholder',
      search: 'dietSpendings.filters.search',
      clearFilter: 'dietSpendings.filters.clearFilter',
      errors: {
        dateLowerThan: 'dietSpendingsTranslations.table.filters.errors.dateLowerThan',
        dateHigherThan: 'dietSpendingsTranslations.table.filters.errors.dateHigherThan',
      },
    },
    header: {
      titular: 'dietSpendings.header.titular',
      employeeNumber: 'dietSpendings.header.employeeNumber',
      commerce: 'dietSpendings.header.commerce',
      postalCode: 'dietSpendings.header.postalCode',
      city: 'dietSpendings.header.city',
      amount: 'dietSpendings.header.amount',
      date: 'dietSpendings.header.date',
    },
  },
  downloadFile: 'dietSpendings.downloadFile',
}
