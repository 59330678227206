import { ChangeEvent } from 'react'
import {
  OreDatePicker,
  OreInput,
  OreSelect,
  OreSelectPlaceholder,
} from '@runroom/oreneta'
import { FilterResultModel } from 'src/Flex/Shared/domain'

interface IInputType {
  id: string
  type: 'text' | 'number' | 'select' | 'date'
  label: string
  placeholder?: string
  options?: { label: string; value: string }[]

  setValue: (filters: Array<FilterResultModel | undefined>) => void
  currentValue: Array<FilterResultModel | undefined>
  index: number
}

export const InputType = ({
  id,
  type,
  label,
  placeholder,
  options,
  setValue,
  currentValue,
  index,
}: IInputType): JSX.Element => {
  const updateValue =
    (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newValue = [...currentValue]
      newValue[index] = { name: id, value: event.target.value }
      setValue(newValue)
    }

  switch (type) {
    case 'text' || 'number':
      return (
        <OreInput
          label={label}
          type={type}
          placeholder={placeholder}
          onChange={updateValue(index)}
          key={id}
          value={currentValue[index]?.value ?? ''}
        />
      )
    case 'select':
      return (
        <OreSelect
          label={label}
          onChange={updateValue(index)}
          key={id}
          value={currentValue[index]?.value ?? ''}>
          {placeholder ? <OreSelectPlaceholder label={placeholder} /> : null}
          {options
            ? options.map((o, i) => (
                <option value={o.value} key={id + i}>
                  {o.label}
                </option>
              ))
            : null}
        </OreSelect>
      )
    case 'date':
      return (
        <OreDatePicker
          label={label}
          key={id}
          onChange={updateValue(index)}
          value={currentValue[index]?.value ?? ''}
        />
      )
    default:
      return (
        <OreInput
          label={label}
          type={type}
          onChange={updateValue(index)}
          placeholder={placeholder}
          key={id}
          value={currentValue[index]?.value ?? ''}
        />
      )
  }
}
