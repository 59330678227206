import { OreButton, OrePagination } from '@runroom/oreneta'
import { memo } from 'react'
import { Seq } from 'immutable'
import type {
  FooterActionButton,
  FooterModel,
  RowModel,
} from '../../../../../domain/customComponents/table'

export const Footer = memo(
  (props: { footer: FooterModel; rows: RowModel[] }): JSX.Element => {
    return (
      <div>
        {props.footer.actionButtons && (
          <div className="flex">
            {Seq(props.footer.actionButtons).map(
              (action: FooterActionButton, index: number) => (
                <div className="mr-2" key={'actionFooter_' + index}>
                  <OreButton
                    size={action.size}
                    category={action.category}
                    icon={action.icon}
                    onClick={action.onClick}>
                    {action.text}
                  </OreButton>
                </div>
              )
            )}
          </div>
        )}
        {props.footer.pagination && props.rows.length > 0 && (
          <OrePagination
            count={
              props.footer.pagination?.totalRows
                ? props.footer.pagination.totalRows
                : props.rows.length
            }
            labelNextPage={props.footer.pagination.labelNextPage}
            labelPreviousPage={props.footer.pagination.labelPreviousPage}
            onPageChange={props.footer.pagination.onPageChange}
            page={props.footer.pagination.page}
            rowsPerPage={props.footer.pagination.rowsPerPage}
            text={props.footer.pagination.text}
          />
        )}
      </div>
    )
  }
)
