import { useTranslation } from 'react-i18next'
import { OreButton, OreStack, OreWysiwyg, SvgDownload } from '@runroom/oreneta'
import { DropZone, UploadingModal } from 'src/Flex/Shared/ui/DropZone'
import { ExcelLayout, useExcelUpload } from 'src/Flex/Employees/ui/employee-excel'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

export const ExcelUploader = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    uploadForm: { formState, handleSubmit, register },
    validFileFormats,
    uploading,
    download,
    upload,
    goToEmployees,
  } = useExcelUpload()
  const { errors } = handleErrors(formState)

  return (
    <ExcelLayout
      onCancel={goToEmployees}
      onContinue={handleSubmit(upload)}
      continueLabel={t(flexEmployeesTranslations.excel.upload.label)}
      continueDisabled={!formState.isValid}>
      <UploadingModal uploading={uploading} />

      <OreStack space="medium" placeItems="start">
        <OreStack space="2xlarge">
          <OreWysiwyg>
            <p style={{ fontWeight: '600' }}>
              {t(flexEmployeesTranslations.excel.upload.steps.title)}
            </p>
            <ol>
              <li>{t(flexEmployeesTranslations.excel.upload.steps.firstStep)}</li>
              <li>{t(flexEmployeesTranslations.excel.upload.steps.secondStep)}</li>
              <li>{t(flexEmployeesTranslations.excel.upload.steps.thirdStep)}</li>
            </ol>
            <p>{t(flexEmployeesTranslations.excel.upload.description)}</p>
          </OreWysiwyg>
          <OreWysiwyg>
            <p style={{ fontWeight: '600' }}>
              {t(flexEmployeesTranslations.excel.upload.description_2)}
            </p>
            <ol style={{ listStyle: 'none', padding: '0' }}>
              <li>{t(flexEmployeesTranslations.excel.upload.option.firstOption)}</li>
              <li>{t(flexEmployeesTranslations.excel.upload.option.secondOption)}</li>
              <li>{t(flexEmployeesTranslations.excel.upload.option.thirdOption)}</li>
              <li>{t(flexEmployeesTranslations.excel.upload.option.fourthOption)}</li>
            </ol>
            <p>{t(flexEmployeesTranslations.excel.upload.description_3)}</p>
          </OreWysiwyg>
        </OreStack>
        <OreButton icon={<SvgDownload />} category="secondary" onClick={download}>
          {t(flexEmployeesTranslations.excel.download.button)}
        </OreButton>
      </OreStack>
      <DropZone
        {...register('file')}
        {...errors('file')}
        validFileFormats={validFileFormats}
      />
    </ExcelLayout>
  )
}
