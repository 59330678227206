import { productsService } from 'src/Flex/Products/application'
import type { edenredProducts } from 'src/domain/enum'
import { useProducts } from 'src/Flex/Products/ui/product-list'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { publishEvent } from 'src/Flex/Shared/ui/Events/helpers'
import { Events as productEvents } from 'src/Flex/Products/domain'

export const useStatusToggleProduct = () => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const { collective } = useProducts()

  const save = async (productId: edenredProducts): Promise<boolean> => {
    startLoading()

    const response = await productsService().SaveProductStatus(productId, collective?.id)

    stopLoading()

    if (handleMetaResponse(response?.meta)) {
      publishEvent(productEvents.SAVE_PRODUCT_STATE)

      return true
    }

    return false
  }

  return {
    save,
  }
}
