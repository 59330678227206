export const cardOrderFormTranslation = {
  title: 'cardOrderFormTranslation.title',
  excelText1: 'cardOrderFormTranslation.excelText1',
  excelText2: 'cardOrderFormTranslation.excelText2',
  dismiss: 'cardOrderFormTranslation.dismiss',
  createNewCard: 'cardOrderFormTranslation.createNewCard',
  modifyCard: 'cardOrderFormTranslation.modifyCard',
  addDeliverySite: 'cardOrderFormTranslation.addDeliverySite',
  shippingButton: 'cardOrderFormTranslation.shippingButton',
  employeeDetail: {
    title: 'cardOrderFormTranslation.employeeDetail.title',
    name: 'cardOrderFormTranslation.employeeDetail.name',
    namePlaceHolder: 'cardOrderFormTranslation.employeeDetail.name.placeHolder',
    surname: 'cardOrderFormTranslation.employeeDetail.surname',
    surnamePlaceHolder: 'cardOrderFormTranslation.employeeDetail.surname.placeHolder',
    secondSurname: 'cardOrderFormTranslation.employeeDetail.secondSurname',
    secondSurnamePlaceHolder:
      'cardOrderFormTranslation.employeeDetail.secondSurname.placeHolder',
    documentType: 'cardOrderFormTranslation.employeeDetail.documentType',
    documentTypeLegend: 'cardOrderFormTranslation.employeeDetail.documentTypeLegend',
    document: 'cardOrderFormTranslation.employeeDetail.document',
    documentPlaceHolder: 'cardOrderFormTranslation.employeeDetail.document.placeHolder',
    birthDate: 'cardOrderFormTranslation.employeeDetail.birthDate',
    birthDatePlaceHolder: 'cardOrderFormTranslation.employeeDetail.birthDate.placeHolder',
    birthDateLegend: 'cardOrderFormTranslation.employeeDetail.birthDate.birthDateLegend',
    birthDateFooter: 'cardOrderFormTranslation.employeeDetail.birthDate.footer',
    email: 'cardOrderFormTranslation.employeeDetail.email',
    emailPlaceHolder: 'cardOrderFormTranslation.employeeDetail.email.placeHolder',
    emailLegend: 'cardOrderFormTranslation.employeeDetail.email.legend',
    phone: 'cardOrderFormTranslation.employeeDetail.phone',
    phonePlaceHolder: 'cardOrderFormTranslation.employeeDetail.phone.placeHolder',
    costCenter: 'cardOrderFormTranslation.employeeDetail.costCenter',
    costCenterPlaceHolder:
      'cardOrderFormTranslation.employeeDetail.costCenter.placeHolder',
    employeeNumber: 'cardOrderFormTranslation.employeeDetail.employeeNumber',
    employeeNumberPlaceHolder:
      'cardOrderFormTranslation.employeeDetail.employeeNumber.placeHolder',
  },
  cardDetail: {
    title: 'cardOrderFormTranslation.cardDetail.title',
    cardType: 'cardOrderFormTranslation.cardDetail.cardType',
    virtual: 'cardOrderFormTranslation.cardDetail.virtual',
    physical: 'cardOrderFormTranslation.cardDetail.physical',
    spendingRules: 'cardOrderFormTranslation.cardDetail.spendingRules',
    spendingRulesPlaceholder:
      'cardOrderFormTranslation.cardDetail.spendingRulesPlaceholder',
    rechargeAmount: 'cardOrderFormTranslation.cardDetail.rechargeAmount',
    rechargeAmountPlaceholder:
      'cardOrderFormTranslation.cardDetail.rechargeAmountPlaceholder',
    rechargeAmountFooter: 'cardOrderFormTranslation.cardDetail.rechargeAmountFooter',
    allowPlasticlessTooltip:
      'cardOrderFormTranslation.cardDetail.allowPlasticlessTooltip',
  },
  shipment: {
    title: 'cardOrderFormTranslation.shipment.title',
    deliverySiteSelectLabel: 'cardOrderFormTranslation.shipment.deliverySiteSelectLabel',
    deliverySiteSelectPlaceholder:
      'cardOrderFormTranslation.shipment.deliverySiteSelectPlaceholder',
    deliverySiteSelectedEditButton:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedEditButton',
    deliverySiteSelectedClearButton:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedClear',
    deliverySiteSelectedAddressDetail:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedAddressDetail',
    deliverySiteSelectedShipmentDetail:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedShipmentDetail',
    deliverySiteSelectedSendBy:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedSendBy',
    deliverySiteSelectedEmail:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedEmail',
    deliverySiteSelectedMessengerService:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedMessengerService',
    deliverySiteSelectedSendTo:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedSendTo',
    noButtons: 'cardOrderFormTranslation.shipment.noButtons',
    deliverySiteSelectedDeliveryAddress:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedDeliveryAddress',
    deliverySiteSelectedContactPhone:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedContactPhone',
    deliverySiteSelectedMail:
      'cardOrderFormTranslation.shipment.deliverySiteSelectedMail',
  },
  popup: {
    title: 'cardOrderFormTranslation.popup.title',
    description: 'cardOrderFormTranslation.popup.description',
    titleSubmit: 'cardOrderFormTranslation.popup.titleSubmit',
    submitDescription1: 'cardOrderFormTranslation.popup.submitDescription1',
    submitDescription2: 'cardOrderFormTranslation.popup.submitDescription2',
    submitDescription3: 'cardOrderFormTranslation.popup.submitDescription3',
  },
}
