import type { ValidateExcelCheckoutResponse } from '../../domain/models'

export const validateExcelCheckoutDefault: ValidateExcelCheckoutResponse = {
  summary: {
    abstractCardOrder: {
      resumeData: [],
    },
    detail: {
      paymentMode: '',
      paymentDescription: '',
      paymentTooltip: '',
    },
    card: {
      services: [],
      subtotal: '0',
      iva: '0',
      total: '0',
      quantity: 0,
    },
    charge: {
      services: [],
      subtotal: '0',
      iva: '0',
      total: '0',
      quantity: 0,
    },
    totalAmount: '0',
  },
  additionalData: null,
}

export const validateExcelCheckoutMock2: ValidateExcelCheckoutResponse = {
  summary: {
    abstractCardOrder: {
      resumeData: [
        {
          resumeCode: 1,
          name: 'Número de tarjetas físicas',
          value: '6',
          order: 1,
        },
        {
          resumeCode: 3,
          name: 'Total tarjetas',
          value: '6',
          order: 3,
        },
        {
          resumeCode: 4,
          name: 'Número de recargas',
          value: '4',
          order: 4,
        },
        {
          resumeCode: 5,
          name: 'Total recargas',
          value: '400.00€',
          order: 5,
        },
        {
          resumeCode: 7,
          name: 'Total de envíos por mensajería - Península',
          value: '1',
          order: 7,
        },
      ],
    },
    detail: {
      paymentMode: '1',
      paymentDescription: 'Mock2',
      paymentTooltip: 'Mock2',
    },
    card: {
      services: [
        {
          label: 'Emisión de Tarjetas Mock2',
          quantity: 11,
          value: '33.0000',
          iva: 6.93,
        },
        {
          label: 'Gestión de Pedido Mock2',
          quantity: 1,
          value: '0',
          iva: 0,
        },
        {
          label: 'Envío por mensajería - Península Mock2',
          quantity: 3,
          value: '10.5000',
          iva: 2.21,
        },
      ],
      subtotal: '43.5000',
      iva: '9.1400',
      total: '52.6400',
      quantity: 11,
    },
    charge: {
      services: [
        {
          label: 'Recargas Mock2',
          quantity: 8,
          value: '800.0000',
          iva: 0,
        },
        {
          label: 'Comisión Recargas Mock2',
          quantity: 1,
          value: '36.0000',
          iva: 7.56,
        },
      ],
      subtotal: '836.0000',
      iva: '7.5600',
      total: '843.5600',
      quantity: 8,
    },
    totalAmount: '896.2000',
  },
  additionalData: null,
}
export const validateExcelCheckoutMock: ValidateExcelCheckoutResponse = {
  summary: {
    abstractCardOrder: {
      resumeData: [
        {
          resumeCode: 1,
          name: 'Número de tarjetas físicas',
          value: '6',
          order: 1,
        },
        {
          resumeCode: 3,
          name: 'Total tarjetas',
          value: '6',
          order: 3,
        },
        {
          resumeCode: 4,
          name: 'Número de recargas',
          value: '4',
          order: 4,
        },
        {
          resumeCode: 5,
          name: 'Total recargas',
          value: '400.00€',
          order: 5,
        },
        {
          resumeCode: 7,
          name: 'Total de envíos por mensajería - Península',
          value: '1',
          order: 7,
        },
      ],
    },
    detail: {
      paymentMode: '1',
      paymentDescription: 'Pago con tarjeta Mock',
      paymentTooltip: 'Pago con tarjeta',
    },
    card: {
      services: [
        {
          label: 'Emisión de Tarjetas Mock',
          quantity: 11,
          value: '33.0000',
          iva: 6.93,
        },
        {
          label: 'Gestión de Pedido Mock',
          quantity: 1,
          value: '0',
          iva: 0,
        },
        {
          label: 'Envío por mensajería - Península Mock',
          quantity: 3,
          value: '10.5000',
          iva: 2.21,
        },
      ],
      subtotal: '43.5000',
      iva: '9.1400',
      total: '52.6400',
      quantity: 11,
    },
    charge: {
      services: [
        {
          label: 'Recargas Mock',
          quantity: 8,
          value: '800.0000',
          iva: 0,
        },
        {
          label: 'Comisión Recargas Mock',
          quantity: 1,
          value: '36.0000',
          iva: 7.56,
        },
      ],
      subtotal: '836.0000',
      iva: '7.5600',
      total: '843.5600',
      quantity: 8,
    },
    totalAmount: '896.2000',
  },
  additionalData: null,
}
