import {
  OreButton,
  OreCardActions,
  OreCardContainer,
  OreCardTitle,
  OreCardWrapper,
  OreData,
  OreStack,
  SvgBin,
  SvgDestinationDirection,
  SvgEdit,
  SvgEmail,
  SvgMessengerService,
  SvgPhone,
  SvgPostalMail,
  SvgUser,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { phonePrefix } from '../../../../../domain/definitions/constants'
import { deliveryType, emptyValues } from '../../../../../domain/enum'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import {
  addComma,
  getUndefinedDashString,
  getUndefinedEmptyString,
} from 'src/core/services'

export interface Props {
  deliverySite?: DeliverySiteModel
  handleDelete?(): void
  handleEdit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'new' | 'edit'
  ) => void
}
export const DeliverySiteSelected = ({
  deliverySite,
  handleDelete,
  handleEdit,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  if (!deliverySite) return <></>

  const sendContactDetails =
    deliverySite.contactName ||
    deliverySite.telephone !== null ||
    deliverySite.email !== null

  const getDeliveryTypeName = (deliveryTypeId: number): string => {
    if (deliveryTypeId === deliveryType.messenger) {
      return t(cardOrderFormTranslation.shipment.deliverySiteSelectedMessengerService)
    }
    if (deliveryTypeId === deliveryType.mail) {
      return t(cardOrderFormTranslation.shipment.deliverySiteSelectedEmail)
    }
    return ''
  }

  return (
    <OreCardContainer hasBorder={true}>
      <OreCardWrapper>
        <OreStack space="large" placeContent="stretch">
          <OreStack>
            <OreCardTitle
              icon={
                deliverySite.deliveryType === deliveryType.mail ? (
                  <SvgPostalMail />
                ) : (
                  <SvgMessengerService />
                )
              }
              tone={
                deliverySite.deliveryType === deliveryType.mail
                  ? 'postal-service'
                  : 'generic-dark'
              }
              title={`${deliverySite.alias} - ${deliverySite.deliveryPointName}`}
              subtitle={`${t(
                cardOrderFormTranslation.shipment.deliverySiteSelectedSendBy
              )} ${getDeliveryTypeName(deliverySite.deliveryType)}`}
              subtitleTone="cobalt-600"
            />
          </OreStack>
          <OreStack
            space="large"
            placeContent="stretch"
            style={{ paddingRight: 'var(--spacing-2-x-large)' }}>
            <OreData
              icon={<SvgDestinationDirection />}
              title={t(
                cardOrderFormTranslation.shipment.deliverySiteSelectedDeliveryAddress
              )}
              description={
                addComma(deliverySite.streetName) +
                addComma(deliverySite.streetNumber) +
                addComma(deliverySite.streetDescription) +
                getUndefinedEmptyString(deliverySite.zipCode) +
                ' ' +
                addComma(deliverySite.city) +
                getUndefinedEmptyString(deliverySite.province)
              }
            />
            <OreStack direction="row" placeContent="space-between">
              <OreData
                icon={<SvgUser />}
                title={t(cardOrderFormTranslation.shipment.deliverySiteSelectedSendTo)}
                description={getUndefinedDashString(deliverySite.contactName)}
              />
              <OreData
                icon={<SvgPhone />}
                title={t(
                  cardOrderFormTranslation.shipment.deliverySiteSelectedContactPhone
                )}
                description={
                  deliverySite.telephone
                    ? `${phonePrefix.spain} ${deliverySite.telephone}`
                    : emptyValues.dash
                }
              />
              <OreData
                icon={<SvgEmail />}
                title={t(cardOrderFormTranslation.shipment.deliverySiteSelectedMail)}
                description={getUndefinedDashString(deliverySite.email)}
              />
            </OreStack>
          </OreStack>
        </OreStack>
      </OreCardWrapper>
      <OreCardActions>
        <OreStack direction="row" space="large">
          <OreButton
            category="tertiary"
            icon={<SvgEdit />}
            onClick={event => handleEdit(event, 'edit')}>
            {t(cardOrderFormTranslation.shipment.deliverySiteSelectedEditButton)}
          </OreButton>
          <OreButton category="tertiary" icon={<SvgBin />} onClick={handleDelete}>
            {t(cardOrderFormTranslation.shipment.deliverySiteSelectedClearButton)}
          </OreButton>
        </OreStack>
      </OreCardActions>
    </OreCardContainer>
  )
}
