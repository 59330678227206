export const employeesAdherenceTranslations = {
  title: 'flex.employees.adherence.title',
  subtitleEmployees: 'flex.employees.adherence.subtitle-emp',
  tabEmp: 'flex.employees.adherence.tab-emp',
  lastAdherenceTable: {
    empty: 'flex.employees.adherence.table.empty',
    title: 'flex.employees.adherence.table.title',
    header: {
      name: 'flex.employees.adherence.table.header.name',
      adherenceDate: 'flex.employees.adherence.table.header.adherenceDate',
      products: 'flex.employees.adherence.table.header.products',
    },
  },
  pendingAdherenceTable: {
    empty: 'flex.employees.pendingAdherence.table.empty',
    title: 'flex.employees.pendingAdherence.table.title',
    subtitle: 'flex.employees.pendingAdherence.table.subtitle',
  },
}
