import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { UseFormReturn, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { excelService } from 'src/Flex/Employees/application'
import { EmployeeExcelUploadResponse } from 'src/Flex/Employees/domain'
import { fileSchema, getFileInBase64 } from 'src/Flex/Shared/ui/DropZone'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { downloadExcel } from 'src/core/helpers'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { Steps, stepSlug } from 'src/Flex/Employees/ui/employee-excel'
import { requiredCheckboxSchema, useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

import { publishEvent } from 'src/Flex/Shared/ui/Events/helpers'
import { Events as EmployeesEvents } from 'src/Flex/Employees/domain'
import { MessagesModel } from 'src/domain/models'
import { INTERNALERROR } from 'src/domain/constants'

type UploadExcelForm = {
  file: FileList | null
}

type ValidateExcelForm = {
  terms: boolean
}

export type UseExcelUploadController = {
  step: Steps | undefined
  uploadForm: UseFormReturn<UploadExcelForm>
  validateForm: UseFormReturn<ValidateExcelForm>
  validFileFormats: string[]
  uploading: boolean
  uploadResponse: EmployeeExcelUploadResponse | undefined
  download: () => Promise<void>
  upload: (data: UploadExcelForm) => Promise<void>
  downloadErrorsFile: () => Promise<void>
  confirm: (data: ValidateExcelForm) => Promise<void>
  goToEmployees: () => void
  goToResumee: () => void
  clearErrors: () => void
  error: MessagesModel | undefined
  setError: Dispatch<SetStateAction<MessagesModel | undefined>>
}

const validFileFormats = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

const maxFileSize = 10 * 1024 * 1024 // 10MB

const uploadSchema = (t: TFunction<'undefined', null>) =>
  object().shape({
    file: fileSchema(t, validFileFormats, maxFileSize),
  })

const validateSchema = (t: TFunction<'undefined', null>) =>
  object().shape({
    terms: requiredCheckboxSchema(t),
  })

const getStep = (step?: string): Steps | undefined => {
  switch (step) {
    case stepSlug(Steps.Upload):
      return Steps.Upload
    case stepSlug(Steps.Validation):
      return Steps.Validation
    case stepSlug(Steps.Confirmation):
      return Steps.Confirmation
    default:
      return undefined
  }
}

export const useExcelUploadController = (): UseExcelUploadController => {
  const { step: rawStep } = useParams<{ step: string }>()
  const { t } = useTranslation()
  const [uploading, setUploading] = useState(false)
  const [uploadResponse, setUploadResponse] = useState<EmployeeExcelUploadResponse>()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<MessagesModel>()
  const uploadForm = useForm<UploadExcelForm>({
    mode: 'onChange',
    resolver: yupResolver(uploadSchema(t)),
    defaultValues: {
      file: null,
    },
  })
  const validateForm = useForm<ValidateExcelForm>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema(t)),
    defaultValues: {
      terms: false,
    },
  })
  const { handleMetaResponse } = useMetaResponseHandler()
  const { addNotification } = useNotification()
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()

  const step = getStep(rawStep)

  const isValidStep = (step: Steps | undefined): boolean => {
    if (!step) {
      return false
    }

    if (
      step === Steps.Validation &&
      (!uploadResponse || uploadResponse.hasErrors || success)
    ) {
      return false
    }

    if (step === Steps.Confirmation && !success) {
      return false
    }

    return true
  }

  const download = async (): Promise<void> => {
    startLoading()

    const response = await excelService().getTemplate()

    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, {
        notifySuccess: false,
      }) &&
      response.data.excel
    ) {
      downloadExcel(
        response.data.excel,
        t(flexEmployeesTranslations.excel.download.fileName)
      )
    }
  }

  const upload = async (data: UploadExcelForm): Promise<void> => {
    setUploading(true)

    const base64File = await getFileInBase64(data.file)

    if (!base64File) {
      setUploading(false)
      addNotification(
        t(flexEmployeesTranslations.excel.uploadError),
        NotificationSeverity.error
      )

      return
    }

    const response = await excelService().validateOrder({ excel: base64File })

    setUploading(false)
    setUploadResponse(response.data)

    if (response.meta.status === MetaStatusCodes.SUCCESS) {
      validateForm.reset()

      navigate(
        flexNavigationRoutes.flexEmployeesExcel.replace(
          ':step',
          stepSlug(Steps.Validation)
        )
      )
    }
    if (
      response.meta.status === MetaStatusCodes.ERROR &&
      response.meta.messages[0].code === INTERNALERROR
    ) {
      setError(response.meta.messages[0])
    } else {
      setError(undefined)
    }
  }

  const confirm = async (data: ValidateExcelForm): Promise<void> => {
    if (!uploadResponse?.excelId) {
      return
    }

    startLoading()

    const response = await excelService().confirmOrder({
      id: uploadResponse.excelId,
      terms: data.terms,
    })

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setSuccess(true)
      setUploadResponse(undefined)

      publishEvent(EmployeesEvents.CREATE_NEW_EMPLOYEE)

      navigate(
        flexNavigationRoutes.flexEmployeesExcel.replace(
          ':step',
          stepSlug(Steps.Confirmation)
        )
      )
    }
  }

  const downloadErrorsFile = async (): Promise<void> => {
    if (!uploadResponse?.excelWithErrors) {
      return
    }

    downloadExcel(uploadResponse.excelWithErrors, 'errors')
  }

  const goToEmployees = () => navigate(flexNavigationRoutes.flexEmployees)
  const goToResumee = () => {
    navigate(
      flexNavigationRoutes.flexEmployeesExcel.replace(':step', stepSlug(Steps.Validation))
    )
  }
  const clearErrors = () => {
    setUploadResponse(undefined)
    setSuccess(false)

    uploadForm.reset()
  }

  useEffect(() => {
    if (step !== Steps.Upload && !isValidStep(step)) {
      navigate(
        flexNavigationRoutes.flexEmployeesExcel.replace(':step', stepSlug(Steps.Upload))
      )
    }

    if (step === Steps.Upload) {
      clearErrors()
    }
  }, [step])

  return {
    step,
    uploadForm,
    validateForm,
    validFileFormats,
    uploading,
    uploadResponse,
    download,
    upload,
    downloadErrorsFile,
    confirm,
    goToEmployees,
    goToResumee,
    clearErrors,
    error,
    setError,
  }
}
