import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation } from '../../../../../../domain/translations'

const useExcelFileValidation = () => {
  const { t } = useTranslation()
  const schema = Yup.object().shape({
    file: Yup.mixed()
      .required(t(cardOrderTranslation.excel.requiredError))
      .test(
        'fileExists',
        t(cardOrderTranslation.excel.requiredError),
        value => value !== null && value.length > 0
      )
      .test('fileFormat', t(cardOrderTranslation.excel.fileFormatError), value => {
        if (value !== null && value.length > 0) {
          const file = value[0]
          return (
            file.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel'
          )
        }
        return true
      })
      .test('fileSize', t(cardOrderTranslation.excel.filesSizeError), value => {
        if (value !== null && value.length > 0) {
          const file = value[0]
          return file.size < 10 * 1024 * 1024 // 10MB
        }
        return true
      }),
  })
  return { schema }
}
export default useExcelFileValidation
