import React from 'react'
import { Textbox } from 'src/presentation/components/Edenred'
import { UseFormReturn } from 'react-hook-form'
import { UsersAndLicensesFormModel } from 'src/domain/models/myAccount/UsersAndLicenses'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { OreHeading } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'

interface Props {
  form: UseFormReturn<UsersAndLicensesFormModel>
  getUserDataForm: () => TextBoxRowModel<UsersAndLicensesFormModel>[]
}

export const AccountUsersAndLicensesUserData = ({
  form,
  getUserDataForm,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className="mt-1">
      <div className="mb-05">
        <OreHeading size="headline-sm">
          {t(accountUsersLicensesTranslations.add.userDataTitle)}
        </OreHeading>
      </div>
      <Textbox
        textBoxList={getUserDataForm()}
        register={form.register}
        errors={form.formState.errors}
      />
    </div>
  )
}
