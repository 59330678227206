import { yupResolver } from '@hookform/resolvers/yup'
import { UseFormReturn, useForm } from 'react-hook-form'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrderFormSchema } from '../validations'
import { ComboBoxOptionModel, TextBoxRowModel } from 'src/domain/customComponents'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import {
  MetaStatusCodes,
  NotificationSeverity,
  cardRequestType,
  cardRequestTypeId,
  currency,
  edenredProducts,
  orderType,
} from 'src/domain/enum'
import { GetTTDeliverySitesRequestBody } from 'src/core/controllers/cardOrder/services'
import { DeliverySiteModel, MessagesModel } from 'src/domain/models'
import { deliverySiteService, getDeliverySitesByGroup } from 'src/core/services'
import { BasicParameters } from 'src/domain/forms'
import { useModalController } from 'src/presentation/components/Edenred'
import { TTOrderService } from 'src/core/services/ttOrderService'
import { useNavigate } from 'react-router-dom'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useTTOrder } from '../../context/TTOrderProvider'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { CardOrderRequestBody } from 'src/domain/bodyRequest/CardOrderRequestBody'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { ConvertStringDateToISOOnly, createOrderId } from 'src/core/helpers'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { usePreviousURL } from 'src/presentation/components/Edenred/navigation/hooks'
import { parseTTOrderFormErrorKey } from 'src/domain/adapters/ttOrderAdapter'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useUser } from 'src/presentation/context/user/UserProvider'

interface ControlState {
  form: UseFormReturn<TTOrderModel, any>
  formTextBoxes: TextBoxRowModel<TTOrderModel>[]
  onCreateNewCard: (newUserCard: TTOrderModel) => Promise<void>
  deliverySiteSelectLoading: boolean
  deliverySitesOptions: ComboBoxOptionModel[]
  onDeliverySiteChange: (event: ChangeEvent<HTMLSelectElement>) => void
  selectedDeliverySite: DeliverySiteModel | undefined
  removeSelectedDeliverySite: () => void
  onClickCancel: () => void
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onNewOrEditDeliverySite: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'new' | 'edit'
  ) => void
  deliverySiteFormParameters: BasicParameters<DeliverySiteModel>
  cardOrderModalShow: boolean
  setCardOrderModalShow: Dispatch<SetStateAction<boolean>>
  setNewDeliverySite: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
}

export const useTTOrderFormController = (
  ttOrderItemToEdit?: TTOrderModel
): ControlState => {
  const [formTextBoxes, setFormTextBoxes] = useState<TextBoxRowModel<TTOrderModel>[]>([])
  const [deliverySites, setDeliverySites] = useState<DeliverySiteModel[]>([])
  const [deliverySitesOptions, setDeliverySitesOptions] = useState<ComboBoxOptionModel[]>(
    []
  )
  const [deliverySiteFormParameters, setDeliverySiteFormParameters] = useState<
    BasicParameters<DeliverySiteModel>
  >({})
  const [selectedDeliverySite, setSelectedDeliverySite] = useState<DeliverySiteModel>()
  const [newDeliverySite, setNewDeliverySite] = useState<DeliverySiteModel>()
  const [deliverySiteSelectLoading, setDeliverySiteSelectLoading] =
    useState<boolean>(false)
  const { TTOrderFormSchema } = useTTOrderFormSchema()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const { addTTOrder, updateTTOrder } = useTTOrder()
  const { startLoading, stopLoading } = useLoader()
  const { user } = useAuth()
  const { show: cardOrderModalShow, setShow: setCardOrderModalShow } =
    useModalController()
  const comesFromSummary = usePreviousURL(navigationRoutes.ttOrderSummary)
  const { userDeliverySitesById } = useUser()

  const form = useForm<TTOrderModel>({
    resolver: yupResolver(TTOrderFormSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: ttOrderItemToEdit ? ttOrderItemToEdit : {},
  })

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const InitFormTextBoxes = () => {
    setFormTextBoxes([
      {
        className: 'form-atom',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'name',
            label: t(forms.contact.name),
            name: 'name',
            placeHolder: t(forms.placeholders.name),
            required: true,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'firstSurname',
            label: t(forms.contact.firstLastName),
            name: 'firstSurname',
            placeHolder: t(forms.placeholders.firstLastname),
            required: true,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
          {
            id: 'secondSurname',
            label: t(forms.contact.secondLastName),
            name: 'secondSurname',
            placeHolder: t(forms.placeholders.secondLastname),
            required: false,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'email',
            label: t(forms.contact.corporativeEmail),
            name: 'corporativeEmail',
            placeHolder: t(forms.placeholders.corporativeEmail),
            legend: t(TTOrderTranslation.form.user.email.helpText),
            required: true,
            maxLength: 75,
            type: 'email',
            autoComplete: 'off',
          },
          {
            id: 'telephone',
            label: t(forms.contact.contactPhone),
            name: 'telephone',
            placeHolder: t(forms.placeholders.contactPhone),
            required: false,
            maxLength: 9,
            type: 'tel',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'costCenter',
            label: t(forms.employee.costCenter),
            name: 'costCenter',
            placeHolder: t(forms.placeholders.employee.costCenter),
            required: false,
            maxLength: 75,
            type: 'text',
            autoComplete: 'off',
          },
          {
            id: 'employeeNumber',
            label: t(forms.employee.employeeNumber),
            name: 'employeeNumber',
            placeHolder: t(forms.placeholders.employee.employeeNumber),
            required: false,
            maxLength: 14,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'initialAmount',
            label: t(TTOrderTranslation.form.card.initialAmount.label),
            name: 'cardData.initialAmount',
            legend: t(TTOrderTranslation.form.card.initialAmount.helpText),
            placeHolder: t(forms.placeholders.order.amount),
            required: false,
            maxLength: 5,
            startAdornment: currency.euro,
            type: 'number',
            autoComplete: 'off',
          },
        ],
      },
    ])
  }

  const GetDeliverySiteOptions = (): void => {
    setDeliverySiteSelectLoading(true)
    deliverySiteService()
      .getAll(GetTTDeliverySitesRequestBody(), false)
      .then(response => {
        if (response.meta.status === MetaStatusCodes.SUCCESS) {
          setDeliverySites(response.data.rows)
          const options: ComboBoxOptionModel[] = [
            {
              options: [
                [t(TTOrderTranslation.form.shipping.deliverySiteSelect.placeholder), 0],
              ],
            },
          ]
          const groupedOptions = getDeliverySitesByGroup(response.data.rows)
          groupedOptions.map(option => {
            options.push(option)
          })
          setDeliverySitesOptions(options)
        }
      })
      .finally(() => setDeliverySiteSelectLoading(false))
  }

  const onDeliverySiteChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const deliverySite = deliverySites.find(
      (ds: DeliverySiteModel) => ds.deliveryPointId === +event.target.value
    )
    if (deliverySite) {
      setSelectedDeliverySite(deliverySite)
      form.setValue('deliverySiteData', deliverySite) // update form CTX
    }
  }

  const removeSelectedDeliverySite = (): void => {
    form.setValue('deliverySiteId', 0)
    setSelectedDeliverySite(undefined)
  }

  const onNewOrEditDeliverySite = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'new' | 'edit'
  ) => {
    event.preventDefault()
    if (type === 'new')
      setDeliverySiteFormParameters({
        editMode: false,
        productCode: edenredProducts.ticketTransporte,
      })
    else
      setDeliverySiteFormParameters({
        editMode: true,
        entity: selectedDeliverySite,
        productCode: edenredProducts.ticketTransporte,
      })
    setCardOrderModalShow(true)
  }

  const onClickCancel = (): void => {
    if (ttOrderItemToEdit || comesFromSummary) {
      navigate(navigationRoutes.ttOrderSummary)
    }
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const onCreateNewCard = async (newUserCard: TTOrderModel): Promise<void> => {
    startLoading()

    const newTTCardOrderRequestBody: CardOrderRequestBody = {
      productCode: edenredProducts.ticketTransporte,
      cardOrders: [
        {
          cardData: {
            cardRequestTypeId: cardRequestTypeId.newPlastic,
            initialAmount: Number(newUserCard.cardData.initialAmount),
            spendingRuleId: 1,
          },
          employeeData: {
            birthDate: ConvertStringDateToISOOnly(newUserCard.birthDate),
            companyId: user?.code ? +user.code : 0,
            costCenter: newUserCard.costCenter,
            document: newUserCard.document,
            documentTypeId: newUserCard.documentTypeId,
            email: newUserCard.corporativeEmail,
            employeeNumber: newUserCard.employeeNumber,
            firstSurname: newUserCard.firstSurname,
            name: newUserCard.name,
            secondSurname: newUserCard.secondSurname,
            telephone: newUserCard.telephone,
          },
          sendData: {
            deliverySiteId: +newUserCard?.deliverySiteId,
          },
        },
      ],
    }

    const newUserCardCTX: TTOrderModel = {
      ...newUserCard,
      deliverySiteId: +newUserCard.deliverySiteId,
      id: ttOrderItemToEdit ? ttOrderItemToEdit.id : createOrderId(),
      orderTypeId: orderType.Card,
      companyId: user?.code ? +user.code : 0,
      birthDate: ConvertStringDateToISOOnly(newUserCard.birthDate),
      cardData: {
        ...newUserCard.cardData,
        cardRequestType: cardRequestType.newCard,
        cardRequestTypeId: cardRequestTypeId.newPlastic,
        productTypeId: edenredProducts.ticketTransporte,
      },
    }

    TTOrderService()
      .validate(newTTCardOrderRequestBody)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          if (ttOrderItemToEdit) {
            updateTTOrder(newUserCardCTX)
          } else {
            addTTOrder(newUserCardCTX)
          }
          addNotification(t(forms.success.message), NotificationSeverity.success)
          navigate(navigationRoutes.ttOrderSummary)
        } else {
          if (response?.meta.messages.length > 0) {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                form.setError(parseTTOrderFormErrorKey(error.value) as any, {
                  type: 'value',
                  message: error.description,
                })
              }
            })
          }
        }
      })
      .finally(() => stopLoading())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTtTab)
        },
        size: 'large',
      },
    ]
  }

  const fetchDeliverySiteDataWhenNull = () => {
    if (ttOrderItemToEdit) {
      if (
        ttOrderItemToEdit.deliverySiteId &&
        !ttOrderItemToEdit.deliverySiteData.deliveryPointId
      ) {
        startLoading()
        userDeliverySitesById(ttOrderItemToEdit.deliverySiteId)
          .then(deliverySiteResponseData => {
            if (deliverySiteResponseData) {
              setNewDeliverySite({ ...deliverySiteResponseData })
            }
          })
          .finally(() => stopLoading())
      }
    }
  }

  useEffect(() => {
    if (newDeliverySite) {
      // Update delivery site select
      GetDeliverySiteOptions() //This functionupdates 'deliverySitesOptions'
    }
  }, [newDeliverySite])

  useEffect(() => {
    if (newDeliverySite) {
      // get new delivery site by alias
      const newDeliverySiteFromServer = deliverySites.find(
        (ds: DeliverySiteModel) => ds.alias === newDeliverySite.alias
      )
      if (newDeliverySiteFromServer) {
        setSelectedDeliverySite(newDeliverySiteFromServer) // update card
        form.setValue('deliverySiteId', newDeliverySiteFromServer.deliveryPointId) // update select
        form.setValue('deliverySiteData', { ...newDeliverySiteFromServer }) // Set data to form CTX
      }
      return
    }
    if (ttOrderItemToEdit) {
      form.setValue('deliverySiteId', ttOrderItemToEdit.deliverySiteId) // update select
      setSelectedDeliverySite(ttOrderItemToEdit.deliverySiteData) // To show DS Card
    }
  }, [deliverySitesOptions])

  useEffect(() => {
    fetchDeliverySiteDataWhenNull()
    InitFormTextBoxes()
    GetDeliverySiteOptions()
    setCancelModalTitle(t(TTOrderTranslation.summary.cancelModal.title))
    setCancelModalDescription(t(TTOrderTranslation.summary.cancelModal.description))
  }, [])

  return {
    formTextBoxes,
    form,
    onCreateNewCard,
    deliverySitesOptions,
    onDeliverySiteChange,
    selectedDeliverySite,
    removeSelectedDeliverySite,
    deliverySiteSelectLoading,
    onNewOrEditDeliverySite,
    deliverySiteFormParameters,
    cardOrderModalShow,
    setCardOrderModalShow,
    setNewDeliverySite,
    onClickCancel,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
  }
}
