import React from 'react'
import { SimpleForm } from '../../../../Templates'
import OrderSummaryExcelContainer from './organism/OrderSummaryExcelContainer'
import { useTitleTranslation } from '../hooks/useTitleTranslation'

const OrderSummaryExcelPage = (): JSX.Element => {
  const { getExcelTitle } = useTitleTranslation()
  return (
    <SimpleForm title={getExcelTitle()} organisms={[<OrderSummaryExcelContainer />]} />
  )
}

export default OrderSummaryExcelPage
