import { OreTableRow } from '@runroom/oreneta'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { memo } from 'react'
import { Cells } from './Cells'
import type { RowModel } from '../../../../../domain/customComponents/table/TableModel'

interface Props {
  multiSelected: boolean | undefined
  rows: RowModel[]
  selectedRows: number[] | undefined
  setRows: Dispatch<React.SetStateAction<RowModel[]>>
  setSelectedRows: Dispatch<React.SetStateAction<number[]>>
  setTotalRows: Dispatch<SetStateAction<number[]>>
  confirmDelete?: boolean
  modalShow: boolean
  setModalShow: Dispatch<SetStateAction<boolean>>
  deleteConfirm: boolean
  setDeleteConfirm: Dispatch<SetStateAction<boolean>>
  pageSize?: number
  page?: number
  isServerSide?: boolean
}

export const Rows = memo(
  ({
    rows,
    pageSize,
    page,
    isServerSide,
    multiSelected,
    selectedRows,
    setRows,
    setSelectedRows,
    setTotalRows,
    modalShow,
    setModalShow,
    deleteConfirm,
    setDeleteConfirm,
    confirmDelete,
  }: Props): JSX.Element => {
    return (
      <>
        {pageSize && page && !isServerSide
          ? rows
              .slice((page - 1) * pageSize, page * pageSize)
              .map((row: any, index: number) => (
                <OreTableRow key={'row_' + index} hover={row.hover} valign={row.valign}>
                  <Cells
                    cells={row.cells}
                    rowIndex={index}
                    rowId={row.rowId}
                    multiSelected={multiSelected}
                    selectedRows={selectedRows}
                    setRows={setRows}
                    setSelectedRows={setSelectedRows}
                    setTotalRows={setTotalRows}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    deleteConfirm={deleteConfirm}
                    setDeleteConfirm={setDeleteConfirm}
                    confirmDelete={confirmDelete}
                  />
                </OreTableRow>
              ))
          : Seq(rows)
              .valueSeq()
              .map((row: RowModel, index: number) => (
                <OreTableRow key={'row_' + index} hover={row.hover} valign={row.valign}>
                  <Cells
                    cells={row.cells}
                    rowIndex={index}
                    rowId={row.rowId}
                    multiSelected={multiSelected}
                    selectedRows={selectedRows}
                    setRows={setRows}
                    setSelectedRows={setSelectedRows}
                    setTotalRows={setTotalRows}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    deleteConfirm={deleteConfirm}
                    setDeleteConfirm={setDeleteConfirm}
                    confirmDelete={confirmDelete}
                  />
                </OreTableRow>
              ))}
      </>
    )
  }
)
