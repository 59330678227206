import { OreSelect, OreSelectPlaceholder } from '@runroom/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch} from 'react';
import { memo } from 'react'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type {
  CellControlModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { useCellComboBox } from '../../hooks/table/useCellComboBox'

export const CellComboBox = memo(
  (props: {
    comboBox: CellControlModel
    rowIndex: number
    cellIndex: number
    setRows: Dispatch<React.SetStateAction<RowModel[]>>
  }): JSX.Element => {
    const { onChangeCellComboBox, ErrorMessage, IsValidValue } = useCellComboBox(
      props.rowIndex,
      props.cellIndex,
      props.setRows
    )
    return (
      <OreSelect
        name={props.comboBox.name}
        disabled={props.comboBox.disabled}
        defaultValue={props.comboBox.defaultValue}
        onChange={event => onChangeCellComboBox(event)}
        value={props.comboBox.value}
        hasError={props.comboBox.hasError}
        errorMessage={props.comboBox.errorMassage}
      >
        {props.comboBox.placeHolder && (
          <OreSelectPlaceholder label={props.comboBox.placeHolder} />
        )}
        {props.comboBox.options &&
          Seq(props.comboBox.options).map((option: ComboBoxOptionModel, index: number) =>
            props.comboBox.group ? (
              <optgroup
                key={'optgroup_' + index}
                label={t(option.groupName ? option.groupName : '')}
              >
                {Seq(option.options).map((option: [string, number], index: number) => (
                  <option
                    key={'option_' + index}
                    label={t(option[0])}
                    value={option[1]}
                  />
                ))}
              </optgroup>
            ) : (
              Seq(option.options).map((option: [string, number], index: number) => (
                <option key={'option_' + index} label={t(option[0])} value={option[1]} />
              ))
            )
          )}
      </OreSelect>
    )
  }
)
