import { Table } from 'src/presentation/components/Edenred'
import { usePendigTasksModuleController } from '../hooks'
import { TaskModel, TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import { EmptyTableImage } from 'src/presentation/components/Edenred/EmptyTableImage'
import taskcheck from '../../../../../presentation/assets/img/taskcheck.svg'
import { PendingTaskEmptyModule } from './PendingTaskEmptyModule'

interface PendingTaskCardProps {
  tasks: TaskModel[]
  title: string
  taskType: string | TaskTypeEnum
}

export const PendigTaskModule = ({
  tasks,
  title,
  taskType,
}: PendingTaskCardProps): JSX.Element => {
  const { caption, rows, setRows, taskCheckModel } = usePendigTasksModuleController(
    tasks,
    title,
    taskType
  )
  if (rows.length > 0) {
    return <Table caption={caption} rows={rows} setRows={setRows} />
  } else
    return (
      <PendingTaskEmptyModule
        image={taskcheck}
        description={taskCheckModel.description}
        title={caption.title}
        subtitle={taskCheckModel.title}
        taskType={taskType}
      />
    )
}
