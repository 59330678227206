import { useTranslation } from 'react-i18next'
import { SimpleForm } from '../../../Templates'
import React from 'react'
import { unloadTranslation } from 'src/domain/translations'
import { UnloadEmployees } from './organism'
import { LeaveOrderPrompt } from '../../Edenred/navigation'

export const UnloadEmployeesPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <SimpleForm title={t(unloadTranslation.title)} organisms={[<UnloadEmployees />]} />
      <LeaveOrderPrompt />
    </>
  )
}
