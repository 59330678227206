import {
  OreButton,
  OreHeading,
  OreInput,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useTGDBasicOrderSaveAndClose } from '../hooks/useTGDBasicOrderSaveAndClose'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { MessageBar } from 'src/presentation/components/Edenred'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  showSaveCloseModal: boolean
  setShowSaveCloseModal: Dispatch<SetStateAction<boolean>>
}

export const TGDBasicOrderSaveAndClose = ({
  showSaveCloseModal,
  setShowSaveCloseModal,
}: Props) => {
  const { t } = useTranslation()
  const { errorMessage, setStateErrorMessage, saveAndCloseTGDBasicOrder, form } =
    useTGDBasicOrderSaveAndClose()

  return (
    <OreModal
      open={showSaveCloseModal}
      handleOnClose={() => {
        setShowSaveCloseModal(false)
        setStateErrorMessage('')
      }}>
      <OreModalBox
        handleOnClose={() => {
          setShowSaveCloseModal(false)
          setStateErrorMessage('')
        }}>
        <form autoComplete="off">
          <OreModalBoxBody>
            <OreHeading size="headline-lg">
              {t(cardOrderTranslation.cardOrderHome.modalSaveTitle)}
            </OreHeading>
            <OreText>{t(cardOrderTranslation.cardOrderHome.modalSaveBody)}</OreText>
            <OreInput
              {...form.register('orderName')}
              id="orderName"
              label={t(cardOrderTranslation.cardOrderHome.modalSaveInputDescription)}
              name="orderName"
              hasError={!!form.formState.errors?.orderName}
              errorMessage={form.formState.errors?.orderName?.message}
              placeholder="Ej: FC98567"
              required
            />
          </OreModalBoxBody>
          <MessageBar color="error" message={errorMessage} />
          <OreModalBoxFooter>
            <div className="ml-auto">
              <OreButton
                category="primary"
                size="large"
                onClick={form.handleSubmit(saveAndCloseTGDBasicOrder)}>
                {t(forms.buttons.saveAndClose)}
              </OreButton>
            </div>
          </OreModalBoxFooter>
        </form>
      </OreModalBox>
    </OreModal>
  )
}
