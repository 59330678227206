import {
  OreButton,
  OreCardActions,
  OreCardContainer,
  OreCardWrapper,
  OreHeading,
  OreStack,
  OreText,
  SvgBin,
  SvgChange,
  SvgPlus,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { KindergartenCard } from 'src/presentation/components/Edenred/kindergarten/KindergartenCard'
import { TGDOrderSearchKindergarten } from './TGDOrderSearchKindergarten'
import { Modal } from 'src/presentation/components/Edenred'
import { useTGDOrderFormKindergartenAssignment } from '../hooks/useTGDOrderFormKindergartenAssignment'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import { useFormContext } from 'react-hook-form'
import { TGDEmployeeAndChildsModel } from 'src/domain/models/TGDOrder/TGDOrderModel'

interface Props {
  childIndex: number
}

export const TGDOrderFormKindergartenAssignment = ({ childIndex }: Props) => {
  const { t } = useTranslation()
  const searchKindergartenProps = useTGDOrderFormKindergartenAssignment(false, childIndex)
  const { watch, setValue: setKindergartenValueToForm } =
    useFormContext<TGDEmployeeAndChildsModel>()

  const formValues = watch()

  return (
    <>
      {!formValues.childs[childIndex].kindergartenData && (
        <OreCardContainer>
          <OreCardWrapper>
            <OreStack>
              <OreHeading size="headline-sm">
                {t(TGDOrderTranslation.kindergartenAssignment.emptyTitle)}
              </OreHeading>
              <OreText size="text-md">
                {t(TGDOrderTranslation.kindergartenAssignment.emptySubtitle)}
              </OreText>
            </OreStack>
          </OreCardWrapper>
          <OreCardActions>
            <OreStack direction="row" placeItems="start">
              <OreButton
                size="large"
                category="tertiary"
                icon={<SvgPlus />}
                onClick={() => {
                  searchKindergartenProps.setNurseryList([])
                  searchKindergartenProps.setShowPopup(true)
                }}>
                {t(TGDOrderTranslation.kindergartenAssignment.selectButton)}
              </OreButton>
            </OreStack>
          </OreCardActions>
        </OreCardContainer>
      )}

      {formValues.childs[childIndex].kindergartenData && (
        <KindergartenCard kindergarten={formValues.childs[childIndex].kindergartenData}>
          <OreStack direction="row" placeItems="start">
            <OreButton
              size="large"
              category="tertiary"
              icon={<SvgBin />}
              onClick={() => {
                searchKindergartenProps.setKindergartenSelected({} as KindergartenModel)
                setKindergartenValueToForm(
                  `childs.${childIndex}.kindergartenData`,
                  undefined
                )
                setKindergartenValueToForm(`childs.${childIndex}.kindergartenId`, 0)
              }}>
              {t(TGDOrderTranslation.kindergartenAssignment.deleteSelection)}
            </OreButton>
            <OreButton
              size="large"
              category="tertiary"
              icon={<SvgChange />}
              onClick={() => {
                searchKindergartenProps.setKindergartenSelected({} as KindergartenModel)
                searchKindergartenProps.setNurseryList([])
                searchKindergartenProps.setShowPopup(true)
              }}>
              {t(TGDOrderTranslation.kindergartenAssignment.changeSelection)}
            </OreButton>
          </OreStack>
        </KindergartenCard>
      )}

      <Modal
        handleOnClose={searchKindergartenProps.setShowPopup}
        open={searchKindergartenProps.popupShow}
        buttons={searchKindergartenProps.popupButtons}
        size="extralarge"
        children={
          <TGDOrderSearchKindergarten searchKindergartenProps={searchKindergartenProps} />
        }
      />
    </>
  )
}
