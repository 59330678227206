import {
  employeeConfirmContractReceived,
  getEmployeeConfiguration,
  getEmployeeFlexibleRenumeration,
  indexEmployeeList,
  indexEmployeesPendingAdherenceList,
  saveEmployeeState,
} from 'src/config/constants/endpoints'

import type {
  EmployeeRepositoryContract,
  EmployeeModel,
  CreateEmployeeDTO,
  EmployeeConfigurationModel,
  SaveEmployeeDTO,
  DeleteEmployeeDTO,
  SaveEmployeeStateDTO,
  EmployeeRenumerationCalculatorModel,
  ConfirmReceivedContractDTO,
} from 'src/Flex/Employees/domain'
import type { HttpModel, PaginationModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { createQueryString } from 'src/core/helpers'
import { FilterResultModel, convertFiltersToQuery } from 'src/Flex/Shared/domain'
import { documentType } from 'src/domain/enum'
import { NovationContractSignState } from 'src/domain'

type ApiEmployee = {
  id: string
  collective: string
  collectiveId: string
  communicationDate: null
  active: boolean
  hasISaludActiveHires: boolean
  userFirstName: string
  userLastName: string
  userLastName2: string
  documentType: number
  document: string
  birthday: string
  email: string
  telephone: string
  costCenter: string
  employeeNumber: string
  socialSecurityNumber: string
  workingPercentage: number
  annualGrossSalary: number
  salaryInKind: number
  irpf: number
  deliverySiteId: number
  novationContractSignState: NovationContractSignState
}

const convertToDomain = (
  response: HttpModel<ApiEmployee | null>
): EmployeeConfigurationModel => ({
  userFirstName: response.data?.userFirstName ?? '',
  userLastName: response.data?.userLastName ?? '',
  userLastName2: response.data?.userLastName2 ?? '',
  documentType:
    response.data?.documentType &&
    Object.values(documentType).includes(response.data.documentType)
      ? response.data.documentType
      : documentType.Default,
  document: response.data?.document ?? '',
  birthday: response.data?.birthday ? new Date(response.data?.birthday) : undefined,
  email: response.data?.email ?? '',
  telephone: response.data?.telephone ?? '',
  costCenter: response.data?.costCenter ?? '',
  employeeNumber: response.data?.employeeNumber ?? '',
  collectiveId: response.data?.collectiveId ?? '',
  socialSecurityNumber: response.data?.socialSecurityNumber ?? '',
  workingPercentage: response.data?.workingPercentage ?? 0,
  annualGrossSalary: response.data?.annualGrossSalary ?? 0,
  salaryInKind: response.data?.salaryInKind ?? 0,
  irpf: response.data?.irpf ?? 0,
  acceptConditions: false,
  active: response.data?.active ?? false,
  hasISaludActiveHires: response.data?.hasISaludActiveHires ?? false,
  deliverySiteId: response.data?.deliverySiteId ?? 0,
  novationContractSignState:
    response.data?.novationContractSignState ?? NovationContractSignState.NotStart,
})

export class EmployeeRepository implements EmployeeRepositoryContract {
  async GetEmployees(
    pageNumber: number,
    numberOfRecordsPerPage: number,
    search?: string,
    filters?: FilterResultModel[]
  ): Promise<HttpModel<PaginationModel<EmployeeModel>>> {
    return await axiosFetch(
      indexEmployeeList.concat(
        createQueryString({
          numberOfRecordsPerPage,
          pageNumber: pageNumber - 1,
          search,
          ...convertFiltersToQuery(filters),
        })
      ),
      HttpMethod.GET,
      {}
    )
  }

  async GetEmployeeConfiguration(
    id: string
  ): Promise<HttpModel<EmployeeConfigurationModel>> {
    const response: HttpModel<ApiEmployee | null> = await axiosFetch(
      getEmployeeConfiguration.concat(createQueryString({ id })),
      HttpMethod.GET,
      {}
    )

    return {
      data: convertToDomain(response),
      meta: response.meta,
    }
  }

  async CreateEmployee(data: EmployeeConfigurationModel): Promise<CreateEmployeeDTO> {
    return await axiosFetch(indexEmployeeList, HttpMethod.POST, data)
  }

  async SaveEmployee(data: EmployeeConfigurationModel): Promise<SaveEmployeeDTO> {
    return await axiosFetch(indexEmployeeList, HttpMethod.PUT, data)
  }

  async DeleteEmployee(id: string): Promise<DeleteEmployeeDTO> {
    return await axiosFetch(
      indexEmployeeList.concat(
        createQueryString({
          id,
        })
      ),
      HttpMethod.DELETE,
      {}
    )
  }

  async SaveEmployeeStatus(id: string): Promise<SaveEmployeeStateDTO> {
    return await axiosFetch(
      saveEmployeeState.replace('{employeeId}', id),
      HttpMethod.PUT,
      {}
    )
  }

  async GetFlexibleRenumerationAmount({
    workingPercentage,
    annualGrossSalary,
    salaryInKind,
    collectiveFlexibleRemunerationPercentage,
  }: EmployeeRenumerationCalculatorModel): Promise<HttpModel<number>> {
    return await axiosFetch(
      getEmployeeFlexibleRenumeration.concat(
        createQueryString({
          workingPercentage,
          annualGrossSalary,
          salaryInKind,
          collectiveFlexibleRemunerationPercentage:
            collectiveFlexibleRemunerationPercentage,
        })
      ),
      HttpMethod.GET,
      {}
    )
  }

  async ConfirmReceivedContract(id: string): Promise<ConfirmReceivedContractDTO> {
    return await axiosFetch(
      employeeConfirmContractReceived.replace('{employeeId}', id),
      HttpMethod.PUT,
      {}
    )
  }

  async GetEmployeesPendingAdherence(
    page: number,
    numberOfRecordsPerPage: number,
    search?: string,
    filters?: FilterResultModel[]
  ): Promise<HttpModel<PaginationModel<EmployeeModel>>> {
    return await axiosFetch(
      indexEmployeesPendingAdherenceList.concat(
        createQueryString({
          numberOfRecordsPerPage,
          pageNumber: page - 1,
          search,
          ...convertFiltersToQuery(filters),
        })
      ),
      HttpMethod.GET,
      {}
    )
  }
}
