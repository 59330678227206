import type { DeliverySiteGetByIdDTO, DeliverySiteModel } from '../models/deliverySites'

export const deliverySiteGetByIdAdapter = (
  DeliverySiteDTO: DeliverySiteGetByIdDTO
): DeliverySiteModel => {
  const deliverySite: any = { ...DeliverySiteDTO.data }

  if (deliverySite) {
    Object.entries(deliverySite).forEach(([key, value]) => {
      if (value === null) {
        deliverySite[key] = ''
      }
    })
  }

  return deliverySite as DeliverySiteModel
}
