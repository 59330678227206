import type { StepsForModel } from './Stepper'
import { cardOrderTranslation } from '../../../../domain/translations'
import { navigationRoutes } from '../../../../config/constants/navigationRoutes'

export const OrderAndDuplicateStepsData: StepsForModel = {
  steps: [
    cardOrderTranslation.stepper.firstStep,
    cardOrderTranslation.stepper.secondStep,
    cardOrderTranslation.stepper.thirdStep,
    cardOrderTranslation.stepper.fourthStep,
  ],
  allowedPaths: [
    navigationRoutes.cardOrderHome,
    navigationRoutes.cardOrderNew,
    navigationRoutes.orderCheckout,
    navigationRoutes.cardOrderCompleteSuccess,
    navigationRoutes.cardOrderCompleteError,
    navigationRoutes.cardOrderDuplicated,
    navigationRoutes.duplicatedCard,
    navigationRoutes.cardOrderDuplicateConfiguration,
    navigationRoutes.cardOrderDuplicateConfigurationEdit,
  ],
}

export const RechargeAndUnloadStepsData: StepsForModel = {
  steps: [
    cardOrderTranslation.stepper.firstStep,
    cardOrderTranslation.stepper.secondStep,
    cardOrderTranslation.stepper.thirdStep,
    cardOrderTranslation.stepper.fourthStep,
  ],
  allowedPaths: [
    navigationRoutes.rechargeHome,
    navigationRoutes.unloadHome,
    navigationRoutes.rechargeCard,
    navigationRoutes.unloadCard,
    navigationRoutes.cardOrderRechargeConfiguration,
    navigationRoutes.cardOrderRechargeConfigurationEdit,
    navigationRoutes.cardOrderUnloadConfiguration,
    navigationRoutes.cardOrderUnloadConfigurationEdit,
  ],
}

export const OrderExcelStepsData: StepsForModel = {
  steps: [
    cardOrderTranslation.stepper.firstStep,
    cardOrderTranslation.stepper.secondStep,
    cardOrderTranslation.stepper.thirdStep,
    cardOrderTranslation.stepper.fourthStep,
  ],
  stepsByPaths: [
    {
      step: cardOrderTranslation.stepper.firstStep,
      paths: [
        navigationRoutes.cardOrderNewExcel,
        navigationRoutes.cardOrderRechargeExcel,
        navigationRoutes.cardOrderExcelAlerts,
        navigationRoutes.cardOrderUnloadExcel,
      ],
    },
    {
      step: cardOrderTranslation.stepper.secondStep,
      paths: [navigationRoutes.cardOrderExcelHome],
    },
    {
      step: cardOrderTranslation.stepper.thirdStep,
      paths: [navigationRoutes.orderCheckout],
    },
    {
      step: cardOrderTranslation.stepper.fourthStep,
      paths: [
        navigationRoutes.cardOrderCompleteSuccess,
        navigationRoutes.cardOrderCompleteError,
      ],
    },
  ],
}
