import {
  OreHeading,
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreTooltip,
} from '@runroom/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { TTOrderConfigurationEmployeeState } from '../hooks/useTTOrderConfigurationEmployees'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { TTOrderConfigurationEmployeesRow } from './TTOrderConfigurationEmployeesRow'
import { FormProvider } from 'react-hook-form'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'

interface Props {
  employeesTTProps: TTOrderConfigurationEmployeeState
}

export const TTOrderConfigurationEmployeesList = ({
  employeesTTProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employeesTT,
    onPageChange,
    page,
    pageSize,
    selectedEmployees,
    onSelectEmployee,
    isSelected,
    showSelected,
    form,
    updateDataRowToTTOrderEmployee,
    deliverySitesOptions,
    deliverySites,
  } = employeesTTProps

  return (
    <>
      <FormProvider {...form}>
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <OreTableContainer>
            <div className="bg-white px-1 py-105">
              <OreHeading size="headline-md">
                {t(TGDOrderTranslation.employees.multipleSelect.title)}
              </OreHeading>
            </div>
            <OreTable>
              <OreTableHead>
                <OreTableRow>
                  {header.headerElement && (
                    <OreTableCell as="th">{header.headerElement}</OreTableCell>
                  )}
                  {header.headerLabel.map((header, index) =>
                    !header.isSortable ? (
                      <OreTableCell as="th" key={'lbl' + index}>
                        {header.label}&nbsp;
                        {header.tooltip && (
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        )}
                      </OreTableCell>
                    ) : (
                      <OreTableCell as="th" key={'lbl' + index}>
                        <OreTableSortLabel
                          active
                          direction={header.sortDirection}
                          onClick={header.onClick}>
                          {header.label}&nbsp;
                          {header.tooltip && (
                            <OreTooltip
                              placement="top"
                              separation="small"
                              text={header.tooltip}>
                              <span>{header.tooltipIcon}</span>
                            </OreTooltip>
                          )}
                        </OreTableSortLabel>
                      </OreTableCell>
                    )
                  )}
                </OreTableRow>
              </OreTableHead>
              <OreTableBody as="tbody" valign="top">
                {employeesTT && employeesTT.length <= 0 ? (
                  <TableEmpty colSpan={7} firstText={t(forms.table.emptyMessage)} />
                ) : (
                  employeesTT
                    .slice((page - 1) * pageSize, page * pageSize)
                    .map((employeeTT: TTOrderModel, index: number) => {
                      return (
                        <TTOrderConfigurationEmployeesRow
                          key={'rows' + index}
                          employeeTT={employeeTT}
                          isSelected={isSelected}
                          onSelectEmployee={onSelectEmployee}
                          index={index}
                          form={form}
                          updateDataRowToTTOrderEmployee={updateDataRowToTTOrderEmployee}
                          deliverySitesOptions={deliverySitesOptions}
                          deliverySites={deliverySites}
                        />
                      )
                    })
                )}
              </OreTableBody>
            </OreTable>
            <OreTableActions>
              <OrePagination
                count={showSelected ? selectedEmployees.length : totalRows}
                labelNextPage={t(forms.pagination.next)}
                labelPreviousPage={t(forms.pagination.back)}
                onPageChange={onPageChange}
                page={page}
                rowsPerPage={pageSize}
                text={t(forms.pagination.of)}
              />
            </OreTableActions>
          </OreTableContainer>
        </form>
      </FormProvider>
    </>
  )
}
