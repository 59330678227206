import { HttpModel } from 'src/domain/models'
import { FlexDataModel, UserRepository } from 'src/Flex/User/domain'

interface IUserContract {
  getInitialFlexData: () => Promise<HttpModel<FlexDataModel>>
}

export const UserContract = (userRepository: UserRepository): IUserContract => ({
  getInitialFlexData: async (): Promise<HttpModel<FlexDataModel>> => {
    return await userRepository.GetInitialFlexData()
  },
})
