import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { invoicesTabs } from '../../../../domain/enum'
import type { IInvoicesTabModel } from '../../../../domain/models/navigate/invoicesTabModel'

interface InvoicesPageState {
  invoiceTab: invoicesTabs | undefined
}

export const useInvoicePageController = (): InvoicesPageState => {
  const { state } = useLocation()
  const [invoiceTab, setInvoiceTab] = useState<invoicesTabs>()

  useEffect(() => {
    if (state) {
      const tab = state as IInvoicesTabModel
      setInvoiceTab(tab.navigateValue)
    }
  }, [state])

  return { invoiceTab: invoiceTab }
}
