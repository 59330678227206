import React from 'react'
import { Divider } from 'src/presentation/layout'
import { AccountNotification } from '../molecules/AccountNotification'

export const AccountNotificationsContainer = (): JSX.Element => {
  return (
    <>
      <Divider marginTop="1.5rem" marginBottom="3rem" />
      <div className="account-container--center">
        <AccountNotification />
      </div>
    </>
  )
}
