import { Seq } from 'immutable'
import { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TextSectionModel } from 'src/domain/models'

export interface SectionState {
  section: JSX.Element
}

export const useSectionController = (sectionText: TextSectionModel[]): SectionState => {
  const [section, setSection] = useState<JSX.Element>(<></>)

  const CreateLinkElementSection = (
    section: TextSectionModel,
    index: number
  ): JSX.Element => {
    if (section.link)
      return (
        <NavLink key={'link_' + index} to={section.link} className="underline">
          {section.text}
        </NavLink>
      )
    return <></>
  }
  const CreateElementSection = (
    section: TextSectionModel,
    index: number
  ): JSX.Element => {
    if (section.isBold && !section.link)
      return <strong key={'strong_' + index}>{section.text}</strong>
    if (!section.isBold && !section.link) return <>{section.text}</>
    return <></>
  }
  const CreateSection = (): JSX.Element => {
    return (
      <>
        {Seq(sectionText).map((sectionElement: TextSectionModel, index: number) => (
          <Fragment key={index}>
            {CreateElementSection(sectionElement, index)}
            {CreateLinkElementSection(sectionElement, index)}
          </Fragment>
        ))}
      </>
    )
  }
  useEffect(() => {
    setSection(CreateSection())
  }, [])

  return { section }
}
