export interface GeocoderResult {
  address_components: AddressComponent[]
  formatted_address: string
  geometry: GeocoderGeometry
  place_id: string
  types: string[]
}

interface GeocoderGeometry {
  bounds?: any
  location: LatLng
  location_type: string
  viewport?: any
}

interface LatLng {
  lat: () => number
  lng: () => number
}

interface LatLngBounds {
  northeast: LatLng
  southwest: LatLng
}

export interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

export enum GeocoderStatus {
  ERROR = 'ERROR',
  INVALID_REQUEST = 'INVALID_REQUEST',
  OK = 'OK',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
  REQUEST_DENIED = 'REQUEST_DENIED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
}

export type GeocoderResponseResult = GeocoderResult[] | null

export type GeocoderResponseStatus = keyof typeof GeocoderStatus
