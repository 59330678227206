import { useTranslation } from 'react-i18next'
import { GetDateFormatted, dniValidate, maxAge, minAge } from 'src/core/helpers'
import { currency } from 'src/domain/enum'
import { forms, spendingRuleFormTranslation } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import * as Yup from 'yup'

export const useTTOrderFormSchema = () => {
  const { t } = useTranslation()

  const minAmount = 0.1
  const maxAmount = 1500

  const TTOrderFormSchema = Yup.object({
    name: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(20, t(forms.errors.maxAllowed)),
    firstSurname: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(20, t(forms.errors.maxAllowed)),
    secondSurname: Yup.string().max(20, t(forms.errors.maxAllowed)),
    documentTypeId: Yup.number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .required(t(forms.errors.fieldRequired))
      .min(1, t(forms.errors.fieldRequired)),
    document: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(14, t(forms.errors.maxAllowed))
      .test('dniTestValidate', t(forms.errors.invalidFormat), (value, ctx) => {
        let isValid = true
        if (ctx.parent.documentTypeId < 2) {
          isValid = dniValidate(value)
        }
        if (ctx.parent.documentTypeId === 2 && value) {
          return value.match(/^[a-z\d\-_\s]+$/) === null ? true : false
        }
        return isValid
      }),
    birthDate: Yup.date()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required(t(forms.errors.fieldRequired))
      .max(
        new Date(minAge()),
        t(TGDOrderTranslation.form.child.errors.validateMaxDateBirth) +
          GetDateFormatted(minAge())
      )
      .test(
        'validateMinDateBirth',
        t(TGDOrderTranslation.form.child.errors.validateMinDateBirth) +
          GetDateFormatted(maxAge()),
        value => {
          if (value) {
            const maximumAge = new Date(maxAge())
            const maximunAgeNew = maximumAge.setDate(maximumAge.getDate() - 1)
            return (new Date(maximunAgeNew) <= value) as boolean
          }
          return true
        }
      ),
    corporativeEmail: Yup.string()
      .email(t(forms.errors.invalidFormat))
      .required(t(forms.errors.fieldRequired)),
    telephone: Yup.string().test('validatePhone', t(forms.errors.phoneInvalid), value => {
      if (value && value.length > 0) return /(6|7|8|9)[ -]*([0-9][ -]*){8}$/.test(value)
      return true
    }),
    costCenter: Yup.string().nullable().max(50, t(forms.errors.maxAllowed)),
    employeeNumber: Yup.string().nullable().max(10, t(forms.errors.maxAllowed)),
    cardData: Yup.object().shape({
      initialAmount: Yup.number()
        .transform(value => (Number.isNaN(value) ? null : value))
        .nullable()
        .min(
          minAmount,
          `${t(spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual)} ${
            minAmount + currency.euro
          }`
        )
        .max(
          maxAmount,
          `${t(spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual)}  ${
            maxAmount + currency.euro
          }`
        )
        .test(
          'decimal-max-2-places',
          t(spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals),
          value => {
            const isNullable = value === null || value === undefined
            if (isNullable) {
              return true
            }
            const regex = /^\d+(\.\d{1,2})?$/
            return regex.test(value?.toString())
          }
        ),
    }),
    deliverySiteId: Yup.string().test(
      'fieldRequired',
      t(forms.errors.fieldRequired),
      value => {
        if (value && +value > 0) return true
        return false
      }
    ),
  })

  return {
    TTOrderFormSchema,
  }
}
