import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@runroom/oreneta'
import { forms } from 'src/domain/translations'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { EmployeeModel } from 'src/Flex/Employees/domain'
import { useEmployeesPendingAdherence } from '../contexts/EmployeesPendingAdherenceContext'

export type EmployeeStatusModalActions = {
  open: (employee: EmployeeModel) => void
}

export const EmployeeStatusModal = forwardRef<EmployeeStatusModalActions>(
  (_, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [employee, setEmployee] = useState<EmployeeModel>()
    const { t } = useTranslation()
    const { toggleState, loadEmployees } = useEmployeesPendingAdherence()

    useImperativeHandle(ref, () => ({
      open: async (employee): Promise<void> => {
        setEmployee(employee)
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      if (!employee) return
      toggleState(employee).then(response => {
        if (response) {
          setOpen(false)
        }
      })
      loadEmployees()
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexEmployeesTranslations.unsubscribe.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(flexEmployeesTranslations.unsubscribe.description)}
              </OreText>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="danger">
              {t(flexEmployeesTranslations.unsubscribe.cta)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
