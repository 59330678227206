import { communicationsTemplatesService } from 'src/Flex/CommunicationsTemplates/application/communicationsTemplatesService'
import { TemplateModel } from 'src/Flex/CommunicationsTemplates/domain'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEffect, useState } from 'react'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { flexCommunicationsTemplatesTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'

export interface IUseTemplateController {
  numberOfRecordsPerPage: number
  onPageChange: (page: number) => void
  page: number
  templates: TemplateModel[]
  count: number
  deleteTemplate: (id: TemplateModel) => Promise<boolean>
}

export const useTemplateController = (): IUseTemplateController => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const numberOfRecordsPerPage = 8
  const [page, setPage] = useState<number>(1)
  const [templates, setTemplates] = useState<TemplateModel[]>([])
  const [count, setCount] = useState<number>(0)
  const { t } = useTranslation()

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const loadTemplates = async (page: number): Promise<void> => {
    startLoading()

    const response = await communicationsTemplatesService().GetTemplates(
      page,
      numberOfRecordsPerPage
    )

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setTemplates(response.data.rows)
      setCount(response.data.totalRows)
    }

    stopLoading()
  }

  const deleteTemplate = async (item: TemplateModel): Promise<boolean> => {
    startLoading()

    const response = await communicationsTemplatesService().DeleteTemplateById(item.id)

    stopLoading()

    const valid = handleMetaResponse(response?.meta, undefined, {
      successMessage: t(flexCommunicationsTemplatesTranslations.deleteModal.success),
    })

    if (valid) {
      if (templates.length === 1 && (page ?? 0) > 1) {
        setPage(page => (page ?? 0) - 1)
      } else {
        await loadTemplates(page)
      }
    }

    return valid
  }

  useEffect(() => {
    loadTemplates(page)
  }, [page])

  return {
    numberOfRecordsPerPage,
    onPageChange,
    page,
    templates,
    count,
    deleteTemplate,
  }
}
