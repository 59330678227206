import { TFunction } from 'react-i18next'
import { RequiredStringSchema } from 'yup/lib/string'
import { ChargePayrollMonthOptions } from 'src/Flex/Orders/domain'
import { nonEmptyStringSchema } from 'src/Flex/Shared/ui/Form'
import { forms } from 'src/domain/translations'

export const chargePayrollSchema = (
  t: TFunction<'translation', undefined>
): RequiredStringSchema<string | undefined> =>
  nonEmptyStringSchema(t).oneOf(
    [ChargePayrollMonthOptions.Current, ChargePayrollMonthOptions.Next],
    t(forms.errors.fieldRequired)
  )
