import { t } from 'i18next'
import { historyTranslation } from '../../../domain/translations/history/history'
import { PageHeader } from '../../Templates/PageHeader'
import { useHistoryPageController } from './hooks/useHistoryPageController'
import { HistoryContainer } from './organism/HistoryContainer'
import { edenredProducts } from 'src/domain/enum'

interface Props {
  product: edenredProducts
}

export const HistoryPage = ({ product }: Props): JSX.Element => {
  const { historyTab } = useHistoryPageController()
  return (
    <PageHeader
      title={t(historyTranslation.title)}
      organism={<HistoryContainer navigateValue={historyTab} product={product} />}
    />
  )
}
