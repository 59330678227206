import { Seq } from 'immutable'
import {
  cardRequestTypeId,
  cardType,
  deliveryType,
  documentType,
  edenredProducts,
  orderType,
} from '../../../../domain/enum'
import type {
  CardOrderModel,
  DeliverySiteModel,
  SpendingRuleModel,
} from '../../../../domain/models'
import type {
  DeliverySiteProtocol,
  SpendingRuleGetAllProtocol,
} from '../../../../domain/protocols'

export const GetDeliverySitesRequestBody = (): DeliverySiteProtocol => {
  return {
    productCode: edenredProducts.ticketRestaurant,
    particularAdress: true,
    companyAdress: true,
    otherAdress: true,
  }
}

export const GetTTDeliverySitesRequestBody = (): DeliverySiteProtocol => {
  return {
    productCode: edenredProducts.ticketTransporte,
    particularAdress: true,
    companyAdress: true,
    otherAdress: true,
  }
}

export const GetSpendingRulesRequestBody = (): SpendingRuleGetAllProtocol => {
  return { productCode: edenredProducts.ticketRestaurant }
}

export const GetInitialValues = (): CardOrderModel => {
  return {
    orderId: 0,
    orderTypeId: orderType.Card,
    employeeData: {
      birthDate: '',
      companyId: 0,
      costCenter: '',
      document: '',
      documentTypeId: documentType.Default,
      email: '',
      employeeNumber: '',
      firstSurname: '',
      name: '',
      secondSurname: '',
      telephone: '',
    },
    cardData: {
      cardTypeId: cardType.Virtual,
      cardRequestTypeId: cardRequestTypeId.default,
      expiredDate: '',
      initialAmount: null,
      productTypeId: edenredProducts.ticketRestaurant,
      spendingRuleId: 0,
      testSpendingRule: '',
    },
    sendData: {
      alias: '',
      city: '',
      contactName: '',
      deliverySiteId: 0,
      deliveryTypeId: deliveryType.Default,
      email: '',
      name: '',
      observation: '',
      province: '',
      streetDescription: '',
      streetName: '',
      streetNumber: '',
      telephone: '',
      zipCode: '',
    },
  }
}

export const SetDeliverySiteInfo = (
  deliverySites: DeliverySiteModel[] | undefined,
  cardOrder: CardOrderModel
): CardOrderModel => {
  if (deliverySites) {
    const deliverySite: DeliverySiteModel | undefined = Seq(deliverySites)
      .filter(
        (deliverySite: DeliverySiteModel) =>
          deliverySite.deliveryPointId === cardOrder.sendData?.deliverySiteId
      )
      .get(0)
    if (deliverySite && cardOrder.sendData) {
      cardOrder.sendData.alias = deliverySite.alias
      cardOrder.sendData.name = deliverySite.deliveryPointName
      cardOrder.sendData.city = deliverySite.city
      cardOrder.sendData.contactName = deliverySite.contactName
      cardOrder.sendData.deliveryTypeId = deliverySite.deliveryType
      cardOrder.sendData.email = deliverySite.email
      cardOrder.sendData.observation = deliverySite.observation
      cardOrder.sendData.province = deliverySite.province
      cardOrder.sendData.streetDescription = deliverySite.streetDescription
      cardOrder.sendData.streetName = deliverySite.streetName
      cardOrder.sendData.streetNumber = deliverySite.streetNumber
      cardOrder.sendData.telephone = deliverySite.telephone
      cardOrder.sendData.zipCode = deliverySite.zipCode
    }
  }
  return cardOrder
}

export const GetDefaultSpendingRule = (
  spendingRules: SpendingRuleModel[]
): number | undefined => {
  return Seq(spendingRules)
    .filter((spendingRule: SpendingRuleModel) => spendingRule.isDefault === true)
    .get(0)?.profileId
}
