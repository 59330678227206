import { useTGDOrderNewChildFormController } from './hooks'
import { TGDOrderForm } from './organism'

export const TGDOrderNewChildPage = (): JSX.Element => {
  const { tgdOrderItemEmployee } = useTGDOrderNewChildFormController()

  if (!tgdOrderItemEmployee) {
    return <></>
  }

  return <TGDOrderForm isChildForm isNew tgdOrderItemToEdit={tgdOrderItemEmployee} />
}
