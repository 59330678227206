export enum SpendingRulesType {
  noRestriction = 0,
  byAmount = 1,
  byTransaction = 2,
  timezones = 3, // Diet
  //0=No Restrictions, 1=Amount restriction, 2=Transaction, 3=Timezones
}

export enum SpendingRulesTRType {
  TRCard = 'card',
  TRDiet = 'diet',
}
