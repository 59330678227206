import { TGDBasicOrderRepository } from 'src/infrastructure/repository/TGDOrder/TGDBasicOrderRepository'
import { RepositoryFactory } from '../factories'
import { Repositories, edenredProducts, edenredSubProducts } from 'src/domain/enum'
import { UserContract } from 'src/domain/models'

export const TGDBasicOrderService = (): TGDBasicOrderRepository => {
  return RepositoryFactory(Repositories.tgdBasicOrder)
}

export const getTGDSubProductByContracts = (contracts?: UserContract[]): number => {
  // filter contract that contains poductCode equal to ticketGuarderia
  const contract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketGuarderia
  )
  if (contract) {
    if (contract?.subProductId === edenredSubProducts.guarderiaPlus) {
      return edenredSubProducts.guarderiaPlus
    }
    return edenredSubProducts.guarderia
  }
  return 0 // No TGD contractFound
}
