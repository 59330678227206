import { Header, Sidebar } from '../components/Edenred/navigation'
import { useUI } from '../context/ui/UIProvider'
import cx from 'classnames'
import { Grid } from '../components/Edenred/layout'
import { Outlet } from 'react-router-dom'

export const AppLayout = (): JSX.Element => {
  const { sidebarCollapsed } = useUI()

  return (
    <>
      <Header />
      <Grid>
        <Sidebar />
        <div
          className={cx('content-layout', {
            'content-layout--collapsed': !sidebarCollapsed,
          })}>
          <main className="main-page">
            <Outlet />
          </main>
        </div>
      </Grid>
    </>
  )
}
