import {
  OreButton,
  OreCheckbox,
  OreInput,
  OreStack,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgChange,
  SvgPlus,
} from '@runroom/oreneta'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetDateFormatted } from 'src/core/helpers'
import { currency } from 'src/domain/enum'
import { EmployeesTGDOrderModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { forms } from 'src/domain/translations'
import { UseFormReturn } from 'react-hook-form'
import { EmployeesTGDOrderFormModel } from '../hooks/useTGDOrderConfigurationEmployees'
import { Modal } from 'src/presentation/components/Edenred'
import { TGDOrderSearchKindergarten } from '../../TGDOrderForm/molecules/TGDOrderSearchKindergarten'
import { useTGDOrderFormKindergartenAssignment } from '../../TGDOrderForm/hooks'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import { addComma, getUndefinedEmptyString } from 'src/core/services'

interface Props {
  employeeTGD: EmployeesTGDOrderModel
  selectedEmployees: EmployeesTGDOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesTGDOrderModel[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesTGDOrderModel
  ): void
  isSelected(employee: EmployeesTGDOrderModel): boolean
  form: UseFormReturn<EmployeesTGDOrderFormModel, any>
  rowId: number
  page: number
  pageSize: number
  updateKindergartenToEmployee: (kindergarten: KindergartenModel, childId: number) => void
}

export const TGDOrderConfigurationEmployeesRow = ({
  employeeTGD,
  updateKindergartenToEmployee,
  onSelectEmployee,
  isSelected,
  form,
  rowId,
  page,
  pageSize,
}: Props): JSX.Element => {
  const searchKindergartenProps = useTGDOrderFormKindergartenAssignment(false, rowId)
  const { t } = useTranslation()
  // const [initialAmount, setInitialAmount] = useState<number | undefined>()
  const GetCurrentIndex = (index: number): number => {
    return index + (page - 1) * pageSize
  }

  const setInitialAmountValue = (index: number, amount: number) => {
    form.setValue(`employeesTGD.${index}.userSon.initialAmount`, amount)
    employeeTGD.userSon.initialAmount = amount
  }
  const setCostCenterValue = (index: number, costCenter: string) => {
    form.setValue(`employeesTGD.${index}.userSon.costCenter`, costCenter)
    employeeTGD.userSon.costCenter = costCenter
  }

  useEffect(() => {
    if (searchKindergartenProps.kindergartenSelected) {
      updateKindergartenToEmployee(
        searchKindergartenProps.kindergartenSelected,
        employeeTGD.userSon.userId
      )
    }
  }, [searchKindergartenProps.kindergartenSelected])
  const index = GetCurrentIndex(rowId)
  return (
    <>
      <OreTableRow valign="top">
        <OreTableCell align="left" as="td" size="auto">
          <OreCheckbox
            name="check"
            onChange={event => onSelectEmployee(event, employeeTGD)}
            role="checkbox"
            aria-checked={isSelected(employeeTGD)}
            checked={isSelected(employeeTGD)}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTGD.userName}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTGD.userDni}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTGD.userSon.userFirstName +
              ' ' +
              employeeTGD.userSon.userLastName +
              ' ' +
              (employeeTGD.userSon.userLastName2
                ? employeeTGD.userSon.userLastName2
                : '')}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTGD.userSon.userBirthdayDate &&
              GetDateFormatted(employeeTGD.userSon.userBirthdayDate)}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {employeeTGD.userSon.lastNurseryName ? (
            <OreStack direction="column" space="xsmall">
              <>
                <OreText size="text-sm" tone="neutral-900" bold>
                  {employeeTGD.userSon.lastNurseryName}
                </OreText>
                <OreText size="text-sm" tone="neutral-900">
                  {addComma(employeeTGD.userSon.lastNurseryStreetName) +
                    addComma(employeeTGD.userSon.lastNurseryStreetNumber) +
                    addComma(employeeTGD.userSon.lastNurseryStreetDescription1) +
                    addComma(employeeTGD.userSon.lastNurseryStreetDescription2) +
                    getUndefinedEmptyString(employeeTGD.userSon.lastNurseryZipCode) +
                    ' ' +
                    addComma(employeeTGD.userSon.lastNurseryCity) +
                    getUndefinedEmptyString(employeeTGD.userSon.lastNurseryProvince)}
                </OreText>
              </>
              <OreButton
                category="tertiary"
                icon={<SvgChange />}
                size="small"
                iconAlign="left"
                onClick={() => searchKindergartenProps.setShowPopup(true)}
                style={{ justifyContent: 'left' }}>
                {t(forms.buttons.change)}
              </OreButton>
            </OreStack>
          ) : (
            <OreButton
              category="tertiary"
              icon={<SvgPlus />}
              size="small"
              onClick={() => searchKindergartenProps.setShowPopup(true)}
              style={{ justifyContent: 'left' }}>
              {t(forms.buttons.select)}
            </OreButton>
          )}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTGD.${index}.userSon.initialAmount`)}
            placeholder={t(forms.placeholders.order.amount)}
            required
            startAdornment={currency.euro}
            type="number"
            autoComplete="off"
            hasError={
              !!form.formState.errors?.employeesTGD?.[index]?.userSon?.initialAmount
            }
            errorMessage={
              form.formState.errors?.employeesTGD?.[index]?.userSon?.initialAmount
                ?.message
            }
            onChange={e => setInitialAmountValue(index, +e.target.value)}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTGD.${index}.userSon.costCenter`)}
            required={false}
            placeholder={t(forms.placeholders.employee.costCenter)}
            maxLength={50}
            onChange={e => setCostCenterValue(index, e.target.value)}
          />
        </OreTableCell>
      </OreTableRow>
      <Modal
        handleOnClose={searchKindergartenProps.setShowPopup}
        open={searchKindergartenProps.popupShow}
        buttons={searchKindergartenProps.popupButtons}
        size="extralarge"
        children={
          <TGDOrderSearchKindergarten searchKindergartenProps={searchKindergartenProps} />
        }
      />
    </>
  )
}
