import { SimpleForm } from '../../../Templates'
import ExcelAlertsContainer from './organism/ExcelAlertsContainer'
import { useTitleTranslation } from './hooks/useTitleTranslation'

const OrderFromExcelHome = (): JSX.Element => {
  const { getExcelTitle } = useTitleTranslation()
  return <SimpleForm title={getExcelTitle()} organisms={[<ExcelAlertsContainer />]} />
}

export default OrderFromExcelHome
