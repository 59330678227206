import { forwardRef, useImperativeHandle, useState } from 'react'

import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@runroom/oreneta'

import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { useSendEmail } from 'src/Flex/CommunicationsTemplates/ui/templates-send'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { flexPendingTasksTranslations } from 'src/Flex/TaskManager/ui/translations'
import { usePendingTasks } from 'src/Flex/TaskManager/ui/pending-tasks'

export type SendPendingMailsModalActions = {
  open: () => void
}

interface IProps {
  ref: React.Ref<SendPendingMailsModalActions>
}

export const SendPendingMailsModal = forwardRef<SendPendingMailsModalActions, IProps>(
  (props, ref): JSX.Element => {
    const { sendPendingEmails } = useSendEmail()
    const { getPendingTasks } = usePendingTasks()
    const [open, setOpen] = useState<boolean>(false)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
      open: async (): Promise<void> => {
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      await sendPendingEmails().then(response => {
        if (response) {
          getPendingTasks()
          setOpen(false)
        }
      })
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="small" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexPendingTasksTranslations.sendModal.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(flexPendingTasksTranslations.sendModal.subtitle)}
              </OreText>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="primary">
              {t(flexCommunicationsTemplatesTranslations.sendModal.button.label)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
