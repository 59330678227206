import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgEdit,
  OreStack,
  SvgBin,
  SvgCommunications,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'

import type { CollectiveModel } from 'src/Flex/Collectives/domain'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { getProductIconByProductType } from 'src/Flex/Products/ui/shared'
import { useNavigate } from 'react-router-dom'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'

interface IProps {
  collective: CollectiveModel
  deleteCollective: (id: string, hasActiveHires: boolean, isalud: boolean) => void
  hasOnlyOneCollective: boolean
  sendEmail: (id: string) => void
}

export const FlexCollectiveRow = ({
  collective: { id, name, products, numberOfEmployees, hasActiveHires },
  deleteCollective,
  hasOnlyOneCollective,
  sendEmail,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCollectiveEdit = (): void => {
    navigate(flexNavigationRoutes.flexCollectivesEdit.replace(':id', id))
  }

  return (
    <OreTableRow valign="middle">
      <OreTableCell>
        <OreText size="text-sm">{name}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="xsmall" direction="row">
          {products.map((product: edenredProducts) => {
            const icon = getProductIconByProductType(product)

            return icon ? (
              <OreTooltip key={product} text={t(flexProductsTranslations.ids[product])}>
                {icon}
              </OreTooltip>
            ) : null
          })}
        </OreStack>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{numberOfEmployees}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="2xsmall" direction="row">
          <OreIconButton
            icon={<SvgCommunications />}
            title={t(flexCommunicationsTemplatesTranslations.sendModal.icon)}
            onClick={() => sendEmail(id)}
          />
          <OreIconButton
            icon={<SvgBin />}
            title={t(flexCollectivesTranslations.configure.delete.iconLabel)}
            onClick={() =>
              deleteCollective(
                id,
                hasActiveHires,
                products.includes(edenredProducts.salud)
              )
            }
            disabled={hasOnlyOneCollective}
          />
          <OreIconButton
            icon={<SvgEdit />}
            title={t(flexCollectivesTranslations.configure.edit.title)}
            onClick={handleCollectiveEdit}
          />
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
