import { useEffect, useState } from 'react'
import { UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ordersAdherenceService } from 'src/Flex/Orders/application'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { ProductAdherenceModel } from 'src/Flex/Orders/domain/ProductAdherenceModel'
import { productAdherenceTranslations } from 'src/Flex/Orders/ui/order-adherence'
import { nonEmptyStringSchema, useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { MetaStatusCodes } from 'src/domain/enum'

export interface DeclineForm {
  reason: string
}
export interface IUseProductController {
  productsAdherence: ProductAdherenceModel[]
  loadProductsAdherence: () => Promise<void>
  acceptOrRejectProductAdherence: (
    id: string,
    isAccepted: boolean,
    data?: DeclineForm
  ) => Promise<boolean>
  changePage: (page: number) => void
  numberOfRecordsPerPage: number
  totalRows: number
  page: number
  form: UseFormReturn<DeclineForm>
}

export const useProductAdherenceController = (
  initPage: number | null = 0
): IUseProductController => {
  const [productsAdherence, setProductsAdherence] = useState<ProductAdherenceModel[]>([])
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState<number | null>(initPage)
  const numberOfRecordsPerPage = 8

  const form = useForm<DeclineForm>({
    resolver: yupResolver(
      object({
        reason: nonEmptyStringSchema(t),
      })
    ),
    mode: 'onChange',
  })

  const loadProductsAdherence = async (page: number | null = 0): Promise<void> => {
    startLoading()

    const response =
      page !== null
        ? await ordersAdherenceService().GetProductsAdherence(
            page,
            numberOfRecordsPerPage
          )
        : await ordersAdherenceService().GetProductsAdherence()

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setProductsAdherence(response.data.rows)
      setTotalRows(response.data.totalRows)
    }
  }

  const acceptOrRejectProductAdherence = async (
    id: string,
    isAccepted: boolean,
    data?: DeclineForm
  ): Promise<boolean> => {
    startLoading()

    const response = await ordersAdherenceService().acceptOrRejectProductAdherence(
      id,
      isAccepted,
      data
    )

    stopLoading()

    loadProductsAdherence(page)

    let apiResponse = false
    if (response?.meta.status === MetaStatusCodes.SUCCESS) {
      apiResponse = handleMetaResponse(response?.meta, form, {
        successMessage: isAccepted
          ? t(productAdherenceTranslations.notification.accepted)
          : t(t(productAdherenceTranslations.notification.rejected)),
      })
    } else {
      apiResponse = handleMetaResponse(response?.meta, undefined, {
        notifySuccess: false,
      })
    }

    return apiResponse
  }

  const changePage = (page: number): void => {
    setPage(page)
  }

  useEffect(() => {
    loadProductsAdherence(page)
  }, [page])

  return {
    productsAdherence,
    loadProductsAdherence,
    acceptOrRejectProductAdherence,
    form,
    changePage,
    numberOfRecordsPerPage,
    totalRows,
    page: (page ?? 0) + 1,
  }
}
