import { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { ProductModel } from 'src/Flex/Products/domain'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { UserRepository, FlexDataModel, ErrorDataModel } from 'src/Flex/User/domain'
import { useUserController } from 'src/Flex/User/ui/hooks'

type Context = {
  repository: UserRepository
  flexData: FlexDataModel | null
  errorData: ErrorDataModel
  getAllProducts: ProductModel[]
  setAllProducts: Dispatch<SetStateAction<ProductModel[]>>
  getInitialFlexData: () => Promise<void>
}

type Props = PropsWithChildren & {
  repository: UserRepository
}

const [useUserData, StateContextProvider] = createGenericContext<Context>()

const UserDataProvider = ({ repository, children }: Props): JSX.Element => {
  const { flexData, errorData, getAllProducts, setAllProducts, getInitialFlexData } =
    useUserController(repository)
  return (
    <StateContextProvider
      value={{
        repository,
        flexData,
        errorData,
        getAllProducts,
        setAllProducts,
        getInitialFlexData,
      }}>
      {children}
    </StateContextProvider>
  )
}

export { UserDataProvider, useUserData }
