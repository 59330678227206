import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form'
import { useTranslation, TFunction } from 'react-i18next'
import type { PolicyModel, ProductConfigurationModels } from 'src/Flex/Products/domain'
import { productsService } from 'src/Flex/Products/application'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useProducts } from 'src/Flex/Products/ui/product-list'
import ObjectSchema, { ObjectShape } from 'yup/lib/object'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { flexProductsTranslations } from '../../translations'
import { UserContract } from 'src/Flex/User/application'
import { createApiUserRepository } from 'src/Flex/User/infrastructure'
import { useUserData } from 'src/Flex/User/ui/context'

interface UseConfigureProduct<
  T extends FieldValues,
  K extends keyof ProductConfigurationModels
> {
  load: () => Promise<void>
  save: (data: T) => Promise<boolean>
  saveChanges: (data: PolicyModel) => Promise<boolean>
  deletePolicy: (id: string) => Promise<boolean>
  reset: () => void
  form: UseFormReturn<T>
  model: ProductConfigurationModels[K] | undefined
  isGlobal: boolean
}

export const useConfigureProduct = <
  T extends FieldValues,
  K extends keyof ProductConfigurationModels
>({
  productType,
  formProps,
  formValidationScheme,
  convertFromFormToAPI,
  convertFromAPIToForm,
  globalValues,
}: {
  productType: K
  formProps: UseFormProps<T>
  formValidationScheme: (
    t: TFunction<'translation', undefined>
  ) => ObjectSchema<ObjectShape>
  convertFromFormToAPI?: (data: T, isGlobal: boolean) => ProductConfigurationModels[K]
  convertFromAPIToForm?: (data: ProductConfigurationModels[K]) => T
  globalValues?: (form: UseFormReturn<T>) => T
}): UseConfigureProduct<T, K> => {
  const { getInitialFlexData } = useUserData()
  const [model, setModel] = useState<ProductConfigurationModels[K]>()
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const { handleMetaResponse } = useMetaResponseHandler()
  const { collective, loadProducts } = useProducts()

  const isGlobal = collective === undefined

  const form = useForm<T>({
    resolver: yupResolver(formValidationScheme(t)),
    mode: 'onChange',
    ...formProps,
  })

  const save = async (data: T): Promise<boolean> => {
    startLoading()

    const response = await productsService().SaveProductConfiguration(
      convertFromFormToAPI
        ? convertFromFormToAPI(data, isGlobal)
        : (data as unknown as ProductConfigurationModels[K]),
      productType,
      collective?.id
    )

    stopLoading()

    if (
      response?.meta.status === MetaStatusCodes.INFO ||
      response?.meta.status === MetaStatusCodes.SUCCESS
    )
      loadProducts()
    await getInitialFlexData()
    if (response?.meta.status !== MetaStatusCodes.ERROR)
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const saveChanges = async (data: PolicyModel): Promise<boolean> => {
    startLoading()

    const response = await productsService().SaveHealthExternalPolicyConfiguration(data)

    stopLoading()
    if (response?.meta.status !== MetaStatusCodes.ERROR)
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const deletePolicy = async (id: string): Promise<boolean> => {
    startLoading()

    const response = await productsService().DeleteHealthExternalPolicyConfiguration(id)

    stopLoading()
    if (response?.meta.status !== MetaStatusCodes.ERROR)
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const load = async (): Promise<void> => {
    startLoading()

    const response = await productsService().GetProductConfiguration(
      productType,
      collective?.id
    )

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      form.reset({
        ...form.getValues(),
        ...(convertFromAPIToForm
          ? convertFromAPIToForm(response.data)
          : (response.data as unknown as Partial<T>)),
      })

      setModel(response.data)
    }
  }

  const reset = (): void => {
    if (globalValues) {
      form.reset({ ...form.getValues(), ...globalValues(form) })
    }
  }

  return {
    isGlobal,
    model,
    form,
    load,
    save,
    saveChanges,
    deletePolicy,
    reset,
  }
}
