import { useTranslation } from 'react-i18next'
import { SidebarMenuItemModel } from '../Sidebar'
import SidebarSection from './SidebarSection'
import { SidebarLink } from '../atoms'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { Fragment } from 'react'

interface SidebarItemProps {
  menuItem: SidebarMenuItemModel
  index: number
  hover: boolean
  isFlex?: boolean
}

export const SidebarItem = ({
  menuItem,
  index,
  hover,
  isFlex = false,
}: SidebarItemProps): JSX.Element => {
  const { t } = useTranslation()
  const { permissionTags } = useAuth()
  const { protectedBy } = menuItem

  if (protectedBy && !permissionTags.includes(protectedBy)) {
    return <Fragment key={index}></Fragment>
  }

  if (menuItem.items) {
    return (
      <SidebarSection
        title={t(menuItem.title || '')}
        items={menuItem.items}
        hover={hover}
        key={index}
        isFlex={isFlex}
      />
    )
  }
  return (
    <SidebarLink
      title={t(menuItem.title || '')}
      icon={menuItem.icon || <></>}
      link={menuItem.link}
      hover={hover}
      key={index}
      isFlex={isFlex}
      pillText={menuItem.pillText}
      activeLinks={menuItem.activeLinks}
    />
  )
}
