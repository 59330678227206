import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { getFirstDayOfMonthOneYearBefore, getToday } from 'src/core/helpers'
import { HistorySpendingService } from 'src/core/services/dietSpendingService'
import { HeaderModel } from 'src/domain/customComponents/table'
import { NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { DietSpendingModel } from 'src/domain/models/historySpendings'
import { DietSpendingsRequest } from 'src/domain/models/historySpendings/DietSpendingsRequest'
import { forms } from 'src/domain/translations'
import { dietSpendingsTranslations } from 'src/domain/translations/history/dietSpendings'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

export interface DietSpendingsState {
  header: HeaderModel
  totalRows: number
  dietSpendings: DietSpendingModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  search(filter: DietSpendingsRequest): void
  downloadExcel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onEnter: (event: KeyboardEvent<HTMLInputElement>) => void
  form: UseFormReturn<DietSpendingsRequest, any>
  onSearch: () => void
  clearFilters: () => void
  showMoreFilters: boolean
  setShowMoreFilters: Dispatch<SetStateAction<boolean>>
}

export const useDietSpendings = (): DietSpendingsState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [dietSpendings, setDietSpendings] = useState<DietSpendingModel[]>([])
  const { addNotification } = useNotification()
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false)

  const defaultValues: DietSpendingsRequest = {
    productCode: edenredProducts.ticketRestaurant,
    beginDate: getFirstDayOfMonthOneYearBefore(),
    endDate: getToday(),
    beginHour: '00:00',
    endHour: '23:59',
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  }

  const [query, setQuery] = useState<DietSpendingsRequest>({ ...defaultValues })

  const header: HeaderModel = {
    headerLabel: [
      { label: t(dietSpendingsTranslations.table.header.titular) },
      { label: t(dietSpendingsTranslations.table.header.employeeNumber) },
      { label: t(dietSpendingsTranslations.table.header.commerce) },
      { label: t(dietSpendingsTranslations.table.header.postalCode) },
      { label: t(dietSpendingsTranslations.table.header.city) },
      { label: t(dietSpendingsTranslations.table.header.amount) },
      { label: t(dietSpendingsTranslations.table.header.date) },
    ],
  }

  const getDietSpendings = (): void => {
    startLoading()
    HistorySpendingService()
      .GetDietSpendingsList(query)
      .then(response => {
        if (response && response.data && response.data.rows) {
          setDietSpendings(response.data.rows)
          setTotalRows(response.data.totalRows)
        }
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const search = (filters: DietSpendingsRequest): void => {
    setQuery({
      ...filters,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const downloadExcel = (): void => {
    startLoading()
    HistorySpendingService()
      .downloadDietExcel(query)
      .then(response => {
        if (response?.meta?.status === 'SUCCESS') {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = 'TicketRestaurant_Consumos.xlsx'
          link.click()
          link.remove()
        } else {
          addNotification(
            response?.meta?.messages[0].description,
            NotificationSeverity.error
          )
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const formSchema = Yup.object({
    productCode: Yup.number(),
    beginDate: Yup.string().required(t(forms.errors.fieldRequired)),
    endDate: Yup.string().required(t(forms.errors.fieldRequired)),
    beginHour: Yup.string().required(t(forms.errors.fieldRequired)),
    endHour: Yup.string().required(t(forms.errors.fieldRequired)),
  })

  const form = useForm<DietSpendingsRequest>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      productCode: edenredProducts.ticketRestaurant,
      beginDate: getFirstDayOfMonthOneYearBefore(),
      endDate: getToday(),
      beginHour: '00:00',
      endHour: '23:59',
    },
  })

  const onSearch = (): void => {
    const filters: DietSpendingsRequest = {
      productCode: edenredProducts.ticketRestaurant,
      multiFilter: form.getValues('multiFilter'),
      beginDate: form.getValues('beginDate'),
      endDate: form.getValues('endDate'),
      beginHour: form.getValues('beginHour'),
      endHour: form.getValues('endHour'),
      zipCode: form.getValues('zipCode'),
      city: form.getValues('city'),
    }
    search(filters)
  }

  const clearFilters = (): void => {
    form.setValue('multiFilter', defaultValues.multiFilter)
    form.setValue('beginDate', defaultValues.beginDate)
    form.setValue('endDate', defaultValues.endDate)
    form.setValue('beginHour', defaultValues.beginHour)
    form.setValue('endHour', defaultValues.endHour)
    form.setValue('zipCode', defaultValues.zipCode)
    form.setValue('city', defaultValues.city)
    //TODO: A revisar con negocio si hace falta ocultar los filtros al limpiar
    // setShowMoreFilters(false)
  }

  useEffect(() => {
    getDietSpendings()
  }, [query])

  return {
    header,
    totalRows,
    dietSpendings,
    page,
    setPage,
    pageSize,
    onPageChange,
    search,
    downloadExcel,
    onEnter,
    form,
    onSearch,
    clearFilters,
    showMoreFilters,
    setShowMoreFilters,
  }
}
