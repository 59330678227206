import { PageWrapper } from 'src/presentation/layout'
import { OreDivider, OreHeading } from '@runroom/oreneta'
import {
  CommunicationsTemplatesEditProvider,
  DynamicTitle,
  TemplatesEditForm,
} from 'src/Flex/CommunicationsTemplates/ui/templates-detail'
import { useParams } from 'react-router-dom'
import { flexCommunicationsTemplatesTranslations } from '../translations'
import { useTranslation } from 'react-i18next'

export const FlexTemplatesEditPage = (): JSX.Element => {
  const { id } = useParams()
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <CommunicationsTemplatesEditProvider id={id}>
        {id ? (
          <DynamicTitle />
        ) : (
          <OreHeading as="h1" size="title-md">
            {t(flexCommunicationsTemplatesTranslations.configure.title_create)}
          </OreHeading>
        )}

        <OreDivider space="larger-bottom" />

        <TemplatesEditForm />
      </CommunicationsTemplatesEditProvider>
    </PageWrapper>
  )
}
