import { BooleanSchema } from 'yup'
import { TFunction } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { checkboxSchema } from 'src/Flex/Shared/ui/Form'

export const requiredCheckboxSchema = (
  t: TFunction<'translation', undefined>
): BooleanSchema<boolean | undefined> =>
  checkboxSchema(t).test(
    'must-be-accepted',
    t(forms.errors.privacyPolicy),
    value => value === true
  )
