export const accountUsersLicensesTranslations = {
  title: 'accountUsersLicensesTranslations.title',
  addButton: 'accountUsersLicensesTranslations.addButton',
  description: 'accountUsersLicensesTranslations.description',
  table: {
    title: 'accountUsersLicensesTranslations.table.title',
    emptyText: 'accountUsersLicensesTranslations.table.emptyText',
    header: {
      name: 'accountUsersLicensesTranslations.table.header.name',
      mail: 'accountUsersLicensesTranslations.table.header.mail',
      status: 'accountUsersLicensesTranslations.table.header.status',
      profile: 'accountUsersLicensesTranslations.table.header.profile',
      actions: 'accountUsersLicensesTranslations.table.header.actions',
    },
    actions: {
      deleteTooltip: 'accountUsersLicensesTranslations.table.actions.deleteTooltip',
      editTooltip: 'accountUsersLicensesTranslations.table.actions.editTooltip',
    },
  },
  add: {
    title: 'accountUsersLicensesTranslations.add.title',
    description: 'accountUsersLicensesTranslations.add.description',
    userDataTitle: 'accountUsersLicensesTranslations.add.form.userDataTitle',
    name: 'accountUsersLicensesTranslations.add.form.name',
    namePlaceHolder: 'accountUsersLicensesTranslations.add.form.namePlaceHolder',
    surname: 'accountUsersLicensesTranslations.add.form.surname',
    surnamePlaceholder: 'accountUsersLicensesTranslations.add.form.surnamePlaceholder',
    surnamePlaceholder2: 'accountUsersLicensesTranslations.add.form.surnamePlaceholder2',
    surname2: 'accountUsersLicensesTranslations.add.form.surname2',
    email: 'accountUsersLicensesTranslations.add.form.email',
    emailPlaceHolder: 'accountUsersLicensesTranslations.add.form.emailPlaceHolder',
    userProfile: 'accountUsersLicensesTranslations.add.form.userProfile',
    visor: 'accountUsersLicensesTranslations.add.form.visor',
    admin: 'accountUsersLicensesTranslations.add.form.admin',
    superAdmin: 'accountUsersLicensesTranslations.add.form.superAdmin',
    fieldsRequired: 'accountUsersLicensesTranslations.add.form.fieldsRequired',
    createButton: 'accountUsersLicensesTranslations.add.form.createButton',
    license1: 'accountUsersLicensesTranslations.add.form.license1',
    license2: 'accountUsersLicensesTranslations.add.form.license2',
    license3: 'accountUsersLicensesTranslations.add.form.license3',
    license4: 'accountUsersLicensesTranslations.add.form.license4',
    license5: 'accountUsersLicensesTranslations.add.form.license5',
    license6: 'accountUsersLicensesTranslations.add.form.license6',
    statusActive: 'accountUsersLicensesTranslations.add.form.statusActive',
    statusPending: 'accountUsersLicensesTranslations.add.form.statusPending',
    mandatoryFields: 'accountUsersLicensesTranslations.add.form.mandatoryFields',
  },
  edit: {
    title: 'accountUsersLicensesTranslations.edit.title',
    name: 'accountUsersLicensesTranslations.edit.name',
    mail: 'accountUsersLicensesTranslations.edit.mail',
  },
  delete: {
    modal: {
      title: 'accountUsersLicensesTranslations.delete.modal.title',
      description: 'accountUsersLicensesTranslations.delete.modal.description',
    },
    success: 'accountUsersLicensesTranslations.delete.success',
    error: 'accountUsersLicensesTranslations.delete.error',
  },
}
