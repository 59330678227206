import { yupResolver } from '@hookform/resolvers/yup'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import {
  MetaStatusCodes,
  NotificationSeverity,
  browserStorageKeys,
  edenredProducts,
  orderType,
  tgdOrderType,
} from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { UseFormReturn, useForm } from 'react-hook-form'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import {
  EmployeesTGDOrderModel,
  TGDEmployeeAndChildsModel,
  TGDOrderSummaryForm,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { useTGDOrderSummarySchema } from '../validations/useTGDOrderSummarySchema'
import { TGDOrderService } from 'src/core/services/tgdOrderService'
import { MessagesModel } from 'src/domain/models'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useModal } from 'src/presentation/hooks'
import { isOlderThan3OrWillTurn4DuringSchool } from 'src/core/helpers'

export interface TGDOrderSummaryState {
  form: UseFormReturn<TGDOrderSummaryForm, any>
  header: HeaderModel
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  showDeleteModal: boolean
  deleteModalTitle: string
  deleteModalDescription: string
  deletePopupButtons: PopupButtonModel[]
  onClickDelete(id: string): void
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  onClickEdit(order: TGDEmployeeAndChildsModel): void
  onCheckoutOrder: (summary: TGDOrderSummaryForm) => Promise<void>
  showSaveCloseModal: boolean
  setShowSaveCloseModal: Dispatch<SetStateAction<boolean>>
  setTGDEmployeesToConfig: (employees: EmployeesTGDOrderModel[]) => void
  existChildsOlderThan3: boolean
}

export const useTGDOrderSummaryContainer = (isExcel: boolean): TGDOrderSummaryState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()
  const {
    tgdOrders,
    removeTGDOrder,
    clearTGDOrders,
    addTDGOrderCheckout,
    addTDGSummaryForm,
    setTGDEmployeesToConfig,
    addTDGOrders,
  } = useTGDOrder()
  const { TGDOrderSummarySchema } = useTGDOrderSummarySchema()
  const { addNotification } = useNotification()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()
  const [existChildsOlderThan3, setExistChildsOlderThan3] = useState<boolean>(false)

  const form = useForm<TGDOrderSummaryForm>({
    resolver: yupResolver(TGDOrderSummarySchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      initialChargeDate: '',
      billReference: '',
      acceptConditions: false,
      orderTypeId: orderType.NurseryAssignmentOrder,
    },
  })

  const {
    show: showDeleteModal,
    title: deleteModalTitle,
    description: deleteModalDescription,
    buttons: deletePopupButtons,
    setShow: setShowDeleteModal,
    setDescription: setDeleteModalDescription,
    setTitle: setDeleteModalTitle,
    setButtons: setDeleteModalButtons,
  } = useModalController()

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(TGDOrderTranslation.summaryAssignment.table.header.user) },
      { label: t(TGDOrderTranslation.summaryAssignment.table.header.child) },
      { label: t(TGDOrderTranslation.summaryAssignment.table.header.amount) },
      { label: t(TGDOrderTranslation.summaryAssignment.table.header.kindergarten) },
      { label: t(TGDOrderTranslation.summaryAssignment.table.header.actions) },
    ],
  }

  const onClickDelete = (id: string): void => {
    setShowDeleteModal(true)
    setDeleteModalButtons(getDeleteModalButtons(id))
  }

  const getDeleteModalButtons = (id: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowDeleteModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => {
          removeTGDOrder(id)
          setShowDeleteModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
        },
        size: 'large',
      },
    ]
  }

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          clearTGDOrders()
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTgdTab)
        },
        size: 'large',
      },
    ]
  }

  const onClickEdit = (tgdOrderForm: TGDEmployeeAndChildsModel): void => {
    const tgdOrderItemToEdit = tgdOrders.find(
      (order: TGDEmployeeAndChildsModel) => order.id === tgdOrderForm.id.toString()
    )
    const id = tgdOrderItemToEdit?.id || ''
    switch (tgdOrderItemToEdit?.tgdOrderType) {
      case tgdOrderType.childOnly:
        navigate(`${navigationRoutes.tgdOrderChildFormEdit.replace(':id', id)}`)
        return
      case tgdOrderType.employeeAndChild:
        navigate(`${navigationRoutes.tgdOrderFormEdit.replace(':id', id)}`)
        return
      case tgdOrderType.assignmentOnly:
        mapTGDEmployeesToConfig(tgdOrderForm)
        navigate(navigationRoutes.tgdOrderEmployeesConfiguration)
        return
    }
  }

  const mapTGDEmployeesToConfig = (tgdOrderForm: TGDEmployeeAndChildsModel): void => {
    setTGDEmployeesToConfig([])
    const employees: EmployeesTGDOrderModel[] = []
    tgdOrderForm.childs?.forEach(child => {
      employees.push({
        accountBalance: 0,
        clientCode: 0,
        costCenter: tgdOrderForm.costCenter,
        documentTypeId: +tgdOrderForm.documentTypeId,
        employeeNumber: tgdOrderForm.employeeNumber,
        productCode: edenredProducts.ticketGuarderia,
        restrictionProfileId: 0,
        userBirthdayDate: tgdOrderForm.birthDate,
        userCorporativeEmail: '',
        userCorporativeTelephone: '',
        userDni: tgdOrderForm.document,
        userEmail: tgdOrderForm.email,
        userFirstName: tgdOrderForm.name,
        userId: tgdOrderForm.userId,
        userLastName: tgdOrderForm.firstSurname,
        userLastName2: tgdOrderForm.secondSurname,
        userName: tgdOrderForm.name,
        userTelephone: tgdOrderForm.telephone,
        validUser: true,
        userSon: {
          lastNurseryId: child.kindergartenId,
          lastNurseryName: child.kindergartenData ? child.kindergartenData?.name : '',
          lastNurseryCity: child.kindergartenData ? child.kindergartenData?.city : '',
          lastNurseryProvince: child.kindergartenData
            ? child.kindergartenData?.province
            : '',
          lastNurseryStreetDescription1: child.kindergartenData
            ? child.kindergartenData?.streetDescription1
            : '',
          lastNurseryStreetDescription2: child.kindergartenData
            ? child.kindergartenData?.streetDescription2
            : '',
          lastNurseryStreetName: child.kindergartenData
            ? child.kindergartenData?.streetName
            : '',
          lastNurseryStreetNumber: child.kindergartenData
            ? child.kindergartenData?.streetNumber
            : '',
          lastNurseryStreetType: child.kindergartenData
            ? child.kindergartenData?.streetType
            : '',
          lastNurseryZipCode: child.kindergartenData
            ? child.kindergartenData?.zipCode
            : '',
          userBirthdayDate: child.birthDate ? child.birthDate : '',
          userFirstName: child.firstName,
          userId: child.userId ? child.userId : 0,
          userLastName: child.lastName,
          userLastName2: child.lastName2,
          userName: '',
          validUser: true,
          costCenter: child.costCenter,
          initialAmount: child.initialAmount,
        },
      })
    })
    setTGDEmployeesToConfig(employees)
  }

  const onCheckoutOrder = async (summary: TGDOrderSummaryForm): Promise<void> => {
    const isValid = await form.trigger()
    if (!isValid) return
    startLoading()
    TGDOrderService()
      .checkout(summary, tgdOrders)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addTDGSummaryForm(summary)
          addTDGOrderCheckout(response)
          navigate(navigationRoutes.tgdOrderCheckout)
          addNotification(t(forms.success.message), NotificationSeverity.success)
        } else {
          if (response?.meta.messages.length > 0) {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                form.setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              }
            })
          }
        }
      })
      .finally(() => stopLoading())
  }

  const TgdOrderComplete = (): TGDEmployeeAndChildsModel[] => {
    const employees = sessionStorage.getItem(browserStorageKeys.tgdOrderComplete)
    return employees ? JSON.parse(employees) : []
  }

  useEffect(() => {
    if (TgdOrderComplete() && TgdOrderComplete().length > 0) {
      addTDGOrders(TgdOrderComplete())
      sessionStorage.removeItem(browserStorageKeys.tgdOrderComplete)
    }
    setDeleteModalTitle(t(TGDOrderTranslation.summaryAssignment.deleteModal.title))
    setDeleteModalDescription(
      t(TGDOrderTranslation.summaryAssignment.deleteModal.description)
    )
    setCancelModalTitle(t(TGDOrderTranslation.summaryAssignment.cancelModal.title))
    setCancelModalDescription(
      t(TGDOrderTranslation.summaryAssignment.cancelModal.description)
    )
  }, [])

  useEffect(() => {
    const existAnyChildOlderThan3 = tgdOrders.some(tgdOrder =>
      tgdOrder.childs.some(child => isOlderThan3OrWillTurn4DuringSchool(child.birthDate))
    )

    setExistChildsOlderThan3(existAnyChildOlderThan3)
  }, [tgdOrders])

  return {
    header,
    setShowDeleteModal,
    showDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    onClickDelete,
    onClickEdit,
    form,
    onCheckoutOrder,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    setShowSaveCloseModal,
    showSaveCloseModal,
    setTGDEmployeesToConfig,
    existChildsOlderThan3,
  }
}
