import { yupResolver } from '@hookform/resolvers/yup'
import { Seq } from 'immutable'
import { useEffect, useState } from 'react'
import type { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEmployeeProfileController } from './useEmployeeProfileController'
import { TextBoxRowModel } from 'src/domain/customComponents'
import {
  EmployeesNurseryModel,
  EmployeesWithLastCardUpdated,
  MessagesModel,
} from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEmployeeEditProfileSchemaTGD } from 'src/presentation/validations'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { forms } from 'src/domain/translations'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { MetaStatusCodes, NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { EmployeesService } from 'src/core/services/employeesService'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { isMixClient } from 'src/core/services'
import { ConvertToISODateOnly } from 'src/core/helpers'

interface ControllerState {
  GetEmployeeDataTextBoxes: () => TextBoxRowModel<EmployeesNurseryModel>[]
  register: UseFormRegister<EmployeesNurseryModel>
  errors: any
  updateEmployee: (updatedEmployee: EmployeesNurseryModel) => void
  handleSubmit: UseFormHandleSubmit<EmployeesNurseryModel>
  enableEdit: boolean
  handleEnableEdit: () => void
  errorMessage: string
}

export const useParentProfileFormController = (
  employeeData: EmployeesNurseryModel
): ControllerState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { permissionTags } = useAuth()
  const { employeeEditParentProfileSchema } = useEmployeeEditProfileSchemaTGD()
  const { addNotification } = useNotification()
  const [enableEdit, setEnableEdit] = useState(false)
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<EmployeesNurseryModel>({
    resolver: yupResolver(employeeEditParentProfileSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      userCorporativeEmail: employeeData?.userCorporativeEmail,
      userCorporativeTelephone: employeeData?.userCorporativeTelephone,
      employeeNumber: employeeData?.employeeNumber,
    },
  })

  const GetEmployeeDataTextBoxes = (): TextBoxRowModel<EmployeesNurseryModel>[] => {
    return [
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'email',
            label: t(employeesTranslation.profile.form.email),
            name: 'userCorporativeEmail',
            placeHolder: t('marta@gmail.com'),
            required: true,
            type: 'email',
            googleApi: false,
            maxLength: 75,
            disabled: !enableEdit,
          },
        ],
      },

      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'telephone',
            label: t(employeesTranslation.profile.form.phone),
            name: 'userCorporativeTelephone',
            placeHolder: t('677777777'),
            required: false,
            type: 'tel',
            googleApi: false,
            maxLength: 9,
            disabled: !enableEdit,
          },
          {
            id: 'employeeNumber',
            label: t(employeesTranslation.profile.form.employeeNumber),
            name: 'employeeNumber',
            placeHolder: t('123456'),
            required: false,
            type: 'text',
            googleApi: false,
            maxLength: 10,
            disabled: !enableEdit,
          },
        ],
      },
    ]
  }

  const updateEmployee = (updatedEmployee: EmployeesNurseryModel): void => {
    startLoading()
    const newEmployee: EmployeesWithLastCardUpdated = {
      userId: employeeData?.userId.toString() || '',
      productCode: edenredProducts.ticketGuarderia,
      corporativeEmail: updatedEmployee.userCorporativeEmail,
      corporativeTelephone: updatedEmployee.userCorporativeTelephone,
      employeeNumber: updatedEmployee.employeeNumber,
      usageProfileId: employeeData.restrictionProfileId.toString(),
      birthDate: ConvertToISODateOnly(employeeData.userBirthdayDate),
    }
    EmployeesService()
      .updateEmployee(newEmployee)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(t(forms.success.message), NotificationSeverity.success)
          setEnableEdit(false)
        } else {
          if (response?.meta.messages.length > 0) {
            if (isMixClient(permissionTags)) {
              addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            }
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
          setEnableEdit(true)
        }
      })
      .finally(() => stopLoading())
  }

  const setDefaultValues = (): void => {
    setValue('userCorporativeEmail', employeeData?.userCorporativeEmail || '')
    setValue('userCorporativeTelephone', employeeData?.userCorporativeTelephone || '')
    setValue('employeeNumber', employeeData?.employeeNumber || '')
  }

  const handleEnableEdit = (): void => {
    setEnableEdit(true)
  }

  useEffect(() => {
    setDefaultValues()
  }, [employeeData])

  return {
    GetEmployeeDataTextBoxes,
    register,
    errors,
    updateEmployee,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  }
}
