import { RepositoryFactory } from '../factories/RepositoryFactory'
import { Repositories } from '../../domain/enum/repositories'
import { HistorySpendingRepository } from 'src/infrastructure/repository/historyReports/HistorySpendingRepository'
import { SpendingRulesTRType, SpendingRulesType } from 'src/domain/enum'

export const HistorySpendingService = (): HistorySpendingRepository => {
  return RepositoryFactory(Repositories.history)
}

export const getSRTypeFromTypeId = (id: number): string => {
  if (id === SpendingRulesType.timezones) {
    return SpendingRulesTRType.TRDiet
  }
  return SpendingRulesTRType.TRCard
}
