import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { contactType, edenredProducts } from '../../../../../domain/enum'
import type { BasicParameters } from '../../../../../domain/forms'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { useDeliverySiteForm } from '../../../../../presentation/components/hooks/useDeliverySiteForm'

export interface FormState {
  t: TFunction<'translation', undefined>
  radioContactTypeValue: string
  enabledFavoriteCheckBox: boolean
  isCheckedFavoriteCheckBox: boolean
  setRadioContactTypeValue: Dispatch<React.SetStateAction<string>>
  setEnabledFavoriteCheckBox: Dispatch<React.SetStateAction<boolean>>
  setIsCheckedFavoriteCheckBox: Dispatch<React.SetStateAction<boolean>>
  form: UseFormReturn<DeliverySiteModel, any>
  onSubmitWarnings: (newDeliverySite: DeliverySiteModel) => void
  showNoPhoneWarning: boolean
  onNoPhoneConfirmation: (isConfirmed: boolean) => void
  showAbandonWarning: boolean
  setShowShowAbandonModal: Dispatch<React.SetStateAction<boolean>>
  onAbandonConfirmation: (isAbandonConfirmed: boolean) => void
  errorMessage: string
  onChangeRadioAdressType: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeCheckBoxFovarite: (event: ChangeEvent<HTMLInputElement>) => void
  onUpdateSubmitWarnings: (newDeliverySite: DeliverySiteModel) => void
  onNoPhoneUpdateConfirmation: (isConfirmed: boolean) => void
  onModalSubmitWarnings: (
    type: 'new' | 'edit' | undefined,
    setShowModal?: Dispatch<SetStateAction<boolean>>,
    setNewDeliverySite?: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  ) => void
  onModalNoPhoneConfirmation: (
    isConfirmed: boolean,
    type: 'new' | 'edit' | undefined,
    setShowModal?: Dispatch<SetStateAction<boolean>>,
    setNewDeliverySite?: Dispatch<SetStateAction<DeliverySiteModel | undefined>>
  ) => void
  onModalAbandonConfirmation: (
    isAbandonConfirmed: boolean,
    setShowModal?: Dispatch<SetStateAction<boolean>>
  ) => void
  requiredPhone: boolean
  setRequiredPhone: Dispatch<SetStateAction<boolean>>
}

export const useFormController = (
  formParameters: BasicParameters<DeliverySiteModel>
): FormState => {
  const { t } = useTranslation()
  const [radioContactTypeValue, setRadioContactTypeValue] = useState<string>(
    contactType.company.toString()
  )
  const [enabledFavoriteCheckBox, setEnabledFavoriteCheckBox] = useState<boolean>(true)
  const [isCheckedFavoriteCheckBox, setIsCheckedFavoriteCheckBox] =
    useState<boolean>(false)
  const [requiredPhone, setRequiredPhone] = useState<boolean>(false)

  const {
    form,
    onSubmitWarnings,
    showNoPhoneWarning,
    onNoPhoneConfirmation,
    showAbandonWarning,
    setShowShowAbandonModal,
    onAbandonConfirmation,
    errorMessage,
    onUpdateSubmitWarnings,
    onNoPhoneUpdateConfirmation,
    onModalSubmitWarnings,
    onModalNoPhoneConfirmation,
    onModalAbandonConfirmation,
  } = useDeliverySiteForm(
    formParameters.productCode
      ? formParameters.productCode
      : edenredProducts.ticketRestaurant
  )

  const SetContactType = (contactTypeValue: string) => {
    if (contactTypeValue === contactType.company.toString())
      setEnabledFavoriteCheckBox(true)
    else {
      setEnabledFavoriteCheckBox(false)
      setIsCheckedFavoriteCheckBox(false)
      form.setValue('isFavorite', false)
    }
    setRadioContactTypeValue(contactTypeValue)
  }

  //event on change adress type radio button
  const onChangeRadioAdressType = (event: ChangeEvent<HTMLInputElement>) => {
    SetContactType(event.target.value)
  }

  //event on change favorite check box
  const onChangeCheckBoxFovarite = (event: ChangeEvent<HTMLInputElement>) => {
    setIsCheckedFavoriteCheckBox(event.target.checked)
  }

  const SetFormEditMode = () => {
    if (formParameters.entity) {
      SetContactType(formParameters.entity.contactType.toString())
      setIsCheckedFavoriteCheckBox(formParameters.entity.isFavorite)
    }
  }

  useEffect(() => {
    if (formParameters) SetFormEditMode()
  }, [formParameters])

  return {
    t,
    radioContactTypeValue,
    enabledFavoriteCheckBox,
    isCheckedFavoriteCheckBox,
    setRadioContactTypeValue,
    setEnabledFavoriteCheckBox,
    setIsCheckedFavoriteCheckBox,
    form,
    onSubmitWarnings,
    showNoPhoneWarning,
    onNoPhoneConfirmation,
    showAbandonWarning,
    setShowShowAbandonModal,
    onAbandonConfirmation,
    errorMessage,
    onChangeRadioAdressType,
    onChangeCheckBoxFovarite,
    onUpdateSubmitWarnings,
    onNoPhoneUpdateConfirmation,
    onModalSubmitWarnings,
    onModalNoPhoneConfirmation,
    onModalAbandonConfirmation,
    requiredPhone,
    setRequiredPhone,
  }
}
