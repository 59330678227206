export const navigationTranslation = {
  sidebar: {
    tabs: {
      general: 'navigation.sidebar.tabs.general',
      flex: 'navigation.sidebar.tabs.flex',
    },
    items: {
      home: 'navigation.sidebar.items.home',
      newOrder: 'navigation.sidebar.items.newOrder',
      myOrders: 'navigation.sidebar.items.myOrders',
      employees: 'navigation.sidebar.items.employees',
      invoices: 'navigation.sidebar.items.invoices',
      flexProducts: 'navigation.sidebar.items.flexProducts',
      flexEmployees: 'navigation.sidebar.items.flexEmployees',
      flexCollectives: 'navigation.sidebar.items.flexCollectives',
      flexProductsAdherence: 'navigation.sidebar.items.flexProductsAdherence',
      flexImputations: 'navigation.sidebar.items.flexOrderImputations',
      flexCommunicationsTemplates: 'navigation.sidebar.items.flexCommunicationsTemplates',
      flexPayrolls: 'navigation.sidebar.items.flexPayrolls',
      flexOrders: {
        title: 'navigation.sidebar.items.flexOrders.title',
      },
      tr: {
        ticketRestaurant: 'navigation.sidebar.items.tr.ticketRestaurant',
        overallConditions: 'navigation.sidebar.items.tr.overallConditions',
        deliveryPoints: 'navigation.sidebar.items.tr.deliveryPoints',
        usageProfiles: 'navigation.sidebar.items.tr.usageProfiles',
        historical: 'navigation.sidebar.items.tr.historical',
      },
      tt: {
        ticketTransport: 'navigation.sidebar.items.tt.ticketTransport',
        overallConditions: 'navigation.sidebar.items.tt.overallConditions',
        deliveryPoints: 'navigation.sidebar.items.tt.deliveryPoints',
        historical: 'navigation.sidebar.items.tt.historical',
      },
      tgd: {
        ticketKindergarten: 'navigation.sidebar.items.tgd.ticketKindergarten',
        overallConditions: 'navigation.sidebar.items.tgd.overallConditions',
        historical: 'navigation.sidebar.items.tgd.historical',
      },
    },
    sections: {
      products: 'navigation.sidebar.sections.products',
      flex: 'navigation.sidebar.sections.flex',
    },
  },
  header: {
    help: 'navigation.header.help',
    helpSubject: 'navigation.header.helpSubject',
    user: {
      userName: 'navigation.header.user.userName',
      menu: {
        configuration: 'navigation.header.user.menu.configuration',
        usersAndLicenses: 'navigation.header.user.menu.usersAndLicenses',
        notifications: 'navigation.header.user.menu.notifications',
        usageConditions: 'navigation.header.user.menu.usageConditions',
        legal: 'navigation.header.user.menu.legal',
        privacyPolicy: 'navigation.header.user.menu.privacyPolicy',
        cookiesPolicy: 'navigation.header.user.menu.cookiesPolicy',
        logout: 'navigation.header.user.menu.logout',
      },
    },
  },
}
