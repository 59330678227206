import { OreButton, OreText, OreStack, SvgResetData } from '@runroom/oreneta'

interface IProps {
  reset: () => void
  text: string
  button: string
}

export const ResetButton = ({ reset, text, button }: IProps): JSX.Element => (
  <OreStack placeItems="start" space="xsmall">
    <OreText>{text}</OreText>
    <OreButton icon={<SvgResetData />} onClick={reset} category="tertiary">
      {button}
    </OreButton>
  </OreStack>
)
