import { IPostPaginationModel } from '../interfaces/history/HistorySpending'

export enum UsersAccountOperationsRequestParams {
  productCode = 'productCode',
  multiFilter = 'multiFilter',
  beginDate = 'beginDate',
  endDate = 'endDate',
  groupBy = 'groupBy',
  accountOperationTypeCode = 'accountOperationTypeCode',
}

export interface IUsersAccountOperations {
  userName: string
  userDni: string
  employeeNumber: string
  amount: number
  dateValue: string
  accountOperationTypeCode: number
  accountOperationType: string
}

export interface UsersAccountOperationsParams {
  productCode?: number
  accountOperationTypeCode?: number
  beginDate?: string
  endDate?: string
  groupBy?: number
  multiFilter?: string
  paginationModel?: IPostPaginationModel
}

export interface UsersAccountOperationsRequestQuery extends UsersAccountOperationsParams {
  paginationModel: IPostPaginationModel
}

export interface UsersAccountOperationsResponse {
  rows: IUsersAccountOperations[]
  totalRows: number
}

export interface IUsersAccountOperationsMapped {
  titular: string
  dniNie: string
  numEmpleado: string
  importe: string
  dateValue: string
  accountOperationTypeCode: number
  accountOperationType: string
}
