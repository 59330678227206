import { useTranslation } from 'react-i18next'
import {
  OreText,
  OreStack,
  OreHeading,
  OreInput,
  OreButton,
  SvgEdit,
} from '@runroom/oreneta'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { useProducts } from 'src/Flex/Products/ui/product-list'
import { GetDateFormattedLong } from 'src/core/helpers'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

export const FlexProductsCutOffDate = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    form: { register, formState, handleSubmit },
    cutOffDate,
    saveCutOffDate,
  } = useProducts()
  const { errors } = handleErrors(formState)

  const maxCutOffDate = 28

  return (
    <OreStack space="medium">
      <OreHeading size="headline-sm">
        {t(flexProductsTranslations.cutOffDate.title)}
        {cutOffDate?.cutOffDate ? GetDateFormattedLong(cutOffDate.cutOffDate) : null}
      </OreHeading>

      <form>
        <OreStack space="small" direction="row" placeItems="end">
          <OreInput
            {...errors('cutOffDate')}
            {...register('cutOffDate')}
            id="cutOffDate"
            type="number"
            label={t(flexProductsTranslations.cutOffDate.form.label)}
            placeholder={t(flexProductsTranslations.cutOffDate.form.placeholder)}
            max={maxCutOffDate}
          />

          <OreButton
            type="submit"
            onClick={handleSubmit(saveCutOffDate)}
            icon={<SvgEdit />}
            disabled={!formState.isDirty || !formState.isValid}>
            {t(flexProductsTranslations.cutOffDate.form.button)}
          </OreButton>
        </OreStack>
      </form>
      <OreText>{t(flexProductsTranslations.cutOffDate.legend)}</OreText>
    </OreStack>
  )
}
