import { OreHeading } from '@runroom/oreneta'
import { RadioButton, Textbox } from '../../../Edenred'
import { deliverySiteFormTranslation } from '../../../../../domain/translations/myProducts/deliverySite'
import type { ChangeEvent } from 'react'
import { useAdressController } from '../hooks'
import type { DeliverySiteModel } from '../../../../../domain/models'
import type { FormProps } from '../../../../../domain/forms/FormProps'

interface AddressProps extends FormProps<DeliverySiteModel> {
  onValueChange?: (event: ChangeEvent<HTMLInputElement>) => void
  radioContactTypeValue: string | undefined
}

export const Address = (props: AddressProps): JSX.Element => {
  const { t, register, errors, adressRadioButtons, adressTextBoxes } =
    useAdressController(props.formParameters)
  return (
    <section className="form-molecule mt-1">
      <div className="pl-05">
        <OreHeading as="h2" size="headline-md">
          {props.title}
        </OreHeading>
      </div>

      <div className="form-atom">
        <RadioButton
          label={t(deliverySiteFormTranslation.address.addressType)}
          name="contactType"
          direction="row"
          required
          errors={errors}
          register={register}
          radioButtons={adressRadioButtons}
          onValueChange={props.onValueChange}
          selectedValue={props.radioContactTypeValue}
        />
      </div>

      <Textbox textBoxList={adressTextBoxes} errors={errors} register={register} />
    </section>
  )
}
