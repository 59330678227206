import {
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableFooter,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import {
  cardRequestType,
  currency,
  orderType,
} from '../../../../../domain/enum/cardOrder'
import type {
  CardOrderDetailModel,
  CardOrderServicesModel,
} from '../../../../../domain/models/cardOrder'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { unloadTranslation } from '../../../../../domain/translations'

interface Props {
  data: CardOrderDetailModel
  cardRequestTypeId: number
  quantity: number
}

export const CardOrderCheckout = ({ data, cardRequestTypeId, quantity }: Props) => {
  const { t } = useTranslation()
  const { orderContext } = useCardOrder()
  let title = ''

  function SetRechargeTitle() {
    if (orderContext.orderType === orderType.Unload) {
      title = unloadTranslation.name
    } else {
      title = cardOrderTranslation.cardOrderResume.chargeTitle
    }
  }

  switch (cardRequestTypeId) {
    case cardRequestType.newCard:
      title = cardOrderTranslation.cardOrderResume.newCardsTitle
      break
    case cardRequestType.charge:
      SetRechargeTitle()
      break
    default:
      title = cardOrderTranslation.cardOrderResume.newCardsTitle
      break
  }

  return data?.services && data?.services?.length > 0 ? (
    <OreTableContainer>
      <>
        <OreTable>
          <OreTableCaption
            counter={quantity + ' ' + t(title).toLocaleLowerCase()}
            title={t(title)}
          />
          <OreTableHead>
            <OreTableRow>
              <OreTableCell as="th" align="left">
                {t(cardOrderTranslation.cardOrderResume.services)}
              </OreTableCell>
              <OreTableCell as="th">
                {t(cardOrderTranslation.cardOrderResume.unit)}
              </OreTableCell>
              <OreTableCell as="th" align="right">
                {t(cardOrderTranslation.cardOrderResume.cost)}
              </OreTableCell>
            </OreTableRow>
          </OreTableHead>
          <OreTableBody>
            {data.services.map((service: CardOrderServicesModel, index: number) => {
              return (
                <OreTableRow key={'row_' + index}>
                  <OreTableCell align="left">{service.label}</OreTableCell>
                  <OreTableCell>{service.quantity}</OreTableCell>
                  <OreTableCell align="right">
                    {service.value + currency.euro}
                  </OreTableCell>
                </OreTableRow>
              )
            })}
          </OreTableBody>
          <OreTableFooter>
            <OreTableRow>
              <OreTableCell colSpan={1} />
              <OreTableCell>
                <OreText size="text-sm">
                  {t(cardOrderTranslation.cardOrderResume.subTotal)}
                </OreText>
                <OreText size="text-sm">
                  {t(cardOrderTranslation.cardOrderResume.iva)}
                </OreText>
                <OreText bold>{t(cardOrderTranslation.cardOrderResume.total)}</OreText>
              </OreTableCell>
              <OreTableCell align="right">
                <OreText size="text-sm" align="right">
                  {data.subtotal + currency.euro}
                </OreText>
                <OreText size="text-sm" align="right">
                  {data.iva + currency.euro}
                </OreText>
                <OreText bold align="right">
                  {data.total + currency.euro}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          </OreTableFooter>
        </OreTable>
      </>
    </OreTableContainer>
  ) : (
    <></>
  )
}
