import { yupResolver } from '@hookform/resolvers/yup'
import { useTGDBasicOrder } from '../../context/TGDBasicOrderProvider'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import {
  MetaStatusCodes,
  NotificationSeverity,
  browserStorageKeys,
  edenredProducts,
  orderType,
  tgdOrderType,
} from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { UseFormReturn, useForm } from 'react-hook-form'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { TGDBasicOrderService, TGDOrderService } from 'src/core/services/tgdOrderService'
import { MessagesModel } from 'src/domain/models'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useModal } from 'src/presentation/hooks'
import {
  EmployeesTGDBasicOrderModel,
  TGDBasicEmployeeAndChildsModel,
  TGDBasicOrderSummaryForm,
} from 'src/domain/models/TGDOrder/TGDBasicOrderModel'
import { TGDBasicOrderTranslation } from 'src/domain/translations/tgdOrders/tgdBasicOrderTranslate'
import { useTGDBasicOrderSummarySchema } from '../validations/useTGDBasicOrderSummarySchema'

export interface TGDBasicOrderSummaryState {
  form: UseFormReturn<TGDBasicOrderSummaryForm, any>
  header: HeaderModel
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  showDeleteModal: boolean
  deleteModalTitle: string
  deleteModalDescription: string
  deletePopupButtons: PopupButtonModel[]
  onClickDelete(id: string): void
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  onClickEdit(order: TGDBasicEmployeeAndChildsModel): void
  onCheckoutOrder: (summary: TGDBasicOrderSummaryForm) => Promise<void>
  showSaveCloseModal: boolean
  setShowSaveCloseModal: Dispatch<SetStateAction<boolean>>
}

export const useTGDBasicOrderSummaryContainer = (): TGDBasicOrderSummaryState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()
  const {
    tgdOrders,
    removeTGDBasicOrder,
    clearTGDBasicOrders,
    addTGDBasicOrderCheckout,
    addTGDBasicSummaryForm,
    addTGDBasicOrders,
  } = useTGDBasicOrder()
  const { TGDBasicOrderSummarySchema } = useTGDBasicOrderSummarySchema()
  const { addNotification } = useNotification()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()

  const form = useForm<TGDBasicOrderSummaryForm>({
    resolver: yupResolver(TGDBasicOrderSummarySchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      initialChargeDate: '',
      billReference: '',
      acceptConditions: false,
      orderTypeId: orderType.NurseryAssignmentOrder,
    },
  })

  const {
    show: showDeleteModal,
    title: deleteModalTitle,
    description: deleteModalDescription,
    buttons: deletePopupButtons,
    setShow: setShowDeleteModal,
    setDescription: setDeleteModalDescription,
    setTitle: setDeleteModalTitle,
    setButtons: setDeleteModalButtons,
  } = useModalController()

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(TGDBasicOrderTranslation.summary.table.header.user) },
      { label: t(TGDBasicOrderTranslation.summary.table.header.child) },
      { label: t(TGDBasicOrderTranslation.summary.table.header.initialBalance) },
      { label: t(TGDBasicOrderTranslation.summary.table.header.amount) },
      { label: t(TGDBasicOrderTranslation.summary.table.header.totalBalance) },
      { label: t(TGDBasicOrderTranslation.summary.table.header.actions) },
    ],
  }

  const onClickDelete = (id: string): void => {
    setShowDeleteModal(true)
    setDeleteModalButtons(getDeleteModalButtons(id))
  }

  const getDeleteModalButtons = (id: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowDeleteModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => {
          removeTGDBasicOrder(id)
          setShowDeleteModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
        },
        size: 'large',
      },
    ]
  }

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          clearTGDBasicOrders()
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTgdTab)
        },
        size: 'large',
      },
    ]
  }

  const onClickEdit = (tgdOrderForm: TGDBasicEmployeeAndChildsModel): void => {
    const tgdOrderItemToEdit = tgdOrders.find(
      (order: TGDBasicEmployeeAndChildsModel) => order.id === tgdOrderForm.id.toString()
    )
    const id = tgdOrderItemToEdit?.id || ''
    switch (tgdOrderItemToEdit?.tgdOrderType) {
      case tgdOrderType.childOnly:
        navigate(`${navigationRoutes.tgdBasicOrderChildFormEdit.replace(':id', id)}`)
        return
      case tgdOrderType.employeeAndChild:
        navigate(`${navigationRoutes.tgdBasicOrderFormEdit.replace(':id', id)}`)
        return
    }
  }

  const onCheckoutOrder = async (summary: TGDBasicOrderSummaryForm): Promise<void> => {
    const isValid = await form.trigger()
    if (!isValid) return
    startLoading()
    // Add order to Checkout
    TGDOrderService()
      .checkout(summary, tgdOrders)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addTGDBasicSummaryForm(summary)
          addTGDBasicOrderCheckout(response)
          navigate(navigationRoutes.tgdBasicOrderCheckout)
          addNotification(t(forms.success.message), NotificationSeverity.success)
        } else {
          if (response?.meta.messages.length > 0) {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                form.setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              }
            })
          }
        }
      })
      .finally(() => stopLoading())
  }

  const TgdOrderComplete = (): TGDBasicEmployeeAndChildsModel[] => {
    const employees = sessionStorage.getItem(browserStorageKeys.tgdOrderComplete)
    return employees ? JSON.parse(employees) : []
  }

  useEffect(() => {
    if (TgdOrderComplete() && TgdOrderComplete().length > 0) {
      addTGDBasicOrders(TgdOrderComplete())
      sessionStorage.removeItem(browserStorageKeys.tgdOrderComplete)
    }
    setDeleteModalTitle(t(TGDBasicOrderTranslation.summary.deleteModal.title))
    setDeleteModalDescription(t(TGDBasicOrderTranslation.summary.deleteModal.description))
    setCancelModalTitle(t(TGDBasicOrderTranslation.summary.cancelModal.title))
    setCancelModalDescription(t(TGDBasicOrderTranslation.summary.cancelModal.description))
  }, [])

  return {
    header,
    setShowDeleteModal,
    showDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    onClickDelete,
    onClickEdit,
    form,
    onCheckoutOrder,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    setShowSaveCloseModal,
    showSaveCloseModal,
  }
}
