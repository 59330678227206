import { OreText } from '@runroom/oreneta'
import { Seq } from 'immutable'
import type { TextSectionModel } from '../../../../../domain/models'
import { useSectionController } from '../hooks/useSectionController'

export const Section = (props: { sectionText: TextSectionModel[] }): JSX.Element => {
  const { section } = useSectionController(props.sectionText)
  return (
    <section className="width-max mt-1">
      <OreText align="center" as="p">
        {section}
      </OreText>
    </section>
  )
}
