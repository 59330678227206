import { OreSwitch } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { useEmployeeProfileSwtichController } from '../hooks'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { EmployeesWithLastCard } from 'src/domain/models'

interface Props {
  employeeData: EmployeesWithLastCard
}

export const EmployeeProfileSwtich = ({ employeeData }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { checked, handleChange } = useEmployeeProfileSwtichController(employeeData)

  return (
    <OreSwitch
      label={t(employeesTranslation.profile.table.body.enableAccount.yes)}
      name="enableAccount"
      secondLabel={t(employeesTranslation.profile.table.body.enableAccount.no)}
      onChange={handleChange}
      checked={checked}
    />
  )
}
