import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { forms, spendingRuleFormTranslation } from '../../domain/translations'
import { SpendingRulesTRType, SpendingRulesType } from 'src/domain/enum'

const useSpendingRulesSchema = (profileTypeIdSelectValue: number, srType: string) => {
  const { t } = useTranslation()

  const maxNumber = 999999999
  const maxAmountWithDecimals = 9999999.99
  const minNumber = 0
  const minAmount = 0.01

  // Validations

  const transactionValidations = Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .integer(t(spendingRuleFormTranslation.form.errors.mustBeInteger))
    .min(
      minNumber,
      `${t(spendingRuleFormTranslation.form.errors.numberMustBeGreaterThan)} ${minNumber}`
    )
    .max(
      maxNumber,
      `${t(
        spendingRuleFormTranslation.form.errors.numberMustBeLessThanOrEqual
      )} ${maxNumber}`
    )

  const amountValidations = Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable()
    .min(
      minAmount,
      `${t(
        spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual
      )} ${minAmount}`
    )
    .max(
      maxAmountWithDecimals,
      `${t(
        spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual
      )} ${maxAmountWithDecimals}`
    )
    .test(
      'decimal-max-2-places',
      t(spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals),
      value => {
        const isNullable = value === null || value === undefined
        if (isNullable) {
          return true
        }
        const regex = /^\d+(\.\d{1,2})?$/
        return regex.test(value?.toString())
      }
    )

  // Schemas

  const weekDaysSchema = Yup.object().shape({
    monday: Yup.boolean(),
    tuesday: Yup.boolean(),
    wednesday: Yup.boolean(),
    thursday: Yup.boolean(),
    friday: Yup.boolean(),
    saturday: Yup.boolean(),
    sunday: Yup.boolean(),
  })

  const timeZonesSchema = Yup.object({
    weekDays: weekDaysSchema.test(
      'at-least-one-true',
      t(spendingRuleFormTranslation.form.errors.atLeastOneDaySelected),
      value => {
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = value
        return (
          !!monday ||
          !!tuesday ||
          !!wednesday ||
          !!thursday ||
          !!friday ||
          !!saturday ||
          !!sunday
        )
      }
    ),
    timeZoneFrom: Yup.string().required(t(forms.errors.fieldRequired)),
    timeZoneTo: Yup.string()
      .when('timeZoneFrom', (timeZoneFrom, schema) => {
        if (timeZoneFrom !== undefined && timeZoneFrom !== null) {
          return schema.test({
            name: 'timeZoneTo',
            exclusive: true,
            message: t(spendingRuleFormTranslation.form.errors.timeToGreaterThanTimeForm),
            test: function (timeZoneTo: string) {
              if (timeZoneTo !== undefined && timeZoneTo !== null) {
                return timeZoneTo > timeZoneFrom
              }
              return true
            },
          })
        }
        return schema
      })
      .required(t(forms.errors.fieldRequired)),
    timeZoneTimeGroup: Yup.boolean(),
    transactionAmount:
      srType === SpendingRulesTRType.TRDiet
        ? amountValidations.required(t(forms.errors.fieldRequired))
        : amountValidations,
  })

  const amountSchema = Yup.object({
    dayAmount: Yup.lazy(() =>
      profileTypeIdSelectValue === SpendingRulesType.byTransaction
        ? transactionValidations
        : amountValidations
    ),
    weekAmount: Yup.lazy(() =>
      profileTypeIdSelectValue === SpendingRulesType.byTransaction
        ? transactionValidations
        : amountValidations
    ),
    monthAmount: Yup.lazy(() =>
      profileTypeIdSelectValue === SpendingRulesType.byTransaction
        ? transactionValidations
        : amountValidations
    ),
    yearAmount: Yup.lazy(() =>
      profileTypeIdSelectValue === SpendingRulesType.byTransaction
        ? transactionValidations
        : amountValidations
    ),
  }).test(
    'at-least-one-amount',
    t(spendingRuleFormTranslation.form.errors.atLeastOneRestriction),
    (values, ctx) => {
      const { dayAmount, weekAmount, monthAmount, yearAmount } = values
      const transactionAmountValue = ctx.parent.timeZones[0].transactionAmount
      const profileTypeIdValue = ctx.parent.profileTypeId

      if (parseInt(profileTypeIdValue) === SpendingRulesType.noRestriction) {
        return true
      }

      return (
        !!dayAmount ||
        !!weekAmount ||
        !!monthAmount ||
        !!yearAmount ||
        !!transactionAmountValue
      )
    }
  )

  const spendingRulesSchema = Yup.object({
    profileName: Yup.string().max(20).required(t(forms.errors.fieldRequired)),
    timeZones: Yup.array().of(timeZonesSchema).required(),
    amount: amountSchema,
  })

  return { spendingRulesSchema }
}

export default useSpendingRulesSchema
