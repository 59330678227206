import type { TFunction } from 'i18next'
import {
  GetAccountOperationsType,
  GetConsumptionGroupBy,
} from 'src/domain/adapters/historyConsumptionAdapters'
import { accountOperationTypeCode, historyConsumptions } from 'src/domain/enum/history'

export const GetHistoryConsumptionsGroupByFilter = (t: TFunction): [string, number][] => {
  const groupByList: [string, number][] = []
  groupByList.push([
    GetConsumptionGroupBy(historyConsumptions.groupByUser),
    historyConsumptions.groupByUser,
  ])
  groupByList.push([
    GetConsumptionGroupBy(historyConsumptions.groupByUserAndDate),
    historyConsumptions.groupByUserAndDate,
  ])
  return groupByList
}

export const GetHistoryRechargeUnloadTypeFilter = (t: TFunction): [string, number][] => {
  const groupByList: [string, number][] = []
  groupByList.push([
    GetAccountOperationsType(accountOperationTypeCode.recharge),
    accountOperationTypeCode.recharge,
  ])
  groupByList.push([
    GetAccountOperationsType(accountOperationTypeCode.unload),
    accountOperationTypeCode.unload,
  ])
  return groupByList
}
