import type { UIState } from './UIContext'
import { uiActionType } from '../../../domain/enum'

export type UIAction =
  | { type: uiActionType.openSidebar }
  | { type: uiActionType.closeSidebar }
  | { type: uiActionType.toggleSidebar }

export function uiReducer(state: UIState, action: UIAction) {
  switch (action.type) {
    case uiActionType.openSidebar: {
      return {
        ...state,
        sidebarCollapsed: false,
      }
    }
    case uiActionType.closeSidebar: {
      return {
        ...state,
        sidebarCollapsed: true,
      }
    }
    case uiActionType.toggleSidebar: {
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      }
    }
    default:
      return state
  }
}
