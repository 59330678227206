import React from 'react'
import { useUI } from '../../../../../context/ui/UIProvider'
import { useLockBodyScroll } from '../../hooks'

const SidebarBackdrop = (): JSX.Element | null => {
  const { closeSidebar } = useUI()
  useLockBodyScroll()

  const handleClick = (): void => {
    closeSidebar()
  }

  return <div onClick={handleClick} className="sidebar-backdrop"></div>
}

export default SidebarBackdrop
