import { useNavigate } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreCardActions,
  OreCardContainer,
  OreHeading,
  OreInput,
  OreText,
  OreStack,
  OreCardWrapper,
} from '@runroom/oreneta'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { forms } from 'src/domain/translations'
import { CollectiveForm } from 'src/Flex/Collectives/ui/collective-detail'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

interface IProps {
  form: UseFormReturn<CollectiveForm>
  save: (data: CollectiveForm) => Promise<void>
  hasId: boolean
}

export const ConfigureCollectivesBasicForm = ({
  form,
  save,
  hasId,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { errors } = handleErrors(form.formState)

  return (
    <OreCardContainer>
      <form>
        <OreCardWrapper space="xlarge">
          <OreStack placeContent="stretch" space="medium">
            <OreHeading size="headline-md">
              {t(flexCollectivesTranslations.configure.common.form.title)}
            </OreHeading>
            <OreInput
              {...form.register('name')}
              {...errors('name')}
              id="collectiveName"
              label={t(
                flexCollectivesTranslations.configure.common.form.collectiveName.label
              )}
              placeholder={t(
                flexCollectivesTranslations.configure.common.form.collectiveName
                  .placeholder
              )}
              required
            />
            <OreInput
              {...form.register('flexibleCompensationPlanPercentage')}
              {...errors('flexibleCompensationPlanPercentage')}
              id="collectivePRF"
              label={t(
                flexCollectivesTranslations.configure.common.form.collectivePRF.label
              )}
              placeholder={t(
                flexCollectivesTranslations.configure.common.form.collectivePRF
                  .placeholder
              )}
              legend={t(
                flexCollectivesTranslations.configure.common.form.collectivePRF.legend
              )}
              required
              min="1"
            />
            <OreText>{t(forms.errors.fieldsRequired)}</OreText>
          </OreStack>
        </OreCardWrapper>
        <OreCardActions>
          <OreStack direction="row" placeContent="end">
            <OreButton
              disabled={!!(hasId && !form.formState.isDirty)}
              size="small"
              onClick={(): void => navigate(flexNavigationRoutes.flexCollectives)}
              category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              type="submit"
              disabled={!!(hasId && !form.formState.isDirty)}
              onClick={form.handleSubmit(save)}
              size="small"
              category="primary">
              {hasId
                ? t(flexCollectivesTranslations.configure.common.form.edit.label)
                : t(flexCollectivesTranslations.cta_create)}
            </OreButton>
          </OreStack>
        </OreCardActions>
      </form>
    </OreCardContainer>
  )
}
