export const paymentMethodsTranslation = {
  wireTransfer: {
    name: 'payments.wireTransfer.name',
    description: 'payments.wireTransfer.description',
    plasticCard: 'payments.wireTransfer.plasticCard',
    virtualCard: 'payments.wireTransfer.virtualCard',
    recharge: 'payments.wireTransfer.recharge',
  },
  directDebit: {
    name: 'payments.directDebit.name',
    description: 'payments.directDebit.description',
    plasticCard: 'payments.directDebit.plasticCard',
    virtualCard: 'payments.directDebit.virtualCard',
    recharge: 'payments.directDebit.recharge',
  },
}
