import {
  downloadLegalDocument,
  employeeDownloadDocument,
} from 'src/config/constants/endpoints'

import type { DocumentRepositoryContract } from 'src/Flex/Employees/domain'
import type { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { EmployeeDocumentType, LegalDocumentType } from 'src/domain/enum'
import { IFile } from 'src/domain/interfaces/global/IFile'
import { createQueryString } from 'src/core/helpers'

export class DocumentRepository implements DocumentRepositoryContract {
  async DownloadLegalDocument(
    legalDocumentType: LegalDocumentType
  ): Promise<HttpModel<IFile>> {
    return await axiosFetch(
      downloadLegalDocument.concat(
        createQueryString({
          documentId: legalDocumentType,
        })
      ),
      HttpMethod.GET
    )
  }

  async DownloadEmployeeDocument(
    employeeId: string,
    documentId: EmployeeDocumentType
  ): Promise<HttpModel<IFile>> {
    return await axiosFetch(
      employeeDownloadDocument
        .replace('{employeeId}', employeeId)
        .replace('{documentType}', documentId.toString()),
      HttpMethod.GET
    )
  }
}
