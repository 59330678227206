import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { IsTgmEnabled } from 'src/core/services/ToggleFeatureService';

// Updates Google Tag Manager data layer
export const usePageViews = (): void => {
 const location = useLocation();
 useEffect(() => {
  if(!IsTgmEnabled()){
   return;
  }
  TagManager.dataLayer({
    dataLayer: {
      site_level2 : 'client portal',
      page_chapter2: 'clientes',
      page: location.pathname
     },
   });
 }, [location]);
};