import { t } from 'i18next'
import { rechargesConfigurationTranslations } from '../../../../domain/translations/cardOrder/rechargesConfiguration'
import { SimpleForm } from '../../../Templates'
import { EditContainer } from './organism/EditContainer'

export const EditRechargesConfigurationPage = (): JSX.Element => {
  return (
    <SimpleForm
      title={t(rechargesConfigurationTranslations.title)}
      organisms={[<EditContainer />]}
    />
  )
}
