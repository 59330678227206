export type EmployeeExcelUpload = {
  excel: string
}

export enum ExcelUploadErrorKind {
  Error = 'error',
  Warning = 'warning',
}

export type ExcelUploadError = {
  row: string
  kind: ExcelUploadErrorKind
  message: string
}

export type EmployeeExcelUploadResponse = {
  excelWithErrors: string | undefined
  created: number
  deleted: number
  excelId: string | undefined
  subscribed: number
  unsubscribed: number
  updated: number
  errors: ExcelUploadError[]
  hasWarnings: boolean
  hasErrors: boolean
}
