import { cloneElement, forwardRef, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  useFloating,
  useInteractions,
  useFocus,
  useHover,
  arrow,
  offset
} from '@floating-ui/react-dom-interactions';
import { mergeRefs } from 'react-merge-refs';
import { classNames, OreText } from '@runroom/oreneta';
import classes from './OreTooltipCustom.module.scss';

type OreTooltipCProps = {
  text: JSX.Element | string;
  children: JSX.Element;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  separation?: 'small' | 'large' | 'xsmall';
};

const staticSideOptions = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right'
};

const ySideOptions = {
  left: 'right',
  right: 'left',
  top: 'left',
  bottom: 'left'
};

const xSideOptions = {
  top: 'bottom',
  bottom: 'top',
  left: 'top',
  right: 'top'
};

const separations = {
  xsmall: 4,
  small: 8,
  large: 16
};

/**
 * Tooltip component
 */
export const OreTooltipCustom = forwardRef<HTMLDivElement, OreTooltipCProps>(
  ({ text, children, placement = 'bottom', separation = 'small' }, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const arrowRef = useRef<HTMLDivElement>(null);

    const {
      x,
      y,
      reference,
      floating,
      strategy,
      context,
      middlewareData: { arrow: { x: arrowX, y: arrowY } = {} }
    } = useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [offset(10 + separations[separation]), arrow({ element: arrowRef })]
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
      useFocus(context),
      useHover(context)
    ]);

    const childrenRef = useMemo(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      () => mergeRefs([reference, (children as any).ref]),
      [reference, children]
    );
    const tooltipRef = useMemo(() => mergeRefs([floating, ref]), [floating, ref]);

    return (
      <>
        {cloneElement(children, getReferenceProps({ ref: childrenRef, ...children.props }))}
        {open &&
          createPortal(
            <div
              ref={tooltipRef}
              className={classes['ore-tooltipCustomWarning']}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                backgroundColor: '#fdf3cf'
              }}
              {...getFloatingProps()}
            >
              <OreText size="text-md" as="span" style={{ color: '#b67514', whiteSpace:'pre-line'}}>
                {text}
              </OreText>
              <div
                className={classNames(classes['ore-tooltipCustomWarning__arrow'])}
                ref={arrowRef}
                style={{
                  [ySideOptions[placement]]: arrowX ?? 0,
                  [xSideOptions[placement]]: arrowY ?? 0,
                  [staticSideOptions[placement]]: '-7px',
                  backgroundColor: '#fdf3cf'
                }}
              />
            </div>,
            document.body
          )}
      </>
    );
  }
);
