import { EmployeesWithLastCard } from 'src/domain/models'
import { currency, emptyValues } from '../../enum'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'

export const getEmployeesWithLastCardMap = (
  response: EmployeesWithLastCardNew[]
): EmployeesWithLastCardNew[] => {
  if (!response) return []

  const list: EmployeesWithLastCardNew[] = []
  response.forEach((item: EmployeesWithLastCardNew) => {
    const tmpItem: EmployeesWithLastCardNew = {
      userId: +item.userId,
      userName: item.userName,
      document: item.document,
      email: item.email,
      employeeNumber: item.employeeNumber ?? emptyValues.dash,
      balance: item.isPPSBalanceRetrieved
        ? item.balance + currency.euro
        : emptyValues.dash,
      expiredDate: item.expiredDate,
      cardStatusId: item.cardStatusId,
      cardStatus: item.cardStatus,
      cardTypeId: item.cardTypeId,
      cardType: item.cardType,
      userIdentity: item.userIdentity,
      costCenter: item.costCenter,
      isPPSBalanceRetrieved: item.isPPSBalanceRetrieved,
      deliverySiteId: item.deliverySiteId,
    }
    list.push(tmpItem)
  })
  return list
}

export const getEmployeesWithLastCardMap_v2 = (
  response: EmployeesWithLastCardNew[]
): EmployeesWithLastCard[] => {
  if (!response) return []

  const list: EmployeesWithLastCard[] = []
  response.forEach((item: EmployeesWithLastCardNew) => {
    const tmpItem: EmployeesWithLastCard = {
      userId: item.userId.toString(),
      userName: item.userName,
      document: item.document,
      email: item.email,
      employeeNumber: item.employeeNumber ?? emptyValues.dash,
      balance: Number(item.balance),
      expiredDate: item.expiredDate,
      cardStatusId: item.cardStatusId,
      cardTypeId: item.cardTypeId,
      cardType: item.cardType,
      costCenter: item.costCenter,
      isPPSBalanceRetrieved: item.isPPSBalanceRetrieved,
      deliverySiteId: item.deliverySiteId,
      productCode: 0,
      documentTypeId: 0,
      serialCardNumber: '',
      mobileType: '',
      usageProfile: '',
      corporativeEmail: item.email,
      corporativeTelephone: '',
      birthDate: '',
      allowPlasticless: '',
      allowMobilePlasticless: null,
      isCardBlocked: false,
    }
    list.push(tmpItem)
  })
  return list
}
