import {
  OreButton,
  OreDivider,
  OreHeading,
  OreStack,
  OreText,
  SvgPlus,
} from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import { TemplatesList } from 'src/Flex/CommunicationsTemplates/ui/templates-list'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { CommunicationsTemplatesProvider } from 'src/Flex/CommunicationsTemplates/ui/templates-list'
import { Trans, useTranslation } from 'react-i18next'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { useUserData } from 'src/Flex/User/ui/context'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const FlexTemplatesListPage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { flexData } = useUserData()

  const handleCommunicationTemplateCreate = (): void => {
    navigate(flexNavigationRoutes.flexCommunicationsTemplatesCreate)
  }

  return (
    <PageWrapper>
      <OreStack direction="row" placeContent="space-between">
        <OreHeading as="h1" size="title-md">
          {t(flexCommunicationsTemplatesTranslations.title)}
        </OreHeading>
        <OreButton
          category="primary"
          icon={<SvgPlus />}
          onClick={handleCommunicationTemplateCreate}>
          {t(flexCommunicationsTemplatesTranslations.button_create)}
        </OreButton>
      </OreStack>
      <OreDivider space="larger-bottom" />

      <CommunicationsTemplatesProvider>
        <OreStack space="large" placeContent="stretch">
          {!flexData?.isOnboardingDone ? (
            <OreText>
              <Trans t={t}>{t(flexCommunicationsTemplatesTranslations.onboarding)}</Trans>
            </OreText>
          ) : null}
          <TemplatesList />
          <VideoTutorial
            title={t(flexCommunicationsTemplatesTranslations.videotutorial)}
            youtubeUrl="https://www.youtube.com/embed/CIlDDTJyjGE?si=UTJcuen63F7JRI-s"
          />
        </OreStack>
      </CommunicationsTemplatesProvider>
    </PageWrapper>
  )
}
