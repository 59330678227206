export const deliverySiteFormTranslation = {
  form: {
    new: 'deliverySiteFormTranslation.form.new',
    create: 'deliverySiteFormTranslation.form.create',
    dimiss: 'deliverySiteFormTranslation.form.dimiss',
    update: 'deliverySiteFormTranslation.form.update',
    editDeliverySite: 'deliverySiteFormTranslation.form.editDeliverySite',
    updateWarning: 'deliverySiteFormTranslation.form.updateWarning',
  },
  modals: {
    abandon: {
      title: 'deliverySiteFormTranslation.abandonModal.title',
      titleUpdate: 'deliverySiteFormTranslation.abandonModal.titleUpdate',
      description: 'deliverySiteFormTranslation.abandonModal.description',
      backToEdit: 'deliverySiteFormTranslation.abandonModal.backToEdit',
      continue: 'deliverySiteFormTranslation.abandonModal.continue',
    },
    confirmWithoutPhone: {
      title: 'deliverySiteFormTranslation.confirmWithOutPhoneModal.title',
      description: 'deliverySiteFormTranslation.confirmWithOutPhoneModal.description',
      backToEdit: 'deliverySiteFormTranslation.confirmWithOutPhoneModal.backToEdit',
      confirm: 'deliverySiteFormTranslation.confirmWithOutPhoneModal.confirm',
    },
  },
  details: {
    title: 'deliverySiteFormTranslation.details.title',
    deliverySiteName: 'deliverySiteFormTranslation.details.deliverySiteName',
    deliverySiteNamePlaceHolder:
      'deliverySiteFormTranslation.details.deliverySiteName.placeHolder',
    alias: 'deliverySiteFormTranslation.details.alias',
    aliasPlaceHolder: 'deliverySiteFormTranslation.details.alias.placeHolder',
    aliasFooter: 'deliverySiteFormTranslation.details.alias.footer',
  },
  address: {
    title: 'deliverySiteFormTranslation.adress.title',
    addressType: 'deliverySiteFormTranslation.adress.adressType',
    company: 'deliverySiteFormTranslation.adress.adressType.company',
    particular: 'deliverySiteFormTranslation.adress.adressType.particular',
    others: 'deliverySiteFormTranslation.adress.adressType.others',
    othersInfo: 'deliverySiteFormTranslation.adress.adressType.others.info',
    streetName: 'deliverySiteFormTranslation.adress.streetName',
    streetNamePlaceHolder: 'deliverySiteFormTranslation.adress.streetName.placeHolder',
    streetNumber: 'deliverySiteFormTranslation.adress.streetNumber',
    streetNumberPlaceHolder:
      'deliverySiteFormTranslation.adress.streetNumber.placeHolder',
    additionalInfo: 'deliverySiteFormTranslation.adress.additionalInfo',
    additionalInfoPlaceHolder:
      'deliverySiteFormTranslation.adress.additionalInfo.placeHolder',
    additionalInfoFooter: 'deliverySiteFormTranslation.adress.additionalInfo.footer',
    zipCode: 'deliverySiteFormTranslation.adress.zipCode',
    zipCodePlaceHolder: 'deliverySiteFormTranslation.adress.zipCode.placeHolder',
    zipCodeNotFound: 'deliverySiteFormTranslation.address.zipCode.notFound',
    city: 'deliverySiteFormTranslation.adress.city',
    cityPlaceHolder: 'deliverySiteFormTranslation.adress.city.placeHolder',
    province: 'deliverySiteFormTranslation.adress.province',
    provincePlaceHolder: 'deliverySiteFormTranslation.adress.province.placeHolder',
    provinceFooter: 'deliverySiteFormTranslation.address.province.footer',
  },
  shipment: {
    title: 'deliverySiteFormTranslation.shipment.title',
    type: 'deliverySiteFormTranslation.shipment.type',
    messengerService: 'deliverySiteFormTranslation.shipment.messengerService',
    messengerServiceUnavailable:
      'deliverySiteFormTranslation.shipment.messengerService.unavailable',
    info: 'deliverySiteFormTranslation.shipment.messengerService.info',
    mail: 'deliverySiteFormTranslation.shipment.mail',
    mailInfo: 'deliverySiteFormTranslation.shipment.mail.info',
  },
  shipmentContact: {
    title: 'deliverySiteFormTranslation.shipmentContact.title',
    name: 'deliverySiteFormTranslation.shipmentContact.name',
    namePlaceHolder: 'deliverySiteFormTranslation.shipmentContact.name.placeHolder',
    email: 'deliverySiteFormTranslation.shipmentContact.email',
    emailPlaceHolder: 'deliverySiteFormTranslation.shipmentContact.email.placeHolder',
    phone: 'deliverySiteFormTranslation.shipmentContact.phone',
    requiredPrhone: 'deliverySiteFormTranslation.shipmentContact.requiredPhone',
    phonePlaceHolder: 'deliverySiteFormTranslation.shipmentContact.phone.placeHolder',
    phoneFooter: 'deliverySiteFormTranslation.shipmentContact.phone.footer',
    comments: 'deliverySiteFormTranslation.shipmentContact.comments',
    commentsPlaceHolder:
      'deliverySiteFormTranslation.shipmentContact.comments.placeHolder',
    favorite: 'deliverySiteFormTranslation.shipmentContact.favorite',
  },
}
