import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { array, object } from 'yup'
import { productsService } from 'src/Flex/Products/application'
import { edenredProducts } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useConfigureProduct } from 'src/Flex/Products/ui/product-configuration'
import {
  HealthInsuranceConfigurationModel,
  HealthProductModel,
} from 'src/Flex/Products/domain'
import { stringSchema, useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

export interface HealthInsuranceForm {
  policies: string[]
}

interface IUseConfigureHealthInsuranceController {
  load: () => Promise<void>
  save: (data: HealthInsuranceForm) => Promise<boolean>
  downloadConditions: (id: string) => Promise<string>
  getPolicyIcon: (id: string) => Promise<string>
  form: UseFormReturn<HealthInsuranceForm>
  model: HealthInsuranceConfigurationModel | undefined
  isGlobal: boolean
}

export const useConfigureHealthInsuranceController =
  (): IUseConfigureHealthInsuranceController => {
    const { t } = useTranslation()
    const { startLoading, stopLoading } = useLoader()
    const { handleMetaResponse } = useMetaResponseHandler()

    const downloadConditions = async (id: string): Promise<string> => {
      startLoading()
      const response = await productsService().GetHealthProductConditions(id)

      stopLoading()

      handleMetaResponse(response?.meta, undefined, { notifySuccess: false })

      return response.data
    }

    const getPolicyIcon = async (id: string): Promise<string> => {
      startLoading()
      const response = await productsService().GetHealthPolicyIcon(id)

      stopLoading()

      handleMetaResponse(response?.meta, undefined, { notifySuccess: false })

      return response.data
    }

    const hook = useConfigureProduct<HealthInsuranceForm, edenredProducts.salud>({
      productType: edenredProducts.salud,
      formProps: {},
      formValidationScheme: () =>
        object({
          policies: array().of(stringSchema(t)),
        }),
      convertFromAPIToForm: data => {
        const activeArray: string[] = []
        data.length &&
          data.forEach((product: HealthProductModel) => {
            product.active && activeArray.push(product.id)
          })

        return {
          policies: activeArray,
        }
      },
    })

    return { downloadConditions, getPolicyIcon, ...hook }
  }
