import { OreHeading, OreStack, OreText } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { Divider } from 'src/presentation/layout'
import { getUndefinedDashString, getUndefinedEmptyString } from 'src/core/services'
import { forms } from 'src/domain/translations'
import { EmployeesNurseryModel, UserSonModel } from 'src/domain/models'
import { GetDateFormatted } from 'src/core/helpers'
import { KindergartenCard } from './KindergartenCard'

interface EmployeeProfileFormLabelsTGDProps {
  employeeData: EmployeesNurseryModel
}

export const EmployeeProfileFormLabelsTGD = ({
  employeeData,
}: EmployeeProfileFormLabelsTGDProps): JSX.Element => {
  const { t } = useTranslation()

  if (!employeeData) {
    return <></>
  }

  return (
    <div className="pt-1 pb-2">
      <OreStack placeContent="normal" space="xlarge">
        <div className="">
          <OreHeading size="headline-sm">
            {t(employeesTranslation.profile.form.title)}
          </OreHeading>
          <Divider marginTop="1rem" marginBottom="2rem" />
          <OreStack direction="row">
            <OreStack>
              <OreText bold>{t(forms.contact.email)}:</OreText>
              <OreText bold>{t(forms.contact.phone)}:</OreText>
              <OreText bold>
                {t(employeesTranslation.profile.form.employeeNumber)}:
              </OreText>
              <OreText bold>{t(employeesTranslation.profile.form.costCenter)}</OreText>
            </OreStack>
            <OreStack>
              <OreText>{employeeData.userCorporativeEmail} &nbsp;</OreText>
              <OreText>{employeeData.userCorporativeTelephone} &nbsp;</OreText>
              <OreText>{employeeData.userId} &nbsp;</OreText>
              <OreText>{getUndefinedDashString(employeeData.costCenter)} &nbsp;</OreText>
            </OreStack>
          </OreStack>
        </div>
        {employeeData.userSons &&
          employeeData.userSons.map((son: UserSonModel) => (
            <div key={son.userId}>
              <OreHeading size="headline-sm">
                {t(employeesTranslation.profile.form.childTitle)}
              </OreHeading>
              <Divider marginTop="1rem" marginBottom="2rem" />
              <OreStack placeContent="normal" space="large">
                <OreStack direction="row">
                  <OreStack>
                    <OreText bold>{t(forms.contact.name)}:</OreText>
                    <OreText bold>{t(forms.contact.lastName)}:</OreText>
                    <OreText bold>{t(forms.contact.birthdate)}:</OreText>
                  </OreStack>
                  <OreStack>
                    <OreText>{son.userFirstName} &nbsp;</OreText>
                    <OreText>
                      {getUndefinedEmptyString(son.userLastName)}{' '}
                      {getUndefinedEmptyString(son.userLastName2)}
                      &nbsp;
                    </OreText>
                    <OreText>{GetDateFormatted(son.userBirthdayDate)} &nbsp;</OreText>
                  </OreStack>
                </OreStack>

                {son.lastNurseryId && (
                  <OreStack placeContent="normal">
                    <OreHeading size="headline-sm">
                      {t(employeesTranslation.profile.form.lastKindergartenAssignment)}
                    </OreHeading>
                    <KindergartenCard son={son} />
                  </OreStack>
                )}
              </OreStack>
            </div>
          ))}
      </OreStack>
    </div>
  )
}
