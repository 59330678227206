import { Outlet } from 'react-router-dom'
import { PageWrapper } from '../../layout'

export const MyClients = (): JSX.Element => {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  )
}
export default MyClients
