import { useEffect, useState } from 'react'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'

import { tasksService } from 'src/Flex/TaskManager/application'
import { GroupedTasks, TaskModel, TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import { useNavigate } from 'react-router-dom'
import { edenredProducts } from 'src/domain/enum'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { ProductTypeSlugsImputation } from 'src/Flex/Orders/ui/order-imputation'
import { ProductTypeSlugs, Steps } from 'src/Flex/Orders/ui/order-validation'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

export interface IUsePendingTasksController {
  tasks: GroupedTasks
  pendingTasks: boolean
  taskAction: (task: TaskModel) => () => void
  getPendingTasks: () => Promise<void>
  showEmployeeTables: boolean
}

interface usePendingTasksProps {
  actioner: (id: number) => void
}

export const usePendingTasksController = ({
  actioner,
}: usePendingTasksProps): IUsePendingTasksController => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const navigate = useNavigate()

  const [tasks, setTasks] = useState<GroupedTasks>({} as GroupedTasks)
  const [pendingTasks, setPendingTasks] = useState<boolean>(false)
  const [showEmployeeTables, setShowEmployeeTables] = useState<boolean>(true)

  const ValidateTasks = () => {
    if (
      tasks[TaskTypeEnum.pendingImputation].length == 0 &&
      tasks[TaskTypeEnum.pendingValidation].length == 0 &&
      tasks['general'].length == 0 &&
      tasks['employees'].length == 0
    ) {
      setPendingTasks(false)
    } else {
      setPendingTasks(true)
    }
  }

  const getPendingTasks = async (): Promise<void> => {
    startLoading()

    const response = await tasksService().GetPendingTasks()
    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setTasks(response.data)
    }
  }

  const taskAction = (task: TaskModel): (() => void) => {
    switch (task.type) {
      case TaskTypeEnum.pendingContract:
        return () => {
          navigate(flexNavigationRoutes.flexProductsAdherenceEmployeesTab)
        }
      case TaskTypeEnum.pendingAdhesion:
        return () => navigate(flexNavigationRoutes.flexProductsAdherenceProductsTab)
      case TaskTypeEnum.pendingWelcomeCommunication:
        return () => actioner(task.type)
      case TaskTypeEnum.pendingValidation:
        if (task.productType === edenredProducts.ticketRestaurant)
          return () =>
            navigate(
              flexNavigationRoutes.flexOrdersValidate
                .replace(':productType', ProductTypeSlugs.TicketRestaurant)
                .replace(':step', Steps.StepOne)
            )
        else if (task.productType === edenredProducts.ticketTransporte)
          return () =>
            navigate(
              flexNavigationRoutes.flexOrdersValidate
                .replace(':productType', ProductTypeSlugs.Movilidad)
                .replace(':step', Steps.StepOne)
            )
        else
          return () =>
            navigate(
              flexNavigationRoutes.flexOrdersValidate
                .replace(':productType', ProductTypeSlugs.Guarderia)
                .replace(':step', Steps.StepOne)
            )

      case TaskTypeEnum.pendingImputation:
        if (task.productType === edenredProducts.formacion)
          return () =>
            navigate(
              flexNavigationRoutes.flexProductsImputation
                .replace(':productType', ProductTypeSlugsImputation.Formacion)
                .replace(':step', Steps.StepOne)
            )
        else
          return () =>
            navigate(
              flexNavigationRoutes.flexProductsImputation
                .replace(':productType', ProductTypeSlugsImputation.SaludExterno)
                .replace(':step', Steps.StepOne)
            )
      case TaskTypeEnum.pendingOnboardingSteps:
        if (task.step === 1) return () => navigate(flexNavigationRoutes.flexProducts)
        else if (task.step === 2)
          return () => navigate(flexNavigationRoutes.flexCollectives)
        else if (task.step === 3)
          return () => navigate(flexNavigationRoutes.flexCommunicationsTemplates)
        else if (task.step === 4)
          return () => navigate(flexNavigationRoutes.flexEmployees)
        else return () => null
      default:
        return () => null
    }
  }

  useEffect(() => {
    getPendingTasks()
  }, [])

  useEffect(() => {
    if (
      tasks['general'] &&
      tasks['employees'] &&
      tasks['general'].length == 0 &&
      tasks['employees'].length == 0
    ) {
      setShowEmployeeTables(false)
    }
  }, [tasks])

  useEffect(() => {
    if (
      tasks[TaskTypeEnum.pendingImputation] &&
      tasks[TaskTypeEnum.pendingValidation] &&
      tasks['general'] &&
      tasks['employees']
    ) {
      ValidateTasks()
    }
  }, [tasks])

  return { tasks, pendingTasks, taskAction, getPendingTasks, showEmployeeTables }
}
