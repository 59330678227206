import { useContext, useState } from 'react'
import { TGDOrderContext } from './TGDOrderContext'
import {
  EmployeesTGDOrderModel,
  TGDChildModel,
  TGDEmployeeAndChildsModel,
  TGDOrderSummaryForm,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { browserStorageKeys } from 'src/domain/enum'
import { ValidateExcelCheckoutResponse } from 'src/domain/models'

export const TGDOrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [tgdOrders, setTgdOrders] = useState<TGDEmployeeAndChildsModel[]>([])
  const [tgdSummaryForm, setTgdSummaryForm] = useState<TGDOrderSummaryForm>(
    {} as TGDOrderSummaryForm
  )
  const [tgdOrderCheckout, setTgdOrderCheckout] = useState<CardOrderResumeDTO>(
    {} as CardOrderResumeDTO
  )

  const [excelResponse, setExcelResponse] = useState<ValidateExcelCheckoutResponse>()

  const addTDGOrder = (tgdOrder: TGDEmployeeAndChildsModel): void => {
    setTgdOrders([...tgdOrders, { ...tgdOrder }])
  }

  const addTDGOrders = (tgdOrders: TGDEmployeeAndChildsModel[]) => {
    tgdOrders.forEach(order => {
      setTgdOrders(prevOrders => [...prevOrders, order])
    })
  }

  const addOrUpdateTDGOrders = (newOrders: TGDEmployeeAndChildsModel[]) => {
    // current list to check existing orders
    const currentList: TGDEmployeeAndChildsModel[] = [...tgdOrders]
    // new list to state update
    const listToUpdate: TGDEmployeeAndChildsModel[] = [...tgdOrders]

    newOrders.forEach(newRelationship => {
      const indexExistingParentByOrderType: number = currentList.findIndex(
        (o: TGDEmployeeAndChildsModel) =>
          o.document === newRelationship.document &&
          o.tgdOrderType === newRelationship.tgdOrderType
      )

      // already exists?
      if (indexExistingParentByOrderType > -1) {
        const currentOrderChilds: TGDChildModel[] =
          listToUpdate[indexExistingParentByOrderType].childs

        // add or update newRelationship childs to current order childs
        newRelationship.childs.forEach(child => {
          const childIndex: number = currentOrderChilds.findIndex(
            (o: TGDChildModel) => o.userId === child.userId
          )
          // if child exists, update
          if (childIndex > -1) {
            listToUpdate[indexExistingParentByOrderType].childs[childIndex] = child
          } else {
            // child does not exists
            listToUpdate[indexExistingParentByOrderType].childs.push(child)
          }
        })
      }
      // if parent does not exists, add
      else {
        listToUpdate.push(newRelationship)
      }
    })

    setTgdOrders(listToUpdate)
  }

  const removeTGDOrder = (id: string): void => {
    setTgdOrders(tgdOrders => tgdOrders.filter(order => order.id !== id))
  }

  const clearTGDOrders = (): void => {
    setTgdOrders([] as TGDEmployeeAndChildsModel[])
  }

  const getTGDOrderById = (id?: string) => {
    return tgdOrders.find(tgdOrder => tgdOrder.id === id)
  }

  const updateTDGOrder = (tgdOrder: TGDEmployeeAndChildsModel): void => {
    const index: number = tgdOrders.findIndex(
      (o: TGDEmployeeAndChildsModel) => o.id === tgdOrder.id
    )
    const updatedOrders: TGDEmployeeAndChildsModel[] = [...tgdOrders]
    updatedOrders[index] = tgdOrder
    setTgdOrders(updatedOrders)
  }

  const addTDGSummaryForm = (form: TGDOrderSummaryForm): void => {
    setTgdSummaryForm({ ...form })
  }

  const clearTGDSummaryForm = (): void => {
    setTgdSummaryForm({} as TGDOrderSummaryForm)
  }

  const addTDGOrderCheckout = (checkout: CardOrderResumeDTO): void => {
    setTgdOrderCheckout({ ...checkout })
  }

  const clearTGDOrderCheckout = (): void => {
    setTgdOrderCheckout({} as CardOrderResumeDTO)
  }

  const setTGDEmployeesToConfig = (employees: EmployeesTGDOrderModel[]): void => {
    sessionStorage.setItem(
      browserStorageKeys.tgdOrderEmployees,
      JSON.stringify(employees)
    )
  }

  const getTGDEmployeesToConfig = (): EmployeesTGDOrderModel[] => {
    const employees = sessionStorage.getItem(browserStorageKeys.tgdOrderEmployees)
    return employees ? JSON.parse(employees) : []
  }

  const addExcelResponse = (response: ValidateExcelCheckoutResponse): void => {
    setExcelResponse(response)
  }

  const context = {
    addTDGOrder,
    tgdOrders,
    removeTGDOrder,
    clearTGDOrders,
    getTGDOrderById,
    updateTDGOrder,
    tgdSummaryForm,
    addTDGSummaryForm,
    clearTGDSummaryForm,
    tgdOrderCheckout,
    addTDGOrderCheckout,
    clearTGDOrderCheckout,
    setTGDEmployeesToConfig,
    getTGDEmployeesToConfig,
    addTDGOrders,
    addOrUpdateTDGOrders,
  }

  return <TGDOrderContext.Provider value={context}>{children}</TGDOrderContext.Provider>
}

export const useTGDOrder = () => useContext(TGDOrderContext)
