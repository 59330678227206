/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBlocker } from './useBlocker'
import { allowedRoutesToNavigate } from 'src/config/constants/routesAllowedForCardOrder'

interface CallbackPromptState {
  showPrompt: boolean
  confirmNavigation: () => void
  cancelNavigation: () => void
  showCancelModal: boolean
}

export function useCallbackPrompt(when: boolean): CallbackPromptState {
  const navigate = useNavigate()
  const location = useLocation()
  const [showPrompt, setShowPrompt] = useState(false)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
    setLastLocation(null)
  }, [])

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      // in this codition we allow navigation in the order process
      if (allowedRoutesToNavigate.includes(nextLocation.location.pathname)) {
        setConfirmedNavigation(true)
        setLastLocation(nextLocation)
        return true
      }
      // in this condition we set the modal translates if comes from cancel button
      if (nextLocation.location.state?.cancelModal) {
        setShowCancelModal(true)
      } else {
        setShowCancelModal(false)
      }
      // in this condition we unlock navigation by unlockNavigation parameter in the state
      if (nextLocation.location.state?.unlockNavigation) {
        setConfirmedNavigation(true)
        setLastLocation(nextLocation)
        return true
      }
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true)
        setLastLocation(nextLocation)
        return false
      }
      return true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  )

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
  }, [])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(
        {
          pathname: lastLocation.location?.pathname,
          search: lastLocation.location?.search,
        },
        {
          replace: lastLocation.location.replace,
          state: lastLocation.location.state,
        }
      )
      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation])

  useBlocker(handleBlockedNavigation, when)

  return { showPrompt, confirmNavigation, cancelNavigation, showCancelModal }
}
