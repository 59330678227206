import { useUnloadEmployees } from '../hooks/useUnloadEmployees'
import { UnloadEmployeesFooterButtons, UnloadEmployeesTable } from '../molecules'

export const UnloadEmployees = (): JSX.Element => {
  const props = useUnloadEmployees()
  return (
    <>
      <UnloadEmployeesTable unloadEmpoyeesProps={props} />
      <UnloadEmployeesFooterButtons unloadEmpoyeesProps={props} />
    </>
  )
}
