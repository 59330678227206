import { OreButton, OreDatePicker, OreHeading, OreStack, SvgEdit } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useChildProfileFormController } from '../hooks'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { Divider } from 'src/presentation/layout'
import { MessageBar, Textbox } from 'src/presentation/components/Edenred'
import { forms } from 'src/domain/translations'
import { UserSonModel } from 'src/domain/models'
import { KindergartenCard } from './KindergartenCard'
import { maxChildAge, minChildAge } from 'src/core/helpers'

interface EmployeeProfileChildFormProps {
  sonData: UserSonModel
}

export const EmployeeProfileChildForm = ({
  sonData,
}: EmployeeProfileChildFormProps): JSX.Element => {
  const {
    register,
    errors,
    GetEmployeeDataTextBoxes,
    updateSon,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  } = useChildProfileFormController(sonData)
  const { t } = useTranslation()

  if (!sonData) {
    return <></>
  }

  return (
    <div className="pt-1">
      <OreHeading size="headline-md">
        {t(employeesTranslation.profile.form.childTitle)}
      </OreHeading>
      <Divider marginTop="1rem" marginBottom="1.5rem" />
      <OreStack placeContent="normal" space="large">
        <div>
          <div className="mb-105">
            <Textbox
              errors={errors}
              register={register}
              textBoxList={GetEmployeeDataTextBoxes()}
            />
            <div className="form-atom">
              <OreDatePicker
                {...register('userBirthdayDate')}
                label={t(forms.contact.birthdate)}
                id="userBirthdayDate"
                legend={t(forms.info.correctingDateIsNecessary)}
                min={maxChildAge()}
                max={minChildAge()}
                hasError={!!errors?.userBirthdayDate}
                errorMessage={errors?.userBirthdayDate?.message}
                disabled={!enableEdit}
                required
              />
            </div>
          </div>

          <MessageBar color="error" message={errorMessage} />

          {enableEdit ? (
            <OreButton category="primary" onClick={handleSubmit(updateSon)}>
              {t(forms.buttons.save)}
            </OreButton>
          ) : (
            <OreButton icon={<SvgEdit />} onClick={handleEnableEdit}>
              {t(forms.buttons.editData)}
            </OreButton>
          )}
        </div>
        {sonData.lastNurseryId && (
          <KindergartenCard
            son={sonData}
            footerInfoText="Para editar esta asignación, debe hacerlo en el próximo pedido"
          />
        )}
      </OreStack>
    </div>
  )
}
