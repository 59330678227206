export const historyConsumptionsTranslations = {
  amount: 'historyConsumptions.amount',
  consumptionDate: 'historyConsumptions.consumptionDate',
  date: 'historyConsumptions.date',
  type: 'historyConsumptions.type',
  recharge: 'historyConsumptions.recharge',
  unload: 'historyConsumptions.unload',
  all: 'historyConsumptions.all',
  consumed: 'historyConsumptions.consumed',
  filters: {
    searchBoxPlaceholder: 'historyConsumptions.filters.searchBoxPlaceholder',
    groupby: 'historyConsumptions.filters.groupBy',
    groupByUser: 'historyConsumptions.filters.groupByUser',
    groupByUserAndDate: 'historyConsumptions.filters.groupByUserAndDate',
  },
}
