export const flexCollectivesTranslations = {
  title: 'flex.collectives.title',
  onboarding: 'flex.collectives.onboarding',
  onboarding_edit: 'flex.collectives.onboarding_edit',
  cta_create: 'flex.collectives.cta_create',
  videotutorial: 'flex.collectives.videotutorial',
  table: {
    title: 'flex.collectives.table.title',
    header: {
      name: 'flex.collectives.table.header.name',
      products: 'flex.collectives.table.header.products',
      employees: 'flex.collectives.table.header.employees',
      actions: 'flex.collectives.table.header.actions',
    },
    noCollectives: 'flex.collectives.table.no_collectives',
  },
  configure: {
    create: {
      title: 'flex.collectives.configure.create.title',
    },
    edit: {
      title: 'flex.collectives.configure.edit.title',
      products: {
        title: 'flex.collectives.configure.edit.products.title',
        subtitle: 'flex.collectives.configure.edit.products.subtitle',
        noProducts: 'flex.collectives.configure.edit.products.no_products',
        error: {
          exceded: 'flex.collectives.configure.edit.products.error.exceded',
          date: 'flex.collectives.configure.edit.products.error.date',
          months: 'flex.collectives.configure.edit.products.error.months',
        },
        common: {
          resetValuesTitle:
            'flex.collectives.configure.edit.products.common.reset_values_title',
          resetValuesButton:
            'flex.collectives.configure.edit.products.common.reset_values_button',
        },
        healthExternal: {
          table: {
            header: {
              company:
                'flex.collectives.configure.edit.products.health_external.table.header.company',
              policyName:
                'flex.collectives.configure.edit.products.health_external.table.header.policyName',
              monthlyImport:
                'flex.collectives.configure.edit.products.health_external.table.header.monthlyImport',
            },
          },
        },
      },
    },
    delete: {
      iconLabel: 'flex.collectives.configure.delete.icon_label',
      form: {
        title: 'flex.collectives.configure.delete.form.title',
        active: 'flex.collectives.configure.delete.form.active',
        inactive: 'flex.collectives.configure.delete.form.inactive',
        isalud: 'flex.collectives.configure.delete.form.isalud',
      },
      select: {
        disclaimer: 'flex.collectives.configure.delete.select.disclaimer',
        disclaimer2: 'flex.collectives.configure.delete.select.disclaimer_2',
        placeholder: 'flex.collectives.configure.delete.select.placeholder',
        label: 'flex.collectives.configure.delete.select.label',
      },
    },
    common: {
      form: {
        title: 'flex.collectives.configure.common.form.title',
        collectiveName: {
          label: 'flex.collectives.configure.common.form.collective_name.label',
          placeholder:
            'flex.collectives.configure.common.form.collective_name.placeholder',
        },
        collectivePRF: {
          label: 'flex.collectives.configure.common.form.collective_prf.label',
          placeholder:
            'flex.collectives.configure.common.form.collective_prf.placeholder',
          legend: 'flex.collectives.configure.common.form.collective_prf.legend',
          error: 'flex.collectives.configure.common.form.collective_prf.error',
        },
        edit: {
          label: 'flex.collectives.configure.common.form.edit.label',
        },
      },
    },
  },
}
