import { getPermissionTags, singleUserData } from '../../../config/constants/endpoints'
import type { HttpModel } from '../../../domain/models'
import type { UserModel } from '../../../domain/models/myAccount/UserModel'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../axios'

export default class ClientUserRepository {
  async GetUserData(): Promise<HttpModel<UserModel>> {
    const { url, method, body }: HttpRequest = {
      url: singleUserData,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, {})
    return response
  }

  async GetUserPermissionTags(): Promise<HttpModel<string[]>> {
    const { url, method, body }: HttpRequest = {
      url: getPermissionTags,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, {})
    return response
  }
}
