import { edenredProducts } from 'src/domain/enum'

export enum HistorySpendingRequestParams {
  productCode = 'productCode',
  multiFilter = 'multiFilter',
  beginDate = 'beginDate',
  endDate = 'endDate',
  groupBy = 'groupBy',
}

export interface HistorySpendingParams {
  [HistorySpendingRequestParams.productCode]?: number
  [HistorySpendingRequestParams.multiFilter]?: string
  [HistorySpendingRequestParams.beginDate]?: string
  [HistorySpendingRequestParams.endDate]?: string
  [HistorySpendingRequestParams.groupBy]?: number
}

export interface HistorySpendingRequestQuery extends HistorySpendingParams {
  paginationModel: IPostPaginationModel
}

export interface useHistorySpendingProps {
  showFilter: boolean
  pageSize: number
  showAllTotalRow?: boolean
  productCode: edenredProducts
}

export interface IPostPaginationModel {
  numberOfRecordsPerPage: number
  pageNumber: number
}
