import { useTranslation } from 'react-i18next'
import { SimpleForm } from 'src/presentation/Templates'
// import { Form } from './organism'
import { spendingRuleTranslation } from 'src/domain/translations'
import { SpendingRulesEditContainer } from './organism'

export const SpendingRulesEditPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SimpleForm
      title={t(spendingRuleTranslation.editPage.title)}
      organisms={[<SpendingRulesEditContainer />]}
    />
  )
}
