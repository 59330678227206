import { useRef, useState } from 'react'
import i18n from 'i18next'
import { useNavigate } from 'react-router-dom'

import { useLoader } from '../context/loader/LoaderProvider'
import { useNotification } from '../context/notification/NotificationProvider'
import type { MessagesModel, MetaModel } from '../../domain/models/meta/MetaModel'
import { contentErrors } from '../../domain/definitions'
import { forms } from '../../domain/translations'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'

export interface NotificationHandler {
  notificationHandler: (
    res: MetaModel,
    successNotification?: boolean,
    successAction?: any,
    navigateTo?: string
  ) => void
  handleError: (res: any) => void
  showTopAlert: boolean
  setShowTopAlert: React.Dispatch<React.SetStateAction<boolean>>
  topAlertMsgs: MessagesModel[] | []
  setTopAlertMsgs: React.Dispatch<React.SetStateAction<MessagesModel[]>>

  ref: React.RefObject<HTMLDivElement>
}

export const useNotificationHandler = (): NotificationHandler => {
  const { t } = i18n
  const { stopLoading } = useLoader()
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const [showTopAlert, setShowTopAlert] = useState(false)
  const [topAlertMsgs, setTopAlertMsgs] = useState<MessagesModel[]>([])

  const ref = useRef<HTMLDivElement>(null)

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current && ref.current.scrollIntoView({ block: 'nearest' })
  }

  const notificationHandler = (
    res: MetaModel,
    successNotification?: boolean,
    successAction?: any,
    navigateTo?: string
  ) => {
    if (res?.status === MetaStatusCodes.SUCCESS || res?.status === MetaStatusCodes.INFO) {
      stopLoading()
      successNotification &&
        addNotification(t(forms.success.message), NotificationSeverity.success)
      successAction && successAction()
      navigateTo && navigate(navigateTo)
    }
    handleError(res)
  }

  const handleError = (res: MetaModel) => {
    if (res?.status === MetaStatusCodes.ERROR) {
      stopLoading()
      const contentError = res.messages.filter((msg: MessagesModel) =>
        contentErrors.some((error: any) => msg.code === error.type)
      )

      if (contentError.length > 0) {
        const msgs = contentError.map((msg: MessagesModel) => msg.description).join(' ')

        addNotification(msgs, NotificationSeverity.error)
      } else {
        addNotification(res.messages[0].description, NotificationSeverity.error)
      }
    }

    if (!res) {
      stopLoading()
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }

    if (res === undefined) {
      stopLoading()
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }

    if (res === null) {
      stopLoading()
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }
  }

  return {
    notificationHandler,
    handleError,
    showTopAlert,
    setShowTopAlert,
    topAlertMsgs,
    ref,
    setTopAlertMsgs,
  }
}
