import { useLoader } from '../../../context/loader/LoaderProvider'

const Loader = () => {
  const { isLoading } = useLoader()

  return (
    <div style={isLoading ? { display: 'block' } : { display: 'none' }}>
      <div className="loader--container">
        <div className="spinner-app"></div>
      </div>
    </div>
  )
}

export default Loader
