import { t } from 'i18next'
import { unloadsConfigurationTranslations } from '../../../../domain/translations'
import { SimpleForm } from '../../../Templates'
import { UnloadConfigurationEmployeeContainer } from './organism/UnloadConfigurationEmployeeContainer'

export const UnloadsConfigurationPage = (): JSX.Element => {
  return (
    <SimpleForm
      title={t(unloadsConfigurationTranslations.title)}
      organisms={[<UnloadConfigurationEmployeeContainer />]}
    />
  )
}
