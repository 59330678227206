import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { forms } from '../../domain/translations'
import { maxChildAge, minChildAge } from 'src/core/helpers'

export const useEmployeeEditProfileSchemaTGD = () => {
  const { t } = useTranslation()
  const employeeEditParentProfileSchema: any = Yup.object().shape({
    userCorporativeEmail: Yup.string()
      .email(t(forms.errors.invalidFormat))
      .required(t(forms.errors.fieldRequired)),
    userCorporativeTelephone: Yup.string().test(
      'validatePhone',
      t(forms.errors.phoneInvalid),
      value => {
        if (value && value.length > 0) return /(6|7|8|9)[ -]*([0-9][ -]*){8}$/.test(value)
        return true
      }
    ),
    employeeNumber: Yup.string(),
  })

  const employeeEditChildProfileSchema: any = Yup.object().shape({
    userFirstName: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(75, t(forms.errors.maxAllowed)),
    userLastName: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(75, t(forms.errors.maxAllowed)),
    userLastName2: Yup.string().max(75, t(forms.errors.maxAllowed)),
    userBirthdayDate: Yup.date()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required(t(forms.errors.fieldRequired))
      .min(maxChildAge(), `${t(forms.errors.maxAge).replace('{{- age}}', '7')}`)
      .max(minChildAge(), `${t(forms.errors.minAge).replace('{{- age}}', '0')}`),
  })

  return { employeeEditParentProfileSchema, employeeEditChildProfileSchema }
}
