import {
  OreCheckbox,
  OreErrorText,
  OreHelperText,
  OreLabel,
  OreStack,
} from '@runroom/oreneta'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'

interface IProps {
  label: string
  fieldName: string
  hasError: boolean
  required: boolean
  errorMessage?: string
  legend?: string
  enabledMonths?: string[]
}

export const MonthCheckboxesGroup = ({
  label,
  fieldName,
  hasError,
  required = true,
  errorMessage,
  legend,
  enabledMonths,
}: IProps): JSX.Element => {
  const { register } = useFormContext()
  const { t } = useTranslation()

  return (
    <OreStack space="xsmall" placeContent="stretch">
      <OreLabel required={required}>{label}</OreLabel>
      <OreStack direction="row" space="xlarge" placeContent="stretch">
        {[...Array(4)].map(
          (_, column: number): JSX.Element => (
            <OreStack direction="column" key={column}>
              {Object.entries(forms.months).map(
                ([month, label]: [string, string], index: number): JSX.Element | null =>
                  index % 4 === column ? (
                    <OreCheckbox
                      key={index}
                      {...register(fieldName)}
                      disabled={
                        undefined !== enabledMonths && !enabledMonths.includes(month)
                      }
                      label={t(label)}
                      value={month}
                    />
                  ) : null
              )}
            </OreStack>
          )
        )}
      </OreStack>
      {legend ? <OreHelperText text={legend} /> : null}
      {hasError ? <OreErrorText>{errorMessage}</OreErrorText> : null}
    </OreStack>
  )
}
