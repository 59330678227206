import { OreFormGroup, OreHeading, OreRadioButtonBoxList } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { UseFormReturn } from 'react-hook-form'
import { UsersAndLicensesFormModel } from 'src/domain/models/myAccount/UsersAndLicenses'

interface Props {
  form: UseFormReturn<UsersAndLicensesFormModel, any>
}
export const AccountUsersAndLicensesUserProfiles = ({ form }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="mb-105">
        <OreHeading size="headline-sm">
          {t(accountUsersLicensesTranslations.add.userProfile)}
        </OreHeading>
      </div>
      <OreFormGroup direction="row">
        <OreRadioButtonBoxList
          {...form.register('profileId')}
          id="visor"
          itemsList={[
            t(accountUsersLicensesTranslations.add.license1),
            t(accountUsersLicensesTranslations.add.license2),
          ]}
          label={t(accountUsersLicensesTranslations.add.visor)}
          value={1}
          defaultChecked={form.getValues('profileId') === 1}
        />
        <OreRadioButtonBoxList
          {...form.register('profileId')}
          id="admin"
          itemsList={[
            t(accountUsersLicensesTranslations.add.license1),
            t(accountUsersLicensesTranslations.add.license2),
            t(accountUsersLicensesTranslations.add.license3),
            t(accountUsersLicensesTranslations.add.license4),
          ]}
          label={t(accountUsersLicensesTranslations.add.admin)}
          value={2}
          defaultChecked={form.getValues('profileId') === 2}
        />
        <OreRadioButtonBoxList
          {...form.register('profileId')}
          id="superAdmin"
          itemsList={[
            t(accountUsersLicensesTranslations.add.license1),
            t(accountUsersLicensesTranslations.add.license2),
            t(accountUsersLicensesTranslations.add.license3),
            t(accountUsersLicensesTranslations.add.license4),
            t(accountUsersLicensesTranslations.add.license5),
            t(accountUsersLicensesTranslations.add.license6),
          ]}
          label={t(accountUsersLicensesTranslations.add.superAdmin)}
          value={3}
          defaultChecked={form.getValues('profileId') === 3}
        />
      </OreFormGroup>
    </div>
  )
}
