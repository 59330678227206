import { SvgArrowWidget } from '@runroom/oreneta'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CaptionModel, FooterModel, RowModel } from 'src/domain/customComponents/table'
import { orderHomeTranslation } from 'src/domain/translations/cardOrder/home'
import { TaskCheckModel, TaskModel, TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import {
  Default,
  FlexHomeTables,
  ManagementEmployessTable,
  PendingTable,
} from '../strategies'
import { usePendingTasks } from '../contexts'

interface PendingTasksModuleModel {
  caption: CaptionModel
  rows: RowModel[]
  footer: FooterModel
  taskCheckModel: TaskCheckModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
}

export const usePendigTasksModuleController = (
  tasks: TaskModel[],
  title: string,
  taskType: string | TaskTypeEnum
): PendingTasksModuleModel => {
  const { taskAction } = usePendingTasks()
  const [rows, setRows] = useState<RowModel[]>([])

  const handleAction = (task: TaskModel) => {
    taskAction(task)()
  }

  const GetStrategy = (): FlexHomeTables<TaskModel> => {
    let strategy: FlexHomeTables<TaskModel> = new FlexHomeTables(new Default())
    if (
      taskType === TaskTypeEnum.pendingValidation ||
      taskType === TaskTypeEnum.pendingImputation
    ) {
      strategy = new FlexHomeTables(new PendingTable())
    }
    if (taskType === 'employees' || taskType === 'general') {
      strategy = new FlexHomeTables(new ManagementEmployessTable())
    }
    return strategy
  }

  const GetCaption = (): CaptionModel => {
    const strategy: FlexHomeTables<TaskModel> = GetStrategy()
    return strategy.GetCaption(title, tasks.length)
  }
  const GetRows = (): RowModel[] => {
    const strategy: FlexHomeTables<TaskModel> = GetStrategy()
    return strategy.GetRows(tasks, handleAction, taskType)
  }
  const GetTaskCheck = (): TaskCheckModel => {
    const strategy: FlexHomeTables<TaskModel> = GetStrategy()
    return strategy.GetTaskCheck(taskType)
  }

  const caption: CaptionModel = GetCaption()

  const footer: FooterModel = {}
  const taskCheckModel: TaskCheckModel = GetTaskCheck()

  useEffect(() => {
    if (tasks.length > 0) {
      setRows(GetRows())
    }
  }, [tasks])
  return {
    caption,
    rows,
    footer,
    taskCheckModel,
    setRows,
  }
}
