import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  TGDOrderService,
  hasRepeatedChildsByName,
  hasRepeatedChildsByBirthDate,
} from 'src/core/services/tgdOrderService'
import { TextBoxRowModel } from 'src/domain/customComponents'
import {
  MetaStatusCodes,
  NotificationSeverity,
  currency,
  tgdOrderType,
} from 'src/domain/enum'
import { MessagesModel } from 'src/domain/models'
import {
  TGDChildModel,
  TGDEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { useTGDOrderFormSchema } from '../validations/useTGDOrderFormSchema'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import {
  ConvertStringDateToISOOnly,
  createOrderId,
  formatDateToISO,
} from 'src/core/helpers'
import { EmployeesService } from 'src/core/services/employeesService'
import { formatISO } from 'date-fns'

interface ControlState {
  form: UseFormReturn<TGDEmployeeAndChildsModel, any>
  employeeTextBoxes: TextBoxRowModel<TGDEmployeeAndChildsModel>[]
  getChildTextBoxes: (index: number) => TextBoxRowModel<TGDEmployeeAndChildsModel>[]
  handleAddChild: () => void
  handleDeleteChild: (childIndex: number) => void
  onValidateBeforeSubmit: (newUserAndChilds: TGDEmployeeAndChildsModel) => Promise<void>
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  showDeleteModal: boolean
  deletePopupButtons: PopupButtonModel[]
  onClickDelete(childIndex: number): void
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  showRepeatedChildModal: boolean
  setShowRepeatedChildModal: Dispatch<SetStateAction<boolean>>
  repeatedChildPopupButtons: PopupButtonModel[]
  allowRepeatedChildRef: React.RefObject<HTMLInputElement>
}

export const useTGDOrderFormController = (
  tgdOrderItemToEdit?: TGDEmployeeAndChildsModel, // Este objeto se ocupa no solo en edicion, sino tambien en nuevo Solo Hijo
  isChildForm?: boolean,
  isNew?: boolean
): ControlState => {
  const { TGDOrderFormSchema, TGDOrderChildOnlyFormSchema } = useTGDOrderFormSchema()
  const allowRepeatedChildRef = useRef<HTMLInputElement>(null)
  const [employeeTextBoxes, setEmployeeTextBoxes] = useState<
    TextBoxRowModel<TGDEmployeeAndChildsModel>[]
  >([])
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const { addNotification } = useNotification()
  const { user } = useAuth()
  const { addTDGOrder, updateTDGOrder, clearTGDOrders, tgdOrders } = useTGDOrder()
  const navigate = useNavigate()

  const {
    show: showDeleteModal,
    buttons: deletePopupButtons,
    setShow: setShowDeleteModal,
    setButtons: setDeleteModalButtons,
  } = useModalController()

  const {
    show: showCancelModal,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const {
    show: showRepeatedChildModal,
    buttons: repeatedChildPopupButtons,
    setShow: setShowRepeatedChildModal,
    setButtons: setRepeatedChildModalButtons,
  } = useModalController()

  const newChild: TGDChildModel = {
    firstName: '',
    firstName2: '',
    lastName: '',
    lastName2: '',
    kindergartenId: 0,
    kindergartenData: undefined,
  }

  const form = useForm<TGDEmployeeAndChildsModel>({
    resolver: yupResolver(isChildForm ? TGDOrderChildOnlyFormSchema : TGDOrderFormSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: tgdOrderItemToEdit
      ? {
          ...tgdOrderItemToEdit,
          childs: isNew
            ? [...tgdOrderItemToEdit.childs, newChild] // add empty child to array when New
            : tgdOrderItemToEdit.childs,
        }
      : {
          childs: [newChild],
        },
  })

  const InitEmployeeTextBoxes = () => {
    setEmployeeTextBoxes([
      {
        className: 'form-atom',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'name',
            label: t(forms.contact.name),
            name: 'name',
            placeHolder: t(forms.placeholders.name),
            required: true,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'firstSurname',
            label: t(forms.contact.firstLastName),
            name: 'firstSurname',
            placeHolder: t(forms.placeholders.firstLastname),
            required: true,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
          {
            id: 'secondSurname',
            label: t(forms.contact.secondLastName),
            name: 'secondSurname',
            placeHolder: t(forms.placeholders.secondLastname),
            required: false,
            maxLength: 20,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'email',
            label: t(forms.contact.corporativeEmail),
            name: 'email',
            placeHolder: t(forms.placeholders.corporativeEmail),
            required: true,
            maxLength: 75,
            type: 'email',
            autoComplete: 'off',
          },
          {
            id: 'telephone',
            label: t(forms.contact.contactPhone),
            name: 'telephone',
            placeHolder: t(forms.placeholders.contactPhone),
            required: false,
            maxLength: 9,
            type: 'tel',
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom form-atom--half',
        rowNoSidePadding: true,
        textBoxes: [
          {
            id: 'costCenter',
            label: t(forms.employee.costCenter),
            name: 'costCenter',
            placeHolder: t(forms.placeholders.employee.costCenter),
            required: false,
            maxLength: 75,
            type: 'text',
            autoComplete: 'off',
          },
          {
            id: 'employeeNumber',
            label: t(forms.employee.employeeNumber),
            name: 'employeeNumber',
            placeHolder: t(forms.placeholders.employee.employeeNumber),
            required: false,
            maxLength: 14,
            type: 'text',
            autoComplete: 'off',
          },
        ],
      },
    ])
  }

  const getChildTextBoxes = (
    index: number
  ): TextBoxRowModel<TGDEmployeeAndChildsModel>[] => [
    {
      className: 'form-atom',
      rowNoSidePadding: true,
      textBoxes: [
        {
          id: `childs_${index}_firstName`,
          label: t(forms.contact.name),
          name: `childs.${index}.firstName`,
          placeHolder: t(forms.placeholders.name),
          required: true,
          maxLength: 20,
          type: 'text',
          autoComplete: 'off',
        },
      ],
    },
    {
      className: 'form-atom form-atom--half',
      rowNoSidePadding: true,
      textBoxes: [
        {
          id: `childs_${index}_lastName`,
          label: t(forms.contact.firstLastName),
          name: `childs.${index}.lastName`,
          placeHolder: t(forms.placeholders.firstLastname),
          required: true,
          maxLength: 20,
          type: 'text',
          autoComplete: 'off',
        },
        {
          id: `childs_${index}_lastName2`,
          label: t(forms.contact.secondLastName),
          name: `childs.${index}.lastName2`,
          placeHolder: t(forms.placeholders.secondLastname),
          required: false,
          maxLength: 20,
          type: 'text',
          autoComplete: 'off',
        },
      ],
    },
    {
      className: 'form-atom',
      rowNoSidePadding: true,
      textBoxes: [
        {
          id: `childs_${index}_initialAmount`,
          label: t(TGDOrderTranslation.form.child.rechargeAmount.label),
          name: `childs.${index}.initialAmount`,
          legend: t(TGDOrderTranslation.form.child.rechargeAmount.helpText),
          placeHolder: t(forms.placeholders.order.amount),
          required: true,
          maxLength: 5,
          startAdornment: currency.euro,
          type: 'number',
          autoComplete: 'off',
        },
      ],
    },
  ]

  const handleAddChild = (): void => {
    form.setValue('childs', [...form.getValues('childs'), newChild])
  }

  const handleDeleteChild = (childIndex: number): void => {
    const updatedArray = [...form.getValues('childs')]
    updatedArray.splice(childIndex, 1)
    form.setValue('childs', updatedArray)
    form.clearErrors('childs')
    form.trigger('childs')
  }

  const existsRepeatedChilds = (userAndChild: TGDEmployeeAndChildsModel): boolean => {
    // Check if user enters duplicated childs (same Name or BirthDate)
    const allChilds = userAndChild.existingChilds
      ? [...userAndChild.existingChilds, ...userAndChild.childs]
      : userAndChild.childs

    const repeatedChildByNameIndex = hasRepeatedChildsByName(allChilds)
    const repeatedChildByBirthDateIndex = hasRepeatedChildsByBirthDate(allChilds)

    // check repeated Childs by name
    if (repeatedChildByNameIndex > -1) {
      // Modify index to set error to the correct child form item
      const errorChildIndex = userAndChild.existingChilds
        ? repeatedChildByNameIndex - userAndChild.existingChilds.length
        : repeatedChildByNameIndex
      // Set and show error in form
      form.setError(`childs.${errorChildIndex}.firstName`, {
        type: 'manual',
        message: t(TGDOrderTranslation.form.child.errors.repeatedName),
      })

      stopLoading()
      return true
    }

    // check repeated Childs by birthDate
    if (repeatedChildByBirthDateIndex > -1) {
      // Modify index to set error to the correct child form item
      const errorChildIndex = userAndChild.existingChilds
        ? repeatedChildByBirthDateIndex - userAndChild.existingChilds.length
        : repeatedChildByBirthDateIndex
      // Set and show error in form
      form.setError(`childs.${errorChildIndex}.birthDate`, {
        type: 'manual',
      })

      // Show repeated birthdate child modal
      setShowRepeatedChildModal(true)
      setRepeatedChildModalButtons(getRepeatedChildModalButtons())

      stopLoading()
      return true
    }

    return false
  }

  const normalizeUserAndChild = (
    newUserAndChilds: TGDEmployeeAndChildsModel
  ): TGDEmployeeAndChildsModel => {
    const normalizedUserAndChild: TGDEmployeeAndChildsModel = {
      ...newUserAndChilds,
      childs: newUserAndChilds.childs.map((child: TGDChildModel) => {
        return {
          ...child,
          birthDate: child.birthDate ? formatDateToISO(child.birthDate) : undefined,
        }
      }),
      companyId: user && user.code ? +user.code : 0,
      tgdOrderType: isChildForm ? tgdOrderType.childOnly : tgdOrderType.employeeAndChild,
      id: tgdOrderItemToEdit ? tgdOrderItemToEdit.id : createOrderId(),
    }
    return normalizedUserAndChild
  }

  const onValidateBeforeSubmit = async (
    newUserAndChilds: TGDEmployeeAndChildsModel
  ): Promise<void> => {
    const userAndChild = normalizeUserAndChild(newUserAndChilds)

    if (existsRepeatedChilds(userAndChild)) return

    await onRegisterAndAssign(userAndChild)
  }

  const onRegisterAndAssign = async (
    userAndChild: TGDEmployeeAndChildsModel
  ): Promise<void> => {
    const isValid = await form.trigger()
    if (!isValid) return

    // Check if employee exists in TGD Orders context(current order)
    const employeeDocument = userAndChild.document
    startLoading()

    const tgdOrderExists = tgdOrders.find(
      tgdOrder => tgdOrder.document === employeeDocument
    )
    if (tgdOrderExists && isNew) {
      TriggerErrorExistsInOrder()
      stopLoading()
      return
    }
    // Check if employee exists in Server side
    const employeExistsInServer = await EmployeesService().EmployeeNurseryExists(
      employeeDocument
    )
    if (employeExistsInServer && !isChildForm) {
      TriggerErrorExistsInServer()
      stopLoading()
      return
    }

    TGDOrderService()
      .validate(userAndChild)
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          if (tgdOrderItemToEdit && !isNew) {
            // si o si edit mode
            updateTDGOrder(userAndChild) // check
          } else {
            addTDGOrder(userAndChild) // check
          }
          navigate(navigationRoutes.tgdOrderSummary)
          addNotification(t(forms.success.message), NotificationSeverity.success)
        } else {
          if (response?.meta.messages.length > 0) {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                form.setError(error.value as any, {
                  type: 'value',
                  message: error.description,
                })
              }
            })
          }
        }
      })
      .finally(() => stopLoading())
  }

  const onClickDelete = (childIndex: number): void => {
    setShowDeleteModal(true)
    setDeleteModalButtons(getDeleteModalButtons(childIndex))
  }

  const getDeleteModalButtons = (childIndex: number): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowDeleteModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => {
          handleDeleteChild(childIndex)
          setShowDeleteModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
        },
        size: 'large',
      },
    ]
  }

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => {
          clearTGDOrders()
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(-1)
        },
        size: 'large',
      },
    ]
  }

  const getRepeatedChildModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowRepeatedChildModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.accept),
        category: 'primary',
        onClick: () => {
          if (allowRepeatedChildRef?.current?.checked === true) {
            const userAndChild = normalizeUserAndChild(form.getValues())
            onRegisterAndAssign(userAndChild)
          }
          setShowRepeatedChildModal(false)
        },
        size: 'large',
      },
    ]
  }

  function TriggerErrorExistsInServer() {
    form.setError('document', {
      type: 'manual',
      message: t(TGDOrderTranslation.form.user.errors.existsInServer),
    })
  }
  function TriggerErrorExistsInOrder() {
    form.setError('document', {
      type: 'manual',
      message: t(TGDOrderTranslation.form.user.errors.existsInOrder),
    })
  }
  useEffect(() => {
    InitEmployeeTextBoxes()
  }, [])

  return {
    employeeTextBoxes,
    getChildTextBoxes,
    handleAddChild,
    handleDeleteChild,
    onValidateBeforeSubmit,
    form,
    setShowDeleteModal,
    showDeleteModal,
    deletePopupButtons,
    onClickDelete,
    setShowCancelModal,
    showCancelModal,
    cancelPopupButtons,
    onClickCancel,
    showRepeatedChildModal,
    setShowRepeatedChildModal,
    repeatedChildPopupButtons,
    allowRepeatedChildRef,
  }
}
