import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { edenredProducts } from 'src/domain/enum'
import { IProductsTabModel } from 'src/domain/models'

interface EmployeesPageState {
  productTab: edenredProducts | undefined
}

export const useEmployeesPageController = (): EmployeesPageState => {
  const { state } = useLocation()
  const [productTab, setProductTab] = useState<edenredProducts>()

  useEffect(() => {
    if (state) {
      const tab = state as IProductsTabModel
      setProductTab(tab.navigateValue)
    }
  }, [state])

  return { productTab }
}
