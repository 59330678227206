import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { HeaderModel, RowModel, SelectorModel } from 'src/domain/customComponents/table'
import { EmployeesWithLastCard, EmployeesWithLastCardProduct } from 'src/domain/models'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { getUndefinedDashString } from 'src/core/services'
import { EmployeeProfilePill, EmployeeProfileSwtich } from '../atom'
import {
  MetaStatusCodes,
  NotificationSeverity,
  allowMobilePlasticless,
  cardType,
  edenredProducts,
  queryParam,
} from 'src/domain/enum'
import { useEmployeesSharedController } from '../../../hooks'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import {
  DeleteClientEmployee,
  DeleteClientEmployeeResponse,
} from 'src/domain/models/cardOrder/DeleteClientEmployees'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { EmployeesService } from 'src/core/services/employeesService'
import { forms } from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'

interface ControllerState {
  header: HeaderModel
  selector: SelectorModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  employeeData: EmployeesWithLastCard | undefined
  getCardType: () => string
  enabledAccount: boolean
  setEnabledAccount: Dispatch<SetStateAction<boolean>>
  setShowPopupDeleteProduct: Dispatch<SetStateAction<boolean>>
  popupShowDeleteProduct: boolean
  popupTitleDeleteProduct: string
  popupDescriptionDeleteProduct: string
  popupButtonsDeleteProduct: PopupButtonModel[]
  onClickDeleteProduct: (employeeId: number) => void
}

export const useEmployeeProfileController = (
  employeeData: EmployeesWithLastCard,
  addSuccessNotification?: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void,
  productCodes?: number[],
  setProductCodes?: Dispatch<SetStateAction<number[] | undefined>>
): ControllerState => {
  const { t } = useTranslation()
  const [rows, setRows] = useState<RowModel[]>([])
  const [enabledAccount, setEnabledAccount] = useState(false)
  const { getCardStatusPill } = useEmployeesSharedController()
  const { addNotification } = useNotification()
  const { startLoading, stopLoading } = useLoader()
  const [searchParams, setSearchParams] = useSearchParams()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(employeesTranslation.profile.table.header.serialNumber) },
      { label: t(employeesTranslation.profile.table.header.expireDate) },
      { label: t(employeesTranslation.profile.table.header.lastCardStatus) },
      { label: t(employeesTranslation.profile.table.header.virtualCardCompatibility) },
      { label: t(employeesTranslation.profile.table.header.enableAccount) },
    ],
  }

  const selector: SelectorModel = {
    buttonTitle: '',
    description: '',
    selectors: [],
    title: '',
  }

  const GetRows = (): void => {
    const cardData: RowModel[] = [
      {
        rowId: 1,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: `#${getUndefinedDashString(employeeData?.serialCardNumber)}`,
                name: 'serialNumber',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: getUndefinedDashString(employeeData?.expiredDate?.toString()),
                name: 'expireDate',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'element',
            cellElement: getCardStatusPill(Number(employeeData?.cardStatusId || 0)),
          },
          { type: 'element', cellElement: getCardCompatibilityPill() },
          {
            type: 'element',
            cellElement: <EmployeeProfileSwtich employeeData={employeeData} />,
          },
        ],
      },
    ]
    if (employeeData) {
      setRows(cardData)
    }
  }

  const getCardCompatibilityPill = (): JSX.Element => {
    switch (employeeData?.allowMobilePlasticless) {
      case allowMobilePlasticless.compatible:
        return (
          <EmployeeProfilePill tone="success-600">
            {t(employeesTranslation.profile.table.body.compatible)}
          </EmployeeProfilePill>
        )
      case allowMobilePlasticless.noCompatible:
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.table.body.noCompatible)}
          </EmployeeProfilePill>
        )
      case allowMobilePlasticless.pending:
        return (
          <EmployeeProfilePill tone="warning-600">
            {t(employeesTranslation.profile.table.body.pending)}
          </EmployeeProfilePill>
        )
      default:
        return <></>
    }
  }

  const getCardType = (): string => {
    if (employeeData?.cardTypeId === cardType.Plastic) {
      return t(employeesTranslation.profile.card.plasticCard)
    }
    if (employeeData?.cardTypeId === cardType.Virtual) {
      return t(employeesTranslation.profile.card.virtualCard)
    }
    return ''
  }

  const GetCardBlocked = (): void => {
    setEnabledAccount(!!employeeData?.isCardBlocked)
  }

  const {
    show: popupShowDeleteProduct,
    title: popupTitleDeleteProduct,
    description: popupDescriptionDeleteProduct,
    buttons: popupButtonsDeleteProduct,
    setShow: setShowPopupDeleteProduct,
    setDescription: setDescriptionPopupDeleteProduct,
    setTitle: setTitlePopupDeleteProduct,
    setButtons: setButtonsPopupDeleteProduct,
  } = useModalController()

  const SetPopupButtonsDeleteProduct = (employeeId: number): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowPopupDeleteProduct(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.unsubscribe),
        category: 'danger',
        onClick: () => deleteProduct(employeeId),
        size: 'large',
      },
    ]
  }

  const deleteProduct = (employeeId: number): void => {
    if (productCodes) {
      const ids: DeleteClientEmployee[] = [
        {
          userId: employeeId,
        },
      ]
      setShowPopupDeleteProduct(false)
      startLoading()
      EmployeesService()
        .deleteEmployees(ids)
        .then(response => {
          if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
            addSuccessNotification && addSuccessNotification(response.data, true)
            const productCodesFiltered = productCodes.filter(
              prod => prod !== edenredProducts.ticketRestaurant
            )
            setProductCodes && setProductCodes(productCodesFiltered)
            setSearchParams({
              [queryParam.product]: productCodesFiltered[0].toString(),
            })
          } else {
            addNotification(
              response?.meta?.messages[0].description,
              NotificationSeverity.error
            )
          }
        })
        .finally(() => stopLoading())
    }
  }

  const onClickDeleteProduct = (employeeId: number): void => {
    setShowPopupDeleteProduct(true)
    setButtonsPopupDeleteProduct(SetPopupButtonsDeleteProduct(employeeId))
  }

  useEffect(() => {
    setTitlePopupDeleteProduct(t(employeesTranslation.table.deleteProductModal.titleTR))
  }, [])

  useEffect(() => {
    GetRows()
    GetCardBlocked()
  }, [employeeData])

  return {
    header,
    selector,
    rows,
    setRows,
    employeeData,
    getCardType,
    enabledAccount,
    setEnabledAccount,
    setShowPopupDeleteProduct,
    popupShowDeleteProduct,
    popupTitleDeleteProduct,
    popupDescriptionDeleteProduct,
    popupButtonsDeleteProduct,
    onClickDeleteProduct,
  }
}
