import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { forms } from '../../domain/translations'
import { deliverySiteTranslation } from '../../domain/translations/myProducts/deliverySite/deliverySite_legacy'
import { deliveryType } from '../../domain/enum'

const useDeliversySiteSchema = () => {
  const { t } = useTranslation()

  const deliverySiteSchema = Yup.object({
    productTypeId: Yup.number().min(3, t(forms.errors.fieldRequired)),
    contactType: Yup.number()
      .typeError(t(forms.errors.fieldRequired))
      .required(t(forms.errors.fieldRequired)),
    deliveryPointName: Yup.string().required(t(forms.errors.fieldRequired)),
    alias: Yup.string().required(t(forms.errors.fieldRequired)),
    streetName: Yup.string().required(t(forms.errors.fieldRequired)),
    streetNumber: Yup.string().required(t(forms.errors.fieldRequired)),
    zipCode: Yup.string().required(t(forms.errors.fieldRequired)),
    province: Yup.string().required(t(forms.errors.fieldRequired)),
    city: Yup.string().required(t(forms.errors.fieldRequired)),
    contactName: Yup.string().required(t(forms.errors.fieldRequired)),
    email: Yup.string()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .email(t(forms.errors.invalidFormat))
      .required(t(forms.errors.fieldRequired)),
    observation: Yup.string().max(100, t(deliverySiteTranslation.errors.maxLenght100)),
    telephone: Yup.string()
      .typeError(t(forms.errors.fieldRequired))
      .test('phoneEmptyValidate', t(forms.errors.fieldRequired), (value, ctx) => {
        const isValid = true
        if (ctx.parent.deliveryType.toString() === deliveryType.messenger.toString()) {
          return !(value === '')
        }
        return isValid
      })
      .test('phoneFormatValidate', t(forms.errors.phoneInvalid), value => {
        const isValid = true
        if (value !== '')
          return value?.match(/(6|7|8|9)[ -]*([0-9][ -]*){8}$/) === null ? false : true
        return isValid
      }),
  })

  return { deliverySiteSchema }
}

export default useDeliversySiteSchema
