import { OreButton, OreHeading, SvgPlus } from '@runroom/oreneta'

interface PageHeaderProps {
  title: string
  buttonText?: string
  buttonAction?: () => void
  organism: React.ReactNode
}

export const PageHeader = ({
  title,
  buttonText,
  buttonAction,
  organism,
}: PageHeaderProps) => {
  return (
    <>
      <header className="width-max mb-105">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <OreHeading as="h1" size="title-sm">
            {title}
          </OreHeading>
          {buttonText && buttonAction && (
            <OreButton
              as="button"
              category="primary"
              onClick={buttonAction}
              icon={<SvgPlus />}
              size="large"
            >
              {buttonText}
            </OreButton>
          )}
        </div>
      </header>
      {organism}
    </>
  )
}
