import { OreCheckbox, OreTableCell, OreTableRow, OreText } from '@runroom/oreneta'
import { ExternalHealthCollectiveModel } from 'src/Flex/Collectives/domain'
import { currency } from 'src/domain/enum'

interface IProps {
  policy: ExternalHealthCollectiveModel
  form: any // eslint-disable-line
}

export const FlexExternalHealthCollectiveRow = ({
  policy,
  form,
}: IProps): JSX.Element => {
  const { id, policyName, company, monthlyLimit, active } = policy

  return (
    <OreTableRow valign="middle">
      {active !== undefined ? (
        <OreTableCell>
          <OreCheckbox
            defaultChecked={active}
            {...form.register('policies')}
            value={id}
          />
        </OreTableCell>
      ) : null}
      <OreTableCell>
        <OreText size="text-sm">{company}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{policyName}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{monthlyLimit} {currency.euro}/mes</OreText>
      </OreTableCell>
    </OreTableRow>
  )
}
