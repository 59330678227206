import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { forms } from '../../domain/translations'
import {
  dateIsAfterLastDayOfMonthOneYearLater,
  dateIsAfterMilesimaDate,
  dateValueIsLowerThanToday,
  dateValueIsLowerThanTomorrow,
  getDateAfter,
} from '../../core/helpers'
import { cardOrderTranslation } from '../../domain/translations/cardOrder/cardOrder'
import { useUser } from '../context/user/UserProvider'
import { edenredProducts } from '../../domain/enum'
import { useCardOrder } from '../context/cardOrder/CardOrderProvider'
import { existRecharge, hasRechargeOrDischarge, isDischarge } from '../../core/services'

const useCardOrderHomeSchema = () => {
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )
  const {
    orders,
    orderExcel,
    orderContext: { orderType },
  } = useCardOrder()

  const cardOrderHomeformSchema = Yup.object({
    initialChargeDate: hasRechargeOrDischarge(orders, orderExcel)
      ? Yup.string()
          .required(t(forms.errors.fieldRequired))
          .test(
            'testChargeDate',
            t(cardOrderTranslation.form.errors.dateBeforeToday),
            value => {
              const error = value
                ? isDischarge(orderType)
                  ? dateValueIsLowerThanToday(value)
                  : dateValueIsLowerThanTomorrow(value)
                : null
              return !error
            }
          )
          .test(
            'testChargeDateMax',
            () =>
              currentContract?.allowMilesima
                ? t(cardOrderTranslation.form.errors.dateBeforeMilesima)
                : t(cardOrderTranslation.form.errors.dateBeforOneYear),
            value => {
              if (value) {
                const compareDate = currentContract?.allowMilesima
                  ? dateIsAfterMilesimaDate(value)
                  : dateIsAfterLastDayOfMonthOneYearLater(value)
                return !compareDate
              }
              return false
            }
          )
      : Yup.string(),
    expiredDate:
      existRecharge(orders, orderExcel) && currentContract?.allowLoadExpiredDate
        ? Yup.string()
            .required(t(forms.errors.fieldRequired))
            .test(
              'testDueDate',
              t(cardOrderTranslation.cardOrderHome.expiredDateError),
              (value, ctx) => {
                const error = value
                  ? getDateAfter(value, ctx.parent.initialChargeDate)
                  : null
                return !error
              }
            )
        : Yup.string(),
    billReference: currentContract?.isCommentRequiredFromClient
      ? Yup.string().required(t(forms.errors.fieldRequired))
      : Yup.string().nullable(),
    acceptConditions: Yup.boolean().oneOf([true], t(forms.errors.termsAndConditions)),
    orderDescription: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(50, t(forms.errors.maxAllowed) + ' 50'),
    reminderDate: Yup.string().test(
      'testReminderDate',
      t(cardOrderTranslation.form.errors.dateBeforeToday),
      value => {
        const error = value ? dateValueIsLowerThanTomorrow(value) : null
        return !error
      }
    ),
  })

  return { cardOrderHomeformSchema }
}

export default useCardOrderHomeSchema
