import { TFunction } from 'react-i18next'
import { StringSchema, string } from 'yup'
import { forms } from 'src/domain/translations'

export const stringSchema = (
  t: TFunction<'translation', undefined>,
  chartLimit?: number
): StringSchema<string | undefined> =>
  string()
    .defined()
    .typeError(t(forms.errors.fieldRequired))
    .test(
      'len',
      t(forms.errors.maxAllowed) + ' ' + chartLimit,
      val => val !== undefined && val.length <= (chartLimit ?? 255) === true
    )
