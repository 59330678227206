import { Dispatch, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { cardOrderService } from 'src/core/services'
import { MetaStatusCodes, edenredProducts, queryParam } from 'src/domain/enum'
import { SaveCardOrder } from 'src/domain/protocols'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotificationHandler } from 'src/presentation/hooks'

interface ControlState {
  errorMessage: string
  setStateErrorMessage: Dispatch<React.SetStateAction<string>>
  saveAndCloseCardOrder: (data: SaveCardOrder) => Promise<void>
}

export const useCardOrderSaveAndClose = (): ControlState => {
  const { notificationHandler } = useNotificationHandler()
  const { orders, cardOrderData, setCardOrderData, clearAll } = useCardOrder()
  const { startLoading, stopLoading } = useLoader()
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const navigate = useNavigate()

  const saveAndCloseCardOrder = async (data: SaveCardOrder): Promise<void> => {
    if (cardOrderData) {
      const cardOrderPayload = {
        ...cardOrderData,
        orderName: data.orderName,
        // reminderDate: data.reminderDate,
        reminderDate: '',
        initialChargeDate: '',
        expiredDate: '',
        productCode: edenredProducts.ticketRestaurant,
        orderTypeId: orders[0].orderTypeId, //obtengo del primero porque las ordenes son todas iguales por porceso (1=nuevo/duplic, 2=recarga, 3=descarga)
      }
      setCardOrderData(cardOrderPayload)
      startLoading()
      const { meta } = await cardOrderService().saveCardOrder({
        ...cardOrderPayload,
        cardOrders: orders,
      })
      stopLoading()

      // Handle API response
      if (meta.status === MetaStatusCodes.SUCCESS) {
        notificationHandler(meta, true, null)
        navigate(navigationRoutes.orderHome + `?${queryParam.unOrder}=true`, {
          state: {
            unlockNavigation: true,
          },
        })
        clearAll()
      } else {
        // has any message?
        if (meta.messages.length > 0) {
          const description = meta.messages[0].description
          const errorField = meta.messages[0].value
          let errorNotification = description

          if (errorField !== null) errorNotification += ': ' + errorField

          setStateErrorMessage(errorNotification)
        }
      }
    }
  }

  return {
    errorMessage,
    setStateErrorMessage,
    saveAndCloseCardOrder,
  }
}
