import { OreButton, OreMonthPicker, OreStack, SvgDownload } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { usePayrolls } from 'src/Flex/Payrolls/ui/payroll-list'
import { flexPayrollsTranslations } from '../../translations'

export const PayrollSelector = (): JSX.Element => {
  const { t } = useTranslation()
  const { changeDate, payrolls, download } = usePayrolls()

  const current = new Date()
  const maxDate = new Date(current.setMonth(current.getMonth() + 1))
    .toISOString()
    .split('T')[0]
    .split('-')

  return (
    <OreStack direction="row" placeItems="end">
      <OreMonthPicker
        style={{ minWidth: '360px' }}
        label={t(flexPayrollsTranslations.monthPicker.label)}
        onChange={event => changeDate(event.target.value)}
        max={`${maxDate[0]}-${maxDate[1]}`}
      />
      {payrolls.length > 0 ? (
        <OreButton icon={<SvgDownload />} category="secondary" onClick={download}>
          {t(flexPayrollsTranslations.downloadButton.label)}
        </OreButton>
      ) : null}
    </OreStack>
  )
}
