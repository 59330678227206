import { useTranslation } from 'react-i18next'
import { duplicatesConfigurationTranslations } from '../../../../domain/translations/cardOrder/duplicatesConfiguration'
import { SimpleForm } from '../../../Templates'
import { Container } from './organisms/Container'

export const DuplicatesConfigurationPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SimpleForm
      title={t(duplicatesConfigurationTranslations.title)}
      organisms={[<Container />]}
    />
  )
}
