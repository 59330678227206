import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KindergartenService } from 'src/core/services/kindergartenService'
import { MetaStatusCodes, NotificationSeverity, edenredProducts } from 'src/domain/enum'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import { forms } from 'src/domain/translations'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

interface ControllerState {
  lastNursery: KindergartenModel | undefined
  handleApiLoaded: (map: any, maps: any) => any
}

export const useKindergartenCardController = (nurseryId: number): ControllerState => {
  const [lastNursery, setLastNursery] = useState<KindergartenModel>()
  const { t } = useTranslation()
  const { addNotification } = useNotification()

  const fetchlastNursery = async (): Promise<void> => {
    const { meta, data } = await KindergartenService().GetSingleKindergarten({
      productCode: edenredProducts.ticketGuarderia,
      nurseryId,
    })

    // Handle API response
    if (meta?.status === MetaStatusCodes.SUCCESS) {
      setLastNursery(data.rows[0])
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }
  }

  const handleApiLoaded = (map: any, maps: any): any => {
    if (!lastNursery) return
    return new maps.Marker({
      position: {
        lat: Number(lastNursery.latitude),
        lng: Number(lastNursery.longitude),
      },
      map,
      title: lastNursery.name,
    })
  }

  useEffect(() => {
    fetchlastNursery()
  }, [document])

  return {
    lastNursery,
    handleApiLoaded,
  }
}
