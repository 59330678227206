import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormReturn, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import {
  UsersAndLicensesFormModel,
  UsersAndLicensesGridModel,
  UsersAndLicensesRequest,
} from 'src/domain/models/myAccount/UsersAndLicenses'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { accountService } from 'src/core/services'
import { useModalController } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { forms } from 'src/domain/translations'
import {
  MetaStatusCodes,
  NotificationSeverity,
  UserAndLicensesProfiles,
  UserAndLicensesProfilesNames,
} from 'src/domain/enum'
import { TextBoxRowModel } from 'src/domain/customComponents'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { MetaModel } from 'src/domain/models'

export interface AccountUsersAndLicensesState {
  header: HeaderModel
  totalRows: number
  usersAndLicenses: UsersAndLicensesGridModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  showAdd: boolean
  showAddTitle: string
  showAddDescription: string
  showAddButtons: PopupButtonModel[]
  setShowAdd: Dispatch<SetStateAction<boolean>>
  form: UseFormReturn<UsersAndLicensesFormModel, any>
  getUserDataForm: () => TextBoxRowModel<UsersAndLicensesFormModel>[]
  errorMessage: string
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  showDeleteModal: boolean
  deleteModalTitle: string
  deleteModalDescription: string
  deletePopupButtons: PopupButtonModel[]
  onClickDelete(userName: string): void
  onClickEdit(userModel: UsersAndLicensesGridModel): void
  showEdit: boolean
  showEditTitle: string
  showEditDescription: string
  showEditButtons: PopupButtonModel[]
  setShowEdit: Dispatch<SetStateAction<boolean>>
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  usersAndLicense?: UsersAndLicensesFormModel
}

export const useAccountUsersAndLicences = (): AccountUsersAndLicensesState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [usersAndLicenses, setUsersAndLicenses] = useState<UsersAndLicensesGridModel[]>(
    []
  )
  const [usersAndLicense, setUsersAndLicense] = useState<UsersAndLicensesFormModel>()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { addNotification } = useNotification()
  const [errorMessage, setStateErrorMessage] = useState('')

  const [query, setQuery] = useState<UsersAndLicensesRequest>({
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  })

  const header: HeaderModel = {
    headerLabel: [
      { label: t(accountUsersLicensesTranslations.table.header.name) },
      { label: t(accountUsersLicensesTranslations.table.header.mail) },
      { label: t(accountUsersLicensesTranslations.table.header.status) },
      { label: t(accountUsersLicensesTranslations.table.header.profile) },
      { label: t(accountUsersLicensesTranslations.table.header.actions) },
    ],
  }

  const {
    show: showAdd,
    title: showAddTitle,
    description: showAddDescription,
    buttons: showAddButtons,
    setShow: setShowAdd,
    setDescription: setShowAddDescription,
    setTitle: setShowAddTitle,
    setButtons: setShowAddButtons,
  } = useModalController()

  const {
    show: showDeleteModal,
    title: deleteModalTitle,
    description: deleteModalDescription,
    buttons: deletePopupButtons,
    setShow: setShowDeleteModal,
    setDescription: setDeleteModalDescription,
    setTitle: setDeleteModalTitle,
    setButtons: setDeleteModalButtons,
  } = useModalController()

  const {
    show: showEdit,
    title: showEditTitle,
    description: showEditDescription,
    buttons: showEditButtons,
    setShow: setShowEdit,
    setTitle: setShowEditTitle,
    setButtons: setShowEditButtons,
  } = useModalController()

  const getUsersAndLicenses = (): void => {
    startLoading()
    accountService()
      .GetUsersAndLicenses(query)
      .then(response => {
        if (response && response.data && response.data.rows) {
          setUsersAndLicenses(response.data.rows)
          setTotalRows(response.data.totalRows)
        }
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const getShowAddButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowAdd(false),
        size: 'large',
      },
      {
        title: t(accountUsersLicensesTranslations.add.createButton),
        category: 'primary',
        onClick: form.handleSubmit(onSubmitUsersAndLicenses),
        size: 'large',
      },
    ]
  }

  const handleSuccessError = (meta: MetaModel): void => {
    if (meta.status === MetaStatusCodes.SUCCESS) {
      setStateErrorMessage('')
      setShowAdd(false)
      setShowEdit(false)
      addNotification(t(forms.success.message), NotificationSeverity.success)
      setQuery({
        ...query,
        paginationModel: {
          numberOfRecordsPerPage: pageSize,
          pageNumber: page - 1,
        },
      })
      setPage(page)
    } else {
      if (meta.messages.length > 0) {
        meta?.messages.forEach((error: any) => {
          if (error.value) {
            form.setError(error.value as any, {
              type: 'value',
              message: error.description,
            })
          } else {
            setStateErrorMessage(meta?.messages[0].description)
          }
        })
      }
    }
  }

  const onSubmitUsersAndLicenses = async (model: UsersAndLicensesFormModel) => {
    const payload = {
      ...model,
      rolName: getRolNameById(+form.getValues('profileId')),
    }

    startLoading()
    await accountService()
      .addUserAndLicenses(payload)
      .then(({ meta }) => {
        handleSuccessError(meta)
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  const initShowAdd = (): void => {
    setShowAddTitle(t(accountUsersLicensesTranslations.add.title))
    setShowAddDescription(t(accountUsersLicensesTranslations.add.description))
    setShowAddButtons(getShowAddButtons())
  }

  const getShowEditButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowEdit(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.save),
        category: 'primary',
        onClick: onEditUsersAndLicenses,
        size: 'large',
      },
    ]
  }

  const getRolNameById = (profileId: number): string => {
    let rolName = UserAndLicensesProfilesNames.viewer
    switch (+profileId) {
      case UserAndLicensesProfiles.viewer:
        rolName = UserAndLicensesProfilesNames.viewer
        break
      case UserAndLicensesProfiles.admin:
        rolName = UserAndLicensesProfilesNames.admin
        break
      case UserAndLicensesProfiles.superAdmin:
        rolName = UserAndLicensesProfilesNames.superAdmin
        break
    }
    return rolName
  }

  const onEditUsersAndLicenses = async (): Promise<void> => {
    if (!usersAndLicense) return

    const payload = {
      ...usersAndLicense,
      profileId: form.getValues('profileId'),
      rolName: getRolNameById(+form.getValues('profileId')),
    }
    startLoading()
    await accountService()
      .editUserAndLicenses(payload)
      .then(({ meta }) => {
        handleSuccessError(meta)
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  const onClickEdit = (userModel: UsersAndLicensesGridModel): void => {
    const formModel: UsersAndLicensesFormModel = {
      userName: userModel.userName,
      name: userModel.name,
      surname: userModel.surname,
      surname2: userModel.surname2,
      email: userModel.email,
      rolName: userModel.rolName,
      profileId: getRolIdByName(userModel.rolName),
    }
    form.setValue('profileId', formModel.profileId)
    setUsersAndLicense(formModel)
    setIsEditing(true)
    setShowEdit(true)
  }

  const getRolIdByName = (rolName: string): number => {
    let rolId = UserAndLicensesProfiles.viewer
    switch (rolName) {
      case UserAndLicensesProfilesNames.viewer:
        rolId = UserAndLicensesProfiles.viewer
        break
      case UserAndLicensesProfilesNames.admin:
        rolId = UserAndLicensesProfiles.admin
        break
      case UserAndLicensesProfilesNames.superAdmin:
        rolId = UserAndLicensesProfiles.superAdmin
        break
    }
    return rolId
  }

  const initShowEdit = (): void => {
    setShowEditTitle(t(accountUsersLicensesTranslations.edit.title))
  }

  const formSchema = Yup.object({
    name: !isEditing
      ? Yup.string()
          .required(t(forms.errors.fieldRequired))
          .max(20, t(forms.errors.maxAllowed) + ' 20')
      : Yup.string(),
    surname: !isEditing
      ? Yup.string()
          .required(t(forms.errors.fieldRequired))
          .max(20, t(forms.errors.maxAllowed) + ' 20')
      : Yup.string(),
    surname2: !isEditing
      ? Yup.string().max(20, t(forms.errors.maxAllowed) + ' 20')
      : Yup.string(),
    email: !isEditing
      ? Yup.string()
          .required(t(forms.errors.fieldRequired))
          .email(t(forms.errors.invalidFormat))
          .max(75, t(forms.errors.maxAllowed) + ' 75')
      : Yup.string(),
    profileId: Yup.number().required(t(forms.errors.fieldRequired)),
  })

  const defaultValues = {
    name: isEditing ? usersAndLicense?.name : '',
    surname: isEditing ? usersAndLicense?.surname : '',
    surname2: isEditing ? usersAndLicense?.surname2 : '',
    email: isEditing ? usersAndLicense?.email : '',
    profileId: isEditing ? usersAndLicense?.profileId : UserAndLicensesProfiles.viewer,
  }

  const form = useForm<UsersAndLicensesFormModel>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: defaultValues,
  })

  const getUserDataForm = (): TextBoxRowModel<UsersAndLicensesFormModel>[] => {
    return [
      {
        className: 'form-atom form-atom--three',
        textBoxes: [
          {
            id: 'name',
            label: t(accountUsersLicensesTranslations.add.name),
            placeHolder: t(accountUsersLicensesTranslations.add.namePlaceHolder),
            name: 'name',
            required: true,
            type: 'text',
            maxLength: 20,
            autoComplete: 'off',
          },
          {
            id: 'surname',
            label: t(accountUsersLicensesTranslations.add.surname),
            placeHolder: t(accountUsersLicensesTranslations.add.surnamePlaceholder),
            name: 'surname',
            required: true,
            type: 'text',
            maxLength: 20,
            autoComplete: 'off',
          },
          {
            id: 'surname2',
            label: t(accountUsersLicensesTranslations.add.surname2),
            placeHolder: t(accountUsersLicensesTranslations.add.surnamePlaceholder2),
            name: 'surname2',
            required: false,
            type: 'text',
            maxLength: 20,
            autoComplete: 'off',
          },
        ],
      },
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'email',
            label: t(accountUsersLicensesTranslations.add.email),
            placeHolder: t(accountUsersLicensesTranslations.add.emailPlaceHolder),
            name: 'email',
            required: true,
            type: 'text',
            maxLength: 75,
            autoComplete: 'off',
          },
        ],
      },
    ]
  }

  const onClickDelete = (userName: string): void => {
    setShowDeleteModal(true)
    setDeleteModalButtons(getDeleteModalButtons(userName))
  }

  const getDeleteModalButtons = (userName: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowDeleteModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.unsubscribe),
        category: 'danger',
        onClick: () => deleteUserLicense(userName),
        size: 'large',
      },
    ]
  }

  const deleteUserLicense = async (userName: string): Promise<void> => {
    setShowDeleteModal(false)
    startLoading()
    await accountService()
      .deleteUserAndLicenses(userName)
      .then(({ meta }) => {
        handleSuccessError(meta)
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  useEffect(() => {
    getUsersAndLicenses()
  }, [query])

  useEffect(() => {
    if (!usersAndLicense) return
    setShowEditButtons(getShowEditButtons())
  }, [usersAndLicense])

  useEffect(() => {
    initShowAdd()
    initShowEdit()
    setDeleteModalTitle(t(accountUsersLicensesTranslations.delete.modal.title))
    setDeleteModalDescription(
      t(accountUsersLicensesTranslations.delete.modal.description)
    )
  }, [])

  return {
    header,
    totalRows,
    usersAndLicenses,
    page,
    setPage,
    pageSize,
    onPageChange,
    showAdd,
    showAddTitle,
    showAddDescription,
    showAddButtons,
    setShowAdd,
    form,
    getUserDataForm,
    errorMessage,
    setShowDeleteModal,
    showDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    onClickDelete,
    onClickEdit,
    showEdit,
    showEditTitle,
    showEditDescription,
    showEditButtons,
    setShowEdit,
    isEditing,
    setIsEditing,
    usersAndLicense,
  }
}
