import { EmployeeDocumentType, LegalDocumentType } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { documentsService } from 'src/Flex/Employees/application'
import { textToPdf } from 'src/core/helpers'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

interface IUseDownloader {
  downloadLegalDocument: (id: LegalDocumentType, name?: string) => Promise<void>
  downloadEmployeeDocument: (
    employeeId: string,
    documentId: EmployeeDocumentType,
    name?: string
  ) => Promise<void>
}

export const useDownloader = (): IUseDownloader => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()

  const downloadLegalDocument = async (
    id: LegalDocumentType,
    name?: string
  ): Promise<void> => {
    startLoading()

    const response = await documentsService().DownloadLegalDocument(id)

    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, { notifySuccess: false }) &&
      response.data.file
    ) {
      textToPdf(response.data.file, name ?? 'document')
    }
  }

  const downloadEmployeeDocument = async (
    employeeId: string,
    documentId: EmployeeDocumentType,
    name?: string
  ): Promise<void> => {
    startLoading()

    const response = await documentsService().DownloadEmployeeDocument(
      employeeId,
      documentId
    )

    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, { notifySuccess: false }) &&
      response.data.file
    ) {
      textToPdf(response.data.file, name ?? 'document')
    }
  }

  return { downloadLegalDocument, downloadEmployeeDocument }
}
