import { t } from 'i18next'
import type { Dispatch } from 'react'
import { useCallback } from 'react'
import type {
  CellModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { forms } from '../../../../../domain/translations'

interface CellTextBoxState {
  onChangeCellTextBox: (event: React.ChangeEvent<HTMLInputElement>) => void
  IsValidValue: (
    value: string | undefined,
    type: 'number' | 'text' | 'email' | 'tel' | 'url' | undefined
  ) => boolean
  ErrorMessage: (
    value: string | undefined,
    type: 'number' | 'text' | 'email' | 'tel' | 'url' | undefined
  ) => string
}

export const useCellTextBox = (
  rowIndex: number,
  cellIndex: number,
  setRows: Dispatch<React.SetStateAction<RowModel[]>>
): CellTextBoxState => {
  const IsValidValue = useCallback(
    (
      value: string | undefined,
      type: 'number' | 'text' | 'email' | 'tel' | 'url' | undefined
    ): boolean => {
      const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

      if (value === '' || value === undefined) {
        return false
      }

      if (type === 'email') {
        if (!value.match(mailformat)) {
          return false
        }
      }

      return true
    },
    []
  )

  const ErrorMessage = useCallback(
    (
      value: string | undefined,
      type: 'number' | 'text' | 'email' | 'tel' | 'url' | undefined
    ): string => {
      const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

      if (value === '' || value === undefined) {
        return t(forms.errors.fieldRequired)
      }

      if (type === 'email') {
        if (!value.match(mailformat)) {
          return t(forms.errors.invalidFormat)
        }
      }

      return ''
    },
    []
  )

  const onChangeCellTextBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRows(prevRows =>
        prevRows.map((prevRow: RowModel, prevRowIndex: number) => {
          if (prevRowIndex === rowIndex)
            return {
              ...prevRow,
              cells: prevRow.cells.map((prevCell: CellModel, prevCellIndex: number) => {
                if (prevCellIndex === cellIndex && prevCell.cellControll?.name) {
                  return {
                    ...prevCell,
                    cellControll: {
                      ...prevCell.cellControll,
                      value: event.target.value,
                      errorMassage: prevCell.cellControll.massageErrorValidate
                        ? prevCell.cellControll.massageErrorValidate(event.target.value)
                        : '',
                      hasError: prevCell.cellControll.errorValidate
                        ? prevCell.cellControll.errorValidate(event.target.value)
                        : false,
                    },
                  }
                }
                return prevCell
              }),
            }
          return prevRow
        })
      )
    },
    []
  )

  return { onChangeCellTextBox, IsValidValue, ErrorMessage }
}
