import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const locale = ['es', 'ca', 'en']

export interface LocaleTypes {
  en: string
  es: string
  ca: string
}

export const LOCALE_TYPES: LocaleTypes = {
  en: 'en-EN',
  es: 'es-ES',
  ca: 'ca-ES',
}

const translateLoadPath = process.env.REACT_APP_TRANSLATE_LOADPATH

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      //lng: 'es', <= not necessary if we use a lang detector
      fallbackLng: 'es',
      debug: false,
      detection: {
        order: ['localStorage', 'cookie'], //may add other sources like 'cookie', 'localStorage', 'sessionStorage'
        caches: ['cookie'],
      },
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            // prefix for stored languages
            prefix: 'i18next_res_',
            // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
            store: typeof window !== 'undefined' ? window.localStorage : null,
            // 7 days
            expirationTime: 0,
          },
          {
            loadPath: translateLoadPath,
          },
        ],
        //loadPath: "http://localhost:3001/locales/{{lng}}/translation.json",
        //loadPath: "https://ees-languageintegration-web-d.azurewebsites.net/api/Translator/web-app/{{lng}}",
        // loadPath: './translations/{{lng}}/{{ns}}.json', <= with name spaces
        //loadPath: "https://raw.githubusercontent.com/i18next/react-i18next/master/example/react/public/locales/en/translation.json",

        /*requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
                mode: 'no-cors',
                credentials: 'same-origin',
                cache: 'default'
            }*/
      },
      react: {
        useSuspense: true, //   <---- this will do the magic. The magic: just a spinner ;)
      },
    },
    (err, t) => {
      if (err) {
        return console.log('something went wrong loading', err) // eslint-disable-line no-console
      }
      t('key') // -> same as i18next.t
    }
  )

export default i18n
