import { edenredProducts, orderType } from '../../../../../domain/enum'
import { cardOrderTranslation } from '../../../../../domain/translations'
import { useUser } from '../../../../context/user/UserProvider'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useTranslation } from 'react-i18next'
import type { UserContract } from '../../../../../domain/models'
import { hasUnloadOrders } from '../../../../../core/services'

interface IUseCardOrderDates {
  currentContract: UserContract | undefined
  getInitialChargeDateLabel: () => string
  getInitialDateTooltip: () => string
}

export const useCardOrderDates = (): IUseCardOrderDates => {
  const { orders, isOrderExcel, orderContext } = useCardOrder()
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )

  const getInitialChargeDateLabel = (): string => {
    if (hasUnloadOrders(orders) || orderContext.orderType === orderType.Unload) {
      return t(cardOrderTranslation.cardOrderHome.initialDateUnload)
    }
    return t(cardOrderTranslation.cardOrderHome.initialDate)
  }

  const getInitialDateTooltip = (): string => {
    if (hasUnloadOrders(orders) || orderContext.orderType === orderType.Unload) {
      return t(cardOrderTranslation.cardOrderHome.initialDateUnloadTooltip)
    }
    return t(cardOrderTranslation.cardOrderHome.initialDateTooltip)
  }

  return { currentContract, getInitialChargeDateLabel, getInitialDateTooltip }
}
