import {
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreIconButton,
  OreStack,
  OreText,
  SvgArrowWidgetRight,
  SvgCommunications,
} from '@runroom/oreneta'
import { TaskModel, TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import {
  getTaskIconByTaskType,
  usePendingTasks,
} from 'src/Flex/TaskManager/ui/pending-tasks'

interface PendingTaskCardProps {
  tasks: TaskModel[]
}

export const PendingTaskCard = ({ tasks }: PendingTaskCardProps): JSX.Element => {
  const { taskAction } = usePendingTasks()
  const handleAction = (task: TaskModel) => {
    taskAction(task)()
  }

  if (!tasks) {
    return <></>
  }

  return (
    <OreCardContainer>
      <OreCardWrapper space="small">
        {tasks.map((task, index) => (
          <OreStack direction="column" key={index} space="small" placeContent="stretch">
            <OreStack
              direction="row"
              placeItems="center"
              placeContent="space-between"
              style={{ marginTop: `${index !== 0 ? '16px' : '0'}` }}>
              <OreStack direction="row" placeItems="center">
                {getTaskIconByTaskType(
                  task.type,
                  task.productType,
                  task.step,
                  task.active ?? true
                )}
                <OreText bold tone={task.active ? 'neutral-800' : 'neutral-300'}>
                  {task.amount ? task.amount : null} {task.title}
                </OreText>
              </OreStack>
              <OreIconButton
                icon={
                  task.type === TaskTypeEnum.pendingWelcomeCommunication ? (
                    <SvgCommunications />
                  ) : (
                    <SvgArrowWidgetRight />
                  )
                }
                onClick={() => handleAction(task)}
                disabled={!task.active ?? false}
              />
            </OreStack>
            {index !== tasks.length - 1 ? <OreDivider /> : null}
          </OreStack>
        ))}
      </OreCardWrapper>
    </OreCardContainer>
  )
}
