export const orderValidationsTranslations = {
  title: 'flex.orders.validation.title',
  configure: {
    title: 'flex.orders.validation.configure.title',
    subtitleTR: 'flex.orders.validation.configure.subtitleTR',
    TRText1_1: 'flex.orders.validation.configure.TRText1_1',
    TRText1_2: 'flex.orders.validation.configure.TRText1_2',
    TRText1_3: 'flex.orders.validation.configure.TRText1_3',
    TRText2: 'flex.orders.validation.configure.TRText2',
    subtitleTT: 'flex.orders.validation.configure.subtitleTT',
    TTText1_1: 'flex.orders.validation.configure.TTText1_1',
    TTText1_2: 'flex.orders.validation.configure.TTText1_2',
    TTText1_3: 'flex.orders.validation.configure.TTText1_3',
    TTText2: 'flex.orders.validation.configure.TTText2',
    subtitleTGD: 'flex.orders.validation.configure.subtitleTGD',
    TGDText1_1: 'flex.orders.validation.configure.TGDText1_1',
    TGDText1_2: 'flex.orders.validation.configure.TGDText1_2',
    TGDText1_3: 'flex.orders.validation.configure.TGDText1_3',
    TGDText2: 'flex.orders.validation.configure.TGDText2',
  },
  step1: {
    title: 'flex.orders.validation.step1.title',
    description1: 'flex.orders.validation.step1.description_1',
    description2: 'flex.orders.validation.step1.description_2',
    table: {
      title: 'flex.orders.validation.step1.table.title',
      header: {
        owner: 'flex.orders.validation.step1.table.header.owner',
        monthlyImport: 'flex.orders.validation.step1.table.header.monthly_import',
        deliveryPoint: 'flex.orders.validation.step1.table.header.delivery_point',
        center: 'flex.orders.validation.step1.table.header.center',
        kindergarten: 'flex.orders.validation.step1.table.header.kindergarten',
      },
      noOrders: 'flex.orders.validation.step1.table.no_orders',
      noOrdersWithFilters: 'flex.orders.validation.step1.table.no_orders_with_filters',
      selected: 'flex.orders.validation.step1.table.selected',
    },
    refresh: {
      description1: 'flex.orders.validation.step1.refresh.description_1',
      description2: 'flex.orders.validation.step1.refresh.description_2',
      action: 'flex.orders.validation.step1.refresh.action',
    },
    complete: {
      checkboxLabel1: 'flex.orders.validation.step1.complete.checkbox_label_1',
      checkboxLabel2: 'flex.orders.validation.step1.complete.checkbox_label_2',
      buttonAction: 'flex.orders.validation.step1.complete.button_action',
      title: 'flex.orders.validation.step1.complete.title',
      recharge: {
        description: 'flex.orders.validation.step1.complete.recharge.description',
        description_guarderia:
          'flex.orders.validation.step1.complete.recharge.description_guarderia',
        date: 'flex.orders.validation.step1.complete.recharge.date',
        tooltip: 'flex.orders.validation.step1.complete.recharge.tooltip',
      },
      imputate: {
        month: 'flex.orders.validation.step1.complete.imputate.month',
        tooltip: 'flex.orders.validation.step1.complete.imputate.month.tooltip',
        current: 'flex.orders.validation.step1.complete.imputate.current',
        next: 'flex.orders.validation.step1.complete.imputate.next',
      },
      number: {
        description: 'flex.orders.validation.step1.complete.number.description',
        label: 'flex.orders.validation.step1.complete.number.label',
        placeholder: 'flex.orders.validation.step1.complete.number.placeholder',
      },
    },
    noOrders: {
      description: 'flex.orders.validation.step1.no_orders.description',
      cutOffDate: 'flex.orders.validation.step1.no_orders.cut_off_date',
    },
    calculate: {
      error1: 'flex.orders.validation.step1.calculate.error1',
      error2: 'flex.orders.validation.step1.calculate.error2',
      error3: 'flex.orders.validation.step1.calculate.error3',
    },
  },
  step2: {
    title: 'flex.orders.validation.step2.title',
    buttonBack: 'flex.orders.validation.step2.button_back',
    buttonConfirm: 'flex.orders.validation.step2.button_confirm',
    general: {
      title: 'flex.orders.validation.step2.general.title',
      initialChargeDate: 'flex.orders.validation.step2.general.initial_charge_date',
      billReference: 'flex.orders.validation.step2.general.bill_reference',
      paymentMode: 'flex.orders.validation.step2.general.payment_mode',
      paymentModeBank: 'flex.orders.validation.step2.general.payment_mode_bank',
      paymentModeDomiciliary:
        'flex.orders.validation.step2.general.payment_mode_domiciliary',
    },
    detail: {
      title: 'flex.orders.validation.step2.detail.title',
      titleCard: 'flex.orders.validation.step2.detail.title_card',
      titleRecharge: 'flex.orders.validation.step2.detail.title_recharge',
      counterCard: 'flex.orders.validation.step2.detail.counter_card',
      counterRecharge: 'flex.orders.validation.step2.detail.counter_recharge',
      services: 'flex.orders.validation.step2.detail.services',
      units: 'flex.orders.validation.step2.detail.units',
      cost: 'flex.orders.validation.step2.detail.cost',
      subtotal: 'flex.orders.validation.step2.detail.subtotal',
      total: 'flex.orders.validation.step2.detail.total',
      iva: 'flex.orders.validation.step2.detail.iva',
    },
    delivery: {
      title: 'flex.orders.validation.step2.delivery.title',
      fisicalCards: {
        title: 'flex.orders.validation.step2.delivery.fisical_cards.title',
        description: 'flex.orders.validation.step2.delivery.fisical_cards.description',
      },
      virtualCards: {
        title: 'flex.orders.validation.step2.delivery.virtual_cards.title',
        description: 'flex.orders.validation.step2.delivery.virtual_cards.description',
      },
      recharges: {
        title: 'flex.orders.validation.step2.delivery.recharges.title',
        description: 'flex.orders.validation.step2.delivery.recharges.description',
      },
    },
  },
  step3: {
    title: 'flex.orders.validation.step3.title',
    success: {
      title: 'flex.orders.validation.step3.success.title',
    },
    description_1: 'flex.orders.validation.step3.description_1',
    description_2: 'flex.orders.validation.step3.description_2',
    description_transfer_1: 'flex.orders.validation.step3.description_transfer_1',
    description_transfer_2: 'flex.orders.validation.step3.description_transfer_2',
    prepayment: 'flex.orders.validation.step3.success.prepayment',
    bullets: {
      first: 'flex.orders.validation.step3.bullets.first',
      second: 'flex.orders.validation.step3.bullets.second',
      third: 'flex.orders.validation.step3.bullets.third',
      fourth: 'flex.orders.validation.step3.bullets.fourth',
    },
    error: {
      tittle: 'flex.orders.validation.step3.error.tittle',
      description1: 'flex.orders.validation.step3.error.description_1',
      description2: 'flex.orders.validation.step3.error.description_2',
      table: {
        tittle: 'flex.orders.validation.step3.error.table.tittle',
        column1: 'flex.orders.validation.step3.error.table.column_1',
        column2: 'flex.orders.validation.step3.error.table.column_2',
        column3: 'flex.orders.validation.step3.error.table.column_3',
        pill: 'flex.orders.validation.step3.error.table.pill',
        numberAlerts: 'flex.orders.validation.step3.error.table.numberAlerts',
      },
      newOrder: 'flex.orders.validation.step3.error.newOrder',
      goHome: 'flex.orders.validation.step3.error.back',
    },
  },
  errors: {
    maxAmountCollective: 'flex.orders.validation.errors.max_amount_collective',
  },
  forceUpdate: {
    success: 'Pedido actualizado correctamente',
    fail: 'El pedido no ha podido ser actualizado. Intenta nuevamente en unos minutos',
    title: 'flex.orders.validation.force_update.title',
    description: 'flex.orders.validation.force_update.description',
    button: 'flex.orders.validation.force_update.button',
    list: {
      text1: 'flex.orders.validation.force_update.list.text1',
      text2: 'flex.orders.validation.force_update.list.text2',
      text3: 'flex.orders.validation.force_update.list.text3',
    },
  },
  emptyRows: {
    title: 'flex.orders.validation.empty_rows.title',
    description1: 'flex.orders.validation.empty_rows.description1',
    description2: 'flex.orders.validation.empty_rows.description2',
    description3: 'flex.orders.validation.empty_rows.description3',
    go_payroll: 'flex.orders.validation.empty_rows.go_payroll',
    payroll_legend: 'flex.orders.validation.empty_rows.payroll_legend',
  },
}
