import { Seq } from 'immutable'
import type { ChangeEvent, Dispatch} from 'react';
import { useCallback } from 'react'

interface SelectorComboBoxState {
  onChangeSelector: (event: ChangeEvent<HTMLSelectElement>, fieldName: string) => void
}

export const useSelectorComboBox = (
  selectorState: [string, string][],
  setSelectorState: Dispatch<React.SetStateAction<[string, string][]>>
): SelectorComboBoxState => {
  const onChangeSelector = useCallback(
    (event: ChangeEvent<HTMLSelectElement>, fieldName: string) => {
      const newSelectorState: [string, string][] = []
      Seq(selectorState).forEach(state => {
        if (state[0] === fieldName) newSelectorState.push([state[0], event.target.value])
        else newSelectorState.push(state)
      })
      setSelectorState(newSelectorState)
    },
    [selectorState]
  )

  return { onChangeSelector }
}
