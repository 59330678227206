import type {
  HistorySpendingParams,
  HistorySpendingRequestQuery,
  HistorySpendingParams as IHistorySpendingParams,
  IPostPaginationModel,
} from 'src/domain/interfaces/history/HistorySpending'
import type { HttpModel } from '../../../domain/models'
import type { HttpRequest } from 'src/domain/protocols'
import { HttpMethod } from 'src/domain/protocols'
import {
  assignmentHistoryExcel,
  assignmentHistory as assignmentsHistory,
  dietSpendings,
  dietSpendingsExcel,
  usersAccountOperations,
  usersAccountOperationsExcel,
  usersConsumption,
  usersConsumptionExcel,
} from 'src/config/constants/endpoints'
import { axiosFetch } from 'src/infrastructure/axios'
import { DietSpendingsRequest } from 'src/domain/models/historySpendings/DietSpendingsRequest'
import { DietSpendingModelResponse } from 'src/domain/models/historySpendings'
import {
  IUserConsumptionResponse,
  UsersAccountOperationsParams,
  UsersAccountOperationsRequestQuery,
  UsersAccountOperationsResponse,
} from 'src/domain/history'
import {
  AssignmentsModelResponse,
  AssignmentsRequest,
} from 'src/domain/models/historySpendings/AssignmentsModel'

export class HistorySpendingRepository {
  async GetUserConsumptionList(
    filters: IHistorySpendingParams,
    pagination: IPostPaginationModel
  ): Promise<HttpModel<IUserConsumptionResponse>> {
    const query: HistorySpendingRequestQuery = {
      ...filters,
      paginationModel: pagination,
    }
    const { url, method, body }: HttpRequest = {
      url: usersConsumption,
      method: HttpMethod.POST,
      body: { ...query },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async GetUserRechargeUnloadComsumtionList(
    filters: UsersAccountOperationsParams,
    pagination: IPostPaginationModel
  ): Promise<HttpModel<UsersAccountOperationsResponse>> {
    const query: UsersAccountOperationsRequestQuery = {
      ...filters,
      paginationModel: pagination,
    }
    const { url, method, body }: HttpRequest = {
      url: usersAccountOperations,
      method: HttpMethod.POST,
      body: { ...query },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async GetDietSpendingsList(
    filters: DietSpendingsRequest
  ): Promise<HttpModel<DietSpendingModelResponse>> {
    const { url, method, body }: HttpRequest = {
      url: dietSpendings,
      method: HttpMethod.POST,
      body: { ...filters, paginationModel: filters.paginationModel },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadDietExcel(request: DietSpendingsRequest): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: dietSpendingsExcel, //TODO: Faltan las URL
      method: HttpMethod.POST,
      body: { ...request },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadConsumptionsExcel(
    request: HistorySpendingParams
  ): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: usersConsumptionExcel,
      method: HttpMethod.POST,
      body: { ...request },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadRechargeAndUnloadExcel(
    request: UsersAccountOperationsParams
  ): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: usersAccountOperationsExcel,
      method: HttpMethod.POST,
      body: { ...request },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async AssignmentsList(
    filters: AssignmentsRequest
  ): Promise<HttpModel<AssignmentsModelResponse>> {
    const { url, method, body }: HttpRequest = {
      url: assignmentsHistory,
      method: HttpMethod.POST,
      body: { ...filters, paginationModel: filters.paginationModel },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async GetAssignmentsExcel(request: AssignmentsRequest): Promise<HttpModel<string>> {
    const { url, method, body }: HttpRequest = {
      url: assignmentHistoryExcel,
      method: HttpMethod.POST,
      body: { ...request },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
