import cx from 'classnames'

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>

type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

interface GridItemProps {
  width?: IntRange<0, 101>
  children: React.ReactNode
  className?: string
}

const GridItem = (props: GridItemProps): JSX.Element => {
  return (
    <div
      className={cx('grid-item', props.className)}
      style={{
        width: `${props.width}%`,
      }}
    >
      {props.children}
    </div>
  )
}

export default GridItem
