import {
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { flexPendingTasksTranslations } from '../../translations'
import { TaskTypeEnum } from 'src/Flex/TaskManager/domain'

interface Props {
  image: string
  title: string
  subtitle: string
  description: string
  taskType: string | TaskTypeEnum
}
export const PendingTaskEmptyModule = ({
  image,
  title,
  subtitle,
  description,
  taskType,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <OreTableContainer>
        <OreTable>
          <OreTableCaption
            title={title}
            counter={
              taskType !== 'employees' && taskType !== 'general'
                ? '0 ' +
                  t(flexPendingTasksTranslations.tasks.homeTables.ordersDescription)
                : ''
            }
          />
          <OreTableBody>
            <OreTableRow>
              <OreTableCell>
                <div className="px-1 py-105" style={{ textAlign: 'center' }}>
                  <figure>
                    <img src={image} alt="" />
                  </figure>
                  <div className="pt-1">
                    <OreText size="text-md" bold align="center" tone="neutral-800">
                      {subtitle}
                    </OreText>
                  </div>
                  <div className="pt-05">
                    <OreText align="center">{description}</OreText>
                  </div>
                </div>
              </OreTableCell>
            </OreTableRow>
          </OreTableBody>
        </OreTable>
      </OreTableContainer>
    </>
  )
}
