import { useTranslation } from 'react-i18next'
import {
  dateIsAfterLastDayOfMonthOneYearLater,
  dateIsAfterMilesimaDate,
  dateValueIsLowerThanTomorrow,
} from 'src/core/helpers'
import { edenredProducts } from 'src/domain/enum'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { useUser } from 'src/presentation/context/user/UserProvider'
import * as Yup from 'yup'

export const useTGDBasicOrderSummarySchema = () => {
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketGuarderia
  )

  const TGDBasicOrderSummarySchema = Yup.object({
    billReference: currentContract?.isCommentRequiredFromClient
      ? Yup.string().required(t(forms.errors.fieldRequired))
      : Yup.string().nullable(),
    acceptConditions: Yup.boolean().oneOf([true], t(forms.errors.termsAndConditions)),
    initialChargeDate: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .test('testDate', t(cardOrderTranslation.form.errors.dateBeforeToday), value => {
        const error = value ? dateValueIsLowerThanTomorrow(value) : null
        return !error
      })
      .test(
        'testDateMax',
        () =>
          currentContract?.allowMilesima
            ? t(cardOrderTranslation.form.errors.dateBeforeMilesima)
            : t(cardOrderTranslation.form.errors.dateBeforOneYear),
        value => {
          if (value) {
            const compareDate = currentContract?.allowMilesima
              ? dateIsAfterMilesimaDate(value)
              : dateIsAfterLastDayOfMonthOneYearLater(value)
            return !compareDate
          }
          return false
        }
      ),
  })

  return { TGDBasicOrderSummarySchema }
}
