import { useTranslation } from 'react-i18next'
import { SimpleHeaderWithAddButtonTemplate } from 'src/presentation/Templates'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { AccountUsersAndLicensesContainer } from './organism'
import { useAccountUsersAndLicences } from './hooks/useAccountUsersAndLicences'

export const AccountUsersAndLicensesPage = (): JSX.Element => {
  const { t } = useTranslation()
  const props = useAccountUsersAndLicences()

  const onAddButton = () => {
    props.setIsEditing(false)
    props.setShowAdd(true)
  }

  return (
    <SimpleHeaderWithAddButtonTemplate
      title={t(accountUsersLicensesTranslations.title)}
      addButtonEnabled={true}
      buttonAction={() => onAddButton()}
      buttonText={t(accountUsersLicensesTranslations.addButton)}
      organism={<AccountUsersAndLicensesContainer props={props} />}
    />
  )
}
