import React from 'react'
import { useTranslation } from 'react-i18next'
import { generalTermsTranslation } from '../../../domain/translations/myProducts/generalTerms/generalTermsTranslation'
import { PageHeader } from '../../Templates/PageHeader'
import { GeneralTermsContainer } from './organism/GeneralTermsContainer'
import { useProductsShared } from '../hooks/useProductsShared'

interface Props {
  productCode: number
}

export const GeneralTermsPage = ({ productCode }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { GetProductNameByCode } = useProductsShared()
  return (
    <PageHeader
      title={t(generalTermsTranslation.title) + GetProductNameByCode(productCode)}
      organism={<GeneralTermsContainer productCode={productCode} />}
    />
  )
}
