import { useTranslation } from 'react-i18next'
import { OreText } from '@runroom/oreneta'
import { NavLink, useNavigate } from 'react-router-dom'
import type { UploadExcelStepProps } from '.';
import { UploadExcelStep } from '.'
import { edenredProducts } from '../../../../../domain/enum'
import { cardOrderTranslation, recharge } from '../../../../../domain/translations'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { SimpleForm } from '../../../../Templates'

export const OrderRechargeExcelPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const uploadExcelStepProps: UploadExcelStepProps = {
    product: edenredProducts.ticketRestaurant,
    additionalHelp: (
      <div className={'mt-2'}>
        <OreText>
          {t(cardOrderTranslation.excel.repeatOrderText)}{' '}
          <NavLink to={navigationRoutes.myOrdersHistory}>
            {t(cardOrderTranslation.excel.repeatOrderLink)}
          </NavLink>
        </OreText>
      </div>
    ),
  }

  return (
    <div>
      <SimpleForm
        title={t(recharge.title)}
        organisms={[<UploadExcelStep {...uploadExcelStepProps} />]}
      />
    </div>
  )
}
