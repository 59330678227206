import Loader from '../components/Edenred/spinner/Loader'
import Notification from '../components/Edenred/notification/Notification'
import { MedalliaTag } from '../components/Edenred/medalliaTag'

// purpose of this component: app container
const PageApp = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <>
      {children}
      <Loader />
      <Notification />
      <MedalliaTag
        src="https://nebula-cdn.kampyle.com/eu/we/932859/onsite/embed.js"
        async
      />
    </>
  )
}

export default PageApp
