import { OreCardContainer, OreDivider, OreHeading, OreText } from '@runroom/oreneta'

interface Props {
  image: string
  title: string
  subtitle: string
  description: string
}

export const EmptyTableImage = ({
  image,
  title,
  subtitle,
  description,
}: Props): JSX.Element => {
  return (
    <OreCardContainer hasShadow radius="all" tone="white">
      <div className="px-1 py-105">
        <OreHeading size="headline-md">{title}</OreHeading>
      </div>
      <OreDivider />
      <div className="px-1 py-105" style={{ textAlign: 'center' }}>
        <figure>
          <img src={image} alt="" />
        </figure>
        <div className="pt-1">
          <OreText size="text-md" bold align="center" tone="neutral-800">
            {subtitle}
          </OreText>
        </div>
        <div className="pt-05">
          <OreText align="center">{description}</OreText>
        </div>
      </div>
    </OreCardContainer>
  )
}
