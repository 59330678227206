import { EmptyTableImage } from 'src/presentation/components/Edenred/EmptyTableImage'
import { clientHomeTranslation } from 'src/domain/translations'
import RepeatOrdersImg from '../../../../assets/img/Timeout.svg'
import { useTranslation } from 'react-i18next'
import { useRepeatLastOrdersController } from '../hooks/useRepeatLastOrdersController'
import { useOrderClienteHome } from '../hooks/useOrderClienteHome'
import { clientOrderTableHomeType } from 'src/domain/enum'
import { Modal, Table } from 'src/presentation/components/Edenred'

export const RepeatLastOrdersClientHome = () => {
  const { footer } = useRepeatLastOrdersController()
  const {
    caption,
    rows,
    setRows,
    showModalRepetExcel,
    setShowModalRepetExcel,
    titleModalRepetExcel,
    descriptionModalRepetExcel,
    buttonsModalRepetExcel,
    htmlElementModalRepeatExcel,
  } = useOrderClienteHome(clientOrderTableHomeType.repeatOrder)
  const { t } = useTranslation()

  if (rows.length > 0)
    return (
      <>
        <Table caption={caption} rows={rows} setRows={setRows} footer={footer} />
        <Modal
          handleOnClose={setShowModalRepetExcel}
          open={showModalRepetExcel}
          title={titleModalRepetExcel}
          description={descriptionModalRepetExcel}
          buttons={buttonsModalRepetExcel}
          htmlElement={htmlElementModalRepeatExcel}
        />
      </>
    )
  else
    return (
      <EmptyTableImage
        image={RepeatOrdersImg}
        description={t(
          clientHomeTranslation.ticketRestaurant.repetOrder.emptyTableDescription
        )}
        title={caption.title}
        subtitle={t(clientHomeTranslation.ticketRestaurant.repetOrder.emptyTableSubtitle)}
      />
    )
}
