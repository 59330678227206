import { useTTOrderFormEditController } from './hooks'
import { TTOrderForm } from './organism'

export const TTOrderFormEditPage = (): JSX.Element => {
  const { ttOrderItemToEdit } = useTTOrderFormEditController()

  if (!ttOrderItemToEdit) {
    return <></>
  }

  return <TTOrderForm ttOrderItemToEdit={ttOrderItemToEdit} />
}
