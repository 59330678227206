import React from 'react'
import { useDeliverySiteController } from '../hooks'
import { Modal, Table } from '../../Edenred'

interface Props {
  productCode: number
}

export const DeliverySiteContainer = ({ productCode }: Props) => {
  const {
    header,
    rows,
    setRows,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    filter,
    setFilter,
    onClickFilter,
    footer,
    tableEmpty,
  } = useDeliverySiteController(productCode)

  return (
    <div>
      <Table
        header={header}
        rows={rows}
        setRows={setRows}
        tableBodyValign="middle"
        filter={filter}
        setFilter={setFilter}
        onClickFilter={onClickFilter}
        footer={footer}
        tableEmpty={tableEmpty}
      />
      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
      />
    </div>
  )
}
