import { useNotification } from '../../../context/notification/NotificationProvider'
import { OreSnackBar } from '@runroom/oreneta'

const Notification = () => {
  const { notification, removeNotification } = useNotification()

  if (!notification.message) return <></>

  return (
    <OreSnackBar
      autoHideDuration={6000}
      color={notification.severity}
      message={notification.message}
      open={true}
      onClose={removeNotification}
      position="top"
    />
  )
}

export default Notification
