export const createQueryString = (data: any) => {
  let str = ''
  Object.keys(data).forEach(key => {
    const val = data[key]
    if (val !== undefined) {
      if (val !== null && typeof val === 'object') {
        str += createQueryStringNested(val, key)
      } else {
        str = str.concat(`${key}=${encodeURIComponent(`${val}`)}&`)
      }
    }
  })
  return str.length > 0 ? '?' + str.slice(0, -1) : str
}

export const createQueryStringNested = (data: any, padre: string) => {
  let str = ''
  Object.keys(data).forEach(key => {
    const val = data[key]
    if (val !== undefined)
      str = str.concat(`${padre}.${key}=${encodeURIComponent(`${val}`)}&`)
  })
  return str
}
