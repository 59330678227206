import {
  OreButton,
  OreDivider,
  OreHeading,
  OreStack,
  OreText,
  SvgArrowLeft,
} from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import { useTranslation } from 'react-i18next'
import { flexPayrollsTranslations } from 'src/Flex/Payrolls/ui/translations'
import { DropContainer } from './organisms/DropContainer'
import { forms } from 'src/domain/translations'
import { useNavigate } from 'react-router-dom'

export const FlexPayrollCustomPage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <PageWrapper>
      <OreHeading as="h1" size="title-md">
        {t(flexPayrollsTranslations.customPayroll.title)}
      </OreHeading>
      <OreDivider space="medium" />
      <OreStack direction="column" space="medium">
        <OreStack direction="column">
          <OreText>{t(flexPayrollsTranslations.customPayroll.descriptipn)}</OreText>
          <div style={{ paddingLeft: '0.5rem' }}>
            <OreText>{t(flexPayrollsTranslations.customPayroll.descriptipn1)}</OreText>
            <OreText>{t(flexPayrollsTranslations.customPayroll.descriptipn2)}</OreText>
            <OreText>{t(flexPayrollsTranslations.customPayroll.descriptipn3)}</OreText>
            <div style={{ paddingLeft: '1.5rem' }}>
              <OreText>{t(flexPayrollsTranslations.customPayroll.descriptipn3a)}</OreText>
              <OreText>{t(flexPayrollsTranslations.customPayroll.descriptipn3b)}</OreText>
            </div>
          </div>
          <OreText>{t(flexPayrollsTranslations.customPayroll.lastDescription)}</OreText>
        </OreStack>
        <DropContainer />
      </OreStack>
    </PageWrapper>
  )
}
