import { LegalDocumentType } from 'src/domain/enum'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { navigationTranslation } from '../../../../../domain/translations'
import type { NavbarUserListItemModel } from '../header/molecules/NavbarUser'
import { useAccountController } from '../hooks/useAccountController'
import { viewTag } from 'src/domain/enum/permissionTags'

interface NavigationState {
  navbarUserMenu: NavbarUserListItemModel[]
}

export const useHeaderNavigationData = (): NavigationState => {
  const { downloadDocument } = useAccountController()

  const navbarUserMenu: NavbarUserListItemModel[] = [
    {
      title: navigationTranslation.header.user.menu.configuration,
      link: navigationRoutes.accountSettings,
    },
    //TODO: Uncomment when user/profiles/roles are ready
    {
      title: navigationTranslation.header.user.menu.usersAndLicenses,
      link: navigationRoutes.accountUsersAndLicenses,
      protectedBy: viewTag.VIEW_SUB_MYACCOUNT_USERACCESS,
    },
    {
      title: navigationTranslation.header.user.menu.notifications,
      link: navigationRoutes.accountNotification,
    },
    {
      title: navigationTranslation.header.user.menu.usageConditions,
      divider: true,
      size: 'text-xs',
      handleClick: () => downloadDocument(LegalDocumentType.useWebConditions),
    },
    {
      title: navigationTranslation.header.user.menu.legal,
      size: 'text-xs',
      handleClick: () => downloadDocument(LegalDocumentType.legalAdvice),
    },
    {
      title: navigationTranslation.header.user.menu.privacyPolicy,
      size: 'text-xs',
      handleClick: () => downloadDocument(LegalDocumentType.privacyPolicies),
    },
    {
      title: navigationTranslation.header.user.menu.cookiesPolicy,
      size: 'text-xs',
      handleClick: () => downloadDocument(LegalDocumentType.cookiesPolicies),
    },
  ]

  return { navbarUserMenu }
}
