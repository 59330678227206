import { OreHeading, OreStack } from '@runroom/oreneta'
import { TGDBasicOrderProvider } from './context/TGDBasicOrderProvider'
import { Stepper, TGDBasicStepsData } from '../Edenred/stepper'
import { Outlet } from 'react-router-dom'
import { stepper } from 'src/domain/enum'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useTranslation } from 'react-i18next'
import { TGDBasicOrderTranslation } from 'src/domain/translations/tgdOrders/tgdBasicOrderTranslate'

export const TGDBasicOrderPageTemplate = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <TGDBasicOrderProvider>
      <OreStack placeContent="normal" space="xlarge">
        <header className="width-max">
          <OreStack placeContent="normal" space="large">
            <OreHeading as="h1" size="title-md">
              {t(TGDBasicOrderTranslation.title)}
            </OreHeading>
            <Stepper
              paddingTop={0}
              paddingBottom={0}
              currentStep={stepper.firstStep}
              stepsFor={TGDBasicStepsData}
              error={
                window.location.pathname === navigationRoutes.tgdBasicOrderCompleteError
              }
            />
          </OreStack>
        </header>
        <Outlet />
      </OreStack>
    </TGDBasicOrderProvider>
  )
}
