import { TaskCheckModel, TaskModel, TaskTypeEnum } from 'src/Flex/TaskManager/domain'
import { CaptionModel, RowModel, TableRowEspec } from 'src/domain/customComponents/table'
import { flexPendingTasksTranslations } from '../../translations'
import { t } from 'i18next'
import { Seq } from 'immutable'
import { getTaskIconByTaskType } from '../atoms'
import { SvgCommunications } from '@runroom/oreneta'

export class FlexHomeTables<T> {
  private strategy: Strategy<T>

  constructor(strategy: Strategy<T>) {
    this.strategy = strategy
  }

  SetStrategy(strategy: Strategy<T>) {
    this.strategy = strategy
  }

  GetCaption(caption: string, totalRow?: number): CaptionModel {
    return this.strategy.GetCaption(caption, totalRow)
  }

  GetRows(tasks: TaskModel[], onClick: any, taskType: string | TaskTypeEnum): RowModel[] {
    return this.strategy.GetRows(tasks, onClick, taskType)
  }

  GetTaskCheck(taskType: string | TaskTypeEnum): TaskCheckModel {
    return this.strategy.GetTaskCheck(taskType)
  }
}

interface Strategy<T> {
  GetCaption: (caption: string, totalRow?: number) => CaptionModel
  GetRows: (
    tasks: TaskModel[],
    onClick: any,
    taskType: string | TaskTypeEnum
  ) => RowModel[]
  GetTaskCheck: (taskType: string | TaskTypeEnum) => TaskCheckModel
}

export class Default<T> implements Strategy<T> {
  GetCaption(caption: string, totalRow?: number | undefined): CaptionModel {
    return { description: '', title: '' }
  }

  GetRows(tasks: TaskModel[], onClick: any, taskType: string | TaskTypeEnum): RowModel[] {
    return []
  }

  GetTaskCheck(taskType: string | TaskTypeEnum): TaskCheckModel {
    return { description: '', title: '' }
  }
}

export class PendingTable implements Strategy<TaskModel> {
  GetTaskCheck(taskType: string | TaskTypeEnum): TaskCheckModel {
    if (taskType === TaskTypeEnum.pendingImputation) {
      return {
        description: t(
          flexPendingTasksTranslations.tasks.homeTables.notPendingInputingDesc
        ),
        title: t(flexPendingTasksTranslations.tasks.homeTables.notPendingInputing),
      }
    }
    if (taskType === TaskTypeEnum.pendingValidation) {
      return {
        description: t(
          flexPendingTasksTranslations.tasks.homeTables.notPendigValidateDesc
        ),
        title: t(flexPendingTasksTranslations.tasks.homeTables.notPendigValidate),
      }
    }

    return { description: '', title: '' }
  }

  GetCaption(caption: string, totalRow?: number): CaptionModel {
    return {
      title: caption,
      rowCount: totalRow,
      description: t(flexPendingTasksTranslations.tasks.homeTables.ordersDescription),
    }
  }

  GetRows(tasks: TaskModel[], onClick: any, taskType: string | TaskTypeEnum): RowModel[] {
    const rows: RowModel[] = []
    Seq(tasks).forEach((task: TaskModel, index: number) => {
      rows.push({
        rowId: task.type + index,
        cells: [
          {
            type: 'element',
            cellElement: getTaskIconByTaskType(
              task.type,
              task.productType,
              task.step,
              task.active ?? true
            ),
          },
          {
            type: 'text',
            cellText: [
              { text: task.title, name: 'task', isBold: true, align: 'left' },
              {
                text: `${task.amount} 
                ${t(flexPendingTasksTranslations.tasks.homeTables.orderSubDescription)}`,
                name: 'contracts',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'complete',
                type: 'button',
                onClick: () => onClick(task),
                category: 'secondary',
                text:
                  taskType === TaskTypeEnum.pendingValidation
                    ? t(flexPendingTasksTranslations.tasks.homeTables.pendigValidate)
                    : taskType === TaskTypeEnum.pendingImputation
                    ? t(flexPendingTasksTranslations.tasks.homeTables.pendingInputing)
                    : '',
              },
            ],
          },
        ],
      })
    })
    return rows
  }
}

export class ManagementEmployessTable implements Strategy<TaskModel> {
  GetTaskCheck(taskType: string | TaskTypeEnum): TaskCheckModel {
    if (taskType === 'employees') {
      return {
        description: t(
          flexPendingTasksTranslations.tasks.homeTables.notPendingEmployeesDesc
        ),
        title: t(flexPendingTasksTranslations.tasks.homeTables.notPendingEmployees),
      }
    } else {
      return {
        description: t(
          flexPendingTasksTranslations.tasks.homeTables.notPendingGeneralDesc
        ),
        title: t(flexPendingTasksTranslations.tasks.homeTables.notPendingGeneral),
      }
    }
  }
  GetCaption(caption: string, totalRow?: number): CaptionModel {
    return {
      title: caption,
      description: '',
    }
  }

  GetRows(tasks: TaskModel[], onClick: any, taskType: string | TaskTypeEnum): RowModel[] {
    const rows: RowModel[] = []
    Seq(tasks).forEach((task: TaskModel, index: number) => {
      rows.push({
        rowId: task.type + index,
        cells: [
          {
            type: 'element',
            cellElement: getTaskIconByTaskType(
              task.type,
              task.productType,
              task.step,
              task.active ?? true
            ),
          },
          {
            type: 'text',
            cellText: [
              { text: task.title, name: 'task', isBold: true, align: 'left' },
              {
                text: `${task.amount} 
                ${t(flexPendingTasksTranslations.tasks.homeTables.pendingDescription)}`,
                name: 'contracts',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'complete',
                type: 'button',
                iconAlign: 'right',
                onClick: () => onClick(task),
                category: 'secondary',
                text:
                  taskType === 'employees' && task.type === 1
                    ? t(flexPendingTasksTranslations.tasks.homeTables.search_employee)
                    : taskType == 'general' && task.type === 5
                    ? t(flexPendingTasksTranslations.tasks.homeTables.pendingSend)
                    : taskType == 'general' && task.type === 2
                    ? t(flexPendingTasksTranslations.tasks.homeTables.add_product)
                    : '',
              },
            ],
          },
        ],
      })
    })
    return rows
  }
}
