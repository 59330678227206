import type { HttpModel } from '../../../domain/models'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../axios'
import type { IInvoiceResponse } from '../../../domain/interfaces/invoice/IInvoiceApiResponse'
import { creditNotesList, invoicesList } from '../../../config/constants/endpoints'
import type { ICreditNotesResponse } from '../../../domain/interfaces/invoice/ICreditNotesApiResponse'

export class InvoiceRepository {
  async GetInvoices(query = ''): Promise<HttpModel<IInvoiceResponse>> {
    const { url, method, body }: HttpRequest = {
      url: invoicesList + query,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async GetCreditNotes(query: string): Promise<HttpModel<ICreditNotesResponse>> {
    const { url, method, body }: HttpRequest = {
      url: creditNotesList + query,
      method: HttpMethod.GET,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
