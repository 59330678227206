export const TTOrderTranslation = {
  title: 'ttOrderTranslation.title',

  form: {
    title: 'ttOrderTranslation.form.title',
    editTitle: 'ttOrderTranslation.form.editTitle',

    user: {
      title: 'ttOrderTranslation.form.user.title',
      documentType: {
        helpText: 'ttOrderTranslation.form.user.documentType.helpText',
      },
      birthdate: {
        helpText: 'ttOrderTranslation.form.user.birthdate.helpText',
      },
      email: {
        helpText: 'ttOrderTranslation.form.user.email.helpText',
      },
    },
    card: {
      title: 'ttOrderTranslation.form.card.title',
      initialAmount: {
        label: 'ttOrderTranslation.form.card.initialAmount.label',
        helpText: 'ttOrderTranslation.form.card.initialAmount.helpText',
      },
    },
    shipping: {
      title: 'ttOrderTranslation.form.shipping.title',
      deliverySiteSelect: {
        label: 'ttOrderTranslation.form.shipping.deliverySiteSelect.label',
        placeholder: 'ttOrderTranslation.form.shipping.deliverySiteSelect.placeholder',
        helpText: 'ttOrderTranslation.form.shipping.deliverySiteSelect.helpText',
      },
      addNewDeliverySite: 'ttOrderTranslation.form.shipping.addNewDeliverySite',
    },
    buttons: {
      createNewCard: 'ttOrderTranslation.form.buttons.createNewCard',
    },
    mandatoryFields: 'ttOrderTranslation.form.mandatoryFields',
  },
  summary: {
    table: {
      title: 'ttOrderTranslation.summary.table.title',
      subtitle: 'ttOrderTranslation.summary.table.subtitle',
      header: {
        user: 'ttOrderTranslation.summary.table.header.user',
        reason: 'ttOrderTranslation.summary.table.header.reason',
        initialAmount: 'ttOrderTranslation.summary.table.header.initialAmount',
        balance: {
          label: 'ttOrderTranslation.summary.table.header.balance.label',
          placeholder: 'ttOrderTranslation.summary.table.header.balance.placeholder',
        },
        deliverySite: 'ttOrderTranslation.summary.table.header.deliverySite',
        actions: 'ttOrderTranslation.summary.table.header.actions',
      },
      emptyTable: {
        emptyText: 'ttOrderTranslation.summary.table.emptyTable.emptyText',
      },
      body: {
        cardRequestType: {
          newCard: 'ttOrderTranslation.summary.table.body.cardRequestType.newCard',
          duplicated: 'ttOrderTranslation.summary.table.body.cardRequestType.duplicated',
        },
      },
      footer: {
        createNewCard: 'ttOrderTranslation.summary.table.footer.createNewCard',
        addDuplicated: 'ttOrderTranslation.summary.table.footer.addDuplicated',
      },
    },
    completeOrder: {
      title: 'ttOrderTranslation.summary.completeOrder.title',
      dateDescription: 'ttOrderTranslation.summary.completeOrder.dateDescription',
      dateRecharge: 'ttOrderTranslation.summary.completeOrder.dateRecharge',
      dateRechargeLabelTooltip:
        'ttOrderTranslation.summary.completeOrder.dateRechargeLabelTooltip',
      dateRechargePlaceholder:
        'ttOrderTranslation.summary.completeOrder.dateRechargePlaceholder',
      expiredDate: 'ttOrderTranslation.summary.completeOrder.expiredDate',
      expiredDateLabelTooltip:
        'ttOrderTranslation.summary.completeOrder.expiredDateLabelTooltip',
      expiredDatePlaceholder:
        'ttOrderTranslation.summary.completeOrder.expiredDatePlaceholder',
      billReferenceDescription:
        'ttOrderTranslation.summary.completeOrder.billReferenceDescription',
      billReference: 'ttOrderTranslation.summary.completeOrder.billReference',
      billReferencePlaceholder:
        'ttOrderTranslation.summary.completeOrder.billReferencePlaceholder',
      fieldsRequired: 'ttOrderTranslation.summary.completeOrder.fieldsRequired',
      termsAcceptance1: 'ttOrderTranslation.summary.completeOrder.termsAcceptance1',
    },
    information: {
      newCard: {
        title: 'ttOrderTranslation.summary.information.newCard.title',
        description: 'ttOrderTranslation.summary.information.newCard.description',
      },
      duplicated: {
        title: 'ttOrderTranslation.summary.information.duplicated.title',
        description: 'ttOrderTranslation.summary.information.duplicated.description',
        bulletList: {
          item1: {
            title:
              'ttOrderTranslation.summary.information.duplicated.bulletList.item1.title',
            description:
              'ttOrderTranslation.summary.information.duplicated.bulletList.item1.description',
          },
          item2: {
            title:
              'ttOrderTranslation.summary.information.duplicated.bulletList.item2.title',
            description:
              'ttOrderTranslation.summary.information.duplicated.bulletList.item2.description',
          },
        },
      },
    },
    deleteModal: {
      title: 'ttOrderTranslation.summary.deleteModal.title',
      description: 'ttOrderTranslation.summary.deleteModal.description',
    },
    cancelModal: {
      title: 'ttOrderTranslation.summary.cancelModal.title',
      description: 'ttOrderTranslation.summary.cancelModal.description',
    },
    checkoutButton: 'ttOrderTranslation.summary.checkoutButton',
  },
  selectMultipleEmployees: {
    title: 'ttOrderTranslation.selectMultipleEmployees.title',
    buttons: {
      configureCard: 'ttOrderTranslation.selectMultipleEmployees.buttons.configureCard',
    },
    table: {
      duplicatedTooltip:
        'ttOrderTranslation.selectMultipleEmployees.table.duplicatedTooltip',
      rechargeTooltip: 'ttOrderTranslation.selectMultipleEmployees.table.rechargeTooltip',
      unloadTooltip: 'ttOrderTranslation.selectMultipleEmployees.table.unloadTooltip',
      emptyBalanceTooltip:
        'ttOrderTranslation.selectMultipleEmployees.table.emptyBalanceTooltip',
    },
  },

  // TT RECHARGE
  recharge: {
    title: 'ttOrderTranslation.recharge.title',
    summary: {
      table: {
        title: 'ttOrderTranslation.recharge.summary.table.title',
        subtitle: 'ttOrderTranslation.recharge.summary.table.subtitle',
        header: {
          user: 'ttOrderTranslation.recharge.summary.table.header.user',
          balance: {
            label: 'ttOrderTranslation.recharge.summary.table.header.balance.label',
            tooltip: 'ttOrderTranslation.recharge.summary.table.header.balance.tooltip',
          },
          initialAmount: 'ttOrderTranslation.recharge.summary.table.header.initialAmount',
          finalBalance: {
            label: 'ttOrderTranslation.recharge.summary.table.header.finalBalance.label',
            tooltip:
              'ttOrderTranslation.recharge.summary.table.header.finalBalance.tooltip',
          },
          costCenter: 'ttOrderTranslation.recharge.summary.table.header.costCenter',
          actions: 'ttOrderTranslation.recharge.summary.table.header.actions',
        },
        emptyTable: {
          emptyText: 'ttOrderTranslation.recharge.summary.table.emptyTable.emptyText',
        },
        footer: {
          createNewRecharge:
            'ttOrderTranslation.recharge.summary.table.footer.createNewRecharge',
          editAll: 'ttOrderTranslation.recharge.summary.table.footer.editAll',
        },
      },
      deleteModal: {
        title: 'ttOrderTranslation.recharge.summary.deleteModal.title',
      },
      completeOrder: {
        dateDescription:
          'ttOrderTranslation.recharge.summary.completeOrder.dateDescription',
      },
    },
    employeeSelection: {
      buttons: {
        configureRecharge:
          'ttOrderTranslation.recharge.employeeSelection.buttons.configureRecharge',
      },
    },
    employeesConfiguration: {
      initialBalance: 'ttOrderTranslation.recharge.employeesConfiguration.initialBalance',
      initialBalanceTooltip:
        'ttOrderTranslation.recharge.employeesConfiguration.initialBalanceTooltip',
      initialAmount: 'ttOrderTranslation.recharge.employeesConfiguration.initialAmount',
      initialAmountTooltip:
        'ttOrderTranslation.recharge.employeesConfiguration.initialAmountTooltip',
    },
  },

  complete: {
    errorMessage1: 'ttOrderTranslation.complete.errorMessage1',
    errorMessage2: 'ttOrderTranslation.complete.errorMessage2',
    successMessage1: 'ttOrderTranslation.complete.successMessage1',
    successMessage2: 'ttOrderTranslation.complete.successMessage2',
    successMessage3: 'ttOrderTranslation.complete.successMessage3',
    assignmentDate: 'ttOrderTranslation.complete.assignmentDate',
    orderDate: 'ttOrderTranslation.complete.orderDate',
    unloadDate: 'ttOrderTranslation.complete.unloadDate',
    totalAmount: 'ttOrderTranslation.complete.totalAmount',
    totalCards: 'ttOrderTranslation.complete.totalCards',
    totalRecharges: 'ttOrderTranslation.complete.totalRecharges',
    totalUnloads: 'ttOrderTranslation.complete.totalUnloads',
    needDeleteCardsMessage1: 'ttOrderTranslation.complete.needDeleteCardsMessage1',
    needDeleteCardsMessage2: 'ttOrderTranslation.complete.needDeleteCardsMessage2',
    successMessage4: 'ttOrderTranslation.complete.successMessage4',
    iban: 'ttOrderTranslation.complete.iban',
    concept: 'ttOrderTranslation.complete.concept',
    transferAmount: 'ttOrderTranslation.complete.transferAmount',
    important: 'ttOrderTranslation.complete.important',
  },
  stoppersAndWarnings: {
    stoppers: {
      title: 'ttOrderTranslation.stoppersAndWarnings.stoppers.title',
      description: 'ttOrderTranslation.stoppersAndWarnings.stoppers.description',
      option1: 'ttOrderTranslation.stoppersAndWarnings.stoppers.option1',
      option1bold: 'ttOrderTranslation.stoppersAndWarnings.stoppers.option1bold',
      option11: 'ttOrderTranslation.stoppersAndWarnings.stoppers.option11',
      option2: 'ttOrderTranslation.stoppersAndWarnings.stoppers.option2',
      option2bold: 'ttOrderTranslation.stoppersAndWarnings.stoppers.option2bold',
      option22: 'ttOrderTranslation.stoppersAndWarnings.stoppers.option22',
    },
    grid: {
      title: 'ttOrderTranslation.stoppersAndWarnings.grid.title',
      title2: 'ttOrderTranslation.stoppersAndWarnings.grid.title2',
      total: 'ttOrderTranslation.stoppersAndWarnings.grid.total',
      row: 'ttOrderTranslation.stoppersAndWarnings.grid.row',
      errorType: 'ttOrderTranslation.stoppersAndWarnings.grid.errorType',
      errorDescription: 'ttOrderTranslation.stoppersAndWarnings.grid.errorDescription',
      document: 'ttOrderTranslation.stoppersAndWarnings.grid.document',
    },
    downloadButton: 'ttOrderTranslation.stoppersAndWarnings.downloadButton',
    changeFile: 'ttOrderTranslation.stoppersAndWarnings.changeFile',
    continueToResume: 'ttOrderTranslation.stoppersAndWarnings.continueToResume',
    warnings: {
      description1: 'ttOrderTranslation.stoppersAndWarnings.warnings.description1',
      description2: 'ttOrderTranslation.stoppersAndWarnings.warnings.description2',
    },
  },

  unload: {
    title: 'ttOrderTranslation.unload.title',
    employeesConfiguration: {
      infoMessage: 'ttOrderTranslation.unload.employeesConfiguration.infoMessage',
      tableTitle: 'ttOrderTranslation.unload.employeesConfiguration.tableTitle',
      unloadBalance: 'ttOrderTranslation.unload.employeesConfiguration.unloadBalance',
      unloadAllBalanceToAllEmployee:
        'ttOrderTranslation.unload.employeesConfiguration.unloadAllBalanceToAllEmployee',
      initialBalance: 'ttOrderTranslation.unload.employeesConfiguration.initialBalance',
      initialBalanceTooltip:
        'ttOrderTranslation.unload.employeesConfiguration.initialBalanceTooltip',
      initialAmount: 'ttOrderTranslation.unload.employeesConfiguration.initialAmount',
      initialAmountTooltip:
        'ttOrderTranslation.unload.employeesConfiguration.initialAmountTooltip',
    },
    employeeSelection: {
      table: {
        header: {
          status: 'ttOrderTranslation.unload.employeeSelection.table.header.status',
          balance: {
            tooltip:
              'ttOrderTranslation.unload.employeeSelection.table.header.balance.tooltip',
          },
        },
      },
      buttons: {
        configureUnload:
          'ttOrderTranslation.unload.employeeSelection.buttons.configureUnload',
      },
    },
    summary: {
      table: {
        title: 'ttOrderTranslation.unload.summary.table.title',
        subtitle: 'ttOrderTranslation.unload.summary.table.subtitle',
        header: {
          user: 'ttOrderTranslation.unload.summary.table.header.user',
          balance: {
            label: 'ttOrderTranslation.unload.summary.table.header.balance.label',
            tooltip: 'ttOrderTranslation.unload.summary.table.header.balance.tooltip',
          },
          initialAmount: 'ttOrderTranslation.unload.summary.table.header.initialAmount',
          finalBalance: {
            label: 'ttOrderTranslation.unload.summary.table.header.finalBalance.label',
            tooltip:
              'ttOrderTranslation.unload.summary.table.header.finalBalance.tooltip',
          },
          costCenter: 'ttOrderTranslation.unload.summary.table.header.costCenter',
          actions: 'ttOrderTranslation.unload.summary.table.header.actions',
        },
        emptyTable: {
          emptyText: 'ttOrderTranslation.unload.summary.table.emptyTable.emptyText',
        },
        footer: {
          createNewUnload:
            'ttOrderTranslation.unload.summary.table.footer.createNewUnload',
          editAll: 'ttOrderTranslation.unload.summary.table.footer.editAll',
        },
      },
      deleteModal: {
        title: 'ttOrderTranslation.unload.summary.deleteModal.title',
      },
      completeOrder: {
        dateDescription:
          'ttOrderTranslation.unload.summary.completeOrder.dateDescription',
        dateUnload: 'ttOrderTranslation.unload.summary.completeOrder.dateUnload',
        dateUnloadLabelTooltip:
          'ttOrderTranslation.unload.summary.completeOrder.dateUnloadLabelTooltip',
      },
    },
  },

  // TT Excel
  excel: {
    import: {
      download: {
        description: 'ttOrderTranslation.excel.import.download.description',
        step1: 'ttOrderTranslation.excel.import.download.step1',
        step2: 'ttOrderTranslation.excel.import.download.step2',
        step3: 'ttOrderTranslation.excel.import.download.step3',
        fileNameNewCard: 'ttOrderTranslation.excel.import.download.fileNameNewCard',
        fileNameRecharge: 'ttOrderTranslation.excel.import.download.fileNameRecharge',
        fileNameUnload: 'ttOrderTranslation.excel.import.download.fileNameUnload',
        recharge: {
          description: 'ttOrderTranslation.excel.import.download.recharge.description',
          step1: 'ttOrderTranslation.excel.import.download.recharge.step1',
          step2: 'ttOrderTranslation.excel.import.download.recharge.step2',
          step3: 'ttOrderTranslation.excel.import.download.recharge.step3',
          repeat1: 'ttOrderTranslation.excel.import.download.recharge.repeat1',
          repeat2: 'ttOrderTranslation.excel.import.download.recharge.repeat2',
        },
        unload: {
          description: 'ttOrderTranslation.excel.import.download.unload.description',
          step1: 'ttOrderTranslation.excel.import.download.unload.step1',
          step2: 'ttOrderTranslation.excel.import.download.unload.step2',
          step3: 'ttOrderTranslation.excel.import.download.unload.step3',
        },
      },
    },
    summary: {
      table: {
        cardsSummary: 'ttOrderTranslation.excel.summary.table.cardsSummary',
        rechargesSummary: 'ttOrderTranslation.excel.summary.table.rechargesSummary',
        unloadsSummary: 'ttOrderTranslation.excel.summary.table.unloadsSummary',
        header: {
          concepts: 'ttOrderTranslation.excel.summary.table.header.concepts',
          units: 'ttOrderTranslation.excel.summary.table.header.units',
        },
      },
    },
  },
}
