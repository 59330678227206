import { useTranslation } from 'react-i18next'
import { generalTermsServiceType } from '../../../../domain/enum/generalTerms'
import { generalTermsTranslation } from '../../../../domain/translations/myProducts/generalTerms/generalTermsTranslation'

interface GeneralTermsState {
  servicesAlias: string[]
  translateAlias(alias: string): string
}

export const useEconomicTermsController = (): GeneralTermsState => {
  const { t } = useTranslation()
  const servicesAlias = [
    generalTermsServiceType.card,
    generalTermsServiceType.comission,
    generalTermsServiceType.magagement,
    generalTermsServiceType.invoice,
    generalTermsServiceType.delivery,
  ]
  const translateAlias = (alias: string): string => {
    switch (alias) {
      case generalTermsServiceType.card:
        return t(generalTermsTranslation.economicTerms.cards)
      case generalTermsServiceType.comission:
        return t(generalTermsTranslation.economicTerms.comissions)
      case generalTermsServiceType.invoice:
        return t(generalTermsTranslation.economicTerms.invoices)
      case generalTermsServiceType.magagement:
        return t(generalTermsTranslation.economicTerms.management)
      case generalTermsServiceType.delivery:
        return t(generalTermsTranslation.economicTerms.shipment)
      default:
        return ''
    }
  }

  return { servicesAlias, translateAlias }
}
