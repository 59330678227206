import { OreTab, OreTabs } from '@runroom/oreneta'
import { t } from 'i18next'
import { edenredProducts, edenredSubProducts, historyTabs } from '../../../../domain/enum'
import { useContainerController } from '../hooks/useContainerController'
import { SpendingContainer } from '../spending/organism/SpendingContainer'
import { DietSpendingsContainer } from '../dietSpendings/organism'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { historyTranslation } from 'src/domain/translations'
import { RechargeAndUnloadSpendingContainer } from '../rechargeUnloadSending/organism/RechargeAndUnloadSpendingContainer'
import { useEffect } from 'react'
import { AssignmentsContainer } from '../assignments/organism/AssignmentsContainer'

interface HistoryContainerProps {
  navigateValue?: historyTabs
  product: edenredProducts
}

export const HistoryContainer = (props: HistoryContainerProps): JSX.Element => {
  const UpdateTab = () => {
    if (props.product === edenredProducts.ticketGuarderia) {
      setSelectedTab(historyTabs.assingments)
    } else if (currentContract?.subProductId === edenredSubProducts.dieta) {
      setSelectedTab(historyTabs.dietSpendings)
    } else {
      setSelectedTab(historyTabs.spending)
    }
  }

  const { handleTabClick, selectedTab, setSelectedTab } = useContainerController(
    props.navigateValue
  )
  const { contracts } = useUser()
  let currentContract = contracts?.find(
    contract => contract.productCode === props.product
  )
  useEffect(() => {
    // Update current contract after contracts are loaded
    currentContract = contracts?.find(contract => contract.productCode === props.product)
    UpdateTab()
  }, [contracts])

  return (
    <>
      {/* TRC */}
      {props.product === edenredProducts.ticketRestaurant && (
        <>
          <OreTabs>
            {currentContract?.subProductId !== edenredSubProducts.dieta && (
              <OreTab
                active={selectedTab === historyTabs.spending}
                handleClick={() => handleTabClick(historyTabs.spending)}
                label={t(historyTranslation.tabs.spending)}
              />
            )}
            {currentContract?.subProductId === edenredSubProducts.dieta && (
              <OreTab
                active={selectedTab === historyTabs.dietSpendings}
                handleClick={() => handleTabClick(historyTabs.dietSpendings)}
                label={t(historyTranslation.tabs.dietSpendings)}
              />
            )}
            <OreTab
              active={selectedTab === historyTabs.rechargeAndUnload}
              handleClick={() => handleTabClick(historyTabs.rechargeAndUnload)}
              label={t(historyTranslation.tabs.rechargeAndUnload)}
            />
          </OreTabs>
          {selectedTab === historyTabs.spending &&
            currentContract?.subProductId !== edenredSubProducts.dieta && (
              <SpendingContainer productCode={edenredProducts.ticketRestaurant} />
            )}
          {selectedTab === historyTabs.dietSpendings &&
            currentContract?.subProductId === edenredSubProducts.dieta && (
              <DietSpendingsContainer />
            )}
          {selectedTab === historyTabs.rechargeAndUnload && (
            <RechargeAndUnloadSpendingContainer
              productCode={edenredProducts.ticketRestaurant}
            />
          )}
          {/* more tabs here */}
        </>
      )}

      {/* TGD */}
      {props.product === edenredProducts.ticketGuarderia && (
        <>
          <OreTabs>
            <OreTab
              active={selectedTab === historyTabs.assingments}
              handleClick={() => handleTabClick(historyTabs.assingments)}
              label={t(historyTranslation.tabs.assingments)}
            />
          </OreTabs>
          {selectedTab === historyTabs.assingments && <AssignmentsContainer />}

          {/* more tabs here */}
        </>
      )}

      {/* TT */}
      {props.product === edenredProducts.ticketTransporte && (
        <>
          <OreTabs>
            <OreTab
              active={selectedTab === historyTabs.spending}
              handleClick={() => handleTabClick(historyTabs.spending)}
              label={t(historyTranslation.tabs.spending)}
            />
            <OreTab
              active={selectedTab === historyTabs.rechargeAndUnload}
              handleClick={() => handleTabClick(historyTabs.rechargeAndUnload)}
              label={t(historyTranslation.tabs.rechargeAndUnload)}
            />
          </OreTabs>
          {selectedTab === historyTabs.spending && (
            <SpendingContainer productCode={edenredProducts.ticketTransporte} />
          )}
          {selectedTab === historyTabs.rechargeAndUnload && (
            <RechargeAndUnloadSpendingContainer
              productCode={edenredProducts.ticketTransporte}
            />
          )}
          {/* more tabs here */}
        </>
      )}
    </>
  )
}
