import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrder } from '../../context/TTOrderProvider'

interface ControlState {
  ttOrderItemToEdit?: TTOrderModel
}

export const useTTOrderFormEditController = (): ControlState => {
  const { id } = useParams()
  const [ttOrderItemToEdit, setTTOrderItemToEdit] = useState<TTOrderModel>()
  const { ttOrders } = useTTOrder()
  const navigate = useNavigate()

  const setTTOrderItemData = (): void => {
    const ttOrderItemToEditData = ttOrders.find(ttOrder => ttOrder.id === id)

    if (!ttOrderItemToEditData) {
      navigate(navigationRoutes.ttOrderSummary)
    }

    setTTOrderItemToEdit(ttOrderItemToEditData)
  }

  useEffect(() => {
    if (!id) {
      navigate(navigationRoutes.ttOrderSummary)
    }
    setTTOrderItemData()
  }, [id])

  return {
    ttOrderItemToEdit,
  }
}
