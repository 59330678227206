import { OreButton, OreDivider, SvgDownload } from '@runroom/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AssignmentsState } from '../hooks/useAssingments'
import { forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  useAssignment: AssignmentsState
}

export const AssignmentsFooterButtons = ({
  useAssignment: useAssignments,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { downloadExcel, assignments: assignments } = useAssignments

  return (
    <div className="py-2">
      <OreDivider />
      <div className="py-1">
        <Grid justify="flex-end">
          <OreButton
            as="button"
            category="primary"
            size="large"
            icon={<SvgDownload />}
            disabled={!assignments || assignments.length <= 0}
            onClick={downloadExcel}>
            {t(forms.buttons.downloadExcel)}
          </OreButton>
        </Grid>
      </div>
    </div>
  )
}
