import { PropsWithChildren } from 'react'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import {
  UseExcelUploadController,
  useExcelUploadController,
} from 'src/Flex/Employees/ui/employee-excel'

type Context = UseExcelUploadController
type Props = PropsWithChildren

const [useExcelUpload, StateContextProvider] = createGenericContext<Context>()

const ExcelUploadProvider = ({ children }: Props): JSX.Element => {
  const hook = useExcelUploadController()

  return <StateContextProvider value={{ ...hook }}>{children}</StateContextProvider>
}

export { ExcelUploadProvider, useExcelUpload }
