import { OreCheckbox, OreInput, OreText } from '@runroom/oreneta'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from '../../../../../domain/enum'
import type { SaveCardOrder } from '../../../../../domain/protocols'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { useUser } from '../../../../context/user/UserProvider'

const CardOrderReferenceAndTerms = (): JSX.Element => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<SaveCardOrder>()
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )

  useEffect(() => {
    if (currentContract?.invoiceComment)
      setValue('billReference', currentContract.invoiceComment)
  }, [currentContract])

  return (
    <>
      <div className="form-molecule">
        <div className="mt-2">
          <div className="float-left">
            <OreText size="text-md">
              {t(cardOrderTranslation.cardOrderHome.referenceTitle)}
            </OreText>
          </div>
        </div>

        <div className="form-atom form-atom--half pl-0">
          <OreInput
            {...register('billReference')}
            name="billReference"
            label={t(cardOrderTranslation.cardOrderHome.billReference)}
            id="billReference"
            placeholder="Ej: FC98568"
            maxLength={400}
            required={currentContract?.isCommentRequiredFromClient}
            hasError={!!errors?.billReference}
            errorMessage={errors?.billReference?.message}
          />
        </div>
      </div>

      <div className="form-section float-left mt-2 inline-flex">
        <OreCheckbox
          {...register('acceptConditions')}
          label={<CheckboxLabel />}
          name="acceptConditions"
          hasError={!!errors?.acceptConditions}
          errorMessage={errors?.acceptConditions?.message}
        />
      </div>
    </>
  )
}

const CheckboxLabel = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.termsAcceptanceExcel1)}
      </OreText>
      <br />
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.termsAcceptanceExcel2)}
      </OreText>
    </div>
  )
}

export default CardOrderReferenceAndTerms
