import {
  OrePagination,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreText,
  OreTooltip,
} from '@runroom/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { EmployeesNurseryModel } from 'src/domain/models'
import { TGDMultipleEmployeeState } from '../hooks/useTGDOrderSelectMultipleEmployees'
import { TGDOrderSelectMultipleEmployeesCaption } from './TGDOrderSelectMultipleEmployeesCaption'
import { TGDOrderSelectMultipleEmployeesRow } from './TGDOrderSelectMultipleEmployeesRow'
import { EmployeesTGDOrderModel } from 'src/domain/models/TGDOrder/TGDOrderModel'

interface Props {
  employeesTGDProps: TGDMultipleEmployeeState
}

export const TGDOrderSelectMultipleEmployeesList = ({
  employeesTGDProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employeesTGD,
    onPageChange,
    page,
    pageSize,
    selectedEmployees,
    setSelectedEmployees,
    onSelectEmployee,
    isSelected,
    showSelected,
  } = employeesTGDProps

  return (
    <>
      <OreTableContainer>
        <div className="bg-white">
          <TGDOrderSelectMultipleEmployeesCaption employeesTGDProps={employeesTGDProps} />
        </div>
        <OreTable>
          <OreTableHead>
            <OreTableRow>
              {header.headerElement && (
                <OreTableCell as="th">{header.headerElement}</OreTableCell>
              )}
              {header.headerLabel.map((header, index) =>
                !header.isSortable ? (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}&nbsp;
                    {header.tooltip && (
                      <OreTooltip
                        placement="top"
                        separation="small"
                        text={header.tooltip}>
                        <span>{header.tooltipIcon}</span>
                      </OreTooltip>
                    )}
                  </OreTableCell>
                ) : (
                  <OreTableCell as="th" key={'lbl' + index}>
                    <OreTableSortLabel
                      active
                      direction={header.sortDirection}
                      onClick={header.onClick}>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <OreTooltip
                          placement="top"
                          separation="small"
                          text={header.tooltip}>
                          <span>{header.tooltipIcon}</span>
                        </OreTooltip>
                      )}
                    </OreTableSortLabel>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody as="tbody" valign="top">
            {employeesTGD && employeesTGD.length <= 0 ? (
              <TableEmpty colSpan={7} firstText={t(forms.table.emptyMessage)} />
            ) : showSelected && selectedEmployees.length > 0 ? (
              selectedEmployees
                .slice((page - 1) * pageSize, page * pageSize)
                .map((employeeTGD: EmployeesTGDOrderModel, index: number) => {
                  return (
                    <TGDOrderSelectMultipleEmployeesRow
                      key={'rows' + index}
                      employeeTGD={employeeTGD}
                      selectedEmployees={selectedEmployees}
                      setSelectedEmployees={setSelectedEmployees}
                      isSelected={isSelected}
                      onSelectEmployee={onSelectEmployee}
                    />
                  )
                })
            ) : (
              employeesTGD.map((employeeTGD: EmployeesTGDOrderModel, index: number) => {
                return (
                  <TGDOrderSelectMultipleEmployeesRow
                    key={'rows' + index}
                    employeeTGD={employeeTGD}
                    selectedEmployees={selectedEmployees}
                    setSelectedEmployees={setSelectedEmployees}
                    isSelected={isSelected}
                    onSelectEmployee={onSelectEmployee}
                  />
                )
              })
            )}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <OreStack direction="row" placeContent="space-between" placeItems="center">
            <OreText>{`${selectedEmployees.length} ${t(
              forms.selection.usersSelected
            )}`}</OreText>
            <OrePagination
              count={showSelected ? selectedEmployees.length : totalRows}
              labelNextPage={t(forms.pagination.next)}
              labelPreviousPage={t(forms.pagination.back)}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={pageSize}
              text={t(forms.pagination.of)}
            />
          </OreStack>
        </OreTableActions>
      </OreTableContainer>
    </>
  )
}
