import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { GetDeliverySitesRequestBody } from '../../../../../core/controllers/cardOrder/services'
import {
  deliverySiteService,
  getDeliverySitesByGroup,
  getDuplicateCardReason,
  getDuplicateCardType,
} from '../../../../../core/services'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
  SelectorModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import type { TableEmptyModel } from '../../../../../domain/customComponents/table'
import {
  cardOrderReason,
  cardRequestTypeId,
  cardType,
  edenredProducts,
  orderType,
} from '../../../../../domain/enum'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { CardOrderModel, DeliverySiteModel } from '../../../../../domain/models'
import {
  duplicatesConfigurationTranslations,
  forms,
} from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useUser } from '../../../../context/user/UserProvider'
import { useAsync } from '../../../../hooks'
import { useModalController } from '../../../Edenred'
import { SvgInfo } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'

export interface ConfigurationState {
  header: HeaderModel
  rows: RowModel[]
  selector: SelectorModel
  caption: CaptionModel
  tableEmpty: TableEmptyModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  validateTable: boolean
  ValidateTableFields: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  clearCardOrderDuplicates: () => void
  navigate: NavigateFunction
}

export const useDuplicatesConfigurationController = (): ConfigurationState => {
  const {
    getCardOrderDuplicates,
    setCardOrderDuplicates,
    clearCardOrderDuplicates,
    addOrders,
    clearOrders,
    orders,
    clearAll,
  } = useCardOrder()
  const [duplicates, setDuplicates] = useState<EmployeesWithLastCardNew[]>(
    getCardOrderDuplicates()
  )
  const [deliverySitesOptions, setDeliverySitesOptions] = useState<ComboBoxOptionModel[]>(
    []
  )
  const [rows, setRows] = useState<RowModel[]>([])
  const [selector, setSelector] = useState<SelectorModel>({
    buttonTitle: '',
    description: '',
    title: '',
    selectors: [],
  })
  const [validateTable, setValidateTable] = useState<boolean>(false)
  const navigate = useNavigate()
  const { startLoading, isLoading } = useLoader()
  const { t } = useTranslation()
  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const { state: deliverySitesResponse } = useAsync<any>(() => {
    startLoading()
    return deliverySiteService().getAll(GetDeliverySitesRequestBody())
  })

  const { company } = useUser()

  const { contracts } = useUser()
  const currentContractTR = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )

  const GetSendDeliverySiteOptions = (deliverySites: DeliverySiteModel[]) => {
    const options: ComboBoxOptionModel[] = [...deliverySitesOptions]
    if (deliverySites) {
      const groupedOptions = [...getDeliverySitesByGroup(deliverySites)]
      groupedOptions.map(option => {
        options.push(option)
      })
    }
    setDeliverySitesOptions(options)
  }

  const header: HeaderModel = {
    headerLabel: [
      { label: t(duplicatesConfigurationTranslations.table.header.name) },
      { label: t(duplicatesConfigurationTranslations.table.header.email) },
      {
        label: t(duplicatesConfigurationTranslations.table.header.reason),
        tooltip: (
          <div>
            <p>{t(duplicatesConfigurationTranslations.table.header.reasonTooltip1)}</p>
            <p className="pt-05">
              {t(duplicatesConfigurationTranslations.table.header.reasonTooltip2)}
            </p>
          </div>
        ),
        tooltipIcon: <SvgInfo aria-label="info" />,
      },
      { label: t(duplicatesConfigurationTranslations.table.header.type) },
      { label: t(duplicatesConfigurationTranslations.table.header.deliverySite) },
    ],
  }

  const caption: CaptionModel = {
    title: t(duplicatesConfigurationTranslations.table.caption.title),
    description: t(duplicatesConfigurationTranslations.table.caption.description),
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(duplicatesConfigurationTranslations.tableEmpty.first),
    linkText: t(duplicatesConfigurationTranslations.tableEmpty.link),
    linkUrl: navigationRoutes.duplicatedCard,
    endText: t(duplicatesConfigurationTranslations.tableEmpty.end),
  }

  const GetRows = () => {
    const duplicatesRows: RowModel[] = []
    Seq(duplicates).forEach((duplicate: EmployeesWithLastCardNew) => {
      const duplicateRow: RowModel = {
        rowId: duplicate.userId,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: duplicate.userName,
                name: 'name',
                tone: 'neutral-900',
                size: 'text-sm',
                isBold: true,
              },
              { text: duplicate.document, name: 'document', size: 'text-sm' },
            ],
          },
          {
            type: 'textBox',
            cellControll: {
              name: 'email',
              value: duplicate.email,
              type: 'email',
              maxLength: 75,
              errorValidate: ErrorEmailValidValue,
              massageErrorValidate: MessageEmailValidValue,
            },
          },
          {
            type: 'comboBox',
            cellControll: {
              name: 'reason',
              options: getDuplicateCardReason(),
              placeHolder: t(
                duplicatesConfigurationTranslations.table.comboBoxFields.default
              ),
              defaultValue: 0,
            },
          },
          {
            type: 'comboBox',
            cellControll: {
              name: 'type',
              options: getDuplicateCardType(currentContractTR?.allowPlasticless),
              placeHolder: t(
                duplicatesConfigurationTranslations.table.comboBoxFields.default
              ),
              value: currentContractTR?.allowPlasticless
                ? duplicate.cardTypeId?.toString()
                : cardType.Plastic.toString(),
              disabled: !currentContractTR?.allowPlasticless,
            },
          },
          {
            type: 'comboBox',
            cellControll: {
              name: 'deliverySites',
              options: deliverySitesOptions,
              placeHolder: t(
                duplicatesConfigurationTranslations.table.comboBoxFields.default
              ),
              value: duplicate.deliverySiteId ? duplicate.deliverySiteId.toString() : '0',
              defaultValue: 0,
              group: true,
            },
          },
        ],
      }
      duplicatesRows.push(duplicateRow)
    })
    setRows(duplicatesRows)
    setSelector({
      description: t(duplicatesConfigurationTranslations.selector.description),
      title: t(duplicatesConfigurationTranslations.selector.title),
      buttonTitle: t(duplicatesConfigurationTranslations.selector.button),
      selectors: [
        {
          type: 'comboBox',
          cellControll: {
            name: 'reason',
            options: getDuplicateCardReason(),
            placeHolder: t(duplicatesConfigurationTranslations.table.header.reason),
            defaultValue: 0,
          },
        },
        {
          type: 'comboBox',
          cellControll: {
            name: 'type',
            options: getDuplicateCardType(currentContractTR?.allowPlasticless),
            placeHolder: t(duplicatesConfigurationTranslations.table.header.type),
            disabled: !currentContractTR?.allowPlasticless,
          },
        },
        {
          type: 'comboBox',
          cellControll: {
            name: 'deliverySites',
            options: deliverySitesOptions,
            placeHolder: t(duplicatesConfigurationTranslations.table.header.deliverySite),
            defaultValue: 0,
            group: true,
          },
        },
      ],
    })
  }

  const getDuplicatesOnContext = () => {
    const duplicatesOnContext: EmployeesWithLastCardNew[] = []
    Seq(duplicates).forEach((duplicate: EmployeesWithLastCardNew) => {
      if (rows.find((row: RowModel) => row.rowId === duplicate.userId))
        duplicatesOnContext.push(duplicate)
    })
    setCardOrderDuplicates(duplicatesOnContext)
  }

  const ValidateTableFields = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    setValidateTable(true)
  }

  const addDuplicatesToCardOrder = () => {
    const cardOrders: CardOrderModel[] = []
    Seq(rows).forEach((row, rowIndex) => {
      let name: string | undefined
      let document: string | undefined
      let email: string | undefined
      let reason: string | undefined
      let type: string | undefined
      let requestType: number = cardRequestTypeId.default
      const duplicate: EmployeesWithLastCardNew | undefined = duplicates.find(
        duplicate => duplicate.userId === row.rowId
      )
      let companyId: number | undefined
      let deliverySiteId: string | undefined
      Seq(row.cells).forEach(cell => {
        if (cell.cellText) {
          name = cell.cellText.find(text => text.name === 'name')?.text
          document = cell.cellText.find(text => text.name === 'document')?.text
        }

        if (cell.cellControll && cell.cellControll.name === 'email') {
          email = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'reason') {
          reason = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'type') {
          type = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'deliverySites') {
          deliverySiteId = cell.cellControll.value
        }
      })

      if (reason && parseInt(reason.trim()) === cardOrderReason.stolenLost) {
        if (type && parseInt(type.trim()) === cardType.Plastic)
          requestType = cardRequestTypeId.lostStolenPlastic
        else requestType = cardRequestTypeId.lostStolenPlasticless
      }
      if (reason && parseInt(reason.trim()) === cardOrderReason.damage) {
        if (type && parseInt(type.trim()) === cardType.Plastic)
          requestType = cardRequestTypeId.damagePlastic
        else requestType = cardRequestTypeId.damagePlasticless
      }
      if (company) companyId = company.code

      const deliverySite: DeliverySiteModel = deliverySitesResponse?.rows.find(
        (ds: DeliverySiteModel) =>
          ds.deliveryPointId === parseInt(deliverySiteId ? deliverySiteId : '0')
      )
      if (duplicate && deliverySite) {
        const cardOrder: CardOrderModel = {
          orderId: new Date().getTime() + rowIndex,
          orderTypeId: orderType.Card,
          cardData: {
            productTypeId: edenredProducts.ticketRestaurant,
            initialAmount: null,
            cardTypeId: type ? parseInt(type.trim()) : cardType.Plastic,
            cardRequestTypeId: requestType,
            balance: duplicate.balance,
          },
          employeeData: {
            email: email,
            name: name?.trim(),
            firstSurname: '',
            secondSurname: '',
            document: document?.trim(),
            companyId: companyId,
            employeeNumber: duplicate.employeeNumber,
          },
          sendData: {
            alias: deliverySite.alias,
            city: deliverySite.city,
            contactName: deliverySite.contactName,
            deliverySiteId: deliverySite.deliveryPointId,
            deliveryTypeId: deliverySite.deliveryType,
            email: deliverySite.email,
            name: deliverySite.deliveryPointName,
            observation: deliverySite.observation,
            province: deliverySite.province,
            streetDescription: deliverySite.streetDescription,
            streetName: deliverySite.streetName,
            streetNumber: deliverySite.streetNumber,
            telephone: deliverySite.telephone,
            zipCode: deliverySite.zipCode,
          },
        }
        cardOrders.push(cardOrder)
      }
    })
    const validatedOrders = validateDuplicatedOrders(cardOrders)
    clearOrders()
    addOrders(validatedOrders)
    clearCardOrderDuplicates()
    navigate(navigationRoutes.cardOrderHome)
  }

  const validateDuplicatedOrders = (cardOrders: CardOrderModel[]) => {
    if (orders.length > 0) {
      const cardOrderConfigContext: CardOrderModel[] = []
      Seq(cardOrders).forEach(cardOrder => {
        Seq(orders).forEach(order => {
          if (order.employeeData.document === cardOrder.employeeData.document) {
            cardOrderConfigContext.push(cardOrder)
          }
        })
        const newCardOrder: CardOrderModel | undefined = cardOrderConfigContext.find(
          order => order.employeeData.document === cardOrder.employeeData.document
        )
        if (!newCardOrder) cardOrderConfigContext.push(cardOrder)
      })

      Seq(orders).forEach(order => {
        const cardOrderContext: CardOrderModel | undefined = cardOrderConfigContext.find(
          cardOrder => cardOrder.employeeData.document === order.employeeData.document
        )
        if (!cardOrderContext) cardOrderConfigContext.push(order)
      })
      return cardOrderConfigContext
    }
    return cardOrders
  }

  const GetErrors = (): boolean => {
    let hasError = false
    Seq(rows).forEach(row => {
      Seq(row.cells).forEach(cell => {
        if (cell.cellControll && cell.cellControll.hasError === true) {
          hasError = true
          return
        }
      })
    })
    return hasError
  }

  const handleDiscardPopup = () => {
    clearAll()
    clearCardOrderDuplicates()
    setShowPopup(false)
    navigate(navigationRoutes.cardOrderHome)
  }

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => handleDiscardPopup(),
        size: 'large',
      },
    ]
  }

  const ErrorEmailValidValue = (email: string | undefined): boolean => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (email === '' || email === undefined) {
      return true
    }

    if (!email.match(mailformat)) {
      return true
    }

    return false
  }

  const MessageEmailValidValue = (email: string | undefined): string | undefined => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (email === '' || email === undefined) {
      return t(forms.errors.fieldRequired)
    }

    if (!email.match(mailformat)) {
      return t(forms.errors.invalidFormat)
    }

    return undefined
  }

  useEffect(() => {
    if (deliverySitesResponse?.rows.length > 0) {
      GetSendDeliverySiteOptions(deliverySitesResponse?.rows)
    }
  }, [deliverySitesResponse])

  useEffect(() => {
    if (duplicates.length > 0 && deliverySitesOptions.length > 0) {
      GetRows()
    }
  }, [duplicates, deliverySitesOptions])

  useEffect(() => {
    setTitlePopup(t(duplicatesConfigurationTranslations.modal.title))
    setDescriptionPopup(t(duplicatesConfigurationTranslations.modal.description))
    setButtonsPopup(SetPopupButtons())
  }, [])

  useEffect(() => {
    getDuplicatesOnContext()
  }, [rows])

  useEffect(() => {
    if (validateTable) {
      if (!GetErrors()) addDuplicatesToCardOrder()
      setValidateTable(false)
    }
  }, [rows])

  return {
    header,
    rows,
    selector,
    caption,
    setRows,
    validateTable,
    ValidateTableFields,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    clearCardOrderDuplicates,
    navigate,
    tableEmpty,
  }
}
