import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface locationState {
  from?: string
}

export const usePreviousURL = (url: string) => {
  const [isPrevious, setIsPrevious] = useState<boolean>(false)
  const location = useLocation()
  const state = location.state as locationState

  useEffect(() => {
    if (state?.from) {
      setIsPrevious(state.from === url)
    }
  }, [url])

  return isPrevious
}
