import { useTTUnloadConfigurationEmployees } from '../hooks/useTTUnloadConfigurationEmployees'
import { TTUnloadConfigurationEmployeesList } from '../molecules/TTUnloadConfigurationEmployeesList'
import { TTUnloadConfigurationEmployeesFooterButtons } from '../molecules/TTUnloadConfigurationEmployeesFooterButtons'
import { TTUnloadConfigurationToolbar } from '../molecules/TTUnloadConfigurationToolbar'

export const TTUnloadConfigurationEmployeeContainer = () => {
  const props = useTTUnloadConfigurationEmployees()

  return (
    <div>
      <TTUnloadConfigurationEmployeesList employeesTTProps={props} />
      <TTUnloadConfigurationEmployeesFooterButtons
        tableIsEmpty={props.employeesTT && props.employeesTT.length <= 0}
        addConfigToOrder={props.addConfigToOrder}
        form={props.form}
        cancelPopupButtons={props.cancelPopupButtons}
        onClickCancel={props.onClickCancel}
        setShowCancelModal={props.setShowCancelModal}
        showCancelModal={props.showCancelModal}
        errorMessage={props.errorMessage}
      />
      {props.selectedEmployees.length > 1 && (
        <TTUnloadConfigurationToolbar
          selectedEmployees={props.selectedEmployees.length}
          onAssignMultipleValues={props.onAssignMultipleValues}
        />
      )}
    </div>
  )
}
