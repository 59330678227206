import { OreText } from '@runroom/oreneta'
import React from 'react'
import type { GeneralTermsServiceModel } from '../../../../domain/models/generalTerms/GeneralTermsModel'
import { Grid, GridItem } from '../../Edenred/layout'

interface Props {
  service: GeneralTermsServiceModel
}

export const EconomicTermItem = ({ service }: Props): JSX.Element => {
  return (
    <div>
      <Grid justify="space-between">
        <GridItem width={90} className="card-row">
          <OreText tone="neutral-600">{service.name}</OreText>
        </GridItem>
        <GridItem width={10} className="ml-1 pt-05">
          <OreText align="right" tone="neutral-400">
            {service.value}
          </OreText>
        </GridItem>
      </Grid>
    </div>
  )
}
