interface IProductsTranslateKeys {
  ticketRestaurant: string
  ticketTransporte: string
  ticketGuarderia: string
}

export const productsTranslate: IProductsTranslateKeys = {
  ticketRestaurant: 'edenred.products.ticketRestaurant',
  ticketTransporte: 'edenred.products.ticketTransporte',
  ticketGuarderia: 'edenred.products.ticketGuarderia',
}
