import { useTemplatesEdit } from 'src/Flex/CommunicationsTemplates/ui/templates-detail'
import { OreHeading } from '@runroom/oreneta'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useTranslation } from 'react-i18next'

export const DynamicTitle = (): JSX.Element => {
  const { t } = useTranslation()
  const { model } = useTemplatesEdit()

  return (
    <OreHeading as="h1" size="title-md">
      {t(flexCommunicationsTemplatesTranslations.configure.title, {
        name: model?.name,
      })}
    </OreHeading>
  )
}
