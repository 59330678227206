import { Seq } from 'immutable'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'
import { getUndefinedEmptyString } from 'src/core/services'

interface SelectorTextBoxState {
  filter: FilterModel | undefined
  openFilters: boolean
  onClickOpenFilters: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onChangeTextFilter: (event: ChangeEvent<HTMLInputElement>) => void
  onClickResetFilter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
  onChangeOptions: (event: ChangeEvent<HTMLInputElement>, name: string) => void
  ResetOptionsValue: () => void
}
export const useFilter = (
  filterTable: FilterModel,
  setFilterTable: Dispatch<SetStateAction<FilterModel>>,
  showOpen: boolean
): SelectorTextBoxState => {
  const [openFilters, setOpenFilters] = useState<boolean>(false)

  const [filter, setFilter] = useState<FilterModel>()

  const onClickOpenFilters = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setOpenFilters(!openFilters)
  }

  const onChangeTextFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(prevFilter => {
      const newFilter = { ...prevFilter }
      if (newFilter.search) newFilter.search.textBox.value = event.target.value
      return newFilter
    })
  }

  const onClickResetFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setFilter(prevFilter => {
      const resetFilter = { ...prevFilter }
      if (resetFilter.search) resetFilter.search.textBox.value = ''
      if (resetFilter.filterFields) {
        Seq(resetFilter.filterFields).forEach((fields: FilterFieldModel) => {
          Seq(fields.filterFields).forEach((field: FilterControlModel) => {
            field.value = getUndefinedEmptyString(field.defaultValue?.toString())
          })
        })
      }
      return resetFilter
    })
  }

  const onChangeOptions = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    setFilter(prevFilter => {
      const favoriteFilter = { ...prevFilter }
      if (favoriteFilter.filerOptions && favoriteFilter.filerOptions.length > 0) {
        Seq(favoriteFilter.filerOptions).forEach(option => {
          if (option.name === name) option.checked = event.target.checked
        })
      }
      return favoriteFilter
    })
  }

  const ResetOptionsValue = () => {
    // event.preventDefault()
    setFilter(prevFilter => {
      const favoriteFilter = { ...prevFilter }
      if (favoriteFilter.filerOptions && favoriteFilter.filerOptions.length > 0) {
        Seq(favoriteFilter.filerOptions).forEach(option => {
          option.checked = false
        })
      }
      return favoriteFilter
    })
  }

  useEffect(() => {
    if (Object.values(filterTable).length > 0 && filter === undefined)
      setFilter(filterTable)
  }, [filterTable])

  useEffect(() => {
    if (filter !== undefined) setFilterTable(filter)
  }, [filter])

  useEffect(() => {
    setOpenFilters(showOpen)
  }, [showOpen])

  return {
    filter,
    openFilters,
    onClickOpenFilters,
    onChangeTextFilter,
    onClickResetFilter,
    setFilter,
    onChangeOptions,
    ResetOptionsValue,
  }
}
